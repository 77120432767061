<template>
  <div class="d-flex flex-column flex-column-fluid flex-lg-row">
    <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
      <div class="d-flex flex-center flex-lg-start flex-column text-start">
        <a href="/" class="mb-7">
          <img alt="Logo" src="assets/theme/media/logos/logo-cc-white.png">
        </a>
        <h2 class="text-white fw-normal m-0 ms-4">{{ $t('Branding tools designed for your business') }}</h2> 
      </div>
    </div>
    <div class="d-flex flex-center w-lg-50 p-10"> 
      <div class="card rounded-3 w-md-550px pt-15 pb-15 mt-20 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow bg-abstract-2">
        <div class="card-body p-20 p-lg-20" v-if="!this.isViewPasswordChangeReminder">
          <div class="text-end mb-10">
              <Translator/> 

               <!-- <div v-if="languages" class="cursor-pointer">
                <span v-for="(lng, index) in Object.keys(languages)" :key="lng">
                  <a v-if="$i18next.resolvedLanguage !== lng" v-on:click="$i18next.changeLanguage(lng)">
                    {{ languages[lng].nativeName }}
                  </a>
                  <strong v-if="$i18next.resolvedLanguage === lng">
                    {{ languages[lng].nativeName }}
                  </strong>
                  <span v-if="index < (Object.keys(languages).length - 1)">&nbsp;|&nbsp;</span>
                </span>
              </div> -->

          </div>
          <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_sign_in_form" @submit.prevent="onBeforeSubmit"> 
            <div class="text-center mb-10">
              <h1 class="mb-3">{{ $t('Sign In to CreatorCUBE') }}</h1>
            </div>
            <div class="separator separator-content my-14" v-if="is_c_n_cp > 0">
              <span class="w-100 text-gray-500 fw-semibold fs-7">
                <router-link to="/NewCompany">
                  <a href="#" class="link-primary fw-bold"> 
                      {{ $t('Create New Company?') }}
                  </a>
                </router-link>
              </span>
            </div> 

            <div class="fv-row mb-10 fv-plugins-icon-container"> 
              <label class="form-label fs-6 fw-bolder">
                {{ $t('Email') }}
              </label>
              <input class="form-control bg-white form-control-lg" type="text" name="email" autocomplete="off" v-model="form.username" placeholder="Email"
              :class="{'border border-danger': isValidEmail == false}"/>
              <small class="text-danger" v-if="!isValidEmail && form.username !== null && form.username !== '' ">{{ $t('Email is not valid') }}</small>
            </div>

            <div class="fv-row mb-10 fv-plugins-icon-container">
            <div class="d-flex flex-stack mb-2">
                <label class="form-label fw-bolder  fs-6 mb-0">{{ $t('Password') }}</label>
              </div>
              <div class="position-relative"> 
              <input id="pd_password" class="form-control bg-white form-control-lg" type="password" name="password" v-model="form.password" maxlength="16" autocomplete="off" placeholder="Password"/>
              <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
              </span>
              </div>
              <div class="fv-plugins-message-container invalid-feedback">
                <p v-if="showError" id="error">
                  {{ $t('Email or Password is incorrect.') }}
                </p>
              </div>
              <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div></div>                
                <router-link to="/ForgotPassword">
                  <a href="#" class="link-primary fw-bold"> 
                      {{ $t('Forgot Password ?') }}
                  </a>
                </router-link>
              </div>
            </div>

            <div class="text-center">
              <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5 shadow-sm" :disabled="isValidEmail !== true">
                <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"/>
                <path d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z" fill="currentColor"/>
                <path d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z" fill="currentColor"/>
                </svg>
                </span>
                <span class="indicator-label">{{ $t('Continue') }}</span>
                <span class="indicator-progress">{{ $t('Please wait...') }}<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
            </div>
            <div></div>
          </form>
        </div>
        <div class="card-body py-15 py-lg-20" v-if="this.isViewPasswordChangeReminder">
          <div class="text-center mb-10">
            <h1 class=" mb-3">
              Your password will expire within {{passwordChangeRemindDay}} days.
            </h1>
            <div class="text-muted fw-semibold fs-5 mb-5">
              {{ $t('You can change your password or continue using current password.') }}
              <br>
              <a href="#" class="link-primary fw-bold" @click="onBackLogin()">
                {{ $t('Back to Login') }}
              </a>
            </div>
          </div>
          
          <div class="text-center">
            <img src="/assets/theme/media/illustrations/unitedpalms-1/9.png" class="mw-100 mh-200px theme-light-show" alt="">
            <img src="/assets/theme/media/illustrations/unitedpalms-1/9-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
          </div>
          <div class="d-flex flex-stack pt-15 justify-content-center"> 
            <div class="me-5">
              <button type="button" class="btn btn-lg btn-light-success" @click="submit()">
                {{ $t('Continue Login') }}
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-lg btn-primary me-3" @click="goToResetPassword()"> 
                {{ $t('Change Password') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template> 
<script>
import axios from "axios"; 
// import { mapActions } from "vuex"; 
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });
import { load } from 'recaptcha-v3';
import Translator from "../components/Translator.vue";

export default {
  name: "Login",
  components: {
    Translator
  },
  mounted() {
    sessionStorage.clear();
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
      isViewPasswordChangeReminder: false,
      passwordChangeRemindDay: 0,
      isHide: true,
      isVerifyRecaptcha: false,
      rc_sk: '',
      is_c_n_cp: 0,
      languages: {
        en: { nativeName: 'English' },
        tr: { nativeName: 'Türkçe' }
      }
    }
  },
  computed: {
    isValidEmail() {
      return (this.form.username == null || this.form.username == '' || this.form.username == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.username);
    }
  },
  methods: {
    // ...mapActions(["LogIn"]),
    async submit() {
      const params = {
        username: this.form.username,
        password: this.form.password
      };
      await axios.post('/api/Auth/AuthUser', params, {'Content-Type': 'application/json'})
      .then((response) => {
          if(response.data.id != null && response.data.id.length == 36 && (response.data.error == null || response.data.error == 'undefined' || response.data.error == '')){
            response.data.company_name = this.$unlock(response.data.company_name);
            response.data.company_vkn = this.$unlock(response.data.company_vkn);
            response.data.name_surname = this.$unlock(response.data.name_surname);
            response.data.user_mail = this.$unlock(response.data.user_mail);
            response.data.company_country_code = this.$unlock(response.data.company_country_code);
            response.data.phone_country_code = this.$unlock(response.data.phone_country_code);
            if(response.data.stripe) {
              response.data.stripe.oid = this.$unlock(response.data.stripe.oid);
              response.data.stripe.mid = this.$unlock(response.data.stripe.mid);
              response.data.stripe.strpk = this.$unlock(response.data.stripe.strpk);
              response.data.stripe.psurl = this.$unlock(response.data.stripe.psurl);
              response.data.stripe.flurl = this.$unlock(response.data.stripe.flurl);
            }

            ls.set('user_' + response.data.session_id, response.data); 
            sessionStorage.setItem('sid', response.data.session_id);
            sessionStorage.setItem('activeYear', response.data.period_id);
            this.getFormFields();
          }
          else{ 
            sessionStorage.clear();
            return;
          }
      })
      .catch(function (error) { 
          console.log(error);
          this.showError = true;
          this.$swal("Login failed.", "Please make sure you entered the correct information and try again..!", 'error');
      });  
    },
    async onBeforeSubmit() {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');        
        this.checkAuthPassword(token, true);
      } else {
        this.checkAuthPassword();
      }
    },
    async loadReCaptcha() {
			if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
        const recaptcha = await load(this.rc_sk);
        if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
        else recaptcha.showBadge();
      }
    },
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg7-dark.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');
      let element = document.getElementsByClassName("modal-backdrop");
      if(element.length > 0) {
        element[0].className = "";
      }

      axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.isVerifyRecaptcha = response.data.is_view_rc_lg == 1 ? true : false;
          this.rc_sk = this.$unlock(response.data.sk);
          this.is_c_n_cp = response.data.is_c_n_cp;
          this.loadReCaptcha();
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      try{
          for (var a in localStorage) { 
            if(a.substring(0,2) == 'dx'){
              ls.remove(a);
            }
          }
      }
      catch(Error){ console.log(Error);}

    },
    goToResetPassword() {
      this.$router.push({ name: 'ResetPassword', params: { user_mail: this.form.username } });
    },
    checkAuthPassword(token, is_recaptcha_active) {
      const parameters = {
        username: this.form.username,
        password: this.form.password,
        tk: token,
        ira: is_recaptcha_active
      };
      axios.post("/api/Auth/CheckAuthPassword", parameters, {'Content-Type': 'application/json'}).then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){				
          if (response.data.is_password_change_required == 1) {
            this.isViewPasswordChangeReminder = false;
            this.$router.push({ name: "ResetPassword", params: { user_mail: this.form.username }});
          } else if (response.data.is_remind_password_change == 1) {
            this.isViewPasswordChangeReminder = true;
            this.passwordChangeRemindDay = response.data.password_change_remind_day;
          } else {
            this.isViewPasswordChangeReminder = false;
            this.submit();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    onBackLogin() {
      this.isViewPasswordChangeReminder = false,
      this.passwordChangeRemindDay = 0;
      this.form = {
        username: "",
        password: "",
      };
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("pd_password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    getFormFields(){
        const params = {
            route: null,
            session_id: ls.get('user_' + sessionStorage.getItem('sid')).session_id
        };
        axios.post('/api/FormValidations/GetFormFields', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data){
              sessionStorage.setItem('frm_val_list', JSON.stringify(response.data));  
              window.location.href= "/";
            }
        })
        .catch(function () { 
        });
    }
  },
  created() {
    this.getData();
  }
};
</script>
<style scoped>
  body{
    background-image: url('/assets/theme/media/auth/bg7-dark.jpg');
    top: 0 !important;
  }

  @media (min-width: 992px){
  .flex-lg-start {
      justify-content: start;
      align-items: start;
  }}   
  .skiptranslate {
    display: none !important;
  }
  
</style>