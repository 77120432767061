<template>
    <div>
        <div class="card-body">
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                    <div class="p-3">
                        <div class="d-flex flex-stack flex-wrap mb-2">
                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                            </div>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">    
                            <div class="table-responsive-lg">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                        key-expr="id" 
                                        :data-source="policyVendorsList" 
                                        :allow-column-reordering="true"  
                                        :allow-column-resizing="true" 
                                        :column-auto-width="true" 
                                        :row-alternatin-enabled="true"
                                        :hover-state-enabled="true" 
                                        :show-row-lines="true"
                                        @exporting="this.$onExporting($event , 'Vendor-commissions')"
                                        @row-prepared="onRowPrepared" 
                                        :customize-columns="onCustomizeColumn"> 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxGroupPanel :visible="true"/>      
                                        <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="1000000"/>
                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                        <DxSearchPanel :visible="true"/>
                                        <DxScrolling
                                            mode="virtual"
                                            row-rendering-mode="virtual"
                                        />
                                        <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Date')" alignment="right" cell-template="regulation_date-cell-template"/> 
                                        <DxColumn
                                            alignment="center"
                                            :caption="$t('Policy')"
                                            :fixed="false"
                                            fixed-position="right">
                                            <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" cell-template="policy_type_name-cell-template"/> 
                                            <DxColumn data-field="policy_no" :caption="$t('No')" cell-template="policy_no-cell-template"/> 
                                        </DxColumn>
                                        <DxColumn data-field="endorsement_type_name" :caption="$t('Endorsement Type')" header-cell-template="endorsement_type_name-header" cell-template="endorsement_type_name-cell-template"/> 
                                        <DxColumn data-field="producer_name" :caption="$t('Producer')" header-cell-template="producer_policy_type-header" cell-template="producer_name-cell-template"/> 
                                        <DxColumn data-field="start_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Start Date')" alignment="right" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                        <DxColumn data-field="vendor_name" :caption="$t('Vendor')" cell-template="vendor_name-cell-template"/> 
                                        <DxColumn data-field="commission_type_name" :caption="$t('Commission Type')" header-cell-template="commission_type_name-header" cell-template="commission_type_name-cell-template"/> 
                                        <DxColumn data-field="commission_rate" :sorting-method="this.$numericSort" alignment="right" :caption="$t('Commission Rate')" header-cell-template="commission_rate-header" cell-template="commission_rate-cell-template"/> 
                                        <DxColumn data-field="commission_amount_local" :sorting-method="this.$numericSort" :caption="$t('Commission Local')" alignment="right" header-cell-template="commission_amount_local-header" cell-template="commission_amount_local-cell-template"/> 
                                        <DxColumn data-field="vendor_total_commission_amount_local" :sorting-method="this.$numericSort" :caption="$t('Vendor Commission Local')" alignment="right" header-cell-template="vendor_commission_amount_local-header" cell-template="vendor_total_commission_amount_local-cell-template"/> 
                                        <DxColumn data-field="net_commission_amount_local" :sorting-method="this.$numericSort" :caption="$t('Net Commission Local')" alignment="right" header-cell-template="net_commission_amount_local-header" cell-template="net_commission_amount_local-cell-template"/> 
                                        <DxColumn data-field="net_amount_local" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" header-cell-template="net_amount_local-header" alignment="right" cell-template="net_amount_local-cell-template"/> 
                                        <DxColumn data-field="policy_total_amount_local" :sorting-method="this.$numericSort" :caption="$t('Total Amount')" header-cell-template="policy_total_amount_local-header" alignment="right" cell-template="policy_total_amount_local-cell-template"/> 
                                        <DxColumn data-field="currency" :caption="$t('Curr.')" header-cell-template="currency-header" cell-template="currency-cell-template"/> 
                                        <DxColumn data-field="net_amount" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" header-cell-template="net_amount-header" alignment="right" cell-template="net_amount-cell-template"/> 
                                        <DxColumn data-field="policy_total_amount" :sorting-method="this.$numericSort" :caption="$t('Total')" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                                        <DxColumn data-field="commission_amount" :sorting-method="this.$numericSort" :caption="$t('Commission')" alignment="right" cell-template="commission_amount_local-cell-template"/> 
                                        <DxColumn data-field="vendor_total_commission_amount" :sorting-method="this.$numericSort" :caption="$t('Vendor Commission')" header-cell-template="vendor_commission_amount-header" alignment="right" cell-template="vendor_total_commission_amount-cell-template"/> 
                                        <DxColumn data-field="net_commission_amount" :sorting-method="this.$numericSort" :caption="$t('Net Commission')" alignment="right" header-cell-template="net_commission_amount-header" cell-template="net_commission_amount-cell-template"/> 

                                        <template #headerLocalTemplate="{ data: data }">
                                            <div v-html="this.$parent.setColumnTemplateForLocal(data)"></div> 
                                        </template>

                                        <template #vendor_commission_amount_local-header>
                                            <div>{{ $t('Vendor Commission') }}</div><div>{{ $t('Local') }}</div>
                                        </template> 
                                        <template #net_commission_amount_local-header>
                                            <div>{{ $t('Net Commission') }}</div><div>{{ $t('Local') }}</div>
                                        </template> 
                                        <template #vendor_commission_amount-header>
                                            <div>{{ $t('Vendor') }}</div><div>{{ $t('Commission') }}</div>
                                        </template> 
                                        <template #net_commission_amount-header>
                                            <div>{{ $t('Net') }}</div><div>{{ $t('Commission') }}</div>
                                        </template> 
                                        <template #commission_type_name-header>
                                            <div>{{ $t('Commission') }}</div><div>{{ $t('Type') }}</div>
                                        </template> 
                                        <template #commission_rate-header>
                                            <div>{{ $t('Commission') }}</div><div>{{ $t('Rate') }}</div>
                                        </template> 
                                        <template #commission_amount_local-header>
                                            <div>{{ $t('Commission') }}</div><div>{{ $t('Local') }}</div>
                                        </template> 
                                        <template #policy_type_name-header>
                                            <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                                        </template> 
                                        <template #currency-header>
                                            <div>{{ $t('Curr.') }}</div><div>{{ $t('Rate') }}</div>
                                        </template> 
                                        <template #is_new_or_renewal-header>
                                            <div>{{ $t('New/') }}</div><div>{{ $t('Renewal') }}</div>
                                        </template> 
                                        <template #endorsement_type_name-header>
                                            <div>{{ $t('Endorsement') }}</div><div>{{ $t('Type/Detail') }}</div>
                                        </template> 
                                        <template #start_date-header>
                                            <div>{{ $t('Start') }}</div><div>{{ $t('End') }}</div>
                                        </template> 
                                        <template #policy_no-header>
                                            <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                        </template>
                                        <template #net_amount-header>
                                            <div>{{ $t('Policy') }}</div><div>{{ $t('Net') }}</div>
                                        </template> 
                                        <template #net_amount_local-header>
                                            <div>{{ $t('Net') }}</div><div>{{ $t('Local') }}</div>
                                        </template> 
                                        <template #policy_total_amount_local-header>
                                            <div>{{ $t('Total') }}</div><div>{{ $t('Local') }}</div>
                                        </template> 
                                        <template #producer_policy_type-header>
                                            <div>{{ $t('Producer') }}</div><div>{{ $t('/Policy Type') }}</div>
                                        </template>
                                    
                                        <template #regulation_date-cell-template="{ data }">
                                            <span class="align-middle text-gray-700 text-end">
                                                <div>{{this.$parent.previewDate(data.data.regulation_date)}}</div>
                                                <div>{{data.data.is_new_or_renewal}}</div>
                                            </span>   
                                        </template>
                                        <template #policy_type_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-900 text-start">
                                                <div>{{data.data.policy_type_name}}</div>
                                            </span>
                                        </template>
                                        <template #endorsement_type_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-700 text-start">
                                                <div>{{data.data.endorsement_type_name}}</div> 
                                                <div>{{data.data.endorsement_detail_name}}</div> 
                                            </span>   
                                        </template>
                                        <template #producer_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-900 text-start">
                                            <div> {{data.data.producer_name}}</div>
                                            <div> {{data.data.producer_policy_type_name}}</div>
                                            </span>   
                                        </template>
                                        <template #start_date-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                            <div>{{this.$parent.previewDate(data.data.start_date) }}</div>
                                            <div>{{this.$parent.previewDate(data.data.end_date) }}</div>
                                            </span>   
                                        </template>
                                        <template #policy_no-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.policy_no}}</div>
                                            </span>   
                                        </template>
                                        <template #vendor_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>
                                                    <span v-if="data.data.row_type == 2"><i class="bi bi-caret-right-fill fs-8 me-1"></i></span>
                                                    <span>{{data.data.vendor_name }}</span>
                                                </div>
                                            </span>   
                                        </template>
                                        <template #currency-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.currency }}</div>
                                                <div>{{data.data.rate }}</div>
                                            </span>   
                                        </template>
                                        <template #commission_type_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.commission_type_name }}</div>
                                            </span>   
                                        </template>
                                        <template #commission_rate-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.commission_rate }}</div>
                                            </span>   
                                        </template>
                                        <template #commission_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.commission_amount }}</div>
                                            </span>   
                                        </template>
                                        <template #commission_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.commission_amount_local }}</div>
                                            </span>   
                                        </template>
                                        <template #vendor_total_commission_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.vendor_total_commission_amount }}</div>
                                            </span>   
                                        </template>
                                        <template #net_commission_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.net_commission_amount }}</div>
                                            </span>   
                                        </template>
                                        <template #vendor_total_commission_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.vendor_total_commission_amount_local }}</div>
                                            </span>   
                                        </template>
                                        <template #net_commission_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div> {{data.data.net_commission_amount_local }}</div>
                                            </span>   
                                        </template>
                                        <template #policy_total_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.policy_total_amount }}</div>
                                            </span>   
                                        </template>        
                                        <template #policy_total_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.policy_total_amount_local }}</div>
                                            </span>   
                                        </template>
                                        <template #net_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.net_amount }}</div>
                                            </span>   
                                        </template>                                     
                                        <template #net_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.net_amount_local }}</div>
                                            </span>   
                                        </template>
                                        <template #payment_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <div>{{data.data.payment_amount_local }}</div>
                                            </span>   
                                        </template>
                                </DxDataGrid>   
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
export default {
    name: 'Commissions',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
    },
    data() {
        return {
            policyVendorsList: []
        }
    },
    methods: {        
        getData() {
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_card_id: this.$getSessionStorage('record_id')
            };  
            axios.post('/api/Insurance/GetPolicyVendors', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.policyVendorsList = response.data;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onRowPrepared(e){
            if(e.rowType === 'data') {  
                if(e.data.row_type == 2) { 
                    e.rowElement.classList.add('bg-gray-200');  
                }
            } 
        },
        onCustomizeColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {               
                        if(column.dataField == 'policy_total_amount_local'){ 
                            column.caption =  'Total Local';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'payment_amount_local'){ 
                            column.caption =  'Producer Payment Local';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
    },
    created() {
        this.getData();
    }
}
</script>