<template>
  <div class="d-flex flex-column flex-column-fluid flex-lg-row justify-content-center align-items-center">
    <div class="d-flex flex-center text-center pt-15">
      <div class="card card-flush w-lg-450px py-5">
        <div class="card-body py-15 py-lg-20">
          <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_new_password_form" @submit.prevent="submit">
            <div class="text-center mb-10">
                <h1 class="text-gray-800 fw-bolder mb-3">
                  Session Locked
                </h1>
                <div class="text-gray-500 fw-semibold fs-6">
                  Session locked due to <b class="fw-boldest text-gray-600">  {{inactiveTime}} </b> minutes inactivity. Please enter the password to activate the session.
                </div>
            </div>
            <div class="fv-row mb-8 fv-plugins-icon-container">    
              <div class="input-group mb-5">
                <input class="form-control bg-white form-control-lg" type="password" name="password" v-model="form.password" autocomplete="off" placeholder="Password">
                <button type="submit" id="kt_new_password_submit" class="btn btn-primary">
                  <i class="bi bi-box-arrow-in-right"></i>
                </button>
              </div>
            </div>
            <div class="fv-plugins-message-container invalid-feedback" v-if="showError" id="error">
              <p class="float-start">
                An error occured. Please try again later.
              </p>
            </div>
            <div class="text-gray-500 fw-semibold fs-6 text-start">
                Not <span class="fw-bolder"> {{userData.name_surname}} </span> ?
                <a href="#" class="link-primary fw-bold" @click="signOut()">
                  Go to Login Page
                </a>
            </div>
          </form>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false});
export default {
  name: "ScreenLock",
  props: {
    inactiveTime: Object
  },
  data() {
    return {
      userData: this.$getUser(),
      form: {
        username: this.$getUser().user_mail,
        password: "",
      }
    };
  },
  methods: {
    async submit() {
      const params = {
        session_id: this.$getUser().session_id,
        username: this.form.username,
        password: this.form.password
      };
      if(!params.session_id || !params.username) {
        sessionStorage.clear();
        this.$parent.isScreenLocked = false;
        this.$router.push({ name: 'Login' });
        return;
      }
      await axios.post('/api/Auth/ContinueToSession', params, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
          var userData = ls.get('user_' + sessionStorage.getItem('sid'));
          if(userData !== null && userData !== '' && userData !== undefined) {
            userData.is_locked = 0;
            ls.set('user_' + sessionStorage.getItem('sid'), userData);
          }
          this.$parent.isScreenLocked = false;
          this.emitter.emit('changeScreenLockStatus', userData.is_locked);
          document.body.style.backgroundImage = '';
          
          let element = document.getElementsByClassName("modal-closed");
          if(element.length > 0) {
            element[0].className = "modal-backdrop fade show";
          }
        }
      })
      .catch(function (error) { 
          console.log(error);
          this.showError = true;
      });  
    },
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg7-dark.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      const parameters = {
        session_id: this.$getUser().session_id
      };  
      axios.post('/api/Auth/LockSession', parameters, {'Content-Type': 'application/json'})
      .then((response) => {        
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          var userData = ls.get('user_' + sessionStorage.getItem('sid'));          
          if(userData !== null && userData !== '' && userData !== undefined) {
            userData.is_locked = 1;
            ls.set('user_' + sessionStorage.getItem('sid'), userData);
          }
          let element = document.getElementsByClassName("modal-backdrop");
          if(element.length > 0) {
            element[0].className = "modal-closed";
          }
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    signOut() {
      this.$parent.isScreenLocked = false;
      document.body.style.backgroundImage = '';
      this.$removeUser('user_' + this.$getUser().session_id);      
      sessionStorage.clear();
      this.$router.push({ name: 'Login' });
    }
  },
  created() {
     this.getData();
  }
};
</script>