<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Data Import Settings') }} </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings')
                            }}</router-link></li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Data Import Settings') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="card-header">
                    <div class="card-title">
                        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                            <li class="nav-item">
                                <a class="nav-link " @click="this.activeView = 'TemplateGroup'"
                                    :class="{ 'text-active-primary active': activeView == 'TemplateGroup' }"
                                    data-bs-toggle="tab" href="#templage_group" target="_self">
                                    {{ $t('Template Group Settings') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="this.activeView == 'TemplateGroup'" class="tab-pane fade show active" id="templage_group"
                    role="tabpanel">
                    <div class="card-header border-0 mt-3 pt-6">
                        <div class="card-title"> 
                            <button type="button" class="btn btn-light-primary border border-primary "
                            ref="btnAddItem"
                            data-bs-toggle="modal" 
                            data-bs-target="#kt_modal_template_group" 
                            @click="openAddTemplateGroupModal('addItem')"
                            data-kt-menu-trigger="click" 
                            data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                        fill="currentColor">
                                    </rect>
                                </svg>
                            </span> 
                            {{ $t('Add Template Group') }}
                            </button>
                        </div>
                        <div class="card-toolbar">
                            <div class="d-flex justify-content-end">
                            </div>                          
                            <div class="d-flex justify-content-end ms-5">
                            <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isListProgressing"> {{ $t('List Template Group') }} </strong> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                            </div> 
                        </div>
                    </div>

                    <div class="card-body pt-3">
                        <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive min-h-300px">
                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="lists" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    @exporting="this.$onExporting($event , 'Data-Import-Settings')">

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="10"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    
                                    <DxColumn data-field="code" :caption="$t('Code')" cell-template="code-cell-template"/> 
                                    <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/>
                                    
                                    <!-- Cell Templates -->
                                    <template #code-cell-template="{ data }">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_template_group"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAddTemplateGroupModal('editItem', data.data)"
                                            class="text-gray-900 mb-1">
                                            <span class="badge badge-circle badge-light me-3">
                                                <i class="bi bi-pencil text-gray-900"></i> 
                                            </span> 
                                            <span> {{ data.data.code }} </span>
                                        </a>
                                    </template>
                                    <template #name-cell-template="{ data }">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_template_group"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAddTemplateGroupModal('editItem', data.data)"
                                        class="text-gray-900 fw-bold mb-1">
                                            {{ data.data.name }}
                                        </a>
                                    </template>
                            </DxDataGrid> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        
            <!-- ADD ACCOUNTING YEAR MODAL -->
            <div class="modal fade" id="kt_modal_template_group" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content"  >
                        <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                            <h2 class="modal-title fw-bolder col-md-11 text-white">
                                <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                                <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> 
                            </h2>
                            <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                            </div> 
                            <div class="btn btn-icon btn-sm btn-active-icon-primary"
                                data-bs-dismiss="modal" aria-label="Close" ref="closeTemplateGroupModal">
                                <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div class="modal-body scroll-y pt-0">
                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="card card-xxl-stretch mb-6">
                                                    <div class="card-body pb-0">
                                                        <form id="kt_ecommerce_settings_general_form"
                                                            class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                            <div class="fv-row mb-7 row">
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                                        {{ $t('Code') }}
                                                                    </label>
                                                                    <input type="text" class="form-control " name="code"
                                                                        v-model="templateGroupForm.code">
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                                                        {{ $t('Name') }}
                                                                    </label>
                                                                    <input type="text" class="form-control " name="name"
                                                                        v-model="templateGroupForm.name">
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="d-grid gap-2">
                                                    <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" 
                                                        @click="this.saveTemplateGroup()">
                                                        <strong>{{ $t('Save') }}</strong> 
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" 
                                                        @click="this.updateTemplateGroup()">
                                                        <strong>{{ $t('Update') }}</strong> 
                                                    </button>
                                                    <button type="button" class="btn btn-danger float-start btn-lg" v-if="this.modalType == 'editItem'" 
                                                        @click="this.removeTemplateGroup(this.templateGroupForm.id)">
                                                        <strong>{{ $t('Delete') }}</strong> 
                                                    </button>
                                                </div>                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios"; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, } from 'devextreme-vue/data-grid';
 
export default {
    name: "DataImportSettings",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel,
    },
    data() {
        return {
            activeView: "TemplateGroup",
            isProgressing: false,
            templateGroupForm:{
                name: null,
                code: null,
            },
            menuSelection: '/DataImportSettingsHelppage',
            lists : [],
            modalType: 'addItem',
            isListProgressing: false,
            iconView : false
        }
    },
    methods:{
        getData(){
            const that = this;
            that.isListProgressing = true;
            this.$setSessionStorage('refreshList', true);
            const parameters = {
                session_id: this.$getUser().session_id,
                account_type : 2,
                list_name: ["account_setting"],
                search_text: '',
                search_parameter: 0, // 0: All           
                list_type: 23 /* Template Group Type */
            };        
            axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
              this.lists = response.data;
            })
            .catch(function (error) {
                console.log(error);
                that.isListProgressing = false;
            })
            .finally(function() {
                that.isListProgressing = false;
                that.$Progress.finish();
            });
        },
        saveTemplateGroup() {
            this.isProgressing = true;
            const that = this;
            const params = {
                name: this.templateGroupForm.name,
                code: this.templateGroupForm.code,
                list_type: 23,
                session_id: this.$getUser().session_id,
            };
            axios.post('/api/PolicyLists/CreateListItem', params, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.data != null && response.data.length === 36) {
                    this.$setSessionStorage('record_id', response.data);
                    this.$setSessionStorage('refreshList', true);
                    this.$refs.closeTemplateGroupModal.click();
                    this.getData();
                } else {
                    this.$swal("Failed", "Data could not be saved..!", 'error');
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..!");   
            }) 
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            });
        },
        openAddTemplateGroupModal(modalType, data){
            this.modalType = modalType;
            this.templateGroupForm = {
                name : null,
                code : null,
            }     
            if (modalType == 'editItem') {
                this.templateGroupForm = {
                    id: data.id,
                    name: data.name,
                    code: data.code,
                }
            }         
        },
        updateTemplateGroup(id){ 
            if(id == -1){
                return;
            }
            this.isProgressing = true;
            const params = this.templateGroupForm; 
            const that = this;   
            params.list_type = 23;
            params.session_id = this.$getUser().session_id;
            params.parent_id = null;
            axios.post('/api/PolicyLists/UpdateListItem', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data != null && response.data.length == 36){   
                    this.$swal("Success", "Data saved succesfully..!", 'success'); 
                    this.$setSessionStorage('refreshList', true);      
                    this.$refs.closeTemplateGroupModal.click(); 
                    this.getData();
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..!");   
            }) 
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            }); 
        },
        removeTemplateGroup(id){
            this.$swal.fire({
                title: 'Are you sure you want to delete this Accounting Period with Accounting Plans?',           
                icon: 'warning',
                confirmButtonColor: '#f1416c', //'#E53935',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes, Delete it!'
            })
            .then((result) => { 
                if (result.isConfirmed) { 
                    const params = {
                        session_id : this.$getUser().session_id,
                        id: id
                    };
                    axios.post("/api/PolicyLists/DeleteListItem", params, {'Content-Type': 'application/json'})  
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                            this.$refs.closeTemplateGroupModal.click();
                            this.getData();
                        } 
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            })  
        },
        async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/DataImportSettingsHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
    },
    created() {
        this.helpPageViewIcon();
        this.getData();
    }
}; 
</script>