<template>
    <div class="row" v-if="this.activeTab == 'BalanceClosing'">
        
        <div class="card card-bordered border-gray-300">
            <div class="row fv-row border-0 p-3">
                    <div class="col-lg-8">
                        <div class="fv-row mb-4 row"> 
                            <div class="col-lg">
                                <label class="fs-6 form-label mt-3" >
                                    <span>{{ $t('Currency') }}</span>
                                </label>
                                <Select2 v-model="balanceForm.currency" :options="this.currencyList" :settings="{ width: '100%' }" @select="onGetBalanceList(true)" />  
                            </div>                                                        
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('Transaction Type') }}</span>
                                </label>
                                <Select2 v-model="balanceForm.transaction_type" :options="transactionTypeList" :settings="{width: '100%'}" @select="onGetBalanceList(true)" />
                            </div>
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3" >
                                    <span>{{ $t('End Due Date') }}</span>
                                </label>
                                <div class="input-group">
                                    <input v-if="isManualOperation || this.modalType == 'addItem' || (this.modalType == 'editItem' && this.isBalanceToRefundExist == 0)"  class="form-check-input mt-3 me-1 rounded-1" type="checkbox" v-model="balanceForm.isShowAll" @change="onGetBalanceList(this.balanceForm.isAutomaticClosing)" data-bs-toggle="tooltip" title="Show All"/>
                                    <Datepicker  textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="balanceForm.end_due_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" @update:modelValue="onGetBalanceList(this.balanceForm.isAutomaticClosing)"  />
                                </div>
                            </div>
                        </div>
                    </div>
                
                <div class="col-lg-4" v-if="this.accountBalanceList.length > 0">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="table-responsive">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="currency" 
                                    :data-source="this.accountBalanceList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    :ref="'dataGrid'"> 

                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                    <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template"/>
                                    <DxColumn data-field="total_balance" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right" cell-template="total_balance-cell-template"/>
                                    <DxColumn data-field="total_balance_local" :sorting-method="this.$numericSort" :caption="$t('Balance Local')" alignment="right" cell-template="total_balance_local-cell-template" header-cell-template="total_balance_local-header"/>
                                    <DxColumn data-field="average_due_state" :caption="$t('Average Due Date')" alignment="right" cell-template="average-due-state-cell-template" header-cell-template="average-due-state-header"/>

                                    <template #total_balance_local-header>
                                        <div>{{ $t('Balance') }} ({{localCurrency}})</div>
                                    </template>
                                    <template #average-due-state-header>
                                        <div>{{ $t('Average') }}</div><div>{{ $t('Due Date') }}</div>
                                    </template>

                                    <!-- Cell Templates -->
                                    <template #currency-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="balanceForm.currency = data.data.currency, onGetBalanceList(this.balanceForm.isAutomaticClosing)">
                                            <i class="bi bi-cursor-fill me-3"></i> <span>{{ data.data.currency }}</span>
                                        </a>
                                    </template>
                                    <template #total_balance-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="balanceForm.currency = data.data.currency">
                                            {{ data.data.total_balance }}
                                        </a>
                                    </template>
                                    <template #total_balance_local-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="balanceForm.currency = data.data.currency">
                                            {{ data.data.total_balance_local }}
                                        </a>
                                    </template>
                                    <template #average-due-state-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="balanceForm.currency = data.data.currency">
                                            <span>{{ data.data.average_due_state }}</span>
                                            <span v-if="data.data.average_due_state !== 0" class="fs-8 text-gray-600 ps-1">
                                                ({{ previewDate(data.data.average_due_date) }})
                                            </span>
                                        </a>
                                    </template>
                                        
                                    <DxSummary :calculate-custom-summary="setDetailSummaries">
                                        <DxTotalItem
                                            name="total_balance_local"
                                            summary-type="custom"
                                            show-in-column="total_balance_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="average_due_state"
                                            summary-type="custom"
                                            show-in-column="average_due_state"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
                                </DxDataGrid>   
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    
        <div class="card card-bordered border-gray-300 my-3">
            <div class="row">
                <div class="col" v-if="isManualOperation || isBalanceToRefundExist == 0">
                    <div class="card card-dashed flex-center shadow-sm min-w-175px my-3 p-3">
                        <span class="fs-6 fw-semibold text-gray-800 pb-0" v-if="this.balanceForm.item_type == 1"> {{$t('Balance Total')}} </span>                    
                        <span class="fs-6 fw-semibold text-gray-800 pb-0" v-if="this.balanceForm.item_type == 2"> {{$t('Payment Total')}} </span>                    
                        <span class="fs-3 fw-bolder d-flex justify-content-center">
                            <span data-kt-countup="true" :data-kt-countup-value="getRemainAmountFormatted" class="counted text-gray-800" data-kt-initialized="1">
                                {{getRemainAmountFormatted}} {{this.balanceForm.currency}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col" v-if="isManualOperation || isBalanceToRefundExist == 0">
                    <div class="card card-dashed flex-center shadow-sm min-w-175px my-3 p-3">
                        <span class="fs-6 fw-semibold text-info pb-0">{{$t('To be Closing')}}</span>                    
                        <span class="fs-3 fw-bolder d-flex justify-content-center">
                            <span data-kt-countup="true" :data-kt-countup-value="this.form.amount" class="counted text-gray-800" data-kt-initialized="1">
                                {{this.$globalParseFloat(this.form.amount)}} {{this.balanceForm.currency}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col">
                    <div class="card card-dashed flex-center shadow-sm min-w-175px my-3 p-3">
                        <span class="fs-6 fw-semibold text-success pb-0">{{$t('Closing Total')}}</span>                    
                        <span class="fs-3 fw-bolder d-flex justify-content-center">
                            <span data-kt-countup="true" :data-kt-countup-value="getClosingTotalFormatted" class="counted text-gray-800" data-kt-initialized="1">
                                {{getClosingTotalFormatted}} {{this.balanceForm.currency}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col">
                    <div class="card card-dashed flex-center shadow-sm min-w-175px my-3 p-3">
                        <span class="fs-6 fw-semibold text-primary pb-0">{{$t('Closing Remain Total')}}</span>                    
                        <span class="fs-3 fw-bolder d-flex justify-content-center">
                            <span data-kt-countup="true" :data-kt-countup-value="getClosingRemainTotalFormatted" class="counted text-gray-800" data-kt-initialized="1">
                                {{ getClosingRemainTotalFormatted}} {{this.balanceForm.currency}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-bordered border-gray-300 my-3">
            <div class="card-body p-2">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-1 pe-0">
                                <input class="form-check-input form-check-sm mt-3" type="checkbox" id="isAutomaticClosing" v-model="balanceForm.isAutomaticClosing" 
                                :disabled="balanceForm.balance_closing_option == 1" @change="onGetBalanceList(this.balanceForm.isAutomaticClosing)">
                            </div>
                            <label class="col-lg-11 ps-0 col-form-label">{{$t('Automatic Balance Closing')}}</label>
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="isBalanceToRefundExist > 0 && !isRefundedBalanceClosing">
                        <button type="button" class="btn btn-info btn-sm float-end mt-1" @click="onRefundBalanceClosing()"> 
                            {{$t('Refund Balance Closing')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-bordered border-gray-300 px-0">
            <div class="table-responsive" v-if="this.$parent.balanceClosingList.length > 0">
                <table class="table table-rounded gy-5 gs-5">
                    <thead>
                        <tr class="fw-bolder fs-7 text-gray-800 border-bottom border-gray-200">
                            <th>#</th>
                            <th class="text-end">{{ $t('Due Date') }}</th>
                            <th>{{ $t('Curr.') }}</th>
                            <th class="text-end">{{ $t('Amount') }}</th>
                            <th class="text-end"> 
                                <span v-if="this.balanceForm.item_type == 1">{{ $t('Collected') }}</span> 
                                <span v-if="this.balanceForm.item_type == 2">{{ $t('Paid') }}</span> 
                            </th>
                            <th colspan="2" class="text-center border-bottom border-warning">
                                <div>{{ $t('Remain') }}</div>                               
                                {{ $t('Amount') }} &nbsp; &nbsp; {{ $t('Balance') }}
                            </th>
                            <th v-if="this.localCurrency !== this.balanceForm.currency">{{ $t('Rate') }}</th>
                            <th class="text-center border-bottom border-primary" colspan="2" v-if="this.localCurrency !== this.balanceForm.currency">
                                <div>{{this.localCurrency}}</div>
                                {{ $t('Remain') }} &nbsp; &nbsp;  {{ $t('Balance') }}
                            </th>
                            <th>{{ $t('Transaction Type') }}</th>
                            <th>
                                <span v-if="this.modalType == 'addItem' || isBalanceToRefundExist == 0">{{ $t('Closing Amount') }}</span>
                                <span v-else>{{ $t('Closed Amount') }}</span>
                            </th>
                            <th>{{ $t('Installment No') }}</th>
                            <th>{{ $t('Transaction No') }}</th>
                            <th>{{ $t('Description') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-bottom text-gray-800" v-for="item in balanceClosingFilteredList" v-bind:key="item" :class="{'bg-light-primary': item.isSelected && !item.isBalanceToRefundExist, 'bg-gray-300': item.isBalanceToRefundExist}">
                            <td class="ps-3">
                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center my-0 py-0" v-if="!item.isBalanceToRefundExist">
                                    <input class="form-check-input" type="checkbox" v-model="item.isSelected" @change="onChangeIsSelected(item)" 
                                    :disabled="balanceForm.isAutomaticClosing || this.form.amount == 0 || (item.isSelected == false && getClosingRemainAmount == 0 && this.form.amount == getClosingAmountTotal)" />
                                </div>
                            </td>
                            <td class="ps-3 text-end">
                                {{ previewDate(item.transaction_due_date) }}
                            </td>
                            <td class="ps-3">{{ item.currency }}</td>
                            <td class="ps-3 text-end">{{ item.amount }}</td>
                            <td class="ps-3 text-end">{{ item.used_amount }}</td>
                            <td class="ps-3 text-end bg-light-warning border-bottom border-warning">{{ item.remain_amount }}</td>
                            <td class="ps-3 text-end bg-light-warning border-bottom border-warning">{{ this.$globalParseFloat(item.remain_balance) }}</td>
                            <td v-if="this.localCurrency !== this.balanceForm.currency" class="ps-3 text-end">{{ item.rate }}</td>
                            <td v-if="this.localCurrency !== this.balanceForm.currency" class="ps-3 text-end bg-light-primary border-bottom border-primary">{{ item.remain_local_amount }}</td>
                            <td v-if="this.localCurrency !== this.balanceForm.currency" class="ps-3 text-end bg-light-primary border-bottom border-primary">{{ this.$globalParseFloat(item.remain_balance_local) }}</td>
                            <td class="ps-3">{{ item.transaction_type_name }}</td>
                            <td class="ps-3 py-2">
                                <div class="input-group">                                    
                                    <CurrencyInput v-if="balanceForm.isAutomaticClosing" type="text" class="form-control text-end" name="local_amount" v-model="item.closing_amount_input" @focus="$event.target.select()" 
                                    :max="item.isBalanceToRefundExist ? item.closing_amount : parseFloat(item.remain_amount.replaceAll(',', ''))" @blur="calculateRemainAmount(item)" disabled />

                                    <CurrencyInput v-if="!balanceForm.isAutomaticClosing" type="text" class="form-control text-end" name="local_amount" v-model="item.closing_amount_input" @focus="$event.target.select()" 
                                    :max="item.isBalanceToRefundExist ? item.closing_amount : parseFloat(item.remain_amount.replaceAll(',', ''))" @blur="calculateRemainAmount(item)" :disabled="!item.isClosingAmountActive || this.form.amount == 0" />
                                    <span class="input-group-text p-1" v-if="!balanceForm.isAutomaticClosing && item.isSelected">
                                        <button type="button" class="btn btn-light btn-xs p-1" @click="item.isClosingAmountActive = !item.isClosingAmountActive">
                                            <i class="bi bi-pencil" :class="{'text-dark': !item.isClosingAmountActive, 'text-primary': item.isClosingAmountActive}"></i>
                                        </button>
                                    </span>
                                </div>
                            </td>
                            <td class="ps-3 text-center">{{ item.installment_no }}</td>
                            <td class="ps-3">{{ item.transaction_no }}</td>
                            <td class="ps-3">{{ item.description }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" v-if="!isListLoading && this.$parent.balanceClosingList.length == 0">
                <div class="col-lg-12">
                    <div class="alert alert-primary d-flex align-items-center m-3">
                        <div class="d-flex flex-column">
                            <span class="text-primary">No record found...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="col-lg-12 px-0 mt-5">
            <div class="alert alert-danger d-flex align-items-center p-3 mb-5" v-if="getClosingRemainAmount < 0 || getClosingAmountTotal > this.form.amount">
                <i class="bi bi-exclamation-triangle fs-2x text-danger me-4"></i>
                <div class="d-flex flex-column">
                    <h5 class="mb-1 text-danger">{{ $t('Check the amounts') }}!</h5>
                    <span> {{ $t('Closing Total cannot be more than the transaction amount. Amounts will be automatically adjusted according to the balance that can be closing. Make sure the amounts are correct before saving.') }} </span>
                </div>
            </div>

            <div class="card">
                <div class="d-grid gap-2">
                    <button v-if="!isManualOperation" type="button" class="btn btn-primary btn-lg" @click="this.returnToTransaction()" :disabled="getClosingRemainAmount < 0 || getClosingAmountTotal > this.form.amount">
                        <strong>{{ $t('Return to Transaction') }}</strong> 
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import moment from 'moment';
import { DxDataGrid, DxColumn, DxSummary, DxTotalItem, DxScrolling } from 'devextreme-vue/data-grid';

export default {
    name: "BalanceClosingList",
    props: {
        isManualOperation: Boolean,
        modalType: String,
        activeTab: String
    },  
    components:{
        DxDataGrid, DxColumn, DxSummary, DxTotalItem, DxScrolling
    },
  data() {
    return {
        currencyList: [],
        accountBalanceList: [],
        form: {
            account_id: null,
            id: null,
            amount: 0,
            item_type: 0,
            docket_type: 0
        },
        balanceForm: {
            currency: '-1',
            transaction_type: 0,
            isAutomaticClosing: false,
            balance_closing_option: 1,
            end_due_date: moment().add(1, 'months').endOf('month'),
            isShowAll: true,
            item_type: 1
        },
        localCurrency: this.$getUser().currency_code,
        transactionTypeList: [],
        isListLoading: false,
        isRefundedBalanceClosing: false,
        summary: {
            total_average_due_state: 0,
            total_average_due_date: null
        }
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
        if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    getClosingTotalFormatted() {
        let sum = 0;
        this.$parent.balanceClosingList.map(x => {
            if(x.isSelected) sum += x.closing_amount_input
        });
        return this.$globalParseFloat(sum);
    },
    getClosingRemainTotalFormatted(){
        let closingRemainTotal = 0;
        let sum = 0;
        this.$parent.balanceClosingList.map(x => {
            if(x.isSelected) sum += x.closing_amount_input
        });
        closingRemainTotal = this.form.amount - sum;
        return this.$globalParseFloat(closingRemainTotal);
    },
    getClosingAmountTotal() {
        let sum = 0;
        this.$parent.balanceClosingList.map(x => {
            if(x.isSelected) sum += x.closing_amount_input
        });
        return parseFloat(sum.toFixed(2));
    },
    getRemainAmountFormatted() {
        let sum = 0;
        this.$parent.balanceClosingList.map(x => {
            sum += parseFloat(x.remain_amount.replaceAll(',', ''))
        });
        return this.$globalParseFloat(sum);
    },
    getClosingRemainAmount() {
        let sum = 0;
        this.$parent.balanceClosingList.map(x => {
            if(x.isSelected) sum += x.closing_amount_input
        });
        return parseFloat((this.form.amount - sum).toFixed(2));
    },    
    isBalanceToRefundExist() {
        return this.$parent.balanceClosingList.filter(p => { 
            return p.isBalanceToRefundExist == true;
        }).length;
    },
    balanceClosingFilteredList() {
        if(!this.isManualOperation && this.modalType == 'editItem' && this.isBalanceToRefundExist > 0 && !this.isRefundedBalanceClosing) {
            return this.$parent.balanceClosingList.filter(p => {
                return p.isSelected == true;
            });
        } else {
            return this.$parent.balanceClosingList;
        }
    },
  },
  methods: {
    getData(isAutoCalculated) {
        this.balanceForm = {
            currency: '-1',
            transaction_type: '0',
            isAutomaticClosing: false,
            balance_closing_option: 1,
            end_due_date: moment().add(1, 'months').endOf('month'),
            isShowAll: true,
            item_type: this.form.item_type
        };
        this.isRefundedBalanceClosing = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            tip: 2 //Transaction Types
        };
        const parametersTwo = {
            session_id: this.$getUser().session_id,
            account_id: this.form.account_id
        };
        const parametersThree = {
            session_id: this.$getUser().session_id
        };
        const requestOne =  axios.post('/api/Helper/GetConst', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetAccountCurrencyBalances', parametersTwo, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/CompanyAccounts/GetBalanceClosingParameters', parametersThree, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/Currency/GetList', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
            this.transactionTypeList = responses[0].data;
            this.accountBalanceList = responses[1].data.balance;
            this.summary = responses[1].data.summary;
            this.transactionTypeList.unshift({ id: '0', text: 'ALL'});
            this.balanceForm.balance_closing_option = this.balanceForm.item_type == 1 ? responses[2].data.balance_closing_option : responses[2].data.payment_balance_closing_option;
            this.balanceForm.isAutomaticClosing = this.balanceForm.balance_closing_option == 1 || this.balanceForm.balance_closing_option == 2 ? true : false;
            this.currencyList = responses[3].data;
            if(isAutoCalculated) this.onGetBalanceList(true);
        }))
        .catch(errors => { 
            console.error(errors); 
        }); 
    },
    onGetBalanceList(isAuto) {
        this.isListLoading = true;
        this.$parent.balanceClosingList = [];
        this.$parent.isDocketItemSaving = true;
        this.balanceForm.item_type = this.form.item_type;
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.form.account_id,
            transaction_type: this.balanceForm.transaction_type,
            currency: this.balanceForm.currency,
            bc_type: this.balanceForm.item_type,
            docket_item_id: this.form.id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            end_due_date: moment(this.balanceForm.end_due_date).format('YYYY-MM-DD'),
            is_show_all: this.balanceForm.isShowAll ? 1 : 0,
            is_refunded: this.modalType == 'editItem' && this.isBalanceToRefundExist > 0 && !this.isRefundedBalanceClosing ? 0 : 1
        };
        const that = this;
        axios.post('/api/BalanceClosing/GetBalancesToClose', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.$parent.balanceClosingList = response.data;
            if(this.$parent.balanceClosingList.length > 0) {
                for (let index = 1; index < this.$parent.balanceClosingList.length; index++) {                
                    const element = this.$parent.balanceClosingList[index];
                    const prevElement = this.$parent.balanceClosingList[index-1];
                    element.remain_balance = (parseFloat(element.remain_amount.replaceAll(',', '')) + prevElement.remain_balance);
                    element.remain_balance_local = (parseFloat(element.remain_local_amount.replaceAll(',', '')) + prevElement.remain_balance_local);
                }
            }
            if(isAuto || (this.modalType == 'editItem' && this.isBalanceToRefundExist == 0)) {
                this.onCalculateClosingBalance();
            } else {
                this.$parent.balanceClosingList.forEach(element => {
                    if(element.isSelected) { element.closing_amount_input = element.closing_amount; }
                });
            }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function() {
            that.isListLoading = false;
            that.$parent.isDocketItemSaving = false;
        });
    },
    onCalculateClosingBalance() {
        let balanceToUse = 0;
        let calculatedAmount = 0;
        let totalClosingAmount = parseFloat(this.form.amount);
        for (let index = 0; parseFloat(balanceToUse.toFixed(2)) < totalClosingAmount && this.$parent.balanceClosingList.length > 0; index++) {
            const element = this.$parent.balanceClosingList[index];
            if(element == null || element == undefined || element == '') return;

            calculatedAmount += element.closing_amount;
            if(calculatedAmount > totalClosingAmount) {
                element.closing_amount =  element.closing_amount - (calculatedAmount - totalClosingAmount);
            }
            balanceToUse += element.closing_amount;
            element.closing_amount_input = element.closing_amount;
            let closing_remain = parseFloat(element.remain_amount.replaceAll(',', '')) - element.closing_amount_input;
            element.closing_remain_amount = parseFloat(closing_remain.toFixed(2));
            element.isSelected = true;
        }
    },
    setDetailSummaries(options){  
        if(options.name == "total_balance_local"){
            if(options.summaryProcess === 'start'){options.totalValue = 0;}
            else if(options.summaryProcess === 'calculate'){
                var amountLocalValue = parseFloat(options.value.total_balance_local.replaceAll(',', ''));
                (options.totalValue += amountLocalValue);
            }
            else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);}
        }
        if(options.name == "average_due_state") { options.totalValue = this.summary.total_average_due_state } 
    },
    clearBalanceForm() {
        this.balanceForm = {
            currency: '-1',
            transaction_type: '0',
            isAutomaticClosing: false,
            balance_closing_option: 1,
            end_due_date: moment().add(1, 'months').endOf('month'),
        };
    },
    onChangeIsSelected(item) {
        if(item.isSelected) {
            if(item.closing_amount_input == 0) {
                let totalRemainAmount = this.getClosingRemainAmount;
                let closingRemain = parseFloat(item.closing_remain_amount);
                if(closingRemain > totalRemainAmount && totalRemainAmount > 0) {
                    item.closing_amount_input = totalRemainAmount;
                    item.closing_remain_amount = closingRemain - totalRemainAmount;
                } else {
                    item.closing_amount_input = closingRemain;
                }
            }
        } else {
            item.closing_amount_input = 0;
            let closing_remain = parseFloat(item.remain_amount.replaceAll(',', '')) - parseFloat(item.closing_amount_input);
            item.closing_remain_amount = parseFloat(closing_remain.toFixed(2));
            item.isClosingAmountActive = false;
        }
    },
    calculateRemainAmount(item) {
        let closingAmount = this.getClosingAmountTotal;
        let closingRemainAmount = this.getClosingRemainAmount;
        if(item.isSelected) {
            if(closingRemainAmount < 0 || closingAmount > this.form.amount) {
                item.closing_amount_input = item.closing_amount_input - Math.abs(closingRemainAmount);
            }
            let closing_remain = parseFloat(item.remain_amount.replaceAll(',', '')) - parseFloat(item.closing_amount_input);
            item.closing_remain_amount = parseFloat(closing_remain.toFixed(2));
        }
    },
    onRefundBalanceClosing() {
        this.$swal.fire({
            title: "Are you sure you want to refund this balance closing?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    item_type: this.balanceForm.item_type,
                    docket_item_id: this.form.id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    transaction_type: this.form.docket_type !== undefined && this.form.docket_type !== null ? this.form.docket_type : 0
                };
                const apiUrl = this.isManualOperation ? '/api/BalanceClosing/RefundBalanceClosingOperation' : '/api/BalanceClosing/RefundBalanceClosing';
                axios.post(apiUrl, parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.isRefundedBalanceClosing = true;
                        this.onGetBalanceList(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
    },
    returnToTransaction() {
        this.$parent.activeTab = 'Transaction';
    }
  }
};
</script>