<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Session Management') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>      
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserManagement' }">{{ $t('User Management') }}</router-link></li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Session Management') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>   
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/UserManagement">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to User Management') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="alert alert-warning d-flex align-items-center p-5 mb-10">
                        <span class="svg-icon svg-icon-muted svg-icon-2hx">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(-1 0 0 1 15.5 11)" fill="currentColor"/>
                            <path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="currentColor"/>
                            <path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <div class="d-flex flex-column">
                            <h4 class="mb-1 text-warning">{{ $t('Terminate all user sessions') }}</h4>
                            <span>{{ $t('If you click the button, all active user sessions of this company will be logged out.') }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12 p-0">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-danger" @click="onTerminateAllSessions()"><i class="fas fa-envelope-open-text fs-4 me-2"></i> {{ $t('Terminate All User Sessions') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'; 
export default {
  name: "SessionManagement", 
  data() {
    return {
      menuSelection: '/SessionManagementHelppage',
      iconView : false
    };
  },
  methods: {
    onTerminateAllSessions(){
        this.$swal.fire({
            title: "Are you sure you want to terminate all user sessions?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const params = {
                    session_id: this.$getUser().session_id
                };  
                axios.post('/api/Users/TerminateAllSessions', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$swal("Success", "Login sessions terminated succesfully..!", 'success');  
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
      },
      async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/CostsManagementHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
    }, 
    created() {
        this.helpPageViewIcon();
    }
}; 
</script>