<template>
  <div>     
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('User Profile') }}</h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600">{{ $t('User Profile') }}</li>  
              <li class="breadcrumb-item text-gray-600">{{ $t('Update User Profile') }}
                  <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
              </li> 
          </ul> 
      </div>
    </div>

    <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start">
      <div class="content flex-row-fluid" id="kt_content">
        <div class="card mb-5 mb-xl-10">
          <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap">
              <div class="me-7 mb-4">
                  <div class="symbol symbol-75px symbol-fixed position-relative">
                    <img src="/assets/theme/media/avatars/blank.png" alt="image">
                  </div>
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{form.name_surname}}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                      <a href="#"
                          class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <span class="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        {{form.user_mail}}
                      </a>
                      <a href="#"
                          class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <span class="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"/>
                          <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"/>
                          </svg>
                        </span>
                        {{form.phone_country_code}} {{form.phone}}
                      </a>
                    </div>
                  </div>
                  <div class="d-flex my-4">
                      <span class="badge badge-light-warning fw-bolder fs-8 px-2 py-1">{{ $t('Company') }}</span>
                       <span class="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2" v-if="userData.permission_type == 1">{{ $t('Admin') }}</span>
                      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2" v-if="userData.permission_type == 2">{{ $t('Client') }}</span> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card  mb-5 mb-xl-10" v-if="licenceList.length > 0">
          <div class="card-body p-0">
            <div class="px-9 pt-7 card-rounded h-150px w-100 bg-primary">
              <div class="d-flex flex-stack">
                <h3 class="m-0 text-white fw-bold fs-3">{{ $t('User Licence') }}</h3> 
              </div> 
            </div>
            <div class="bg-body shadow-sm card-rounded mx-9 mb-5 px-5 py-5 position-relative z-index-1" style="margin-top: -100px">                    
              <div class="d-flex align-items-center mb-6" v-for="item in licenceList" v-bind:key="item">
                <div class="symbol symbol-45px w-240px me-5">
                    <div class="form-check form-switch form-check-custom mt-5">
                      <label class="form-check-label form-label mt-2" for="isActive">
                          {{ $t('Subscription Payment Status') }}
                          <br>
                          <span class="text-success" v-if="item.payment_type == 1">{{ $t('Active') }}</span>
                          <span class="text-danger" v-if="item.payment_type !== 1">{{ $t('Passive') }}</span>
                      </label> 
                    </div>
                </div>
                <div class="d-flex align-items-center flex-wrap w-100 ms-5">
                  <div class="mb-1 pe-3 flex-grow-1">
                    <span class="fs-8 fw-bold" :class="{'text-warning': item.licence_type == 0, 'text-success': item.licence_type == 1 && item.period_end > 30, 'text-danger': (item.licence_type == 1 && item.period_end < 30) || item.period_end <= 0}">
                      <b class="badge badge-lg fw-bolder bg-body text-dark shadow-sm me-3" v-if="item.parameter_type !== 5 && item.parameter_type !== 3"
                        :class="{'bg-warning': item.licence_type == 0, 
                          'bg-success': item.licence_type == 1 && item.period_end > 30, 
                          'bg-danger': (item.licence_type == 1 && item.period_end < 30) || item.period_end <= 0}">
                        {{item.licence_name}}
                      </b>
                      <b class="badge badge-lg fw-bolder bg-body text-dark shadow-sm me-3 bg-secondary" v-if="item.parameter_type == 5 || item.parameter_type == 3">
                        {{item.licence_name}}
                      </b>

                    <span v-if="item.parameter_type !== 5 && item.parameter_type !== 3 && item.period_end > 0"> {{parseInt(item.period_end)}} {{ $t('days left') }}</span>
                    <span v-if="item.parameter_type !== 5 && item.parameter_type !== 3 && item.period_end < 0"> {{ $t('Expired') }} {{Math.abs(item.period_end)}} {{ $t('days ago') }}</span>
                    <span v-if="item.parameter_type !== 5 && item.parameter_type !== 3 && item.period_end == 0"> {{ $t('Expired today') }}</span>

                    <span v-if="item.parameter_type == 3 && item.accounting_period_end > 0"> {{parseInt(item.accounting_period_end)}} {{ $t('days left') }}</span>
                    <span v-if="item.parameter_type == 3 && item.accounting_period_end < 0"> {{ $t('Expired') }} {{Math.abs(item.accounting_period_end)}} {{ $t('days ago') }}</span>
                    <span v-if="item.parameter_type == 3 && item.accounting_period_end == 0"> {{ $t('Expired today') }}</span>
                    </span>
                  </div> 
                  <div class="d-flex align-items-center" v-if="item.payment_type == 1">
                    <button v-if="item.parameter_type !== 3 && item.parameter_type !== 5" type="button" class="btn btn-danger btn-sm" @click="this.onCancelMainLicenceSubscription(item)">
                      {{ $t('Cancel Main Licence Subscription') }}
                    </button>
                    <button v-if="item.parameter_type == 3 || item.parameter_type == 5" type="button" class="btn btn-light-danger btn-sm" @click="this.onCancelLicenceSubscription(item)">
                      {{ $t('Cancel Monthly Subscription') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-5 mb-xl-10" v-if="userData.permission_type == 1">
            <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Profile Details</h3>
                </div>
            </div>

            <div id="kt_account_settings_profile_details" class="collapse show">
                <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                    novalidate="novalidate">
                    <div class="card-body border-top p-9">
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label required fw-semibold fs-6">{{ $t('Name Surname') }}</label>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Phone') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <div class="input-group">
                              <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                              <input type="text" class="form-control" name="phone" v-model="form.phone">
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Permission Type') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                              <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('User Assignment') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <Select2 v-model="form.user_assignment_id" :options="userAssignments" :settings="{width: '100%'}" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Login Method') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Authentication Type') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Validity Type') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                          </div>
                        </div>
                        <div class="row mb-3" v-if="form.validity_type == 2">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            {{ $t('Validity Start Date') }}                                
                            <small v-if="form.validity_date_start == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                          </label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" 
                              previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                          </div>
                        </div>
                        <div class="row mb-3" v-if="form.validity_type == 2">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            {{ $t('Validity End Date') }}
                            <small v-if="form.validity_date_end == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                          </label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                              <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" 
                                previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />  
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Description') }}</label>
                          <div class="col-lg-8 fv-row fv-plugins-icon-container">
                            <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                          </div>
                        </div>
                    </div>

                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                      <button type="button" class="btn btn-primary" id="kt_user_submit_button" @click="saveData()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                          <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                          </svg>
                        </span>
                        <strong v-if="!this.isProgressing"> {{ $t('Update User') }} </strong> 
                        <span class="indicator-progress" v-if="this.isProgressing">
                          {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                      </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="card  mb-5 mb-xl-10">
          <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
              data-bs-target="#kt_account_signin_method">
              <div class="card-title m-0">
                  <h3 class="fw-bold m-0">{{ $t('Sign-in Method') }}</h3>
              </div>
          </div>

          <div id="kt_account_settings_signin_method" class="collapse show">
              <div class="card-body border-top p-9">
                  <div class="d-flex flex-wrap align-items-center">
                      <div id="kt_signin_email">
                          <div class="fs-6 fw-bold mb-1">{{ $t('Email Address') }}</div>
                          <div class="fw-semibold text-gray-600">{{form.user_mail}}</div>
                      </div>
                      <!-- <div id="kt_signin_email_button" class="ms-auto">
                          <button class="btn btn-light btn-active-light-primary">Change Email</button>
                      </div> -->
                  </div>

                  <div class="separator separator-dashed my-6"></div>

                  <div class="d-flex flex-wrap align-items-center mb-10">
                      <div id="kt_signin_password" v-if="!form.isChangePassword">
                          <div class="fs-6 fw-bold mb-1">{{ $t('Password') }}</div>
                          <div class="fw-semibold text-gray-600">************</div>
                      </div>

                      <div id="kt_signin_password_edit" class="flex-row-fluid" v-if="form.isChangePassword">
                          <form id="kt_signin_change_password" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                              novalidate="novalidate">
                              
                            <div class="row">
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Old Password') }}
                                </label>
                                <div class="position-relative"> 
                                <input id="Old_Password" type="password" class="form-control" placeholder="" name="old_password" maxlength="16" v-model="form.old_password" autocomplete="off"> 
                                  <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="toggleOldPasswordVisibility()">
                                      <i v-if="isHideOld" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                      <i v-if="!isHideOld" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                  </span>
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('New Password') }}
                                </label>
                                <div class="position-relative"> 
                                <input id="Pw_Password" type="password" class="form-control" placeholder="" name="user_password" maxlength="16" v-model="form.user_password" autocomplete="off"
                                  :class="{'border border-success': isPasswordConfirm == true}"> 
                                  <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility()">
                                      <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                      <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                  </span>
                                  <password-meter class="mb-3" @score="onScore" :password="form.user_password"/>
                                </div>
                                <div class="d-flex flex-stack mb-3 ms-1">
                                  <div class="text-gray-500 fw-semibold">
                                    {{ $t('Including 3 of the following') }}:
                                  </div>
                                  <div class="text-gray-500 fw-semibold">
                                    {{ $t('Must Contain') }}:
                                  </div>
                                </div>
                                <div class="d-flex flex-stack mb-3 ms-1">
                                  <div class="text-muted">
                                    <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                                    <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                                    <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                                    <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                                  </div>
                                  <div class="text-muted" v-if="form.user_password !== null && form.user_password !== '' && form.user_password !== undefined">
                                    <span> <i class="fas" :class="form.user_password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    {{ $t('Confirm Password') }}
                                </label>
                                <input type="password" class="form-control" placeholder="" name="confirm_password" maxlength="16" v-model="form.confirm_password" autocomplete="off"
                                  :class="{'border border-success': isPasswordConfirm == true,
                                    'border border-danger': isPasswordConfirm == false}" @paste.prevent> 
                                <small class="text-danger" v-if="isPasswordConfirm == false">
                                  {{ $t('The password and its confirm are not the same') }}
                                </small>
                              </div>
                            </div>

                            <div class="separator separator-dashed my-6"></div>
                            <div class="d-flex">
                                <button id="kt_password_submit" type="button" class="btn btn-primary me-2 px-6" @click="changePassword()" data-kt-indicator="on" :disabled="form.old_password == '' || isPasswordConfirm == false || (form.isChangePassword && passwordScore < 3)">                                      
                                  <strong v-if="!this.isProgressing"> {{ $t('Update Password') }} </strong> 
                                  <span class="indicator-progress" v-if="this.isProgressing">
                                    {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span> 
                                </button>
                                <button id="kt_password_cancel" type="button" class="btn btn-color-gray-500 btn-active-light-primary px-6" @click="form.isChangePassword = false">{{ $t('Cancel') }}</button>
                            </div>
                          </form>
                      </div>
                      <div id="kt_signin_password_button mt-3" class="ms-auto" v-if="!form.isChangePassword">
                          <button class="btn btn-light btn-active-light-primary" @click="onChangePassword()">{{ $t('Change Password') }}</button>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>
  
  <script>
  // Component Alias: `USP001`
  import axios from 'axios';
  import moment from 'moment'; 
  import PasswordMeter from "../../../../scripts/passwordMeter.js";
   
  export default {
    name: "UserProfile",
    components: {
      PasswordMeter
    },
    data() {
      return {
        passwordScore: 0,
        isOpenActionsDropdown: false,
        lists: [],
        userData: this.$getUser(),
        isProgressing: false,
        accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }],
        permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
        loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
        authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
        validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
        userAssignments: [{ id: '-1', text: 'Select an option' }],
        phoneMaskList: [],
        form: { 
            id: null,
            account_type: -1,
            permission_type: 1,
            user_assignment_id: '-1',
            system_account_id: null,
            user_mail: null,
            user_password: '',
            old_password: '',
            name_surname: null,
            phone: null,
            user_description: null,
            login_method : 1,
            authentication_type: 1,
            validity_type: 1,
            validity_date_start: new Date(),
            validity_date_end: new Date(),
            password_validity: -1,
            is_active: 1,
            isActive: true,
            is_owner: 0,
            isOwner: false,
            isChatbiUser: false,
            is_chatbi_user: 0,
            isChangePassword: false,
            confirm_password: '',
            phone_country_code: null
        },
        isHide: true,
        isHideOld: true,
        menuSelection: '/UsersManagementHelppage',
        iconView : false,
        isSubscriptionActive: false,
        licenceList: []
      };
    },
    computed: {
      isPasswordConfirm(){
        if(this.form.isChangePassword) {
          if(this.form.user_password !== null && this.form.user_password !== '') {
            if(this.form.user_password == this.form.confirm_password) return true;
            else return false;
          }
          else return null;
        }
        else { return null;}
      },
      isHasNumber() {
        return /(.*[0-9].*)/g.test(this.form.user_password);
      },
      isHasLowercase() {
        return /(.*[a-z].*)/g.test(this.form.user_password);
      },
      isHasUppercase() {
        return /(.*[A-Z].*)/g.test(this.form.user_password);
      },
      isHasSpecial() {
        return /[^A-Za-z0-9]/g.test(this.form.user_password);
      }
    },
    methods: {
      getData() {
        this.lists = []; 
        this.$setSessionStorage('refreshList', true);
        const parameters = {
          id:  this.$getUser().id,
          session_id: this.$getUser().session_id,
          get_access_status: 0,
          list_type: 18 // User Assignment List: 18
        };  

        const requestOne =  axios.post('/api/Users/GetUser', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'});

        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
          this.form = responses[0].data;
          this.form.user_mail = this.$unlock(this.form.user_mail);
          this.form.name_surname = this.$unlock(this.form.name_surname);
          this.form.phone = this.$unlock(this.form.phone);
          this.form.phone_country_code = this.$unlock(this.form.phone_country_code);
          this.form.user_description = this.$unlock(this.form.user_description);
          this.form.user_password = null;
          this.form.confirm_password = null;
          this.form.isChangePassword = false;
          this.form.user_assignment_id = responses[0].data.user_assignment_id == null || responses[0].data.user_assignment_id == '' ? '-1' : responses[0].data.user_assignment_id;
          this.form.isActive = responses[0].data.is_active == 1 ? true : false;
          this.form.isOwner = responses[0].data.is_owner == 1 ? true : false;
          this.form.isChatbiUser = responses[0].data.is_chatbi_user == 1 ? true : false;
          this.form.user_group_id = responses[0].data.user_group_id == '' ? null : responses[0].data.user_group_id;
          this.userAssignments = responses[1].data; 
          this.userAssignments.unshift({ id: '-1', text:  'Select an option'}); 
          this.phoneMaskList = responses[2].data; 
          this.isSubscriptionActive = this.$getUser().payment_type == 1 && this.$getUser().lic_st == 2 ? true : false;

          if(this.$getUser().is_quick_mode !== 1) {
            this.getSubscriptions();
          }
        }))
        .catch(errors => { 
          console.error(errors); 
        });
    },
    getSubscriptions() {
      const parameters = {
          session_id: this.$getUser().session_id,
      };  
      axios.post('/api/Auth/GetSubscriptions', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
         if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.licenceList = response.data;
         }
      })
      .catch(function (error) {
         console.log(error);
      });
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("Pw_Password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    toggleOldPasswordVisibility() {
      this.isHideOld = !this.isHideOld;
      var x = document.getElementById("Old_Password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    saveData(){
      this.isProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        id: this.form.id,
        permission_type: this.form.permission_type,
        user_mail: this.form.user_mail,
        name_surname: this.form.name_surname,
        phone: this.form.phone,
        user_description: this.form.user_description,
        login_method: this.form.login_method,
        authentication_type: this.form.authentication_type,
        validity_type: this.form.validity_type,
        validity_date_start: this.form.validity_date_start,
        validity_date_end: this.form.validity_date_end,
        password_validity: this.form.password_validity,
        is_active: this.form.isActive == true ? 1 : 0,
        is_owner: this.form.isOwner == true ? 1 : 0,
        is_chatbi_user: this.form.isChatbiUser == true ? 1 : 0,
        user_assignment_id: this.form.user_assignment_id !== '-1' ? this.form.user_assignment_id : null,
        phone_country_code: this.form.phone_country_code,
        is_allow_list_all_data: this.form.is_allow_list_all_data,
        is_allow_export: this.form.is_allow_export,
        is_allow_cancel_accounting_integration: this.form.is_allow_cancel_accounting_integration,
      }; 
      const that = this;
      if (params.validity_type == 2) {
        params.validity_date_start = params.validity_date_start !== null ? moment(params.validity_date_start).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.validity_date_end = params.validity_date_end !== null ? moment(params.validity_date_end).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      }
      axios.post('/api/Users/UpdateUser', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Data saved succesfully..!", 'success'); 
            this.$setSessionStorage('refreshList', true);    
            this.getData();
          }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      },
    changePassword(){
      if(this.form.isChangePassword == true) {
        if(this.form.user_password == null || this.form.user_password == '') return;
        if(this.form.user_password !== this.form.confirm_password) {
          this.$swal("Failed", "The passwords you entered do not match..!", 'error');
          return;
        }
        else if(this.passwordScore < 3) {
          this.$swal("Failed", "Please specify a strong password..!", 'error');
          return;
        }
      }
      this.isProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        id: this.form.id,
        user_mail: this.form.user_mail,
        user_password: this.form.user_password,
        old_password: this.form.old_password
      }; 
      const that = this;
      axios.post('/api/Users/ChangePassword', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Data saved succesfully..!", 'success');
          }
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProgressing = false;
          }, 500);
        });   
      },
      deleteUser() { 
          this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
              const queryParameter = {
                id: this.form.id,
                session_id: this.$getUser().session_id,
              };
              axios.post('/api/Users/DeleteUser', queryParameter, {'Content-Type': 'application/json'})
              .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                  this.$setSessionStorage('refreshList', true);
                  this.$router.push({ name: 'UserList' });
                }
              })
              .catch(function (error) {
                  console.log(error);
              });
            }
          })  
      },      
      onScore(pwScore) {
        this.passwordScore = pwScore.score;
      },
      onChangePassword() {
        this.form.isChangePassword = !this.form.isChangePassword;
        this.form.old_password = '';
        this.form.user_password = '';
        this.form.confirm_password = '';
      },
      async helpPageViewIcon(){
          const queryparameter = {
              session_id : this.$getUser().session_id,
              menu_selection : '/UsersManagementHelppage'
          };
          axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
          .then((response) => { 
              if(!response.data.error){ 
                  this.iconView = response.data;
              }
          })
      }, 
      onCancelLicenceSubscription(item){
        this.$swal.fire({
            title: "Are you sure you want to cancel your subscription?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CANCEL SUBSCRIPTION",
            icon: 'error'
        })
        .then((result) => {
            if (result.isConfirmed) {
              let subIdList = [];
              subIdList.push(item.strsubid);

              const params = {
                session_id: this.$getUser().session_id, 
                transaction_id: item.licence_transaction_id,
                strsubids: subIdList
              };
              axios.post('api/Lic/CancelLicenceSubscriptionRequest', params, {'Content-Type': 'application/json'})
              .then((response) => {
                  if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$signOutWithTimer('Session terminated..!', 'Subscription has been cancelled! </br> Please relogin to your account.');
                  }
              })
              .catch(function (Error) {
                  console.error(Error);
                  this.$swal("A problem occured..!", "Subscription has already been cancelled!", 'error');
                  this.isSubscriptionActive = false;
                  return;
              }); 
            }
        }); 
      },
      onCancelMainLicenceSubscription(item){
        this.$swal.fire({
            title: "Are you sure you want to cancel your main licence subscription? This will also cancel any other uses related to this license. From the next payment period, your access to the application will be restricted.",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CANCEL SUBSCRIPTION",
            icon: 'error'
        })
        .then((result) => {
            if (result.isConfirmed) {
              let subIdList = [];
              this.licenceList.forEach(element => {
                subIdList.push(element.strsubid);
              });

              const params = {
                session_id: this.$getUser().session_id, 
                transaction_id: item.licence_transaction_id,
                strsubids: subIdList
              };
              axios.post('api/Lic/CancelLicenceSubscriptionRequest', params, {'Content-Type': 'application/json'})
              .then((response) => {
                  if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$signOutWithTimer('Session terminated..!', 'Subscription has been cancelled! </br> Please relogin to your account.');
                  }
              })
              .catch(function (Error) {
                  console.error(Error);
                  this.$swal("A problem occured..!", "Subscription has already been cancelled!", 'error');
                  this.isSubscriptionActive = false;
                  return;
              }); 
            }
        }); 
      }
    },
    mounted() {
      this.helpPageViewIcon();
      this.$setSessionStorage('refreshList', true);
      this.getData();
    }
  }; 
  </script>