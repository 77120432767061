<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Rates') }}
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li>    
                <li class="breadcrumb-item text-primary">{{ $t('Financial Reports') }}</li> 
                <li class="breadcrumb-item text-gray-600">{{ $t('Rates') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6 px-7">
                <div class="card-title">                        
                    <div class="fv-row mb-4 row">
                        <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Rate Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.rate_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                         <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Rate Type') }}</span>
                            </label>
                            <Select2 v-model="typeSelection" :options="typeList" :settings="{ width: '100%' }" @select="onChangeRateType($event)"/> 
                        </div>
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <!-- <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> Reset All Criterias </span>
                            </template>
                        </Popper> -->
                        <button v-if="this.$isCredentialActive(75,'I') && this.rateType !== 2" type="button" class="btn btn-light-success border border-success me-3" @click="getLatestRatesFromService()">
                            <span class="svg-icon svg-icon-muted"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"/>
                            <path d="M19 10.4C19 10.3 19 10.2 19 10C19 8.9 18.1 8 17 8H16.9C15.6 6.2 14.6 4.29995 13.9 2.19995C13.3 2.09995 12.6 2 12 2C11.9 2 11.8 2 11.7 2C12.4 4.6 13.5 7.10005 15.1 9.30005C15 9.50005 15 9.7 15 10C15 11.1 15.9 12 17 12C17.1 12 17.3 12 17.4 11.9C18.6 13 19.9 14 21.4 14.8C21.4 14.8 21.5 14.8 21.5 14.9C21.7 14.2 21.8 13.5 21.9 12.7C20.9 12.1 19.9 11.3 19 10.4Z" fill="currentColor"/>
                            <path d="M12 15C11 13.1 10.2 11.2 9.60001 9.19995C9.90001 8.89995 10 8.4 10 8C10 7.1 9.40001 6.39998 8.70001 6.09998C8.40001 4.99998 8.20001 3.90005 8.00001 2.80005C7.30001 3.10005 6.70001 3.40002 6.20001 3.90002C6.40001 4.80002 6.50001 5.6 6.80001 6.5C6.40001 6.9 6.10001 7.4 6.10001 8C6.10001 9 6.80001 9.8 7.80001 10C8.30001 11.6 9.00001 13.2 9.70001 14.7C7.10001 13.2 4.70001 11.5 2.40001 9.5C2.20001 10.3 2.10001 11.1 2.10001 11.9C4.60001 13.9 7.30001 15.7 10.1 17.2C10.2 18.2 11 19 12 19C12.6 20 13.2 20.9 13.9 21.8C14.6 21.7 15.3 21.5 15.9 21.2C15.4 20.5 14.9 19.8 14.4 19.1C15.5 19.5 16.5 19.9 17.6 20.2C18.3 19.8 18.9 19.2 19.4 18.6C17.6 18.1 15.7 17.5 14 16.7C13.9 15.8 13.1 15 12 15Z" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Get Latest Rates from Service') }}
                        </button>
                        <button v-if="this.$isCredentialActive(75,'R')" type="button" class="btn btn-light-primary me-3 border border-primary" @click="getRateList()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div> 
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <table class="table table-rounded table-striped border gy-4 gs-4">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                                    <th style="width: 1% !important;">#</th>
                                    <th>{{ $t('Rate Date') }}</th>
                                    <th>{{ $t('Currency') }}</th>
                                    <th></th>
                                    <th class="text-end">{{ $t('Rate 1') }}</th>
                                    <th class="text-end">{{ $t('Rate 2') }}</th> 
                                    <th class="text-end">{{ $t('Change Value') }}</th> 
                                    <th class="text-end">{{ $t('Change Percent') }}</th> 
                                    <th class="text-end">{{ $t('Date') }}</th>
                                </tr>
                            </thead>
                            <tbody class="fw-bold text-gray-600" v-if="this.lists"> 
                                <tr v-for="(item, index) in searchableList" v-bind:key="item"
                                    :class="{'bg-light': item.is_priority && item.change_percent == '0', 'bg-light-success': item.is_priority && item.change_percent > '0', 'bg-light-danger': item.is_priority && item.change_percent < '0'}"> 
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                          {{ ((this.page - 1) * this.page_size) + index + 1 }}
                                        </span>
                                    </td> 
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                           {{ previewOnlyDate(item.rate_date) }}
                                        </span>
                                    </td>
                                     <td class="text-gray-700">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            <strong> {{ item.currency }} </strong>
                                        </span>
                                    </td>  
                                    <td>
                                        <i class="bi bi-dash-lg fs-5 ms-n1" v-if="item.change_percent == '0'"></i> 
                                        <i class="bi bi-arrow-up fs-5 text-success ms-n1" v-if="item.change_percent > '0'"></i> 
                                        <i class="bi bi-arrow-down fs-5 text-danger ms-n1" v-if="item.change_percent < '0'"></i> 
                                    </td>
                                     <td class="text-end">
                                        <span class="text-gray-800 text-hover-primary mb-1">
                                            <strong> {{ item.rate }} </strong>
                                        </span>
                                    </td>  
                                     <td class="text-end">
                                        <span class="text-gray-800 text-hover-primary mb-1">
                                            <strong> {{ item.rate2 }} </strong>
                                        </span>
                                    </td>  
                                     <td class="text-end">
                                        <span class="mb-1" :class="{'text-success': item.change_value > '0', 'text-danger': item.change_value < '0'}">
                                            {{ item.change_value }}
                                        </span>
                                    </td>  
                                     <td class="text-end">
                                        <span class="badge badge-light fs-base" v-if="item.change_percent == '0'">
                                            {{ item.change_percent }} %
                                        </span>
                                        <span class="badge badge-light-success fs-base" v-if="item.change_percent > '0'">
                                            {{ item.change_percent }} %
                                        </span>
                                        <span class="badge badge-light-danger fs-base" v-if="item.change_percent < '0'">
                                            {{ item.change_percent }} %
                                        </span>
                                    </td>
                                    <td class="text-gray-600 text-end">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                           {{ previewDate(item.created_at) }}
                                        </span>
                                    </td>
                                </tr> 
                            </tbody> 
                            <tbody v-if="!lists || this.lists.length == 0">
                                <tr>
                                    <td colspan="9">
                                        <no-table-results></no-table-results>
                                    </td>
                                </tr>
                            </tbody> 
                            <tfoot class="border-top border-dashed" v-if="this.lists || this.lists.length > 0">
                                <tr> 
                                    <td colspan="9">  
                                        <div class="d-flex w-90px align-items-start justify-content-start justify-content-md-start mb-3">
                                            <select v-model="this.page_size" class="form-select form-select-sm form-select-solid" @change="this.page = 1">
                                                <option>10</option>
                                                <option>20</option>
                                                <option>50</option>
                                                <option>100</option>
                                                <option>200</option>
                                            </select>
                                        </div>
                                        <pagination v-model="page" :records="lists.length" :per-page="this.page_size" @paginate="searchableList"/> 
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div> 
    </div>  
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
 
export default {
  name: "RatesList",
  data() {
    return {
        page: 1, 
        page_size: 50,
        lists: [],
        bankList: [ { id: 1, text: 'European Central Bank'}],
        form:  {
            rate_date: moment(),
            bank_id: 1
        },
        rateType: 1,
        isListProgressing: false,
        menuSelection: '/RatesHelppage',
        iconView : false,
        typeList: [],
        typeSelection: 2
    };
  },
  computed: {
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewOnlyDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    getSourceFromType(){
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        if(source !== null && source !== undefined && source !== ''){
            return source.type;
        }
        else{
            return 0;
        }
    }
  },
  methods: {
    getData(){
        const params = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/Rate/RateTypes', params, {'Content-Type': 'application/json'})
        .then((typeResponse) => {
            this.typeList = typeResponse.data;
            this.typeList.forEach((item) => { 
            if(item.is_default == 1){
                this.typeSelection = item.id;
                this.rateType = item.type;
            }
            });  
        })
        .catch(function (error) {
            console.log(error); 
        });  
    },
    getRateList() {
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.rate_date = (this.form.rate_date == null || this.form.rate_date == '') ? moment() : this.form.rate_date; 
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.form.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            bank_id: 0,
            currency: null
        };
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;
            this.page = 1;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    setCriteriasToDefault() {   
        this.form = {
            rate_date: moment(),
            bank_id: 1
        };
    },
    getLatestRatesFromService() {
        if(this.rateType == 1) {
            this.updateEUData();
        }
        if(this.rateType == 3) {
            this.updateLiveRates();
        }
    },
    updateLiveRates() {
        this.$Progress.start();
        const params = {
            session_id: this.$getUser().session_id,
            currency: this.$getUser().currency_code,
            rate_date: moment(this.form.rate_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };
        axios.post('/api/Rate/CreateLiveRates', params, {'Content-Type': 'application/json'})
        .then(() => { 
            this.getRateList();
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    updateEUData() {
        this.$Progress.start();
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: this.form.rate_date,
            bank_id: 1, //EUROPEAN BANK
        };
        axios.post('/api/Rate/CreateRates', params, {'Content-Type': 'application/json'})
        .then(() => { 
            this.getRateList();
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeRateType(event) {
        this.rateType = event.type;
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/RatesHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  }
}; 
</script>