<template>
   <div> 
       <button type="button" class="btn btn-secondary btn-icon btn-secondary w-30px h-30px mb-3" data-bs-toggle="modal" data-bs-target="#dxstatemodal">
            <i class="bi bi-aspect-ratio"></i>
       </button>

       <!-- Modal: Save or Restore DX DataGrid State Modal -->
       <div class="modal fade" tabindex="-1" id="dxstatemodal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
          <div class="modal-dialog modal-md modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header bg-primary py-3">
                        <h5 class="modal-title text-white">Save/Restore DataGrid State</h5>                         
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                  </div>  
                  <div class="modal-body">
                        <div class="fv-row mb-15">
                            <label class="fs-6 fw-bold form-label mb-2">Name:</label> 
                            <input type="text" v-model="modalForm.state_name" class="form-control form-control-lg"/>
                        </div>
                        <div class="fv-row">
                            <button type="button" class="btn btn-sm btn-success w-100 mt-4" data-bs-dismiss="modal" @click="saveState()" :disabled="this.modalForm.state_name == '' || this.modalForm.state_name == null">Save</button>
                            <div class="separator my-2"></div>
                            <button type="button" class="btn btn-sm btn-danger w-100" @click="deleteState()">Restore to Factory Settings</button>
                        </div>
                  </div>   
                  <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                  </div>
              </div>
          </div>
      </div> 
    </div>
</template>
<script> 
import axios from 'axios';
export default {
    name: "ListOptions",
    props: {
        name: Object,
        state: Object
    },
    data() {
        return {
            isViewListOptionsModal: false,
            modalForm: {
                state_name: null
            }
        };
    },
    methods: {
        getState(){
            const params = {
                session_id: this.$getUser().session_id,
                user_id: this.$getUser().id,
                table_code: this.name
            };
            axios.post("/api/Users/GetGridSession", params, {'Content-Type': 'application/json'}).then((response) => {
                if(response.data.table_code !== null){
                    this.modalForm.state_name = response.data.state_name;
                    //localStorage.setItem('dx_' + response.data.table_code, JSON.stringify(response.data.state_detail));
                } 
            })
            .catch(function (error) {
                console.log(error);
            }); 
        },
        saveState(){
                var stateDetail = localStorage.getItem('dx_' + this.name);
                if(stateDetail !== null && stateDetail !== ''){
                    const params = {
                    session_id: this.$getUser().session_id,
                    user_id: this.$getUser().id,
                    state_detail: JSON.stringify(stateDetail),
                    table_code: this.name,
                    state_name: this.modalForm.state_name
                };
                axios.post("/api/Users/SetGridSession", params, {'Content-Type': 'application/json'}).then(() => {

                })
                .catch(function (error) {
                    console.log(error);
                });  
            } 
        },
        deleteState(){
            const dataGridInstance = this.$parent.$refs.dataGrid.instance;
            const params = {
                session_id: this.$getUser().session_id,
                user_id: this.$getUser().id,
                table_code: this.name
            };
            this.$swal.fire({
                title: "Are you sure you want to restore to factory settings?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post("/api/Users/DeleteGridSession", params, { 'Content-Type': 'application/json' }).then(() => {
                        localStorage.removeItem('dx_' + this.name);
                        dataGridInstance.clearGrouping();
                        dataGridInstance.clearFilter();
                        this.$refs.closeModal.click();
                        this.modalForm.state_name="";
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                }
            })
        },
    }
}; 
</script>