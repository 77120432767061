import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en|en',
    resources: {
      'en|en': {
        translation: {
          'Branding tools designed for your business': 'Branding tools designed for your business'
        }
      } 
    }
  });

export default function (app) {
  app.use(I18NextVue, { i18next })
  return app
}