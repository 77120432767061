<template>
  <div> 
    <div id="kt_header" class="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
      <div class="container-xxl d-flex flex-grow-1 flex-stack">
        <div class="d-flex align-items-center me-5">
          <div class="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3" id="kt_header_menu_toggle">
            <span class="svg-icon svg-icon-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"/>
                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"/>
              </svg>
            </span>
          </div>
          <a href="/">
             <img v-if="userData.company_logo == null || userData.company_logo == '' || userData.company_logo == undefined" alt="Logo" src="assets/theme/media/logos/logo-cc.png" />
             <img  v-if="userData.company_logo != null && userData.company_logo != ''" alt="Logo" :src="userData.company_logo" style="max-width: 200px; max-height:54px;" class="img-fluid"/>
          </a>
        </div>
        <div class="topbar d-flex align-items-stretch flex-shrink-0" id="kt_topbar">
          <div class="d-flex align-items-center ms-1" id="kt_header_user_menu_toggle">
            <div class="d-flex cursor-pointer" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
              <div class="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3">
                <div class="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
                  <span class="text-muted fs-6 fw-bold lh-base">{{getOnlyUserName}}</span>
                  <span class="text-muted fw-semibold fs-8 lh-base"> {{userData.company_name}} </span>
                  
                  <span v-if="userData.is_quick_mode !== 1" class="fs-8 fw-bold" :class="{'text-warning': userData.licence_type == 0, 'text-success': userData.licence_type == 1 && userData.period_end > 30, 'text-danger': (userData.licence_type == 1 && userData.period_end < 30) || userData.period_end <= 0, 'text-muted': userData.licence_type == 1 && userData.period_end == 30 }">
                    <b class="badge badge-sm fw-bolder bg-body text-dark shadow-sm me-3" 
                      :class="{'bg-warning': userData.licence_type == 0, 
                        'bg-success': userData.licence_type == 1 && userData.period_end > 30, 
                        'bg-danger': (userData.licence_type == 1 && userData.period_end < 30) || userData.period_end <= 0}">
                      {{userData.licence_name}}
                    </b>

                    <span v-if="userData.period_end > 0"> {{parseInt(userData.period_end)}} {{ $t('days left') }}</span>
                    <span v-if="userData.period_end < 0"> {{ $t('Expired') }} {{Math.abs(userData.period_end)}} {{ $t('days ago') }}</span>
                    <span v-if="userData.period_end == 0"> {{ $t('Expired today') }}</span>
                  </span>
                </div>

                <div class="symbol symbol-40px symbol-md-50px">
                  <img src="assets/theme/media/avatars/blank.png" alt="user" />
                </div>
            </div>
            </div>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px"
            data-kt-menu="true">
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <div class="symbol symbol-75px me-5">
                    <img alt="Logo" src="assets/theme/media/avatars/blank.png"/>
                  </div>

                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5 text-break">{{ userData.name_surname }} </div>  
                    <a class="fw-bold text-muted text-hover-primary fs-7 text-break">{{ userData.user_mail }}</a>
                    <div class="fw-bolder d-flex align-items-center mt-4">
                      <span class="badge badge-light-warning fw-bolder fs-8 px-2 py-1">{{ $t('Company') }}</span>
                       <span class="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2" v-if="userData.permission_type == 1">{{ $t('Admin') }}</span>
                      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2" v-if="userData.permission_type == 2">{{ $t('Client') }}</span> 
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="separator my-3" v-if="this.$isAdmin() && userData.is_pending_licence_exist == 0 && userData.is_quick_mode !== 1"></div>
              <span class="w-100 d-grid gap-2 px-5 fs-7 text-center" v-if="this.$isAdmin() && userData.is_pending_licence_exist == 0 && userData.is_quick_mode !== 1">
                <a href="#" class="btn btn-sm btn-warning text-dark fw-bolder py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan_header" @click="openUpgradeModal()">
                    <span class="svg-icon svg-icon-2 text-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                        <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                        </svg>
                    </span>
                    {{ $t('Upgrade your plan') }}
                </a>
              </span>
              <div class="separator my-3"></div>
              <div class="menu-item px-5">
                <router-link :to="{ name: 'UserProfile' }">
                  <a href="" class="menu-link px-5">{{ $t('User Profile') }}</a>
                </router-link>
              </div>
              <div class="separator my-2"></div>
              <div class="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                <a href="#" class="menu-link px-5">
                    <span class="menu-title position-relative">
                      Mode
                      <span class="ms-5 position-absolute translate-middle-y top-50 end-0">
                        <i class="bi bi-brightness-high-fill theme-light-show"></i>
                        <i class="bi bi-moon-stars-fill theme-dark-show"></i>
                      </span>
                    </span>
                </a>
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                    data-kt-menu="true" data-kt-element="theme-mode-menu" style="">
                    <div class="menu-item px-3 my-0">
                        <a href="#" class="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light" @click="changeTheme('light')">
                            <span class="menu-icon" data-kt-element="icon">
                              <i class="bi bi-brightness-high-fill"></i>
                            </span>
                            <span class="menu-title">
                              Light
                            </span>
                        </a>
                    </div>
                    <div class="menu-item px-3 my-0">
                        <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark" @click="changeTheme('dark')">
                            <span class="menu-icon" data-kt-element="icon">
                              <i class="bi bi-moon-stars-fill"></i>
                            </span>
                            <span class="menu-title">
                              Dark
                            </span>
                        </a>
                    </div>
                    <div class="menu-item px-3 my-0">
                      <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system" @click="changeTheme()">
                          <span class="menu-icon" data-kt-element="icon">
                            <i class="bi bi-display"></i>
                          </span>
                          <span class="menu-title">
                            System
                          </span>
                      </a>
                    </div>
                </div>
              </div>
              <div class="separator my-2"></div> 
              <div class="menu-item px-5">
                <a href="" class="menu-link px-5" @click="signOut()">{{ $t('Sign Out') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <div v-if="userData.period_end > 0 && userData.is_quick_mode !== 1" class="header-menu-container d-flex align-items-stretch flex-stack h-lg-55px w-100" id="kt_header_nav" style="border-bottom: 2px solid #F1416C;">
        <div class="header-menu container-xxl flex-column align-items-stretch flex-lg-row"
          data-kt-drawer="true"
          data-kt-drawer-name="header-menu"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction="start"
          data-kt-drawer-toggle="#kt_header_menu_toggle"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
          <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu"
            data-kt-menu="true">
            <div class="menu menu-item me-lg-1">
              <router-link :to="{ name: 'Home' }" class="menu-item">
                <span class="menu-link py-3">
                    <span class="menu-title"> 
                      <span class="svg-icon svg-icon-3 svg-icon-gray-300 me-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="currentColor"/>
                        </svg>
                      </span>
                      {{ $t('Home') }}
                    </span>
                  <span class="menu-arrow d-lg-none"></span>
                </span> 
              </router-link>
            </div>  
            <div v-if="this.$isCredentialActive(1,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2">
              <span class="menu-link py-3">
                <span class="menu-title">{{ $t('Accounts') }}</span>
                <span class="menu-arrow d-lg-none"></span>
              </span> 
              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-600px" style="">
                <div class="menu-state-bg menu-extended"> 
                  <div class="row">
                    <div class="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                      <div class="row">
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(10,'')">
                          <router-link :to="{ name: 'CustomerList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor"/>
                                    <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor"/>
                                    <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor"/>
                                    <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor"/>
                                </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Customer Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage your customer') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(11,'')">
                          <router-link :to="{ name: 'SupplierList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-indigo svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="currentColor"/>
                                  <path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Supplier') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage your supplier') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(12,'')">
                          <router-link :to="{ name: 'CashList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="currentColor"/>
                                  <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Cash Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage cash account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(13,'')">
                          <router-link :to="{ name: 'BankList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-gray svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Bank Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage bank account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(14,'')">
                          <router-link :to="{ name: 'PersonnelList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-muted svg-icon-2hx">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"/>
                                    <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"/>
                                    <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"/>
                                  </svg>
                              </span> 
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Personnel Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage personnel account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(15,'')">
                          <router-link :to="{ name: 'ExpenseList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-info svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"/>
                                  <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Expense Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage expense account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(16,'')">
                          <router-link :to="{ name: 'StockList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-warning svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z" fill="currentColor"/>
                                  <path d="M7 16H6C5.4 16 5 15.6 5 15V13H8V15C8 15.6 7.6 16 7 16Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z" fill="currentColor"/>
                                  <path d="M18 16H17C16.4 16 16 15.6 16 15V13H19V15C19 15.6 18.6 16 18 16Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z" fill="currentColor"/>
                                  <path d="M7 5H6C5.4 5 5 4.6 5 4V2H8V4C8 4.6 7.6 5 7 5Z" fill="currentColor"/>
                                  <path opacity="0.3" d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z" fill="currentColor"/>
                                  <path d="M18 5H17C16.4 5 16 4.6 16 4V2H19V4C19 4.6 18.6 5 18 5Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Stock & Service Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage stock and service account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(17,'')">
                          <router-link :to="{ name: 'FixedAssetList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor"/>
                                    <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Fixed Asset Account') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage fixed asset account') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(18,'')">
                          <router-link :to="{ name: 'OtherAccountList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-muted svg-icon-2hx">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor"/>
                                    <rect x="17" y="10" width="4" height="4" rx="2" fill="currentColor"/>
                                    <rect x="3" y="10" width="4" height="4" rx="2" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Other Accounts') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage other accounts') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                        <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(19,'')">
                          <router-link :to="{ name: 'ChequeAccountList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-cyan svg-icon-2hx">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="currentColor"/>
                                    <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="currentColor"/>
                                    <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Cheque Account List') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('Manage cheque accounts.') }}</span>
                              </span>
                            </a>
                          </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="separator separator-dashed mx-5 my-5"></div>
                      <div class="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
                        <div class="d-flex flex-column me-5">
                          <div class="fs-6 fw-bold text-gray-800">{{ $t('Do you need to configure your workspace?') }}</div>
                          <div class="fs-7 fw-semibold text-muted">{{ $t('Click here to access company settings.') }}</div>
                        </div>
                        <router-link :to="{ name: 'Settings' }">
                        <a href="" class="btn btn-sm btn-light btn-active-primary fw-bold">{{ $t('General Settings') }}</a>
                        </router-link>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div>
						</div>
            <div v-if="this.$isCredentialActive(2,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2">
              <span class="menu-link py-3">
                <span class="menu-title">{{ $t('Transactions') }}</span>
                <span class="menu-arrow d-lg-none"></span>
              </span> 
              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-650px" style="">
                  <div class="menu-state-bg menu-extended"> 
                      <div class="row">
                          <div class="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                              <div class="row">
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(20,'')">
                                    <router-link :to="{ name: 'InvoiceCreate' }">
                                      <div class="menu-item p-0 m-0">
                                        <a href="" class="menu-link">
                                          <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                            <span class="svg-icon svg-icon-primary svg-icon-2hx">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="currentColor"/>
                                              <path d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                          </span>
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Invoice Transaction') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Invoice Transactions') }}</span>
                                          </span>
                                        </a>
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(21,'')">
                                    <router-link :to="{ name: 'CashTransactionCreate' }">
                                      <div class="menu-item p-0 m-0">
                                        <a href="" class="menu-link">
                                          <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                            <span class="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="currentColor"/>
                                              <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                          </span>
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Cash Transaction') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('Manage your Cash Transactions') }}</span>
                                          </span>
                                          </a>
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(22,'')">
                                    <router-link :to="{ name: 'BankTransactionCreate' }">
                                      <div class="menu-item p-0 m-0">
                                          <a href="" class="menu-link">
                                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                <span class="svg-icon svg-icon-gray svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="currentColor"/>
                                                  <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="currentColor"/>
                                                  </svg>
                                                </span>
                                              </span>
                                              <span class="d-flex flex-column">
                                                  <span class="fs-6 fw-bold text-gray-800">{{ $t('Bank Transaction') }}</span>
                                                  <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Bank Transactions') }}</span>
                                              </span>
                                          </a>
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(23,'')">
                                    <router-link :to="{ name: 'ExpenseTransactionCreate' }">
                                      <div class="menu-item p-0 m-0">
                                          <a href="" class="menu-link">
                                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                  <span class="svg-icon svg-icon-info svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"/>
                                                    <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"/>
                                                    <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"/>
                                                    </svg>
                                                  </span>
                                              </span>
                                              <span class="d-flex flex-column">
                                                  <span class="fs-6 fw-bold text-gray-800">{{ $t('Expense Transaction') }}</span>
                                                  <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Expense Transactions') }}</span>
                                              </span>
                                          </a>
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(24,'')">
                                    <router-link :to="{ name: 'OffsettingCreate' }">
                                      <div class="menu-item p-0 m-0">
                                        <a href="" class="menu-link">
                                          <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                            <span class="svg-icon svg-icon-warning svg-icon-2hx "><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"/>
                                              <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"/>
                                              <path d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                          </span>
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Offsetting') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Offsetting') }}</span>
                                          </span>
                                        </a>
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(25,'')">
                                    <div data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                                      <span class="menu-link py-3">
                                        <span class="menu-icon w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M20 18H4C3.4 18 3 17.6 3 17V7C3 6.4 3.4 6 4 6H20C20.6 6 21 6.4 21 7V17C21 17.6 20.6 18 20 18ZM12 8C10.3 8 9 9.8 9 12C9 14.2 10.3 16 12 16C13.7 16 15 14.2 15 12C15 9.8 13.7 8 12 8Z" fill="currentColor"/>
                                              <path d="M18 6H20C20.6 6 21 6.4 21 7V9C19.3 9 18 7.7 18 6ZM6 6H4C3.4 6 3 6.4 3 7V9C4.7 9 6 7.7 6 6ZM21 17V15C19.3 15 18 16.3 18 18H20C20.6 18 21 17.6 21 17ZM3 15V17C3 17.6 3.4 18 4 18H6C6 16.3 4.7 15 3 15Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                        </span>
                                        <span class="menu-title">
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Cheque/Card Transactions') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('View Cheque/Card Transactions') }}</span>
                                          </span> 
                                        </span>
                                        <span class="menu-arrow"></span>
                                      </span>
                                      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-350px" style="">
                                        <div class="menu-item" v-if="this.$isCredentialActive(38,'')">
                                          <router-link :to="{ name: 'ChequeTransactionCreate' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Incoming/Outgoing Cheque/Card') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(39,'')">
                                          <router-link :to="{ name: 'ChequeOperationsCreate' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Cheque/Card Operations') }}</span>
                                            </a>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="separator separator-dashed mx-5 my-5" v-if="this.$isCredentialActive(26,'')"></div>
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(26,'')">
                                    <router-link :to="{ name: 'BalanceClosingOperations' }">
                                      <div class="menu-item p-0 m-0">
                                        <a href="" class="menu-link">
                                          <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                            <span class="svg-icon svg-icon-primary svg-icon-2hx">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 20C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20H6ZM18 20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20H18Z" fill="currentColor"/>
                                                <path opacity="0.3" d="M21 20H3C2.4 20 2 19.6 2 19V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V19C22 19.6 21.6 20 21 20ZM12 10H10.7C10.5 9.7 10.3 9.50005 10 9.30005V8C10 7.4 9.6 7 9 7C8.4 7 8 7.4 8 8V9.30005C7.7 9.50005 7.5 9.7 7.3 10H6C5.4 10 5 10.4 5 11C5 11.6 5.4 12 6 12H7.3C7.5 12.3 7.7 12.5 8 12.7V14C8 14.6 8.4 15 9 15C9.6 15 10 14.6 10 14V12.7C10.3 12.5 10.5 12.3 10.7 12H12C12.6 12 13 11.6 13 11C13 10.4 12.6 10 12 10Z" fill="currentColor"/>
                                                <path d="M18.5 11C18.5 10.2 17.8 9.5 17 9.5C16.2 9.5 15.5 10.2 15.5 11C15.5 11.4 15.7 11.8 16 12.1V13C16 13.6 16.4 14 17 14C17.6 14 18 13.6 18 13V12.1C18.3 11.8 18.5 11.4 18.5 11Z" fill="currentColor"/>
                                              </svg>
                                            </span>
                                          </span>
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Balance Closing Operations') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Balance Closing Operations') }}</span>
                                          </span>
                                        </a>
                                      </div>
                                    </router-link>
                                  </div>
                              </div>
                          </div>
                      </div> 
                  </div> 
              </div>
            </div>
            <div v-if="userData.is_insurance_module_active == 1 && this.$isCredentialActive(3,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2">
              <span class="menu-link py-3">
                  <span class="menu-title">{{ $t('Insurance') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
              </span> 
              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-650px" style="">
                  <div class="menu-state-bg menu-extended"> 
                      <div class="row">
                          <div class="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                              <div class="row">
                                  <div class="col-lg-6 mb-3" v-if="this.$isCredentialActive(48,'')">
                                    <router-link :to="{ name: 'PolicyList' }">
                                        <div class="menu-item p-0 m-0">
                                            <a href="" class="menu-link">
                                                <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                                    <span class="svg-icon svg-icon-primary svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="currentColor"/>
                                                      <path d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z" fill="currentColor"/>
                                                      </svg>
                                                    </span>
                                                </span>
                                                <span class="d-flex flex-column">
                                                    <span class="fs-6 fw-bold text-gray-800">{{ $t('Policies') }}</span>
                                                    <span class="fs-7 fw-semibold text-muted">{{ $t('Manage Policies') }}</span>
                                                </span>
                                            </a>
                                        </div>
                                    </router-link>
                                  </div>
                              </div>
                          </div>
                      </div> 
                  </div> 
              </div>
            </div>
            <div v-if="userData.is_v_ac && this.$isCredentialActive(4,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2" :class="{'pe-none': userData.is_accounting_info_required == 1}">
              <span class="menu-link py-3">
                <span class="menu-title">{{ $t('Accounting') }}
                  <i class="bi bi-exclamation-circle text-danger ms-1 fs-4" v-if="userData.is_accounting_info_required == 1"></i>
                  <Popper placement="top" hover>
                    <span class="badge badge-sm badge-circle badge-warning ms-1" v-if="userData.package_type == 3 && userData.accounting_period_end < 10">
                      <i class="bi bi-exclamation fs-4 fw-boldest text-dark"></i>
                    </span>
                    <template #content>
                      <span class="text-gray-800"> {{ $t('Your accounting module will expire within') }} <b class="text-danger">{{userData.accounting_period_end}}</b> {{ $t('days') }}. 
                        {{ $t('To continue using this module, you can add Accounting Module to your plan from the Upgrade Licence modal.') }}</span>
                    </template>
                  </Popper>
                </span>
                <span class="menu-arrow d-lg-none"></span>
              </span>
              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-300px" style="">
                  <div class="menu-state-bg menu-extended"> 
                      <div class="row">
                          <div class="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                              <div class="row">
                                  <div class="col-lg-12 mb-3">
                                    <div v-if="this.$isCredentialActive(49,'')" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                                      <span class="menu-link py-3">
                                        <span class="menu-icon w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-danger svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor"/>
                                            <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </span>
                                        <span class="menu-title">
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Accounting Transaction') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('View Accounting Transactions') }}</span>
                                          </span> 
                                        </span>
                                        <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                                      </span>
                                      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                                        <div class="menu-item" v-if="this.$isCredentialActive(52,'')">
                                          <router-link :to="{ name: 'AccountingVoucherList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Accounting Voucher Operations') }}</span>
                                            </a>
                                          </router-link>
                                        </div>
                                        <div class="menu-item" v-if="this.$isCredentialActive(53,'')">
                                          <router-link :to="{ name: 'VATOperationsList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('VAT Operations') }} </span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(54,'')">
                                          <router-link :to="{ name: 'OpeningOperationsList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Opening Operations') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(55,'')">
                                          <router-link :to="{ name: 'ProfitAndLossOperationsList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Profit & Loss Operations') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(56,'')">
                                          <router-link :to="{ name: 'ClosingOperationsList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Closing Operations') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                      </div>
                                    </div>
                                  </div>
                                   <div class="separator separator-dashed mx-3 mt-0 mb-3"></div>
                                  <div class="col-lg-12 mb-3">
                                    <div v-if="this.$isCredentialActive(50,'')" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                                      <span class="menu-link py-3">
                                        <span class="menu-icon w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-info svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </span>
                                        <span class="menu-title">
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Balance-Sheet') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('View Balance-Sheet') }}</span>
                                          </span> 
                                        </span>
                                        <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                                      </span>
                                      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                                        <div class="menu-item" v-if="this.$isCredentialActive(57,'')">
                                          <router-link :to="{ name: 'DXBalanceSheet' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('General Balance-Sheet') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(58,'')">
                                          <router-link :to="{ name: 'DXComparativeBalanceSheet' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Comparative Balance-Sheet') }}</span>
                                            </a>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 mb-3">
                                    <div v-if="this.$isCredentialActive(51,'')" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                                      <span class="menu-link py-3">
                                        <span class="menu-icon w-40px h-40px me-3">
                                          <span class="svg-icon svg-icon-cyan svg-icon-2hx">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </span>
                                        <span class="menu-title">
                                          <span class="d-flex flex-column">
                                            <span class="fs-6 fw-bold text-gray-800">{{ $t('Profit & Loss Statements') }}</span>
                                            <span class="fs-7 fw-semibold text-muted">{{ $t('View Profit & Loss Statements') }}</span>
                                          </span> 
                                        </span>
                                        <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                                      </span>
                                      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                                        <div class="menu-item" v-if="this.$isCredentialActive(59,'')">
                                          <router-link :to="{ name: 'GeneralProfitAndLossList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('General Profit & Loss') }}</span>
                                            </a>
                                          </router-link>
                                        </div> 
                                        <div class="menu-item" v-if="this.$isCredentialActive(60,'')">
                                          <router-link :to="{ name: 'ComparativeProfitAndLossList' }">
                                            <a class="menu-link py-3 cursor-pointer" href="">
                                              <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                              </span>
                                              <span class="menu-title">{{ $t('Comparative Profit & Loss') }}</span>
                                            </a>
                                          </router-link>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                              </div> 
                          </div>
                      </div> 
                  </div> 
              </div>
            </div>
            <div v-if="this.$isCredentialActive(5,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2">
              <span class="menu-link py-3">
                  <span class="menu-title">{{ $t('Reports') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
              </span> 
              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-300px py-3" style="">
                  <div class="menu-state-bg menu-extended">
                    <div class="row">
                      <div class="col-lg-12 mb-3 mb-lg-0 py-1 px-3 py-lg-3 px-lg-6" v-if="this.$isCredentialActive(61,'')">
                        <div data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                          <span class="menu-link py-3">
                            <span class="menu-icon w-40px h-40px me-3">
                              <span class="svg-icon svg-icon-primary svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M8.4 14L15.6 8.79999L20 9.90002V6L16 4L9 11L5 12V14H8.4Z" fill="currentColor"/>
                                <path d="M21 18H20V12L16 11L9 16H6V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </span>
                            <span class="menu-title">
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Account Reports') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('View Account Reports') }}</span>
                              </span> 
                            </span>
                            <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                          </span>
                          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                              <div class="menu-item" v-if="this.$isCredentialActive(65,'')">
                                  <router-link :to="{ name: 'GeneralAccountReport' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('General Account Report') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(66,'')">
                                  <router-link :to="{ name: 'DXTransactionList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Account Transactions') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(67,'')">
                                  <router-link :to="{ name: 'ConsolidationList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Account Transactions') }} ({{ $t('Consolidation') }})</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(68,'')">
                                  <router-link :to="{ name: 'CollectionList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Collection List') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(69,'')">
                                  <router-link :to="{ name: 'CollectedList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Collected List') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(70,'')">
                                  <router-link :to="{ name: 'PaymentList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Payment List') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(71,'')">
                                  <router-link :to="{ name: 'PaidList' }">
                                    <a class="menu-link py-3 cursor-pointer" href="">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">{{ $t('Paid List') }}</span>
                                    </a>
                                  </router-link>
                              </div> 
                          </div>
                        </div>
                      </div> 

                      <div class="col-lg-12 mb-3 px-3 px-lg-6" v-if="this.$isCredentialActive(62,'')">
                        <div data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                          <span class="menu-link py-3">
                            <span class="menu-icon w-40px h-40px me-3">
                              <span class="svg-icon svg-icon-info svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="currentColor"/>
                                <path d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </span>
                            <span class="menu-title">
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Stock Reports') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('View Stock Reports') }}</span>
                              </span> 
                            </span>
                            <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                          </span>
                          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                              <div class="menu-item" v-if="this.$isCredentialActive(72,'')">
                                <router-link :to="{ name: 'GeneralStockTransactionList' }">
                                  <a class="menu-link py-3 cursor-pointer" href="">
                                    <span class="menu-bullet">
                                      <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t('General Stock Report') }}</span>
                                  </a>
                                </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(73,'')">
                                <router-link :to="{ name: 'DXStockTransactionList' }">
                                  <a class="menu-link py-3 cursor-pointer" href="">
                                    <span class="menu-bullet">
                                      <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t('Stock Transaction List') }}</span>
                                  </a>
                                </router-link>
                              </div> 
                              <div class="menu-item" v-if="this.$isCredentialActive(74,'')">
                                <router-link :to="{ name: 'StockCostList' }">
                                  <a class="menu-link py-3 cursor-pointer" href="">
                                    <span class="menu-bullet">
                                      <span class="bullet bullet-dot"></span>
                                    </span>
                                    <span class="menu-title">{{ $t('Stock-Cost List') }}</span>
                                  </a>
                                </router-link>
                              </div> 
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-lg-12 mb-3 px-3 px-lg-6" v-if="userData.is_insurance_module_active == 1 && this.$isCredentialActive(64,'')">
                        <div data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                          <span class="menu-link py-3">
                            <span class="menu-icon w-40px h-40px me-3">
                              <span class="svg-icon svg-icon-warning svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M9.4 8.79999L16.6 14H20V12L16 11L9 4L5 6V9.90002L9.4 8.79999Z" fill="currentColor"/>
                                <path d="M21 18H20V16H16L9 11L6 11.8V3C6 2.4 5.6 2 5 2C4.4 2 4 2.4 4 3V18H3C2.4 18 2 18.4 2 19C2 19.6 2.4 20 3 20H4V21C4 21.6 4.4 22 5 22C5.6 22 6 21.6 6 21V20H21C21.6 20 22 19.6 22 19C22 18.4 21.6 18 21 18Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </span>
                            <span class="menu-title">
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Policy Reports') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('View Policy Reports') }}</span>
                              </span> 
                            </span>
                            <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                          </span>
                          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                            <div class="menu-item" v-if="this.$isCredentialActive(76,'')">
                              <router-link :to="{ name: 'GeneralPolicyReport' }">
                                <a class="menu-link py-3 cursor-pointer" href="">
                                  <span class="menu-bullet">
                                    <span class="bullet bullet-dot"></span>
                                  </span>
                                  <span class="menu-title">{{ $t('General Policy Report') }}</span>
                                </a>
                              </router-link>
                            </div>  
                          </div>
                        </div>
                      </div>                       

                      <!-- <div class="col-lg-12 mb-3 px-3 px-lg-6" v-if="this.$isCredentialActive(63,'')">
                        <div data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item menu-lg-down-accordion"> 
                          <span class="menu-link py-3">
                            <span class="menu-icon w-40px h-40px me-3">
                              <span class="svg-icon svg-icon-success svg-icon-2hx">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.0021 10.9128V3.01281C13.0021 2.41281 13.5021 1.91281 14.1021 2.01281C16.1021 2.21281 17.9021 3.11284 19.3021 4.61284C20.7021 6.01284 21.6021 7.91285 21.9021 9.81285C22.0021 10.4129 21.5021 10.9128 20.9021 10.9128H13.0021Z" fill="currentColor"/>
                                <path opacity="0.3" d="M11.0021 13.7128V4.91283C11.0021 4.31283 10.5021 3.81283 9.90208 3.91283C5.40208 4.51283 1.90209 8.41284 2.00209 13.1128C2.10209 18.0128 6.40208 22.0128 11.3021 21.9128C13.1021 21.8128 14.7021 21.3128 16.0021 20.4128C16.5021 20.1128 16.6021 19.3128 16.1021 18.9128L11.0021 13.7128Z" fill="currentColor"/>
                                <path opacity="0.3" d="M21.9021 14.0128C21.7021 15.6128 21.1021 17.1128 20.1021 18.4128C19.7021 18.9128 19.0021 18.9128 18.6021 18.5128L13.0021 12.9128H20.9021C21.5021 12.9128 22.0021 13.4128 21.9021 14.0128Z" fill="currentColor"/>
                                </svg>
                              </span> 
                            </span>
                            <span class="menu-title">
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Financial Reports') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('View Financial Reports') }}</span>
                              </span> 
                            </span>
                            <span class="menu-arrow" style="position:absolute; right: 15px;"></span>
                          </span>
                          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px" style="">
                            <div class="menu-item">
                              <router-link :to="{ name: '' }">
                                <a class="menu-link py-3 cursor-pointer" href="">
                                  <span class="menu-bullet">
                                    <span class="bullet bullet-dot"></span>
                                  </span>
                                  <span class="menu-title">{{ $t('') }}</span>
                                </a>
                              </router-link>
                            </div>  
                          </div>
                        </div>
                      </div>  -->
                      <div class="col-lg-12 mb-3 px-3 px-lg-6" v-if="this.$isCredentialActive(75,'')">
                        <router-link :to="{ name: 'RatesList' }">
                          <div class="menu-item p-0 m-0">
                            <a href="" class="menu-link">
                              <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                <span class="svg-icon svg-icon-success svg-icon-2hx">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="currentColor"/>
                                    <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="currentColor"/>
                                  </svg>
                                </span>
                              </span>
                              <span class="d-flex flex-column">
                                <span class="fs-6 fw-bold text-gray-800">{{ $t('Rate List') }}</span>
                                <span class="fs-7 fw-semibold text-muted">{{ $t('View Exchange Rate List') }}</span>
                              </span>
                            </a>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div> 
              </div>
            </div> 
            <div v-if="userData.is_v_dc == 1 && this.$isCredentialActive(6,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu menu-item menu-lg-down-accordion me-0 me-lg-2">
              <router-link :to="{ name: 'Documents' }" class="menu-item">
                <span class="menu-link py-3">
                  <span class="menu-title">{{ $t('Documents') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>   
              </router-link>
            </div>
            <div v-if="userData.is_v_lb == 1 && this.$isCredentialActive(7,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu menu-item menu-lg-down-accordion me-0 me-lg-2">
              <router-link :to="{ name: 'Liabilities' }" class="menu-item">
                <span class="menu-link py-3">
                  <span class="menu-title">{{ $t('Liabilities') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </router-link>
            </div>            
            <div v-if="userData.is_insurance_module_active == 1 && this.$isCredentialActive(8,'')" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-0 me-lg-2">
              <span class="menu-link py-3">
                <span class="menu-title">{{ $t('Data Import') }}</span>
                <span class="menu-arrow d-lg-none"></span>
              </span> 
             <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-650px" style="">
                <div class="menu-state-bg menu-extended"> 
                  <div class="row">
                    <div class="col-lg-12 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6" v-if="this.$isCredentialActive(77,'')">
                        <div class="row">
                          <div class="col-lg-6 mb-3">
                            <router-link :to="{ name: 'PolicyDataImport' }">
                              <div class="menu-item p-0 m-0">
                                <a href="" class="menu-link">
                                  <span class="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                    <span class="svg-icon svg-icon-info svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path opacity="0.3" d="M5 15C3.3 15 2 13.7 2 12C2 10.3 3.3 9 5 9H5.10001C5.00001 8.7 5 8.3 5 8C5 5.2 7.2 3 10 3C11.9 3 13.5 4 14.3 5.5C14.8 5.2 15.4 5 16 5C17.7 5 19 6.3 19 8C19 8.4 18.9 8.7 18.8 9C18.9 9 18.9 9 19 9C20.7 9 22 10.3 22 12C22 13.7 20.7 15 19 15H5ZM5 12.6H13L9.7 9.29999C9.3 8.89999 8.7 8.89999 8.3 9.29999L5 12.6Z" fill="currentColor"/>
                                      <path d="M17 17.4V12C17 11.4 16.6 11 16 11C15.4 11 15 11.4 15 12V17.4H17Z" fill="currentColor"/>
                                      <path opacity="0.3" d="M12 17.4H20L16.7 20.7C16.3 21.1 15.7 21.1 15.3 20.7L12 17.4Z" fill="currentColor"/>
                                      <path d="M8 12.6V18C8 18.6 8.4 19 9 19C9.6 19 10 18.6 10 18V12.6H8Z" fill="currentColor"/>
                                      </svg>
                                    </span>
                                  </span>
                                  <span class="d-flex flex-column">
                                    <span class="fs-6 fw-bold text-gray-800">{{ $t('Insurance Policy Import') }}</span>
                                    <span class="fs-7 fw-semibold text-muted">{{ $t('Import your Insurance Policies') }}</span>
                                  </span>
                                </a>
                              </div>
                            </router-link>
                          </div>
                          <div class="col-lg-6 mb-3">
                            <img src="assets/theme/media/thumb_insurance.png" class="rounded mw-100" alt="" />
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div> 
                </div>
              </div>
            </div>
          <div class="d-flex align-items-stretch flex-shrink-0 p-4 p-lg-0 me-lg-2" id="kt_header_search_wrapper" v-if="this.$isCredentialActive(80,'') && userData.is_chatbi_user == 1">
            <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu"
            data-kt-menu="true">
              <div class="menu-item me-lg-1 cursor-pointer" @click="openChatBi()" data-bs-toggle="modal" data-bs-target="#chatbi_modal">
                <span class=" ">
                  <span class="menu-title">
                    <span class="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z" fill="currentColor"/>
                    <path d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z" fill="currentColor"/>
                    </svg>
                    </span>
                  </span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <span class="badge badge-circle badge-danger badge-sm" v-if="unReadMessageCount > 0">
                {{ unReadMessageCount }}
              </span> 
            </div>
          </div>
          <div class="d-flex align-items-stretch flex-shrink-0 p-4 p-lg-0 me-lg-2" id="kt_header_search_wrapper" v-if="userData.permission_type == 1">
            <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu"
            data-kt-menu="true">
            <div class="menu-item me-lg-1">
              <router-link class= "menu-link py-3" :to="{ name: 'Settings' }">
                <span class=" ">
                  <span class="menu-title">
                    <i class="bi bi-gear fs-1"></i> 
                  </span>
                  <span class="menu-arrow d-lg-none"></span>
                </span> </router-link>
            </div> 
            </div>
          </div>
        </div>
      </div> 


      <div v-if="userData.is_quick_mode == 1" class="header-menu-container d-flex align-items-stretch flex-stack h-lg-55px w-100" id="kt_header_nav" style="border-bottom: 2px solid #F1416C;">
        <div class="header-menu container-xxl flex-column align-items-stretch flex-lg-row"
          data-kt-drawer="true"
          data-kt-drawer-name="header-menu"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction="start"
          data-kt-drawer-toggle="#kt_header_menu_toggle"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
          <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu"
            data-kt-menu="true">
            <div class="menu menu-item me-lg-1">
              <router-link :to="{ name: 'Home' }" class="menu-item">
                <span class="menu-link py-3">
                    <span class="menu-title"> 
                      <span class="svg-icon svg-icon-3 svg-icon-gray-300 me-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="currentColor"/>
                        </svg>
                      </span>
                      {{ $t('Home') }}
                    </span>
                  <span class="menu-arrow d-lg-none"></span>
                </span> 
              </router-link>
            </div>
            <div v-if="userData.is_v_dc == 1" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu menu-item menu-lg-down-accordion me-0 me-lg-2">
              <router-link :to="{ name: 'Documents' }" class="menu-item">
                <span class="menu-link py-3">                 
                  <span class="menu-title">{{ $t('Documents') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>   
              </router-link>
            </div>
            <div v-if="userData.is_v_lb == 1" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" class="menu menu-item menu-lg-down-accordion me-0 me-lg-2">
              <router-link :to="{ name: 'Liabilities' }" class="menu-item">
                <span class="menu-link py-3">
                  <span class="menu-title">{{ $t('Liabilities') }}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </router-link>
            </div>
          </div>
          <div class="d-flex align-items-stretch flex-shrink-0 p-4 p-lg-0 me-lg-2" id="kt_header_search_wrapper" v-if="this.$isCredentialActive(80,'') && userData.is_chatbi_user == 1">
            <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch flex-grow-1" id="#kt_header_menu"
            data-kt-menu="true">
              <div class="menu-item me-lg-1 cursor-pointer" @click="openChatBi()" data-bs-toggle="modal" data-bs-target="#chatbi_modal">
                <span class=" ">
                  <span class="menu-title">
                    <span class="svg-icon svg-icon-success svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z" fill="currentColor"/>
                    <path d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z" fill="currentColor"/>
                    </svg>
                    </span>
                  </span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <span class="badge badge-circle badge-danger badge-sm" v-if="unReadMessageCount > 0">
                {{ unReadMessageCount }}
              </span> 
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- SYSTEM ADMIN ACCESS NOTIFICATION -->
    <div class="bg-light-success pt-4 pb-3 fs-7 text-gray-900 border border-success" v-if="isInstantLogin"> 
      <div class="container">
        <span class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="currentColor"/>
        <path d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"/>
        </svg></span> <span>{{ $t('You are logged in by inheriting') }} 
          
          <strong> {{ userData.company_name }} </strong> {{ $t('privileges.') }} </span>
      </div>
    </div>
      
    <!-- ChatBi -->
    <div class="modal fade" tabindex="-1" id="chatbi_modal" data-bs-backdrop="static" aria-label="Close" ref="closeModal" data-bs-keyboard="false">
      <div class="modal-dialog modal-xl border border-3 border-primary rounded-0">
        <div class="modal-content shadow-none rounded-0">
          <div class="modal-header rounded-0 p-5">
            <div class="modal-title">
              <h5 class="ms-1">{{ $t('ChatBi © CreatorCUBE Edition') }}</h5>
              <div class="d-flex flex-wrap rounded border bg-light p-1 mt-2" v-if="this.liabilityDetails.id !== null">
                <div class="d-flex flex-column me-10">
                  <div class="fw-bold">
                    <span class="me-1"> <i class="bi bi-hourglass-top text-warning fs-6"></i> </span>
                    <span class="fs-7 text-gray-700 fw-bold">{{previewDate(this.liabilityDetails.value_date)}}</span>
                  </div>
                </div>
                <div class="d-flex flex-column me-10">
                  <div class="fw-bold">
                    <span class="me-1"> <i class="bi bi-bookmark-fill text-success fs-7"></i> </span>
                    <span class="fs-7 text-gray-700 fw-bold">{{this.liabilityDetails.liability_type_name}}</span>
                  </div>
                </div>
                <div class="d-flex flex-column me-10">
                  <div class="fw-bold">
                    <span class="me-1"> <i class="bi bi-bookmarks-fill text-info fs-7"></i> </span>
                    <span class="fs-7 text-gray-700 fw-bold">{{this.liabilityDetails.liability_group_name}}</span>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div class="fw-bold text-center">
                    <span class="fs-4 badge badge-light-danger fs-base mt-n3" v-if="this.liabilityDetails.amount_type != 0"> {{this.liabilityDetails.amount}} </span>
                    <small class="text-gray-700 fw-bold" v-if="this.liabilityDetails.amount_type != 0"> {{this.localCurrency}} </small>

                    <span class="fs-7 badge badge-light-danger mt-n3" v-if="this.liabilityDetails.amount_type == 0"> {{ $t('No Amount') }} </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn btn-sm btn-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="getTotalMessageCount(), onRefreshLiabilityMessageCount()">
              {{ $t('Close Chat Window') }}
            </div>
          </div> 
          <div class="modal-body rounded-0 p-3">
            <div class="d-flex flex-row row">
              <div class="d-flex flex-column flex-row-auto col-lg-4">
                <div class="d-flex flex-column-fluid card card-xxl-stretch">
                  <div class="card-body p-5" id="kt_chat_contacts_body">
                    <div class="bg-light-primary mh-350px menu rounded menu-column menu-title-gray-700 menu-icon-gray-600 menu-arrow-gray-600 resize-vertical shadow-sm
                      menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold scroll-y p-5" v-if="this.chatBiGroupsList.length > 0"> 
                      <div class="menu-item menu-sub-indention menu-accordion" v-for="groupItem in orderedGroupList" v-bind:key="groupItem">
                        <div class="d-flex flex-center bg-hover-light rounded cursor-pointer pb-1" @click="setActiveChat(groupItem)" v-if="groupItem.chat_type == 4 || (groupItem.chat_type == 3 && groupItem.parent_group !== '')" 
                          :class="{'bg-light': groupItem.id == this.activeChat.id && groupItem.chat_type == this.activeChat.chat_type}">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-30px symbol-circle">
                              <span class="symbol-label fw-bolder" :class="{'bg-light-primary': groupItem.chat_type !== 3}">
                                <i class="fa fa-solid fa-user fs-6" v-if="groupItem.chat_type == 3"></i>
                                <i class="fa fa-solid fa-users fs-5 text-primary" v-if="groupItem.chat_type !== 3"></i>
                              </span>
                            </div>
                            <div>
                              <a href="#" class="menu-link">
                                <span class="menu-title text-gray-700 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" :title="groupItem.name" :class="{'fw-bolder': groupItem.chat_type !== 3}">
                                  {{ groupItem.name.substring(0, 20) }}
                                  <span v-if="groupItem.name.length > 20">...</span>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="ms-auto">
                            <span class="badge badge-circle badge-light-success me-3" v-if="groupItem.message_count > 0">
                              {{groupItem.message_count}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-light-primary mh-350px menu rounded menu-column menu-title-gray-700 menu-icon-gray-600 menu-arrow-gray-600 resize-vertical shadow-sm
                      menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold scroll-y p-5 mt-10" v-if="this.chatBiGroupsList.length > 0"> 
                      <div class="menu-item menu-sub-indention menu-accordion" v-for="groupItem in orderedGroupList" v-bind:key="groupItem">
                        <div class="d-flex flex-center bg-hover-light rounded cursor-pointer pb-1" @click="setActiveChat(groupItem)" v-if="groupItem.chat_type == 2 || (groupItem.chat_type == 3 && groupItem.parent_group == '')" 
                          :class="{'bg-light': groupItem.id == this.activeChat.id && groupItem.chat_type == this.activeChat.chat_type}">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-30px symbol-circle">
                              <span class="symbol-label fw-bolder" :class="{'bg-light-primary': groupItem.chat_type !== 3}">
                                <i class="fa fa-solid fa-user fs-6" v-if="groupItem.chat_type == 3"></i>
                                <i class="fa fa-solid fa-users fs-5 text-primary" v-if="groupItem.chat_type !== 3"></i>
                              </span>
                            </div>
                            <div>
                              <a href="#" class="menu-link">
                                <span class="menu-title text-gray-700 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" :title="groupItem.name" :class="{'fw-bolder': groupItem.chat_type !== 3}">
                                  {{ groupItem.name.substring(0, 20) }}
                                  <span v-if="groupItem.name.length > 20">...</span>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="ms-auto">
                            <span class="badge badge-circle badge-light-success me-3" v-if="groupItem.message_count > 0">
                              {{groupItem.message_count}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
              <div class="d-flex flex-column flex-row-auto col-lg-8"> 
                  <div class="d-flex flex-column-fluid card bgi-no-repeat h-400px" v-if="this.activeChat.id == null"
                    style="background-color: #009ef7; background-position: calc(100% + 1rem) bottom; background-size: 25% auto; background-image:url('/assets/theme/media/svg/humans/custom-1.svg')">
                      <div class="card-body d-flex flex-column align-items-start justify-content-center">
                          <h3 class="text-white fw-bold mb-3">{{ $t('Welcome to ChatBi © CreatorCUBE Edition') }}</h3>
                          <h4 class="text-white fs-4">{{ $t('Please select a user to start chatting.') }} </h4>
                      </div>
                  </div>
                  <div class="d-flex flex-column-fluid card bgi-no-repeat h-400px" v-if="this.activeChat.id != null && !isGroupHaveUsers" v-cloak>
                      <div class="card-body d-flex flex-column align-items-center justify-content-center">                                    
                        <div class="d-flex flex-center w-80px h-80px rounded-3 bg-light-danger bg-opacity-90 mb-10">
                          <span class="svg-icon svg-icon-muted svg-icon-3hx"><svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M12 6.20001V1.20001H2V6.20001C2 6.50001 2.1 6.70001 2.3 6.90001L5.6 10.2L2.3 13.5C2.1 13.7 2 13.9 2 14.2V19.2H12V14.2C12 13.9 11.9 13.7 11.7 13.5L8.4 10.2L11.7 6.90001C11.9 6.70001 12 6.50001 12 6.20001Z" fill="currentColor"/>
                          <path d="M13 2.20001H1C0.4 2.20001 0 1.80001 0 1.20001C0 0.600012 0.4 0.200012 1 0.200012H13C13.6 0.200012 14 0.600012 14 1.20001C14 1.80001 13.6 2.20001 13 2.20001ZM13 18.2H10V16.2L7.7 13.9C7.3 13.5 6.7 13.5 6.3 13.9L4 16.2V18.2H1C0.4 18.2 0 18.6 0 19.2C0 19.8 0.4 20.2 1 20.2H13C13.6 20.2 14 19.8 14 19.2C14 18.6 13.6 18.2 13 18.2ZM4.4 6.20001L6.3 8.10001C6.7 8.50001 7.3 8.50001 7.7 8.10001L9.6 6.20001H4.4Z" fill="currentColor"/>
                          </svg>
                          </span>
                        </div>
                        <h1 class="mb-5">{{ $t('There is no user in this group..!') }}</h1>
                        <div class="fs-4 text-gray-600 py-3">
                            {{ $t('Please add user to a group to start chatting.') }}
                        </div>
                      </div>
                  </div>
                  <div class="d-flex flex-column-fluid card card-xxl-stretch" v-if="this.activeChat.id != null && isGroupHaveUsers" id="kt_chat_messenger">
                    <div class="card-header" id="kt_chat_messenger_header"> 
                      <div class="card-title fs-5">
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-45px symbol-circle">
                            <span class="symbol-label fw-bolder">
                              <i class="bi bi-person-fill fs-2" v-if="activeChat.chat_type == 3"></i>
                              <i class="bi bi-people-fill fs-2" v-if="activeChat.chat_type !== 3"></i>
                            </span>
                          </div>
                          <div class="ms-3">
                            <a class="fs-5 fw-bolder text-gray-800 text-hover-primary me-1">{{ activeChat.name }}</a>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div class="card-body"> 
                        <div class="scroll-y me-n5 pe-5 h-450px" id="kt_chat_messenger_body" ref="kt_chat_messenger_body">
                            <div class="d-flex flex-column align-items-center">
                              <button type="button" class="btn btn-light btn-elevate-hover btn-circle btn-icon" data-kt-indicator="on"
                                @click="loadMoreMessages()" v-if="messages.length > 29" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Load more messages">
                               <span v-if="!this.isMessagesLoading"> <i class="fa fa fa-undo"></i> </span> 
                                <span class="indicator-progress" v-if="this.isMessagesLoading">
                                    <span class="spinner-border spinner-border-sm align-middle"></span>
                                </span>
                              </button>
                            </div>
                            <span v-for="(item) in messages" v-bind:key="item">
                                <div class="d-flex justify-content-start mb-5" v-if="item.sender_id != userData.id && ((item.chat_type !== 3 && item.chat_type == activeChat.chat_type && activeChat.id == item.receiver_id) || (item.chat_type == 3 && userData.id == item.receiver_id && activeChat.id == item.sender_id))
                                && (this.liabilityDetails.id !== null ? item.liability_id == this.liabilityDetails.id : true)">
                                  <div class="d-flex flex-column align-items-start"> 
                                    <div class="d-flex align-items-center">
                                      <div class="symbol symbol-45px me-3 symbol-circle">
                                          <span class="symbol-label bg-light-info text-info fw-bolder" v-if="item.sender_name != '' && item.sender_name != null">
                                              {{ getUserAlias(item.sender_name) }} 
                                          </span>
                                      </div>
                                      <div v-if="item.is_deleted !== 1" class="p-2 rounded bg-light-info text-gray-800 fw-semibold mw-lg-450px text-start" data-kt-element="message-text">                                              
                                        <div class="my-2">
                                          <a class="fw-bolder text-gray-800 text-hover-primary me-1">{{ item.sender_name }}</a>
                                          <div class="dropdown float-end">
                                            <span class="svg-icon svg-icon-2 cursor-pointer" data-toggle="dropdown" @click="onClickDropdown(item)">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                              </svg>
                                            </span>
                                            <ul class="dropdown-menu" :class="{ 'show': item.isOpenActionsDropdown }"> 
                                              <li>
                                                <a class="dropdown-item" href="#" @click="onReplyMessage(item)"> {{ $t('Reply') }} </a>
                                                <a class="dropdown-item" href="#" @click="onDeleteMessage(item)"
                                                  v-if="item.sender_mail == userData.user_mail && item.is_deleted !== 1"> {{ $t('Delete') }} </a>
                                              </li>
                                            </ul>
                                          </div>    
                                        </div>
                                        <small class="d-flex flex-row" v-if="item.liability_id !== null && item.liability_id !== '' && item.liability_id !== undefined">
                                          <li class="d-flex align-items-center me-2">
                                              <span class="bullet bullet-sm bullet-dot me-1 bg-primary"></span>
                                              {{previewDate(item.value_date)}}
                                          </li>
                                          <li class="d-flex align-items-center me-2">
                                              <span class="bullet bullet-sm bullet-dot me-1 bg-warning"></span>
                                              {{item.liability_type_name}} 
                                          </li>
                                          <li class="d-flex align-items-center me-2">
                                              <span class="bullet bullet-sm bullet-dot me-1 bg-success"></span> 
                                              {{item.amount}}
                                          </li>
                                        </small>
                                        <div class="separator my-2 border-gray-300" v-if="item.liability_id !== null && item.liability_id !== '' && item.liability_id !== undefined"></div>

                                        <!-- Repllied message content: Start -->
                                        <div v-if="item.repliedMessageItems" class="bg-white mb-3 px-5 py-1 rounded text-gray-800 fw-semibold mw-lg-450px text-start border-start border-0 border-left-3" data-kt-element="message-text"
                                          :class="{'border-primary': item.repliedMessageItems.sender_mail == userData.user_mail, 'border-info': item.repliedMessageItems.sender_mail !== userData.user_mail}">
                                          <div v-if="item.repliedMessageItems.is_deleted !== 1">
                                            <div class="" v-if="item.repliedMessageItems.sender_mail !== userData.user_mail">
                                              <a class="fw-bolder text-gray-800 text-hover-primary me-1">{{ item.repliedMessageItems.sender_name }}</a>
                                            </div>
                                            <small class="d-flex flex-row" v-if="item.repliedMessageItems.liability_id !== null && item.repliedMessageItems.liability_id !== '' && item.repliedMessageItems.liability_id !== undefined">
                                              <li class="d-flex align-items-center me-2">
                                                  <span class="bullet bullet-sm bullet-dot me-1 bg-primary"></span>
                                                  {{previewDate(item.repliedMessageItems.value_date)}}
                                              </li>
                                              <li class="d-flex align-items-center me-2">
                                                  <span class="bullet bullet-sm bullet-dot me-1 bg-warning"></span>
                                                  {{item.repliedMessageItems.liability_type_name}} 
                                              </li>
                                              <li class="d-flex align-items-center me-2">
                                                  <span class="bullet bullet-sm bullet-dot me-1 bg-success"></span> 
                                                  {{item.repliedMessageItems.amount}}
                                              </li>
                                            </small>
                                            <div class="separator my-2 border-gray-300" v-if="item.repliedMessageItems.liability_id !== null && item.repliedMessageItems.liability_id !== '' && item.repliedMessageItems.liability_id !== undefined"></div>

                                            <!-- Replied Documents: Start -->
                                            <span v-if="item.repliedMessageItems.document_name !== null && item.repliedMessageItems.document_name !== '' && item.repliedMessageItems.document_name !== undefined">
                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                                v-if="item.repliedMessageItems.document_extension == 'jpg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                                v-if="item.repliedMessageItems.document_extension == 'jpeg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />
                                                
                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/png.png"
                                                v-if="item.repliedMessageItems.document_extension == 'png'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/gif.png"
                                                v-if="item.repliedMessageItems.document_extension == 'gif'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/video.png"
                                                v-if="item.repliedMessageItems.document_extension == 'mp4'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/doc.png"
                                                v-if="item.repliedMessageItems.document_extension == 'doc'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/docx.png"
                                                v-if="item.repliedMessageItems.document_extension == 'docx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/pdf.png"
                                                v-if="item.repliedMessageItems.document_extension == 'pdf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                                v-if="item.repliedMessageItems.document_extension == 'ppt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                                v-if="item.repliedMessageItems.document_extension == 'pptx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rar.png"
                                                v-if="item.repliedMessageItems.document_extension == 'rar'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rtf.png" 
                                                v-if="item.repliedMessageItems.document_extension == 'rtf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/txt.png"
                                                v-if="item.repliedMessageItems.document_extension == 'txt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xls.png"
                                                v-if="item.repliedMessageItems.document_extension == 'xls'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xlsx.png"
                                                v-if="item.repliedMessageItems.document_extension == 'xlsx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/zip.png"
                                                v-if="item.repliedMessageItems.document_extension == 'zip'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                              {{item.repliedMessageItems.document_name}}
                                              <br v-if="item.repliedMessageItems.message">
                                            </span>
                                            <!-- Replied Documents: End -->

                                            <span class="fs-7">{{ item.repliedMessageItems.message.substring(0, 100) }}</span>
                                            <br>
                                            <span class="text-muted" style="font-size:9px !important;">
                                              {{ item.repliedMessageItems.time }}
                                            </span>
                                          </div>
                                          <span class="fs-7" v-if="item.repliedMessageItems.is_deleted == 1"><i class="bi bi-x-circle"> {{ $t('This message was deleted') }} </i></span>
                                        </div>
                                        <!-- Repllied message content: End -->
                                        
                                        <!-- Documents: Start -->
                                        <span v-if="item.document_name !== null && item.document_name !== '' && item.document_name !== undefined">
                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                            v-if="item.document_extension == 'jpg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                            v-if="item.document_extension == 'jpeg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />
                                            
                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/png.png"
                                            v-if="item.document_extension == 'png'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/gif.png"
                                            v-if="item.document_extension == 'gif'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/video.png"
                                            v-if="item.document_extension == 'mp4'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/doc.png"
                                            v-if="item.document_extension == 'doc'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/docx.png"
                                            v-if="item.document_extension == 'docx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/pdf.png"
                                            v-if="item.document_extension == 'pdf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                            v-if="item.document_extension == 'ppt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                            v-if="item.document_extension == 'pptx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rar.png"
                                            v-if="item.document_extension == 'rar'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rtf.png" 
                                            v-if="item.document_extension == 'rtf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/txt.png"
                                            v-if="item.document_extension == 'txt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xls.png"
                                            v-if="item.document_extension == 'xls'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xlsx.png"
                                            v-if="item.document_extension == 'xlsx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/zip.png"
                                            v-if="item.document_extension == 'zip'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                          {{item.document_name}}
                                          <br v-if="item.message">
                                        </span>
                                        <!-- Documents: End -->
                                        <span>{{ item.message }}</span>
                                        <br>
                                        <span class="text-muted" style="font-size:9px !important;">{{ item.time }}</span>
                                      </div>
                                      <div v-if="item.is_deleted == 1" class="p-2 rounded bg-light-info text-gray-800 fw-semibold mw-lg-450px" data-kt-element="message-text">
                                        <i class="bi bi-x-circle"> {{ $t('This message was deleted') }} </i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-end mb-5" v-if="item.sender_mail == userData.user_mail">
                                  <div class="d-flex flex-column align-items-end">
                                    <div v-if="item.is_deleted !== 1" class="p-2 rounded bg-light-primary text-gray-800 fw-semibold mw-lg-450px" data-kt-element="message-text">
                                      <small class="d-flex flex-row" v-if="item.liability_id !== null && item.liability_id !== '' && item.liability_id !== undefined">
                                        <li class="d-flex align-items-center me-2">
                                            <span class="bullet bullet-sm bullet-dot me-1 bg-primary"></span>
                                            {{previewDate(item.value_date)}}
                                        </li>
                                        <li class="d-flex align-items-center me-2">
                                            <span class="bullet bullet-sm bullet-dot me-1 bg-warning"></span>
                                            {{item.liability_type_name}} 
                                        </li>
                                        <li class="d-flex align-items-center me-2">
                                            <span class="bullet bullet-sm bullet-dot me-1 bg-success"></span> 
                                            {{item.amount}}
                                        </li>
                                      </small>
                                      <div class="separator my-2 border-gray-300" v-if="item.liability_id !== null && item.liability_id !== '' && item.liability_id !== undefined"></div>
                                      <div class="d-flex justify-content-end">
                                        <div class="dropdown dropstart">
                                          <span class="svg-icon svg-icon-2 cursor-pointer" data-toggle="dropdown" @click="onClickDropdown(item)">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                            </svg>
                                          </span>
                                          <ul class="dropdown-menu dropdown-menu-start" style="right: 0;" :class="{ 'show': item.isOpenActionsDropdown }"> 
                                            <li>
                                              <a class="dropdown-item" href="#" @click="onReplyMessage(item)"> {{ $t('Reply') }} </a>
                                              <a class="dropdown-item" href="#" @click="onDeleteMessage(item)"
                                                v-if="item.sender_mail == userData.user_mail && item.is_deleted !== 1"> {{ $t('Delete') }} </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      
                                      <!-- Repllied message content: Start -->
                                      <div v-if="item.repliedMessageItems" class="bg-white mb-3 px-5 py-1 rounded text-gray-800 fw-semibold mw-lg-450px text-start border-start border-0 border-left-3" data-kt-element="message-text"
                                        :class="{'border-primary': item.repliedMessageItems.sender_mail == userData.user_mail, 'border-info': item.repliedMessageItems.sender_mail !== userData.user_mail}">
                                        <div v-if="item.repliedMessageItems.is_deleted !== 1">
                                          <div class="" v-if="item.repliedMessageItems.sender_mail !== userData.user_mail">
                                            <a class="fw-bolder text-gray-800 text-hover-primary me-1">{{ item.repliedMessageItems.sender_name }}</a>
                                          </div>
                                          <small class="d-flex flex-row" v-if="item.repliedMessageItems.liability_id !== null && item.repliedMessageItems.liability_id !== '' && item.repliedMessageItems.liability_id !== undefined">
                                            <li class="d-flex align-items-center me-2">
                                                <span class="bullet bullet-sm bullet-dot me-1 bg-primary"></span>
                                                {{previewDate(item.repliedMessageItems.value_date)}}
                                            </li>
                                            <li class="d-flex align-items-center me-2">
                                                <span class="bullet bullet-sm bullet-dot me-1 bg-warning"></span>
                                                {{item.repliedMessageItems.liability_type_name}} 
                                            </li>
                                            <li class="d-flex align-items-center me-2">
                                                <span class="bullet bullet-sm bullet-dot me-1 bg-success"></span> 
                                                {{item.repliedMessageItems.amount}}
                                            </li>
                                          </small>
                                          <div class="separator my-2 border-gray-300" v-if="item.repliedMessageItems.liability_id !== null && item.repliedMessageItems.liability_id !== '' && item.repliedMessageItems.liability_id !== undefined"></div>
                                          
                                          <!-- Documents: Start -->
                                          <span v-if="item.repliedMessageItems.document_name !== null && item.repliedMessageItems.document_name !== '' && item.repliedMessageItems.document_name !== undefined">
                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                              v-if="item.repliedMessageItems.document_extension == 'jpg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                              v-if="item.repliedMessageItems.document_extension == 'jpeg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />
                                              
                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/png.png"
                                              v-if="item.repliedMessageItems.document_extension == 'png'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/gif.png"
                                              v-if="item.repliedMessageItems.document_extension == 'gif'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/video.png"
                                              v-if="item.repliedMessageItems.document_extension == 'mp4'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/doc.png"
                                              v-if="item.repliedMessageItems.document_extension == 'doc'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/docx.png"
                                              v-if="item.repliedMessageItems.document_extension == 'docx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/pdf.png"
                                              v-if="item.repliedMessageItems.document_extension == 'pdf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                              v-if="item.repliedMessageItems.document_extension == 'ppt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                              v-if="item.repliedMessageItems.document_extension == 'pptx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rar.png"
                                              v-if="item.repliedMessageItems.document_extension == 'rar'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rtf.png" 
                                              v-if="item.repliedMessageItems.document_extension == 'rtf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/txt.png"
                                              v-if="item.repliedMessageItems.document_extension == 'txt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xls.png"
                                              v-if="item.repliedMessageItems.document_extension == 'xls'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xlsx.png"
                                              v-if="item.repliedMessageItems.document_extension == 'xlsx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/zip.png"
                                              v-if="item.repliedMessageItems.document_extension == 'zip'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                            {{item.repliedMessageItems.document_name}}
                                            <br v-if="item.repliedMessageItems.message">
                                          </span>
                                          <!-- Documents: End -->

                                          <span class="fs-7">{{ item.repliedMessageItems.message.substring(0, 100) }}</span>
                                          <br>
                                          <span class="text-muted" style="font-size:9px !important;">
                                            {{ item.repliedMessageItems.time }}
                                          </span>
                                        </div>
                                        <span class="fs-7" v-if="item.repliedMessageItems.is_deleted == 1"><i class="bi bi-x-circle"> {{ $t('This message was deleted') }} </i></span>
                                      </div>
                                      <!-- Repllied message content: End -->

                                      <!-- Replied Documents: Start -->
                                      <span v-if="item.document_name !== null && item.document_name !== '' && item.document_name !== undefined">
                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                          v-if="item.document_extension == 'jpg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                          v-if="item.document_extension == 'jpeg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />
                                          
                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/png.png"
                                          v-if="item.document_extension == 'png'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/gif.png"
                                          v-if="item.document_extension == 'gif'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/video.png"
                                          v-if="item.document_extension == 'mp4'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/doc.png"
                                          v-if="item.document_extension == 'doc'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/docx.png"
                                          v-if="item.document_extension == 'docx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/pdf.png"
                                          v-if="item.document_extension == 'pdf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                          v-if="item.document_extension == 'ppt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                          v-if="item.document_extension == 'pptx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rar.png"
                                          v-if="item.document_extension == 'rar'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rtf.png" 
                                          v-if="item.document_extension == 'rtf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/txt.png"
                                          v-if="item.document_extension == 'txt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xls.png"
                                          v-if="item.document_extension == 'xls'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xlsx.png"
                                          v-if="item.document_extension == 'xlsx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/zip.png"
                                          v-if="item.document_extension == 'zip'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                                        {{item.document_name}}
                                        <br v-if="item.message">
                                      </span>
                                      <!-- Replied Documents: End -->
                                      <span>{{ item.message }}</span>
                                      <br>
                                      <span class="text-muted" style="font-size:9px !important;">{{ item.time }}</span>
                                    </div>
                                    <div v-if="item.is_deleted == 1" class="p-2 rounded bg-light-primary text-gray-800 fw-semibold mw-lg-450px" data-kt-element="message-text">
                                      <i class="bi bi-x-circle"> {{ $t('This message was deleted') }} </i>
                                    </div>
                                  </div>
                                </div>  
                            </span>
                        </div>
                    </div>
                    <div class="card-footer pt-4">
                      <div v-if="repliedMessageItem !== null && repliedMessageItem !== undefined"
                        class="alert alert-dismissible d-flex flex-column flex-sm-row m-0 p-0 border-start border-0 border-left-3 mb-3"
                        :class="{'bg-light-primary border-primary': repliedMessageItem.sender_mail == userData.user_mail, 'bg-light-info border-info': repliedMessageItem.sender_mail !== userData.user_mail}">
                        <div class="d-flex align-items-center">
                          <div class="ms-5 p-2 rounded text-gray-800 fw-semibold mw-lg-450px text-start" :class="{'bg-light-primary': repliedMessageItem.sender_mail == userData.user_mail, 'bg-light-info': repliedMessageItem.sender_mail !== userData.user_mail}">
                            <div class="" v-if="repliedMessageItem.sender_mail !== userData.user_mail">
                              <a class="fw-bolder text-gray-800 text-hover-primary me-1">{{ repliedMessageItem.sender_name }}</a>
                            </div>
                            <small class="d-flex flex-row" v-if="repliedMessageItem.liability_id !== null && repliedMessageItem.liability_id !== '' && repliedMessageItem.liability_id !== undefined">
                              <li class="d-flex align-items-center me-2">
                                  <span class="bullet bullet-sm bullet-dot me-1 bg-primary"></span>
                                  {{previewDate(repliedMessageItem.value_date)}}
                              </li>
                              <li class="d-flex align-items-center me-2">
                                  <span class="bullet bullet-sm bullet-dot me-1 bg-warning"></span>
                                  {{repliedMessageItem.liability_type_name}} 
                              </li>
                              <li class="d-flex align-items-center me-2">
                                  <span class="bullet bullet-sm bullet-dot me-1 bg-success"></span> 
                                  {{repliedMessageItem.amount}}
                              </li>
                            </small>
                            <div class="separator my-2 border-gray-300" v-if="repliedMessageItem.liability_id !== null && repliedMessageItem.liability_id !== '' && repliedMessageItem.liability_id !== undefined"></div>
                            
                            <!-- Replied Documents: Start -->
                            <span v-if="repliedMessageItem.document_name !== null && repliedMessageItem.document_name !== '' && repliedMessageItem.document_name !== undefined">
                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                v-if="repliedMessageItem.document_extension == 'jpg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/jpg.png"
                                v-if="repliedMessageItem.document_extension == 'jpeg'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />
                                
                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/png.png"
                                v-if="repliedMessageItem.document_extension == 'png'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/gif.png"
                                v-if="repliedMessageItem.document_extension == 'gif'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/video.png"
                                v-if="repliedMessageItem.document_extension == 'mp4'" style="margin-right: auto; margin-left: auto;" width="50" height="50" /> 

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/doc.png"
                                v-if="repliedMessageItem.document_extension == 'doc'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/docx.png"
                                v-if="repliedMessageItem.document_extension == 'docx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/pdf.png"
                                v-if="repliedMessageItem.document_extension == 'pdf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                v-if="repliedMessageItem.document_extension == 'ppt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/ppt.png"
                                v-if="repliedMessageItem.document_extension == 'pptx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rar.png"
                                v-if="repliedMessageItem.document_extension == 'rar'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/rtf.png" 
                                v-if="repliedMessageItem.document_extension == 'rtf'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/txt.png"
                                v-if="repliedMessageItem.document_extension == 'txt'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xls.png"
                                v-if="repliedMessageItem.document_extension == 'xls'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/xlsx.png"
                                v-if="repliedMessageItem.document_extension == 'xlsx'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              <img class="img-responsive cursor-pointer" src="/assets/theme/media/extensionImage/zip.png"
                                v-if="repliedMessageItem.document_extension == 'zip'" style="margin-right: auto; margin-left: auto;" width="50" height="50" />

                              {{repliedMessageItem.document_name}}
                              <br v-if="repliedMessageItem.message">
                            </span>
                            <!-- Replied Documents: End -->

                            <span class="fs-7">{{ repliedMessageItem.message.substring(0, 100) }}</span>
                          </div>
                        </div>
                        <button type="button" @click="cancelReply()" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto">
                          <span class="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                          <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                          </span>
                        </button>
                      </div>
                      <div class="bg-light" v-if="isViewFileUploader">
                        <!-- Show a warning message if no Document Locations defined -->
                        <div class="col-lg-12" v-if="this.documentLocation == null && isFileManagerReady">
                          <div class="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row w-100 p-3">
                            <span class="svg-icon svg-icon-2x svg-icon-danger me-4 mb-sm-0">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                                    <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            <div class="d-flex flex-column pe-0 pe-sm-10">
                                <h5 class="fw-semibold">{{ $t('Document Location not found.') }}</h5>
                                <small>{{ $t('No Document Location has been defined yet.') }}</small>
                            </div>
                            <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert" @click="isViewFileUploader = false">
                              <span class="svg-icon svg-icon-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                      viewBox="0 0 24 24" fill="none">
                                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                          transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                      <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                          transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                  </svg>
                              </span>    
                            </button>
                          </div>
                        </div>
                        <div class="row" v-if="this.documentLocation !== null && isFileManagerReady">
                          <div class="col-lg-11">
                            <DxFileUploader
                              multiple="false"
                              upload-mode="useButtons" 
                              :visible="true"
                              accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, image/*"
                              :upload-headers="getUploadHeaders()"
                              :on-uploaded="onUploadCompleted"
                              upload-url="/api/Folders/UploadDocumentByLocation"
                              :ref="'fileUploader'" />
                          </div>
                          <div class="col-lg-1">
                            <div class="btn btn-icon btn-sm btn-active-icon-primary float-end mt-3" @click="isViewFileUploader = false">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form @submit.prevent="submit">
                        <div class="row">
                          <div class="col-lg-10">
                            <input type="text" class="form-control form-control-flush mb-3" rows="1" data-kt-element="input" placeholder="Type a message" v-model="this.chatBiForm.chatMessage"/>
                          </div>
                          <div class="col-lg-2">
                            <button class="btn btn-sm btn-icon btn-active-light-primary me-1 float-end mt-1" type="button" @click="onUploadFile()">
                              <i class="bi bi-upload fs-3"></i>
                            </button>
                          </div>
                        </div>
                        <div class="d-flex flex-stack justify-content-end mt-3">
                          <button class="btn btn-primary" type="submit" data-kt-element="send"
                            @click="SendChatBiMessage(this.chatBiForm.chatMessage, null, null)"
                            :disabled="this.chatBiForm.chatMessage == '' || this.chatBiForm.chatMessage == null">
                            {{ $t('Send') }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> 
              </div>
            </div>
          </div>
          <div class="modal-footer p-1">
            <span class="badge badge-light fw-light fs-7 px-5">{{ $t('You are logged in as') }} &nbsp; <strong> {{ userData.name_surname}} </strong></span> 
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal fade" id="kt_modal_upgrade_plan_header" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-xxl">
            <div class="modal-content rounded"  >
                <div class="modal-header justify-content-end border-0 p-0">
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="onRefreshUserData()">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-1"> <Upgrade ref="Upgrade"/></div>
            </div>
        </div>
    </div>
  </div>
</template>  

<style> 
 @media (min-width: 992px) {
  .header {
    height: 150px !important;
    min-height: 150px !important;
  }
}
</style>
<script>  
import * as signalR from "@microsoft/signalr";
import axios from 'axios';
import moment from 'moment'; 
import { DxFileUploader} from 'devextreme-vue/file-uploader';
import Upgrade from '../parts/Upgrade.vue';
import themes from "devextreme/ui/themes";
export default {
  name: "NavHeader",
  components:{
    DxFileUploader, Upgrade
  },
  data() {
    return {
      isExpanded: false,
      userData: this.$getUser(),
      localCurrency: this.$getUser().currency_code,
      isInstantLogin: this.$getSessionStorage('is_instant_login'),
      accountingYears: [],
      accountingYearSelection: null,

      /* ChatBi */
      messages: [],
      connection: "",
      chatBiForm: {
        chatMessage: ""
      },
      activeChat: {
        id: null,
        name: null,
        user_mail: null,
        chat_type: 0  /* 0-Main Controller Group, 1-Operation Group, 2-Company Group, 3-Private, 4-Private Company Account Group */
      },
      isGroupHaveUsers: true,
      isViewFileUploader: false,
      chatBiGroupsList: [],
      unReadMessageCount: 0,
      liabilityDetails: {
        id: null,
        liability_group_id: null,
        company_id: null,
        amount: null,
        liability_type_name: null,
        liability_group_name: null,
        value_date: null
      },
      isMessagesLoading: false,
      repliedMessageItem: null,
      uploadForm:  {
        document_type_id: null,
        importance: 0,
        document_source: null,
        upload_source: 0,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      },
      documentLocation: null,
      isFileManagerReady: false
    };
  },
  computed: {
    orderedUserList(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return value.slice().sort((a, b) => new Date(b.last_message_time)- new Date(a.last_message_time));
        }
      }
    },
    getUserAlias() {
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return value.split(' ', 3).map(x => x !== '' ? x[0].toUpperCase() : '').join('.');
        }
      }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    getUsersMessageCount() {
      return (value)=>{
        if (value) {
          let sum = 0;
          value.forEach((item)  =>  sum += (item.message_count));
          return sum;
        }
      } 
    },
    orderedGroupList() {
      return this.chatBiGroupsList.slice().sort((a, b) => new Date(b.last_message_time)- new Date(a.last_message_time));
    },
    getOnlyUserName() {
      return (this.userData !== null && this.userData !== undefined && this.userData !== '') ? this.userData.name_surname.split(' ')[0] : '';
    },
  },
  methods: {
    changeTheme(themeName) {
    // Devexpress için tema ayari yapiliyor
      if(themeName == 'light') {
        themes.current("generic.light");
      } else if (themeName == 'dark') {
        themes.current("generic.dark");
      } else {
        const themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "generic.dark" : "generic.light";
        themes.current(themeMode);
      }
    },
    collapse() {
      this.isExpanded = false;
    },
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    signOut(){
      const parameters = {
        session_id: this.$getUser().session_id
      };  
      axios.post('/api/Auth/Logout', parameters, {'Content-Type': 'application/json'});

      
      this.$removeUser('user_' + this.$getUser().session_id);
      if(this.$getSessionStorage('is_instant_login')){
        sessionStorage.clear();
        window.close();
      }
      else{ 
        sessionStorage.clear();
        this.$router.push({ name: 'Login' });
      }
    },
    onRefreshUserData() {
      if(this.$refs.Upgrade.licenceTransactionId !== null && this.$refs.Upgrade.licenceTransactionId !== '' && this.$refs.Upgrade.licenceTransactionId !== undefined) {
        if(this.$route.name == 'Home') this.$router.go(0);
        else this.$router.push({ name: 'Home' });
      }
    },
    
    /* CHATBI */
    getTotalMessageCount() {
      if(!this.$isCredentialActive(80,'R')) return;
      const parameters = {
        session_id: this.$getUser().session_id
      };
      axios.post("/api/Chatbi/GetUnreadMessageCount", parameters, {"Content-Type": "application/json",})
      .then((response) => {  
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.unReadMessageCount = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    },
    onRefreshLiabilityMessageCount() {
      if(this.$route.path == '/Liabilities') { 
        this.emitter.emit('onReadLiabilityMessage', null);
      }
    },
    openChatBi(liabilityItem){
      this.chatBiForm.chatMessage = "";      
      this.activeChat = {
        id: null,
        name: null,
        user_mail: null,
        chat_type: 0
      };
      this.isGroupHaveUsers = true;
      if(liabilityItem !== null && liabilityItem !== undefined && liabilityItem !== '') {
        this.liabilityDetails = liabilityItem;
      }
      else {        
        this.liabilityDetails = {
          id: null,
          liability_group_id: null,
          company_id: null
        }
      }
      const params = {
        session_id: this.$getUser().session_id,
        liability_id: this.liabilityDetails.id
      };
      axios.post("/api/Chatbi/Groups", params, {"Content-Type": "application/json",})
      .then((response) => {
        this.chatBiGroupsList = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    SendChatBiMessage: function(message, document_id, document_name) {
      if(message == null && message == '' && message == undefined) return;
      this.connection.invoke("SendChatBiMessage",
        this.$getUser().user_mail,
        this.$getUser().name_surname,
        message,
        this.$getUser().session_id,
        this.$getUser().id, this.activeChat.id,
        (this.repliedMessageItem !== null ? this.repliedMessageItem.id : null),
        this.activeChat.chat_type,
        this.liabilityDetails,
        document_id,
        document_name
      ).then((message_id) => {
        this.messages.push({
          id: message_id,
          sender_mail: this.$getUser().user_mail,
          sender_name: this.$getUser().name_surname,
          message: message,
          time: moment().format('DD.MM.YYYY HH:mm:ss'), 
          session_id: this.$getUser().session_id, 
          sender_id: this.$getUser().id, 
          receiver_id: this.activeChat.id,
          reply_id: (this.repliedMessageItem !== null && this.repliedMessageItem !== '') ? this.repliedMessageItem.id : null, 
          chat_type: this.activeChat.chat_type,
          liability_id: this.liabilityDetails.id,
          value_date: this.liabilityDetails.value_date,
          amount: this.liabilityDetails.amount,
          liability_type_name: this.liabilityDetails.liability_type_name,
          repliedMessageItems: (this.repliedMessageItem !== null && this.repliedMessageItem !== '') ? this.getRepliedMessageItems(this.repliedMessageItem.id) : null,
          is_deleted: 0,
          document_name: document_name,
          document_extension: (document_name !== null && document_name !== undefined && document_name !== '') ? document_name.split('.')[document_name.split('.').length - 1] : null
        });
        try {
          this.$nextTick(() => {
            this.$refs.kt_chat_messenger_body.scrollTop = this.$refs.kt_chat_messenger_body.scrollHeight;
            this.repliedMessageItem = null;
            this.setMessagesAsRead();
          });
        } catch (Err) {
          console.log(Err);
        }
      })
      .catch(function(err) {
        return console.error(err);
      });
        
      this.chatBiForm.chatMessage = ''; 
    },
    setActiveChat(item) {
      this.activeChat = {
        id: item.id,
        name: item.name,
        user_mail: item.user_mail,
        chat_type: item.chat_type
      };
      this.chatBiForm.chatMessage = "";
      this.repliedMessageItem = null;
      this.messages = [];
      this.isGroupHaveUsers = true;
      this.isViewFileUploader = false;
      this.messages = [];

      this.getMessages(item);
    },
    getMessages(item) {
      const parameters = {
        session_id: this.$getUser().session_id,
        receiver_id: this.userData.id,
        sender_id:  item.id,
        chat_type: item.chat_type,
        liability_id: this.liabilityDetails.id,
        count: 0
      };
      axios.post("/api/Chatbi/GetChatbiMessages", parameters, {"Content-Type": "application/json",})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          response.data.forEach(element => {
            this.messages.push({
              id: element.id,
              sender_mail: element.sender_mail,
              sender_name: element.sender_name,
              message: element.message,
              time: element.time,
              sender_id: element.sender_id, 
              receiver_id: element.receiver_id,
              reply_id: element.reply_id, 
              chat_type: element.chat_type, 
              liability_id: element.liability_id,
              value_date: element.value_date,
              amount: element.amount,
              liability_type_name: element.liability_type_name,              
              repliedMessageItems: (element.reply_id !== null && element.reply_id !== '') ? this.getRepliedMessageItems(element.reply_id) : null,
              is_deleted: element.is_deleted,
              document_name: element.document_name,
              document_extension: (element.document_name !== null && element.document_name !== undefined && element.document_name !== '') ? element.document_name.split('.')[element.document_name.split('.').length - 1] : null
            });
          });
        }
        try {
          this.$nextTick(() => {
            this.$refs.kt_chat_messenger_body.scrollTop = this.$refs.kt_chat_messenger_body.scrollHeight;
            this.setMessagesAsRead();
          });
        } catch (Err) {
          console.log(Err);
        }
      })
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    },
    setMessagesAsRead() {
      const parameters = {
        session_id: this.$getUser().session_id,
        receiver_id: this.activeChat.id,
        chat_type: this.activeChat.chat_type,
        sender_id: null,
        liability_id: this.liabilityDetails.id
      };
      if(parameters.chat_type == 3) {
        parameters.receiver_id = this.userData.id;
        parameters.sender_id = this.activeChat.id;
      }
      axios.post("/api/Chatbi/SetMessagesAsRead", parameters, {"Content-Type": "application/json",})
      .then(() => {
        this.activeChat.message_count = 0;
        this.chatBiGroupsList.forEach(element => {
          if(element.id == this.activeChat.id && element.chat_type == this.activeChat.chat_type) {
            element.message_count = 0;
          }
        });
      })
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    },
    getUnreadMessageCount(receiverId, senderId, chatType, liabilityId) {
      if (this.liabilityDetails.id !== null) {
        this.chatBiGroupsList.forEach(element => {
          if (receiverId == element.id && chatType == element.chat_type && senderId !== this.userData.id && (this.liabilityDetails.id == liabilityId)) {
            if (parseInt(element.message_count) > -1) {
              element.message_count = element.message_count + 1;
              element.last_message_time = moment().format('YYYY-MM-DD[T]HH:mm:ss');
            }
            else { element.message_count = 0; }
          }
          
          if (senderId == element.id && chatType == element.chat_type && receiverId == this.userData.id && (this.liabilityDetails.id == liabilityId)) {
            if (parseInt(element.message_count) > -1) {
              element.message_count = element.message_count + 1;
              element.last_message_time = moment().format('YYYY-MM-DD[T]HH:mm:ss');
            }
            else {
              element.message_count = 0;
            }
          }
        });
      } else {
        this.chatBiGroupsList.forEach(element => {
          if (receiverId == element.id && chatType == element.chat_type && senderId !== this.userData.id) {
            if (parseInt(element.message_count) > -1) {
              element.message_count = element.message_count + 1;
              element.last_message_time = moment().format('YYYY-MM-DD[T]HH:mm:ss');
            }
            else { element.message_count = 0; }
          }
          
          if (senderId == element.id && chatType == element.chat_type && receiverId == this.userData.id) {
            if (parseInt(element.message_count) > -1) {
              element.message_count = element.message_count + 1;
              element.last_message_time = moment().format('YYYY-MM-DD[T]HH:mm:ss');
            }
            else {
              element.message_count = 0;
            }
          }
        });
      }
      setTimeout(() => {
        this.getTotalMessageCount();
      }, 500);
    },    
    loadMoreMessages () {
      this.isMessagesLoading = true;
      const parameters = {
        session_id: this.$getUser().session_id,
        receiver_id: this.userData.id ,
        sender_id:  this.activeChat.id,
        chat_type: this.activeChat.chat_type,
        liability_id: this.liabilityDetails.id,
        count: this.messages.length
      };
      const that = this;
      axios.post("/api/Chatbi/GetChatbiMessages", parameters, {"Content-Type": "application/json",})
      .then((response) => {          
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          response.data.forEach(element => {
            this.messages.unshift({
              sender_mail: element.sender_mail,
              sender_name: element.sender_name,
              message: element.message,
              time: element.time,
              sender_id: element.sender_id, 
              receiver_id: element.receiver_id,
              reply_id: element.reply_id, 
              chat_type: element.chat_type, 
              liability_id: element.liability_id,
              value_date: element.value_date,
              amount: element.amount,
              liability_type_name: element.liability_type_name,
              repliedMessageItems: (element.reply_id !== null && element.reply_id !== '') ? this.getRepliedMessageItems(element.reply_id) : null,
              is_deleted: element.is_deleted,
              document_name: element.document_name,
              document_extension: (element.document_name !== null && element.document_name !== undefined && element.document_name !== '') ? element.document_name.split('.')[element.document_name.split('.').length - 1] : null
            });
          });
        }
      })        
      .finally(function () { 
          setTimeout(() => {
              that.isMessagesLoading = false;
          }, 500);
      }); 
    },
    onClickDropdown(item) {
      this.messages.forEach(element => {
          if (item.id !== element.id) {
              element.isOpenActionsDropdown = false;
          }
      });
      item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    },
    onReplyMessage(item) {
      item.isOpenActionsDropdown = false;
      this.repliedMessageItem = item;
    },
    cancelReply() {
      this.repliedMessageItem = null;
    },
    onDeleteMessage(item) {
      item.isOpenActionsDropdown = false;      
      this.$swal.fire({
        title: 'Are you sure you want to delete this message?',
        confirmButtonColor: '#f1416c',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        icon: 'warning'
      }).then((result) => { 
        if (result.isConfirmed) {
            const params = {
                session_id: this.$getUser().session_id,
                id: item.id
            }; 
            axios.post('api/Chatbi/DeleteMessage', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.connection.invoke("DeleteMessage", item.id).catch(function (err) {
                      return console.error(err.toString());
                  });
                }
            })
            .catch(function () { 
              this.$swal("Error.", "Data could not be deleted..Please refresh the page and try again..!", 'error');
            });  
        }
      })   
    },
    getRepliedMessageItems(replyId) {
      let repliedMessageItem = null;
      this.messages.forEach(element => {
        if(element.id == replyId) {
          repliedMessageItem = element;
        }
      });
      return repliedMessageItem;
    },
    onUploadFile() {
      this.isViewFileUploader = true;
      this.isFileManagerReady = false;
      const that = this;
      const parameters = { 
        session_id: this.$getUser().session_id,
        location: 5, //General
        location_type: 2, //ChatBi
        location_subtype: 0,
        account_id: null,
        is_transaction: null
      }; 
      axios.post('/api/DocumentLocation/GetDocumentLocations', parameters, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data.length > 0) { 
          this.documentLocation = response.data[0];
          // this.$refs.fileUploader.instance.reset();
        } else {
          this.documentLocation = null;
        }
      })
      .finally(function () {
        that.isFileManagerReady = true;
      });
    },
    getUploadHeaders(){
        return { 
            sid: this.$getUser().session_id,
            dos: 1, /* Source */
            ups: 3, /* Upload Source */
            hgp: this.uploadForm.have_gdpr_permission,
            hpd: this.uploadForm.have_personal_data,
            imp: 0,  /* Importance */
            lid: null,
            lci: this.documentLocation != null ? this.documentLocation.id : null,
            mid: this.documentLocation != null ? this.documentLocation.main_location : null,
            dti: this.documentLocation != null ? this.documentLocation.document_type_id : null,
        };
    },
    onUploadCompleted(e){
      const parameters = { 
        session_id: this.$getUser().session_id,
        fid: e.request.responseText,
        source_company: this.$getUser().company_name,
        source_person: this.$getUser().name_surname,
        document_name: (e.file.name !== '' && e.file.name !== null && e.file.name !== undefined) ? e.file.name.split('.').slice(0, -1).join('.') : null,
        document_no: this.uploadForm.document_no,
        description: this.uploadForm.description,
        gdpr_description: this.uploadForm.gdpr_description
      }; 
      axios.post('/api/Folders/UpdateDocumentForChatBi', parameters, {'Content-Type': 'application/json'})
      .then(() => {
          this.isViewFileUploader = false;
          // this.$refs.fileUploader.instance.reset();
          this.SendChatBiMessage(this.chatBiForm.chatMessage, e.request.responseText, e.file.name);
        }) 
      .catch(function (error) {
          console.log(error);
          this.Progress.finish();
      });
    },
    openUpgradeModal() {
      this.$refs.Upgrade.getData();
    }
  },
  created() {
    if(this.$isCredentialActive(80,'') && this.userData.is_chatbi_user == 1 && this.userData.is_locked !== 1) {
      axios.get('/api/Auth/GetCU').then((response) => {
        const hubUrl = response.data;
        if(hubUrl !== null && hubUrl !== '' && hubUrl !== undefined) {
          let that = this;
          this.connection = new signalR.HubConnectionBuilder().withUrl(hubUrl + "chatbi", { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets, }).build();

          this.connection.on("ReceiveMessage", function (sender_mail,sender_name,message,time,session_id,sender_id,receiver_id,reply_id, chat_type, liabilityDetails, message_id, document_name) {
            that.messages.push({
              id: message_id,
              sender_mail: sender_mail,
              sender_name: sender_name,
              message: message,
              time: time, 
              session_id: session_id, 
              sender_id: sender_id, 
              receiver_id: receiver_id,
              reply_id: reply_id, 
              chat_type: chat_type, 
              liability_id: liabilityDetails.id,
              value_date: liabilityDetails.value_date,
              amount: liabilityDetails.amount,
              liability_type_name: liabilityDetails.liability_type_name,
              repliedMessageItems: (reply_id !== null && reply_id !== '') ? that.getRepliedMessageItems(reply_id) : null,
              is_deleted: 0,
              document_name: document_name,
              document_extension: (document_name !== null && document_name !== undefined && document_name !== '') ? document_name.split('.')[document_name.split('.').length - 1] : null
            });
            // console.log({sender_mail,sender_name,message,time,session_id,sender_id,receiver_id,reply_id, chat_type, liabilityDetails, message_id});
            if(liabilityDetails.id !== null && liabilityDetails.id !== '' && liabilityDetails.id !== undefined) { 
              that.emitter.emit('ReceiveLiabilityMessage', liabilityDetails.id);
            }
            that.getUnreadMessageCount(receiver_id, sender_id, chat_type, liabilityDetails.id);
            try {
              that.$nextTick(() => {
                if(that.activeChat.id !== null) { that.$refs.kt_chat_messenger_body.scrollTop = that.$refs.kt_chat_messenger_body.scrollHeight; }
              });
            } catch (Err) {
              console.log(Err);
            }      
          });
          this.connection.on("DeleteMessage", function (messageId) {
            that.messages.forEach(element => {
              if(element.id == messageId) { element.is_deleted = 1; }
            });
          });
          // this.connection.on("GetChatbiMessages");
          this.connection.start().then(() => {
            this.connection.invoke("JoinAllGroups", this.$getUser().session_id)
            .catch(err => {
              console.log(err);
            });
          }).catch(err => {
            console.log(err);
          });

        }
        if(this.userData.id !== null && this.userData.id !== '' && this.userData.id !== undefined) {this.getTotalMessageCount();}
      });
    }

    this.emitter.on("isExistPendingLicenceRequest", () => {
      this.userData = this.$getUser();
    });
  },
  mounted() {
    this.emitter.on("openChatBi", item => {
      this.openChatBi(item);
    });
    this.emitter.on("CompanyLogo", item => {
      this.userData.company_logo = item;
    });
    this.emitter.on("RefreshUserData", item => {
      this.userData = item;
    });
  }
};
</script>