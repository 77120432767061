<template>
    <div>
        <div v-if="modalType == null" class="card card-xxl-stretch border border-gray-300">
            <div class="card-body">
                <div class="d-flex flex-stack flex-wrap mb-5">
                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="openModal('add')">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                        fill="currentColor">
                                    </rect>
                                </svg>
                            </span>
                            {{ $t('Add') }}
                        </button>
                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" @click="openModal('edit')" :disabled="this.isButtonDisabled == null || this.isButtonDisabled == undefined">
                            <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                    </svg>
                            </span>
                            {{ $t('Edit') }}
                        </button>
                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteCoverageParameterItem()"
                            :disabled="this.isButtonDisabled == null || this.isButtonDisabled == undefined">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                            {{ $t('Remove') }}
                        </button>
                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllCoverageParameters()"
                            :disabled="this.coverageParametersList.length == 0">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                            {{ $t('Remove All (!)') }}
                        </button>
                    </div>
                </div>
                <DxDataGrid id="gridContainer" :show-borders="true" 
                    key-expr="id" 
                    :data-source="this.coverageParametersList" 
                    :allow-column-reordering="true"  
                    :allow-column-resizing="true" 
                    :column-auto-width="true" 
                    :row-alternatin-enabled="true"
                    :hover-state-enabled="true" 
                    :show-row-lines="true" 
                    @exporting="this.$onExporting($event , 'Coverage-Parameters')" 
                    :ref="'dataGrid'"
                    :v-model:page-size="pageSize"
                    :v-model:page-index="pageIndex"> 

                    <DxFilterRow :visible="false"/>
                    <DxHeaderFilter :visible="false"/>
                    <DxGroupPanel :visible="false"/>      
                    <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                    <DxPaging :page-size="10"/>
                    <DxPager :visible="false" :show-info="true" :show-navigation-buttons="true"/>  
                    <DxSearchPanel :visible="false"/>
                    
                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                    <DxColumn data-field="group_name" caption="Group" cell-template="group_name-cell-template"/>  
                    <DxColumn data-field="coverage_code" caption="Code" cell-template="coverage_code-cell-template"/>  
                    <DxColumn data-field="coverage_name" caption="Name" cell-template="coverage_name-cell-template"/>  

                    <template #is-selected-cell-template="{ data }"> 
                        <span>
                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                            </div>
                        </span>
                    </template>
                    <template #group_name-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                            {{ data.data.group_name }}
                        </a>
                    </template> 
                    <template #coverage_code-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                            {{ data.data.coverage_code }}
                        </a>
                    </template> 
                    <template #coverage_name-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                            {{ data.data.coverage_name }}
                        </a>
                    </template> 
                </DxDataGrid>
            </div>
        </div>

        <div class="card card-xxl-stretch border border-gray-300" v-if="modalType !== null">
            <div class="card-header">
                <div class="card-title">
                    <span v-if="modalType == 'add'">{{ $t('Add Parameter') }}</span>
                    <span v-if="modalType == 'edit'">{{ $t('Edit Parameter') }}</span>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <form class="form">
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'group_name'">
                                {{ $t('Group Name') }}
                            </label>
                            <input type="text" class="form-control" name="group_name" v-model="coverageParameterForm.group_name" v-custom>
                        </div>
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'coverage_code'">
                                {{ $t('Code') }}
                            </label> 
                            <input type="text" class="form-control" name="coverage_code" v-model="coverageParameterForm.coverage_code" v-custom>
                        </div>
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'coverage_name'">
                                {{ $t('Name') }}
                            </label>
                            <input type="text" class="form-control" name="coverage_name" v-model="coverageParameterForm.coverage_name" v-custom>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-footer py-3">
                <div class="row">
                    <div class="col-lg-12">
                        <button type="button" class="btn btn-default shadow-sm border border-gray-400 btn-lg float-start" @click="this.modalType = null">
                            <span class="indicator-label">{{ $t('Cancel') }}</span> 
                        </button>
                        <button v-if="this.modalType == 'add'" type="button" class="btn btn-light-success border border-success btn-lg float-end" @click="addCoverageParameterItem()">
                            <span class="indicator-label">{{ $t('Save Parameter') }}</span> 
                        </button>
                        <button v-if="this.modalType == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg float-end" @click="editCoverageParameterItem()">
                            <span class="indicator-label">{{ $t('Edit Parameter') }}</span> 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,  DxSearchPanel } from 'devextreme-vue/data-grid';
export default {
    name: 'CoverageParameters',
    data() {
        return {
            coverageParameterForm: {
                id: null,
                coverage_code: null,
                coverage_name: null,
                group_name: null,
            },
            isItemSaving: false,
            coverageParametersList: [],
            modalType: null
        }
    },
    components: {
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,  DxSearchPanel
    },
    computed: {
        isButtonDisabled() {
            return this.coverageParametersList.find(item => item.isSelected == true );
        }
    },
    methods: {
        getData() {
            this.modalType = null;
            const parameters = {
                session_id: this.$getUser().session_id,
                account_id: this.$getSessionStorage('record_id'),
                group_name: null
            };  
            axios.post('/api/SupplierAccount/GetCoverageParameters', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.coverageParametersList = response.data;
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        addCoverageParameterItem(){
            this.isItemSaving = true;
            const parameters = {
                session_id: this.$getUser().session_id,
                parent_account_id: this.$getSessionStorage('record_id'),
                group_name: this.coverageParameterForm.group_name,
                coverage_code: this.coverageParameterForm.coverage_code,
                coverage_name: this.coverageParameterForm.coverage_name
            };
            const that = this;
            axios.post('/api/SupplierAccount/AddCoverageParameter', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.coverageParametersList = response.data;
                    this.modalType = null;
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        editCoverageParameterItem(){
            this.isItemSaving = true;
            const parameters = {
                id: this.coverageParameterForm.id,
                session_id: this.$getUser().session_id,
                parent_account_id: this.$getSessionStorage('record_id'),
                group_name: this.coverageParameterForm.group_name,
                coverage_code: this.coverageParameterForm.coverage_code,
                coverage_name: this.coverageParameterForm.coverage_name
            };
            const that = this;
            axios.post('/api/SupplierAccount/EditCoverageParameter', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.coverageParametersList = response.data;
                    this.modalType = null;
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        deleteCoverageParameterItem(){
            this.coverageParametersList.forEach((item) => {
                if (item.isSelected) {
                    this.coverageParameterForm.id = item.id;
                }
            });
            this.$swal.fire({
                title: "Are you sure you want to remove this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        parent_account_id: this.$getSessionStorage('record_id'),
                        id: this.coverageParameterForm.id,
                        is_delete_all: 0
                    }; 
                    axios.post('/api/SupplierAccount/DeleteCoverageParameter', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.coverageParametersList = response.data;
                        } 
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        deleteAllCoverageParameters(){ 
            this.$swal.fire({
                title: "Are you sure you want to remove all items?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        parent_account_id: this.$getSessionStorage('record_id'),
                        is_delete_all: 1,
                        id: null
                    }; 
                    axios.post('/api/SupplierAccount/DeleteCoverageParameter', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.coverageParametersList = [];
                        } 
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        openModal(modalType) {
            this.modalType = modalType;
            this.coverageParameterForm = {
                id: null,
                coverage_code: null,
                coverage_name: null,
                group_name: null,
            };
            if(modalType == 'edit'){
                this.coverageParametersList.forEach(element => {
                    if(element.isSelected) {
                        this.coverageParameterForm = {
                            id: element.id,
                            coverage_code: element.coverage_code,
                            coverage_name: element.coverage_name,
                            group_name: element.group_name,
                        };
                    }
                });
            }
        },
    onSelectItem(item) { 
        this.coverageParametersList.forEach((value) => {
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },
    },
    created() {
        this.getData();
    }
}
</script>