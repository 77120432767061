<template>
    <div>
        <div class="card-body">
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                    <div class="p-3">
                        <div class="d-flex flex-stack flex-wrap mb-2">
                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                            </div>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">    
                            <div class="table-responsive-lg">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="this.$parent.transactionList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true"
                                    @exporting="this.$onExporting($event , 'Policy-transactions')"
                                    :customize-columns="this.$parent.onCustomizeColumn"> 

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="10"/>
                                    <DxPager :visible="false" :allowed-page-sizes="this.$parent.pageSizes(this.$parent.transactionList, 10)" :show-page-size-selector="this.$parent.showPageSizeSelector(this.$parent.transactionList, 10)" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                    <!-- <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="right" cell-template="is-selected-cell-template" :allow-exporting="false"/>  -->
                                    <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Date')" alignment="right" cell-template="regulation_date-cell-template"/> 
                                    <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/> 
                                    <DxColumn data-field="endorsement_type_name" :caption="$t('Endorsement Type')" header-cell-template="endorsement_type_name-header" cell-template="endorsement_type_name-cell-template"/> 
                                    <DxColumn data-field="producer_name" :caption="$t('Producer')" header-cell-template="producer_policy_type-header" cell-template="producer_name-cell-template"/> 
                                    <DxColumn data-field="start_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Start Date')" alignment="right" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                    <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                                    <DxColumn data-field="policy_net_amount_local" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" header-cell-template="policy_net_amount_local-header" alignment="right" cell-template="policy_net_amount_local-cell-template"/> 
                                    <DxColumn data-field="policy_tax_amount_local" :sorting-method="this.$numericSort" :caption="$t('Taxes')" alignment="right" header-cell-template="policy_tax_amount_local-header" cell-template="policy_tax_amount_local-cell-template"/> 
                                    <DxColumn data-field="policy_total_amount_local" :sorting-method="this.$numericSort" :caption="$t('Total Amount')" header-cell-template="policy_total_amount_local-header" alignment="right" cell-template="policy_total_amount_local-cell-template"/> 
                                    <DxColumn data-field="insurance_commission_local" :sorting-method="this.$numericSort" :caption="$t('Commission')" alignment="right" header-cell-template="insurance_commission_local-header" cell-template="insurance_commission_local-cell-template"/> 
                                    <DxColumn data-field="payment_amount_local" :sorting-method="this.$numericSort" :caption="$t('Producer Payment')" header-cell-template="payment_amount_local-header" alignment="right" cell-template="payment_amount_local-cell-template"/> 
                                    <DxColumn data-field="currency" :caption="$t('Curr.')" header-cell-template="currency-header" cell-template="currency-cell-template"/> 
                                    <DxColumn data-field="policy_net_amount" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" header-cell-template="policy_net_amount-header" alignment="right" cell-template="policy_net_amount-cell-template"/> 
                                    <DxColumn data-field="policy_tax_amount" :sorting-method="this.$numericSort"  :caption="$t('Taxes')" alignment="right" cell-template="policy_tax_amount-cell-template"/> 
                                    <DxColumn data-field="policy_total_amount" :sorting-method="this.$numericSort" :caption="$t('Total')" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                                    <DxColumn data-field="insurance_commission" :sorting-method="this.$numericSort" :caption="$t('Commission')" alignment="right" cell-template="insurance_commission-cell-template"/> 
                                    <DxColumn data-field="payment_amount" :sorting-method="this.$numericSort" :caption="$t('Producer Payment')" header-cell-template="payment_amount-header" alignment="right" cell-template="payment_amount-cell-template"/>

                                    <template #headerLocalTemplate="{ data: data }">
                                        <div v-html="this.$parent.setColumnTemplateForLocal(data)"></div> 
                                    </template>

                                    <template #policy_type_name-header>
                                        <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                                    </template> 
                                    <template #currency-header>
                                        <div>{{ $t('Curr.') }}</div><div>{{ $t('Rate') }}</div>
                                    </template> 
                                    <template #endorsement_type_name-header>
                                        <div>{{ $t('Endorsement') }}</div><div>{{ $t('Type/Detail') }}</div>
                                    </template> 
                                    <template #start_date-header>
                                        <div>{{ $t('Start') }}</div><div>{{ $t('End') }}</div>
                                    </template> 
                                    <template #policy_no-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #policy_net_amount-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('Net') }}</div>
                                    </template> 
                                    <template #policy_net_amount_local-header>
                                        <div>{{ $t('Net') }}</div><div>{{ $t('Local') }}</div>
                                    </template> 
                                    <template #policy_total_amount_local-header>
                                        <div>{{ $t('Total') }}</div><div>{{ $t('Local') }}</div>
                                    </template> 
                                    <template #policy_tax_amount_local-header>
                                        <div>{{ $t('Taxes') }}</div><div>{{ $t('Local') }}</div>
                                    </template> 
                                    <template #policy_tax_amount-header>
                                        <div>{{ $t('Tax') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #producer_policy_type-header>
                                        <div>{{ $t('Producer') }}</div><div>{{ $t('/Policy Type') }}</div>
                                    </template>
                                    <template #payment_amount-header>
                                        <div>{{ $t('Producer') }}</div><div>{{ $t('Payment') }}</div>
                                    </template>
                                    <template #payment_amount_local-header>
                                        <div>{{ $t('Producer') }}</div><div>{{ $t('Payment Local') }}</div>
                                    </template>
                                    <template #insurance_commission_local-header>
                                        <div>{{ $t('Commission') }}</div><div>{{ $t('Local') }}</div>
                                    </template>

                                    <!-- <template #is-selected-cell-template="{ data }">
                                        <span class="align-middle text-gray-900 text-start">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(transactionList, data.data)" />
                                            </div>
                                        </span>
                                    </template>                                                   -->
                                    <template #policy_type_name-cell-template="{ data }">
                                        <span class="align-middle text-gray-900 text-start">
                                            <div>{{data.data.policy_type_name}}</div>
                                        </span>
                                    </template>
                                    <template #is_new_or_renewal-cell-template="{ data }">
                                        <span class="align-middle text-gray-900 text-start">
                                            <div>{{data.data.is_new_or_renewal}}</div>
                                        </span>
                                    </template>
                                    <template #endorsement_type_name-cell-template="{ data }">
                                        <span class="align-middle text-gray-700 text-start">
                                            <div>{{data.data.endorsement_type_name}}</div> 
                                            <div>{{data.data.endorsement_detail_name}}</div> 
                                        </span>   
                                    </template>
                                    <template #producer_name-cell-template="{ data }">
                                        <span class="align-middle text-gray-900 text-start">
                                            <div> {{data.data.producer_name}}</div>
                                            <div> {{data.data.producer_policy_type_name}}</div>
                                        </span>   
                                    </template>
                                    <template #regulation_date-cell-template="{ data }">
                                        <span class="align-middle text-gray-700 text-end">
                                            <div>{{this.$parent.previewDate(data.data.regulation_date)}}</div>
                                            <div>{{data.data.is_new_or_renewal}}</div>
                                        </span>   
                                    </template>
                                    <template #start_date-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{this.$parent.previewDate(data.data.start_date) }}</div>
                                            <div>{{this.$parent.previewDate(data.data.end_date) }}</div>
                                        </span>   
                                    </template>
                                    <template #policy_no-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}</div>
                                        </span>   
                                    </template>
                                    <template #currency-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.currency }}</div>
                                            <div>{{data.data.rate }}</div>
                                        </span>   
                                    </template>
                                    <template #policy_net_amount-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_net_amount }}</div>
                                        </span>   
                                    </template>
                                    <template #insurance_commission-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.insurance_commission }}</div>
                                        </span>   
                                    </template>
                                    <template #payment_amount-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.payment_amount }}</div>
                                        </span>   
                                    </template>
                                    <template #policy_tax_amount-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_tax_amount}}</div>
                                            <div v-if="data.data.sub_tax_1_amount !== '0.00' && data.data.sub_tax_1_amount !== '0' && data.data.sub_tax_1_amount !== 0">
                                                {{data.data.sub_tax_1_amount}}
                                            </div>
                                            <div v-if="data.data.sub_tax_2_amount !== '0.00' && data.data.sub_tax_2_amount !== '0' && data.data.sub_tax_2_amount !== 0">
                                                {{data.data.sub_tax_2_amount}}
                                            </div>
                                            <div v-if="data.data.sub_tax_3_amount !== '0.00' && data.data.sub_tax_3_amount !== '0' && data.data.sub_tax_3_amount !== 0">
                                                {{data.data.sub_tax_3_amount}}
                                            </div>
                                        </span>   
                                    </template>
                                    <template #policy_total_amount-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_total_amount }}</div>
                                        </span>   
                                    </template>                                                    
                                    <template #policy_net_amount_local-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_net_amount_local }}</div>
                                        </span>   
                                    </template>
                                    <template #insurance_commission_local-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.insurance_commission_local }}</div>
                                        </span>   
                                    </template>
                                    <template #payment_amount_local-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.payment_amount_local }}</div>
                                        </span>   
                                    </template>
                                    <template #policy_tax_amount_local-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_tax_amount_local}}</div>                                                            
                                            <div v-if="data.data.sub_tax_1_amount_local !== '0.00' && data.data.sub_tax_1_amount_local !== '0' && data.data.sub_tax_1_amount_local !== 0">
                                                {{data.data.sub_tax_1_amount_local}}
                                            </div>
                                            <div v-if="data.data.sub_tax_2_amount_local !== '0.00' && data.data.sub_tax_2_amount_local !== '0' && data.data.sub_tax_2_amount_local !== 0">
                                                {{data.data.sub_tax_2_amount_local}}
                                            </div>
                                            <div v-if="data.data.sub_tax_3_amount_local !== '0.00' && data.data.sub_tax_3_amount_local !== '0' && data.data.sub_tax_3_amount_local !== 0">
                                                {{data.data.sub_tax_3_amount_local}}
                                            </div>
                                        </span>   
                                    </template>
                                    <template #policy_total_amount_local-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            <div>{{data.data.policy_total_amount_local }}</div>
                                        </span>   
                                    </template>
                                    
                                    <DxSummary :calculate-custom-summary="this.setDetailTransactionSummaries">
                                        <DxTotalItem
                                            name="policy_net_amount"
                                            summary-type="custom"
                                            show-in-column="policy_net_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="policy_net_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_net_amount_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="policy_total_amount"
                                            summary-type="custom"
                                            show-in-column="policy_total_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="policy_total_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_total_amount_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="policy_tax_amount"
                                            summary-type="custom"
                                            show-in-column="policy_tax_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="policy_tax_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_tax_amount_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="insurance_commission"
                                            summary-type="custom"
                                            show-in-column="insurance_commission"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="insurance_commission_local"
                                            summary-type="custom"
                                            show-in-column="insurance_commission_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="payment_amount"
                                            summary-type="custom"
                                            show-in-column="payment_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="payment_amount_local"
                                            summary-type="custom"
                                            show-in-column="payment_amount_local"
                                            display-format="{0}"
                                        />

                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_net_amount"
                                            summary-type="custom"
                                            show-in-column="policy_net_amount"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_net_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_net_amount_local"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_total_amount"
                                            summary-type="custom"
                                            show-in-column="policy_total_amount"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_total_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_total_amount_local"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_tax_amount"
                                            summary-type="custom"
                                            show-in-column="policy_tax_amount"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="policy_tax_amount_local"
                                            summary-type="custom"
                                            show-in-column="policy_tax_amount_local"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="insurance_commission"
                                            summary-type="custom"
                                            show-in-column="insurance_commission"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="insurance_commission_local"
                                            summary-type="custom"
                                            show-in-column="insurance_commission_local"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="payment_amount"
                                            summary-type="custom"
                                            show-in-column="payment_amount"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="payment_amount_local"
                                            summary-type="custom"
                                            show-in-column="payment_amount_local"
                                            display-format="{0}"
                                        /> 
                                    </DxSummary>
                                </DxDataGrid>   
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling, DxTotalItem, DxGroupItem, DxSummary } from 'devextreme-vue/data-grid';
export default {
    name: 'Commissions',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling, DxTotalItem, DxGroupItem, DxSummary
    },
    methods: {        
        setDetailTransactionSummaries(options){
            const optionNames = ["policy_net_amount", "policy_net_amount_local", "policy_total_amount", "policy_total_amount_local", "policy_tax_amount", "policy_tax_amount_local", "insurance_commission", "insurance_commission_local", "payment_amount", "payment_amount_local"];
            optionNames.forEach(item => {
                if(options.name == item){
                    if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                    else if(options.summaryProcess === 'calculate'){
                        var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                        (options.totalValue += itemValue);
                    }
                    else if(options.summaryProcess === 'finalize'){ 
                        options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                    }
                }
            });
        }
    }
}
</script>