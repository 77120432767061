<template> 
  <div>
    <div class="page-title flex-row me-3 mt-3"> 
        <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Policy List') }}
        </h1> 
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                </a>
            </li> 
            <li class="breadcrumb-item text-gray-600">{{ $t('Policy List') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </li> 
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <div class="card">
            <div class="card-header pt-6">
                <div class="card-title">
                    <router-link to="/PolicyCreate" v-if="this.$isCredentialActive(48,'I')">
                        <button type="button" class="btn btn-light-primary border border-primary me-3">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Create New Policy') }}
                        </button>
                    </router-link> 
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                        <button type="button" class="btn btn-light-primary border border-primary me-3" data-bs-toggle="modal" 
                            data-bs-target="#kt_search_policies_modal" @click="openSearchModal()">
                            <i class="fa fa-search"></i>
                            {{ $t('Search') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework rounded-2">
                            <div class="fv-row mb-7 row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Date Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.date_type" :options="dateTypeList" @select="onChangeStartDate()" :settings="{width: '100%' }" />
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" @update:modelValue="onChangeStartDate()"></Datepicker>  
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Insurance Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.insurance_type" :options="insuranceTypeList" :settings="{width: '100%'}" />
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Main Branch') }}</span>
                                    </label>
                                    <Select2 v-model="form.main_branch_id" :options="mainBranchList" @select="getPolicyParentList()" :settings="{width: '100%'}" />
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Sub Branch') }}</span>
                                    </label>
                                    <Select2 v-model="form.policy_branch_id" :options="policyBranchList" :settings="{width: '100%'}" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3" v-if="this.$isCredentialActive(48,'R')">                        
                        <div class="d-flex justify-content-end align-items-center mt-8" data-kt-customer-table-toolbar="base"> 
                            <Popper hover placement="bottom">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button type="button" class="btn btn-light border border-gray-400" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('All Policies') }} </span>
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                    </div>
                </div>
                <div class="separator my-5 mt-0 border-gray-300"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">                    
                    <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                        key-expr="id" 
                        :data-source="policyList" 
                        :allow-column-reordering="true"  
                        :allow-column-resizing="true" 
                        :column-auto-width="true" 
                        :row-alternatin-enabled="true"
                        :hover-state-enabled="true" 
                        :show-row-lines="true" 
                        @row-prepared="onRowPrepared" 
                        @exporting="this.$onExporting($event , 'Policy-list')"
                        :ref="'dataGrid'"
                        :customize-columns="onCustomizeColumn"> 

                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>      
                        <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                        <DxPaging :page-size="pageSize"/>
                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                        <DxSearchPanel :visible="false"/>
                        <DxScrolling
                            mode="virtual"
                            row-rendering-mode="virtual"
                        />
                        <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" alignment="right" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                        <DxColumn data-field="start_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Start Date')" alignment="right" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                        <DxColumn data-field="end_date" data-type="date" format="dd.MM.yyyy" :caption="$t('End Date')" alignment="right" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                        <DxColumn data-field="account_name" :caption="$t('Account')" cell-template="account_name-cell-template"/>   
                        <DxColumn data-field="insurance_type_name" :caption="$t('Insurance Type')" header-cell-template="insurance_type-header" cell-template="insurance_type-cell-template"/> 
                        <DxColumn
                            alignment="center"
                            :caption="$t('Policy')"
                            :fixed="false"
                            fixed-position="right">
                            <DxColumn data-field="main_branch_name" :caption="$t('Main Branch')" cell-template="main_branch_name-cell-template"/> 
                            <DxColumn data-field="policy_branch_name" :caption="$t('Sub Branch')" cell-template="policy_branch_name-cell-template"/> 
                        </DxColumn>
                        <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/>
                        <DxColumn data-field="is_new_or_renewal" :caption="$t('New/Renewal')" header-cell-template="is_new_or_renewal-header" cell-template="is_new_or_renewal-cell-template"/> 
                        <DxColumn
                            alignment="center"
                            :caption="$t('Endorsement')"
                            :fixed="false"
                            fixed-position="right">
                            <DxColumn data-field="endorsement_type_name" :caption="$t('Type')" cell-template="endorsement_type_name-cell-template"/> 
                            <DxColumn data-field="endorsement_detail_name" :caption="$t('Detail')" cell-template="endorsement_detail_name-cell-template"/> 
                        </DxColumn>
                        <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                        <DxColumn data-field="renewal_no" :caption="$t('Renewal No')" header-cell-template="renewal_no-header" cell-template="renewal_no-cell-template"/> 
                        <DxColumn data-field="endorsement_no" :caption="$t('Endorsement No')" header-cell-template="endorsement_no-header" cell-template="endorsement_no-cell-template"/> 
                        <DxColumn data-field="vehicle_registration_number" :caption="$t('Vehicle')" cell-template="vehicle_registration_number-cell-template"/> 
                        <DxColumn data-field="property_registration_number" :caption="$t('Property')" cell-template="property_registration_number-cell-template"/> 
                        <DxColumn
                            alignment="center"
                            :caption="$t('Producer')"
                            :fixed="false"
                            fixed-position="right">
                            <DxColumn data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                            <DxColumn data-field="producer_policy_type_name" :caption="$t('Regulation Type')" cell-template="producer_policy_type_name-cell-template"/> 
                        </DxColumn>
                        <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/> 
                        <DxColumn data-field="group_name" :caption="$t('Group')" cell-template="group_name-cell-template"/> 
                        <DxColumn data-field="vendor_name" :caption="$t('Vendors')" cell-template="vendor_name-cell-template"/> 

                        <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                        <template #headerLocalTemplate="{ data: data }">
                            <div v-html="setColumnTemplateForLocal(data)"></div> 
                        </template>

                        <template #regulation_date-header>
                            <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                        </template>
                        <template #start_date-header>
                            <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                        </template>
                        <template #end_date-header>
                            <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                        </template>
                        <template #insurance_type-header>
                            <div>{{ $t('Insurance') }}</div><div>{{ $t('Type') }}</div>
                        </template>
                        <template #account_name-header>
                            <div>{{ $t('Account') }}</div><div>{{ $t('Name') }}</div>
                        </template>
                        <template #policy_no-header>
                            <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                        </template>
                        <template #renewal_no-header>
                            <div>{{ $t('Renewal') }}</div><div>{{ $t('No') }}</div>
                        </template>
                        <template #endorsement_no-header>
                            <div>{{ $t('Endorsement') }}</div><div>{{ $t('No') }}</div>
                        </template>
                        <template #policy_type_name-header>
                            <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                        </template> 
                        <template #is_new_or_renewal-header>
                            <div>{{ $t('New/') }}</div><div>{{ $t('Renewal') }}</div>
                        </template> 
                        
                            <!-- Cell Templates -->
                        <template #regulation_date-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ previewDate(data.data.regulation_date) }} 
                            </a>
                        </template>
                        <template #start_date-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ previewDate(data.data.start_date) }} 
                            </a>
                        </template>
                        <template #end_date-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ previewDate(data.data.end_date) }} 
                            </a>
                        </template>
                        <template #account_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                <span>{{ data.data.account_name }}</span>
                                <span class="badge badge-light-danger ms-5 float-end" v-if="data.data.is_transaction_exist == 0">{{ $t('No Transaction') }}</span>
                            </a>
                        </template>
                        <template #insurance_type-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.insurance_type_name }}  
                            </a>
                        </template>
                        <template #main_branch_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.main_branch_name }}  
                            </a>
                        </template>
                        <template #policy_branch_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.policy_branch_name }}  
                            </a>
                        </template>
                            <template #policy_no-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.policy_no }}  
                            </a>
                        </template>
                        <template #renewal_no-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.renewal_no }}  
                            </a>
                        </template>
                        <template #endorsement_no-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.endorsement_no }}  
                            </a>
                        </template>
                        <template #policy_type_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.policy_type_name }}  
                            </a>
                        </template>
                        <template #is_new_or_renewal-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.is_new_or_renewal }}  
                            </a>
                        </template>
                        <template #endorsement_type_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.endorsement_type_name }}  
                            </a>
                        </template>
                        <template #endorsement_detail_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.endorsement_detail_name }}  
                            </a>
                        </template>
                        <template #producer_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.producer_name }}  
                            </a>
                        </template>
                        <template #producer_policy_type_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.producer_policy_type_name }}  
                            </a>
                        </template>
                        <template #group_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.group_name }}  
                            </a>
                        </template>
                        <template #currency-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.currency }}  
                            </a>
                        </template>
                        <template #vendor_name-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.vendor_name }}  
                            </a>
                        </template>
                        <template #vehicle_registration_number-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.vehicle_registration_number }}  
                            </a>
                        </template>
                        <template #property_registration_number-cell-template="{ data }"> 
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updatePolicy(data.data.policy_card_id)">
                                {{ data.data.property_registration_number }}  
                            </a>
                        </template>
                        
                        <DxStateStoring
                            :enabled="true"
                            type="custom"
                            :storage-key="this.storageKey"
                            :ref="dataGrid" 
                            :saving-timeout="1000"
                            :custom-load="LoadDXState"
                        />
                    </DxDataGrid>   
                </div> 
            </div>
        </div> 
    </div>

    <!-- Search Account Modal -->
    <div class="modal fade" id="kt_search_policies_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <SearchPolicies ref="SearchPolicies"></SearchPolicies>
    </div> 
  </div>  
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import SearchPolicies from '../Policies/Modals/SearchPolicies.vue';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling, DxStateStoring } from 'devextreme-vue/data-grid';

import DataGrid from "devextreme/ui/data_grid";

DataGrid.defaultOptions({ 
    options: {
        scrolling: {
            legacyMode: true
        }
    }
});
 
export default {
    name: "PolicyList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, SearchPolicies, DxScrolling, DxStateStoring
    },
 data() {
    return { 
        storageKey: 'dx_' + this.$options.name,
        localCurrency: null,
        isOpenActionsDropdown: false,      
        page: 1,
        page_size: 10,
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            insurance_type: 0,
            date_type: 1,
            main_branch_id: '-1',
            policy_branch_id: '-1',
        },
        policyList: [],
        searchbar: '',
        pageSize: 20,
        pageIndex: 0,
        isListProgressing: false,
        menuSelection:'/PoliciesHelppage',
        iconView : false,
        insuranceTypeList: [
          { id: 0, text: 'ALL'},
          { id: 1, text: 'Insurance'},
          { id: 2, text: 'Reinsurance'},
          { id: 3, text: 'Coinsurance'}
        ],
        dateTypeList: [
            { id: 1, text: 'Regulation'},
            { id: 2, text: 'Start/End'},
        ],
        mainBranchList: [{ id: '-1', text: 'ALL'}],
        policyBranchList: [{ id: '-1', text: 'ALL'}] 
    };
  },
  computed: { 
    setColumnTemplateForLocal(){
        return (value)=>{
            return this.localCurrency == null ? value.column.caption.replace('Local', '') :  this.localCurrency + '<br>' + value.column.caption.replace('Local', '');
        }
   },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.policyList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.policyList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: { 
    getData(searchText, searchParameter) {
        this.policyList = [];
        this.$Progress.start(); 
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = (this.form.end_date == null || this.form.end_date == '') ? moment() : this.form.end_date;
        const params = {
            session_id: this.$getUser().session_id,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            insurance_type: this.form.insurance_type,
            date_type: this.form.date_type,
            main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id,
            search_text: searchText,
            search_parameter: (searchText !== null && searchText !== '' && searchText !== undefined) ? searchParameter : 0
        };
        if(params.insurance_type == 0) { params.insurance_type = null }
        params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        axios.post('api/Insurance/GetPolicyTransactionAll', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.policyList = response.data;
            }
            this.page = 1;
            this.localCurrency = this.$getUser().currency_code; 
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function () {
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
    },
    getLists() {
        const parameters = {
            session_id: this.$getUser().session_id,
            list_type: 1 /* Main Branch */
        };
        const requestOne =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.mainBranchList= responses[0].data;
            this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getPolicyParentList() {
        this.form.policy_branch_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 2, // Policy Branch: 2 
            parent_id: this.form.main_branch_id
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyBranchList = response.data;
            this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    updatePolicy(id){
        this.$setSessionStorage('record_id', id);
        this.$router.push({ name: 'PolicyUpdate' });
    },
    setCriteriasToDefault() {   
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            insurance_type: 0,
            date_type: 1,
            main_branch_id: '-1',
            policy_branch_id: '-1',
        };
        this.policyList = [];
    },
    onClickDropdown(item) {
        this.policyList.forEach(element => {
            if (item.id !== element.id) {
                element.isOpenActionsDropdown = false;
            }
        });
        item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                     if(column.dataField == 'gross_amount_local'){ 
                         column.caption =  'Grand Total Local';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/PoliciesHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onChangeStartDate() {
        if(this.form.date_type == 2) {
            this.form.end_date = moment(this.form.start_date).add(1, 'year');
        } else {
            this.form.end_date = moment();
        }
    },
    openSearchModal() {
        this.$refs.SearchPolicies.getData();
    },
    onRowPrepared(e){ 
        if(e.rowType === 'data') {
            if(e.data.is_transaction_exist == 0) { 
                e.rowElement.classList.add('bg-light-danger');  
            } 
        } 
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getLists();
  }
}; 
</script>