<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('User Groups') }}</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserManagement' }">{{ $t('User Management') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600">{{ $t('User Groups') }}</li> 
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/UserManagement">
            <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> {{ $t('Return back to User Management') }} </span>
            </button>
        </router-link>
      </div>  
    </div>
  <div class="content flex-row-fluid mt-4" id="kt_content">
      <div class="card">
          <div class="card-header border-0 pt-6">
              <div class="card-title">
                  <router-link to="/UserGroupCreate">
                      <button type="button" class="btn btn-light-primary border border-primary me-3"><span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg>
                        </span>{{ $t('Add User Group') }}
                      </button>
                  </router-link>
              </div>
              <div class="card-toolbar">
                  <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <strong v-if="!this.isListProgressing"> {{ $t('List') }} </strong> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                  </div> 
              </div>
          </div>
          <div class="card-body pt-0">
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">                  
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            @exporting="this.$onExporting($event , 'User-Group-List')">

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                              mode="virtual"
                              row-rendering-mode="virtual"
                            />
                            <DxColumn data-field="name" :caption="$t('Group Name')" cell-template="name-cell-template"/> 
                            <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/> 
                            <DxColumn data-field="is_active" :caption="$t('Active?')" cell-template="is_active-cell-template" alignment="center"/> 
                            <DxColumn data-field="created_at" :caption="$t('Date')" alignment="center" cell-template="created_at-cell-template"/> 

                            <template #name-cell-template="{ data }">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                    {{ data.data.name }}
                                </a>
                            </template>
                            <template #description-cell-template="{ data }">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                    {{ data.data.description }}
                                </a>
                            </template>
                            <template #is_active-cell-template="{ data }">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                  <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_active">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="updateItem(data.data.id)">
                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                      <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                    </svg>
                                  </span>
                                </a>
                            </template>
                            <template #created_at-cell-template="{ data }">
                                <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                    {{ previewDate(data.data.created_at) }}
                                </a>
                            </template>
                    </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div> 
    
  </div>  
</template>

<script>
import axios from 'axios';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
import moment from 'moment'; 
 
export default {
  name: "UserGroupList", 
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      lists:[],
      isListProgressing: false,
      menuSelection: '/UserGroupHelppage',
      iconView : false,
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() {  
      this.$Progress.start();
      this.lists = [];
      this.isListProgressing = true;
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/UserGroup/Get', params, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.lists = response.data;
        this.$Progress.finish();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      })
      .catch(function (error) {
        console.log(error); 
        this.$Progress.fail();
        setTimeout(() => {
          this.isListProgressing = false;
        }, 500);
      });
    },
    updateItem(item){
      this.$setSessionStorage("record_id", item);
      this.$router.push({ name: 'UserGroupUpdate' }); 
    },
    async helpPageViewIcon()
    {
      const queryparameter = {
          session_id : this.$getUser().session_id,
          menu_selection : '/UserGroupHelppage'
      };
      axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
      .then((response) => { 
          if(!response.data.error){ 
              this.iconView = response.data;
          }
      })
    },        
  }, 
  created() {
    this.helpPageViewIcon();
    if(this.$getSessionStorage('refreshList')){
      this.getData();
      this.$removeSessionStorage('refreshList');
    } 
    else {
      this.lists = [];
    }
  }
}; 
</script>