<template>
    <div>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header p-5 bg-primary">
                    <h2 class="text-white">Collection Plan</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 svg-icon-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-1"> 
                      <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="id" 
                            :data-source="collectionPlans" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true"
                            @exporting="this.$onExporting($event, 'payment-plans')"
                            :customize-columns="onCustomizeColumn"> 

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="100000"/>
                            <DxSearchPanel :visible="true"/>
                            
                            <DxColumn data-field="installment_no" :caption="$t('Installment No')" alignment="right" cell-template="installment_no-cell-template"/>                            
                            <DxColumn data-field="due_date" :caption="$t('Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="due_date-cell-template"/>
                            <DxColumn data-field="installment_amount" :caption="$t('Installment Amount')" alignment="right" cell-template="installment_amount-cell-template"/>
                            <DxColumn data-field="installment_amount_local" :caption="$t('Installment Amount Local')" alignment="right" cell-template="installment_amount_local-cell-template"/>

                            <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                            <template #headerLocalTemplate="{ data: data }">
                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                            </template>

                            <template #installment_no-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.installment_no }}
                                </div>
                            </template>
                            <template #due_date-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ this.$parent.previewDate(data.data.due_date) }}
                                </div>
                            </template>
                            <template #installment_amount-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.installment_amount }}
                                </div>
                            </template>
                            <template #installment_amount_local-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.installment_amount_local }}
                                </div>
                            </template>

                      </DxDataGrid>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid'; 
export default {
    name:'CollectionPlanModal',
    data(){
        return {
            collectionPlans: [],
            localCurrency: null
        }
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel
    },
    computed: {
        setColumnTemplateForLocal(){
            return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
            }
        }
    },
    methods:{
        getData(item){
            if(!this.$parent.isViewCollectionPlanModal) return;
            this.collectionPlans = [];            
            this.localCurrency = this.$getUser().currency_code;
            const queryParameter = {
                policy_transaction_id: item.id,
                session_id: this.$getUser().session_id
            };  
            axios.post('/api/DLLTemplates/GetTempTransactionPlans', queryParameter, {'Content-Type': 'application/json'}) 
            .then((response) => { 
                this.collectionPlans = response.data; 
            });
        },
        onCustomizeColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {   
                        if(column.dataField == 'installment_amount_local'){ 
                            column.caption =  'Local Installment Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        } 
                    });  
                } 
            } 
        },
    }
}
</script>