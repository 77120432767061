<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Policy') }}
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Policy') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/PolicyList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-2"> 
            <!-- <StatsBar></StatsBar> -->
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row">
                    <div class="card-toolbar">
                        <div class="card card-xxl-stretch ">
                            <div class="card-header border-0 min-h-45px ">
                                <div class="card-title">
                                </div>
                                <div class="d-flex justify-content-end py-4">
                                    &nbsp;
                                    <router-link to="/PolicyCreate" v-if="this.$isCredentialActive(48,'I')">
                                            <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                </svg>
                                                </span>
                                                {{ $t('Add New Policy Card') }}
                                            </button>
                                    </router-link> 
                                    <div class="dropdown" v-if="this.$isCredentialActive(48,'D')">
                                        <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="policyActions" type="button">
                                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                        <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                        </svg></span>
                                            {{ $t('Actions') }}
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="policyActions">
                                            <li><a class="dropdown-item fs-4 text-danger" href="#" @click="deletePolicyCard()">{{ $t('Delete') }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-5">
                    <ul class="nav nav-tabs flex-nowrap w-100 text-nowrap float-end justify-content-end" role="tablist">
                       <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'General'}" @click="this.activeTab = 'General'"
                                data-bs-toggle="tab" href="#kt_policy_tab_1" aria-selected="true" role="tab">{{ $t('Policy Card') }}</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'Transactions'}" @click="this.activeTab = 'Transactions'"
                                data-bs-toggle="tab" href="#kt_policy_tab_3" aria-selected="true" role="tab">{{ $t('Transactions') }}</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'Commission'}" @click="this.activeTab = 'Commission'"
                                data-bs-toggle="tab" href="#kt_policy_tab_2" aria-selected="true" role="tab">{{ $t('Commission') }}</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'Collections'}" @click="this.activeTab = 'Collections'"
                                data-bs-toggle="tab" href="#kt_policy_tab_6" aria-selected="true" role="tab">{{ $t('Collections') }}/{{ $t('Payments') }}</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'Factors'}" @click="this.activeTab = 'Factors'"
                                data-bs-toggle="tab" href="#kt_policy_tab_4" aria-selected="true" role="tab">{{ $t('Factors') }}</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'Coverages'}" @click="this.activeTab = 'Coverages'"
                                data-bs-toggle="tab" href="#kt_policy_tab_5" aria-selected="true" role="tab">{{ $t('Coverages/Load/Person') }}</a>
                        </li>
                    </ul>
                </div>
                <div id="kt_policy_tab_1" class="tab-pane fade show active" v-if="this.activeTab == 'General'" role="tabpanel">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row p-4" :class="{'pt-5': form.activeView == 2}">
                            <div class="row" v-if="form.activeView == 2">
                                <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                        <div class="card-header min-h-40px rounded-2 rounded-bottom-0 bg-gray-gradient">
                                            <div class="card-title">
                                                <h3>{{ $t('Insurer Account') }}</h3>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row">
                                                        <span class="fs-2 fw-bolder text-gray-800">{{selectedAccountInfo.name}}<br>
                                                        <span class="text-gray-700">{{selectedAccountInfo.customer_account_number}}</span></span> 
                                                        <div class="d-flex justify-content-end ms-3">
                                                            <div class="symbol symbol-100px cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()">
                                                                <div class="symbol-label fw-bold bg-light border text-hover-primary">
                                                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 fw-bolder text-gray-700 mt-2">
                                                            {{selectedAccountInfo.customer_address}}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                        <span class="fs-6 text-gray-700 fw-bold"> {{selectedAccountInfo.customer_city}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_district}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_country}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_postcode}} </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                        <a href="{{selectedAccountInfo.customer_email}}" class="fw-bold fs-6"> {{selectedAccountInfo.customer_email}} </a>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_mobile_phone}} </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                       <span class="fs-6 text-gray-700 fw-bolder"> 
                                                            <i class="bi bi-caret-right-fill"></i>
                                                            {{form.insurance_type_name}}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 text-gray-700 fw-bolder"> 
                                                            <i class="bi bi-caret-right-fill"></i>
                                                            {{form.main_branch_name}}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 text-gray-700 fw-bolder"> 
                                                            <i class="bi bi-caret-right-fill"></i>
                                                            {{form.policy_branch_name}} 
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-12">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                        <div class="card-header min-h-40px rounded-2 rounded-bottom-0 bg-gray-gradient">
                                            <div class="card-title">
                                                <h3>{{ $t('Policy Card Detail') }} </h3>
                                            </div>
                                            <div class="card-toolbar m-0">
                                                <button type="button" class="btn btn-icon btn-sm" @click="isFormFieldsDisabled = !isFormFieldsDisabled">
                                                    <i class="bi bi-pencil fs-5" :class="{'text-gray-800': isFormFieldsDisabled, 'text-primary': !isFormFieldsDisabled}"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body pt-2">
                                            <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">                                                
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_producer_id'">
                                                    {{ $t('Producer Company') }}
                                                    </label>
                                                    <Select2 :disabled="isFormFieldsDisabled" v-model="form.main_producer_id" :options="producerList" name="main_producer_id" v-custom :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'regulation_date'">
                                                            {{ $t('Regulation Date') }}
                                                        </label>
                                                        <Datepicker name="regulation_date" :required="this.$checkIfDateFieldRequired ('regulation_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" :disabled="isFormFieldsDisabled" v-model="form.regulation_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'start_date'">
                                                            {{ $t('Start Date') }}
                                                        </label>
                                                        <Datepicker name="start_date" @blur="getYearList(true)" :required="this.$checkIfDateFieldRequired ('start_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" :disabled="isFormFieldsDisabled" v-model="form.start_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'end_date'">
                                                            {{ $t('End Date') }}
                                                        </label>
                                                        <Datepicker name="end_date" :required="this.$checkIfDateFieldRequired ('end_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" :disabled="isFormFieldsDisabled" v-model="form.end_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_no'">
                                                            {{ $t('Policy No') }}
                                                        </label>
                                                        <input type="text" class="form-control fw-boldest text-black-600" name="policy_no" v-custom :disabled="isFormFieldsDisabled" v-model="form.policy_no">
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'renewal_no'">
                                                            {{ $t('Renewal No') }}
                                                        </label>
                                                        <input type="text" class="form-control fw-boldest text-black-600" name="renewal_no" v-custom :disabled="isFormFieldsDisabled" v-model="form.renewal_no">
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'year'">
                                                            {{ $t('Year') }}
                                                        </label>
                                                        <Select2 :disabled="isFormFieldsDisabled" v-model="form.year" :options="yearList" name="year" v-custom :settings="{ width: '100%' }"/> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="form.activeView == 2">
                                <ul class="nav nav-tabs flex-nowrap w-100 text-nowrap px-3 border-0" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                            :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'PolicyTransaction'}" @click="this.activeSubTab = 'PolicyTransaction'"
                                            data-bs-toggle="tab" href="#kt_policy_sub_tab_1" aria-selected="true" role="tab">
                                            {{ $t('Policy Transaction') }}
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                            :class="{'active border border-bottom-0 border-gray-300': this.activeSubTab == 'DefaultVendors'}" @click="this.activeSubTab = 'DefaultVendors'"
                                            data-bs-toggle="tab" href="#kt_policy_sub_tab_2" aria-selected="true" role="tab">
                                            {{ $t('Insurance Vendors') }}
                                        </a>
                                    </li>
                                </ul>
                                
                                <div id="kt_policy_sub_tab_1" class="tab-pane fade show active" v-if="this.activeSubTab == 'PolicyTransaction'" role="tabpanel">
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                            <div class="p-3">
                                                <div class="d-flex flex-stack flex-wrap mb-2">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button ref="openTransactionModal" type="button" class="btn btn-sm btn-success fw-bold required me-3" data-bs-toggle="modal" 
                                                            data-bs-target="#kt_modal_add_item" @click="openTransactionModal('addItem')"
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add Policy Transaction') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-original-title="Coming Soon" data-bs-toggle="modal" ref="btnEditItem"
                                                            data-bs-target="#kt_modal_add_item" @click="openTransactionModal('editItem')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Edit') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" data-bs-toggle="tooltip" title="" @click="deleteTransaction()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Delete') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="dataTables_wrapper dt-bootstrap4 no-footer">    
                                                    <div class="table-responsive-lg">
                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                key-expr="id" 
                                                                :data-source="transactionList" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true" 
                                                                :column-auto-width="true" 
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true" 
                                                                :show-row-lines="true"
                                                                @exporting="this.$onExporting($event , 'Policy-transactions')"
                                                                :customize-columns="onCustomizeTransactionColumns"
                                                                @option-changed="handlePropertyChange"> 

                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="true"/>      
                                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                                <DxPaging :page-size="10"/>
                                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes(transactionList, 10)" :show-page-size-selector="showPageSizeSelector(transactionList, 10)" :show-info="true" :show-navigation-buttons="true"/> 
                                                                <DxSearchPanel :visible="true"/>
                                                                <DxScrolling
                                                                    mode="virtual"
                                                                    row-rendering-mode="virtual"
                                                                />

                                                                <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                                <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" alignment="right" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                                                                <DxColumn data-field="policy_type_name" :caption="$t('Policy Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/> 
                                                                <DxColumn data-field="is_new_or_renewal" :caption="$t('New/Renewal')" header-cell-template="is_new_or_renewal-header" cell-template="is_new_or_renewal-cell-template"/> 
                                                                <DxColumn
                                                                    alignment="center"
                                                                    :caption="$t('Endorsement')"
                                                                    :fixed="false"
                                                                    fixed-position="right">
                                                                    <DxColumn data-field="endorsement_type_name" :caption="$t('Type')" cell-template="endorsement_type_name-cell-template"/> 
                                                                    <DxColumn data-field="endorsement_detail_name" :caption="$t('Detail')" cell-template="endorsement_detail_name-cell-template"/> 
                                                                </DxColumn>
                                                                <DxColumn
                                                                    alignment="center"
                                                                    :caption="$t('Producer')"
                                                                    :fixed="false"
                                                                    fixed-position="right">
                                                                    <DxColumn data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                                                                    <DxColumn data-field="producer_policy_type_name" :caption="$t('Policy Type')" cell-template="producer_policy_type-cell-template"/> 
                                                                </DxColumn>
                                                                <DxColumn data-field="start_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Start Date')" alignment="right" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                                                <DxColumn data-field="end_date" data-type="date" format="dd.MM.yyyy" :caption="$t('End Date')" alignment="right" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                                                                <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                                                                <DxColumn data-field="currency" :caption="$t('Curr.')" cell-template="currency-cell-template"/> 
                                                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                                <DxColumn data-field="policy_total_amount" :sorting-method="this.$numericSort" :caption="$t('Total Amount')" header-cell-template="policy_total_amount-header" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                                                                <DxColumn data-field="policy_tax_amount" :sorting-method="this.$numericSort" :caption="$t('Tax')" alignment="right" cell-template="policy_tax_amount-cell-template"/> 

                                                                <template #headerLocalTemplate="{ data: data }">
                                                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                                </template>

                                                                <template #policy_type_name-header>
                                                                    <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                                                                </template> 
                                                                <template #is_new_or_renewal-header>
                                                                    <div>{{ $t('New/') }}</div><div>{{ $t('Renewal') }}</div>
                                                                </template> 
                                                                <template #regulation_date-header>
                                                                    <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                                                                </template> 
                                                                <template #start_date-header>
                                                                    <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                                                                </template> 
                                                                <template #end_date-header>
                                                                    <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                                                                </template> 
                                                                <template #policy_no-header>
                                                                    <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                                                </template>
                                                                <template #policy_total_amount-header>
                                                                    <div>{{ $t('Total') }}</div><div>{{ $t('Amount') }}</div>
                                                                </template> 

                                                                <template #is-selected-cell-template="{ data }">
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(transactionList, data.data)" />
                                                                        </div>
                                                                    </span>
                                                                </template>                                                  
                                                                <template #policy_type_name-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-900 text-start">
                                                                        {{data.data.policy_type_name}}
                                                                    </span>
                                                                </template>
                                                                <template #is_new_or_renewal-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-900 text-start">
                                                                        {{data.data.is_new_or_renewal}}
                                                                    </span>
                                                                </template>
                                                                <template #endorsement_type_name-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-700 text-start">
                                                                        {{data.data.endorsement_type_name}}
                                                                    </span>   
                                                                </template>
                                                                <template #endorsement_detail_name-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-start">
                                                                        {{data.data.endorsement_detail_name}}
                                                                    </span>   
                                                                </template>
                                                                <template #producer_name-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-900 text-start">
                                                                        {{data.data.producer_name}}
                                                                    </span>   
                                                                </template>
                                                                <template #producer_policy_type-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-900 text-start">
                                                                        {{data.data.producer_policy_type_name}}
                                                                    </span>   
                                                                </template>
                                                                <template #regulation_date-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-700 text-end">
                                                                        {{previewDate(data.data.regulation_date)}}
                                                                    </span>   
                                                                </template>
                                                                <template #start_date-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{previewDate(data.data.start_date) }}
                                                                    </span>   
                                                                </template>
                                                                <template #end_date-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{previewDate(data.data.end_date)}}
                                                                    </span>   
                                                                </template>
                                                                <template #policy_no-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}
                                                                    </span>   
                                                                </template>
                                                                <template #currency-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.currency }}
                                                                    </span>   
                                                                </template>
                                                                <template #policy_total_amount-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.policy_total_amount }}
                                                                    </span>   
                                                                </template>
                                                                <template #rate-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.rate }}
                                                                    </span>   
                                                                </template>
                                                                <template #policy_tax_amount-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.policy_tax_amount}}
                                                                    </span>   
                                                                </template>
                                                                <template #policy_total_amount_local-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.policy_total_amount_local }}
                                                                    </span>   
                                                                </template>
                                                                <template #policy_tax_amount_local-cell-template="{ data }">
                                                                    <span class="align-middle text-gray-600 text-end">
                                                                        {{data.data.policy_tax_amount_local}}
                                                                    </span>   
                                                                </template>
                                                                
                                                                <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                                    <DxTotalItem
                                                                        name="policy_total_amount"
                                                                        summary-type="custom"
                                                                        show-in-column="policy_total_amount"
                                                                        display-format="{0}"
                                                                    />
                                                                    <DxTotalItem
                                                                        name="policy_total_amount_local"
                                                                        summary-type="custom"
                                                                        show-in-column="policy_total_amount_local"
                                                                        display-format="{0}"
                                                                    />
                                                                    <DxTotalItem
                                                                        name="policy_tax_amount"
                                                                        summary-type="custom"
                                                                        show-in-column="policy_tax_amount"
                                                                        display-format="{0}"
                                                                    />
                                                                    <DxTotalItem
                                                                        name="policy_tax_amount_local"
                                                                        summary-type="custom"
                                                                        show-in-column="policy_tax_amount_local"
                                                                        display-format="{0}"
                                                                    />
                                                                </DxSummary>
                                                        </DxDataGrid>   
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="kt_policy_sub_tab_2" class="tab-pane fade show active" v-if="this.activeSubTab == 'DefaultVendors'" role="tabpanel">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                            <div class="card-body"> 
                                                <div class="card card-xxl-stretch card-bordered">
                                                    <div class="p-5">
                                                        <div class="d-flex flex-stack flex-wrap mb-5">
                                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                                <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                                    data-bs-target="#kt_modal_add_vendor" @click="this.openAddVendorModal()"
                                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                            viewBox="0 0 24 24" fill="none">
                                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                                fill="currentColor">
                                                                            </rect>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Add') }}
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()" 
                                                                    :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Remove') }}
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()" 
                                                                    :disabled="vendorDefaultList.length == 0">
                                                                    <span class="svg-icon svg-icon-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                        </svg>
                                                                    </span>
                                                                    {{ $t('Remove All (!)') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                            <div class="table-responsive">
                                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                            key-expr="id" 
                                                                            :data-source="vendorDefaultList" 
                                                                            :allow-column-reordering="true"  
                                                                            :allow-column-resizing="true" 
                                                                            :column-auto-width="true" 
                                                                            :row-alternatin-enabled="true"
                                                                            :hover-state-enabled="true" 
                                                                            :show-row-lines="true" > 

                                                                        <DxFilterRow :visible="true"/>
                                                                        <DxHeaderFilter :visible="true"/>
                                                                        <DxGroupPanel :visible="false"/>
                                                                        <DxPaging :page-size="1000"/>
                                                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                        <DxScrolling
                                                                            mode="virtual"
                                                                            row-rendering-mode="virtual"
                                                                        />
                                                                        <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                                        <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/>
                                                                        <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template"/> 
                                                                        
                                                                        <template #is-selected-cell-template="{ data }"> 
                                                                            <span>
                                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(vendorDefaultList, data.data)" />
                                                                                </div>
                                                                            </span>
                                                                        </template>
                                                                        <template #account_id-cell-template="{ data }"> 
                                                                            <span>
                                                                                {{ data.data.account_id }}
                                                                            </span>
                                                                        </template>
                                                                        <template #vendor_name-cell-template="{ data }"> 
                                                                            <span>
                                                                                {{ data.data.vendor_name }}
                                                                            </span>
                                                                        </template>
                                                                </DxDataGrid>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(48,'U')">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-primary btn-lg mb-4" type="submit" @click="updatePolicy();"  data-kt-indicator="on"> 
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isPolicyProgressing"> {{ $t('Update Policy') }}</strong> 
                                        <span class="indicator-progress" v-if="this.isPolicyProgressing">
                                            {{ $t('Saving Policy. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="px-10" v-if="this.activeTab !== 'General'">
                    <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                        <div class="d-flex flex-column-fluid card card-xxl-stretch bg-lighten rounded-3 border border-gray-300"> 
                            <div class="card-header min-h-40px rounded-2 rounded-bottom-0 bg-gray-gradient">
                                <div class="card-title">
                                    <h3>{{ $t('Insurer Account') }}</h3>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row">
                                            <span class="fs-2 fw-bolder text-gray-800">{{selectedAccountInfo.name}}<br>
                                            <span class="text-gray-700">{{selectedAccountInfo.customer_account_number}}</span></span> 
                                            <div class="d-flex justify-content-end ms-3">
                                                <div class="symbol symbol-100px cursor-pointer" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()">
                                                    <div class="symbol-label fw-bold bg-light border text-hover-primary">
                                                        <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                            <span class="fs-6 fw-bolder text-gray-700 mt-2">
                                                {{selectedAccountInfo.customer_address}}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                            <span class="fs-6 text-gray-700 fw-bold"> {{selectedAccountInfo.customer_city}} </span>
                                            <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_district}} </span>
                                            <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_country}} </span>
                                            <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_postcode}} </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                            <a href="{{selectedAccountInfo.customer_email}}" class="fw-bold fs-6"> {{selectedAccountInfo.customer_email}} </a>
                                            <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_mobile_phone}} </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                            <span class="fs-6 text-gray-700 fw-bolder"> 
                                                <i class="bi bi-caret-right-fill"></i>
                                                {{form.insurance_type_name}}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                            <span class="fs-6 text-gray-700 fw-bolder"> 
                                                <i class="bi bi-caret-right-fill"></i>
                                                {{form.main_branch_name}}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                            <span class="fs-6 text-gray-700 fw-bolder"> 
                                                <i class="bi bi-caret-right-fill"></i>
                                                {{form.policy_branch_name}} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="kt_policy_tab_7" class="tab-pane fade show active" v-if="this.activeTab == 'Transactions'" role="tabpanel">
                    <Transactions ref="Transactions"></Transactions>
                </div>
                
                <div id="kt_policy_tab_2" class="tab-pane fade show active" v-if="this.activeTab == 'Commission'" role="tabpanel">
                    <Commissions ref="Commissions"></Commissions>
                </div>
                <div id="kt_policy_tab_4" class="tab-pane fade show active" v-if="this.activeTab == 'Factors'" role="tabpanel">
                    <Factors ref="Factors"></Factors>
                </div>
                <div id="kt_policy_tab_5" class="tab-pane fade show active" v-if="this.activeTab == 'Coverages'" role="tabpanel">
                    <Coverages ref="Coverages"></Coverages>
                </div>
                <div id="kt_policy_tab_6" class="tab-pane fade show active" v-if="this.activeTab == 'Collections'" role="tabpanel">
                    <CollectionAndPayments ref="CollectionAndPayments"></CollectionAndPayments>
                </div>
            </div>
        </div>

        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false" 
            :class="{'d-none': isViewFactorModal, 'show': isTransactionModalVisible && !isViewFactorModal}">
            <div class="modal-dialog modal-xxl">
                <div class="modal-content">
                    <div class="modal-header bg-primary p-5 min-h-40px">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span>
                            {{ $t('Transaction') }}

                            <div class="d-flex flex-wrap fw-semibold text-white fs-6">
                                <span class="d-flex align-items-center me-1">
                                    {{selectedAccountInfo.name}}
                                </span>
                                <span class="d-flex align-items-center me-1">
                                    <i class="bi bi-dash text-white"></i>
                                    {{form.main_branch_name}}
                                </span>
                                <span class="d-flex align-items-center me-1">
                                    <i class="bi bi-dash text-white"></i>
                                    {{form.policy_branch_name}}
                                </span>
                                <span class="d-flex align-items-center me-1" v-if="this.activeModalStep > 1">
                                    <i class="bi bi-dash text-white"></i>
                                    {{getProducerName(modalForm.producer_id)}}
                                </span>
                                <span class="d-flex align-items-center me-1" v-if="this.activeModalStep > 1">
                                    <i class="bi bi-dash text-white"></i>
                                    {{getProducerPolicyTypeName(modalForm.producer_policy_type)}}
                                </span>
                            </div>               
                        </h2>                        
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionModal" @click="isTransactionModalVisible = false">
                            <span class="svg-icon svg-icon-2x text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y p-5" :class="{'overlay overlay-block': this.isTransactionSaving == true}">
                        <div class="d-flex flex-column flex-md-row rounded">
                            <ul class="nav nav-tabs nav-pills card p-5 border shadow-sm flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-lg-250px" role="tablist">
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-primary" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 1}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Producer Detail') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-success" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 2}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Regulation Detail') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-info" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 3}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Policy Amounts') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-danger" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 4}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Vendor Detail') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-warning" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 5}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Coverage Detail') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 5">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-info" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 6}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Factor Detail') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 6">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item w-100" role="presentation">
                                    <button class="w-100 btn btn-flex px-3 btn-active-light-warning" data-bs-toggle="tab" style="pointer-events: none;"
                                        :class="{'active': this.activeModalStep == 7}">
                                        <span class="d-flex flex-column align-items-start w-75">
                                            <span class="fs-4 fw-bold">{{ $t('Sources') }}</span>
                                            <small class="fs-8 text-start">{{ $t('Description') }}</small>
                                        </span>
                                        <span class="svg-icon svg-icon-muted svg-icon-1 ms-2" v-if="activeModalStep == 7">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.5" d="M12.8956 13.4982L10.7949 11.2651C10.2697 10.7068 9.38251 10.7068 8.85731 11.2651C8.37559 11.7772 8.37559 12.5757 8.85731 13.0878L12.7499 17.2257C13.1448 17.6455 13.8118 17.6455 14.2066 17.2257L21.1427 9.85252C21.6244 9.34044 21.6244 8.54191 21.1427 8.02984C20.6175 7.47154 19.7303 7.47154 19.2051 8.02984L14.061 13.4982C13.7451 13.834 13.2115 13.834 12.8956 13.4982Z" fill="currentColor"/>
                                            <path d="M7.89557 13.4982L5.79487 11.2651C5.26967 10.7068 4.38251 10.7068 3.85731 11.2651C3.37559 11.7772 3.37559 12.5757 3.85731 13.0878L7.74989 17.2257C8.14476 17.6455 8.81176 17.6455 9.20663 17.2257L16.1427 9.85252C16.6244 9.34044 16.6244 8.54191 16.1427 8.02984C15.6175 7.47154 14.7303 7.47154 14.2051 8.02984L9.06096 13.4982C8.74506 13.834 8.21146 13.834 7.89557 13.4982Z" fill="currentColor"/>
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                            </ul>

                            <div class="tab-content w-100" id="myTabContent">
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 1">
                                    <div class="row">
                                        <div class="col-lg-12">                                            
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered mb-5 rounded-3">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5"> {{ $t('Producer Detail') }} </h3>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'producer_type'">
                                                            {{ $t('Producer Type') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.producer_type" :options="producerTypeList" @select="onChangeProducerType()" name="producer_type" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'producer_id'">
                                                            {{ $t('Producer') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.producer_id" @select="onChangeProducer(true)" :options="producerList" name="producer_id" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                            :disabled="modalForm.producer_type == 1"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'producer_policy_type'">
                                                                {{ $t('Producer Policy Type') }}
                                                            </label>
                                                            <span class="float-end" v-if="modalForm.policy_group_name !== null && modalForm.policy_group_name !== '' && modalForm.policy_group_name !== undefined">
                                                                <small class="fw-bold text-gray-500 me-1">Policy Group: </small>
                                                                <span class="badge badge-light-primary badge-sm fw-bold my-2">{{modalForm.policy_group_name}}</span>
                                                            </span>
                                                            <Select2 v-model="modalForm.producer_policy_type" :options="producerPolicyTypeList" name="producer_policy_type" v-custom @select="onChangeProducerPolicyType($event)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'policy_type'">
                                                            {{ $t('Regulation Type') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.policy_type" :options="policyTypeList" name="policy_type" @select="onChangePolicyType()" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'endorsement_type'">
                                                            {{ $t('Endorsement Type') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.endorsement_type" :options="endorsementTypeFilteredList" @select="getEndorsementDetailList()" name="endorsement_type" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-6" v-if="modalForm.policy_type == 2">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'endorsement_detail_id'">
                                                            {{ $t('Endorsement Detail') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.endorsement_detail_id" :options="endorsementDetailList" name="endorsement_detail_id" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3" 
                                            v-if="modalForm.endorsement_type == 2 || modalForm.endorsement_type == 3 || modalForm.endorsement_type == 4">
                                                <div class="card-header min-h-50px">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800"> {{ $t('Select Main Policy') }}</h3>
                                                        <small class="text-danger ms-5" v-if="selectedProducerAccount.supplier_is_main_policy_check_active == 1"> {{ $t('Main policy selection required!') }}</small>
                                                    </div>
                                                </div>
                                                <div class="card-body">                                                    
                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                            key-expr="id" 
                                                            :show-column-headers="false"
                                                            :data-source="mainPolicyList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true"
                                                            @exporting="this.$onExporting($event , 'Main-policy-list')"> 

                                                            <DxFilterRow :visible="false"/>
                                                            <DxHeaderFilter :visible="false"/>
                                                            <DxGroupPanel :visible="false"/>      
                                                            <DxExport :enabled="false" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                            <DxPaging :page-size="100000"/>
                                                            <DxSearchPanel :visible="false"/>
                                                            <DxScrolling
                                                                mode="virtual"
                                                                row-rendering-mode="virtual"
                                                            />
                                                            <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                            <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                                                            <DxColumn
                                                                alignment="center"
                                                                :caption="$t('Producer')"
                                                                :fixed="false"
                                                                fixed-position="right">
                                                                <DxColumn data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                                                                <DxColumn data-field="producer_policy_type" :caption="$t('Policy Type')" header-cell-template="producer_policy_type-header" cell-template="producer_policy_type-cell-template"/> 
                                                            </DxColumn>
                                                            <DxColumn data-field="start_date" :caption="$t('Start Date')" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                                            <DxColumn data-field="end_date" :caption="$t('End Date')" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                                                            <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                                                            <DxColumn data-field="currency" :caption="$t('Curr.')" cell-template="currency-cell-template"/> 
                                                            <DxColumn data-field="policy_total_amount" :caption="$t('Total Amount')" header-cell-template="policy_total_amount-header" alignment="right" cell-template="policy_total_amount-cell-template"/> 

                                                            <template #headerLocalTemplate="{ data: data }">
                                                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                            </template>

                                                            <template #regulation_date-header>
                                                                <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #start_date-header>
                                                                <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #end_date-header>
                                                                <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #policy_no-header>
                                                                <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                                            </template>
                                                            <template #policy_total_amount-header>
                                                                <div>{{ $t('Total') }}</div><div>{{ $t('Amount') }}</div>
                                                            </template> 
                                                            <template #policy_total_amount_local-header>
                                                                <div>{{ $t('Total') }}</div><div>{{ $t('Amount Local') }}</div>
                                                            </template> 
                                                            <template #producer_policy_type-header>
                                                                <div>{{ $t('Policy') }}</div><div>{{ $t('Type') }}</div>
                                                            </template>

                                                            <template #is-selected-cell-template="{ data }">
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(mainPolicyList, data.data)" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #producer_name-cell-template="{ data }">
                                                                <span class="align-middle text-gray-900 text-start">
                                                                    {{data.data.producer_name}}
                                                                </span>   
                                                            </template>
                                                            <template #producer_policy_type-cell-template="{ data }">
                                                                <span class="align-middle text-gray-900 text-start">
                                                                    {{data.data.producer_policy_type_name}}
                                                                </span>   
                                                            </template>
                                                            <template #regulation_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-700 text-end">
                                                                    {{previewDate(data.data.regulation_date)}}
                                                                </span>   
                                                            </template>
                                                            <template #start_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{previewDate(data.data.start_date) }}
                                                                </span>   
                                                            </template>
                                                            <template #end_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{previewDate(data.data.end_date)}}
                                                                </span>   
                                                            </template>
                                                            <template #policy_no-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}
                                                                </span>   
                                                            </template>
                                                            <template #currency-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.currency }}
                                                                </span>   
                                                            </template>
                                                            <template #policy_total_amount-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_total_amount }}
                                                                </span>   
                                                            </template>
                                                            <template #rate-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.rate }}
                                                                </span>   
                                                            </template>
                                                            <template #policy_total_amount_local-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_total_amount_local }}
                                                                </span>   
                                                            </template>
                                                    </DxDataGrid>   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 2" >
                                    <div class="row">
                                        <div class="col-lg-12">                                            
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered mb-5 rounded-3">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5"> {{ $t('Regulation Detail') }} </h3>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1 fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'regulation_date'">
                                                                    {{ $t('Regulation Date') }}
                                                                </label>
                                                                <Datepicker name="regulation_date" :required="this.$checkIfDateFieldRequired ('regulation_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.regulation_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" />
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1 fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'start_date'">
                                                                    {{ $t('Start Date') }}
                                                                </label>
                                                                <Datepicker name="start_date" @blur="onChangeStartDate()" :required="this.$checkIfDateFieldRequired ('start_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.start_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" />
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1 fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'end_date'">
                                                                    {{ $t('End Date') }}
                                                                </label>
                                                                <Datepicker name="end_date" :required="this.$checkIfDateFieldRequired ('end_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.end_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" />
                                                            <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'policy_no'">
                                                                    {{ $t('Policy No') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="policy_no" v-custom v-model="modalForm.policy_no">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'renewal_no'">
                                                                    {{ $t('Renewal No') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="renewal_no" v-custom v-model="modalForm.renewal_no">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'endorsement_no'" :class="{'required': modalForm.endorsement_type !== 0}">
                                                                    {{ $t('Endorsement No') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="endorsement_no" v-custom v-model="modalForm.endorsement_no">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                {{ $t('Insurant Account') }}
                                                            </label>
                                                            <input type="text" class="form-control form-control-lg" name="insurant_account" v-model="modalForm.insurant_account" v-custom>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                {{ $t('Loss Payable Clause') }}
                                                            </label>
                                                            <input type="text" class="form-control form-control-lg" name="loss_payable_clause" v-model="modalForm.loss_payable_clause" v-custom>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row mb-1">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'description'">
                                                                    {{ $t('Description') }}
                                                                </label>
                                                                <textarea class="form-control" name="description" rows="1" v-model="modalForm.description"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_type'">
                                                                <span>{{ $t('Payment Method') }}</span>
                                                            </label>
                                                            <Select2 v-model="modalForm.payment_method_id" :options="paymentList" name="payment_type" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" /> 
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'shipping_type'">
                                                                <span>{{ $t('Shipping Type') }}</span>
                                                            </label>
                                                            <Select2 v-model="modalForm.shipping_type_id" :options="shippingList" name="shipping_type" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" /> 
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                                {{ $t('Branch') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" />
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'producer_type'">
                                                            {{ $t('New/Renewal') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.is_new_or_renewal" :options="newOrRenewalList" name="producer_type" v-custom @select="onChangeNewOrRenewal()"
                                                                :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3" v-if="modalForm.is_new_or_renewal == 2">
                                                <div class="card-header min-h-50px">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800"> {{ $t('Select to Renewal') }}</h3>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                            key-expr="id" 
                                                            :show-column-headers="false"
                                                            :data-source="oldPolicyList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true"
                                                            @exporting="this.$onExporting($event , 'Old-policy-list')"> 

                                                            <DxFilterRow :visible="false"/>
                                                            <DxHeaderFilter :visible="false"/>
                                                            <DxGroupPanel :visible="false"/>      
                                                            <DxExport :enabled="false" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                            <DxPaging :page-size="100000"/>
                                                            <DxSearchPanel :visible="false"/>
                                                            <DxScrolling
                                                                mode="virtual"
                                                                row-rendering-mode="virtual"
                                                                
                                                            />
                                                            <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                            <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                                                            <DxColumn
                                                                alignment="center"
                                                                :caption="$t('Producer')"
                                                                :fixed="false"
                                                                fixed-position="right">
                                                                <DxColumn data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                                                                <DxColumn data-field="producer_policy_type" :caption="$t('Policy Type')" header-cell-template="producer_policy_type-header" cell-template="producer_policy_type-cell-template"/> 
                                                            </DxColumn>
                                                            <DxColumn data-field="start_date" :caption="$t('Start Date')" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                                            <DxColumn data-field="end_date" :caption="$t('End Date')" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                                                            <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                                                            <DxColumn data-field="currency" :caption="$t('Curr.')" cell-template="currency-cell-template"/> 
                                                            <DxColumn data-field="policy_total_amount" :caption="$t('Total Amount')" header-cell-template="policy_total_amount-header" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                                                            <DxColumn data-field="isRenewed" :caption="$t('Renewed?')" cell-template="is_renewed-cell-template"/> 

                                                            <template #headerLocalTemplate="{ data: data }">
                                                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                            </template>

                                                            <template #regulation_date-header>
                                                                <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #start_date-header>
                                                                <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #end_date-header>
                                                                <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                                                            </template> 
                                                            <template #policy_no-header>
                                                                <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                                            </template>
                                                            <template #policy_total_amount-header>
                                                                <div>{{ $t('Total') }}</div><div>{{ $t('Amount') }}</div>
                                                            </template> 
                                                            <template #policy_total_amount_local-header>
                                                                <div>{{ $t('Total') }}</div><div>{{ $t('Amount Local') }}</div>
                                                            </template> 
                                                            <template #producer_policy_type-header>
                                                                <div>{{ $t('Policy') }}</div><div>{{ $t('Type') }}</div>
                                                            </template>

                                                            <template #is-selected-cell-template="{ data }">
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #producer_name-cell-template="{ data }">
                                                                <span class="align-middle text-gray-900 text-start">
                                                                    {{data.data.producer_name}}
                                                                </span>   
                                                            </template>
                                                            <template #producer_policy_type-cell-template="{ data }">
                                                                <span class="align-middle text-gray-900 text-start">
                                                                    {{data.data.producer_policy_type_name}}
                                                                </span>   
                                                            </template>
                                                            <template #regulation_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-700 text-end">
                                                                    {{previewDate(data.data.regulation_date)}}
                                                                </span>   
                                                            </template>
                                                            <template #start_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{previewDate(data.data.start_date) }}
                                                                </span>   
                                                            </template>
                                                            <template #end_date-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{previewDate(data.data.end_date)}}
                                                                </span>   
                                                            </template>
                                                            <template #policy_no-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}
                                                                </span>   
                                                            </template>
                                                            <template #currency-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.currency }}
                                                                </span>   
                                                            </template>
                                                            <template #policy_total_amount-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_total_amount }}
                                                                </span>   
                                                            </template>
                                                            <template #rate-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.rate }}
                                                                </span>   
                                                            </template>
                                                            <template #policy_total_amount_local-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    {{data.data.policy_total_amount_local }}
                                                                </span>   
                                                            </template>
                                                            <template #is_renewed-cell-template="{ data }">
                                                                <span class="align-middle text-gray-600 text-end">
                                                                    <span v-if="data.data.isRenewed"> {{ $t('Renewed') }} </span>
                                                                    <span v-if="!data.data.isRenewed"> <i class="bi bi-dash"></i> </span>
                                                                </span>   
                                                            </template>
                                                    </DxDataGrid>   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 3">
                                    <div class="row">
                                        <div class="col-lg-12">                                            
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered mb-5 rounded-3" v-if="!isViewLocalTotals">
                                                <div class="card-header min-h-50px">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5"> {{ $t('Policy Amounts') }} </h3>
                                                    </div>
                                                    <div class="card-toolbar" v-if="this.modalForm.currency !== this.localCurrency">
                                                        <span class="me-6"> {{ $t('Rate') }}: <b> {{this.modalForm.rate}} </b> </span>
                                                        <button class="btn btn-light-primary btn-circle btn-icon btn-sm" @click="onChangeTotalsView()">
                                                            {{this.localCurrency}}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body py-0">
                                                    <div class="card-body p-1 pt-2">
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Currency') }} <span v-if="this.modalForm.currency !== this.localCurrency">/{{ $t('Rate List') }}/{{ $t('Rate') }}</span></label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container" :class="{'col-lg-12': this.modalForm.currency == this.localCurrency}">
                                                                        <Select2 v-model="modalForm.currency" :options="currencyList" @select="onChangeCurrency()"  name="currency" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"  /> 
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container" v-if="this.modalForm.currency !== this.localCurrency">
                                                                        <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" name="rate_type" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" />  
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end" v-if="this.modalForm.currency !== this.localCurrency">
                                                                        <CurrencyInput type="text" step="any" class="form-control form-control-lg text-end" name="rate" v-custom v-model="modalForm.rate" :precision="5" @focus="$event.target.select()"/>
                                                                        <small v-if="this.modalForm.rate_date !== null && this.modalForm.rate_date !== undefined">{{ $t('Rate Date') }}: {{ previewDate(this.modalForm.rate_date) }}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Policy Net') }}/{{ $t('Tax') }}/{{ $t('Total Amount') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_net_amount"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_tax_amount"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_total_amount"  @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Sub Tax 1') }}/ {{ $t('Tax 2') }}/ {{ $t('Tax 3') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_1_amount"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_2_amount" @focus="$event.target.select()" @blur="onCalculateTotalAmount()"  />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_3_amount"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Commission') }}/{{ $t('Payment to Producer') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.insurance_commission" @focus="$event.target.select()" @blur="onCalculateProducerAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.producer_amount" @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Advance') }}/ {{ $t('Remain Total') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.advance_amount" @focus="$event.target.select()" @blur="onCalculateAdvanceTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.installment_remain" @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Advance Date') }}/ {{ $t('Installment Count-Date') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <Datepicker name="advance_date" :required="this.$checkIfDateFieldRequired ('advance_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.advance_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end" v-model="modalForm.installment_count" :max="13" @focus="$event.target.select()"  :precision="0" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <Datepicker name="installment_date" :required="this.$checkIfDateFieldRequired ('installment_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.installment_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                                    :enableTimePicker="false" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-lg-3 px-2">
                                                                <div class="input-group">
                                                                    <label class="col-form-label form-label fw-bold me-3">{{ $t('No amount on policy') }}</label>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                        <input class="form-check-input" type="checkbox" v-model="modalForm.is_no_amount" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-12 fv-row fv-plugins-icon-container text-end">
                                                                        <div class="d-grid gap-2"> 
                                                                            <button type="button" class="btn btn-sm btn-light-success border border-gray-300" @click="onCreateCollectionPlan()">{{ $t('Create Installment Plans') }}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered mb-5 rounded-3" v-if="isViewLocalTotals && this.modalForm.currency !== this.localCurrency">
                                                <div class="card-header min-h-50px">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5"> {{ $t('Local') }} ({{this.localCurrency}}) {{ $t('Totals') }}
                                                        </h3>
                                                    </div>
                                                    <div class="card-toolbar">
                                                        <span class="me-6"> {{ $t('Rate') }}: <b> {{this.modalForm.rate}} </b> </span>
                                                        <button class="btn btn-light-info btn-circle btn-icon btn-sm" @click="onChangeTotalsView()">
                                                            {{modalForm.currency}}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body py-0">
                                                    <div class="card-body p-1 pt-2">
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Policy Net') }}/{{ $t('Tax') }}/{{ $t('Total Amount') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_net_amount_local"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_tax_amount_local"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.policy_total_amount_local"  @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Sub Tax 1') }}/ {{ $t('Tax 2') }}/ {{ $t('Tax 3') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_1_amount_local"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_2_amount_local" @focus="$event.target.select()" @blur="onCalculateTotalAmount()"  />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.sub_tax_3_amount_local"  @focus="$event.target.select()" @blur="onCalculateTotalAmount()"  />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Commission') }}/{{ $t('Payment to Producer') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.insurance_commission_local" @focus="$event.target.select()"  />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.producer_amount_local" @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-3 col-form-label form-label fw-bold p-2">{{ $t('Advance') }}/ {{ $t('Remain Total') }}</label>
                                                            <div class="col-lg-9">
                                                                <div class="row">
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container text-end">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.advance_amount_local" @focus="$event.target.select()" @blur="onCalculateAdvanceTotalAmount()" />
                                                                    </div>
                                                                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                        <CurrencyInput type="text" step="any" class="form-control text-end p-2" v-model="modalForm.installment_remain_local" @focus="$event.target.select()" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3">
                                                <div class="card-body py-5">                                                    
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'collection_status'">
                                                                {{ $t('Collection Status') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.collection_status" :options="collectionStatusList" name="collection_status"  v-custom  :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'collection_type'">
                                                                {{ $t('Collection Type') }}
                                                            </label>
                                                            <Select2 v-model="modalForm.collection_type" :options="collectionTypeList" name="collection_type"  v-custom  :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" :disabled="modalForm.collection_status == 1 "/>
                                                        </div>
                                                    </div>
                                                    <div class="separator my-3 mt-5 border-gray-300"></div>
                                                    <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-3 fs-6">
                                                        <li class="nav-item" v-if="modalForm.endorsement_type != 4">
                                                            <a class="nav-link" data-bs-toggle="tab" href="#installment_tab_1" target="_self" 
                                                            :class="{'active': this.activePlanTab == 'collection'}" @click="activePlanTab = 'collection'">
                                                               <h6>{{ $t('Collection Plan') }}</h6>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" data-bs-toggle="tab" href="#installment_tab_2" target="_self" 
                                                            :class="{'active': this.activePlanTab == 'payment' || modalForm.endorsement_type == 4}" @click="activePlanTab = 'payment'">
                                                                <h6>{{ $t('Payment Plan') }}</h6>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div class="tab-content" id="myTabContent">
                                                        <div class="row tab-pane fade active show" v-if="activePlanTab == 'collection'" id="installment_tab_1" role="tabpanel">
                                                            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                                <div class="table-responsive">
                                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                            key-expr="id" 
                                                                            :data-source="collectionPlanList" 
                                                                            :allow-column-reordering="true"  
                                                                            :allow-column-resizing="true" 
                                                                            :column-auto-width="true" 
                                                                            :row-alternatin-enabled="true"
                                                                            :hover-state-enabled="true" 
                                                                            :show-row-lines="true" 
                                                                            :ref="'dataGrid'"
                                                                            :repaint-changes-only="true"
                                                                            @exporting="this.$onExporting($event , 'Collection-plan')"
                                                                            @row-updating="onUpdateDueDate"> 

                                                                            <DxFilterRow :visible="false"/>
                                                                            <DxHeaderFilter :visible="false"/>
                                                                            <DxGroupPanel :visible="false"/>      
                                                                            <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                                            <DxPaging :page-size="10000000"/>
                                                                            <DxSearchPanel :visible="false"/>
                                                                            <DxScrolling
                                                                                mode="virtual"
                                                                                row-rendering-mode="virtual"
                                                                            />
                                                                            <DxEditing :allow-updating="true" mode="row"/>
                                                                            <DxColumn data-field="installment_no" :caption="$t('Installment No')" alignment="right" header-cell-template="installment_no-header" cell-template="installment_no-cell-template"/>
                                                                            <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="collection_amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="collection_amount-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="collection_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Local')" alignment="right" header-cell-template="collection_amount_local-header" cell-template="collection_amount_local-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="collection_due" :caption="$t('Due Date')" alignment="right" cell-template="collection_due-cell-template" data-type="date" format="dd.MM.yyyy" :allowEditing="true"/>

                                                                            <!-- Header Cell Templates -->
                                                                            <template #installment_no-header>
                                                                                <div>{{ $t('Installment') }}</div><div>{{ $t('No') }}</div>
                                                                            </template>
                                                                            <template #collection_amount_local-header>
                                                                                <div>{{ $t('Amount') }}</div><div>{{ $t('Local') }}</div>
                                                                            </template>

                                                                            <!-- Cell Templates -->
                                                                            <template #installment_no-cell-template="{ data }">
                                                                                <span>{{ data.data.installment_no }}</span>
                                                                            </template>
                                                                            <template #currency-cell-template="{  }">
                                                                                <span>{{ this.modalForm.currency }}</span>
                                                                            </template>
                                                                            <template #collection_amount-cell-template="{ data }">
                                                                                <span>{{ data.data.collection_amount }}</span>
                                                                            </template>
                                                                            <template #collection_amount_local-cell-template="{ data }">
                                                                                <span>{{ data.data.collection_amount_local }}</span>
                                                                            </template>
                                                                            <template #collection_due-cell-template="{ data }">
                                                                                <span>{{ previewDate(data.data.collection_due) }}</span>
                                                                            </template>
                                                                            
                                                                            <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                                                <DxTotalItem
                                                                                    name="collection_amount"
                                                                                    summary-type="custom"
                                                                                    show-in-column="collection_amount"
                                                                                    display-format="{0}"
                                                                                />
                                                                                <DxTotalItem
                                                                                    name="collection_amount_local"
                                                                                    summary-type="custom"
                                                                                    show-in-column="collection_amount_local"
                                                                                    display-format="{0}"
                                                                                />
                                                                            </DxSummary>
                                                                    </DxDataGrid>   
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <div class="row tab-pane fade active show" v-if="activePlanTab == 'payment'" id="installment_tab_2" role="tabpanel">
                                                            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                                <div class="table-responsive">
                                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                                key-expr="id" 
                                                                                :data-source="collectionPlanList" 
                                                                                :allow-column-reordering="true"  
                                                                                :allow-column-resizing="true" 
                                                                                :column-auto-width="true" 
                                                                                :row-alternatin-enabled="true"
                                                                                :hover-state-enabled="true" 
                                                                                :show-row-lines="true" 
                                                                                :ref="'dataGrid'"
                                                                                @exporting="this.$onExporting($event , 'Payment-plan')"
                                                                                @row-updating="onUpdateDueDate"> 

                                                                            <DxFilterRow :visible="false"/>
                                                                            <DxHeaderFilter :visible="false"/>
                                                                            <DxGroupPanel :visible="false"/>      
                                                                            <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                                            <DxPaging :page-size="1000000"/>
                                                                            <DxSearchPanel :visible="false"/>
                                                                            <DxScrolling
                                                                                mode="virtual"
                                                                                row-rendering-mode="virtual"
                                                                            />
                                                                            <!-- Cell Templates -->
                                                                            <DxEditing :allow-updating="true" mode="row"/>
                                                                            <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="collection_amount" :sorting-method="this.$numericSort" :caption="$t('Installment Amount')" alignment="right" header-cell-template="collection_amount-header" cell-template="collection_amount-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="premium_tax" :sorting-method="this.$numericSort" :caption="$t('Premium + Tax')" alignment="right" cell-template="premium_tax-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="payment_amount" :sorting-method="this.$numericSort" :caption="$t('Payment Amount')" alignment="right" header-cell-template="payment_amount-header" cell-template="payment_amount-cell-template" :allowEditing="false"/>
                                                                            <DxColumn data-field="payment_due" :caption="$t('Due Date')" alignment="right" cell-template="payment_due-cell-template" data-type="date" format="dd.MM.yyyy" :allowEditing="true"/>

                                                                            <template #collection_amount-header>
                                                                                <div>{{ $t('Installment') }}</div><div>{{ $t('Amount') }}</div>
                                                                            </template>
                                                                            <template #payment_amount-header>
                                                                                <div>{{ $t('Payment') }}</div><div>{{ $t('Amount') }}</div>
                                                                            </template>

                                                                            <template #currency-cell-template="{  }">
                                                                                <span>{{ this.modalForm.currency }}</span>
                                                                            </template>
                                                                            <template #collection_amount-cell-template="{ data }">
                                                                                <span>{{ data.data.collection_amount }}</span>
                                                                            </template>
                                                                            <template #premium_tax-cell-template="{ data }">
                                                                                <span>{{ data.data.premium_tax }}</span>
                                                                            </template>
                                                                            <template #payment_amount-cell-template="{ data }">
                                                                                <span>{{ data.data.payment_amount }}</span>
                                                                            </template>
                                                                            <template #payment_due-cell-template="{ data }">
                                                                                <span>{{ previewDate(data.data.payment_due) }}</span>
                                                                            </template>
                                                                            
                                                                            <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                                                <DxTotalItem
                                                                                    name="collection_amount"
                                                                                    summary-type="custom"
                                                                                    show-in-column="collection_amount"
                                                                                    display-format="{0}"
                                                                                />
                                                                                <DxTotalItem
                                                                                    name="premium_tax"
                                                                                    summary-type="custom"
                                                                                    show-in-column="premium_tax"
                                                                                    display-format="{0}"
                                                                                />
                                                                                <DxTotalItem
                                                                                    name="payment_amount"
                                                                                    summary-type="custom"
                                                                                    show-in-column="payment_amount"
                                                                                    display-format="{0}"
                                                                                />
                                                                            </DxSummary>
                                                                    </DxDataGrid>   
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 4">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3">
                                            <div class="card-header min-h-50px">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-5"> {{ $t('Vendor Detail') }} </h3>
                                                </div>
                                            </div>
                                            <div class="card-body"> 
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.openAddTransactionVendorModal()">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteTransactionVendorItem()" 
                                                            :disabled="isVendorButtonDisabled == null || isVendorButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteTransactionVendorItem(true)" 
                                                            :disabled="this.transactionVendorFilteredList.length == 0">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button>
                                                    </div>
                                                    <div class="d-flex justify-content-end">
                                                        <div class="form-check mt-5">
                                                            <input class="form-check-input" type="checkbox" value="" id="isMainPolicy" v-model="isMainPolicy" disabled>
                                                            <label class="fs-6 fw-bold form-label" for="isMainPolicy">
                                                                {{ $t('Main Policy') }}
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div class="table-responsive">
                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                key-expr="id" 
                                                                ref="dxVendorList"
                                                                :data-source="transactionVendorList" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true" 
                                                                :column-auto-width="true" 
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true" 
                                                                :show-row-lines="true"
                                                                :repaint-changes-only="true"
                                                                @row-updating="onUpdateVendorRow"
                                                                @editing-start="setDecimalValueForEdit"
                                                                @edit-canceled="getTempVendors" >

                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="false"/>
                                                                <DxPaging :page-size="1000"/>
                                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                <DxScrolling
                                                                    mode="virtual"
                                                                    row-rendering-mode="virtual"
                                                                />
                                                                <DxEditing :allow-updating="this.transactionVendorFilteredList.length > 0 ? true : false" mode="row" />
                                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allowEditing="false" :allow-exporting="false"/>
                                                                <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template"  :allowEditing="false"/> 
                                                                <DxColumn data-field="commission_type" :caption="$t('Commission Type')" alignment="left" cell-template="commission_type-cell-template" :allowEditing="true" :set-cell-value="setCommissionTypeCellValue">
                                                                    <DxLookup :data-source="commissionTypeList" value-expr="id" display-expr="text" v-model="commission_type"/>
                                                                </DxColumn> 
                                                                <DxColumn data-field="commission_rate" :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number"
                                                                    :sorting-method="this.$numericSort" :caption="$t('Commission Rate')" alignment="right" cell-template="commission_rate-cell-template" :allowEditing="true" :set-cell-value="setCommissionRateCellValue">
                                                                    <DxFormat type="fixedPoint" :precision="2"/>
                                                                </DxColumn>
                                                                <DxColumn data-field="commission_amount" :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number" 
                                                                    :sorting-method="this.$numericSort" :caption="$t('Commission Amount')" alignment="right" cell-template="commission_amount-cell-template" :allowEditing="true" :set-cell-value="setCommissionAmountCellValue" >
                                                                    <DxFormat type="fixedPoint" :precision="2"/>
                                                                </DxColumn>
                                                       
                                                                <template #is-selected-cell-template="{ data }"> 
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(transactionVendorList, data.data)" />
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template #vendor_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.vendor_name }}
                                                                    </span>
                                                                </template>
                                                                <template #commission_type-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.commission_type_name }}
                                                                    </span>
                                                                </template>
                                                                <template #commission_rate-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.commission_rate }}
                                                                    </span>
                                                                </template>
                                                                <template #commission_amount-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.commission_amount }}
                                                                    </span>
                                                                </template>

                                                                <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                                    <DxTotalItem
                                                                        name="commission_amount"
                                                                        summary-type="custom"
                                                                        show-in-column="commission_amount"
                                                                        display-format="Total:  {0}"
                                                                    />
                                                                </DxSummary>
                                                        </DxDataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 5">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3">
                                            <div class="card-header min-h-50px">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-5"> {{ $t('Coverage Detail') }} </h3>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.openAddTransactionCoverageModal()">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteTransactionCoverageItem()" 
                                                            :disabled="isCoverageButtonDisabled == null || isCoverageButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteTransactionCoverageItem(true)" 
                                                            :disabled="this.coverageDetailList.length == 0">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div class="table-responsive">
                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                key-expr="id" 
                                                                :data-source="coverageDetailList" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true" 
                                                                :column-auto-width="true" 
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true" 
                                                                :show-row-lines="true" 
                                                                @row-updating="onRowUpdating"> 

                                                            <DxFilterRow :visible="true"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxGroupPanel :visible="false"/>
                                                            <DxPaging :page-size="1000"/>
                                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                            <DxEditing :allow-updating="true" mode="row" /> 
                                                            <DxScrolling
                                                                mode="virtual"
                                                                row-rendering-mode="virtual"
                                                            />               
                                                            <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allowEditing="false" :allow-exporting="false"/>
                                                            <DxColumn data-field="code" :caption="$t('Code')" cell-template="code-cell-template" :allowEditing="false"/>
                                                            <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template" :allowEditing="false"/> 
                                                            <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template" >
                                                                <DxLookup :data-source="currencyList" value-expr="id" display-expr="text" v-model="coverageForm.currency"/>    
                                                            </DxColumn> 
                                                            <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template" :allowEditing="true"/> 
                                                            <DxColumn data-field="coverage_main_name" :caption="$t('Coverage Main')" header-cell-template="coverage_main_name-header" cell-template="coverage_main_name-cell-template"  :allowEditing="false"/> 
                                                            <DxColumn data-field="coverage_detail_name" :caption="$t('Coverage Detail')" header-cell-template="coverage_detail_name-header" cell-template="coverage_detail_name-cell-template"  :allowEditing="false"/> 
                                                            
                                                            <template #coverage_main_name-header>
                                                                <div>{{ $t('Coverage') }}</div><div>{{ $t('Main') }}</div>
                                                            </template>
                                                            <template #coverage_detail_name-header>
                                                                <div>{{ $t('Coverage') }}</div><div>{{ $t('Detail') }}</div>
                                                            </template>

                                                            <template #is-selected-cell-template="{ data }"> 
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(coverageDetailList, data.data)" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #code-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.code }}
                                                                </span>
                                                            </template>
                                                            <template #name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.name }}
                                                                </span>
                                                            </template>
                                                            <template #coverage_main_name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.coverage_main_name }}
                                                                </span>
                                                            </template>
                                                            <template #coverage_detail_name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.coverage_detail_name }}
                                                                </span>
                                                            </template>
                                                            <template #currency-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.currency }}
                                                                </span>
                                                            </template>
                                                            <template #amount-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.amount }}
                                                                </span>
                                                            </template>
                                                        </DxDataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 6">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3">
                                            <div class="card-header min-h-50px">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-5"> {{ $t('Factor Detail') }} </h3>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.openTransactionFactorModal()">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Select') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.openFactorModal('add')">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add New') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" @click="openFactorModal('edit')"
                                                         :disabled="isFactorButtonDisabled == null || isFactorButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                    </svg>
                                                            </span>
                                                            {{ $t('Edit') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteTransactionFactorItem()" 
                                                            :disabled="isFactorButtonDisabled == null || isFactorButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteTransactionFactorItem(true)" 
                                                            :disabled="this.transactionFactorList.length == 0">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div class="table-responsive">
                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                    key-expr="id" 
                                                                    :data-source="transactionFactorList" 
                                                                    :allow-column-reordering="true"  
                                                                    :allow-column-resizing="true" 
                                                                    :column-auto-width="true" 
                                                                    :row-alternatin-enabled="true"
                                                                    :hover-state-enabled="true" 
                                                                    :show-row-lines="true" > 

                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="false"/>
                                                                <DxPaging :page-size="1000"/>
                                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                                <DxScrolling
                                                                    mode="virtual"
                                                                    row-rendering-mode="virtual"
                                                                />
                                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                                <DxColumn data-field="factor_type_name" :caption="$t('Factor Type')" cell-template="factor_type_name-cell-template"/> 
                                                                <DxColumn data-field="factor_detail_name" :caption="$t('Factor Detail')" alignment="left" cell-template="factor_detail_name-cell-template"/>
                                                                <DxColumn data-field="registration_number" :caption="$t('ID/Plate')" alignment="left" cell-template="registration_number-cell-template"/>
                                                                <DxColumn data-field="name" :caption="$t('Name/Desc.')" alignment="left" cell-template="name-cell-template"/>
                                                                
                                                                <template #is-selected-cell-template="{ data }"> 
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectFactorItem(data.data)" />
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template #factor_type_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.factor_type_name }}
                                                                    </span>
                                                                </template>
                                                                <template #factor_detail_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.factor_detail_name }}
                                                                    </span>
                                                                </template>
                                                                <template #registration_number-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.registration_number }}
                                                                    </span>
                                                                </template>
                                                                <template #name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.name }}
                                                                    </span>
                                                                </template>
                                                        </DxDataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade active show" role="tabpanel" v-if="this.activeModalStep == 7">
                                    <div class="col-lg-12">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch card-bordered rounded-3">
                                            <div class="card-header min-h-50px">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-5">{{ $t('Sources') }}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <label class="fw-bold form-label mt-3">
                                                            <span> {{ $t('Sales Channel') }}</span>
                                                        </label>
                                                        <Select2 v-model="modalForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fw-bold form-label mt-3">
                                                            <span>{{ $t('Sales Role / Campaign') }}</span>
                                                        </label>
                                                        <Select2 v-model="modalForm.campaign_id" :options="campaignList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fw-bold form-label mt-3">
                                                            <span>{{ $t('Source Code') }} 1</span>
                                                        </label>
                                                        <Select2 v-model="modalForm.source_code" :options="sourceCodeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fw-bold form-label mt-3">
                                                            <span>{{ $t('Source Code') }} 2</span>
                                                        </label>
                                                        <Select2 v-model="modalForm.source_code_2" :options="sourceCode2List" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isTransactionSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer row mx-0 border-gray-300 card p-3" :class="{'overlay overlay-block': this.isTransactionSaving == true}">
                        <div class="col-lg-12">
                            <div class="d-grid gap-2">
                                <div class="btn-group"> 
                                    <div class="col-lg-2" v-if="this.activeModalStep > 1">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-light btn-lg border border-gray-300 me-3" @click="onBackStep()">
                                                <i class="bi bi-chevron-left text-gray-800"></i>
                                                <span class="indicator-label text-gray-800">{{ $t('Back') }}</span> 
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-10" v-if="this.activeModalStep !== 7" :class="{'col-lg-12': this.activeModalStep == 1}">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.activeModalStep == 2" @click="onCheckPolicyDuplicate()" :disabled="isNextButtonDisabled || isTransactionSaving">
                                                <span class="indicator-label">{{ $t('Continue') }}</span> 
                                                <i class="bi bi-chevron-right"></i>
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.activeModalStep == 3" @click="onCheckPolicyAmounts()" :disabled="isNextButtonDisabled || isTransactionSaving">
                                                <span class="indicator-label">{{ $t('Continue') }}</span> 
                                                <i class="bi bi-chevron-right"></i>
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.activeModalStep !== 2 && this.activeModalStep !== 3"  @click="onNextStep()" :disabled="isNextButtonDisabled || isTransactionSaving">
                                                <span class="indicator-label">{{ $t('Continue') }}</span> 
                                                <i class="bi bi-chevron-right"></i>
                                            </button>
                                            <small class="text-danger text-center" v-if="isNextButtonDisabled">*{{ $t('Required fields are missing!') }}</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-10" v-if="this.activeModalStep == 7">
                                        <div class="d-grid gap-2">
                                            <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" @click="this.addTransaction()">
                                                <span class="indicator-label">{{ $t('Save Policy Transaction') }}</span> 
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" @click="this.editTransaction()">
                                                <span class="indicator-label">{{ $t('Update') }}</span> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.account_name" :account-id="form.account_id" :account-type="form.account_type" />
        </div> 
   
        <!-- Add Default Vendor -->
        <div class="modal fade" id="kt_modal_add_vendor" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddDefaultVendor ref="AddDefaultVendor" :supplier-account-type="2"/>
        </div>
        
        <!-- Add Vendor to Transaction -->
        <div v-if="isViewTransactionVendorModal" class="modal fade show" id="kt_modal_add_trn_vendor" style="display: block;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddVendorToTransaction ref="AddVendorToTransaction"/>
        </div>

        <!-- Add Coverage to Transaction -->
        <div v-if="isViewTransactionCoverageModal" class="modal fade show" id="kt_modal_add_trn_vendor" style="display: block;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddCovarageToTrasaction ref="AddCovarageToTrasaction"/>
        </div>

        <!-- Add Account Factor -->
        <div class="modal fade" v-show="isViewFactorModal" :class="{'show': isViewFactorModal}" id="kt_modal_add_trn_factor" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
            <TransactionFactorModal ref="TransactionFactorModal" />
        </div>
        
        <!-- Add Factor to Transaction -->
        <div v-if="isViewTransactionFactorModal" class="modal fade show" id="kt_modal_add_trn_vendor" style="display: block;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddFactorToTransaction ref="AddFactorToTransaction" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxEditing, DxLookup, DxScrolling, DxFormat } from 'devextreme-vue/data-grid';
import AddDefaultVendor from './Modals/AddDefaultVendor.vue';
import AddCovarageToTrasaction from './Modals/AddCovarageToTrasaction.vue';
import AddVendorToTransaction from './Modals/AddVendorToTransaction.vue';
import AddFactorToTransaction from './Modals/AddFactorToTransaction.vue';
import Commissions from './tabs/Commissions.vue';
import Factors from './tabs/Factors.vue';
import Coverages from './tabs/Coverages.vue';
import CollectionAndPayments from './tabs/CollectionAndPayments.vue';
import Transactions from './tabs/Transactions.vue';
import TransactionFactorModal from './Modals/TransactionFactorModal.vue';

export default {
  name: "PolicyUpdate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, TransactionFactorModal, DxSummary, AddDefaultVendor, AddVendorToTransaction, AddCovarageToTrasaction, AddFactorToTransaction, DxTotalItem, DxEditing, DxLookup, Commissions, Factors, Coverages, CollectionAndPayments, Transactions, DxScrolling, DxFormat
  },
  data() {
    return {
        columnEditorOptions: { format: "#,##0.00", precision: 2 },
        decimalFormat: "#,##0.00",
        userData: this.$getUser(),
        activeTab: 'General',
        activeModalStep: 1,
        activePlanTab: 'collection',
        pageSize: 10,
        pageIndex: 0,
        transactionList: [],
        currencyList: [],
        rateList: [{ id: '-1', text: 'Select an option'}],
        policyTypeList: [
          { id: 1, text: 'Main Policy'},
          { id: 2, text: 'Endorsement Policy'}
        ],
        endorsementTypeList: [
          { id: 1, text: 'Main Policy'},
          { id: 0, text: 'Select an option'},
          { id: 2, text: 'Coverage Addition'},
          { id: 3, text: 'Coverage Reduction'},
          { id: 4, text: 'Coverage Cancellation'},
          { id: 5, text: 'Change of Content'},
        ],
        endorsementDetailList: [{ id: '-1', text: 'Select an option'}],
        salesChannelList: [],
        campaignList: [],
        sourceCodeList: [],
        sourceCode2List: [],
        paymentList: [],
        shippingList: [],
        branchList: [],
        localCurrency: null,
        modalType: 'addItem',
        isPolicyProgressing: 'off',
        isViewLocalTotals: false,
        isTransactionSaving: false,
        form: {            
            activeView: 2,
            policy_card_id: this.$getSessionStorage('record_id'),
            account_id: null,
            account_name: null,
            account_type: 0,
            sales_channel_id: '-1',
            campaign_id: '-1',
            policy_no: null,
            renewal_no: null,
            insurance_type: 1,
            regulation_date: moment(),
            start_date: moment(),
            end_date: moment(),
            policy_type: 1,
            endorsement_type: 1,
            policy_branch_id: '-1',
            main_branch_id: '-1',
            main_producer_id: '-1',
            vendor_id: '-1',
            main_branch_name: null,
            policy_branch_name: null,
            insurance_type_name: null,
            year: 0
        },
        selectedAccountInfo: {},
        selectedProducerAccount: {},
        modalForm: {
            id: null,
            producer_type: 1,
            producer_id: '-1',
            producer_policy_type: '-1',
            policy_type: 1,
            endorsement_type: 1,
            endorsement_detail_id: '-1',
            regulation_date: moment(),
            start_date: moment(),
            end_date: moment().add(1, 'year'),
            policy_no: '0',
            renewal_no: '0',
            endorsement_no: '0',
            policy_net_amount:0,
            policy_total_amount: 0,
            policy_tax_amount: 0,
            sub_tax_1_amount: 0,
            sub_tax_2_amount: 0,
            sub_tax_3_amount: 0,
            policy_net_amount_local:0,
            policy_total_amount_local: 0,
            advance_amount_local: 0,
            installment_remain_local: 0,
            insurance_commission: 0,
            payment_type: 1,
            campaign_id: '-1',
            sales_channel_id: '-1',
            advance_date: moment(),
            installment_date: moment(),
            advance_amount: 0,
            installment_count: 1,
            installment_remain: 0,
            producer_amount: 0,
            is_no_amount: 0,
            is_new_or_renewal: 1,
            currency: '-1',
            rate_list_type: 0,
            rate: 1.00000,
            rate_date: null,
            rate_list_id: '-1',
            insurant_account: null,
            loss_payable_clause: null,
            main_policy_id: null,
            source_code: '-1',
            source_code_2: '-1',
            collection_status: 1,
            collection_type: 0,
            payment_method_id: '-1',
            shipping_type_id: '-1',
            branch_id: '-1',
            policy_group_name: null
        },
        collectionStatusList: [
            {id: 1, text: 'Waiting'},
            {id: 2, text: 'Closed With Card'},
        ],
        collectionTypeList: [
            {id: 0, text: 'Select an option'},
            {id: 1, text: '1-Paid to the Producer with Insured Card'},
            {id: 2, text: '2-Paid to the Producer with Our Card'},
            {id: 3, text: '3-Paid to the Our Bank Card Account'},
        ],
        menuSelection: '/PoliciesHelppage',
        iconView : false,
        typeSelection: null,
        rateTypeSelection: null,
        rateType: 0,
        typeList: [],
        vendorDefaultList: [],
        coverageDetailList: [],
        activeSubTab: 'PolicyTransaction',
        isItemSaving: false,
        producerList: [],
        producerTypeList: [
            { id: 1, text: 'Main Producer'},
            { id: 2, text: 'Sub Producer'},
        ],
        newOrRenewalList: [
            { id: 1, text: 'New'},
            { id: 2, text: 'Renewal'},
        ],
        yearList: [],
        producerPolicyTypeList: [{ id: '-1', text: 'Select an option'}],
        isFormFieldsDisabled: true,
        isTransactionModalVisible: false,
        collectionPlanList: [],
        transactionVendorList: [],
        isVendorsAdded: false,
        isCoveragesAdded: false,
        isViewTransactionVendorModal: false,
        isViewFactorModal: false,
        isViewTransactionFactorModal: false,
        updateForm: {
            id: null,
            type: 'collection',
            collection_due: moment(),
            payment_due: moment()
        },
        mainPolicyList: [],
        oldPolicyList: [],
        isViewTransactionCoverageModal: false,
        isViewEditTransactionCoverageModal: false,
        coverageForm: {
            id: null,
            code: null,
            name: null,
            currency: null,
            amount: null,
        },
        isMainPolicy: true,
        transactionFactorList: [],
        commissionTypeList: [
            { id: 1, text: 'Policy Commission' },
            { id: 2, text: 'Net Amount' },
            { id: 3, text: 'Total Amount' },
        ]
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace(' Local', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    isItemButtonDisabled() {
       return this.transactionList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return(list, pageSize) => {
            return list.length > pageSize;
        }
    },
    pageSizes() {
        return(list, pageSize) => {
            let arr = [];
            let x = 1;
            let loop = (list.length / pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(pageSize * x);
                x++;
            }
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    endorsementTypeFilteredList() {
        if(this.modalForm.policy_type == 1) {
            return this.endorsementTypeList.filter(p => { 
                return p.id == 1;
            });
        } else {            
            return this.endorsementTypeList.filter(p => { 
                return p.id !== 1;
            });
        }
    },
    isButtonDisabled() {
        return this.vendorDefaultList.find(item => item.isSelected == true );
    },
    isFactorButtonDisabled() {
        return this.transactionFactorList.find(item => item.isSelected == true );
    },
    isVendorButtonDisabled() {
        return this.transactionVendorList.find(item => item.isSelected == true && item.id !== '');
    },
    isCoverageButtonDisabled() {
        return this.coverageDetailList.find(item => item.isSelected == true );
    },
    getProducerName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          const element = this.producerList.find(element => {
            return element.id == value
          });

          if (element !== null && element !== '' && element !== undefined) {
            return element.name;
          }
          else return null;
        }
      }
    },
    getProducerPolicyTypeName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          const element = this.producerPolicyTypeList.find(element => {
            return element.id == value
          });

          if (element !== null && element !== '' && element !== undefined) {
            return element.producer_policy_name;
          }
          else return null;
        }
      }
    },
    isNextButtonDisabled() {
        if(this.activeModalStep == 1) {
            return this.modalForm.producer_id == null || this.modalForm.producer_id == '' || this.modalForm.producer_id == '-1' || this.modalForm.producer_policy_type == null 
            || this.modalForm.producer_policy_type == '' || this.modalForm.producer_policy_type == '-1' || 
            (this.modalForm.policy_type == 2 && (this.modalForm.endorsement_type == null || this.modalForm.endorsement_type == '' || this.modalForm.endorsement_type == 0 
            || this.modalForm.endorsement_detail_id == null || this.modalForm.endorsement_detail_id == '-1' || this.modalForm.endorsement_detail_id == '' || 
            (this.selectedProducerAccount.supplier_is_main_policy_check_active == 1 && !(this.mainPolicyList.find(item => item.isSelected == true ))))) ;
        }
        if(this.activeModalStep == 2) {
            return this.modalForm.policy_no == null || this.modalForm.policy_no == '' 
            || this.modalForm.renewal_no == null || this.modalForm.renewal_no == ''  
            || this.modalForm.endorsement_no == null || this.modalForm.endorsement_no == '';
        }
        else {
            return false;
        }
    },
    transactionVendorFilteredList(){
        return this.transactionVendorList.filter(p => { return p.id !== ''; });
    }
  },
  methods: { 
    getData() {
        this.$Progress.start(); 
        this.localCurrency = this.$getUser().currency_code;
        this.$setSessionStorage('refreshList', true); 
        this.isPolicyProgressing = false;        
        this.$removeSessionStorage('tmp_session_id'); 
        this.$removeSessionStorage('data'+ '_ITMLST');
             
        const params = {
            session_id : this.$getUser().session_id,
            policy_card_id : this.$getSessionStorage('record_id')
        };
        axios.post('api/Insurance/GetPolicyCardById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.form = JSON.parse(JSON.stringify(response.data.policy_data));
                this.form.campaign_id = (response.data.policy_data.campaign_id == null || response.data.policy_data.campaign_id == '') ? '-1': response.data.policy_data.campaign_id;
                this.form.sales_channel_id = (response.data.policy_data.sales_channel_id == null || response.data.policy_data.sales_channel_id == '') ? '-1': response.data.policy_data.sales_channel_id;
                this.form.endorsement_no = 0,
                this.form.activeView = 2;
                this.vendorDefaultList = JSON.parse(JSON.stringify(response.data.policy_vendors));
                if(this.vendorDefaultList.length > 0){ this.$setSessionStorage('tmp_session_id', this.vendorDefaultList[0].tmp_session_id); } 
                this.transactionList = response.data.transactions;
                this.getDetailLists();
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    getYearList(isChanged) {
        this.yearList = [];
        let x = -1;
        let thisYear =  moment(this.form.start_date).year();
        for (let i = 1; i <= 3; i++) {
            this.yearList.push(thisYear + x);
            x++;
        }

        if(isChanged) {
            this.form.year = thisYear;
            this.form.end_date = moment(this.form.start_date).add(1, 'year');
        }
    },
    onChangeStartDate() {
        this.modalForm.end_date = moment(this.modalForm.start_date).add(1, 'year');
    },
    onChangeInstallmentDate() {
        this.modalForm.installment_date = moment(this.modalForm.advance_date).add(30, 'days');
    },
    onCheckPolicyDuplicate() {
        const parameters = {
            session_id : this.$getUser().session_id,
            producer_id: this.modalForm.producer_id,
            producer_policy_type: this.modalForm.producer_policy_type,
            policy_no: this.modalForm.policy_no,
            renewal_no: this.modalForm.renewal_no,
            endorsement_no: this.modalForm.endorsement_no,
            id: this.modalType == 'editItem' ? this.modalForm.id : null
        };  
        axios.post('/api/Insurance/CheckPolicyDuplicate', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.onNextStep();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onCheckPolicyAmounts() {
        if(!this.modalForm.is_no_amount) {
            const parameters = {
                session_id : this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                total_amount: this.modalForm.policy_total_amount,
                producer_amount: this.modalForm.producer_amount,
            };  
            axios.post('/api/Insurance/CheckPolicyAmounts', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.onNextStep();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            this.onNextStep();
        }
    },
    onNextStep() {
        this.activeModalStep++;

        if(this.activeModalStep == 3) {
            if(this.modalForm.currency !== this.localCurrency) {
                axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'}).then((typeResponse) => {
                    this.typeList = typeResponse.data;
                    this.typeList.forEach((item) => { 
                        if(item.type == this.form.rate_list_type){
                            this.typeSelection = item.id;
                            this.rateTypeSelection = item.id;
                            this.rateType = item.type;
                        } else {
                            if(item.is_default == 1){
                                this.typeSelection = item.id;
                                this.rateTypeSelection = item.id;
                                this.rateType = item.type;
                            }
                        }
                    });
                });
            }
        }

        if(this.activeModalStep == 4 && !this.isVendorsAdded && this.modalType == 'addItem') {
            this.addVendorToTransaction();
        }
        if(this.activeModalStep == 4 && this.isVendorsAdded) {
            this.updateVendorCommission();
        }
        if(this.activeModalStep == 5 && !this.isCoveragesAdded && this.modalType == 'addItem') {
            this.addCoverageToTransaction();
        }

        if(this.activeModalStep == 2 && (this.modalForm.endorsement_type == 2 || this.modalForm.endorsement_type == 3 || this.modalForm.endorsement_type == 4)) {
            this.mainPolicyList.forEach((value) => {
                if (value.isSelected) {
                    this.modalForm.policy_no = value.policy_no;
                    this.modalForm.renewal_no = value.renewal_no;
                }
            });
        }
        if(this.activeModalStep == 2 && this.modalForm.is_new_or_renewal == 2) { this.onChangeNewOrRenewal(); }

        if(this.activeModalStep == 7) {
            this.getSourceLists();
        }
    },
    onBackStep() {
        this.activeModalStep--;
    },
    getSourceLists() {
        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["campaign", "sales_channel"]
        };  
        const parametersTwo = { 
            session_id: this.$getUser().session_id,
            list_type: 7,
            main_branch_id: this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id
        };  
        const parametersThree = { 
            session_id: this.$getUser().session_id,
            list_type: 8,
            main_branch_id: this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id
        };  
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
        const requestTwo =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersTwo, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersThree, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.campaignList = responses[0].data.campaign;
            this.salesChannelList = responses[0].data.sales_channel;
            this.sourceCodeList = responses[1].data;
            this.sourceCode2List = responses[2].data;
            this.campaignList.unshift({ id: '-1', text: 'Select an option'});
            this.salesChannelList.unshift({ id: '-1', text: 'Select an option'});
            this.sourceCodeList.unshift({ id: '-1', text: 'Select an option'});
            this.sourceCode2List.unshift({ id: '-1', text: 'Select an option'});
        }))
        .catch(errors => { 
            console.error(errors);
        });
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    getDetailLists() {
        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency"]
        };
        const parametersTwo = {
            session_id: this.$getUser().session_id,            
            supplierAccounts: [
                {account_type: 1,account_name: "producer"},
                {account_type: 2,account_name: "vendor"}
            ],
        };
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/GetCompanyAccounts/GetMultiPolicyAccounts', parametersTwo, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.currencyList = responses[0].data.currency;
            this.producerList = responses[1].data.producer;
            this.vendorList = responses[1].data.vendor;
            this.producerList.unshift({ id: '-1', text: 'Select an option'});
            this.vendorList.unshift({ id: '-1', text: 'Select an option'});
            this.getAccountDetail(this.form.account_id);
            this.getYearList();
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        });
    },
    getPolicyTransactionLists(isChanged) {
        if(isChanged) this.modalForm.producer_policy_type = '-1';
        const parameter1 = {
          session_id: this.$getUser().session_id,
          producer_id: this.modalForm.producer_id,
          main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
          policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id,
        };  
        const parameterTwo = {
            id: this.modalForm.producer_id,
            session_id: this.$getUser().session_id
        };        
        const parameterThree = {
            session_id: this.$getUser().session_id,
            list_name: ["branch"],
            list_types: [
                { list_type: 11, list_name: "payment" },
                { list_type: 12, list_name: "shipping" }
            ],
        };
        const requestOne =  axios.post('/api/Insurance/GetProducerPolicyTypes', parameter1, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/CompanyAccounts/GetAccountById', parameterTwo, {'Content-Type': 'application/json'});        
        const requestThree =  axios.post('/api/Helper/GetMultiLists', parameterThree, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/Helper/GeneralLists', parameterThree, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
            this.producerPolicyTypeList = responses[0].data;
            this.producerPolicyTypeList.unshift({ id: '-1', text: 'Select an option'});
            this.selectedProducerAccount = responses[1].data;            
            this.paymentList = responses[2].data.payment;
            this.shippingList = responses[2].data.shipping;
            this.branchList = responses[3].data.branch;
            this.paymentList.unshift({ id: '-1', text: 'Select an option'});
            this.shippingList.unshift({ id: '-1', text: 'Select an option'});
            this.paymentList.forEach((value) => { if(value.is_default) {this.modalForm.payment_method_id = value.id;} });
            this.shippingList.forEach((value) => { if(value.is_default) {this.modalForm.shipping_type_id = value.id;} });
            this.getMainPolicyTransactions();
        }))
        .catch(errors => { 
            console.error(errors); 
        });        
    },
    onChangeProducer(isChanged) {
        if(isChanged) this.modalForm.producer_policy_type = '-1';
        const parameter = {
          session_id: this.$getUser().session_id,
          producer_id: this.modalForm.producer_id,
          main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
          policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id,
        };
        const requestOne =  axios.post('/api/Insurance/GetProducerPolicyTypes', parameter, {'Content-Type': 'application/json'});        
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.producerPolicyTypeList = responses[0].data;
            this.producerPolicyTypeList.unshift({ id: '-1', text: 'Select an option'});
            if(this.modalType == 'editItem') {                
                this.producerPolicyTypeList.forEach(element => {
                    if(element.id == this.modalForm.producer_policy_type) {
                        this.modalForm.policy_group_name = element.policy_group_name;
                    }
                });
            }
            this.getMainPolicyTransactions();
        }))
        .catch(errors => { 
            console.error(errors); 
        });        
    },
    getEndorsementDetailList(isEditMode) {
        if(!isEditMode)this.modalForm.endorsement_detail_id = '-1';
        const parameters = {
            session_id: this.$getUser().session_id,
            list_type: 6, /* Endorsement Detail */
            type: this.modalForm.endorsement_type,
            policy_card_id: this.$getSessionStorage('record_id'),
            producer_id: this.modalForm.producer_id
        };  
        
        const requestOne =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.endorsementDetailList = responses[0].data;
            this.endorsementDetailList.unshift({ id: '-1', text: 'Select an option'});
            this.getMainPolicyTransactions(isEditMode);
        }))
        .catch(errors => { 
            console.error(errors);
        }); 
    },
    getMainPolicyTransactions(isEditMode) {
        if(this.modalForm.endorsement_type == 1) return;
        const parameters = {
            session_id: this.$getUser().session_id,
            policy_card_id: this.$getSessionStorage('record_id'),
            producer_id: this.modalForm.producer_id
        };  
        axios.post('/api/Insurance/GetMainPolicyTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){              
                this.mainPolicyList = response.data;
                if(isEditMode) {
                    this.mainPolicyList.forEach(element => { if(element.id == this.modalForm.main_policy_id) { element.isSelected = true; } });
                } else {
                    this.getMainPolicyRenewalStatus();
                    this.mainPolicyList.forEach(element => { if(element.policy_no == this.modalForm.policy_no) { element.isSelected = true; } });
                }
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    getMainPolicyRenewalStatus() {
        const parameters = {
            session_id: this.$getUser().session_id,
            policy_card_id: this.$getSessionStorage('record_id'),
            policy_no: this.modalForm.policy_no
        };  
        axios.post('/api/Insurance/GetMainPolicyRenewalStatus', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.modalForm.is_new_or_renewal = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onChangeNewOrRenewal() {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.form.account_id,
            main_branch_id: this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id,
            policy_transaction_id: this.modalForm.id,
            regulation_date: moment(this.modalForm.regulation_date).format('YYYY-MM-DD')
        };
        axios.post('/api/Insurance/GetPoliciesToRenewal', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){              
                this.oldPolicyList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectItem(array, item) {
        array.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onSelectFactorItem(item) {
        this.transactionFactorList.forEach((value) => { 
            if (item.factor_id !== value.factor_id) {
                value.isSelected = false;
            }
        });
    },
    getAccountDetail(id) {
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            response.data.name = this.$unlock(response.data.name);
            response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
            response.data.customer_address = this.$unlock(response.data.customer_address);
            response.data.customer_city = this.$unlock(response.data.customer_city);
            response.data.customer_district = this.$unlock(response.data.customer_district);
            response.data.customer_country = this.$unlock(response.data.customer_country);
            response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
            response.data.customer_email = this.$unlock(response.data.customer_email);
            response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            this.selectedAccountInfo = response.data;
            this.form.account_id = response.data.id;
            this.form.account_type = response.data.account_type;
            this.form.account_name = this.$unlock(response.data.account_id) + (this.$unlock(response.data.account_id) ? ' - ' : '') + this.$unlock(response.data.name) + ' | ' + response.data.account_type_name;
        })
        .catch(function () {
        });  
    },
    onChangeRateType(){
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.modalForm.regulation_date),
            bank_id: 0,
            currency: this.modalForm.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == (!this.isViewEditTransactionCoverageModal ? this.typeSelection : this.rateTypeSelection));
        this.rateType = source.type;
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                this.rateList = response.data;
                this.modalForm.rate_list_id = response.data[0].id;
                this.modalForm.rate = parseFloat(response.data[0].rate.replace(/,/g, '.'));
                this.modalForm.rate_date = response.data[0].rate_date;
            }
            else{
                this.modalForm.rate = 1;
                this.modalForm.rate_date = null; 
                this.rateList = null;
                this.rateList = 
                [{
                    id: '1',
                    text: '1,0000'
                }];
                this.modalForm.rate_list_id = 1;
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency(){
        this.modalForm.rate = 1;
        if (this.modalForm.currency == this.localCurrency) {return;}

        this.$Progress.start();
        axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
        .then((typeResponse) => {
                this.typeList = typeResponse.data;
                this.typeList.forEach((item) => { 
                    if(item.is_default == 1){
                        this.typeSelection = item.id;
                        this.rateTypeSelection = item.id;
                        this.rateType = item.type;
                    }
                });  

                const params = {
                    session_id: this.$getUser().session_id,
                    rate_date: moment(this.modalForm.regulation_date),
                    bank_id: 0,
                    currency: this.modalForm.currency
                };
                params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                var source = this.typeList.find((element) => element.id == (!this.isViewEditTransactionCoverageModal ? this.typeSelection : this.rateTypeSelection));
                params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.length > 0){
                        this.rateList = response.data;
                        this.modalForm.rate_list_id = response.data[0].id;
                        this.modalForm.rate = parseFloat(response.data[0].rate.replace(/,/g, '.'));
                        this.modalForm.rate_date = response.data[0].rate_date;
                    }
                    else{
                        this.modalForm.rate = 1;
                        this.modalForm.rate_date = null;
                        this.rateList = [{
                            id: '1',
                            text: '1,0000'
                        }];
                        this.modalForm.rate_list_id = 1;
                    }
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    console.log(error);
                    this.$Progress.finish();
                });  
        })
        .catch(function (error) {
            console.log(error); 
        }); 
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/PoliciesHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onChangePolicyType() {
        this.modalForm.endorsement_type = this.modalForm.policy_type == 1 ? 1 : 0;
    },    
    updatePolicy(){ 
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.$swal.fire({
            title: "Are you sure you want to save this Policy?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(this.form.start_date == null || this.form.start_date == '' || this.form.start_date == undefined) {
                    this.$swal("Warning", "Start Date must be valid..!", 'info'); return;
                }
                if(this.form.end_date == null || this.form.end_date == '' || this.form.end_date == undefined) {
                    this.$swal("Warning", "End Date must be valid..!", 'info'); return;
                }
                if(this.form.regulation_date == null || this.form.regulation_date == '' || this.form.regulation_date == undefined) {
                    this.$swal("Warning", "Regulation Date must be valid..!", 'info'); return;
                }
                this.$Progress.start();
                this.isPolicyProgressing = true;
                const that = this;
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                    policy_no: this.form.policy_no,
                    renewal_no: this.form.renewal_no,
                    regulation_date: this.form.regulation_date,
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    year: this.form.year,
                    main_producer_id: this.form.main_producer_id !== '-1' ? this.form.main_producer_id : null
                };
                params.regulation_date = moment(params.regulation_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

                axios.post('api/Insurance/UpdatePolicy', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.getData();
                        this.$swal("Success", "Policy updated succesfully..!", 'success');
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () { 
                    this.$Progress.finish(); 
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isPolicyProgressing = false; 
                        that.$Progress.finish();
                    }, 500);
                });  
            }
        });
    },
    openAddVendorModal(){
        this.$refs.AddDefaultVendor.getData();
    },
    deleteVendorItem(){
        let vendorId = null;
        this.vendorDefaultList.forEach((item) => {
            if (item.isSelected) {
                vendorId = item.vendor_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    vendor_id: vendorId,
                    is_delete_all: 0
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllVendors(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    vendor_id: null
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    onCalculateTotalAmount() {
        this.modalForm.policy_net_amount = this.modalForm.policy_net_amount == '' || this.modalForm.policy_net_amount == null ? 0 : this.modalForm.policy_net_amount;
        this.modalForm.policy_tax_amount = this.modalForm.policy_tax_amount == '' || this.modalForm.policy_tax_amount == null ? 0 : this.modalForm.policy_tax_amount;
        this.modalForm.sub_tax_1_amount = this.modalForm.sub_tax_1_amount == '' || this.modalForm.sub_tax_1_amount == null ? 0 : this.modalForm.sub_tax_1_amount;
        this.modalForm.sub_tax_2_amount = this.modalForm.sub_tax_2_amount == '' || this.modalForm.sub_tax_2_amount == null ? 0 : this.modalForm.sub_tax_2_amount;
        this.modalForm.sub_tax_3_amount = this.modalForm.sub_tax_3_amount == '' || this.modalForm.sub_tax_3_amount == null ? 0 : this.modalForm.sub_tax_3_amount;
        this.modalForm.insurance_commission = this.modalForm.insurance_commission == '' || this.modalForm.insurance_commission == null ? 0 : this.modalForm.insurance_commission;

        this.modalForm.policy_total_amount  = 0;
        this.modalForm.policy_total_amount = parseFloat(this.modalForm.policy_net_amount) +  parseFloat(this.modalForm.policy_tax_amount) +  parseFloat(this.modalForm.sub_tax_1_amount) + parseFloat(this.modalForm.sub_tax_2_amount) + parseFloat(this.modalForm.sub_tax_3_amount);

        this.modalForm.advance_amount = this.modalForm.policy_total_amount;
        this.modalForm.installment_remain = this.modalForm.advance_amount;
        
        this.modalForm.producer_amount = this.modalForm.policy_total_amount - this.modalForm.insurance_commission;
        if(this.selectedProducerAccount.supplier_tax_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.policy_tax_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_1_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_1_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_2_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_2_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_3_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_3_amount);
        }
        this.onCalculateAdvanceTotalAmount();
    },
    onCalculateProducerAmount() {
        this.modalForm.policy_total_amount = this.modalForm.policy_total_amount == '' || this.modalForm.policy_total_amount == null ? 0 : this.modalForm.policy_total_amount;
        this.modalForm.policy_tax_amount = this.modalForm.policy_tax_amount == '' || this.modalForm.policy_tax_amount == null ? 0 : this.modalForm.policy_tax_amount;
        this.modalForm.sub_tax_1_amount = this.modalForm.sub_tax_1_amount == '' || this.modalForm.sub_tax_1_amount == null ? 0 : this.modalForm.sub_tax_1_amount;
        this.modalForm.sub_tax_2_amount = this.modalForm.sub_tax_2_amount == '' || this.modalForm.sub_tax_2_amount == null ? 0 : this.modalForm.sub_tax_2_amount;
        this.modalForm.sub_tax_3_amount = this.modalForm.sub_tax_3_amount == '' || this.modalForm.sub_tax_3_amount == null ? 0 : this.modalForm.sub_tax_3_amount;
        this.modalForm.insurance_commission = this.modalForm.insurance_commission == '' || this.modalForm.insurance_commission == null ? 0 : this.modalForm.insurance_commission;

        this.modalForm.producer_amount = this.modalForm.policy_total_amount - this.modalForm.insurance_commission;
        if(this.selectedProducerAccount.supplier_tax_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.policy_tax_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_1_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_1_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_2_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_2_amount);
        }
        if(this.selectedProducerAccount.supplier_sub_tax_3_method == 2) {
            this.modalForm.producer_amount = this.modalForm.producer_amount - parseFloat(this.modalForm.sub_tax_3_amount);
        }
    },
    onCalculateAdvanceTotalAmount() {
        this.modalForm.advance_amount = this.modalForm.advance_amount == '' || this.modalForm.advance_amount == null ? 0 : this.modalForm.advance_amount;
        this.modalForm.installment_remain = this.modalForm.policy_total_amount - this.modalForm.advance_amount;
    },
    onChangeTotalsView() {
        this.isViewLocalTotals = !this.isViewLocalTotals;
        if(this.isViewLocalTotals) {
            this.modalForm.policy_net_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.policy_net_amount / this.modalForm.rate) : (this.modalForm.policy_net_amount * this.modalForm.rate);
            this.modalForm.policy_tax_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.policy_tax_amount / this.modalForm.rate) : (this.modalForm.policy_tax_amount * this.modalForm.rate);
            this.modalForm.sub_tax_1_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.sub_tax_1_amount / this.modalForm.rate) : (this.modalForm.sub_tax_1_amount * this.modalForm.rate);
            this.modalForm.sub_tax_2_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.sub_tax_2_amount / this.modalForm.rate) : (this.modalForm.sub_tax_2_amount * this.modalForm.rate);
            this.modalForm.sub_tax_3_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.sub_tax_3_amount / this.modalForm.rate) : (this.modalForm.sub_tax_3_amount * this.modalForm.rate);
            this.modalForm.insurance_commission_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.insurance_commission / this.modalForm.rate) : (this.modalForm.insurance_commission * this.modalForm.rate);
            this.modalForm.producer_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.producer_amount / this.modalForm.rate) : (this.modalForm.producer_amount * this.modalForm.rate);

            this.modalForm.policy_total_amount_local  = 0;
            this.modalForm.policy_total_amount_local = parseFloat(this.modalForm.policy_net_amount_local) +  parseFloat(this.modalForm.policy_tax_amount_local) +  parseFloat(this.modalForm.sub_tax_1_amount_local) + parseFloat(this.modalForm.sub_tax_2_amount_local) + parseFloat(this.modalForm.sub_tax_3_amount_local);

            this.modalForm.advance_amount_local = this.modalForm.policy_total_amount_local;
            this.modalForm.installment_remain_local = this.modalForm.advance_amount_local;
            this.onCalculateAdvanceTotalAmountLocal();
        }
    },
    onCalculateAdvanceTotalAmountLocal() {        
        this.modalForm.advance_amount_local = this.$getUser().currency_search_type == 1 ? (this.modalForm.advance_amount / this.modalForm.rate) : (this.modalForm.advance_amount * this.modalForm.rate);
        this.modalForm.installment_remain_local = this.modalForm.policy_total_amount_local - this.modalForm.advance_amount_local;
    },
    openTransactionModal(modalType) {
        try {
            this.$refs.openTransactionModal.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.clearModalForm();
        this.$removeSessionStorage('trn_tmp_session_id'); 
        this.isVendorsAdded = false;
        this.isCoveragesAdded = false;
        this.modalForm.policy_no = this.form.policy_no;
        this.modalForm.renewal_no = this.form.renewal_no;
        this.modalForm.producer_id = this.form.main_producer_id;
        this.modalForm.currency = this.$getUser().currency_code;
        if(this.transactionList.length == 0) { 
            this.modalForm.regulation_date = this.form.regulation_date;            
            this.modalForm.start_date = this.modalForm.regulation_date;
            this.modalForm.end_date = moment(this.modalForm.regulation_date).add(1, 'year');
        }

        this.modalType = modalType;
        this.isTransactionModalVisible = true;
        this.isViewFactorModal = false;
        this.isViewTransactionFactorModal = false;
        this.isViewTransactionVendorModal = false;
        this.isTransactionSaving = false;
        this.activeModalStep = 1;
        this.transactionVendorList = [];
        this.collectionPlanList = [];
        this.coverageDetailList = [];
        this.transactionFactorList = [];
        if(this.modalType == 'addItem') {
            if(this.vendorDefaultList.length > 0) {
                this.modalForm.branch_id = (this.vendorDefaultList[0].vendor_branch_id !== null && this.vendorDefaultList[0].vendor_branch_id !== '') ? this.vendorDefaultList[0].vendor_branch_id : '-1';
            }
        }
        this.getPolicyTransactionLists();

        if(this.modalType == 'editItem') {
            this.isTransactionSaving = true;
            this.isVendorsAdded = true;
            this.transactionList.forEach(element => {
                if(element.isSelected) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        transaction_id: element.id
                    };  
                    const that = this;
                    axios.post('/api/Insurance/GetTransactionById', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.modalForm = {
                                id: response.data.transaction.id,
                                producer_type: response.data.transaction.producer_type,
                                producer_id: response.data.transaction.producer_id,
                                producer_policy_type: (response.data.transaction.producer_policy_type == '' || response.data.transaction.producer_policy_type == null) ? '-1' : response.data.transaction.producer_policy_type,
                                policy_type: response.data.transaction.policy_type,
                                endorsement_type: response.data.transaction.endorsement_type,
                                endorsement_detail_id: (response.data.transaction.endorsement_detail_id == '' || response.data.transaction.endorsement_detail_id == null) ? '-1' : response.data.transaction.endorsement_detail_id,
                                regulation_date: response.data.transaction.regulation_date,
                                start_date: response.data.transaction.start_date,
                                end_date: response.data.transaction.end_date,
                                policy_no: response.data.transaction.policy_no,
                                renewal_no: response.data.transaction.renewal_no,
                                endorsement_no: response.data.transaction.endorsement_no,
                                policy_net_amount: (response.data.transaction.policy_net_amount).replace(/,/g, ''),
                                policy_total_amount: (response.data.transaction.policy_total_amount).replace(/,/g, ''),
                                policy_tax_amount: (response.data.transaction.policy_tax_amount).replace(/,/g, ''),
                                sub_tax_1_amount: (response.data.transaction.sub_tax_1_amount).replace(/,/g, ''),
                                sub_tax_2_amount: (response.data.transaction.sub_tax_2_amount).replace(/,/g, ''),
                                sub_tax_3_amount: (response.data.transaction.sub_tax_3_amount).replace(/,/g, ''),
                                policy_net_amount_local: 0,
                                policy_total_amount_local: (response.data.transaction.policy_total_amount_local).replace(/,/g, ''),
                                advance_amount: (response.data.transaction.advance_amount).replace(/,/g, ''),
                                advance_amount_local: 0,
                                insurance_commission: (response.data.transaction.insurance_commission).replace(/,/g, ''),
                                producer_amount: (response.data.transaction.payment_amount).replace(/,/g, ''),
                                payment_type: response.data.transaction.payment_type,
                                campaign_id: (response.data.transaction.campaign_id == '' || response.data.transaction.campaign_id == null) ? '-1' : response.data.transaction.campaign_id,
                                sales_channel_id: (response.data.transaction.sales_channel_id == '' || response.data.transaction.sales_channel_id == null) ? '-1' : response.data.transaction.sales_channel_id,
                                advance_date: moment(),
                                installment_date: moment(),
                                installment_count: response.data.transaction.installment_count,
                                installment_remain: 0,
                                installment_remain_local: 0,
                                is_no_amount: response.data.transaction.is_no_amount == 1 ? true : false,
                                is_new_or_renewal: response.data.transaction.is_new_or_renewal,
                                currency: response.data.transaction.currency,
                                rate_list_type: response.data.transaction.rate_list_type,
                                rate: response.data.transaction.rate,
                                rate_date: response.data.transaction.regulation_date,
                                rate_list_id: response.data.transaction.rate_list_id,
                                insurant_account: response.data.transaction.insurant_account,
                                loss_payable_clause: response.data.transaction.loss_payable_clause,
                                main_policy_id: response.data.transaction.main_policy_id,
                                collection_status: response.data.transaction.collection_status,
                                collection_type: response.data.transaction.collection_type,
                                source_code: (response.data.transaction.source_code == '' || response.data.transaction.source_code == null) ? '-1' : response.data.transaction.source_code,
                                source_code_2: (response.data.transaction.source_code_2 == '' || response.data.transaction.source_code_2 == null) ? '-1' : response.data.transaction.source_code_2,
                                payment_method_id: (response.data.transaction.payment_method_id == '' || response.data.transaction.payment_method_id == null) ? '-1' : response.data.transaction.payment_method_id,
                                shipping_type_id: (response.data.transaction.shipping_type_id == '' || response.data.transaction.shipping_type_id == null) ? '-1' : response.data.transaction.shipping_type_id,
                                branch_id: (response.data.transaction.branch_id == '' || response.data.transaction.branch_id == null) ? '-1' : response.data.transaction.branch_id
                            };
                            this.rateType = this.modalForm.rate_list_type;
                            this.typeList.forEach((item) => { 
                                if(item.type == this.modalForm.rate_list_type){
                                    this.typeSelection = item.id;
                                    this.rateTypeSelection = item.id;
                                    this.rateType = item.type;
                                }
                            });
                            this.$setSessionStorage('trn_tmp_session_id', response.data.transaction.tmp_session_id);
                            this.collectionPlanList = response.data.collectionPlan;
                            this.transactionVendorList = response.data.vendors;
                            this.coverageDetailList = response.data.coverages;
                            this.transactionFactorList = response.data.factors;
                            if(this.transactionVendorFilteredList.length == 0) { this.isMainPolicy = true; }
                            else { this.isMainPolicy = false; }
                            this.onCalculateTotalAmount();
                            this.modalForm.advance_amount = (response.data.transaction.advance_amount).replace(/,/g, '');
                            this.modalForm.installment_remain = this.modalForm.policy_total_amount - this.modalForm.advance_amount;

                            if(this.modalForm.endorsement_type !== 1 && this.modalForm.endorsement_type !== 0) {                                
                                this.getEndorsementDetailList(true);
                            }
                            this.onChangeProducer();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function() {
                        setTimeout(() => {
                            that.isTransactionSaving = false;
                        }, 500);
                    });
                }
            });
        }
    },
    addTransaction() {
        this.$swal.fire({
            title: "Are you sure you want to save this transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(this.modalForm.start_date == null || this.modalForm.start_date == '' || this.modalForm.start_date == undefined) {
                    this.$swal("Warning", "Start Date must be valid..!", 'info'); return;
                }
                if(this.modalForm.end_date == null || this.modalForm.end_date == '' || this.modalForm.end_date == undefined) {
                    this.$swal("Warning", "End Date must be valid..!", 'info'); return;
                }
                if(this.modalForm.regulation_date == null || this.modalForm.regulation_date == '' || this.modalForm.regulation_date == undefined) {
                    this.$swal("Warning", "Regulation Date must be valid..!", 'info'); return;
                }
                this.isTransactionSaving = true;
                const parameters = {
                    session_id: this.$getUser().session_id,
                    policy_card_id: this.$getSessionStorage('record_id'),
                    currency: this.modalForm.currency,
                    rate: this.modalForm.rate,
                    rate_list_type: this.rateType,
                    producer_type: this.modalForm.producer_type,
                    producer_id: this.modalForm.producer_id !== '-1' ? this.modalForm.producer_id : null,
                    producer_policy_type: this.modalForm.producer_policy_type !== '-1' ? this.modalForm.producer_policy_type : null,
                    policy_type: this.modalForm.policy_type,
                    endorsement_type: this.modalForm.endorsement_type,
                    endorsement_detail_id: this.modalForm.endorsement_detail_id !== '-1' ? this.modalForm.endorsement_detail_id : null,
                    regulation_date: this.modalForm.regulation_date,
                    start_date: this.modalForm.start_date,
                    end_date: this.modalForm.end_date,
                    policy_no: this.modalForm.policy_no,
                    renewal_no: this.modalForm.renewal_no,
                    endorsement_no: this.modalForm.endorsement_no,
                    policy_net_amount: !this.modalForm.is_no_amount ? (this.modalForm.policy_net_amount !== '' && this.modalForm.policy_net_amount !== null ? this.modalForm.policy_net_amount : 0) : 0,
                    policy_tax_amount: !this.modalForm.is_no_amount ? (this.modalForm.policy_tax_amount !== '' && this.modalForm.policy_tax_amount !== null ? this.modalForm.policy_tax_amount : 0) : 0,
                    sub_tax_1_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_1_amount !== '' && this.modalForm.sub_tax_1_amount !== null ? this.modalForm.sub_tax_1_amount : 0) : 0,
                    sub_tax_2_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_2_amount !== '' && this.modalForm.sub_tax_2_amount !== null ? this.modalForm.sub_tax_2_amount : 0) : 0,
                    sub_tax_3_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_3_amount !== '' && this.modalForm.sub_tax_3_amount !== null ? this.modalForm.sub_tax_3_amount : 0) : 0,
                    insurance_commission: !this.modalForm.is_no_amount ? (this.modalForm.insurance_commission !== '' && this.modalForm.insurance_commission !== null ? this.modalForm.insurance_commission : 0) : 0,
                    advance_amount: (this.modalForm.advance_amount !== '' && this.modalForm.advance_amount !== null) ? this.modalForm.advance_amount : 0,
                    campaign_id: this.modalForm.campaign_id !== '-1' ? this.modalForm.campaign_id : null,
                    sales_channel_id: this.modalForm.sales_channel_id !== '-1' ? this.modalForm.sales_channel_id : null,
                    is_no_amount: this.modalForm.is_no_amount ? 1 : 0, 
                    is_new_or_renewal: this.modalForm.is_new_or_renewal,
                    insurant_account: this.modalForm.insurant_account,
                    loss_payable_clause: this.modalForm.loss_payable_clause,
                    tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                    main_policy_id: null,
                    source_code: this.modalForm.source_code !== '-1' ? this.modalForm.source_code : null,
                    source_code_2: this.modalForm.source_code_2 !== '-1' ? this.modalForm.source_code_2 : null,
                    collection_status: this.modalForm.collection_status,
                    collection_type: this.modalForm.collection_type,
                    payment_method_id: this.modalForm.payment_method_id !== '-1' ? this.modalForm.payment_method_id : null,
                    shipping_type_id: this.modalForm.shipping_type_id !== '-1' ? this.modalForm.shipping_type_id : null,
                    branch_id: this.modalForm.branch_id !== '-1' ? this.modalForm.branch_id : null,
                    transactionIds: []
                };
                parameters.regulation_date = moment(parameters.regulation_date).format('YYYY-MM-DD[T]HH:mm:ss');
                parameters.start_date = moment(parameters.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                parameters.end_date = moment(parameters.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

                if(parameters.endorsement_type == 2 || parameters.endorsement_type == 3 || parameters.endorsement_type == 4) {
                    this.mainPolicyList.forEach(element => {
                        if(element.isSelected) {
                            parameters.main_policy_id = element.id;
                        }
                    });
                }

                if(parameters.is_new_or_renewal == 2) {
                    this.oldPolicyList.forEach(element => {
                        if(element.isSelected) {
                            parameters.transactionIds.push(element.id);
                        }
                    });
                    if(parameters.transactionIds.length == 0) parameters.transactionIds = ['-1'];
                }
                const that = this;
                axios.post('/api/Insurance/CreatePolicyTransaction', parameters, {'Content-Type': 'application/json'})
                .then((response) => {            
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.transactionList = response.data;
                        this.$refs.closeTransactionModal.click();
                        this.isTransactionModalVisible = false;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setTimeout(() => {
                        that.isTransactionSaving = false;
                    }, 500);
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isTransactionSaving = false;
                    }, 500);
                });
            }
        });
    },
    editTransaction() {
        this.$swal.fire({
            title: "Are you sure you want to update this transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(this.modalForm.start_date == null || this.modalForm.start_date == '' || this.modalForm.start_date == undefined) {
                    this.$swal("Warning", "Start Date must be valid..!", 'info'); return;
                }
                if(this.modalForm.end_date == null || this.modalForm.end_date == '' || this.modalForm.end_date == undefined) {
                    this.$swal("Warning", "End Date must be valid..!", 'info'); return;
                }
                if(this.modalForm.regulation_date == null || this.modalForm.regulation_date == '' || this.modalForm.regulation_date == undefined) {
                    this.$swal("Warning", "Regulation Date must be valid..!", 'info'); return;
                }
                this.isTransactionSaving = true;
                const parameters = {
                    id: this.modalForm.id,
                    session_id: this.$getUser().session_id,
                    policy_card_id: this.$getSessionStorage('record_id'),
                    currency: this.modalForm.currency,
                    rate: this.modalForm.rate,
                    rate_list_type: this.rateType,
                    producer_type: this.modalForm.producer_type,
                    producer_id: this.modalForm.producer_id !== '-1' ? this.modalForm.producer_id : null,
                    producer_policy_type: this.modalForm.producer_policy_type !== '-1' ? this.modalForm.producer_policy_type : null,
                    policy_type: this.modalForm.policy_type,
                    endorsement_type: this.modalForm.endorsement_type,
                    endorsement_detail_id: this.modalForm.endorsement_detail_id !== '-1' ? this.modalForm.endorsement_detail_id : null,
                    regulation_date: this.modalForm.regulation_date,
                    start_date: this.modalForm.start_date,
                    end_date: this.modalForm.end_date,
                    policy_no: this.modalForm.policy_no,
                    renewal_no: this.modalForm.renewal_no,
                    endorsement_no: this.modalForm.endorsement_no,
                    policy_net_amount: !this.modalForm.is_no_amount ? (this.modalForm.policy_net_amount !== '' && this.modalForm.policy_net_amount !== null ? this.modalForm.policy_net_amount : 0) : 0,
                    policy_tax_amount: !this.modalForm.is_no_amount ? (this.modalForm.policy_tax_amount !== '' && this.modalForm.policy_tax_amount !== null ? this.modalForm.policy_tax_amount : 0) : 0,
                    sub_tax_1_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_1_amount !== '' && this.modalForm.sub_tax_1_amount !== null ? this.modalForm.sub_tax_1_amount : 0) : 0,
                    sub_tax_2_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_2_amount !== '' && this.modalForm.sub_tax_2_amount !== null ? this.modalForm.sub_tax_2_amount : 0) : 0,
                    sub_tax_3_amount: !this.modalForm.is_no_amount ? (this.modalForm.sub_tax_3_amount !== '' && this.modalForm.sub_tax_3_amount !== null ? this.modalForm.sub_tax_3_amount : 0) : 0,
                    insurance_commission: !this.modalForm.is_no_amount ? (this.modalForm.insurance_commission !== '' && this.modalForm.insurance_commission !== null ? this.modalForm.insurance_commission : 0) : 0,
                    campaign_id: this.modalForm.campaign_id !== '-1' ? this.modalForm.campaign_id : null,
                    sales_channel_id: this.modalForm.sales_channel_id !== '-1' ? this.modalForm.sales_channel_id : null,
                    is_no_amount: this.modalForm.is_no_amount ? 1 : 0, 
                    is_new_or_renewal: this.modalForm.is_new_or_renewal,
                    insurant_account: this.modalForm.insurant_account,
                    loss_payable_clause: this.modalForm.loss_payable_clause,
                    source_code: this.modalForm.source_code !== '-1' ? this.modalForm.source_code : null,
                    source_code_2: this.modalForm.source_code_2 !== '-1' ? this.modalForm.source_code_2 : null,
                    collection_status: this.modalForm.collection_status,
                    collection_type: this.modalForm.collection_type,
                    tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                    main_policy_id: null,
                    payment_method_id: this.modalForm.payment_method_id !== '-1' ? this.modalForm.payment_method_id : null,
                    shipping_type_id: this.modalForm.shipping_type_id !== '-1' ? this.modalForm.shipping_type_id : null,
                    branch_id: this.modalForm.branch_id !== '-1' ? this.modalForm.branch_id : null,
                    transactionIds: []
                };  
                parameters.regulation_date = moment(parameters.regulation_date).format('YYYY-MM-DD[T]HH:mm:ss');
                parameters.start_date = moment(parameters.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                parameters.end_date = moment(parameters.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

                if(parameters.endorsement_type == 2 || parameters.endorsement_type == 3 || parameters.endorsement_type == 4) {
                    this.mainPolicyList.forEach(element => {
                        if(element.isSelected) {
                            parameters.main_policy_id = element.id;
                        }
                    });
                }
                if(parameters.is_new_or_renewal == 2) {
                    this.oldPolicyList.forEach(element => {
                        if(element.isSelected) {
                            parameters.transactionIds.push(element.id);
                        }
                    });
                    if(parameters.transactionIds.length == 0) parameters.transactionIds = ['-1'];
                }
                const that = this;
                axios.post('/api/Insurance/UpdatePolicyTransaction', parameters, {'Content-Type': 'application/json'})
                .then((response) => {            
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.transactionList = response.data;
                        this.$refs.closeTransactionModal.click();
                        this.isTransactionModalVisible = false;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setTimeout(() => {
                        that.isTransactionSaving = false;
                    }, 500);
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isTransactionSaving = false;
                    }, 500);
                });
            }
        });
    },
    clearModalForm() {
        this.modalForm = {
            id: null,
            producer_type: 1,
            producer_id: '-1',
            producer_policy_type: '-1',
            policy_type: 1,
            endorsement_type: 1,
            endorsement_detail_id: '-1',
            regulation_date: moment(),
            start_date: moment(),
            end_date: moment().add(1, 'year'),
            policy_no: '0',
            renewal_no: '0',
            endorsement_no: '0',
            policy_net_amount:0,
            policy_total_amount: 0,
            policy_tax_amount: 0,
            sub_tax_1_amount: 0,
            sub_tax_2_amount: 0,
            sub_tax_3_amount: 0,
            policy_net_amount_local:0,
            policy_total_amount_local: 0,
            advance_amount_local: 0,
            installment_remain_local: 0,
            insurance_commission: 0,
            payment_type: 1,
            campaign_id: '-1',
            sales_channel_id: '-1',
            advance_date: moment(),
            installment_date: moment(),
            advance_amount: 0,
            installment_count: 1,
            installment_remain: 0,
            producer_amount: 0,
            is_no_amount: 0,
            is_new_or_renewal: 1,
            currency: '-1',
            rate_list_type: 0,
            rate: 1.00000,
            rate_date: null,
            rate_list_id: '-1',
            insurant_account: null,
            loss_payable_clause: null,
            main_policy_id: null,
            source_code: '-1',
            source_code_2: '-1',
            collection_status: 1,
            collection_type: 0,
            payment_method_id: '-1',
            shipping_type_id: '-1',
            branch_id: '-1',
            policy_group_name: null
        };
    },
    onCreateCollectionPlan() {
        const parameters = {
            session_id: this.$getUser().session_id,
            amount: this.modalForm.policy_total_amount,
            currency: this.modalForm.currency,
            rate: this.modalForm.rate,
            producer_id: this.modalForm.producer_id,
            tax_amount: this.modalForm.policy_tax_amount,
            sub_tax1: this.modalForm.sub_tax_1_amount,
            sub_tax2: this.modalForm.sub_tax_2_amount,
            sub_tax3: this.modalForm.sub_tax_3_amount,
            commission_amount: this.modalForm.insurance_commission,
            advance_amount: this.modalForm.advance_amount,
            installment_count: (this.modalForm.installment_count !== '' && this.modalForm.installment_count !== null && this.modalForm.installment_count !== undefined) ? this.modalForm.installment_count : 0,
            advance_date: this.modalForm.advance_date,
            installment_date : this.modalForm.installment_date,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
        };
        parameters.advance_date = moment(parameters.advance_date).format('YYYY-MM-DD');
        parameters.installment_date = moment(parameters.installment_date).format('YYYY-MM-DD');
        if(this.modalForm.advance_amount == this.modalForm.policy_total_amount && this.modalForm.installment_count == 1) {
            parameters.installment_count = 0;
        }
        axios.post('/api/Insurance/CreateCollectionPlan', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.collectionPlanList = response.data;
                if(this.collectionPlanList.length > 0) { 
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.collectionPlanList[0].tmp_session_id); 
                    }
                } 
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    addVendorToTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            policy_card_id: this.$getSessionStorage('record_id'), 
            net_amount: this.modalForm.policy_net_amount, 
            gross_amount: this.modalForm.policy_total_amount, 
            commission_amount: this.modalForm.insurance_commission,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
        };  
        axios.post('/api/Insurance/AddVendorToTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.transactionVendorList = response.data;
                if(this.transactionVendorList.length > 0) {
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.transactionVendorList[0].tmp_session_id);
                    }
                } 
                if(this.transactionVendorFilteredList.length == 0) { this.isMainPolicy = true; }
                else { this.isMainPolicy = false; }
                this.isVendorsAdded = true;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    updateVendorCommission() {
        const parameters = {
            session_id: this.$getUser().session_id,
            policy_card_id: this.$getSessionStorage('record_id'), 
            net_amount: this.modalForm.policy_net_amount, 
            gross_amount: this.modalForm.policy_total_amount, 
            commission_amount: this.modalForm.insurance_commission,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
        };  
        axios.post('/api/Insurance/UpdateVendorCommission', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.transactionVendorList = response.data;
                if(this.transactionVendorList.length > 0) {
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.transactionVendorList[0].tmp_session_id);
                    }
                }
                if(this.transactionVendorFilteredList.length == 0) { this.isMainPolicy = true; }
                else { this.isMainPolicy = false; }
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    addCoverageToTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            producer_id: this.modalForm.producer_id, 
            producer_policy_type: this.modalForm.producer_policy_type, 
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
        };  
        axios.post('/api/Insurance/AddCoverageToTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.coverageDetailList = response.data;
                if(this.coverageDetailList.length > 0) {
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.coverageDetailList[0].tmp_session_id);
                    }
                } 
                this.isCoveragesAdded = true;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    openAddTransactionVendorModal() {
        this.isViewTransactionVendorModal = true;
    },
    deleteTransactionVendorItem(isDeleteAll){
        let vendorId = null;
        this.transactionVendorList.forEach((item) => {
            if (item.isSelected) {
                vendorId = item.vendor_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                    vendor_id: vendorId,
                    is_delete_all: isDeleteAll ? 1 : 0
                }; 
                axios.post('/api/Insurance/DeleteTransactionVendor', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.transactionVendorList = response.data;
                        if(this.transactionVendorFilteredList.length == 0) { this.isMainPolicy = true; }
                        else { this.isMainPolicy = false; }
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    onChangeProducerType() {
        if(this.modalForm.producer_type == 2) {
            this.modalForm.policy_no = null;
            this.modalForm.renewal_no = null;
            this.modalForm.insurant_account = null;
            this.modalForm.loss_payable_clause = null;
        }
    },
    deletePolicyCard(){
        this.$swal.fire({
            title: "Are you sure you want to delete policy card?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: 'error'
        })
        .then((result) => {
            if (result.isConfirmed) {
                
            this.$Progress.start();
            const params = { 
                id: this.$getSessionStorage('record_id'),
                session_id: this.$getUser().session_id
            }
            axios.post('api/Insurance/DeletePolicyCard', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$setSessionStorage('refreshList', true);
                        this.$router.push({ name: 'PolicyCreate' });
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });   
            }
        });
    },
    deleteTransaction(){
        let transactionId = null;
        this.transactionList.forEach((item) => {
            if (item.isSelected) {
                transactionId = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to delete policy transaction?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            icon: 'error'
        })
        .then((result) => {
            if (result.isConfirmed) {
                
            this.$Progress.start();
            const params = { 
                policy_card_id: this.$getSessionStorage('record_id'),
                id: transactionId,
                session_id: this.$getUser().session_id
            }
            axios.post('api/Insurance/DeletePolicyTransaction', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Deleted", "Policy transaction deleted..!", 'success');
                        this.transactionList = response.data;
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });   
            }
        });
    },
    onCustomizeTransactionColumns(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {               
                    if(column.dataField == 'policy_total_amount_local'){ 
                        column.caption =  'Total Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'policy_tax_amount_local'){ 
                        column.caption =  'Tax Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {               
                    if(column.dataField == 'policy_total_amount_local'){ 
                        column.caption =  'Total Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'policy_tax_amount_local'){ 
                        column.caption =  'Taxes Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'policy_net_amount_local'){ 
                        column.caption =  'Net Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'payment_amount_local'){ 
                        column.caption =  'Producer Payment Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'insurance_commission_local'){ 
                        column.caption =  'Commission Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'net_commission_local'){ 
                        column.caption =  'Net Commission Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'vendor_commission_local'){ 
                        column.caption =  'Vendor Commission Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
    openAddTransactionCoverageModal() {
        this.isViewTransactionCoverageModal = true;
    },
    editTransactionCoverageItem(){
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
            id: this.coverageForm.id,
            currency: this.coverageForm.currency,
            amount: this.coverageForm.amount
        };
        const that = this;
        axios.post('/api/Insurance/EditTransactionCoverage', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.coverageDetailList = response.data;
                if(this.coverageDetailList.length > 0){ 
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.coverageDetailList[0].tmp_session_id); 
                    }
                } 
            }
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteTransactionCoverageItem(isDeleteAll){
        let coverageId = null;
        this.coverageDetailList.forEach((item) => {
            if (item.isSelected) {
                coverageId = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                    id: coverageId,
                    is_delete_all: isDeleteAll ? 1 : 0
                }; 
                axios.post('/api/Insurance/DeleteTransactionCoverage', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.coverageDetailList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },    
    openTransactionFactorModal() {
        this.isViewTransactionFactorModal = true;
    },
    deleteTransactionFactorItem(isDeleteAll){
        let factorId = null;
        this.transactionFactorList.forEach((item) => {
            if (item.isSelected) {
                factorId = item.factor_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                    factor_id: factorId,
                    is_delete_all: isDeleteAll ? 1 : 0
                }; 
                axios.post('/api/Insurance/DeleteFactorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.transactionFactorList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    setDetailSummaries(options){
        const optionNames = ["commission_amount", "policy_total_amount", "policy_total_amount_local", "policy_tax_amount", "policy_tax_amount_local", "collection_amount", 
        "collection_amount_local", "premium_tax", "payment_amount", "payment_amount_local", "installment_amount", "installment_amount_local", "remain_amount", "remain_amount_local"]; 

        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
    openFactorModal(modalType) {
        this.isViewFactorModal = true;
        this.$refs.TransactionFactorModal.getData(modalType);
    },
    onChangeProducerPolicyType(event) {
        this.modalForm.policy_group_id = event.id !== '-1' ? event.policy_group_id : null;
        this.modalForm.policy_group_name = event.id !== '-1' ? event.policy_group_name : null;
    },
    onOpenAccountReport(){
        this.$refs.AccountReports.getData();
        this.$refs.AccountReports.setModalCriteriasToDefault();
    },
    onRowUpdating(e){
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
            id: e.oldData.id,
            currency: e.newData.currency ? e.newData.currency : e.oldData.currency,
            amount: e.newData.amount ? parseFloat(e.newData.amount.split(',').join('')) : parseFloat(e.oldData.amount.split(',').join(''))
        };
        
        const that = this;
        axios.post('/api/Insurance/EditTransactionCoverage', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.coverageDetailList = response.data;
                if(this.coverageDetailList.length > 0){ 
                    if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                        this.$setSessionStorage('trn_tmp_session_id', this.coverageDetailList[0].tmp_session_id); 
                    }
                } 
            }
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    onUpdateVendorRow(e){
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
            id: e.oldData.id,
            commission_type: e.newData.commission_type ? e.newData.commission_type : e.oldData.commission_type,
            commission_amount: e.newData.commission_amount ? parseFloat(e.newData.commission_amount.toString().split(',').join('')) : parseFloat(e.oldData.commission_amount.toString().split(',').join('')),
            commission_rate: e.newData.commission_rate ? parseFloat(e.newData.commission_rate.toString().split(',').join('')) : parseFloat(e.oldData.commission_rate.toString().split(',').join(''))
        };
        const that = this;
        axios.post('/api/Insurance/EditTransactionVendor', parameters, { 'Content-Type': 'application/json' })
        .then((response) => {
            if (response.data.error == null || response.data.error == '' || response.data.error == undefined) {                
                const dataSource = this.$refs["dxVendorList"].instance.getDataSource();
                var store = dataSource.store();
                store.update(response.data.id,  response.data)
                .done(function () {
                    dataSource.reload();
                })
                .fail(function (error) { console.log(error) });
            }
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    setCommissionTypeCellValue(newData, value, currentRowData) {
        let calculatedAmount = this.modalForm.insurance_commission;
        if (value == 2) { calculatedAmount = this.modalForm.policy_net_amount; }
        if (value == 3) { calculatedAmount = this.modalForm.policy_total_amount; }
        newData.commission_type = value;
        newData.commission_amount = ((calculatedAmount / 100) * parseFloat(currentRowData.commission_rate)).toFixed(2);
    },
    setCommissionRateCellValue(newData, value, currentRowData) {
        let calculatedAmount = this.modalForm.insurance_commission;
        if (currentRowData.commission_type == 2) { calculatedAmount = this.modalForm.policy_net_amount; }
        if (currentRowData.commission_type == 3) { calculatedAmount = this.modalForm.policy_total_amount; }
        newData.commission_rate = value;
        newData.commission_amount = ((calculatedAmount / 100) * parseFloat(value)).toFixed(2);
    },
    setCommissionAmountCellValue(newData, value, currentRowData) {
        let calculatedAmount = this.modalForm.insurance_commission;
        if (currentRowData.commission_type == 2) { calculatedAmount = this.modalForm.policy_net_amount; }
        if (currentRowData.commission_type == 3) { calculatedAmount = this.modalForm.policy_total_amount; }
        newData.commission_amount = value;        
        newData.commission_rate = ((parseFloat(value) * 100) / calculatedAmount).toFixed(2);
    },
    setDecimalValueForEdit(e){
        e.data.commission_rate = e.data.commission_rate.replaceAll(',','');
        e.data.commission_amount = e.data.commission_amount.replaceAll(',','');
    },
    getTempVendors() {
        const parameters = {
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
        };  
        axios.post('/api/Insurance/GetTempVendors', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.transactionVendorList = response.data;
                if(this.transactionVendorFilteredList.length == 0) { this.isMainPolicy = true; }
                else { this.isMainPolicy = false; }
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onUpdateDueDate(e){
        const parameters = {
            session_id: this.$getUser().session_id,
            id: e.oldData.id,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
            collection_due: moment(e.newData.collection_due ? e.newData.collection_due : e.oldData.collection_due).format('YYYY-MM-DD'),
            payment_due: moment(e.newData.payment_due ? e.newData.payment_due : e.oldData.payment_due).format('YYYY-MM-DD'),
        };
        axios.post('/api/Insurance/UpdateCollectionPlan', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.collectionPlanList = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
  },
  created() { 
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
};
</script>
<style scoped> 
.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px !important;
    color: black;
} 
</style>