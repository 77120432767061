<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Supplier Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'SupplierList' }">{{ $t('Supplier') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Supplier Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/SupplierList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch"> 
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="d-flex flex-row row p-10">
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body">
                                    <div class="fv-row mb-7 row">
                                        <div class="col-lg-12" v-if="isViewAccountId">
                                            <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                {{ $t('Account ID') }}
                                            </label>
                                            <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                        </div> 
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> {{ $t('Name / Company Name') }}</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="name" v-custom v-model="form.name">
                                                <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(11,'CI')" type="button" 
                                                        class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                        id="basic-addon2" 
                                                        @click="onSearchCompanyByName()" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#kt_modal_search_company" 
                                                        data-kt-menu-trigger="click" 
                                                        data-kt-menu-placement="bottom-end"
                                                        :disabled="form.name == null || form.name == ''">
                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                </button>
                                            </div>
                                        </div>   
                                        <div class="col-lg-12">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                    <span>{{ $t('Member Type') }}</span>
                                                </label> 
                                                <Select2 v-model="form.company_account_type" :options="companyAccountTypeList" name="member_type" v-custom :settings="{ width: '100%'}"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12" v-if="form.company_account_type == 1">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'registration_number'">
                                                {{ $t('Registration Number') }}
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="registration_number" v-custom v-model="form.account_number">
                                                <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(11,'CI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                        :disabled="form.account_number == null || form.account_number == ''">
                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-lg-12" v-if="form.company_account_type == 2">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'personal_id'">
                                                {{ $t('Personal ID') }}
                                            </label>
                                            <input type="text" class="form-control" v-model="form.personal_id" name="personal_id" v-custom>
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">
                                                {{ $t('Contact Name') }}
                                            </label>
                                            <input type="text" class="form-control" name="contact_name" v-custom v-model="form.contact_name">
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'supplier_account_type'">
                                                {{ $t('Account Type') }}
                                            </label>
                                            <Select2 v-model="form.supplier_account_type" :options="supplierAccountTypeList" name="supplier_account_type" v-custom :disabled="!this.$isAdmin()" :settings="{ width: '100%'}"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body"> 
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <div class="fv-row fv-plugins-icon-container">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">
                                                        {{ $t('Email') }}
                                                    </label>
                                                    <input type="email" class="form-control" name="email" v-custom v-model="form.email" :class="{'border border-danger': isValidEmail == false}">
                                                    <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">
                                                        {{ $t('Mobile Phone') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                        <input type="text" class="form-control" name="mobile_phone" v-custom v-model="form.mobile_phone">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                        <span>{{ $t('Phone') }}</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                        <input type="text" class="form-control" name="phone" v-custom v-model="form.phone">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'supplier_type'">
                                                        <span>{{ $t('Supplier Type') }}</span>
                                                    </label> 
                                                    <Select2 v-model="form.customer_type_id" :options="customerTypeList" name="supplier_type" v-custom :settings="{ width: '100%'}"/>  
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                        <span>{{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.sales_channel_id" :options="salesChannelList" name="sales_channel" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                        <span>{{ $t('Payment Value') }}</span>
                                                        <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                    </label>
                                                    <input type="text" class="form-control" name="payment_value" v-custom v-model="form.payment_value"/>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">
                                        <div class="fv-row mb-6 row">
                                            <GetAddress @address-selected="onSelectAddress" :account-type="2" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(11,'AI')"/>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(11,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByPostCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(11,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByAddressCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address_by_code" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">
                                                        {{ $t('Address') }}
                                                    </label>
                                                    <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">
                                                        {{ $t('City') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}                                                    
                                                    </label>
                                                    <input type="text" class="form-control" name="city" v-model="form.district" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                        {{ $t('Country') }}
                                                    </label>
                                                    <Select2 v-model="form.country" :options="countryList" :settings="{width: '100%'}" @select="onChangeCountry($event)" name="country" v-custom/>
                                                </div>
                                            </div> 
                                        </div>
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row row">
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">{{ $t('VAT Account?') }}</label> 
                                                <br>
                                                <div class="form-check form-check-inline mt-5">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" v-custom id="inlineRadio1" value="false"
                                                        v-model="form.isVATNumberExist">
                                                    <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" v-custom id="inlineRadio2" value="true"
                                                    v-model="form.isVATNumberExist">
                                                    <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required' : this.form.isVATNumberExist == 'true'}" v-tag="'vat_number'">
                                                        {{ $t('VAT Number') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="vat_number" v-custom v-model="form.vat_number" :disabled="form.isVATNumberExist == 'false'">
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(11,'I')">
                            <div class="d-grid gap-2"> 
                                <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <strong v-if="!this.isProgressing"> {{ $t('Save Supplier') }} </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.name" :account-type="2"></SearchCompanyByName>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.postcode" :account-type="2"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn" :account-type="2"></FindAddressByAddressCode>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2"; 
import SearchCompanyByName from '../../Accounts/Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
export default {
  name: "SupplierCreate",
  components:{
    SearchCompanyByName,
    FindAddressByPostCode, FindAddressByAddressCode
  },
  data() {
    return {
        userData: this.$getUser(),
        companyAccountTypeList: [
            {id: 1, text: 'Company'},
            {id: 2, text: 'Person'},
        ],
        supplierAccountTypeList:[
            {id: 0, text: 'Supplier'},
            {id: 1, text: 'Insurance Producer'},
            {id: 2, text: 'Insurance Vendor'},
            {id: 3, text: 'Sales Vendor'},
        ],
        countryList: [],
        cityList: [],
        addressList: [],
        customerTypeList: [], 
        salesChannelList:[],
        isProgressing: false,
        form: {
            name: null,
            company_account_type: 2,
            company_type_id: null,
            account_type: null,
            account_id: null,
            qr_code: null,
            notes: null, 
            account_number: null,
            personal_id: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            address_id: '-1',
            country: -1,
            city: null, 
            addressSelection: null,
            vat_number: null,
            payment_value: null,
            isVATNumberExist: 'false',
            accounting_plan: null,
            customer_type_id: '-1',
            sales_channel_id: '-1',
            phone_country_code: null,
            land_phone_country_code: null,
            district: null,
            x_coordinate: 0,
            y_coordinate: 0,
            uprn: null,
            udprn: null,
            supplier_account_type: 0,
            tax_method: 1,
            sub_tax_1_method: 1,
            sub_tax_2_method: 1,
            sub_tax_3_method: 1,
            dll_template_id: '-1'
        },
        menuSelection: '/SupplierHelppage',
        isViewAccountId: false,
        iconView : false,
        phoneMaskList: [],
        dllTemplateList: []
    };
  },
  computed: {
		isValidEmail() {
			return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},    
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.addressList = [];
        this.addressList.unshift({ id: '-1', text: 'Select an option'}); 
        this.form.countrySelection = this.countryList[0];

        if(this.$getUser().company_country_code !== null && this.$getUser().company_country_code !== '' && this.$getUser().company_country_code !== undefined) {
            this.form.country = this.$getUser().company_country_code;
        }

        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["account_setting", "sales_channel","country"],
            account_type: 2, // yalnızca account settings listesi çekilecek ise gerekli
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            list_type: 14, // Customer Type: 14            
            get_access_status: 0,
            type_id: 2
        };  
    
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/DLLTemplates/GetDLLTemplate', parameters, {'Content-Type': 'application/json'});

        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
                this.salesChannelList = responses[0].data.sales_channel; 
                this.countryList = responses[0].data.country; 
                this.customerTypeList = responses[1].data; 
                this.phoneMaskList = responses[2].data; 
                this.phoneMaskList.forEach(element => {
                    if(element.code == this.$getUser().company_country_code) {
                        this.form.phone_country_code = element.id;
                        this.form.land_phone_country_code = element.id;
                    }
                });
                if (responses[0].data.account_setting.is_default == false) {
                    this.isViewAccountId = true; 
                }

                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.countryList.unshift({ id: '-1', text: 'Select an option'});
                this.salesChannelList.forEach((value) => {
                    if(value.is_default){
                        this.form.sales_channel_id = value.id;
                    }
                });
                this.customerTypeList.forEach((value) => {
                    if(value.is_default) {
                        this.form.customer_type_id = value.id;
                    }
                });

                this.dllTemplateList = responses[3].data;
                this.dllTemplateList.unshift({ id: '-1', text: 'Select an option'}); 

                this.$Progress.finish();   
            })
          )
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/SupplierHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = this.form;    
      const that = this;
      params.session_id = this.$getUser().session_id; 
      params.account_type = 2; // Supplier: 2
      if(params.country =='-1'){ params.country = null;}
      if(params.sales_channel_id =='-1'){ params.sales_channel_id = null;}
      if(params.customer_type_id == '-1'){ params.customer_type_id = null }
      if(params.company_account_type == 2) {params.account_number = params.personal_id;}
      params.dll_template_id = params.dll_template_id == -1 ? null : params.dll_template_id;
      axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'SupplierUpdate' });
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeCountry(event) {
        this.form.phone_country_code = event.phone_country_code;
        this.form.land_phone_country_code = event.phone_country_code;
    },
    onSearchCompanyByNo(selectedCompanyNumber) {
        if(selectedCompanyNumber) {
            this.form.account_number = selectedCompanyNumber;
        }
        const parameters = {
            CompanyNumber: this.form.account_number,
            SessionId: this.$getUser().session_id,
            SourceType: 2
        }
        const that = this;
        axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.form.name = response.data.name;
                this.form.account_number = response.data.number;
                this.form.contact_name = response.data.contactName;
                this.form.postcode = response.data.postalCode;
                this.form.address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                //this.form.country = response.data.country; Çıktı England şeklinde?
                this.form.city = response.data.locality;
                this.companySearchResult = [];

                if(this.form.account_number !== null && this.form.account_number !== '' && this.form.account_number !== undefined) {
                    this.form.company_account_type = 1;
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            that.$swal("Error", "No records found..!", 'error');
        });
    },
    onSearchCompanyByName(){
        this.$refs.SearchCompanyByName.getData();
    },
    onFindAddressByPostCode(){  
        this.$refs.FindAddressByPostCode.getData();
    },
    onFindAddressByAddressCode(){  
        this.$refs.FindAddressByAddressCode.getData();
    },
    onSelectAddressSearchResult(item){
        this.form.address = item.ADDRESS;
        this.form.city = item.POST_TOWN;
        this.form.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.form.x_coordinate = item.X_COORDINATE;
        this.form.y_coordinate = item.Y_COORDINATE;
        this.form.uprn = item.UPRN;
        this.form.udprn = item.UDPRN;
        this.form.postcode = item.POSTCODE;
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.form.address = item.fullAddress;
        this.form.city = item.town_or_city;
        this.form.district = item.district;
        this.form.postcode = item.postcode;
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>