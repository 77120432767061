<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Customer Account') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CustomerList' }">{{ $t('Customer') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Customer Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/CustomerList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField> 
            <div class="card card-xxl-stretch">
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-10">
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">                              
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12" v-if="isViewAccountId">
                                                <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                    {{ $t('Account ID') }}
                                                </label>
                                                <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> 
                                                    {{ $t('Name / Company Name') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="name" v-custom v-model="form.name">
                                                    <button type="button" v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')"
                                                            class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                            id="basic-addon2" 
                                                            @click="onSearchCompanyByName()" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#kt_modal_search_company" 
                                                            data-kt-menu-trigger="click" 
                                                            data-kt-menu-placement="bottom-end"
                                                            :disabled="form.name == null || form.name == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                        <span>{{ $t('Member Type') }}</span>
                                                    </label> 
                                                    <Select2 v-model="form.company_account_type" :options="companyAccountTypeList" name="member_type" v-custom :settings="{ width: '100%'}"/>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" v-if="form.company_account_type == 1">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'registration_number'">
                                                    {{ $t('Registration Number') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" v-model="form.account_number" name="registration_number" v-custom>
                                                    <button v-if="userData.is_company_inquiry_active == 1 && this.$isCredentialActive(10,'CI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                            :disabled="form.account_number == null || form.account_number == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" v-if="form.company_account_type == 2">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'personal_id'">
                                                    {{ $t('Personal ID') }}
                                                </label>
                                                <input type="text" class="form-control" v-model="form.personal_id" name="personal_id" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">{{ $t('Contact Name') }}</label>
                                                <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <div class="fv-row fv-plugins-icon-container">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">{{ $t('Email') }}</label>
                                                    <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}" v-custom>
                                                    <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">{{ $t('Mobile Phone') }}</label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                        <input type="text" class="form-control" v-model="form.mobile_phone" name="mobile_phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'"><span>{{ $t('Phone') }}</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}" />
                                                        <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'customer_type'"><span>{{ $t('Customer Type') }}</span></label> 
                                                    <Select2 v-model="form.customer_type_id" :options="customerTypeList" v-custom name="customer_type" :settings="{ width: '100%'}"/>  
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'"><span>{{ $t('Sales Channel') }}</span></label>
                                                    <Select2 v-model="form.sales_channel_id" :options="salesChannelList" v-custom name="sales_channel" :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                        <span>{{ $t('Payment Value') }}</span> 
                                                        <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                    </label>
                                                    <input type="text" class="form-control" v-model="form.payment_value" v-custom name="payment_value">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                        <div class="fv-row mb-6 row">
                                            <GetAddress @address-selected="onSelectAddress" :account-type="1" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(10,'AI')"/>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByPostCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByAddressCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address_by_code" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Address') }}</label>
                                                    <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">{{ $t('City') }}</label>
                                                    <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}</label>
                                                    <input type="text" class="form-control" name="district" v-model="form.district" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">{{ $t('Country') }}</label>
                                                    <Select2 v-model="form.country" :options="countryList" :settings="{width: '100%'}" @select="onChangeCountry($event)" v-custom name="country"/>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <div class="fv-row row">
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">{{ $t('VAT Account?') }}</label>
                                                <br>
                                                <div class="form-check form-check-inline mt-5">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="false" v-model="form.isVATNumberExist" v-custom>
                                                    <label class="form-check-label" for="inlineRadio1">{{ $t('No') }}</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="true" v-model="form.isVATNumberExist" v-custom>
                                                    <label class="form-check-label" for="inlineRadio2">{{ $t('Yes') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required' : this.form.isVATNumberExist == 'true'}" v-tag="'vat_number'">
                                                        {{ $t('VAT Number') }}                                                       
                                                    </label>
                                                    <input type="text" class="form-control" name="vat_number" v-model="form.vat_number" :disabled="form.isVATNumberExist == 'false'" v-custom>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300" id="kt_accounting_1" role="tabpanel">
                                    <div class="card-header">
                                        <div class="card-title">
                                            {{ $t('Add Default Vendor') }}
                                        </div>
                                    </div>
                                    <div class="card-body"> 
                                        <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                            <div class="fv-row mb-6 row">   
                                                <div class="col-lg-4">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                            {{ $t('Vendor') }}
                                                        </label> 
                                                        <Select2 v-model="vendorForm.vendor_id" :options="vendorSelectionList" name="vendor" v-custom :settings="{width: '100%'}"/> 
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                            {{ $t('Main Branch') }}
                                                        </label> 
                                                        <Select2 v-model="vendorForm.main_branch_id" :options="mainBranchList" :settings="{width: '100%'}" @select="getPolicyParentList(vendorForm.main_branch_id)" v-custom name="main_branch_id"/>  
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                            {{ $t('Policy Branch') }}
                                                        </label> 
                                                        <Select2 v-model="vendorForm.policy_branch_id" :options="policyBranchList" :settings="{width: '100%'}" v-custom name="policy_branch_id"/>  
                                                    </div>
                                                </div>
                                            </div> 
                                        </form>
                                        <div class="separator my-10"></div>
                                        <div class="d-flex flex-stack flex-wrap my-5">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addVendorItem()">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Add') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()" 
                                                    :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()" :disabled="this.vendorFilteredList.length == 0">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove All (!)') }}
                                                </button>
                                            </div>
                                        </div>
                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive">
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="vendorList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    @row-updating="onRowUpdating"
                                                    @edit-canceled="getTempVendor"> 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="false"/>
                                                    <DxPaging :page-size="1000"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"
                                                    />
                                                    <DxEditing :allow-updating="this.vendorFilteredList.length > 0 ? true : false" mode="row" />
                                                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allowEditing="false"/>
                                                    <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template" :allowEditing="false"/>
                                                    <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template" :allowEditing="false"/> 
                                                    <DxColumn data-field="main_branch_name" :caption="$t('Main Branch')" cell-template="main_branch_name-cell-template" :set-cell-value="setMainBranch">
                                                        <DxLookup :data-source="mainBranchList" value-expr="id" display-expr="text" v-model="vendorForm.main_branch_id"/>
                                                    </DxColumn> 
                                                    <DxColumn data-field="policy_branch_name" :caption="$t('Policy Branch')" cell-template="policy_branch_name-cell-template" :set-cell-value="setPolicyBranch">
                                                        <DxLookup :data-source="policyBranchList" value-expr="id" display-expr="text" v-model="vendorForm.policy_branch_id"/>
                                                    </DxColumn> 
                                                    <DxColumn data-field="vendor_type_name" :caption="$t('Type')" cell-template="vendor_type_name-cell-template" :allowEditing="false"/> 

                                                    <template #is-selected-cell-template="{ data }"> 
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #account_id-cell-template="{ data }"> 
                                                        <span>
                                                            {{ data.data.account_id }}
                                                        </span>
                                                    </template>
                                                    <template #vendor_name-cell-template="{ data }"> 
                                                        <span>
                                                            {{ data.data.vendor_name }}
                                                        </span>
                                                    </template>
                                                    <template #main_branch_name-cell-template="{ data }"> 
                                                        <span>
                                                            {{ data.data.main_branch_name }}
                                                        </span>
                                                    </template>
                                                    <template #policy_branch_name-cell-template="{ data }"> 
                                                        <span>
                                                            {{ data.data.policy_branch_name }}
                                                        </span>
                                                    </template>
                                                        <template #vendor_type_name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.vendor_type_name }}
                                                            </span>
                                                        </template>

                                                    <DxSummary>
                                                        <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                    </DxSummary>
                                                </DxDataGrid>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(10,'I')">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> {{ $t('Save Customer') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.name" :account-type="1"></SearchCompanyByName>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.postcode" :account-type="1"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn" :account-type="1"></FindAddressByAddressCode>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import SearchCompanyByName from '../../Accounts/Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxScrolling, DxEditing, DxLookup } from 'devextreme-vue/data-grid';

export default {
  name: "CustomerCreate",
  components: {
    SearchCompanyByName, FindAddressByPostCode, DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, FindAddressByAddressCode, DxScrolling, DxEditing, DxLookup
  },
  data() {
        return {
            userData: this.$getUser(),
            companyAccountTypeList: [
                {id: 1, text: 'Company'},
                {id: 2, text: 'Person'},
            ],
            customerTypeList: [], 
            countryList: [],
            cityList: [],
            addressList: [],
            salesChannelList:[],
            isProgressing: false,
            form: {
                name: null,
                company_account_type: 2,
                company_type_id: null,
                account_type: null,
                account_id: null,
                qr_code: null,
                notes: null, 
                account_number: null,
                personal_id: null,
                contact_name: null,
                email: null,
                mobile_phone: null,
                phone: null,
                postcode: null,
                address: null,
                address_id: '-1',
                country: '-1',
                city: null, 
                vat_number: null,
                payment_value: null,
                isVATNumberExist: 'false',
                accounting_plan: null,
                customer_type_id: '-1',
                sales_channel_id: '-1',
                phone_country_code: null,
                land_phone_country_code: null,
                district: null,
                x_coordinate: 0,
                y_coordinate: 0,
                uprn: null,
                udprn: null,
            },
            vendorForm: {
                id: null,
                vendor_id: '-1',
                main_branch_id: '-1',
                policy_branch_id: '-1'
            },
            menuSelection: '/CustomerHelppage',
            iconView : false,
            phoneMaskList: [],
            isViewAccountId: false,
            vendorSelectionList: [],
            vendorList: [{                
                account_id: "MAIN",
                id: "",
                main_branch_id: null,
                main_branch_name: "ALL",
                policy_branch_id: null,
                policy_branch_name: "ALL",
                tmp_session_id: null,
                vendor_branch_id: null,
                vendor_id: null,
                vendor_name: "MAIN"
            }],
            policyBranchList: [{ id: '-1', text: 'ALL'}],
            mainBranchList: [{ id: '-1', text: 'ALL'}],
            isItemSaving: false,
            selectedVendorId: null
        };
    },
    computed: {
		isValidEmail() {
			return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},
        isButtonDisabled() {
            return this.vendorList.find(item => item.isSelected == true && item.id !== '' );
        },
        vendorFilteredList(){
            return this.vendorList.filter(p => { return p.id !== ''; });
        }
	},
    methods: {
        getData() {
            this.$Progress.start();
            this.$setSessionStorage('tmp_session_id', null);
            this.addressList = [];
            this.addressList.unshift({ id: '-1', text: 'Select an option'});

            if(this.$getUser().company_country_code !== null && this.$getUser().company_country_code !== '' && this.$getUser().company_country_code !== undefined) {
                this.form.country = this.$getUser().company_country_code;
            }

            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["account_setting", "sales_channel","country"],
                account_type: 1, // yalnızca account settings listesi çekilecek ise gerekli
                country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
                list_type: 14, // Customer Type: 14            
                get_access_status: 0,
                type_id: 1
            };  
            const parametersTwo = {
                session_id: this.$getUser().session_id,
                supplier_account_type: -1, // ALL ( 2:Insurance Vendors and 3: Sales Vendors)
                list_type: 1 /* Main Branch */
            };        
            const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'});
            const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'});
            const requestFour=  axios.post('/api/GetCompanyAccounts/GetPolicyAccounts', parametersTwo, {'Content-Type': 'application/json'});
            const requestFive =  axios.post('/api/Helper/GetPolicyLists', parametersTwo, {'Content-Type': 'application/json'});
            
            axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive]).then(axios.spread((...responses) => {
                    this.salesChannelList = responses[0].data.sales_channel; 
                    this.customerTypeList = responses[1].data; 
                    this.countryList = responses[0].data.country; 
                    this.phoneMaskList = responses[2].data; 
                    this.vendorSelectionList = responses[3].data;
                    this.mainBranchList = responses[4].data;
                    if (responses[0].data.account_setting.is_default == false) { this.isViewAccountId = true; }
                    this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                    this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.countryList.unshift({ id: '-1', text: 'Select an option'});
                    this.salesChannelList.forEach((value) => { if(value.is_default){this.form.sales_channel_id = value.id;}} );
                    this.customerTypeList.forEach((value) => { if(value.is_default) {this.form.customer_type_id = value.id;}} );
                    this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
                    this.vendorSelectionList.unshift({ id: '-1', text: 'Select an option'});
                    
                    this.phoneMaskList.forEach(element => {
                        if(element.code == this.$getUser().company_country_code) {
                            this.form.phone_country_code = element.id;
                            this.form.land_phone_country_code = element.id;
                        }
                    });
                    this.$Progress.finish();   
                })
            )
            .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish();   
            });  
        },
        saveData() {
            this.$checkSelect2Validation('poliform');
            if(!document.forms['poliform'].reportValidity()){
                return;
            }
            
            try{
                var data = sessionStorage.getItem('frm_val'); 
                var vendorTableValidation = JSON.parse(data).find((item) => item.field == "vendor_table");
                if(vendorTableValidation !== null && vendorTableValidation !== undefined && vendorTableValidation !== ''){
                    if(vendorTableValidation.is_required){
                        if(this.vendorList.length == 0){
                            this.$swal("Failed", "Vendor Table entry is required..!", 'error');
                            return;
                        }
                    } 
                }
            }
            catch(Err){
                console.error(Err);
            }

            this.isProgressing = true;
            const params = this.form;    
            params.session_id = this.$getUser().session_id;
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
            params.account_type = 1; // Customer: 1 
            const that = this;
            if(params.country == '-1'){ params.country = null }
            if(params.sales_channel_id == '-1'){ params.sales_channel_id = null }
            if(params.customer_type_id == '-1'){ params.customer_type_id = null }
            if(params.company_account_type == 2) {params.account_number = params.personal_id;}
            axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data != null && response.data.length == 36){  
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.$setSessionStorage('record_id', response.data);
                    this.$router.push({ name: 'CustomerUpdate' });
                }
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            }); 
        },
        async helpPageViewIcon()
        {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/CustomerHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
        onChangeCountry(event) {
            this.form.phone_country_code = event.phone_country_code;
            this.form.land_phone_country_code = event.phone_country_code;
        },
        onSearchCompanyByNo(selectedCompanyNumber){
            if(selectedCompanyNumber) {
                this.form.account_number = selectedCompanyNumber;
            }
            const parameters = {
                CompanyNumber: this.form.account_number,
                SessionId: this.$getUser().session_id,
                SourceType: 1
            }
            const that = this;
            axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.form.name = response.data.name;
                    this.form.account_number = response.data.number;
                    this.form.contact_name = response.data.contactName;
                    this.form.postcode = response.data.postalCode;
                    this.form.address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                    //this.form.country = response.data.country; Çıktı England şeklinde?
                    this.form.city = response.data.locality;
                    if(this.form.account_number !== null && this.form.account_number !== '' && this.form.account_number !== undefined) {
                        this.form.company_account_type = 1;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                that.$swal("Error", "No records found..!", 'error');
            });
        },
        onSearchCompanyByName(){
            this.$refs.SearchCompanyByName.getData();
        },
        onFindAddressByPostCode(){  
            this.$refs.FindAddressByPostCode.getData();
        },
        onFindAddressByAddressCode(){  
            this.$refs.FindAddressByAddressCode.getData();
        },
        onSelectAddressSearchResult(item){
            this.form.address = item.ADDRESS;
            this.form.city = item.POST_TOWN;
            this.form.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
            this.form.x_coordinate = item.X_COORDINATE;
            this.form.y_coordinate = item.Y_COORDINATE;
            this.form.uprn = item.UPRN;
            this.form.udprn = item.UDPRN;
            this.form.postcode = item.POSTCODE;
        },
        getPolicyParentList(parentId, isChanged) {
            if(!isChanged) this.vendorForm.policy_branch_id = '-1';
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 2, // Policy Branch: 2 
                parent_id: parentId
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.policyBranchList = response.data;
                this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        addVendorItem(){
            if (this.vendorForm.vendor_id == '-1') {
                this.$swal("Failed", "Vendor must be selected..!", 'warning' );
                return;
            }
            this.isItemSaving = true;
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                accountList: [this.vendorForm.vendor_id],
                main_branch_id: this.vendorForm.main_branch_id == '-1' ? null : this.vendorForm.main_branch_id,
                policy_branch_id: this.vendorForm.policy_branch_id == '-1' ? null : this.vendorForm.policy_branch_id
            };
            const that = this;
            axios.post('/api/GetCompanyAccounts/AddVendorItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.vendorList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.vendorForm = {
                        id: null,
                        vendor_id: '-1',
                        main_branch_id: '-1',
                        policy_branch_id: '-1'
                    };
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        deleteVendorItem(){
            this.$swal.fire({
                title: "Are you sure you want to remove this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                        vendor_id: this.selectedVendorId,
                        is_delete_all: 0
                    }; 
                    axios.post('/api/GetCompanyAccounts/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        this.vendorList = response.data;
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        deleteAllVendors(){ 
            this.$swal.fire({
                title: "Are you sure you want to remove all items?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                        is_delete_all: 1,
                        vendor_id: null
                    }; 
                    axios.post('/api/GetCompanyAccounts/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        this.vendorList = response.data;
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },   
        onSelectItem(item) { 
            this.selectedVendorId = item.id;
            this.vendorList.forEach((value) => {
                if (item.id !== value.id) { 
                    value.isSelected = false;
                }
            });
        },
        setMainBranch(newData, value){
            newData.main_branch_name = value;
            newData.main_branch_id = value;
            newData.policy_branch_name = '-1';
            newData.policy_branch_id = '-1';
            this.getPolicyParentList(value, true);
        },
        setPolicyBranch(newData, value){
            newData.policy_branch_name = value;
            newData.policy_branch_id = value;
        },
        getTempVendor(){
            this.policyBranchList = null;
        },
        onRowUpdating(e){
            this.isItemSaving = true;
            const parameters = {
                id: e.oldData.id,
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                main_branch_id: e.newData.main_branch_id ? e.newData.main_branch_id : e.oldData.main_branch_id,
                policy_branch_id: e.newData.policy_branch_id ? e.newData.policy_branch_id  : e.oldData.policy_branch_id
            };
            const that = this;
            if(parameters.main_branch_id == '-1' || parameters.main_branch_id == '') { parameters.main_branch_id = null; parameters.policy_branch_id = null ;}
            if(parameters.policy_branch_id == '-1' || parameters.policy_branch_id == '') parameters.policy_branch_id = null ;
            axios.post('/api/GetCompanyAccounts/EditVendorItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.vendorList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.form.address = item.fullAddress;
            this.form.city = item.town_or_city;
            this.form.district = item.district;
            this.form.postcode = item.postcode;
        }
    },
    created() {
        this.$removeSessionStorage('tmp_session_id');
        this.helpPageViewIcon();
        this.getData();
    },
};
</script>