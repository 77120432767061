<template>
   <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"> 
        <div class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row w-100 p-5 mb-10"> 
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                </svg>
                </span>    
            </span>  
            <div class="d-flex flex-column pe-0 pe-sm-10"> 
                <div class="fs-6 text-gray-800 fw-bolder">No results yet.</div>
                <div class="fw-bold text-gray-400">Please use "List" button to get the latest data.</div>
            </div>  
        </div>   
    </div>
</template>
<script>
export default {
  name: "NoTableResults" 
};
</script>