<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Expense Account') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ExpenseList' }">{{ $t('Expense') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Expense Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/ExpenseList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch"> 
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-10">
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-8 row">                                           
                                                <div class="col-lg-12" v-if="isViewAccountId">
                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                        {{ $t('Account ID') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'">{{ $t('Name') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="name" v-model="form.name" v-custom>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_group'">{{ $t('Expense Group') }}
                                                    </label>
                                                    <Select2 v-model="form.expense_group_id" :options="expenseGroupList" @select="onChangeExpenseGroup()" name="expense_group" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-6 row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_type'">
                                                        <span>{{ $t('Expense Type') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.expense_type_id" :options="expenseTypeList" name="expense_type" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_center'">
                                                            <span>{{ $t('Expense Center') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.expense_center_id" :options="expenseCenterList" name="expense_center" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="fv-row">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_status'">
                                                            <span>{{ $t('VAT Status') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.vat_status_id" :options="vatStatusList" @select="onChangeVatStatus()" name="vat_status" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_rate'">
                                                        <span>{{ $t('VAT Rate') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.vat_rate" :options="vatRateList" :disabled="this.form.vat_status_id == 1" name="vat_rate" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-1" v-tag="'branch'">
                                                        <span>{{ $t('Branch') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                    <span>{{ $t('Notes') }}</span>
                                                </label>
                                                <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                            </div>
                                            <div class="fv-row mb-7">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'show_on_invoice'">
                                                        <span>{{ $t('Show on Invoice?') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.is_show_invoice" :options="showInvoiceList" name="show_on_invoice" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>     
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(15,'I')">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> {{ $t('Save Expense') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
 
export default {
  name: "ExpenseCreate",
  data() {
    return {
      expenseGroupList: [],
      expenseTypeList: [],
      branchList: [],
      expenseCenterList: [],
      vatStatusList: [
        { id: 1, text: 'Select an option'},
        { id: 2, text: 'VAT (+)'},
        { id: 3, text: 'VAT (in)'}
      ],
      vatRateList: [{ id: 0, text: 'Select an option'}],
      showInvoiceList: [ {id: '1', text: 'Yes'}, {id: '0', text: 'No'}],
      isProgressing: false,
      form: {
        name: null,
        company_id: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null,
        expense_type_id: '-1',
        expense_group_id: '-1',
        expense_expense_type_id: null,
        expense_expense_group_id: null,
        branch_id: '-1',
        expense_center_id: '-1',
        accounting_plan: null,
        vat_rate: 0.00,
        vat_status_id: 1,
        is_show_invoice: 0
      },
      isViewAccountId: false,
      menuSelection: '/ExpenseHelppage',        
      iconView : false
    };
  },
  methods: {
    getData() {

        this.$Progress.start();
        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [{ list_type: 4, list_name: "expense_group"}, { list_type: 15, list_name: "vat_rate"}],
            list_type: 5, // Expense Type: 5 
            parent_id: this.form.expense_group_id,
            account_type: 6, // yalnızca account settings için gerekli
            list_name: ["branch", "account_setting", "expense_center"]
        };  
        
        const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetParentList', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.expenseGroupList = responses[0].data.expense_group;
                this.vatRateList = responses[0].data.vat_rate;
                this.expenseTypeList = responses[1].data;
                this.branchList = responses[2].data.branch;
                this.expenseCenterList = responses[2].data.expense_center;

                this.expenseGroupList.unshift({ id: '-1', text: 'Select an option'}); 
                this.expenseTypeList.unshift({ id: '-1', text: 'Select an option'}); 

                this.expenseGroupList.forEach((value) => {
                    if(value.is_default) {
                        this.form.expense_group_id = value.id;
                    }
                });
                this.vatRateList.forEach((value) => {
                    value.id = value.val;
                    if(value.is_default) {
                        this.form.vat_rate = value.id;
                    }
                });
                this.vatRateList.unshift({ id: 0, text: 'Select an option'});
                this.expenseTypeList.forEach((value) => {
                    if(value.is_default) {
                       
                        this.form.expense_type_id = value.id;
                    }
                });
                this.branchList.forEach((value) => {
                    if(value.is_default){
                        this.form.branch_id = value.id;
                    }
                });   
                if(this.modalType !== 'editItem'){ 
                    this.expenseCenterList.forEach((value) => {
                        if(value.is_default){
                            this.form.expense_center_id = value.id;
                        }
                    });
                }                 
                if (responses[2].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.onChangeExpenseGroup();
                this.$Progress.finish();
            })
          )
          .catch(errors => { 
            console.error(errors); 
        });  
    },
    onChangeExpenseGroup() {
        this.$Progress.start();
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 5, // Expense Type: 5 
            parent_id: this.form.expense_group_id
        };
        axios.post("/api/Helper/GetParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.expenseTypeList = response.data;
            this.expenseTypeList.unshift({ id: '-1', text: 'Select an option'}); 
            this.form.expense_type_id = this.expenseTypeList[0].id;
            this.expenseTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.expense_type_id = value.id;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ExpenseHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = {
        id:this.form.id,
        name:this.form.name,
        company_id:this.form.company_id,
        account_type:this.form.account_type,
        account_id:this.form.account_id,
        qr_code:this.form.qr_code,
        notes:this.form.notes,
        expense_type_id: this.form.expense_type_id == '-1' ? null : this.form.expense_type_id,
        expense_type_name:this.form.expense_type_name,
        expense_group_id:this.form.expense_group_id == '-1' ? null : this.form.expense_group_id,
        expense_group_name:this.form.expense_group_name,
        branch_id:this.form.branch_id == '-1' ? null : this.form.branch_id,
        expense_center_id:this.form.expense_center_id == '-1' ? null : this.form.expense_center_id,
        accounting_plan:this.form.accounting_plan,
        vat_rate:this.form.vat_rate,
        vat_status_id:this.form.vat_status_id,
        is_show_invoice:this.form.is_show_invoice,
        criteria:this.form.criteria,
      };    
      const that = this;
      params.session_id = this.$getUser().session_id;
      params.account_type = 6; // Expense: 6
      axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'ExpenseUpdate' });
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeVatStatus() {
        if(this.form.vat_status_id == 1) {this.form.vat_rate = '0.00';}
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>