<template>
    <div>
        <div class="modal-dialog modal-xl">
            <div class="modal-content"  >
                <div class="modal-header py-3 min-h-50px bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="this.selectedTransaction.is_record_exists == true">
                      Update Customer Account
                    </h2>
                    <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="this.selectedTransaction.is_record_exists == false">
                      Create Customer Account
                    </h2>                      
                    <div class="btn btn-icon btn-sm"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeEditModal" @click="this.$parent.isViewCustomerEditModal = false">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isAccountModalLoading == true}"> 
                    <div class="card">
                        <div class="card-body pt-2">                                            
                            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 pt-3">
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#insurer_tab_1" :class="{'active': insurerActiveTab == 'insurerDetail'}" @click="insurerActiveTab = 'insurerDetail'" target="_self" ref="insurerDetailTab">
                                        <h4> {{ $t('Insurer Detail') }} </h4>
                                    </a>
                                </li> 
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#insurer_tab_5" :class="{'active': insurerActiveTab == 'accountGroup'}" @click="insurerActiveTab = 'accountGroup'" target="_self">
                                        <h4> {{ $t('Account Group') }} </h4>
                                    </a>
                                </li> 
                                <li class="nav-item" v-if="this.selectedTransaction.is_record_exists != true">
                                    <a class="nav-link" data-bs-toggle="tab" href="#insurer_tab_2" :class="{'active': insurerActiveTab == 'insurerSearch'}" @click="insurerActiveTab = 'insurerSearch'" target="_self">
                                        <h4> {{ $t('Search') }} {{ $t('Insurer') }}/{{ $t('Customer') }}</h4>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#insurer_tab_3" target="_self" :class="{'active': insurerActiveTab == 'vendorSettings'}" @click="insurerActiveTab = 'vendorSettings', getVendorPolicyLists()">
                                        <h4> {{ $t('Vendor Settings') }}</h4>
                                    </a>
                                </li>
                                <li class="nav-item" v-if="this.selectedTransaction.is_record_exists == true">
                                    <a class="nav-link" data-bs-toggle="tab" href="#insurer_tab_4" :class="{'active': insurerActiveTab == 'policyData'}" @click="insurerActiveTab = 'policyData'" target="_self">
                                        <h4> {{ $t('Policy Data') }}</h4>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="row tab-pane fade active show" v-if="insurerActiveTab == 'insurerDetail'" id="insurer_tab_1" role="tabpanel">
                                    <form id="poliformModal" @submit.prevent=""  class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                                <div class="card-body pb-0">
                                                    <div class="fv-row mb-6 row">    
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'member_type'">
                                                                    {{ $t('Member') }} <br> {{ $t('Type') }}
                                                                </label> 
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="editForm.customer_member_type" :options="companyAccountTypeList" name="member_type" v-custom 
                                                                    :disabled="this.selectedTransaction.is_record_exists == true"
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="editForm.customer_member_type == 2">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'personal_id'">
                                                                    {{ $t('Personal') }} <br> {{ $t('ID') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">
                                                                    <input type="text" class="form-control" name="personal_id" v-custom v-model="editForm.customer_account_number"
                                                                        :disabled="this.selectedTransaction.is_record_exists == true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="editForm.customer_member_type == 1">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'registration_number'">
                                                                    {{ $t('Company') }} <br> {{ $t('ID') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">
                                                                    <input type="text" class="form-control" name="registration_number" v-custom v-model="editForm.customer_account_number"
                                                                        :disabled="this.selectedTransaction.is_record_exists == true" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'customer_account_name'"  v-if="editForm.customer_member_type == 2">
                                                                    {{ $t('Name') }} 
                                                                </label>
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" v-tag="'customer_account_name'"  v-if="editForm.customer_member_type == 1">
                                                                    {{ $t('Company Name') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">
                                                                    <input type="text" class="form-control" name="name" v-custom v-model="editForm.customer_account_name">
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_email == true }" v-tag="'email'">
                                                                    {{ $t('Email') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">
                                                                    <input type="email" class="form-control" name="email" v-model="editForm.customer_email" :class="{'border border-danger': isValidEmail == false}"  v-custom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_mobile_phone == true }" v-tag="'mobile_phone'">
                                                                    {{ $t('Mobile') }} <br> {{ $t('Phone') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">                                                                            
                                                                    <div class="input-group">
                                                                        <Select2 v-model="editForm.phone_country_code" :options="phoneMaskList" :settings="{ width: '120px', dropdownParent: '#kt_modal_edit_item .modal-content' }"/>
                                                                        <input type="text" class="form-control" name="mobile_phone" v-model="editForm.customer_mobile_phone"  v-custom>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_landphone == true }" v-tag="'phone'">
                                                                    {{ $t('Phone') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container">                                                                            
                                                                    <div class="input-group">
                                                                        <Select2 v-model="editForm.land_phone_country_code" :options="phoneMaskList" :settings="{ width: '120px', dropdownParent: '#kt_modal_edit_item .modal-content' }"/>
                                                                        <input type="text" class="form-control" name="phone" v-model="editForm.customer_landphone" v-custom>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-3 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_type_id == true }" v-tag="'customer_type'">
                                                                    {{ $t('Customer Type') }}
                                                                </label>
                                                                <div class="col-lg-9 fv-row fv-plugins-icon-container"> 
                                                                    <Select2 v-model="editForm.customer_type_id" :options="customerTypeList" v-custom name="customer_type" :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }"/>  
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="input-group">
                                                                <input class="form-check-input mt-3" type="checkbox" v-model="editForm.is_update_locked" 
                                                                :disabled="!this.$isCredentialActive(10,'CUL')"/>
                                                                <!-- :disabled="this.originalCustomerAccount.is_update_locked == true" -->
                                                                <label class="fs-6 fw-bold form-label mt-4 ms-4" v-tag="'is_update_locked'">
                                                                    {{ $t('Lock Update') }}
                                                                </label>
                                                                <!-- ADMIN ACCESS -->
                                                                <!-- <div class="input-group-append" v-if="this.$isAdmin() && this.selectedTransaction.is_record_exists == true">
                                                                    <span class="input-group-text p-1 ms-3 mt-1">
                                                                        <button type="button" class="btn btn-xs btn-warning text-center" 
                                                                                @click="onAdminActivateLockUpdate()"
                                                                                :disabled="!this.userData.permission_type == 1"> 
                                                                            <i class="fa fa-lock text-gray-800 ps-1" v-if="this.editForm.is_admin_update_locked == true"></i> 
                                                                            <i class="fa fa-unlock text-gray-800 ps-1" v-if="this.editForm.is_admin_update_locked !== true"></i> 
                                                                        </button>
                                                                    </span>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                                <div class="card-body pb-0">                       
                                                    <div class="fv-row mb-6 row">
                                                        <GetAddress @address-selected="onSelectAddress" :account-type="1" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(10,'AI')"/>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_postcode == true }" v-tag="'post_code'">
                                                                    {{ $t('Post Code') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control" name="post_code" v-model="editForm.customer_postcode" v-custom>
                                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info"
                                                                            @click="this.onFindAddressByPostCode(editForm.customer_postcode)"
                                                                            :disabled="this.editForm.customer_postcode == null || this.editForm.customer_postcode == ''">                                                                        
                                                                            <span v-if="!isSearchingByPostCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                                            <span v-if="isSearchingByPostCode">{{ $t('Searching...') }}</span> 
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'address_code'">
                                                                {{ $t('Address Code') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control" name="address_code" v-model="editForm.customer_uprn" v-custom>
                                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(10,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info"
                                                                            @click="this.onFindAddressByAddressCode(editForm.customer_uprn)"
                                                                            :disabled="this.editForm.customer_uprn == null || this.editForm.customer_uprn == ''">                                                                        
                                                                            <span v-if="!isSearchingByAddressCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                                            <span v-if="isSearchingByAddressCode">{{ $t('Searching...') }}</span> 
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-2 col-form-label form-label fw-bold p-2" v-tag="'address_id'">
                                                                {{ $t('Select Address') }}
                                                                </label>
                                                                <div class="col-lg-10 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="editForm.address_id" :options="addressList" name="address_id" v-custom @select="onSelectCustomerAddressSearchResult($event)"
                                                                        :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }" :disabled="addressList.length == 0"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_city == true }" v-tag="'city'">
                                                                    {{ $t('City') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <input type="text" class="form-control" name="city" v-model="editForm.customer_city" v-custom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_district == true }" v-tag="'district'">
                                                                    {{ $t('District') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <input type="text" class="form-control" name="district" v-model="editForm.customer_district" v-custom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'country'">
                                                                    {{ $t('Country') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="editForm.customer_country" :options="countryList" name="country" v-custom
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.updatePolicyTempForm.customer_address == true }" v-tag="'address'">
                                                                    {{ $t('Address') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <textarea rows="3" class="form-control" name="address" v-model="editForm.customer_address" v-custom></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                                <div class="card-body pb-0">                          
                                                    <div class="fv-row row">
                                                        <div class="col-lg-6">
                                                            <div class="row mb-3">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'sales_channel'">
                                                                    {{ $t('Sales') }} <br> {{ $t('Channel') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="editForm.policy_sales_channel_id" :options="salesChannelList" name="sales_channel"
                                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }" v-custom/>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div class="col-lg-6">
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 col-form-label form-label fw-bold p-2 mt-2" v-tag="'branch_id'">
                                                                    Branch
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="editForm.branch_id" 
                                                                        :options="generalBranchList" 
                                                                        name="branch" v-custom 
                                                                        :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                        </div>
                                    </form>
                                    <div class="col-lg-12" v-if="this.selectedTransaction.is_record_exists == false">
                                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                                            <div class="card-body pb-2 pt-3">
                                                <form id="kt_ecommerce_settings_general_form" @submit.prevent=""
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row row">
                                                        <div class="col-lg-12">
                                                            <div class="form-check my-3">
                                                                <input class="form-check-input" type="checkbox" value="" id="is_create_insured_card" v-model="editForm.is_create_insured_card">
                                                                <label class="fs-6 fw-bold form-label" for="is_create_insured_card">
                                                                    <span> {{ $t('Create Insurer Card') }} </span>
                                                                </label>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>                    
                                    </div>
                                </div>
                                <div class="row tab-pane fade active show" v-if="insurerActiveTab == 'insurerSearch' && this.selectedTransaction.is_record_exists != true" id="insurer_tab_2" role="tabpanel">
                                    <div class="card">
                                    <div class="card-header border-0 px-0">
                                        <div class="card-title">
                                            <div class="col-lg-12">
                                            <input type="text" name="searchText" class="form-control form-control-lg mt-3" v-model="searchText" placeholder="Please enter at least 3 characters" @focus="$event.target.select()">
                                        </div>
                                        </div>
                                        <div class="card-toolbar">
                                            <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                            <button type="button" class="btn btn-light border border-gray-400" @click="onSearchCustomer()" data-kt-indicator="on">
                                                <i class="fa fa-search"></i>
                                                {{ $t('Search') }}
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive min-h-300px">
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="customerSearchResultList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" 
                                                            @exporting="this.$onExporting($event, 'customer-list')" 
                                                            :ref="'dataGrid'"
                                                            :v-model:page-size="pageSize"
                                                            :v-model:page-index="pageIndex" > 

                                                        <DxFilterRow :visible="false"/>
                                                        <DxHeaderFilter :visible="false"/>
                                                        <DxGroupPanel :visible="false"/>      
                                                        <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="1000000"/>

                                                        <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="left" :allow-exporting="false" :width="50"/> 
                                                        <DxColumn v-if="isViewAccountId" data-field="account_id" caption="Code" alignment="left" cell-template="account_id-cell-template"/> 
                                                        <DxColumn data-field="name" caption="Name" alignment="left" cell-template="name-cell-template"/> 
                                                        <DxColumn data-field="customer_customer_account_number" caption="Registration Number" header-cell-template="customer_customer_account_number-header" alignment="left" cell-template="customer_customer_account_number-cell-template"/> 
                                                        <DxColumn data-field="customer_company_account_type" caption="Account Type" header-cell-template="customer_company_account_type-header" alignment="left" cell-template="customer_company_account_type-cell-template"/> 
                                                        <DxColumn data-field="customer_type_name" caption="Customer Type" header-cell-template="customer-type-header" alignment="left" cell-template="customer_type_name-cell-template"/> 
                                                        <DxColumn data-field="country" caption="Country" alignment="left" cell-template="country-cell-template"/>  
                                                        <DxColumn data-field="city" caption="City" alignment="left" cell-template="city-cell-template"/>  

                                                        <template #company-type-header>
                                                            <div>Company</div><div>Type</div>
                                                        </template>
                                                        <template #customer-type-header>
                                                            <div>Customer</div><div>Type</div>
                                                        </template>
                                                        <template #customer_customer_account_number-header>
                                                            <div>Registration</div><div>Number</div>
                                                        </template>
                                                        <template #customer_company_account_type-header>
                                                            <div>Account</div><div>Type</div>
                                                        </template>

                                                        <template #detail-cell-template="{  }">
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                    
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template v-if="isViewAccountId" #account_id-cell-template="{ data }">
                                                            <a href="#" class="text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                <div>
                                                                    <span class="badge badge-circle badge-light me-3">
                                                                        <i class="bi bi-pencil text-gray-900"></i> 
                                                                    </span> 
                                                                    <span class="text-gray-900"> 
                                                                        {{ data.data.account_id }} 
                                                                    </span> 
                                                                </div>
                                                            </a>
                                                        </template>
                                                        <template #name-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.name }}
                                                            </a>
                                                        </template>
                                                        <template #customer_customer_account_number-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.customer_customer_account_number }}
                                                            </a>
                                                        </template>
                                                        <template #customer_company_account_type-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.customer_company_account_type }}
                                                            </a>
                                                        </template>
                                                        <template #customer_type_name-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.customer_type_name }}
                                                            </a>
                                                        </template>
                                                        <template #country-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.country }}
                                                            </a>
                                                        </template>
                                                        <template #city-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                                                {{ data.data.city }}
                                                            </a>
                                                        </template>
                                                </DxDataGrid>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                                </div>
                                <div class="row tab-pane fade active show" v-if="insurerActiveTab == 'vendorSettings'" id="insurer_tab_3" role="tabpanel">
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch bg-lighten border border-gray-300 mt-3"> 
                                            <div class="card-body">
                                                <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                                    <div class="fv-row mb-6 row">   
                                                        <div class="col-lg-4">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                                    {{ $t('Vendor') }}
                                                                </label> 
                                                                <Select2 v-model="editVendorForm.vendor_id" :options="generalVendorList" @select="onChangeVendor($event)"
                                                                    name="vendor" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content' }"/> 
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                                    {{ $t('Main Branch') }}
                                                                </label> 
                                                                <Select2 v-model="editVendorForm.main_branch_id" :options="mainBranchList" :settings="{width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content'}" @select="getPolicyParentList()" v-custom name="main_branch_id"/>  
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                                    {{ $t('Policy Branch') }}
                                                                </label> 
                                                                <Select2 v-model="editVendorForm.policy_branch_id" :options="policyBranchList" :settings="{width: '100%', dropdownParent: '#kt_modal_edit_item .modal-content'}" v-custom name="policy_branch_id" @select="onChangeVendor()"/>  
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </form>
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addVendorItem()"
                                                            :disabled="editVendorForm.vendor_id == '-1' || editVendorForm.vendor_id == null || editVendorForm.vendor_id == '' || editVendorForm.vendor_id == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Add') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()"
                                                            :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="alert alert-warning d-flex align-items-center p-5 mb-3" 
                                                v-if="!isVendorChecking && editVendorForm.vendor_id !== '-1' && editVendorForm.is_commission_exist == 0 && editVendorForm.commission_rate == '' || editVendorForm.commission_type_name == ''  ">
                                                    <div class="d-flex flex-column">
                                                        <h4 class="mb-1 text-gray-800">{{ $t('Attention') }}!</h4>
                                                        <span>{{ $t('Commission rates are not defined for this vendor account in these branches.') }}.</span>
                                                    </div>
                                                </div>
                                                <div class="alert alert-success d-flex align-items-center p-5 mb-3" 
                                                    v-if="!isVendorChecking && editVendorForm.vendor_id !== '-1' && editVendorForm.is_commission_exist !== 0 && editVendorForm.commission_rate !== '' && editVendorForm.commission_type_name !== '' "> 
                                                    <div class="d-flex flex-column">
                                                        <span>The commission rate for this account is "<b>{{editVendorForm.commission_rate}}</b>" percent on the "<b>{{editVendorForm.commission_type_name}}</b>".</span>
                                                    </div>
                                                </div>
                                                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div class="table-responsive">
                                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                    key-expr="id" 
                                                                    :data-source="accountVendorList" 
                                                                    :allow-column-reordering="true"  
                                                                    :allow-column-resizing="true" 
                                                                    :column-auto-width="true" 
                                                                    :row-alternatin-enabled="true"
                                                                    :hover-state-enabled="true" 
                                                                    :show-row-lines="true" > 

                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="false"/>
                                                                <DxPaging :page-size="1000000"/>    
                                                                                       
                                                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                                                                <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template"/> 
                                                                <DxColumn data-field="main_branch_name" :caption="$t('Main Branch')" cell-template="main_branch_name-cell-template"/> 
                                                                <DxColumn data-field="policy_branch_name" :caption="$t('Policy Branch')" cell-template="policy_branch_name-cell-template"/> 
                                                                
                                                                <template #is-selected-cell-template="{ data }"> 
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.$parent.onSelectItem(accountVendorList, data.data)" />
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template #vendor_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.vendor_name }}
                                                                    </span>
                                                                </template>
                                                                <template #main_branch_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.main_branch_name }}
                                                                    </span>
                                                                </template>
                                                                <template #policy_branch_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.policy_branch_name }}
                                                                    </span>
                                                                </template>

                                                                <DxSummary>
                                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                                </DxSummary>
                                                        </DxDataGrid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                                <div class="row tab-pane fade active show" v-if="insurerActiveTab == 'policyData' && this.selectedTransaction.is_record_exists == true" id="insurer_tab_4" role="tabpanel">
                                    <div class="card">
                                        <div class="card-body px-0">
                                            <div class="table-responsive">
                                                <table class="table table-striped text-gray-800 gy-3 gs-3"> 
                                                    <tbody>
                                                        <tr>
                                                            <td class="w-50px"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Member Type</td>
                                                            <td class="ps-3">
                                                                <span v-if="this.selectedTransaction.customer_member_type == 2">Personal</span>
                                                                <span v-if="this.selectedTransaction.customer_member_type == 1">Company</span>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="this.selectedTransaction.customer_member_type == 2">
                                                            <td class="w-50px"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Personal ID</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_account_number }}</td>
                                                        </tr>
                                                        <tr v-if="this.selectedTransaction.customer_member_type == 1">
                                                            <td class="w-50px"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Company ID</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_vat_number }} </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Name/Company Name</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_account_name }}</td>
                                                        </tr> 
                                                        <tr>
                                                            <td colspan="3" class="text-left align-middle">
                                                                <button class="btn btn-block btn-success btn-xs" v-if="!updatePolicyTempForm.isSelected" @click="onSetAllPolicyData()" :disabled="this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    SET ALL DATA
                                                                </button>   
                                                                <button class="btn btn-block btn-danger btn-xs" v-if="updatePolicyTempForm.isSelected" @click="onSetAllPolicyData()" :disabled="this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    UNSET ALL DATA
                                                                </button>   
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_email" v-model="updatePolicyTempForm.customer_email" 
                                                                    :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_email == null || this.selectedTransaction.customer_email == '')" @change="this.onSelectPolicyDataItem('customer_email')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Email</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_email }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_mobile_phone" v-model="updatePolicyTempForm.customer_mobile_phone" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_mobile_phone == null || this.selectedTransaction.customer_mobile_phone == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_mobile_phone')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Mobile Phone</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_mobile_phone }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_landphone" v-model="updatePolicyTempForm.customer_landphone" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_landphone == null || this.selectedTransaction.customer_landphone == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_landphone')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Phone</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_landphone }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_type_id" v-model="updatePolicyTempForm.customer_type_id" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_type_id == null || this.selectedTransaction.customer_type_id == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_type_id')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Customer Type</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_type_id }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_postcode" v-model="updatePolicyTempForm.customer_postcode" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_postcode == null || this.selectedTransaction.customer_postcode == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_postcode')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Post Code</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_postcode }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_city" v-model="updatePolicyTempForm.customer_city" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_city == null || this.selectedTransaction.customer_city == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_city')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">City</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_city }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_district" v-model="updatePolicyTempForm.customer_district" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_district == null || this.selectedTransaction.customer_district == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_district')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">District</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_district }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px">
                                                                <input class="form-check-input" type="checkbox" value="" id="customer_address" v-model="updatePolicyTempForm.customer_address" 
                                                                        :disabled="(this.editForm.is_update_locked && this.$isCredentialActive(10,'ULR')) || (this.selectedTransaction.customer_address == null || this.selectedTransaction.customer_address == '')" 
                                                                        @change="this.onSelectPolicyDataItem('customer_address')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Address</td>
                                                            <td class="ps-3">{{ this.selectedTransaction.customer_address }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div class="row tab-pane fade active show" v-if="insurerActiveTab == 'accountGroup'" id="insurer_tab_5" role="tabpanel">                                
                                    <AccountGroupTab ref="AccountGroupTab" :account-info="accountInfo"></AccountGroupTab>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountModalLoading == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer pt-0 mt-0 row" style="border-top: 0px !important;">
                    <div class="d-grid gap-2">
                        <div class="btn-group">
                            <div class="col-lg-12" v-if="insurerActiveTab == 'insurerDetail'">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-success btn-lg" @click="onSaveTempTransaction()" :disabled="!this.$isCredentialActive(10,'ULR')">
                                        <!-- :disabled="this.editForm.is_update_locked == true && this.$isCredentialActive(10,'ULR')" -->
                                        <span class="indicator-label" v-if="this.selectedTransaction.is_record_exists == 1 || this.selectedTransaction.is_record_exists == true">{{ $t('Update') }}</span> 
                                        <span class="indicator-label" v-if="this.selectedTransaction.is_record_exists == 0 || this.selectedTransaction.is_record_exists == false">{{ $t('Save') }}</span> 
                                        <i class="bi bi-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn } from 'devextreme-vue/data-grid'; 
import AccountGroupTab from '../../Accounts/Modals/AccountGroupTab.vue';
export default {
    name:'CustomerEditModal',
    data(){
        return {
            userData: this.$getUser(),
            insurerActiveTab: 'insurerDetail',
            isAccountModalLoading: false,
            editForm: {
                customer_postcode: null,
                customer_city: null,
                customer_country: '-1',
                policy_main_branch_id: '-1',
                policy_sales_channel_id: '-1',
                customer_district: null,
                customer_contact_name: null,
                is_create_insured_card: false,
                customer_address: null,
                is_main_group: 0,
                customer_account_id: null,
                branch_id: null,
                vendor_id: null,
                customer_account_name: null,
                customer_member_type: 2,
                company_type_id: null,
                customer_email: null,
                customer_mobile_phone: null,
                customer_landphone: null,
                account_type: null, 
                qr_code: null,
                notes: null, 
                customer_account_number: null,
                customer_vat_number: null,
                personal_id: null,
                contact_name: null,
                postcode: null,
                address: null,
                address_id: '-1',
                country: '-1',
                city: null, 
                vat_number: null,
                payment_value: null,
                isVATNumberExist: 'false',
                accounting_plan: null,
                customer_type_id: '-1',
                sales_channel_id: '-1',
                phone_country_code: '+44',
                land_phone_country_code: '+44',
                district: null,
                customer_x_coordinate: 0,
                customer_y_coordinate: 0,
                customer_uprn: null,
                customer_udprn: null,
                campaign_id: '-1',
                is_update_locked: false
            },
            selectedTransaction: {
                is_record_exists: false
            },
            originalCustomerAccount: {
                is_update_locked: false
            },
            editVendorForm: {
                vendor_id: '-1',
                main_branch_id: '-1',
                policy_branch_id: '-1',
                is_commission_exist: 0,
                commission_type_name:null,
                commission_rate:null,
            },
            customerSearchResultList: [],
            companyAccountTypeList: [
                {id: 1, text: 'Company'},
                {id: 2, text: 'Person'},
            ], 
            updatePolicyTempForm: {},
            countryList: [{ id: '-1', text: 'Select an option'}],
            salesChannelList: [{ id: '-1', text: 'Select an option'}],
            phoneMaskList: [],
            generalBranchList: [],
            generalVendorList: [],
            accountVendorList: [],
            customerTypeList: [],
            foundAccountData: null,
            searchText: null,
            addressList: [{id: '-1', text: 'Select an address from the list after the postcode query'}],
            isSearchingByPostCode: false,
            isSearchingByAddressCode: false,
            isVendorChecking: false,
            accountInfo: {
                id: null,
                account_type: 1,
                is_main_group: 0,
                is_record_exists: 0,
                policy_transaction_id: null
            },
            mainBranchList: [{ id: '-1', text: 'Select an option'}],
            policyBranchList: [{ id: '-1', text: 'ALL'}],
            is_default: true,
        }
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, AccountGroupTab
    },
    computed: {
        setColumnTemplateForLocal(){
            return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
            }
        },
        isButtonDisabled() {
            return this.accountVendorList.find(item => item.isSelected == true && item.id !== '');
        }
    },
    methods:{
        getData(selectedTransaction){
            if(!this.$parent.isViewCustomerEditModal) return;            
            this.selectedTransaction = selectedTransaction;
            this.$removeSessionStorage('tmp_session_id');
            this.clearEditForm();
            this.accountVendorList = [];            
            this.isAccountModalLoading = true;
            this.insurerActiveTab = 'insurerDetail';            
            const that = this;
            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["account_setting", "sales_channel", "country"],
                country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
                account_type: 1, // yalnızca account settings listesi çekilecek ise gerekli
                policy_transaction_id: selectedTransaction.id
            }; 
            const parameters2 = {
                session_id: this.$getUser().session_id,
                list_type: 14, // Customer Type: 14
                get_access_status: 1,
                type_id: 1
            };
            const parameters3 = {
                route: this.selectedTransaction.is_record_exists === 1 ? 'CustomerUpdate' : 'CustomerCreate',
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Branch/BranchList', parameters, {'Content-Type': 'application/json'});
            const requestThree =  axios.post('/api/Helper/GetLists', parameters2, {'Content-Type': 'application/json'});
            const requestFour =  axios.post('/api/FormValidations/GetFormFields', parameters3, {'Content-Type': 'application/json'});
            const requestFive =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
            axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive]).then(axios.spread((...responses) => {
                this.salesChannelList = responses[0].data.sales_channel;
                this.countryList = responses[0].data.country; 
                this.generalBranchList = responses[1].data;
                this.customerTypeList = responses[2].data;
                this.phoneMaskList = responses[4].data;
                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
                this.countryList.unshift({ id: '-1', text: 'Select an option'});
                this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
                if(responses[3].data != null){
                    sessionStorage.removeItem('frm_val');
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[3].data));
                }
                // CREATE MODE İSE ACCOUNT BİLGİLERİ TEMP'DEN SETLENİR
                if(selectedTransaction.is_record_exists == false || selectedTransaction.is_record_exists == 0){
                    this.editForm = selectedTransaction;
                    this.editForm.customer_country = this.$getUser().company_country_code; 
                    this.editForm.customer_type_id = '-1'; 
                    this.countryList.forEach(countryItem => {
                        if(countryItem.text == selectedTransaction.customer_country){
                            this.editForm.customer_country = countryItem.id;
                        } 
                    });
                    this.editForm.customer_address = selectedTransaction.customer_address;
                    this.editForm.policy_sales_channel_id = this.editForm.policy_sales_channel_id == null || this.editForm.policy_sales_channel_id == '' ? '-1' : this.editForm.policy_sales_channel_id;
                    this.editForm.policy_main_branch_id = this.editForm.policy_main_branch_id == null || this.editForm.policy_main_branch_id == '' ? '-1' : this.editForm.policy_main_branch_id;
                    this.editForm.branch_id = this.editForm.branch_id == null || this.editForm.branch_id == '' ? '-1' : this.editForm.branch_id;
                    
                    this.phoneMaskList.forEach(element => {
                        if(element.code == this.$getUser().company_country_code) {
                            this.editForm.phone_country_code = element.id;
                            this.editForm.land_phone_country_code = element.id;
                        }
                    });
                } 
                this.editForm.vendor_id = '-1';
                this.editForm.address_id = '-1';

                // UPDATE MODE İSE ACCOUNT BİLGİLERİ GETİR
                if(selectedTransaction.is_record_exists == true || selectedTransaction.is_record_exists == 1){
                    const params = {
                        id: selectedTransaction.customer_account_id,
                        session_id: this.$getUser().session_id
                    };
                    // VENDOR HESAPLARI GETİRİLİR
                    const vendorParams = {
                        account_id: selectedTransaction.customer_account_id,
                        session_id: this.$getUser().session_id
                    };
                    const requestOne =  axios.post('/api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'});
                    const requestTwo =  axios.post('/api/GetCompanyAccounts/GetCustomerVendorsByAccountId', vendorParams, {'Content-Type': 'application/json'}); 
                    axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                        this.editForm.customer_account_id = this.$unlock(selectedTransaction.customer_account_id);
                        this.editForm.branch_id = responses[0].data.branch_id == '' ? '-1' : responses[0].data.branch_id;
                        this.editForm.customer_account_name = this.$unlock(responses[0].data.name);
                        this.editForm.customer_member_type = responses[0].data.customer_company_account_type;
                        this.editForm.customer_type_id = responses[0].data.customer_customer_type_id == '' ? '-1' : responses[0].data.customer_customer_type_id;
                        this.editForm.customer_email = this.$unlock(responses[0].data.customer_email);
                        this.editForm.customer_mobile_phone = this.$unlock(responses[0].data.customer_mobile_phone);
                        this.editForm.customer_landphone = this.$unlock(responses[0].data.customer_phone);
                        this.editForm.account_type = responses[0].data.account_type; 
                        this.editForm.notes = responses[0].data.notes;
                        this.editForm.customer_account_number = this.$unlock(responses[0].data.customer_account_number);
                        this.editForm.customer_vat_number = responses[0].data.customer_vat_number == '' ? null : responses[0].data.customer_vat_number;
                        this.editForm.contact_name = this.$unlock(responses[0].data.customer_contact_name);
                        this.editForm.customer_postcode = this.$unlock(responses[0].data.customer_postcode);
                        this.editForm.customer_address = this.$unlock(responses[0].data.customer_addres); 
                        this.editForm.customer_country = this.$unlock(responses[0].data.customer_country);
                        this.editForm.customer_city = this.$unlock(responses[0].data.customer_city);
                        this.editForm.qr_code = this.$unlock(responses[0].data.qr_code);
                        this.editForm.vat_number = this.$unlock(responses[0].data.customer_vat_number) == '' ? null : this.$unlock(responses[0].data.customer_vat_number);
                        this.editForm.payment_value = responses[0].data.customer_payment_value == '' ? null : responses[0].data.customer_payment_value; 
                        this.editForm.policy_sales_channel_id = responses[0].data.customer_sales_channel_id == '' ? '-1' : responses[0].data.customer_sales_channel_id;
                        responses[0].data.customer_phone_country_code = this.$unlock(responses[0].data.customer_phone_country_code);
                        responses[0].data.customer_land_phone_country_code = this.$unlock(responses[0].data.customer_land_phone_country_code);
                        if(responses[0].data.customer_phone_country_code !== null && responses[0].data.customer_phone_country_code !== undefined && responses[0].data.customer_phone_country_code !== '') {
                            this.editForm.phone_country_code = responses[0].data.customer_phone_country_code;
                        }
                        if(responses[0].data.customer_land_phone_country_code !== null && responses[0].data.customer_land_phone_country_code !== undefined && responses[0].data.customer_land_phone_country_code !== '') {
                            this.editForm.land_phone_country_code = responses[0].data.customer_land_phone_country_code;
                        }
                        this.editForm.customer_district = this.$unlock(responses[0].data.customer_district);
                        this.editForm.customer_x_coordinate = this.$unlock(responses[0].data.customer_x_coordinate);
                        this.editForm.customer_y_coordinate = this.$unlock(responses[0].data.customer_y_coordinate);
                        this.editForm.customer_uprn = this.$unlock(responses[0].data.customer_uprn);
                        this.editForm.customer_udprn = this.$unlock(responses[0].data.customer_udprn);
                        this.editForm.is_main_group = responses[0].data.is_main_group;
                        this.editForm.is_update_locked = responses[0].data.is_update_locked == 1 ? true : false;
                        this.accountInfo = {
                            id: this.editForm.customer_account_id,
                            account_type: 1,
                            is_main_group: this.editForm.is_main_group,
                            is_record_exists: this.selectedTransaction.is_record_exists,
                            policy_transaction_id: null
                        };

                        const originalData = JSON.parse(JSON.stringify(this.editForm));
                        this.originalCustomerAccount = originalData;                    
                        this.accountVendorList = responses[1].data;
                        if(this.accountVendorList.length > 0){ this.$setSessionStorage('tmp_session_id', responses[1].data[0].tmp_session_id); }
                    }))
                    .catch(errors => { 
                        console.error(errors); 
                    })
                    .finally(function(error) {
                        console.log(error);
                        that.isAccountModalLoading = false;
                    });
                } else {
                    that.isAccountModalLoading = false;
                }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        clearEditForm() {
            this.editForm = {            
                customer_postcode: null,
                customer_city: null,
                customer_country: '-1',
                policy_main_branch_id: '-1',
                policy_sales_channel_id: '-1',
                customer_district: null,
                customer_contact_name: null,
                is_create_insured_card: false,
                customer_address: null,
                is_main_group: 0,
                customer_account_id: null,
                branch_id: null,
                vendor_id: null,
                customer_account_name: null,
                customer_member_type: 2,
                company_type_id: null,
                customer_email: null,
                customer_mobile_phone: null,
                customer_landphone: null,
                account_type: null, 
                qr_code: null,
                notes: null, 
                customer_account_number: null,
                customer_vat_number: null,
                personal_id: null,
                contact_name: null,
                postcode: null,
                address: null,
                address_id: '-1',
                country: '-1',
                city: null, 
                vat_number: null,
                payment_value: null,
                isVATNumberExist: 'false',
                accounting_plan: null,
                customer_type_id: '-1',
                sales_channel_id: '-1',
                phone_country_code: '+44',
                land_phone_country_code: '+44',
                district: null,
                customer_x_coordinate: 0,
                customer_y_coordinate: 0,
                customer_uprn: null,
                customer_udprn: null,
                campaign_id: '-1',
                is_update_locked: false
            };
            this.accountInfo = {
                id: null,
                account_type: 1,
                is_main_group: 0,
                is_record_exists: 0,
                policy_transaction_id: this.selectedTransaction.id
            };
        },
        onSelectCustomerAddressSearchResult(item){
            if(item.id == '-1') return;
            this.isViewAddressModal = false;
            this.editForm.customer_address = item.ADDRESS;
            this.editForm.customer_city = item.POST_TOWN;
            this.editForm.customer_district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
            this.editForm.customer_customer_x_coordinate = item.X_COORDINATE;
            this.editForm.customer_postcode = item.POSTCODE;
            this.editForm.customer_uprn = item.UPRN;
            this.editForm.customer_udprn = item.UDPRN;
        },
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.editForm.customer_address = item.fullAddress;
            this.editForm.customer_city = item.town_or_city;
            this.editForm.customer_district = item.district;
            this.editForm.customer_postcode = item.postcode;
        },
        getVendorPolicyLists() {        
            this.editVendorForm = {
                vendor_id: '-1',
                main_branch_id: '-1',
                policy_branch_id: '-1',
                is_commission_exist: 0
            };
            const parameters = {
                session_id: this.$getUser().session_id,
                list_type: 1, /* Main Branch */
                supplier_account_type: 2,
            };  
            const requestOne =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'});        
            const requestTwo =  axios.post('/api/GetCompanyAccounts/GetPolicyAccounts', parameters, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.mainBranchList= responses[0].data;
                this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
                this.generalVendorList = responses[1].data;
                this.generalVendorList.unshift({ id: '-1', text: 'Select an option'});
                this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getPolicyParentList() {
            this.editVendorForm.policy_branch_id = '-1';
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 2, // Policy Branch: 2 
                parent_id: this.editVendorForm.main_branch_id
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.policyBranchList = response.data;
                this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
                this.onChangeVendor();
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        addVendorItem(){
            this.selectedAccounts = [];
            if (this.editVendorForm.vendor_id == null || this.editVendorForm.vendor_id == '' || this.editVendorForm.vendor_id == undefined) {            
                this.$swal("Failed", " Vendor must be selected..!", 'warning' );
                return;
            }
            this.selectedAccounts.push(this.editVendorForm.vendor_id);
            this.isItemSaving = true;
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                accountList: this.selectedAccounts,
                main_branch_id: this.editVendorForm.main_branch_id == '-1' ? null : this.editVendorForm.main_branch_id,
                policy_branch_id: this.editVendorForm.policy_branch_id == '-1' ? null : this.editVendorForm.policy_branch_id
            };
            const that = this;
            axios.post('/api/GetCompanyAccounts/AddVendorItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.accountVendorList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.editVendorForm = {
                        vendor_id: '-1',
                        main_branch_id: '-1',
                        policy_branch_id: '-1',
                        is_commission_exist: 0
                    };
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        deleteVendorItem(){
            let vendorId = null;
            this.accountVendorList.forEach((item) => {
                if (item.isSelected) { vendorId = item.id; }
            });
            this.$swal.fire({
                title: "Are you sure you want to remove this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                        vendor_id: vendorId,
                        is_delete_all: 0
                    }; 
                    axios.post('/api/GetCompanyAccounts/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.accountVendorList = response.data;
                        } 
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        onChangeIsMainGroup(isMainGroup) {
            this.editForm.is_main_group = isMainGroup;
            this.accountInfo.is_main_group = isMainGroup;
        },
        onChangeVendor() {
            if(this.editVendorForm.vendor_id == '-1') return;
            this.isVendorChecking = true;
            const that = this;
            const parameters = {
                session_id: this.$getUser().session_id,
                account_id: this.editVendorForm.vendor_id,
                main_branch_id: this.editVendorForm.main_branch_id !== '-1' ? this.editVendorForm.main_branch_id : null,
                policy_branch_id: this.editVendorForm.policy_branch_id !== '-1' ? this.editVendorForm.policy_branch_id : null,
            };  
            axios.post('/api/GetCompanyAccounts/CheckIfVendorCommissionExist', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.editVendorForm.is_commission_exist = response.data;
                this.editVendorForm.commission_type_name = response.data.commission_type_name;
                this.editVendorForm.commission_rate = response.data.commission_rate;
            })
            .finally(function() {            
                setTimeout(function(){
                    that.isVendorChecking = false;
                }, 500); 
            });
        },
        onSelectPolicyDataItem(type){ 
            if(type == 'customer_email' && this.updatePolicyTempForm.customer_email == true && this.selectedTransaction.customer_email !== '' && this.selectedTransaction.customer_email !== null){
            this.editForm.customer_email = this.selectedTransaction.customer_email;
            }
            if(type == 'customer_email' && this.updatePolicyTempForm.customer_email == false && this.originalCustomerAccount.customer_email !== '' && this.originalCustomerAccount.customer_email !== null){
            this.editForm.customer_email = this.originalCustomerAccount.customer_email;
            }

            if(type == 'customer_mobile_phone' && this.updatePolicyTempForm.customer_mobile_phone == true && this.selectedTransaction.customer_mobile_phone !== '' && this.selectedTransaction.customer_mobile_phone !== null){
                this.editForm.customer_mobile_phone = this.selectedTransaction.customer_mobile_phone;
            }
            if(type == 'customer_mobile_phone' && this.updatePolicyTempForm.customer_mobile_phone == false && this.originalCustomerAccount.customer_mobile_phone !== '' && this.originalCustomerAccount.customer_mobile_phone !== null){
                this.editForm.customer_mobile_phone = this.originalCustomerAccount.customer_mobile_phone;
            }

            if(type == 'customer_landphone' && this.updatePolicyTempForm.customer_landphone == true && this.selectedTransaction.customer_landphone !== '' && this.selectedTransaction.customer_landphone !== null){
                this.editForm.customer_landphone = this.selectedTransaction.customer_landphone;
            }
            if(type == 'customer_landphone' && this.updatePolicyTempForm.customer_landphone == false && this.originalCustomerAccount.customer_landphone !== '' && this.originalCustomerAccount.customer_landphone !== null){
                this.editForm.customer_landphone = this.originalCustomerAccount.customer_landphone;
            }

            if(type == 'customer_type_id' && this.updatePolicyTempForm.customer_type_id == true && this.selectedTransaction.customer_type_id !== '' && this.selectedTransaction.customer_type_id !== null){
                this.editForm.customer_type_id = this.selectedTransaction.customer_type_id;
            }
            if(type == 'customer_type_id' && this.updatePolicyTempForm.customer_type_id == false && this.originalCustomerAccount.customer_type_id !== '' && this.originalCustomerAccount.customer_type_id !== null){
                this.editForm.customer_type_id = this.originalCustomerAccount.customer_type_id;
            }

            if(type == 'customer_postcode' && this.updatePolicyTempForm.customer_postcode == true && this.selectedTransaction.customer_postcode !== '' && this.selectedTransaction.customer_postcode !== null){
                this.editForm.customer_postcode = this.selectedTransaction.customer_postcode;
            }
            if(type == 'customer_postcode' && this.updatePolicyTempForm.customer_postcode == false && this.originalCustomerAccount.customer_postcode !== '' && this.originalCustomerAccount.customer_postcode !== null){
                this.editForm.customer_postcode = this.originalCustomerAccount.customer_postcode;
            }

            if(type == 'customer_city' && this.updatePolicyTempForm.customer_city == true && this.selectedTransaction.customer_city !== '' && this.selectedTransaction.customer_city !== null){
                this.editForm.customer_city = this.selectedTransaction.customer_city;
            }
            if(type == 'customer_city' && this.updatePolicyTempForm.customer_city == false && this.originalCustomerAccount.customer_city !== '' && this.originalCustomerAccount.customer_city !== null){
                this.editForm.customer_city = this.originalCustomerAccount.customer_city;
            }

            if(type == 'customer_district' && this.updatePolicyTempForm.customer_district == true && this.selectedTransaction.customer_district !== '' && this.selectedTransaction.customer_district !== null){
                this.editForm.customer_district = this.selectedTransaction.customer_district;
            }
            if(type == 'customer_district' && this.updatePolicyTempForm.customer_district == false && this.originalCustomerAccount.customer_district !== '' && this.originalCustomerAccount.customer_district !== null){
                this.editForm.customer_district = this.originalCustomerAccount.customer_district;
            }

            if(type == 'customer_address' && this.updatePolicyTempForm.customer_address == true && this.selectedTransaction.customer_address !== '' && this.selectedTransaction.customer_address !== null){
                this.editForm.customer_address = this.selectedTransaction.customer_address;
            }
            if(type == 'customer_address' && this.updatePolicyTempForm.customer_address == false && this.originalCustomerAccount.customer_address !== '' && this.originalCustomerAccount.customer_address !== null){
                this.editForm.customer_address = this.originalCustomerAccount.customer_address;
            }
        },
        onSetAllPolicyData(){
            this.updatePolicyTempForm.isSelected = !this.updatePolicyTempForm.isSelected;
            this.updatePolicyTempForm.customer_email = this.updatePolicyTempForm.customer_email == '' || this.updatePolicyTempForm.customer_email == null ? true : !this.updatePolicyTempForm.customer_email;
            this.updatePolicyTempForm.customer_mobile_phone = this.updatePolicyTempForm.customer_mobile_phone == '' || this.updatePolicyTempForm.customer_mobile_phone == null ? true : !this.updatePolicyTempForm.customer_mobile_phone;
            this.updatePolicyTempForm.customer_landphone = this.updatePolicyTempForm.customer_landphone == '' || this.updatePolicyTempForm.customer_landphone == null ? true : !this.updatePolicyTempForm.customer_landphone;
            this.updatePolicyTempForm.customer_type_id = this.updatePolicyTempForm.customer_type_id == '' || this.updatePolicyTempForm.customer_type_id == null ? true : !this.updatePolicyTempForm.customer_type_id;
            this.updatePolicyTempForm.customer_postcode = this.updatePolicyTempForm.customer_postcode == '' || this.updatePolicyTempForm.customer_postcode == null ? true : !this.updatePolicyTempForm.customer_postcode;
            this.updatePolicyTempForm.customer_city = this.updatePolicyTempForm.customer_city == '' || this.updatePolicyTempForm.customer_city == null ? true : !this.updatePolicyTempForm.customer_city;
            this.updatePolicyTempForm.customer_district = this.updatePolicyTempForm.customer_district == '' || this.updatePolicyTempForm.customer_district == null ? true : !this.updatePolicyTempForm.customer_district;
            this.updatePolicyTempForm.customer_address = this.updatePolicyTempForm.customer_address == '' || this.updatePolicyTempForm.customer_address == null ? true : !this.updatePolicyTempForm.customer_address;

            if(this.updatePolicyTempForm.isSelected){
                if(this.updatePolicyTempForm.customer_email == true && this.selectedTransaction.customer_email !== '' && this.selectedTransaction.customer_email !== null){
                    this.editForm.customer_email = this.selectedTransaction.customer_email;
                }
                if(this.updatePolicyTempForm.customer_mobile_phone == true && this.selectedTransaction.customer_mobile_phone !== '' && this.selectedTransaction.customer_mobile_phone !== null){
                    this.editForm.customer_mobile_phone = this.selectedTransaction.customer_mobile_phone;
                }
                if(this.updatePolicyTempForm.customer_landphone == true && this.selectedTransaction.customer_landphone !== '' && this.selectedTransaction.customer_landphone !== null){
                    this.editForm.customer_landphone = this.selectedTransaction.customer_landphone;
                }
                if(this.updatePolicyTempForm.customer_type_id == true && this.selectedTransaction.customer_type_id !== '' && this.selectedTransaction.customer_type_id !== null){
                    this.editForm.customer_type_id = this.selectedTransaction.customer_type_id;
                }
                if(this.updatePolicyTempForm.customer_postcode == true && this.selectedTransaction.customer_postcode !== '' && this.selectedTransaction.customer_postcode !== null){
                    this.editForm.customer_postcode = this.selectedTransaction.customer_postcode;
                }
                if(this.updatePolicyTempForm.customer_city == true && this.selectedTransaction.customer_city !== '' && this.selectedTransaction.customer_city !== null){
                    this.editForm.customer_city = this.selectedTransaction.customer_city;
                }
                if(this.updatePolicyTempForm.customer_district == true && this.selectedTransaction.customer_district !== '' && this.selectedTransaction.customer_district !== null){
                    this.editForm.customer_district = this.selectedTransaction.customer_district;
                }
                if(this.updatePolicyTempForm.customer_address == true && this.selectedTransaction.customer_address !== '' && this.selectedTransaction.customer_address !== null){
                    this.editForm.customer_address = this.selectedTransaction.customer_address;
                }
            }
            if(!this.updatePolicyTempForm.isSelected){
                if(this.updatePolicyTempForm.customer_email == false && this.originalCustomerAccount.customer_email !== '' && this.originalCustomerAccount.customer_email !== null){
                    this.editForm.customer_email = this.originalCustomerAccount.customer_email;
                }
                if(this.updatePolicyTempForm.customer_mobile_phone == false && this.originalCustomerAccount.customer_mobile_phone !== '' && this.originalCustomerAccount.customer_mobile_phone !== null){
                    this.editForm.customer_mobile_phone = this.originalCustomerAccount.customer_mobile_phone;
                }
                if(this.updatePolicyTempForm.customer_landphone == false && this.originalCustomerAccount.customer_landphone !== '' && this.originalCustomerAccount.customer_landphone !== null){
                    this.editForm.customer_landphone = this.originalCustomerAccount.customer_landphone;
                }
                if(this.updatePolicyTempForm.customer_type_id == false && this.originalCustomerAccount.customer_type_id !== '' && this.originalCustomerAccount.customer_type_id !== null){
                    this.editForm.customer_type_id = this.originalCustomerAccount.customer_type_id;
                }
                if(this.updatePolicyTempForm.customer_postcode == false && this.originalCustomerAccount.customer_postcode !== '' && this.originalCustomerAccount.customer_postcode !== null){
                    this.editForm.customer_postcode = this.originalCustomerAccount.customer_postcode;
                }
                if(this.updatePolicyTempForm.customer_city == false && this.originalCustomerAccount.customer_city !== '' && this.originalCustomerAccount.customer_city !== null){
                    this.editForm.customer_city = this.originalCustomerAccount.customer_city;
                }
                if(this.updatePolicyTempForm.customer_district == false && this.originalCustomerAccount.customer_district !== '' && this.originalCustomerAccount.customer_district !== null){
                    this.editForm.customer_district = this.originalCustomerAccount.customer_district;
                }
                if(this.updatePolicyTempForm.customer_address == false && this.originalCustomerAccount.customer_address !== '' && this.originalCustomerAccount.customer_address !== null){
                    this.editForm.customer_address = this.originalCustomerAccount.customer_address;
                }
            }
        }, 
        onSaveTempTransaction(){
            this.$checkSelect2Validation('poliformModal');
            if(!document.forms['poliformModal'].reportValidity()){
                return;
            }
            const that = this;
            this.$swal.fire({
                title: "Are you sure you want to UPDATE selected data?",
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "UPDATE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    var parameters = that.editForm; 
                    parameters.id = this.selectedTransaction.id;
                    parameters.is_create_insured_card = parameters.is_create_insured_card ? 1 : 0;
                    parameters.branch_id = that.editForm.branch_id == '-1' ? null : that.editForm.branch_id;
                    parameters.session_id = this.$getUser().session_id;
                    parameters.tmp_session_id = this.$getSessionStorage('tmp_session_id');
                    parameters.is_update_locked = parameters.is_update_locked ? 1 : 0;
                    parameters.customer_type_id = that.editForm.customer_type_id == '-1' ? null : that.editForm.customer_type_id;
                    const requestOne =  axios.post('/api/DLLTemplates/UpdateTempTransaction', parameters, {'Content-Type': 'application/json'});
                    axios.all([requestOne]).then(axios.spread(() => {
                        that.$refs.closeEditModal.click(); 
                        this.$parent.getTransactionRowById(that.editForm.id, 1);
                    }))
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            });  
        },
        onPostProcessEditModal(){
            this.foundAccountData = null;
            const params = {
                session_id: this.$getUser().session_id,
                search_text: this.selectedTransaction.customer_member_type == 2 ? this.selectedTransaction.customer_account_number : this.selectedTransaction.customer_vat_number,
                search_parameter: '2',
                account_type: 12
            };
            axios.post("/api/Invoice/GetAccountsWithSearch", params, {'Content-Type': 'application/json'}).then((response) => { 
                if(response.data != null && response.data.length > 0){
                    this.foundAccountData = response.data[0];
                } 
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onSearchCustomer(){
            this.customerSearchResultList = [];
            const params = {
                session_id: this.$getUser().session_id,
                search_text: this.searchText,
                search_parameter: 1,
                account_type: 12
            };
            axios.post("/api/Invoice/GetAccountsWithSearch", params, {'Content-Type': 'application/json'}).then((response) => { 
                if(response.data != null && response.data.length > 0){
                    this.customerSearchResultList = response.data;
                } 
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onSyncCustomerData(){
            this.editForm.customer_account_id = this.foundAccountData.id;
            this.editForm.customer_account_name = this.foundAccountData.name;
            this.editForm.customer_account_number = this.foundAccountData.account_number; 
        },
        onFindAddressByPostCode(postCode){
            this.isSearchingByPostCode = true;
            this.editForm.address_id = '-1';
            this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
            const that = this;
            const parameters = {
                PostCode: postCode,
                SessionId: this.$getUser().session_id,
                SourceType: 1
            };
            axios.post('api/GetCompanyAccounts/FindAddressByPostCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data.results) {
                        response.data.results.forEach(value => {
                            value.DPA.id = value.DPA.UPRN;
                            value.DPA.text = value.DPA.ADDRESS;
                            that.addressList.push(value.DPA);
                        });
                    }
                }
            })
            .finally(function () { 
                that.isSearchingByPostCode = false;
            }); 
        },
        onFindAddressByAddressCode(addressCode){
            this.isSearchingByAddressCode = true;
            this.editForm.address_id = '-1';
            this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
            const that = this;
            const parameters = {
                PostCode: addressCode,
                SessionId: this.$getUser().session_id,
                SourceType: 1
            };
            axios.post('api/GetCompanyAccounts/FindAddressByAddressCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data.results) {
                        response.data.results.forEach(value => {
                            value.DPA.id = value.DPA.UPRN;
                            value.DPA.text = value.DPA.ADDRESS;
                            that.addressList.push(value.DPA);
                        });
                    }
                }
            })
            .finally(function () { 
                that.isSearchingByAddressCode = false;
            }); 
        },
    }
}
</script>