<template>
    <div>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content"  >
              <div class="modal-header bg-primary py-3">
                  <h2 class="modal-title fw-bolder col-md-10 text-white"> Search </h2>
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeSearchPolicyModal">
                      <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white"> 
                <form class="form fv-plugins-bootstrap5 fv-plugins-framework" @submit.prevent="onSearch()"> 
                    <div class="row"> 
                        <div class="col-lg-12">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Search By</span>
                            </label>
                            <Select2 v-model="searchParameter" :options="searchParameterList" :settings="{ width: '100%', dropdownParent: '#kt_search_policies_modal .modal-content'}"/>
                        </div>
                        <div class="col-lg-12">
                            <input type="text" name="searchText" class="form-control form-control-lg mt-3" v-model="searchText" placeholder="Please enter at least 3 characters" @focus="$event.target.select()">
                        </div>
                        <div class="col-lg-12 mt-5">
                            <div class="card card-flush">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" @click="onSearch()" :disabled="searchText.length < 3">
                                        <span class="indicator-label">Search Policies</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'SearchAccount',
    props: {
        accountType: String
    },
    data() {
        return {
            searchText: '',
            searchParameter: 1,
            searchParameterList: [
                { id: 1, text: 'Policy No'},
                { id: 2, text: 'Vehicle Registration Number'},
                { id: 3, text: 'Property Registration Number'},
                { id: 4, text: 'Person/Company ID'},
            ]
        }
    },
    methods: {
        getData() {
            this.searchText = '';
            this.searchParameter = 1;
        },
        onSearch() {            
            this.$refs.closeSearchPolicyModal.click();
            this.$parent.getData(this.searchText, this.searchParameter);
        }
    }
}
</script>