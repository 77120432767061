<template>
    <div>
        <div class="modal-dialog modal-xxl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Policy Producer Type</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closePolicyProducerModal" @click="onCloseModal()">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-7"> 
                     <div class="card card-xxl-stretch pt-3">
                        <div class="card-body">
                            <form id="producerPolicyModal" @submit.prevent="" class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">        
                                <div class="row"> 
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                            {{ $t('System Main Branch') }}
                                                        </label> 
                                                        <Select2 v-model="producerForm.main_branch_id" :options="mainBranchList" @select="getParentLists(2, producerForm.main_branch_id, true)" name="main_branch_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_policy_producer_type .modal-content'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                            {{ $t('System Policy Branch') }}
                                                        </label> 
                                                        <Select2 v-model="producerForm.policy_branch_id" :options="policyBranchList" name="policy_branch_id" @select="getPolicyGroupList(true)" v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_policy_producer_type .modal-content'}"/>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_group_id'">
                                                            {{ $t('System Policy Group') }}
                                                        </label> 
                                                        <Select2 v-model="producerForm.policy_group_id" :options="policyGroupList" name="policy_group_id"  v-custom  :settings="{width: '100%', dropdownParent: '#kt_modal_policy_producer_type .modal-content'}"/>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_policy_code'">
                                                            {{ $t('Producer Policy Code') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="producer_policy_code" v-model="producerForm.producer_policy_code" :disabled ="!producerForm.producer_type_exists == 0" v-custom>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_policy_name'">
                                                            {{ $t('Producer Policy Name') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="producer_policy_name" v-model="producerForm.producer_policy_name" v-custom>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'search_title'">
                                                            {{ $t('Search Title') }}
                                                            <!-- Producer Policy Name setlenecek otomatik olarak -->
                                                        </label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="search_title" v-model="producerForm.search_title" v-custom />
                                                            <span class="input-group-text pt-1 pb-1 ps-3 pe-3">
                                                                <input class="form-check-input" type="checkbox" v-model="producerForm.is_search_from_title"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="card card-flush mt-4">
                                                <div class="d-grid gap-2"> 
                                                    <button v-if="policyProducerModalType == 'add'" type="button" class="btn btn-light-success border border-success btn-lg"
                                                        @click="addProducerSetting()">
                                                        <span class="indicator-label">{{ $t('Save') }}</span> 
                                                    </button>
                                                    <button v-if="policyProducerModalType == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg"
                                                        @click="editProducerSetting()">
                                                        <span class="indicator-label">{{ $t('Update') }}</span> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
export default {
    name:'ProducerPolicyTypeModal',
    data() {
        return {
            mainBranchList: [],
            policyBranchList: [],
            policyGroupList: [],
            producerForm: {},
            policyProducerModalType: null,
        }
    },
    methods:{ 
        getData(producerItem){
            if(!this.$parent.isViewProducerPolicyTypeModal) return;
            if(producerItem.producer_type_exists == 1){
                this.policyProducerModalType = 'edit';
                this.producerForm.policy_transaction_id = producerItem.policy_transaction_id;
            } else {
                this.policyProducerModalType = 'add';
                this.producerForm = {
                    policy_transaction_id: producerItem.policy_transaction_id,
                    main_branch_id: '-1',
                    policy_branch_id: '-1',
                    producer_policy_code: producerItem.producer_policy_code,
                    producer_policy_name: producerItem.producer_policy_name,
                    policy_producer_type_id: null,
                    supplier_producer_id: producerItem.supplier_producer_id,
                    policy_group_id: producerItem.policy_group_id,
                    search_title: producerItem.producer_policy_name
                };
            }
            const parameters = {
                session_id: this.$getUser().session_id,
                list_types: [{ list_type: 1, list_name: "main_branch" }],
            };
            const parametersTwo = {
                route: producerItem.producer_type_exists === 1  ? 'ProducerPolicyUpdate' : 'ProducerPolicyCreate',
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo = axios.post('/api/FormValidations/GetFormFields', parametersTwo, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.mainBranchList = responses[0].data.main_branch;
                if(responses[1].data != null){  
                    sessionStorage.removeItem('frm_val');
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[1].data));
                } 
                this.mainBranchList.unshift({ id: '-1', text: 'Select an option'});
                this.policyBranchList.unshift({ id: '-1', text: 'Select an option'});
                this.policyGroupList.unshift({ id: '-1', text: 'Select an option'});

                if(producerItem.producer_type_exists == 1){
                    this.getParentLists(2, producerItem.main_branch_id, true);
                    this.producerForm = producerItem;
                } 
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },   
        getParentLists(listType, parent_id, isChanged) {
            if(listType == 2 && isChanged) this.producerForm.policy_branch_id = '-1';
            if(listType == 4 && isChanged) this.coverageForm.coverage_detail_id = '-1';
            const params = {
                session_id: this.$getUser().session_id,
                list_type: listType,
                parent_id: parent_id
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                if(listType == 2) {
                    this.policyBranchList = response.data;
                    this.policyBranchList.unshift({ id: '-1', text: (this.$parent.form.supplier_account_type == 2 ? 'ALL' : 'Select an option')});
                    this.getPolicyGroupList();
                }
                if(listType == 4) {
                    this.coverageDetailList = response.data;
                    this.coverageDetailList.unshift({ id: '-1', text: 'Select an option'});
                    this.coverageDetailList.forEach(element => {
                        if(element.id !== '-1') {
                            element.text = element.code + ' - ' + element.text;
                        }
                    });            
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getPolicyGroupList(isChanged) {
            if(isChanged) this.producerForm.policy_group_id = '-1';
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 14,
                parent_id: this.producerForm.policy_branch_id
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => {   
                this.policyGroupList = response.data;
                this.policyGroupList.unshift({ id: '-1', text: 'Select an option'});
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        addProducerSetting(){
            this.$checkSelect2Validation('producerPolicyModal');
            if(!document.forms['producerPolicyModal'].reportValidity()){
                return;
            }
            if (this.producerForm.policy_branch_id == '-1' || this.producerForm.policy_branch_id == null) {
                this.$swal("Failed", " Policy Branch must be selected..!", 'warning' );
                return;
            }
            this.isItemSaving = true;
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_transaction_id: this.producerForm.policy_transaction_id,
                parent_account_id: this.producerForm.supplier_producer_id,
                main_branch_id: this.producerForm.main_branch_id == '-1' ? null : this.producerForm.main_branch_id,
                policy_branch_id: this.producerForm.policy_branch_id == '-1' ? null : this.producerForm.policy_branch_id,
                producer_policy_code: this.producerForm.producer_policy_code,
                producer_policy_name: this.producerForm.producer_policy_name,
                policy_group_id: this.producerForm.policy_group_id == '-1' ? null : this.producerForm.policy_group_id,
                search_title: this.producerForm.search_title
            };
            const that = this;
            axios.post('/api/SupplierAccount/AddProducerItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    that.$refs.closePolicyProducerModal.click();
                    this.$parent.getTransactionRowById(this.producerForm.policy_transaction_id,2);
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        editProducerSetting(){
            this.$checkSelect2Validation('producerPolicyModal');
            if(!document.forms['producerPolicyModal'].reportValidity()){
                return;
            }
            if (this.producerForm.policy_branch_id == '-1' || this.producerForm.policy_branch_id == null) {
                this.$swal("Failed", " Policy Branch must be selected..!", 'warning' );
                return;
            }
            this.isItemSaving = true;
            const parameters = {
                id: this.producerForm.policy_producer_type_id,
                policy_transaction_id: this.producerForm.policy_transaction_id,
                session_id: this.$getUser().session_id,
                parent_account_id: this.producerForm.supplier_producer_id,
                main_branch_id: this.producerForm.main_branch_id == '-1' ? null : this.producerForm.main_branch_id,
                policy_branch_id: this.producerForm.policy_branch_id == '-1' ? null : this.producerForm.policy_branch_id,
                producer_policy_code: this.producerForm.producer_policy_code,
                producer_policy_name: this.producerForm.producer_policy_name,
                policy_group_id: this.producerForm.policy_group_id == '-1' ? null : this.producerForm.policy_group_id,
                search_title: this.producerForm.search_title
            };
            const that = this;
            axios.post('/api/SupplierAccount/EditProducerItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    const quickUpdateParameters = {
                        policy_producer_policy_type_code: this.producerForm.producer_policy_code,
                        policy_producer_policy_type_name: this.producerForm.producer_policy_name,
                        session_id: this.$getUser().session_id,
                    };
                    axios.post('/api/DLLTemplates/QuickUpdateTempTransaction', quickUpdateParameters, {'Content-Type': 'application/json'})
                    .then(() => {
                        that.isItemSaving = false;
                        that.$refs.closePolicyProducerModal.click();
                        this.$parent.getTransactionRowById(this.producerForm.policy_transaction_id,2);
                    }) 
                    .catch(function () {
                        setTimeout(() => {
                            that.isItemSaving = false;
                        }, 200);
                    });
                }            
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
        onCloseModal(){
            this.$parent.isViewProducerPolicyTypeModal = false;
        },
    }
}
</script>