<template>
    <div>
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content"  >
                <div class="modal-header bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">{{ $t('Add Vendor') }}</h2>
                    <div class="btn btn-icon btn-sm"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionVendorModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-3" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="card">
                        <div class="card-body">
                            <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                <div class="fv-row mb-6 row">   
                                    <div class="col-lg-12">
                                        <div class="fv-row">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                {{ $t('Vendor') }}
                                            </label> 
                                            <Select2 v-model="form.vendor_id" :options="vendorSelectionList"
                                                name="vendor" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_transaction_vendor .modal-content' }"/> 
                                        </div>
                                    </div>
                                    <div class="col-lg-12" v-if="form.vendor_id !== '-1' && form.vendor_id !== '' && form.vendor_id !== null">
                                        <small class="text-muted fw-bold"> 
                                            <i class="bi bi-info-circle text-muted fs-7"></i> {{ $t('Commission rates must be defined to add this vendor account to the transaction...') }}.
                                        </small>
                                    </div>
                                </div> 
                            </form>

                            <div class="d-flex flex-stack flex-wrap mb-5">
                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.addVendorToTempTransaction()"
                                        :disabled="form.vendor_id == '-1' || form.vendor_id == '' || form.vendor_id == null">
                                        <span class="svg-icon svg-icon-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                    fill="currentColor">
                                                </rect>
                                            </svg>
                                        </span>
                                        {{ $t('Add') }}
                                    </button>
                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="removeVendorToTempTransactionAccount()" 
                                        :disabled="isVendorButtonDisabled == null || isVendorButtonDisabled == undefined">
                                        <span class="svg-icon svg-icon-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        {{ $t('Remove') }}
                                    </button>
                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="removeVendorToTempTransactionAccount(true)" 
                                        :disabled="this.transactionVendorList.length == 0">
                                        <span class="svg-icon svg-icon-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        {{ $t('Remove All (!)') }}
                                    </button>
                                </div>
                            </div>
                            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive">
                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="transactionVendorList" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true"
                                            @row-updating="editVendorToTempTransaction" > 

                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="false"/>
                                            <DxPaging :page-size="100000"/>
                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                            
                                            <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                                            <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template" :allowEditing="false"/> 
                                            <DxColumn data-field="commission_type_name" :caption="$t('Commission Type')" alignment="left" cell-template="commission_type-cell-template"/> 
                                            <DxColumn data-field="commission_rate" :sorting-method="this.$numericSort" :caption="$t('Commission Rate')" alignment="right" cell-template="commission_rate-cell-template"/> 
                                            <DxColumn data-field="commission_amount" :sorting-method="this.$numericSort" :caption="$t('Commission Amount')" alignment="right" cell-template="commission_amount-cell-template"/> 
                                            <DxEditing :allow-updating="false" mode="row" /> 
                                            
                                            <template #is-selected-cell-template="{ data }"> 
                                                <span>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.$parent.onSelectItem(transactionVendorList, data.data)" />
                                                    </div>
                                                </span>
                                            </template>
                                            <template #vendor_name-cell-template="{ data }"> 
                                                <span>
                                                    {{ data.data.vendor_name }}
                                                </span>
                                            </template>
                                            <template #commission_type-cell-template="{ data }"> 
                                                <span>
                                                    {{ data.data.commission_type_name }}
                                                </span>
                                            </template>
                                            <template #commission_rate-cell-template="{ data }"> 
                                                <span>
                                                    {{ data.data.commission_rate }}
                                                </span>
                                            </template>
                                            <template #commission_amount-cell-template="{ data }"> 
                                                <span>
                                                    {{ data.data.commission_amount }}
                                                </span>
                                            </template>

                                            <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                <DxTotalItem
                                                    name="commission_amount"
                                                    summary-type="custom"
                                                    show-in-column="commission_amount"
                                                    display-format="Total:  {0}"
                                                />
                                            </DxSummary>
                                    </DxDataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer pt-0 mt-0 row" style="border-top: 0px !important;">
                    <div class="col-lg-12">
                        <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-primary btn-lg" @click="saveTransactionVendors()">
                                <span class="indicator-label">{{ $t('Update') }}</span> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary, DxEditing } from 'devextreme-vue/data-grid';
export default {
    name:'TransactionVendorModal',
    data() {
        return {
            form: {
                policy_transaction_id: null,
                vendor_id: '-1'
            },
            vendorSelectionList: [],
            transactionVendorList: [],
            is_default: true
        }
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary, DxEditing
    },
    computed: {
        isVendorButtonDisabled() {
            return this.transactionVendorList.find(item => item.isSelected == true && item.id !== '' );
        }
    },
    methods:{ 
        getData(policyTransactionId){
            if(!this.$parent.isViewVendorModal) return;
            this.$removeSessionStorage('vn_tmp_session_id');
            this.form = {
                policy_transaction_id: policyTransactionId,
                vendor_id: '-1'
            };
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_transaction_id: this.form.policy_transaction_id
            };            
            const requestOne =  axios.post('/api/GetCompanyAccounts/GetTrnVendorAccountsForSelection', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/DLLTemplates/GetVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.vendorSelectionList = responses[0].data;
                this.transactionVendorList = responses[1].data;
                this.vendorSelectionList.unshift({ id: '-1', text: 'Select an option'});
                if(this.transactionVendorList.length > 0){ this.$setSessionStorage('vn_tmp_session_id', responses[1].data[0].tmp_session_id); }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        addVendorToTempTransaction() {
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('vn_tmp_session_id'),
                policy_transaction_id: this.form.policy_transaction_id,
                vendor_id: this.form.vendor_id !== '-1' ? this.form.vendor_id : null
            };  
            axios.post('/api/DLLTemplates/AddVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.transactionVendorList = response.data;
                    if(this.transactionVendorList.length > 0){ this.$setSessionStorage('vn_tmp_session_id', response.data[0].tmp_session_id); }
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        editVendorToTempTransaction(e) {
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_transaction_id: this.form.policy_transaction_id,
                tmp_session_id: this.$getSessionStorage('vn_tmp_session_id'),
                id: e.key,
                commission_type: e.newData.commission_type ? e.newData.commission_type : 1,
                commission_amount: e.newData.commission_amount ? e.newData.commission_amount : 0,
                commission_rate: e.newData.commission_rate ? e.newData.commission_rate : 0,
            };
            axios.post('/api/DLLTemplates/EditVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'})
            .then((response) => {               
                this.transactionVendorList = response.data;  
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        removeVendorToTempTransactionAccount(isDeleteAll) {
            let vendorId = null;
            this.transactionVendorList.forEach((item) => {
                if (item.isSelected) {
                    vendorId = item.id;
                }
            });
            this.$swal.fire({
                title: "Are you sure you want to remove this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        id: vendorId,
                        is_delete_all: isDeleteAll ? 1 : 0,
                        tmp_session_id: this.$getSessionStorage('vn_tmp_session_id'),
                    }; 
                    axios.post('/api/DLLTemplates/RemoveVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        this.transactionVendorList = response.data;
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        saveTransactionVendors() {
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('vn_tmp_session_id'),
                policy_transaction_id: this.form.policy_transaction_id
            };  
            axios.post('/api/DLLTemplates/SaveTransactionVendors', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.transactionVendorList = response.data;
                    this.$refs.closeTransactionVendorModal.click();
                    this.$parent.getTransactionRowById(this.form.policy_transaction_id, 1);
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        setDetailSummaries(options){  
            if(options.name == "commission_amount"){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var commissionAmountLocalValue = parseFloat(options.value.commission_amount.replaceAll(',', ''));
                    (options.totalValue += commissionAmountLocalValue);
                }
                else if(options.summaryProcess === 'finalize'){ options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue); }
            }
        },
        onCloseModal() {
            this.$parent.isViewVendorModal = false;
        }
    }
}
</script>