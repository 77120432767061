<template>
    <div>
        <div class="card card-xxl-stretch card-bordered">
            <div class="card-header">
                    <div class="card-title">
                        {{ $t('Account Factor List') }}
                    </div>
                </div>
            <div class="card-body">
                <div class="d-flex flex-stack flex-wrap mb-5">
                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.openFactorModal('add')" data-bs-toggle="modal" data-bs-target="#kt_modal_add_factor">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                        fill="currentColor">
                                    </rect>
                                </svg>
                            </span>
                            {{ $t('Add New') }}
                        </button>
                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" @click="this.openFactorModal('edit')" data-bs-toggle="modal" data-bs-target="#kt_modal_add_factor"
                            :disabled="isFactorButtonDisabled == null || isFactorButtonDisabled == undefined">
                            <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                    </svg>
                            </span>
                            {{ $t('Edit') }}
                        </button>
                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="this.deleteTransactionFactorItem()" 
                            :disabled="isFactorButtonDisabled == null || isFactorButtonDisabled == undefined">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                            {{ $t('Remove') }}
                        </button>
                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="this.deleteTransactionFactorItem(true)" 
                            :disabled="this.accountFactorList.length == 0">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                            {{ $t('Remove All (!)') }}
                        </button>
                    </div>
                </div>
                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="accountFactorList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Account-factor-list')">

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>    
                                <DxSearchPanel :visible="true"/>

                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                <DxColumn data-field="factor_type_name" :caption="$t('Factor Type')" cell-template="factor_type_name-cell-template"/> 
                                <DxColumn data-field="factor_detail_name" :caption="$t('Factor Detail')" alignment="left" cell-template="factor_detail_name-cell-template"/>
                                <DxColumn data-field="registration_number" :caption="$t('ID/Plate')" alignment="left" cell-template="registration_number-cell-template"/>
                                <DxColumn data-field="name" :caption="$t('Name/Desc.')" alignment="left" cell-template="name-cell-template"/>
                                
                                <template #is-selected-cell-template="{ data }"> 
                                    <span>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectFactorItem(data.data)" />
                                        </div>
                                    </span>
                                </template>
                                <template #factor_type_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.factor_type_name }}
                                    </span>
                                </template>
                                <template #factor_detail_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.factor_detail_name }}
                                    </span>
                                </template>
                                <template #registration_number-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.registration_number }}
                                    </span>
                                </template>
                                <template #name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.name }}
                                    </span>
                                </template>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Account Factor -->
        <div class="modal fade" id="kt_modal_add_factor" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AddAccountFactorModal ref="AddAccountFactorModal" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import AddAccountFactorModal from "../Modals/AddAccountFactorModal.vue";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
export default {
    name: 'AccountFactors',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling, AddAccountFactorModal
    },
    data() {
        return {
            pageSize: 20,
            accountFactorList: [],
            form: this.$parent.form,
        }
    },
    computed:{
        isFactorButtonDisabled() {
            return this.accountFactorList.find(item => item.isSelected == true ); 
        },
        showPageSizeSelector() {
            return this.accountFactorList.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = this.accountFactorList.length / this.pageSize;
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(1000);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    methods: {        
        openFactorModal(modalType) {
            this.$refs.AddAccountFactorModal.getData(modalType);
        },
        getData() {
            const parameters = {
                session_id: this.$getUser().session_id,
                account_id: this.$parent.form.id
            };  
            axios.post('/api/GetCompanyAccounts/GetFactors', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.accountFactorList = response.data;
            }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSelectFactorItem(item) {
            this.accountFactorList.forEach((value) => { 
                if (item.id !== value.id) {
                    value.isSelected = false;
                }
            });
        },
    deleteTransactionFactorItem(isDeleteAll){
        let factorId = null;
        let factorType = null;
        this.accountFactorList.forEach((item) => {
            if (item.isSelected) {
                factorId = item.id;
                factorType= item.factor_type
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    factor_id: factorId,
                    session_id: this.$getUser().session_id,
                    account_id: this.$parent.form.id,
                    is_delete_all: isDeleteAll ? 1 : 0,
                    factor_type: factorType,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('/api/Insurance/DeleteAccountFactorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.accountFactorList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    },
    created() {
        this.getData();
    }
}
</script>