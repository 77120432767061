import axios from "axios"; 
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });

const state = {};

const getters = { 
};

const actions = {   
  async LogIn({commit}, user) {   
    const params = {
      username: user.get("username"),
      password: user.get("password")
    };
    await axios.post('/api/Auth/AuthUser', params, {'Content-Type': 'application/json'})
    .then((response) => {     
        if(response.data.id != null && response.data.id.length == 36){
          response.data.company_name = this.$unlock(response.data.company_name);
          response.data.company_vkn = this.$unlock(response.data.company_vkn);
          response.data.name_surname = this.$unlock(response.data.name_surname);
          response.data.user_mail = this.$unlock(response.data.user_mail);
          response.data.company_country_code = this.$unlock(response.data.company_country_code);
          response.data.phone_country_code = this.$unlock(response.data.phone_country_code);
          if(response.data.stripe) {
            response.data.stripe.oid = this.$unlock(response.data.stripe.oid);
            response.data.stripe.mid = this.$unlock(response.data.stripe.mid);
            response.data.stripe.strpk = this.$unlock(response.data.stripe.strpk);
            response.data.stripe.psurl = this.$unlock(response.data.stripe.psurl);
            response.data.stripe.flurl = this.$unlock(response.data.stripe.flurl);
          }
          ls.set('user_' + response.data.session_id, response.data); 
          sessionStorage.setItem('activeYear', response.data.period_id);
          window.location.href= "/";
        }
        else{ 
          commit("removeUser");
          this.$swal("Login failed.", "User not found..!", 'error');
          sessionStorage.clear();
          return;
        }
    })
    .catch(function (error) { 
        console.log(error);
    }); 
  }
};

const mutations = {  
  removeUser() { 
    sessionStorage.clear();
  }
};   
export default {
  state,
  getters,
  actions,
  mutations
};
