
<template>
    <div class="modal-dialog modal-dialog-centered modal-xxl-90" >
        <div class="modal-content"  >
            <div class="modal-header bg-primary py-3">
                <div class="modal-title col-md-10 text-white">
                    <h3 class="fw-bold text-white ms-5" v-if="this.selectedAccountName !== null">
                        <span class="text-white fw-boldest">{{this.selectedAccountName}}</span>
                        <span v-if="this.modalForm.report_selection == 1"> {{ $t(' \'s Statements') }} </span> 
                        <span v-if="this.modalForm.report_selection == 2"> {{ $t(' \'s Statement Report') }} </span> 
                    </h3>  
                </div>
                <div class="btn btn-icon btn-sm"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" :class="{'min-h-700px': isViewReportDesign, 'overlay overlay-block': isViewReportDesign && this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')}">
                <div class="row"> 
                    <div class="card p-2">
                        <div class="card-body p-3">
                            <div class="row fv-row " v-if="!isViewReportDesign">
                                <div class="col-lg-8">
                                    <div class="fv-row row"> 
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Report Type') }} </span>
                                            </label>
                                            <Select2 v-model="this.modalForm.report_selection" :options="this.reportSelectionFilteredList" :settings="{ width: '100%', dropdownParent: '#kt_modal_stock_account_report_selection' }"/>  
                                        </div>
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Start Date') }}</span>
                                            </label>
                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.start_date" inputClassName="form-control"
                                                format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                        </div>  
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('End Date') }}</span>
                                            </label>
                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.end_date" inputClassName="form-control"
                                                format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                        </div>
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Currency') }}</span>
                                            </label>
                                            <Select2 v-model="this.modalForm.currency" :options="this.currencyList" :settings="{ width: '100%', dropdownParent: '#kt_modal_stock_account_report_selection' }"/>  
                                        </div>
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('View Type') }}</span>
                                            </label>
                                            <Select2 v-model="this.modalForm.view_type" :options="this.viewTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_stock_account_report_selection' }"/>  
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 mt-lg-10">
                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">  
                                        <Popper hover placement="top">
                                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setModalCriteriasToDefault()">
                                                <i class="bi bi-eraser-fill fs-4"> </i>
                                            </button>
                                            <template #content>
                                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                            </template>
                                        </Popper>
                                        <button type="button" class="btn btn-light-primary border border-primary" @click="onGetReportByType()" data-kt-indicator="on">
                                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                            </svg>
                                            </span> 
                                            <span v-if="!this.isListProgressing"> 
                                                <span v-if="modalForm.report_selection == 2">{{ $t('View Report') }}</span>
                                                <span v-else>List Transactions</span>
                                            </span> 
                                            <span class="indicator-progress" v-if="this.isListProgressing">
                                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                        </button>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <!-- Statement Report -->
                        <div v-if="modalForm.report_selection == 2 && isViewReportDesign" class="card-body p-2">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-5">
                                        <Select2 v-model="designSelection" :options="designList" @select="onChangeReport($event)" :settings="{ width: '300px', dropdownParent: '#kt_modal_stock_account_report_selection .modal-content' }"/>  
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="alert alert-warning d-flex align-items-center p-2" v-if="designDescription !== '' && designDescription !== null && designDescription !== undefined">
                                        <span class="svg-icon svg-icon-muted svg-icon-2x">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                            <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                            <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column ms-3">
                                            {{designDescription}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                        <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3" @click="onBackStep()">
                                            <span class="ms-2 fs-7"> {{ $t('Return back to Criterias') }}</span>
                                        </button>
                                        <button type="button" class="btn btn-primary" @click="downloadStatement()">
                                            <i class="fa fa-file fs-5"></i> {{ $t('Download PDF') }}
                                        </button>
                                    </div> 
                                </div>
                            </div>
                            <div class="alert alert-warning d-flex align-items-center" v-if="this.designSelection == '-1' || this.designSelection == null || this.designSelection == '' || this.designSelection == undefined">
                                <span class="svg-icon svg-icon-muted svg-icon-2x">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div class="d-flex flex-column ms-3">
                                    {{ $t('No default report template specified') }}!
                                </div>
                            </div>
                            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'https://localhost:5001/Home/Viewer?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + '&report_type=' + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date" 
                                    style="width:100% !important; height: 100% !important;min-height:700px;"
                                    v-if="this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                            </iframe>

                            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'/reports/Home/Viewer?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + '&report_type=' + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date" 
                                    style="width:100% !important; height: 100% !important;min-height:700px;"
                                    v-if="!this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                            </iframe>
                            
                        </div>  

                        <!-- Statement List -->
                        <div v-if="modalForm.report_selection == 1" class="card-body p-2">
                            <div class="separator mb-5"></div>
                            <div class="table-responsive min-h-300px">
                                <DxDataGrid id="gridContainer"
                                    :show-borders="true"
                                    :data-source="detailList"
                                    :customize-columns="onCustomizeColumn"
                                    :allow-column-reordering="true" 
                                    :allow-column-resizing="true"
                                    :column-auto-width="true"
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    :ref="'dataGrid'"
                                    @exporting="this.$onExporting($event , 'Stock-Account-Detail')" >   

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="100"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes(detailList, 100)" :show-page-size-selector="showPageSizeSelector(detailList, 100)" :show-info="true" :show-navigation-buttons="true"/>    
                                    <DxSearchPanel :visible="true"/>

                                    <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trns. Date')" header-cell-template="transaction-date-header" cell-template="transaction_date-cell-template"/>
                                    <DxColumn data-field="transaction_type_name" :caption="$t('Trns. Type')" header-cell-template="transaction-type-header" cell-template="transaction_type_name-cell-template"/> 
                                    <DxColumn data-field="invoice_type_name" :caption="$t('Sub Type')" header-cell-template="invoice-type-header" cell-template="invoice_type_name-cell-template"/> 
                                    <DxColumn data-field="transaction_no" :caption="$t('Trns. No')" header-cell-template="transaction-no-header" cell-template="transaction_no-cell-template"/>
                                    <DxColumn data-field="in_out_name" :caption="$t('In / Out')" header-cell-template="inout-type-header" cell-template="in_out_name-cell-template"/>  
                                    <DxColumn data-field="currency" :caption="$t('Cur.')" cell-template="currency-cell-template"/>
                                    <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Qty')"  alignment="right" cell-template="quantity1-cell-template"/>
                                    <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" cell-template="price-cell-template"/>
                                    <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total Price')" alignment="right" header-cell-template="total-price-header" cell-template="total_price-cell-template"/>
                                    <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount')" alignment="right" cell-template="discount_amount-cell-template"/>
                                    <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Price')" alignment="right" header-cell-template="net-price-header" cell-template="net_price-cell-template"/>
                                    <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('Tax')" alignment="right" cell-template="tax_amount-cell-template"/> 
                                    <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Gross Amount')" alignment="right" header-cell-template="gross-amount-header" cell-template="gross_amount-cell-template"/>

                                    <DxColumn :visible="modalForm.view_type == 3" data-field="cost" :caption="$t('Cost')" alignment="right" cell-template="cost-cell-template"/>
                                    <DxColumn :visible="modalForm.view_type == 3" data-field="profit" :caption="$t('Profit')" alignment="right" cell-template="profit-cell-template"/>
                                    
                                    <DxColumn :visible="modalForm.view_type == 2" data-field="rate" :sorting-method="this.$numericSort" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>
                                    <DxColumn :visible="modalForm.view_type == 2" data-field="price_local" :sorting-method="this.$numericSort" :caption="$t('Price Local')" alignment="right" header-cell-template="price-local-header" cell-template="price_local-cell-template"/>
                                    <DxColumn :visible="modalForm.view_type == 2" data-field="total_price_local" :sorting-method="this.$numericSort" :caption="$t('Local Total Price')" alignment="right" header-cell-template="total-price-local-header" cell-template="total_price_local-cell-template"/>
                                    <DxColumn :visible="modalForm.view_type == 2" data-field="tax_amount_local" :sorting-method="this.$numericSort" :caption="$t('Tax Amt')" alignment="right" header-cell-template="tax-amount-local-header" cell-template="tax_amount_local-cell-template"/>
                                    <DxColumn :visible="modalForm.view_type == 2" data-field="gross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Gross Amout Local')" alignment="right"  header-cell-template="gross-amount-local-header" cell-template="gross_amount_local-cell-template"/>
                                    <DxColumn data-field="tax_rate" :caption="$t('Tax Rate')" alignment="right" header-cell-template="tax-rate-header" cell-template="tax_rate-cell-template"/> 
                                    <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" :caption="$t('Record Date')" cell-template="record_date-cell-template"/>
                                    <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/>

                                    
                                    <template #headerLocalTemplate="{ data: data }">
                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                    </template>

                                    <template #record-date-header>
                                        <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #tax-rate-header>
                                        <div>{{ $t('Tax') }}</div><div>{{ $t('Rate') }}</div>
                                    </template>
                                    <template #transaction-type-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #invoice-type-header>
                                        <div>{{ $t('Sub') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #inout-type-header>
                                        <div>{{ $t('In') }}</div><div>{{ $t('Out') }}</div>
                                    </template>
                                    <template #transaction-no-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #transaction-date-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #option-date-header>
                                        <div>{{ $t('Option') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #total-price-local-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Total') }}</div>
                                    </template>
                                    <template #tax-amount-local-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Tax') }}</div>
                                    </template>
                                    <template #gross-amount-header>
                                        <div>{{ $t('Gross') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #gross-amount-local-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Gross Amount') }}</div>
                                    </template>
                                    <template #net-price-header>
                                        <div>{{ $t('Net') }}</div><div>{{ $t('Price') }}</div>
                                    </template>
                                    <template #total-price-header>
                                        <div>{{ $t('Total') }}</div><div>{{ $t('Price') }}</div>
                                    </template>
                                    <template #price-local-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Price') }}</div>
                                    </template>

                                    <!-- Cell Templates --> 
                                    <template #transaction_date-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ previewDate(data.data.transaction_date) }}
                                        </span>
                                    </template>
                                    <template #transaction_type_name-cell-template="{ data }"> 
                                        <span class="align-middle" :class="{ 'text-primary': data.data.transaction_type == '11', 'text-info': data.data.transaction_type == '12'}">
                                            {{ data.data.transaction_type_name }}
                                        </span>
                                    </template>
                                    <template #invoice_type_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.invoice_type_name }}
                                        </span>
                                    </template>
                                    <template #transaction_no-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.transaction_no }}
                                        </span>
                                    </template>
                                    <template #in_out_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.in_out_name }}
                                        </span>
                                    </template>
                                    <template #quantity1-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.quantity1 }}
                                        </span>
                                    </template>
                                    <template #price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.price }}
                                        </span>
                                    </template>
                                    <template #total_price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.total_price }}
                                        </span>
                                    </template>
                                    <template #discount_amount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.discount_amount }}
                                        </span>
                                    </template>
                                    <template #net_price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.net_price }}
                                        </span>
                                    </template>
                                    <template #gross_amount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.gross_amount }}
                                        </span>
                                    </template>
                                    <template #price_local-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.price_local }}
                                        </span>
                                    </template>
                                    <template #total_price_local-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.total_price_local }}
                                        </span>
                                    </template>
                                    <template #currency-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.currency }}
                                        </span>
                                    </template>
                                    <template #rate-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.rate }}
                                        </span>
                                    </template>
                                    <template #tax_amount-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.tax_amount }}
                                        </span>
                                    </template>
                                    <template #tax_amount_local-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.tax_amount_local }}
                                        </span>
                                    </template>
                                    <template #tax_rate-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.tax_rate }}
                                        </span>
                                    </template>
                                    <template #gross_amount_local-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.gross_amount_local }}
                                        </span>
                                    </template>
                                    <template #cost-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.cost }}
                                        </span>
                                    </template>
                                    <template #profit-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.profit }}
                                        </span>
                                    </template>
                                    <template #record_date-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ previewDate(data.data.record_date) }} 
                                        </span>
                                    </template>
                                    <template #description-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.description }}
                                        </span>
                                    </template>

                                    <DxSummary :calculate-custom-summary="setDetailSummaries">
                                        <DxTotalItem
                                            name="gross_amount"
                                            summary-type="custom"
                                            show-in-column="gross_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="gross_amount_local"
                                            summary-type="custom"
                                            show-in-column="gross_amount_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="quantity1"
                                            summary-type="custom"
                                            show-in-column="quantity1"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="discount_amount"
                                            summary-type="custom"
                                            show-in-column="discount_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="tax_amount"
                                            summary-type="custom"
                                            show-in-column="tax_amount"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="tax_amount_local"
                                            summary-type="custom"
                                            show-in-column="tax_amount_local"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="total_price"
                                            summary-type="custom"
                                            show-in-column="total_price"
                                            display-format="{0}"
                                        />
                                        <DxTotalItem
                                            name="total_price_local"
                                            summary-type="custom"
                                            show-in-column="total_price_local"
                                            display-format="{0}"
                                        />
                                    </DxSummary>

                                    <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                                </DxDataGrid>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading...') }}</span>
                    </div>
                    <span class="d-flex text-primary ms-5">{{ $t('Document loading. Please wait') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring } from 'devextreme-vue/data-grid';
export default {
  name: "StockAccountReports",
  props: {
    accountName: String,
    accountId: String
  },
  components: {
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring
  },
  data() {
    return { 
        localCurrency: this.$getUser().currency_code,
        id: this.$getSessionStorage('record_id'),
        designSelection: '-1',
        designDescription: null,
        designList: [{id: '-1', text: 'Select an option'}],
        is_local: false,
        report_type: 4, //Stock Statement
        modalForm: {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            currency: '-1',
            report_selection: 1,
            view_type: 1
        },
        isViewReportDesign: false,
        isFrameLoading: false,
        currencyList: [],
        balanceList: [],
        detailList: [],
        reportSelectionList: [
            {id: 1, text: 'Statement List'},
            {id: 2, text: 'Statement Report'}
        ],
        viewTypeList: [
            {id: 1, text: 'Local Currency'},
            {id: 2, text: 'Currency Detail'},
            {id: 3, text: 'Cost Detail'},
        ],
        selectedAccountId: null,
        selectedAccountName: null,
        summary: {
            total_average_due_state: 0,
            total_average_due_date: null
        }
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    previewDate(){
        return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        }
    },
    showPageSizeSelector() {
        return(list, pageSize) => {
            return list.length > pageSize;
        }
    },
    pageSizes() {
        return(list, pageSize) => {
            let arr = [];
            let x = 1;
            let loop = (list.length / pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(1000);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    reportSelectionFilteredList() {
        if(!this.$isCredentialActive(16, 'P')) {
            return this.reportSelectionList.filter(p => p.id != 2);
        }    
        return this.reportSelectionList;
    }
  },
  methods: {
    getData(accountInfo) {
        this.detailList = [];
        this.selectedAccountId = this.accountId;
        this.selectedAccountName = this.accountName;
        this.localCurrency = this.$getUser().currency_code;
        this.modalForm.currency = this.$getUser().currency_code;
        this.modalForm.view_type = 1;
        if(accountInfo) {
            this.selectedAccountId = accountInfo.id;
            this.selectedAccountName = accountInfo.name;
        }
        this.isViewReportDesign = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.selectedAccountId
        };
        const parametersThree = {
            session_id: this.$getUser().session_id,
            category: this.report_type,
            account_id: this.selectedAccountId,
            view_option: (this.localCurrency == this.modalForm.currency) ? 1 : 2
        };
        if(this.modalForm.currency == '-1' || this.modalForm.currency == null || this.modalForm.currency == '') {
            parametersThree.view_option = 0;
        }
        const requestOne =  axios.post('/api/Helper/GetStockStatementCurrencies', parameters, {'Content-Type': 'application/json'}); 
        const requestTwo =  axios.post('/api/Reports/GetControllerReportDesigns', parametersThree, {'Content-Type': 'application/json'});         
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {       
            this.currencyList = responses[0].data;
            this.modalForm.currency = this.currencyList.length > 0 ? this.currencyList[0].id : null;
            if(this.currencyList.length == 0) { this.currencyList.unshift({id: '-1', text: 'Select an option'}); }
            this.currencyList.forEach(element => {
                if(element.id == this.$getUser().currency_code) {
                    this.modalForm.currency = element.id;
                }
            });

            this.designList = responses[1].data.report_designs;
            this.designSelection = !responses[1].data.default_report_design ? '-1' : responses[1].data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.designDescription = item.description;
                    this.report_type = item.category;
                }
            });
            this.designList.unshift({id: '-1', text: 'Select an option'});
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){ this.is_local = true; }
            else{ this.is_local = false; }

            this.getStatementList();
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    onGetReportByType() {
        if(this.modalForm.report_selection == 1) {
            this.getStatementList();
        } else {
            this.getStatementReport();
        }
    },
    getStatementList(){ 
        this.isViewReportDesign = false;
        this.detailList = [];
        const params = {
            session_id: this.$getUser().session_id,
            account_id: this.selectedAccountId,
            currency: this.modalForm.currency,
            start_date: moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };
        params.currency = this.modalForm.currency == '-1' ? null : this.modalForm.currency;
        axios.post('/api/GetAccountsDetails/GetStockAccountDetailTransactions', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.detailList = response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getStatementReport () {
        this.isFrameLoading = true;
        if(this.modalForm.currency == '-1') {this.modalForm.currency = '';}        
        this.modalForm.start_date = moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.modalForm.end_date = moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.isViewReportDesign = true;
        this.saveLogs(1);
    },
    saveLogs(viewType) {
        const parameters = {
            log_type: 7,
            log_location: 99,
            session_id: this.$getUser().session_id,
            log_location_detail: null,
            log_operation: viewType == 1 ? 22 : 19,
            document_no: null,
            account_id: null,
            description: this.selectedAccountName + ' Statement ' + (viewType == 1 ? 'viewed.' : 'downloaded.'),
            criteria_data: moment(this.modalForm.start_date).format('YYYY-MM-DD') + ' ;' + moment(this.modalForm.end_date).format('YYYY-MM-DD') + ' ;' + (this.modalForm.currency !== '-1' ? this.modalForm.currency : 'ALL Currencies')
        };  
        axios.post('/api/GetCompanyAccounts/SaveLogs', parameters, {'Content-Type': 'application/json'});
    },
    getReportTemplates() { 
        const parameters = {
            session_id: this.$getUser().session_id,
            category: this.report_type,
            account_id: this.selectedAccountId,
            view_option: (this.localCurrency == this.modalForm.currency) ? 1 : 2
        };
        if(this.modalForm.currency == '-1' || this.modalForm.currency == null || this.modalForm.currency == '') {
            parameters.view_option = 0;
        }        
        const requestOne =  axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'});        
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.designList = responses[0].data.report_designs;
            this.designSelection = !responses[0].data.default_report_design ? '-1' : responses[0].data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.designDescription = item.description;
                    this.report_type = item.category;
                }
            });
            this.designList.unshift({id: '-1', text: 'Select an option'});
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){ this.is_local = true; }
            else{ this.is_local = false; }
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    onChangeReport(event) {
        this.isFrameLoading = true;
        this.designSelection = event.id;
        this.designDescription = event.description;
        this.report_type = event.category;
    },
    onCloseModal(){
        this.$parent.isViewStatementModal = false;
        this.$parent.isViewAddTurnoverModal = true;
    },
    setModalCriteriasToDefault() {
        this.modalForm = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            currency: '-1',
            report_selection: 1,
            view_type: 1
        };
        this.isViewReportDesign = false;
        this.currencyList.forEach(element => {
            if(element.id == this.$getUser().currency_code) {
                this.modalForm.currency = element.id;
            }
        });
    },
    onBackStep() {
        this.isFrameLoading = false;
        if(this.modalForm.currency == null || this.modalForm.currency == '') this.modalForm.currency = '-1';
        this.isViewReportDesign = false;
    },
    downloadStatement(){
        if(this.modalForm.currency == '-1') {this.modalForm.currency = '';}
        this.modalForm.start_date = moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.modalForm.end_date = moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

        if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        {
            fetch('https://localhost:5001/Home/Downloader?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + "&report_type=" + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.selectedAccountName + ' Statement' + '.pdf');
                    this.saveLogs(2);
                });
        }
        else{
            fetch('/reports/Home/Downloader?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + "&report_type=" + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.selectedAccountName + ' Statement' + '.pdf');
                    this.saveLogs(2);
                });
        } 
    },
    iframeloadhelper() {
      this.isFrameLoading = false;
    },
    setDetailSummaries(options){  
        const optionNames = ["quantity1", "price_local", "gross_amount", "gross_amount_local", "tax_amount", "tax_amount_local", "discount_amount", "total_price", "total_price_local"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                    if(column.dataField == 'gross_amount_local'){ 
                        column.caption =  'Local Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'price_local'){ 
                        column.caption =  'Local Price';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'total_price_local'){ 
                        column.caption =  'Local Total Price';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'tax_amount_local'){ 
                        column.caption =  'Local Tax';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
  }
};
</script>