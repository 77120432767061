<template> 
    <div>        
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Accounting Integration') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'RecalculationOperations' }">{{ $t('Recalculation Operations') }}</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">{{ $t('Accounting Integration') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/RecalculationOperations">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Recalculation Operations') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <div class="card">
                <div class="row fv-row border-0 pt-6 px-9">
                    <div class="col-lg-8">
                        <div class="fv-row mb-4 row"> 
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('Accounting Period') }}</span> 
                                </label>   
                                <Select2 v-model="form.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountingYear($event)"/>  
                            </div>
                            <div class="col-lg">
                                <label class="fs-6 form-label mt-3">
                                    <span>{{ $t('Start Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date"></Datepicker>  
                            </div>  
                            <div class="col-lg">
                                <label class="fs-6 form-label mt-3">
                                    <span>{{ $t('End Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date"></Datepicker>  
                            </div>   
                            <div class="col-lg">
                                <label class="fs-6 form-label mt-3">
                                    <span>{{ $t('Integration Type') }}</span>
                                </label>
                                <Select2 v-model="this.form.integration_type" :options="this.integrationTypeList" :settings="{ width: '100%' }" @select="lists = []"/>  
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-6">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">  
                            <Popper hover placement="top">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button type="button" class="btn btn-light-primary border border-primary me-3" @click="getListData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted">
                                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                    </svg>
                                </span> 
                                <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span>
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                            <button type="button" class="btn btn-light-success border border-success" v-if="form.integration_type == 1" @click="onStartIntegration()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                    <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <span v-if="!this.isProgressing"> {{ $t('Start Integration') }} </span> 
                                <span class="indicator-progress" v-if="this.isProgressing">
                                    {{ $t('Progressing...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                            <button type="button" class="btn btn-light-danger border border-danger" v-if="form.integration_type == 2" @click="onCancelIntegration()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.8 15.8C7.3 15.7 7.9 16 8 16.5C8.2 17.4 8.99999 18 9.89999 18H17.9C19 18 19.9 17.1 19.9 16V8C19.9 6.9 19 6 17.9 6H9.89999C8.79999 6 7.89999 6.9 7.89999 8V9.4H5.89999V8C5.89999 5.8 7.69999 4 9.89999 4H17.9C20.1 4 21.9 5.8 21.9 8V16C21.9 18.2 20.1 20 17.9 20H9.89999C8.09999 20 6.5 18.8 6 17.1C6 16.5 6.3 16 6.8 15.8Z" fill="currentColor"/>
                                    <path opacity="0.3" d="M12 9.39999H2L6.3 13.7C6.7 14.1 7.3 14.1 7.7 13.7L12 9.39999Z" fill="currentColor"/>
                                    </svg>
                                </span>
                                <span v-if="!this.isProgressing"> {{ $t('Cancel Integration') }} </span> 
                                <span class="indicator-progress" v-if="this.isProgressing">
                                    {{ $t('Progressing...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div> 
                    </div>
                </div> 
                <div class="card-body pt-3">  
                    <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">
    
                            <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                            
                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                        :data-source="lists" 
                                        :allow-column-reordering="true" 
                                        :allow-column-resizing="true" 
                                        :column-auto-width="true"  
                                        :customize-columns="onCustomizeColumn"
                                        :row-alternatin-enabled="true"
                                        :hover-state-enabled="true" 
                                        :show-row-lines="true" 
                                        :ref="'dataGrid'"
                                        @exporting="this.$onExporting($event , 'Transaction-List')">  
    
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="pageSize"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes(lists)" :show-page-size-selector="showPageSizeSelector(lists)" :show-info="true" :show-navigation-buttons="true"/>    
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                    <DxColumn data-field="id" cell-template="custom-template" caption="" :allow-filtering="false" width="35" :allow-exporting="false" :visible="isDetailVisible"/>  
                                    <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" header-cell-template="record-date-header"/>
                                    <DxColumn data-field="account_code" :caption="$t('Account Code')" header-cell-template="account-code-header"/>
                                    <DxColumn data-field="account_name" :caption="$t('Account Name')" header-cell-template="account-name-header"/>
                                    <DxColumn data-field="account_type_name" :caption="$t('Account Type')" header-cell-template="account-type-header"/>
                                    <DxColumn data-field="transaction_type_name" :caption="$t('Transaction Type')" header-cell-template="transaction-type-header"/>
                                    <DxColumn data-field="transaction_subtype_name" :caption="$t('Transaction Sub Type')" header-cell-template="transaction-subtype-header"/>
                                    <DxColumn data-field="transaction_no" :caption="$t('Transaction No')" header-cell-template="transaction-no-header"/>
                                    <DxColumn data-field="transaction_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Transaction Date')" header-cell-template="transaction-date-header"/>
                                    <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right"/>
                                    <DxColumn data-field="currency" :caption="$t('Curr.')"/>
                                    <DxColumn data-field="debit_credit" :caption="$t('Debit/Credit')" header-cell-template="debit-credit-header"/>
                                    <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right"/>
                                    <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')"  alignment="right" header-cell-template="local-amount-header"/>
                                    <DxColumn data-field="transaction_due_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Due Date')" header-cell-template="due-date-header"/> 
                                    <DxColumn data-field="description" :caption="$t('Description')"/> 
    
                                    <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                    <template #headerLocalTemplate="{ data: data }">
                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                    </template>

                                    <template #custom-template="{ data }"> 
                                        <button class="btn btn-sm btn-icon btn-secondary btn-icon-dark btn-text-dark w-20px h-20px m-0" @click="getTransactionDetail(data.data.transaction_type_id)"
                                        data-bs-toggle="modal" data-bs-target="#kt_modal_view_detail" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-muted icon-size-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                        </button>
                                    </template>  
                                    <template #record-date-header>
                                        <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #account-name-header>
                                        <div>{{ $t('Account') }}</div><div>{{ $t('Name') }}</div>
                                    </template>
                                    <template #account-code-header>
                                        <div>{{ $t('Account') }}</div><div>{{ $t('Code') }}</div>
                                    </template>
                                    <template #account-type-header>
                                        <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-type-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-subtype-header>
                                        <div>{{ $t('Transaction Sub') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-no-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #transaction-date-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #local-amount-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #due-date-header>
                                        <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #debit-credit-header>
                                        <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                    </template>
    
                                    <DxSummary :calculate-custom-summary="setDetailSummaries">
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="t_amount_local"
                                            summary-type="custom"
                                            show-in-column="amount_local"
                                            display-format="{0}"
                                        /> 
                                        <DxTotalItem
                                            name="t_amount_local"
                                            summary-type="custom"
                                            show-in-column="amount_local"
                                            display-format="{0}"
                                        />
                                    </DxSummary>
    
                                    <DxStateStoring
                                            :enabled="true"
                                            type="custom"
                                            :storage-key="this.storageKey"
                                            :ref="dataGrid" 
                                            :saving-timeout="1000"
                                            :custom-load="LoadDXState"
                                        />
                            </DxDataGrid>
                        </div>
                    </div> 
                </div>
            </div> 
        </div>  

      
        <div class="modal fade" id="kt_modal_view_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            {{ $t('Detail') }}                     
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                            <div class="row"> 
                                <div class="card p-0">  
                                    <div class="card-body px-3">
                                        <div>
                                            <div class="table-responsive min-h-300px">
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="source_id" 
                                                    :data-source="transactionDetailList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true"
                                                    :ref="'dataGridDetail'"
                                                    @exporting="this.$onExporting($event , 'Transaction-List')"> 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="pageSize"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes(transactionDetailList)" :show-page-size-selector="showPageSizeSelector(transactionDetailList)" :show-info="true" :show-navigation-buttons="true"/>
                                                <DxSearchPanel :visible="true"/>  

                                                <DxColumn data-field="record_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" cell-template="record_date-cell-template"/> 
                                                <DxColumn data-field="source_name" :caption="$t('Source Name')" header-cell-template="source-name-header" cell-template="source_name-cell-template"/> 
                                                <DxColumn data-field="integration_type_name" :caption="$t('Integration Type')" header-cell-template="integration_type_name-header" cell-template="integration_type_name-cell-template"/>
                                                <DxColumn data-field="voucher_type_name" :caption="$t('Voucher Type')" header-cell-template="voucher-type-header" cell-template="voucher_type_name-cell-template"/>
                                                <DxColumn data-field="docket_no" :caption="$t('Voucher Number')" header-cell-template="voucher-number-header" alignment="right" cell-template="docket_no-cell-template"/> 
                                                <DxColumn data-field="serial_no" :caption="$t('S.N.')" alignment="right" cell-template="serial_no-cell-template"/> 
                                                <DxColumn data-field="is_manuel_name" :caption="$t('Record Type')" header-cell-template="record-type-header" cell-template="is_manuel_name-cell-template"/> 
                                                <DxColumn data-field="total_debit" :caption="$t('Total Debit')" header-cell-template="total-debit-header" alignment="right" cell-template="total_debit-cell-template"/> 
                                                <DxColumn data-field="total_credit" :caption="$t('Total Credit')" header-cell-template="total-credit-header" alignment="right" cell-template="total_credit-cell-template"/> 
                                                <DxColumn data-field="transaction_amount" :caption="$t('Transaction Amount')" header-cell-template="transaction-amount-header" alignment="right" cell-template="transaction_amount-cell-template"/>

                                                <template #record-date-header>
                                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                                </template>
                                                <template #source-name-header>
                                                    <div>{{ $t('Source') }}</div><div>{{ $t('Name') }}</div>
                                                </template>
                                                <template #voucher-number-header>
                                                    <div> {{ $t('Voucher') }}</div><div>{{ $t('Number') }}</div>
                                                </template>
                                                <template #voucher-type-header>
                                                    <div> {{ $t('Voucher') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #record-type-header>
                                                    <div> {{ $t('Record') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #integration_type_name-header>
                                                    <div> {{ $t('Integration') }}</div><div>{{ $t('Type') }}</div>
                                                </template>
                                                <template #total-debit-header>
                                                    <div> {{ $t('Total') }}</div><div>{{ $t('Debit') }}</div>
                                                </template>
                                                <template #total-credit-header>
                                                    <div> {{ $t('Total') }}</div><div>{{ $t('Credit') }}</div>
                                                </template>
                                                <template #transaction-amount-header>
                                                    <div> {{ $t('Transaction') }}</div><div>{{ $t('Amount') }}</div>
                                                </template>

                                                <!-- Cell Templates -->
                                                <template #record_date-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ previewDate(data.data.record_date) }}
                                                    </a>
                                                </template>
                                                <template #source_name-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary">
                                                        <span class="badge badge-circle badge-light me-3">
                                                            <i class="bi bi-pencil text-gray-900"></i> 
                                                        </span> 
                                                        <span class="text-gray-800"> 
                                                            {{ data.data.source_name }}
                                                        </span> 
                                                    </a>
                                                </template>
                                                <template #voucher_type_name-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                    {{ data.data.voucher_type_name }}
                                                    </a>
                                                </template>
                                                <template #integration_type_name-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                    {{ data.data.integration_type_name }}
                                                    </a>
                                                </template>
                                                <template #docket_no-cell-template="{ data }">  
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.docket_no }}
                                                    </a>
                                                </template>
                                                <template #serial_no-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.serial_no }}
                                                    </a>
                                                </template>
                                                <template #is_manuel_name-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.is_manuel_name }}
                                                    </a>
                                                </template>
                                                <template #total_debit-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.total_debit }}
                                                    </a>
                                                </template>
                                                <template #total_credit-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.total_credit }}
                                                    </a>
                                                </template>
                                                <template #transaction_amount-cell-template="{ data }"> 
                                                    <a href="#" class="text-gray-800 text-hover-primary"> 
                                                        {{ data.data.transaction_amount }}
                                                    </a>
                                                </template> 
                                                
                                                </DxDataGrid>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </form>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>  
</template>
<script>
  import axios from 'axios' 
  // import Swal from 'sweetalert2';
  import moment from 'moment';
  import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxGroupItem, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
   
  export default {
    name: "AccountingIntegrationList",
    components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxGroupItem, DxStateStoring, DxScrolling
    },
    data() {
      return { 
          storageKey: 'dx_' + this.$options.name,
          isFilterOpen: false,
          pageSize: 100,
          page: 1, 
          page_size: 20,
          lists: [],
          integrationTypeList: [
            { id: 1, text: 'Start Integration'},
            { id: 2, text: 'Cancel Integration'}
          ],
          transactionTypeList: [],
          form:  {
              first_date: moment(),
              last_date: moment(),
              integration_type: 1,
              transaction_type: 0,
              is_accounted: 0,
              periodSelection: {},
              period_id: null
          },
          isListProgressing: false,
          isProgressing: false,
          menuSelection: '/AccountingIntegrationHelppage',
          iconView : false,
          accountingYears: [],
          transactionDetailList: [],
          isDetailVisible: false
      };
    },
    computed: {
        setColumnTemplateForLocal(){
            return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
            }
        },
        searchableList() {
            return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
            }
        },
        showPageSizeSelector() {
            return(list) => {
                return list.length > this.pageSize;
            }
        },
        pageSizes() {
            return(list) => {
                let arr = [];
                let x = 1;
                let loop = (list.length / this.pageSize);
                for (let i = 1; i <= loop && i <= 5; i++) {
                    arr.push(this.pageSize * x);
                    x++;
                }
                if(loop > 5) arr.push(1000);
                if(loop > 1) arr.push('all');
                return arr;
            }
        }
    },
    methods: {
        getData() {
            this.$Progress.start();
            this.accountingYears = [];    
            const parameters = {
                session_id: this.$getUser().session_id
            };          
            const requestOne =  axios.post('/api/Auth/GetCompanyAccountingYears', parameters, {'Content-Type': 'application/json'});          
            axios.all([requestOne]).then(axios.spread((...responses) => {
                this.accountingYears = responses[0].data; 
                this.accountingYears.forEach((period) => {
                    if(period.id == this.$getSessionStorage('activeYear')){
                        this.form.period_id = period.id;
                        this.form['periodSelection'] = period; 
                        this.form.first_date = moment(this.form['periodSelection'].start_date); 
                        this.form.last_date = moment(this.form['periodSelection'].end_date); 
                    } 
                });
                this.$Progress.finish(); 
            }))
            .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish(); 
            });   
        },
        getListData() {
            if (this.form.integration_type == 2) {
                this.isDetailVisible = true;
            } else {
                this.isDetailVisible = false;
            }
            this.$Progress.start();
            this.isListProgressing = true;
            this.form.first_date = (this.form.first_date == null || this.form.first_date == '') ? moment() : this.form.first_date;
            this.form.last_date = ( this.form.last_date == null || this.form.last_date == '') ? moment () : this.form.last_date;
            const params = {
                session_id: this.$getUser().session_id,
                first_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                last_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                transaction_type: this.form.transaction_type,
                transaction_subtype: this.form.transaction_subtype,
                account_type: this.form.account_type,
                currency: this.form.currency,
                is_accounted: this.form.integration_type == 1 ? 0 : 1,
                ct: 1
            };
            params.currency = this.form.currency == '-1' ? null : this.form.currency;
            axios.post('/api/Transaction/GetAllTransactionList', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.lists = response.data;
                this.page = 1;
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });  
        }, 
        onStartIntegration() {
            this.$swal.fire({
                title: "Are you sure you want to start Accounting Integration?",
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                    this.isProgressing = true;
                    const params = {
                        session_id: this.$getUser().session_id,
                        start_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                        end_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                        period_id: this.form.period_id,
                    };   
                    const that = this;
                    axios.post('api/AccountingIntegration/StartIntegration', params, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){                            
                            this.lists = [];
                            this.$swal("Success", "Accounting Integration completed succesfully..!", 'success');  
                        }
                    })
                    .finally(function () { 
                        setTimeout(() => {
                            that.isProgressing = false;
                        }, 500);
                    });  
                }
            });
        },
        onCancelIntegration() {
            this.$swal.fire({
                title: "Are you sure you want to cancel integrated records?",
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                    this.isProgressing = true;
                    const params = {
                        session_id: this.$getUser().session_id,
                        start_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                        end_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                        period_id: this.form.period_id,
                    };   
                    const that = this;
                    axios.post('api/AccountingIntegration/CancelIntegration', params, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.lists = [];                          
                            this.$swal("Success", "Accounting Integration canceled..!", 'success');  
                        }
                    })
                    .finally(function () { 
                        setTimeout(() => {
                            that.isProgressing = false;
                        }, 500);
                    });  
                }
            });
        },
        getTransactionDetail(transaction_type_id) {
            this.transactionDetailList = [];
            const parameters = {
              session_id: this.$getUser().session_id,
              header_id: transaction_type_id
            };  
            axios.post('/api/AccountingIntegration/GetTransactionInfo', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
               if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                  this.transactionDetailList = response.data;
               }
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        onChangeAccountingYear(e){
            this.form['periodSelection'] = e;
            this.form.first_date = moment(this.form['periodSelection'].start_date); 
            this.form.last_date = moment(this.form['periodSelection'].end_date); 
        },
        setCriteriasToDefault() {   
            this.form = {
              periodSelection: {},
              first_date: moment(),
              last_date: moment(),
              integration_type: 1,
              transaction_type: 0,
              is_accounted: 0,
            };
            this.lists = [];
            this.accountingYears.forEach((period) => {
                if(period.id == this.$getSessionStorage('activeYear')){
                    this.form.period_id = period.id;
                    this.form['periodSelection'] = period; 
                    this.form.first_date = moment(this.form['periodSelection'].start_date); 
                    this.form.last_date = moment(this.form['periodSelection'].end_date); 
                } 
            });
        },
        setDetailSummaries(options){  
            if(options.name == "t_amount_local"){
                if(options.summaryProcess === 'start'){
                    options.totalValue = 0;
                }
                else if(options.summaryProcess === 'calculate'){
                    var amountLocalValue = parseFloat(options.value.amount_local.replaceAll(',', ''));
                    (options.totalValue += amountLocalValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }   
        },
        onCustomizeColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {   
                        if(column.dataField == 'amount_local'){ 
                            column.caption =  'Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
        /** DX STATE OPERATIONS **/  
        LoadDXState() {
            return JSON.parse(localStorage.getItem(this.storageKey));
        }, 
        SaveDXStateToStorage() { 
            const state = this.$refs.dataGrid.instance.state();
            localStorage.setItem(this.storageKey, JSON.stringify(state));
            this.$refs.listOptionComponent.getState();        
        },
        async helpPageViewIcon()
        {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/AccountingIntegrationHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
    },
    created() { 
      this.helpPageViewIcon();
      this.$parent.$parent.isFullWidth = true;
      this.lists = [];
      this.getData();
    }
  }; 
  </script>