<template>
    <div>
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header p-5 bg-primary">
                    <h2 class="text-white">{{ $t('Select to Renewal') }}</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" ref="closeRenewalModal">
                        <span class="svg-icon svg-icon-1 svg-icon-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="card"> 
                        <div class="card-body"> 
                            <div class="row">
                                <div class="col-lg-12">
                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                        key-expr="id" 
                                        :show-column-headers="false"
                                        :data-source="oldPolicyList" 
                                        :allow-column-reordering="true"  
                                        :allow-column-resizing="true" 
                                        :column-auto-width="true" 
                                        :row-alternatin-enabled="true"
                                        :hover-state-enabled="true" 
                                        :show-row-lines="true"
                                        @exporting="this.$onExporting($event , 'Policy-list')"> 

                                        <DxFilterRow :visible="false"/>
                                        <DxHeaderFilter :visible="false"/>
                                        <DxGroupPanel :visible="false"/>      
                                        <DxExport :enabled="false" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="100000"/>
                                        <DxSearchPanel :visible="false"/>
                                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                        <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                                        <DxColumn
                                            alignment="center"
                                            :caption="$t('Producer')"
                                            :fixed="false"
                                            fixed-position="right">
                                            <DxColumn data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                                            <DxColumn data-field="producer_policy_type" :caption="$t('Policy Type')" header-cell-template="producer_policy_type-header" cell-template="producer_policy_type-cell-template"/> 
                                        </DxColumn>
                                        <DxColumn data-field="start_date" :caption="$t('Start Date')" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                        <DxColumn data-field="end_date" :caption="$t('End Date')" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                                        <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                                        <DxColumn data-field="currency" :caption="$t('Curr.')" cell-template="currency-cell-template"/> 
                                        <DxColumn data-field="policy_total_amount" :caption="$t('Total Amount')" header-cell-template="policy_total_amount-header" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                                        <DxColumn data-field="isRenewed" :caption="$t('Renewed?')" cell-template="is_renewed-cell-template"/> 

                                        <template #headerLocalTemplate="{ data: data }">
                                            <div v-html="setColumnTemplateForLocal(data)"></div> 
                                        </template>

                                        <template #regulation_date-header>
                                            <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                                        </template> 
                                        <template #start_date-header>
                                            <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                                        </template> 
                                        <template #end_date-header>
                                            <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                                        </template> 
                                        <template #policy_no-header>
                                            <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                        </template>
                                        <template #policy_total_amount-header>
                                            <div>{{ $t('Total') }}</div><div>{{ $t('Amount') }}</div>
                                        </template> 
                                        <template #policy_total_amount_local-header>
                                            <div>{{ $t('Total') }}</div><div>{{ $t('Amount Local') }}</div>
                                        </template> 
                                        <template #producer_policy_type-header>
                                            <div>{{ $t('Policy') }}</div><div>{{ $t('Type') }}</div>
                                        </template>

                                        <template #is-selected-cell-template="{ data }">
                                            <span>
                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                                </div>
                                            </span>
                                        </template>
                                        <template #producer_name-cell-template="{ data }">
                                            <span class="align-middle text-gray-900 text-start">
                                                {{data.data.producer_name}}
                                            </span>   
                                        </template>
                                        <template #producer_policy_type-cell-template="{ data }">
                                            <span class="align-middle text-gray-900 text-start">
                                                {{data.data.producer_policy_type_name}}
                                            </span>   
                                        </template>
                                        <template #regulation_date-cell-template="{ data }">
                                            <span class="align-middle text-gray-700 text-end">
                                                {{previewDate(data.data.regulation_date)}}
                                            </span>   
                                        </template>
                                        <template #start_date-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{previewDate(data.data.start_date) }}
                                            </span>   
                                        </template>
                                        <template #end_date-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{previewDate(data.data.end_date)}}
                                            </span>   
                                        </template>
                                        <template #policy_no-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}
                                            </span>   
                                        </template>
                                        <template #currency-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{data.data.currency }}
                                            </span>   
                                        </template>
                                        <template #policy_total_amount-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{data.data.policy_total_amount }}
                                            </span>   
                                        </template>
                                        <template #rate-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{data.data.rate }}
                                            </span>   
                                        </template>
                                        <template #policy_total_amount_local-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                {{data.data.policy_total_amount_local }}
                                            </span>   
                                        </template>
                                        <template #is_renewed-cell-template="{ data }">
                                            <span class="align-middle text-gray-600 text-end">
                                                <span v-if="data.data.isRenewed"> {{ $t('Renewed') }} </span>
                                                <span v-if="!data.data.isRenewed"> <i class="bi bi-dash"></i> </span>
                                            </span>
                                        </template>
                                    </DxDataGrid>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer row py-3 mx-0">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-success btn-lg" @click="onSaveRenewalRelation()">
                            <span class="indicator-label">{{ $t('Save') }}</span> 
                            <i class="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
export default {
    name:'NewRenewalModal',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSearchPanel
    },
    data(){
        return {
            form: {
                policy_transaction_id: null
            },
            renewalStatusList: [{id: 1, text: 'Waiting'}, {id: 2, text: 'Renewed'}],
            oldPolicyList: []
        }
    },
    computed: {
        previewDate(){
            return (value)=>{
                if (value) {
                    return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        }
    },
    methods: {
        getData(tempValues) {
            this.policy_transaction_id = tempValues.policy_transaction_id;
            const parameters = {
                session_id: this.$getUser().session_id,
                account_id: tempValues.account_id,
                main_branch_id: tempValues.main_branch_id,
                policy_branch_id: tempValues.policy_branch_id,
                policy_transaction_id: tempValues.policy_transaction_id,
                regulation_date: moment(tempValues.regulation_date).format('YYYY-MM-DD')
            };  
            axios.post('/api/Insurance/GetPoliciesToRenewal', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.oldPolicyList = response.data;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onSaveRenewalRelation() {
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_transaction_id: this.policy_transaction_id,
                transactionIds: []
            };
            this.oldPolicyList.forEach(element => {
                if(element.isSelected) {
                    parameters.transactionIds.push(element.id);
                }
            });
            if(parameters.transactionIds.length == 0) parameters.transactionIds = ['-1'];
            axios.post('/api/Insurance/SaveRenewalPolicyRelation', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$refs.closeRenewalModal.click();
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        }
    }
}
</script>