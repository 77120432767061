<template> 
  <div> 
    <div class="content flex-row-fluid mt-2" id="kt_content">        
        <div class="card">
            <div class="card-body pt-3">                
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2">
                            <div class="fv-row mb-7 row">                            
                                <div class="col-lg-3">
                                <label class="fs-6 fw-bold form-label">
                                    <span>{{ $t('Start Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'"></Datepicker>  
                            </div>
                            <div class="col-lg-3">
                                <label class="fs-6 fw-bold form-label">
                                    <span>{{ $t('End Date') }}</span>
                                </label>
                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'"></Datepicker>
                            </div>
                            <div class="col-lg-3">
                                <label class="fs-6 fw-bold form-label">
                                    <span>{{ $t('Transaction Type') }}</span>
                                </label>
                                <Select2 v-model="form.item_type" :options="transactionTypeFilteredList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                            </div> 
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3" v-if="this.$isCredentialActive(9,'R')">
                        <div class="d-flex justify-content-end mt-8" data-kt-customer-table-toolbar="base">
                            <Popper hover placement="top">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light-primary border border-primary" @click="getData(false)" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List') }}</span> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                    </div>
                </div>
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Turnover-Diff-List')"> 
                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />
                            <DxColumn data-field="record_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" /> 
                            <DxColumn data-field="docket_type_name" :caption="$t('Transaction Type')" header-cell-template="transaction-type-header"/> 
                            <DxColumn data-field="docket_number" :caption="$t('Transaction Number')" header-cell-template="transaction-number-header"/> 
                            <DxColumn data-field="total_debit" :sorting-method="this.$numericSort" :caption="$t('Total Debit')" alignment="right" header-cell-template="total-debit-header"/> 
                            <DxColumn data-field="total_credit" :sorting-method="this.$numericSort" :caption="$t('Total Credit')" alignment="right" header-cell-template="total-credit-header"/> 
                            <DxColumn data-field="total_balance_amount" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right"/> 
                            <DxColumn data-field="total_provision_amount" :sorting-method="this.$numericSort" :caption="$t('Provision')" alignment="right"/> 
                            <DxColumn data-field="description" :caption="$t('Description')"/>

                            <template #transaction-type-header>
                                <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                            </template>  
                            <template #transaction-number-header>
                                <div>{{ $t('Transaction') }}</div><div>{{ $t('Number') }}</div>
                            </template>  
                            <template #total-debit-header>
                                <div>{{ $t('Total') }}</div><div>{{ $t('Debit') }}</div>
                            </template>  
                            <template #total-credit-header>
                                <div>{{ $t('Total') }}</div><div>{{ $t('Credit') }}</div>
                            </template>

                            <template #dataRowTemplate="{ data: data }"> 
                                    <td class="cursor-pointer align-middle text-end" @click="onUpdateHeader(data.data.id)">{{ previewDate(data.data.record_date) }} </td>
                                    <td class="text-gray-900 fw-bolder cursor-pointer p-3 align-middle" @click="onUpdateHeader(data.data.id)">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            <div>
                                                <span class="badge badge-circle badge-light me-3">
                                                    <i class="bi bi-pencil text-gray-900"></i> 
                                                </span> 
                                                <span class="text-gray-800"> 
                                                    {{ data.data.docket_type_name }} 
                                                </span> 
                                            </div>
                                        </a>
                                    </td>  
                                    <td class="cursor-pointer align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.docket_number }} </td>
                                    <td class="text-end cursor-pointer align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.total_debit }} </td>
                                    <td class="text-end cursor-pointer align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.total_credit }} </td>
                                    <td class="text-end cursor-pointer align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.total_balance_amount }} </td>
                                    <td class="text-end cursor-pointer align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.total_provision_amount }} </td>  
                                    <td class="align-middle" @click="onUpdateHeader(data.data.id)">{{ data.data.description }} </td> 
                            </template> 
                    </DxDataGrid>   
                </div> 
            </div>
        </div> 

        <!-- <div class="card card-xxl-stretch mb-6 mt-5">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="text-gray-800 me-5"> {{ $t('General Total') }} </h3>
                </div>
            </div>
            <div class="card-body pt-1">
                <form class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="fv-row row">
                        <table class="table align-middle table-row-dashed fs-6 gy-5 table-hover">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 fw-bolder text-gray-800"> 
                                    <th class="text-end"></th>
                                    <th class="text-end">{{ $t('Total Debit') }}</th>
                                    <th class="text-end">{{ $t('Total Credit') }}</th>
                                    <th class="text-end">{{ $t('Balance') }}</th>
                                    <th class="text-end">{{ $t('Provision') }}</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 fw-bold" v-if="this.summary">
                                <tr v-for="item in summary" v-bind:key="item"> 
                                    <td class="text-end">{{ item.transaction_type }}</td>
                                    <td class="text-end">{{ item.total_debit }}</td>
                                    <td class="text-end">{{ item.total_credit }}</td>
                                    <td class="text-end">{{ item.total_balance_amount }}</td>
                                    <td class="text-end">{{ item.total_provision_amount }}</td>
                                </tr> 
                            </tbody> 
                        </table> 
                    </div>
                </form>
            </div>
        </div> -->
    </div>
  </div>  
</template>

<script>
import axios from 'axios' 
import moment from 'moment';
import Swal from 'sweetalert2';

import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "TurnoverDiffList", 
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return { 
        pageSize: 10,
        lists:[],
        searchbar: '',
        isListProgressing: false,  
        form:  {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment().add('days'),
            item_type: '-1'
        },
        transactionTypeList: [{ id: '-1', text: 'ALL' }, { id: '11', text: 'Turnover' }, { id: '12', text: 'Difference'}],
        menuSelection: '/TurnoverAndDifferenceListHelppage',
        iconView : false
    };
  },
  computed: { 
     searchableList() {  
        return this.lists.filter(p => { 
            return p.docket_type_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            }
        ); 
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    transactionTypeFilteredList() {
      if(!this.$isCredentialActive(78, 'I', 9)) {
        return this.transactionTypeList.filter(p => p.id != 11);
      }
      if(!this.$isCredentialActive(79, 'I', 9)) {
        return this.transactionTypeList.filter(p => p.id != 12);
      }      
      return this.transactionTypeList;
    },
  },
  methods: { 
    getData(isAuto) {
        if(isAuto && this.$getUser().is_allow_list_all_data) return;
        this.isListProgressing = true;
        this.$Progress.start(); 
        const that = this; 
        const params = {
            session_id : this.$getUser().session_id,
            docket_type: this.form.item_type, 
            first_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            last_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };  
        axios.post('api/TurnoverDifference/GetHeaderList', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = []; 
            this.lists = response.data.master;
            this.summary = response.data.summary;
            this.isListProgressing = false; 
            this.$setSessionStorage('criterias'+ '_TRNTRS001', this.form);
            this.$Progress.finish();
        })
        .catch(function () { 
            this.isListProgressing = false;
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not get..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isListProgressing = false;
                that.$Progress.finish();
            }, 500);
        });    
    },
    onUpdateHeader(id){
        this.$setSessionStorage('record_id', id);
        this.$parent.$refs.closeTransactionListModal.click();
        if (this.$route.name == 'TurnoverDiffUpdate') {
            this.$router.go(0);
        } else {
            this.$router.push({ name: 'TurnoverDiffUpdate' });
        }
    },
    setCriteriasToDefault() { 
        this.form = {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),            
            item_type: '-1'
        };
        this.lists = []; 
        this.getData();
    },
    async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/TurnoverAndDifferenceListHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
  },
  created() {
    // this.helpPageViewIcon();
    // this.$removeSessionStorage('record_id');
    // try{
    // if(this.$getSessionStorage('refreshList')){
    //     this.$getSessionStorage('criterias');   
    //     const criteriaData = this.$getSessionStorage('criterias'+ '_TRNTRS001');
    //         this.form = {
    //             first_date: moment(criteriaData.first_date),
    //             last_date: moment(),
    //             item_type: criteriaData.item_type
    //         }   
    //     this.getData();  
    //     this.$removeSessionStorage('refreshList'+ '_TRNTRS001');  
    // }
    // else{
    //     this.$removeSessionStorage('criterias'+ '_TRNTRS001');
    //     this.transactionsList = [];
    // }
    // }
    // catch{ this.getData(); }
  },
  mounted(){ 
  }
}; 
</script>