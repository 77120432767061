<template>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content"  >
            <div class="modal-header bg-primary">
                <h2 class="modal-title fw-bolder col-md-10 text-white">{{ $t('Add Factor') }}</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionFactorModal" @click="this.$parent.isViewTransactionFactorModal = false">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="factorSelectionList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    @exporting="this.$onExporting($event , 'Factor-list')" >

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="false"/>
                                    <DxGroupPanel :visible="false"/>      
                                    <DxExport :enabled="false" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="10000"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="false"/>

                                    <DxColumn data-field="isSelected" :width="80" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :allow-filtering="false" alignment="center" :allow-exporting="false"/>
                                    <DxColumn data-field="factor_type_name" :caption="$t('Factor Type')" cell-template="factor_type_name-cell-template"/> 
                                    <DxColumn data-field="factor_detail_name" :caption="$t('Factor Detail')" cell-template="factor_detail_name-cell-template"/> 
                                    <DxColumn data-field="registration_number" :caption="$t('Reg. No')" cell-template="registration_number-cell-template"/> 
                                    <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 

                                    <!-- Header Cell Templates -->
                                    <template #is-selected-header>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" v-model="isSelectedAllFactors" @change="onSelectAllFactors()"> 
                                        </div>
                                    </template>

                                    <!-- Cell Templates -->
                                    <template #is_selected-cell-template="{ data }"> 
                                        <span>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                            </div>
                                        </span>
                                    </template>
                                    <template #factor_type_name-cell-template="{ data }">
                                        <span>
                                            {{ data.data.factor_type_name }}
                                        </span>
                                    </template>
                                    <template #factor_detail_name-cell-template="{ data }">
                                        <span>
                                            {{ data.data.factor_detail_name }}
                                        </span>
                                    </template>
                                    <template #registration_number-cell-template="{ data }">
                                        <span>
                                            {{ data.data.registration_number }}
                                        </span>
                                    </template>
                                    <template #name-cell-template="{ data }">
                                        <span>
                                            {{ data.data.name }}
                                        </span>
                                    </template>
                                </DxDataGrid> 
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-5" id="kt_add_user_button">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="addTransactionFactorItem()">
                                            <span class="indicator-label">{{ $t('Add Factor') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading...') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
export default {
    name:"AddFactorToTransaction",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel
    },
    data() {
        return {
            isItemSaving: false,
            factorSelectionList: [],
        }
    },
    methods:{
        getData(){
            const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.$parent.form.account_id,
            tmp_session_id: this.$getSessionStorage('trn_tmp_session_id')
            };  
            axios.post('/api/Insurance/GetAccountFactors', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.factorSelectionList = response.data;
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSelectAllFactors() {
            this.factorSelectionList.forEach((value) => {
                value.isSelected = this.isSelectedAllFactors;
            });
        },
        addTransactionFactorItem(){
            this.selectedAccounts = [];
            this.factorSelectionList.forEach((value) => {
                if(value.isSelected){
                    this.selectedAccounts.push(
                        { id: value.id, factor_type: value.factor_type }
                    );
                }
            }); 
            if (this.selectedAccounts.length == 0) {            
                this.$swal("Failed", " At least one option must be selected..!", 'warning' );
                return;
            }
            this.isItemSaving = true;
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                factorList: this.selectedAccounts
            };
            const that = this;
            axios.post('/api/Insurance/AddFactorItem', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.$parent.transactionFactorList = response.data;
                    this.$parent.isViewTransactionFactorModal = false;
                    if(this.$parent.transactionFactorList.length > 0){ 
                        if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                            this.$setSessionStorage('trn_tmp_session_id', this.$parent.transactionFactorList[0].tmp_session_id); 
                        }
                    } 
                }
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            }) 
            .catch(function () {
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                }, 500);
            });
        },
    },
    created(){
        this.getData();
    }
};
</script>