<template>
  <footer class="mt-auto py-4 d-flex flex-lg-column bg-white" id="kt_footer">
    <div class="d-flex container-xxl">
      <div class="flex-grow-1 bd-highlight">
        <span class="text-muted fw-bold me-1">{{fullYear}}© Polisoft Software Ltd</span> 
          <div class="bullet bg-secondary h-15px w-1px mx-5"></div>
          <a href="https://polisoft.co.uk" target="_blank" class="text-gray-800 text-hover-primary">
          CreatorCUBE v{{userData.version}} CP
          </a>
      </div> 
      <div class="flex-grow-1 bd-highlight d-none">
        <!-- Idle for Screen Lock -->
        <Vidle v-if="this.userData.is_locked !== 1 && this.userData.is_lock_screen_active == 1 && userData.lock_screen_time > 0" @idle="onScreenLock" :wait="3" :duration="lockScreenTime" />
      </div>
      <div class="flex-grow-1 bd-highlight d-none">
        <!-- Idle for Logout -->
        <Vidle v-if="this.userData.is_automatic_logout_active == 1 && userData.automatic_logout_time > 0" @idle="forceSignOut" @remind="onremind" :reminders="[10]" :wait="3" :duration="automaticLogoutTime" />
      </div> 


      <div class="bd-highlight"> 
        <span class="badge badge-primary align-items-right justify-content-end">
          {{ userData.company_vkn }} 
          <span v-if="userData.company_vkn !== null && userData.company_vkn !== '' && userData.company_vkn !== undefined">  <i class="bi bi-dash text-white mx-1"></i>  </span> 
          {{userData.company_name }}
        </span> 
      </div>
    </div> 
  </footer>
</template>  
<style>
</style>
<script>
export default {
  name: 'Home',
  data() {
    return {
      userData: this.$getUser(),
      automaticLogoutTime: this.$getUser().automatic_logout_time ? this.$getUser().automatic_logout_time * 60 : 0,
      lockScreenTime: this.$getUser().lock_screen_time ? this.$getUser().lock_screen_time * 60 : 0,
      fullYear: new Date().getFullYear()
    };
  },
  methods: {
    onremind() {
      let timerInterval;
      this.$swal.fire({
        title: 'Session expiring soon!',
        html: 'You have been inactive for <span class="fw-bolder">'+ this.userData.automatic_logout_time +'</span> minutes. You will be automatically signed out in <b></b> seconds. If session expires, all of unsaved data will be lost. Click <span class="fw-bolder">Stay Logged In</span> to continue.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Stay Logged In',
        allowOutsideClick: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: () => {
          const b = this.$swal.getHtmlContainer().querySelector('b');
          b.textContent = 10;
          timerInterval = setInterval(() => {
            b.textContent = (this.$swal.getTimerLeft() / 1000).toFixed(0)
          }, 1000)
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      });
    },	   
    forceSignOut() {
      this.$parent.isScreenLocked = false;
      this.$removeUser('user_' + this.$getUser().session_id);
      sessionStorage.clear();
      this.$router.push({ name: 'Logout', params: { m: this.userData.automatic_logout_time } });
    },
    onScreenLock() {
      this.userData.is_locked = 1;
      this.$parent.isScreenLocked = true;
    },
  },
  props: {msg: String},
  mounted() {
    this.emitter.on("changeScreenLockStatus", item => {
      this.userData.is_locked = item;
    });
  }
}
</script>