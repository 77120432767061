<template>
    <div class="modal-dialog modal-xxl p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header bg-primary py-7 d-flex justify-content-between">
                <h2 class="text-white">Create New Asset</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeContactModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y m-3" :class="{'overlay overlay-block': this.isAccountSaving == true}">
                <div class="stepper stepper-links d-flex flex-column" data-kt-stepper="true">
                    <form id="poliformAssetModal" @submit.prevent="" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                        <div class="current" data-kt-stepper-element="content" v-if="this.activeStep == 1">
                            <div class="w-100">
                                <div class="fv-row row">
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-flex flex-row row">
                                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="fv-row mb-7 row">
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'asset_name'">
                                                                        Asset Name
                                                                    </label>
                                                                    <input type="text" class="form-control" name="asset_name" v-model="accountForm.name" v-custom>
                                                                </div>
                                                                <div class="col-lg-12" v-if="isViewAccountId">
                                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                                        Account Serial No
                                                                    </label>
                                                                    <input type="text" class="form-control" name="accountID" v-model="accountForm.account_id">
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'asset_type'">
                                                                        <span>Asset Type</span>
                                                                    </label>
                                                                    <Select2 v-model="accountForm.asset_type_id" :options="assetTypeList" name="asset_type" v-custom 
                                                                        :settings="{ width: '100%', dropdownParent: '#kt_modal_add_asset .modal-content' }"/> 
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                                        <span>Branch</span>
                                                                    </label>
                                                                    <Select2 v-model="accountForm.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_asset .modal-content' }"/> 
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_center'">
                                                                        <span>Expense Center</span>
                                                                    </label>
                                                                    <Select2 v-model="accountForm.expense_center_id" :options="expenseCenterList" name="expense_center" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_asset .modal-content' }"/> 
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="fv-row row mb-7">
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'economic_life'">
                                                                        <span>Economic Life(Year)</span>
                                                                    </label>
                                                                    <input type="number" class="form-control" name="economic_life" v-model="accountForm.economic_life" @blur="onChangeEconomicLife()" v-custom>                                        
                                                                </div>     
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'amortization_rate'">
                                                                        <span>Amortization Rate (%)</span>
                                                                    </label>
                                                                    <CurrencyInput type="text" step="any" class="form-control text-end" v-model="accountForm.amortization_rate" 
                                                                        name="amortization_rate" v-custom @focus="$event.target.select()" @blur="onChangeEconomicLife()"/>
                                                                </div>
                                                                <!-- <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'show_on_invoice'">
                                                                        <span>Show on Invoice?</span>
                                                                    </label>
                                                                    <Select2 v-model="accountForm.is_show_invoice" :options="showInvoiceList" name="show_on_invoice" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_asset .modal-content' }"/> 
                                                                </div>                                                                 -->
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                                            <span>Notes</span>
                                                                        </label>
                                                                        <textarea rows="5" class="form-control" name="notes" v-custom v-model="accountForm.notes"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="d-grid gap-2"> 
                                <button type="submit" class="btn btn-lg btn-block btn-success" @click="createAccount()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    Save Account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
export default {
    name: "AddNewAsset",
    data() {
        return {
            assetTypeList: [],
            branchList: [],
            expenseCenterList: [],
            isProgressing: false,
            showInvoiceList: [ {id: '1', text: 'Yes'}, {id: '0', text: 'No'}],
            accountForm: {
                account_type: 8,
                name: null,
                company_id: null,
                account_id: null,
                qr_code: null,
                notes: null,
                asset_type_id: '-1',
                branch_id: '-1',
                expense_center_id: '-1',
                accounting_plan: null,
                is_show_invoice: 1,
                economic_life: 1,
                amortization_rate: 100
            },
            isViewAccountId: false,
            isAccountSaving: false,
            activeStep: 1
        };
    },
    computed: {
		isValidEmail() {
			return (this.accountForm.email == null || this.accountForm.email == '' || this.accountForm.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.accountForm.email);
		}
	},
    methods: {
    getData() {
        this.clearForm();
        this.$Progress.start();
        this.activeStep = 1;
        this.isAccountSaving = false;

        const params = {
            session_id: this.$getUser().session_id,
            list_type: 8, // Asset Type: 8            
            get_access_status: 0
        };
        axios.post("/api/Helper/GetLists", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.assetTypeList = response.data; 
            this.assetTypeList.unshift({ id: '-1', text: 'Select an option'}); 
            this.assetTypeList.forEach((value) => {
                if(value.is_default) {
                    this.accountForm.asset_type_id = value.id;
                }
            });
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["branch", "account_setting", "expense_center"],
                account_type: 8 // yalnızca account settings listesi çekilecek ise gerekli
            }; 
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {                
                this.branchList = response.data.branch;
                this.expenseCenterList = response.data.expense_center;
                this.branchList.forEach((value) => {
                    if (value.is_default) {
                        this.accountForm.branch_id = value.id;
                    }
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.accountForm.expense_center_id = value.id;
                    }
                });
                if (response.data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.$Progress.finish();
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
            });
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    createAccount() {
        this.$checkSelect2Validation('poliformAssetModal');
        if(!document.forms['poliformAssetModal'].reportValidity()){
            return;
        }
        this.isAccountSaving = true;
        const params = {
            id:this.accountForm.id,
            name:this.accountForm.name,
            company_id:this.accountForm.company_id,
            account_type:this.accountForm.account_type,
            account_id:this.accountForm.account_id,
            qr_code:this.accountForm.qr_code,
            notes:this.accountForm.notes,
            asset_type_id:this.accountForm.asset_type_id == '-1' ? null : this.accountForm.asset_type_id,
            branch_id:this.accountForm.branch_id == '-1' ? null : this.accountForm.branch_id,
            expense_center_id:this.accountForm.expense_center_id == '-1' ? null : this.accountForm.expense_center_id,
            accounting_plan:this.accountForm.accounting_plan,
            is_show_invoice:this.accountForm.is_show_invoice,
            economic_life:this.accountForm.economic_life,
            amortization_rate:this.accountForm.amortization_rate
        };   
        const that = this; 
        params.session_id = this.$getUser().session_id;
        params.account_type = 8; // Fixed Asset: 8 

        axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.accountForm.id = response.data;
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$refs.closeContactModal.click();
                this.$parent.onSuccessNewAccount(this.accountForm.id, 8);
            }
            this.isAccountSaving = false;
        })
        .catch(function () { 
            this.isAccountSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isAccountSaving = false;
            }, 500);
        }); 
    },
    onNextStep() {
        this.$checkSelect2Validation('poliformAssetModal');
        if(!document.forms['poliformAssetModal'].reportValidity()){
            return;
        }
        this.activeStep++;
    },
    onBackStep() {
      this.activeStep--;
    },
    clearForm() {
        this.accountForm = {
            account_type: 8,
            name: null,
            company_id: null,
            account_id: null,
            qr_code: null,
            notes: null,
            asset_type_id: '-1',
            branch_id: '-1',
            expense_center_id: '-1',
            accounting_plan: null,
            is_show_invoice: 1,
            economic_life: 1,
            amortization_rate: 100
        };        
        const params = {
            route: 'FixedAssetCreate',
            session_id: this.$getUser().session_id
        };
        axios.post('api/FormValidations/GetFormFields', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(response.data));
            }
        })
        .catch(function () { 
            console.error('No fields found..!');
        });
    },
    onChangeEconomicLife() {
        this.accountForm.amortization_rate = 100 / this.accountForm.economic_life;
    },
    onCloseModal() {
        this.$parent.$refs.openStockItemModal.click();
    }
  }
};
</script>