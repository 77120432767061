<template>
    <div>
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content"  >
                <div class="modal-header bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="factorModalType == 'add'">{{ $t('Add New Factor') }}</h2>
                    <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="factorModalType == 'edit'">{{ $t('Edit Factor') }}</h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionFactorModal" @click="this.$parent.isViewFactorModal = false">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="card">
                        <div class="card-body py-3">                                
                            <div id="kt_policy_tab_1" class="tab-pane fade show active" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'factor_type'">
                                            {{ $t('Factor Type') }}
                                        </label>
                                        <Select2 v-model="factorForm.factor_type" :options="factorTypeList" name="factor_type" v-custom 
                                            @select="onChangeFactorType(true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" :disabled="factorModalType == 'edit'" /> 
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'factor_detail_id'">
                                            {{ $t('Factor Type Detail') }}
                                        </label>
                                        <Select2 v-model="factorForm.factor_detail_id" :options="factorDetailFilteredList" name="factor_detail_id" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" /> 
                                    </div>
                                    <div :class="{'col-lg-6': factorForm.factor_type == 1, 'col-lg-12': factorForm.factor_type != 1}">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'factor_detail_id'">
                                            <span v-if="factorForm.factor_type == 1">{{ $t('Recorded Vehicle List') }}</span>
                                            <span v-if="factorForm.factor_type == 2">{{ $t('Recorded Property List') }}</span>
                                            <span v-if="factorForm.factor_type == 3">{{ $t('Recorded Person List') }}</span>
                                            <span v-if="factorForm.factor_type == 4">{{ $t('Recorded Load List') }}</span>
                                            <span v-if="factorForm.factor_type == 5">{{ $t('Recorded Other Factor List') }}</span>
                                        </label>
                                        <Select2 v-model="factorForm.recorded_factor_id" :options="recordedFactorList" name="recorded_factor_id" v-custom 
                                            @select="getFactorDetails()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }"/> 
                                    </div>
                                    <div class="col-lg-4" v-if="factorForm.factor_type == 1">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'vehicle_registration_status'">
                                            <span>{{ $t('Vehicle Registration Status') }}</span>
                                        </label>
                                        <Select2 v-model="factorForm.vehicle_registration_status" :options="vehicleRegistrationStatusList" name="vehicle_registration_status" v-custom 
                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }"/> 
                                    </div>
                                    <div class="col-lg-2" v-if="factorForm.factor_type == 1">
                                        <label class="col-form-label form-label fw-bold me-3">{{ $t('Is Vehicle Active') }}</label>
                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                            <input class="form-check-input" type="checkbox" v-model="factorForm.is_vehicle_active" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'registration_number'">
                                            {{ $t('Registration No') }} /
                                            <span v-if="factorForm.factor_type == '-1' || factorForm.factor_type == 2 || factorForm.factor_type == 3 || factorForm.factor_type == 4"> {{ $t('ID') }} </span>
                                            <span v-if="factorForm.factor_type == 1"> {{ $t('Plate') }} </span>

                                        </label>
                                        <input type="text" class="form-control" name="registration_number" v-model="factorForm.registration_number" v-custom>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label required mt-3" v-tag="'name'">
                                            <span v-if="factorForm.factor_type != 1 && factorForm.factor_type != 3"> {{ $t('Name') }} / {{ $t('Description') }}</span>
                                            <span v-if="factorForm.factor_type == 1">{{ $t('Description') }}</span>
                                            <span v-if="factorForm.factor_type == 3">{{ $t('Full Name') }}</span>
                                        </label>
                                        <input type="text" class="form-control" name="name" v-model="factorForm.name" v-custom>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-5" v-if="activeFactorStep == 2">
                        <div class="card-header min-h-50px"> 
                            <div class="card-title"> 
                                <span v-if="factorForm.factor_type == 1"> {{ $t('Vehicle Detail') }} </span>
                                <span v-if="factorForm.factor_type == 2"> {{ $t('Property Detail') }}</span>
                                <span v-if="factorForm.factor_type == 3"> {{ $t('Person Detail') }}</span>
                                <span v-if="factorForm.factor_type == 4"> {{ $t('Load Detail') }}</span>
                                <span v-if="factorForm.factor_type == 5"> {{ $t('Other Factors Detail') }}</span>
                            </div>
                            <div class="card-toolbar">
                                <RequiredFieldOnModal v-if="factorForm.factor_type == 1" :route-name="factorModalType == 'edit' ? 'VehicleUpdate' : 'VehicleCreate'"></RequiredFieldOnModal>
                                <RequiredFieldOnModal v-if="factorForm.factor_type == 2" :route-name="factorModalType == 'edit' ? 'PropertyUpdate' : 'PropertyCreate'"></RequiredFieldOnModal>
                                <RequiredFieldOnModal v-if="factorForm.factor_type == 3" :route-name="factorModalType == 'edit' ? 'FactorPersonUpdate' : 'FactorPersonCreate'"></RequiredFieldOnModal>
                                <RequiredFieldOnModal v-if="factorForm.factor_type == 5" :route-name="factorModalType == 'edit' ? 'FactorOtherUpdate' : 'FactorOtherCreate'"></RequiredFieldOnModal>
                            </div>
                        </div>
                        <!-- Vehicle Form -->
                        <div class="card-body py-3" v-if="factorForm.factor_type == 1">
                            <form id="vehicleDetailForm" @submit.prevent="" class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                <div class="row"> 
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_brand_id'">
                                                {{ $t('Vehicle') }} <br> {{ $t('Brand') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.vehicle_brand_id" :options="vehicleBrandList" name="vehicle_brand_id" v-custom
                                                @select="getVehicleModelList($event, true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_model_id'">
                                                {{ $t('Vehicle') }} <br> {{ $t('Model') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.vehicle_model_id" :options="vehicleModelList" name="vehicle_model_id" v-custom 
                                                @select="onSelectVehicleModel($event)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_code'">
                                                {{ $t('Vehicle') }} <br> {{ $t('Code') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="vehicle_code" v-model="factorVehicleForm.vehicle_code" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator my-3 border-gray-300"></div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_group_id'">
                                                {{ $t('Vehicle') }} <br> {{ $t('Group') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.vehicle_group_id" :options="vehicleGroupList" name="vehicle_group_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_type_id'">
                                                {{ $t('Vehicle') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.vehicle_type_id" :options="vehicleTypeList" name="vehicle_type_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_type_id'">
                                                {{ $t('Usage') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.usage_type_id" :options="usageTypeList" name="usage_type_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_detail_id'">
                                                {{ $t('Usage') }} <br> {{ $t('Detail') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.usage_detail_id" :options="usageDetailList" name="usage_detail_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'fuel_type_id'">
                                                {{ $t('Fuel') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorVehicleForm.fuel_type_id" :options="fuelTypeList" name="fuel_type_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'licence_number'">
                                                {{ $t('Licence') }} <br> {{ $t('Number') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="licence_number" v-model="factorVehicleForm.licence_number" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator my-3 border-gray-300"></div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'engine_number'">
                                                {{ $t('Engine') }} <br> {{ $t('Number') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="engine_number" v-model="factorVehicleForm.engine_number" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'chassis_number'">
                                                {{ $t('Chassis') }} <br> {{ $t('Number') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="chassis_number" v-model="factorVehicleForm.chassis_number" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'model_year'">
                                                {{ $t('Model') }} <br> {{ $t('Year') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Datepicker :textInputOptions="{ format: 'yyyy' }" v-maska="'####'" :required="this.$checkIfDateFieldRequired('model_year')" name="model_year" v-model="factorVehicleForm.model_year" yearPicker autoApply :clearable="false" v-custom ></Datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'color'">
                                                {{ $t('Color') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="color" v-model="factorVehicleForm.color" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'max_passenger'">
                                            {{ $t('Max') }}. <br> {{ $t('Passenger') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="number" class="form-control" name="max_passenger" v-model="factorVehicleForm.max_passenger" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'empty_weight'">
                                                {{ $t('Empty') }} <br> {{ $t('Weight') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="number" class="form-control" name="empty_weight" v-model="factorVehicleForm.empty_weight" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'load_capacity'">
                                                {{ $t('Load') }} <br> {{ $t('Capacity') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="number" class="form-control" name="load_capacity" v-model="factorVehicleForm.load_capacity" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'engine_power'">
                                                {{ $t('Engine') }} <br> {{ $t('Power') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="number" class="form-control" name="engine_power" v-model="factorVehicleForm.engine_power" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'first_registration_date'">
                                                {{ $t('First') }} <br> {{ $t('Reg. Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Datepicker name="first_registration_date" :required="this.$checkIfDateFieldRequired ('first_registration_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorVehicleForm.first_registration_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" v-custom />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'last_registration_date'">
                                                {{ $t('Last') }} <br> {{ $t('Reg. Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Datepicker name="last_registration_date" :required="this.$checkIfDateFieldRequired ('last_registration_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorVehicleForm.last_registration_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" v-custom />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_price'">
                                            {{ $t('Vehicle') }} <br> {{ $t('Price') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <CurrencyInput type="text" step="any" class="form-control text-end" v-model="factorVehicleForm.vehicle_price" @focus="$event.target.select()" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 p-0" v-if="vehicleStatus.is_vehicle_exist">
                                        <div class="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row w-100 my-3">
                                            <div class="d-flex flex-column ps-3 pe-0 pe-sm-10">
                                                <h4 class="fw-semibold">{{ $t('This vehicle is registered!') }}</h4>
                                                <span>{{ $t('There is already a registered vehicle with the same engine number and chassis number information for this account.') }}</span>
                                            </div>
                                            <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-sm btn-primary ms-sm-auto" @click="onSelectExistedVehicleToEdit()">
                                                <i class="bi bi-pencil-square"></i> {{ $t('Update Vehicle') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- Property Form -->
                        <div class="card-body py-3" v-if="factorForm.factor_type == 2">
                            <form id="propertyDetailForm" @submit.prevent="" class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">    
                                <div class="row"> 
                                    <GetAddress @address-selected="onSelectAddress" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(11,'AI')"/>
                                    <div class="col-lg-6">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'postcode'">
                                                {{ $t('Post Code') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="postcode" v-model="factorPropertyForm.postcode" v-custom>
                                                    <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                        id="basic-addon2" data-kt-indicator="on"
                                                        @click="this.onFindAddressByPostCode()"
                                                        :disabled="this.factorPropertyForm.postcode == null || this.factorPropertyForm.postcode == ''">
                                                        <span v-if="!isSearchingByPostCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                        <span v-if="isSearchingByPostCode">{{ $t('Searching...') }}</span> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'address_code'">
                                                {{ $t('Address Code') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="address_code" v-model="factorPropertyForm.uprn" v-custom>
                                                    <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                        id="basic-addon2" data-kt-indicator="on"
                                                        @click="this.onFindAddressByAddressCode()"
                                                        :disabled="this.factorPropertyForm.uprn == null || this.factorPropertyForm.uprn == ''">
                                                        <span v-if="!isSearchingByAddressCode"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                        <span v-if="isSearchingByAddressCode">{{ $t('Searching...') }}</span> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 ">
                                        <div class="row mb-2">
                                            <label class="col-lg-2 col-form-label form-label fw-bold p-2" v-tag="'address'">
                                                {{ $t('Select Address') }}
                                            </label>
                                            <div class="col-lg-10 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.address_id" :options="addressList" name="address" v-custom @select="onSelectAddressSearchResult($event)"
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" :disabled="addressList.length == 0"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">                                        
                                        <div class="row mb-2">
                                            <textarea class="form-control" placeholder="Address" name="address" rows="2" v-model="factorPropertyForm.address"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'city'">
                                                {{ $t('City') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="city" v-model="factorPropertyForm.city" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'district'">
                                                {{ $t('District') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="district" v-model="factorPropertyForm.district" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'country'">
                                                {{ $t('Country') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.country" :options="countryList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="country"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator my-3 border-gray-300"></div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'apartment_no'">
                                                {{ $t('Apartment No') }} <br> {{ $t('Name') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="apartment_no" v-model="factorPropertyForm.apartment_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'building_no'">
                                                {{ $t('Building No') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="building_no" v-model="factorPropertyForm.building_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'block_no'">
                                                {{ $t('Block No') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="block_no" v-model="factorPropertyForm.block_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'floor_no'">
                                                {{ $t('Floor No') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="floor_no" v-model="factorPropertyForm.floor_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8">
                                        <div class="row mb-2">
                                            <label class="col-lg-2 col-form-label form-label fw-bold p-2" v-tag="'street_no'">
                                                Street
                                            </label>
                                            <div class="col-lg-10 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="street_no" v-model="factorPropertyForm.street_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'residence_year' && 'residence_month'">
                                                {{ $t('Residence') }} <br> {{ $t('Period') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="residence_year" v-model="factorPropertyForm.residence_year" placeholder="Year" v-custom>
                                                    <input type="text" class="form-control" name="residence_month" v-model="factorPropertyForm.residence_month" placeholder="Month" v-custom>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_type_id'">
                                                {{ $t('Usage') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.usage_type_id" :options="propertyUsageTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="usage_type_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_type_detail_id'">
                                                {{ $t('Usage Type') }} <br> {{ $t('Detail') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.usage_type_detail_id" :options="propertyUsageDetailList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="usage_type_detail_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'property_type_id'">
                                                {{ $t('Property') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.property_type_id" :options="propertyTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="property_type_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'construction_type_id'">
                                                {{ $t('Construction') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.construction_type_id" :options="constructionTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="construction_type_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'construction_year_id'">
                                                {{ $t('Construction') }} <br> {{ $t('Year') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.construction_year_id" :options="constructionYearList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="construction_year_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'property_area'">
                                                {{ $t('Property') }} <br> {{ $t('Area') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="property_area" v-model="factorPropertyForm.property_area" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'residence_type_id'">
                                                {{ $t('Residence') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Select2 v-model="factorPropertyForm.residence_type_id" :options="residenceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" v-custom name="residence_type_id"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'owner_name'">
                                                {{ $t('Owner') }} <br> {{ $t('Name') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="owner_name" v-model="factorPropertyForm.owner_name" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Person Form -->
                        <div class="card-body py-3" v-if="factorForm.factor_type == 3">
                            <form id="personDetailForm" @submit.prevent="" class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'birth_date'">
                                                {{ $t('Birth') }} <br> {{ $t('Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <Datepicker name="birth_date" v-custom :required="this.$checkIfDateFieldRequired ('birth_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.birth_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'gender'">
                                                {{ $t('Gender') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                
                                                <Select2 v-model="factorPersonForm.gender" :options="genderList" name="gender" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'position_type_id'">
                                                {{ $t('Position') }} <br> {{ $t('Type') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                
                                                <Select2 v-model="factorPersonForm.position_type_id" :options="personPositionList" name="position_type_id" v-custom 
                                                    :settings="{ width: '100%', dropdownParent: '#kt_modal_add_trn_factor .modal-content' }" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'position_date'">
                                                {{ $t('Position') }} <br> {{ $t('Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">                                               
                                                <Datepicker name="position_date" v-custom :required="this.$checkIfDateFieldRequired ('position_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.position_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'licence_number'">
                                                {{ $t('Licence') }} <br> {{ $t('Number') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="licence_number" v-model="factorPersonForm.licence_number" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'licence_date'">
                                                {{ $t('Licence') }} <br> {{ $t('Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">                                               
                                                <Datepicker name="licence_date" v-custom :required="this.$checkIfDateFieldRequired ('licence_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorPersonForm.licence_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- Other Form -->
                        <div class="card-body py-3" v-if="factorForm.factor_type == 5">
                            <form id="otherDetailForm" @submit.prevent="" class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'brand'">
                                                {{ $t('Brand') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="brand" v-model="factorOtherForm.brand" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'model'">
                                                {{ $t('Model') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="model" v-model="factorOtherForm.model" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'serial_no'">
                                                {{ $t('Serial') }} <br> {{ $t('No') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="serial_no" v-model="factorOtherForm.serial_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'invoice_date'">
                                                {{ $t('Invoice') }} <br> {{ $t('Date') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">                                               
                                                <Datepicker name="invoice_date" v-custom :required="this.$checkIfDateFieldRequired ('invoice_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="factorOtherForm.invoice_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'invoice_no'">
                                                {{ $t('Invoice') }} <br> {{ $t('No') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="invoice_no" v-model="factorOtherForm.invoice_no" v-custom>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'producer'">
                                                {{ $t('Producer') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <input type="text" class="form-control" name="producer" v-model="factorOtherForm.producer" v-custom>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="col-lg-4">
                                        <div class="row mb-2">
                                            <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'price'">
                                                {{ $t('Price') }}
                                            </label>
                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                <CurrencyInput type="text" step="any" v-custom class="form-control text-end" name="price" v-model="factorOtherForm.price" @focus="$event.target.select()" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer row mx-0">
                    <div class="d-grid gap-2">
                        <div class="btn-group"> 
                            <div class="col-lg-2" v-if="this.activeFactorStep > 1">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-light btn-lg border border-gray-300 me-3" @click="onBackFactorStep()">
                                        <i class="bi bi-chevron-left"></i>
                                        <span class="indicator-label">{{ $t('Back') }}</span> 
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-10" :class="{'col-lg-12': activeFactorStep == 1}">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary btn-lg" v-if="activeFactorStep == 1"
                                        @click="onNextFactorStep()" :disabled="isFactorNextButtonDisabled">
                                        <span class="indicator-label">{{ $t('Next') }}</span> 
                                    </button>
                                    <button type="button" class="btn btn-success btn-lg" v-if="activeFactorStep == 2 && factorModalType == 'add'" :disabled="vehicleStatus.is_vehicle_exist"
                                        @click="onSaveFactorDetails()">
                                        <span class="indicator-label">{{ $t('Save') }}</span> 
                                    </button>
                                    <button type="button" class="btn btn-primary btn-lg" v-if="activeFactorStep == 2 && factorModalType == 'edit'"
                                        @click="onEditFactorDetails()">
                                        <span class="indicator-label">{{ $t('Update') }}</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
    name: 'TransactionFactorModal',
    data() {
        return {
            userData: this.$getUser(),
            factorModalType: 'add',
            factorTypeList: [],
            factorDetailList: [{id: '-1', text: 'Select an option'}],
            activeFactorStep: 1,
            vehicleGroupList: [],
            vehicleTypeList: [],
            usageTypeList: [],
            usageDetailList: [],
            fuelTypeList: [],
            vehicleBrandList: [],
            vehicleModelList: [{id: '-1', text: 'Select an option'}],
            recordedFactorList: [{id: '-1', text: 'New Vehicle Card'}],
            vehicleRegistrationStatusList: [
                {id: 1, text: 'Registered'},
                {id: 2, text: 'Not Registered'}
            ],
            genderList: [
                {id: '-1', text: 'Select an option'},
                {id: 'M', text: 'Male'},
                {id: 'F', text: 'Female'},
                {id: 'O', text: 'Other'},
            ],
            factorForm: {
                factor_type: 1,
                factor_detail_id: '-1',
                registration_number: null,
                name: null,
                recorded_factor_id: '-1',
                vehicle_registration_status: 1,
                is_vehicle_active: true
            },
            factorVehicleForm: {
                vehicle_group_id: '-1',
                vehicle_type_id: '-1',
                usage_type_id: '-1',
                usage_detail_id: '-1',
                fuel_type_id: '-1',
                licence_number: null,
                vehicle_brand_id: '-1',
                vehicle_model_id: '-1',
                vehicle_brand_code: null,
                vehicle_model_code: null,
                vehicle_code: null,
                engine_number: null,
                chassis_number: null,
                model_year: null,
                color: null,
                max_passenger: 0,
                empty_weight: 0,
                load_capacity: 0,
                engine_power: 0,
                last_registration_date: null,
                first_registration_date: null,
                vehicle_price: 0
            },
            factorPropertyForm: {
                postcode: null,
                address_id: '-1',
                address: null,
                city: null,
                district: null,
                country: '-1',
                apartment_no: null,
                building_no: null,
                block_no: null,
                floor_no: null,
                street_no: null,
                residence_year: null,
                residence_month: null,
                usage_type_id: '-1',
                usage_type_detail_id: '-1',
                property_type_id: '-1',
                construction_type_id: '-1',
                construction_year_id: '-1',
                property_area: '0',
                residence_type_id: 0,
                owner_name: null
            },
            factorPersonForm: {
                gender: '-1',
                birth_date: null,
                position_type_id: '-1',
                position_date: null,
                licence_number: null,
                licence_date: null,
            },
            factorOtherForm: {
                invoice_date: null,
                brand: null,
                model: null,
                serial_no: null,
                invoice_no: null,
                price: 0,
                producer: null,
            },
            addressList: [{id: '-1', text: 'Select an address from the list after the postcode query'}],
            countryList: [{id: '-1', text: 'Select an option'}],
            propertyUsageTypeList: [{id: '-1', text: 'Select an option'}],
            propertyUsageDetailList: [{id: '-1', text: 'Select an option'}],
            propertyTypeList: [{id: '-1', text: 'Select an option'}],
            constructionTypeList: [{id: '-1', text: 'Select an option'}],
            constructionYearList: [{id: '-1', text: 'Select an option'}],
            residenceTypeList: [
                {id: 0, text: 'Select an option'},
                {id: 1, text: 'Tenant'},
                {id: 2, text: 'Owner'},
            ],
            personPositionList: [],  
            isSearchingByPostCode: false,
            isSearchingByAddressCode: false,
            vehicleStatus: {
                is_vehicle_exist: false,
                existed_vehicle_id: null
            },
        }
    },
    computed: {
        factorDetailFilteredList() {
            return this.factorDetailList.filter(p => { 
                return p.type == this.factorForm.factor_type || p.id == '-1';
            });        
        },
        isFactorNextButtonDisabled() {
            if(this.activeFactorStep == 1) {
                return this.factorForm.factor_type == null || this.factorForm.factor_type == '' || this.factorForm.factor_type == 0 ||
                this.factorForm.factor_detail_id == null || this.factorForm.factor_detail_id == '' || this.factorForm.factor_detail_id == '-1'||
                this.factorForm.registration_number == null || this.factorForm.registration_number == '' || this.factorForm.registration_number == undefined||
                this.factorForm.name == null || this.factorForm.name == '' || this.factorForm.name == undefined;
            }
            else {
                return false;
            }
        }
    },
    methods: {     
        getData(modalType) {            
            this.factorModalType = modalType;
            this.activeFactorStep = 1;
            this.$parent.isViewFactorModal = true;
            this.vehicleModelList = [{ id: '-1', text: 'Select an option'}];
            this.clearFactorForm();

            if(modalType == 'edit') {
                this.$parent.transactionFactorList.forEach(element => {
                    if(element.isSelected) {
                        this.factorForm.recorded_factor_id = element.factor_id;
                        this.factorForm.factor_type = element.factor_type;
                    }
                });
            }

            const parameters = {
                session_id : this.$getUser().session_id,      
                tip: 28, //Factor Type
                list_type: 5 /* Factor Detail */
            };  
            const parametersTwo = {
                session_id: this.$getUser().session_id,
                account_id: this.$parent.form.account_id,
                tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                factor_type: this.factorForm.factor_type
            };  
            const requestOne =  axios.post('/api/Helper/GetConst', parameters, {'Content-Type': 'application/json'}); 
            const requestTwo =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'}); 
            const requestThree =  axios.post('/api/Insurance/GetAccountFactorsByType', parametersTwo, {'Content-Type': 'application/json'}); 
            axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.factorTypeList = responses[0].data;
                this.factorDetailList = responses[1].data;
                this.factorDetailList.unshift({ id: '-1', text: 'Select an option'});

                this.recordedFactorList = responses[2].data;                
                if(this.factorForm.factor_type == 1) {
                    this.recordedFactorList.unshift({ id: '-1', text: 'New Vehicle Card'});
                }
                if(this.factorForm.factor_type == 2) {
                    this.recordedFactorList.unshift({ id: '-1', text: 'New Property'});
                }
                if(this.factorForm.factor_type == 3) {
                    this.recordedFactorList.unshift({ id: '-1', text: 'New Person'});
                }
                if(this.factorForm.factor_type == 5) {
                    this.recordedFactorList.unshift({ id: '-1', text: 'New Other Factor'});
                }
                
                // Faktor tipine göre listeler getiriliyor
                if(this.factorForm.factor_type == 1) {
                    this.getVehicleLists();
                }
                if(this.factorForm.factor_type == 2) {
                    this.getPropertyLists();
                }
                if(this.factorForm.factor_type == 3) {
                    this.getPersonLists();
                }
                if(modalType == 'edit') {
                    if(this.factorForm.factor_type == 5) {
                        this.getOtherDetailsById();
                    }
                }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getVehicleLists() {
            this.vehicleModelList = [{ id: '-1', text: 'Select an option'}];
            const parametersOne = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 9, list_name: "vehicle_group" },
                    { list_type: 10, list_name: "vehicle_type" },
                    { list_type: 11, list_name: "usage_type" },
                    { list_type: 12, list_name: "usage_detail" },
                    { list_type: 13, list_name: "fuel_type" },
                    { list_type: 15, list_name: "vehicle_brand" },
                ],
            };
            const parametersTwo = {
                route:  this.factorModalType == 'edit' ? 'VehicleUpdate' : 'VehicleCreate', 
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parametersOne, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/FormValidations/GetFormFields', parametersTwo, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
                this.vehicleGroupList= responses[0].data.vehicle_group;
                this.vehicleTypeList = responses[0].data.vehicle_type; 
                this.usageTypeList = responses[0].data.usage_type; 
                this.usageDetailList = responses[0].data.usage_detail; 
                this.fuelTypeList = responses[0].data.fuel_type; 
                this.vehicleBrandList = responses[0].data.vehicle_brand; 
                
                this.vehicleGroupList.unshift({ id: '-1', text: 'Select an option'});
                this.vehicleTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.usageTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.usageDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.fuelTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.vehicleBrandList.unshift({ id: '-1', text: 'Select an option'});

                if(responses[1].data != null){  
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[1].data));
                }
                
                if(this.factorModalType == 'edit') {
                    this.getVehicleDetailsById();
                }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getPropertyLists() {
            const parameters = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 17, list_name: "construction_type" },
                    { list_type: 18, list_name: "construction_year" },
                    { list_type: 20, list_name: "property_type" },
                    { list_type: 21, list_name: "property_usage_detail" },
                    { list_type: 22, list_name: "property_usage_type" },
                ],
                list_name: ["country"]
            };
            const parametersTwo = {
                route:  this.factorModalType == 'edit' ? 'PropertyUpdate' : 'PropertyCreate',
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
            const requestThree =  axios.post('/api/FormValidations/GetFormFields', parametersTwo, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {            
                this.constructionTypeList= responses[0].data.construction_type;
                this.constructionYearList = responses[0].data.construction_year; 
                this.propertyTypeList = responses[0].data.property_type; 
                this.propertyUsageDetailList = responses[0].data.property_usage_detail; 
                this.propertyUsageTypeList = responses[0].data.property_usage_type; 
                this.countryList = responses[1].data.country; 
                
                this.constructionTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.constructionYearList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyUsageDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyUsageTypeList.unshift({ id: '-1', text: 'Select an option'});   
                this.countryList.unshift({ id: '-1', text: 'Select an option'});   
                this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];

                if(responses[2].data != null){  
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[2].data));
                }

                if(this.factorModalType == 'edit') {
                    this.getPropertyDetailsById();
                }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getPersonLists() {
            const parametersOne = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 19, list_name: "person_position" }
                ],
            };
            const parametersTwo = {
                route:  this.factorModalType == 'edit' ? 'FactorPersonUpdate' : 'FactorPersonCreate', 
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyLists', parametersOne, {'Content-Type': 'application/json'}); 
            const requestTwo =  axios.post('/api/FormValidations/GetFormFields', parametersTwo, {'Content-Type': 'application/json'});       
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
                this.personPositionList = responses[0].data.person_position;            
                this.personPositionList.unshift({ id: '-1', text: 'Select an option'});
                if(responses[1].data != null){  
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[1].data));
                }
                
                if(this.factorModalType == 'edit') {
                    this.getPersonDetailsById();
                }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getOtherLists() {
            const parameters = {
                route:  this.factorModalType == 'edit' ? 'FactorOtherUpdate' : 'FactorOtherCreate', 
                session_id: this.$getUser().session_id
            };  
            axios.post('/api/FormValidations/GetFormFields', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data != null){  
                        sessionStorage.setItem('frm_val', JSON.stringify(response.data));
                    }
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        getVehicleModelList(event, isChanged) {
            if(isChanged) {
                this.factorVehicleForm.vehicle_model_id = '-1';
                this.factorVehicleForm.vehicle_brand_code = event.code;
            }
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 16,
                parent_id: this.factorVehicleForm.vehicle_brand_id
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.vehicleModelList = response.data;
                this.vehicleModelList.unshift({id: '-1', text: 'Select an option'});

                if(!isChanged) {
                    this.vehicleModelList.forEach(element => {
                        if(element.code == this.factorVehicleForm.vehicle_model_code) {
                            this.factorVehicleForm.vehicle_model_id = element.id;
                        }
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onSelectVehicleModel(event) {
            this.factorVehicleForm.vehicle_model_code = event.code;
            this.factorVehicleForm.vehicle_code = this.factorVehicleForm.vehicle_brand_code + this.factorVehicleForm.vehicle_model_code;
        },
        getFactorDetails() {
            if(this.factorForm.factor_type == 1) this.getVehicleDetailsById();
            else if(this.factorForm.factor_type == 2) this.getPropertyDetailsById();
            else if(this.factorForm.factor_type == 3) this.getPersonDetailsById();
            else if(this.factorForm.factor_type == 5) this.getOtherDetailsById();
            else {
                this.$swal("Failed", " Unknown factor type..!", 'danger' )
                return;
            }
        },
        getVehicleDetailsById(isRecordExist) {
            if(this.factorForm.recorded_factor_id !== '-1') {
                this.activeFactorStep = 2;
                this.factorModalType = 'edit';
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: this.factorForm.recorded_factor_id
                };  
                axios.post('/api/Insurance/GetFactorVehiclesById', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.factorForm.factor_detail_id = (response.data.factor_detail_id !== '' && response.data.factor_detail_id !== null) ? response.data.factor_detail_id : '-1';
                    this.factorForm.registration_number = !isRecordExist ? response.data.registration_number : this.factorForm.registration_number;
                    this.factorForm.name = response.data.name;
                    this.factorForm.vehicle_registration_status = !isRecordExist ? response.data.vehicle_registration_status : 1;
                    this.factorForm.is_vehicle_active = response.data.is_vehicle_active == 1 ? true : false;
                    this.factorVehicleForm = {
                        vehicle_group_id: (response.data.vehicle_group_id !== '' && response.data.vehicle_group_id !== null) ? response.data.vehicle_group_id : '-1',
                        vehicle_type_id: (response.data.vehicle_type_id !== '' && response.data.vehicle_type_id !== null) ? response.data.vehicle_type_id : '-1',
                        usage_type_id: (response.data.usage_type_id !== '' && response.data.usage_type_id !== null) ? response.data.usage_type_id : '-1',
                        usage_detail_id: (response.data.usage_detail_id !== '' && response.data.usage_detail_id !== null) ? response.data.usage_detail_id : '-1',
                        fuel_type_id: (response.data.fuel_type_id !== '' && response.data.fuel_type_id !== null) ? response.data.fuel_type_id : '-1',
                        vehicle_brand_id: (response.data.vehicle_brand_id !== '' && response.data.vehicle_brand_id !== null) ? response.data.vehicle_brand_id : '-1',
                        vehicle_model_id: (response.data.vehicle_model_id !== '' && response.data.vehicle_model_id !== null) ? response.data.vehicle_model_id : '-1',
                        vehicle_brand_code: response.data.vehicle_brand_id,
                        vehicle_model_code: response.data.vehicle_model_id,
                        licence_number: response.data.licence_number,
                        vehicle_code: response.data.vehicle_code,
                        engine_number: response.data.engine_number,
                        chassis_number: response.data.chassis_number,
                        model_year: response.data.model_year,
                        color: response.data.color,
                        max_passenger: response.data.max_passenger,
                        empty_weight: response.data.empty_weight,
                        load_capacity: response.data.load_capacity,
                        engine_power: response.data.engine_power,
                        last_registration_date: response.data.last_registration_date,
                        first_registration_date: response.data.first_registration_date,
                        vehicle_price: response.data.vehicle_price
                    };
                    this.vehicleBrandList.forEach(element => {
                        if(element.code == this.factorVehicleForm.vehicle_brand_code) {
                            this.factorVehicleForm.vehicle_brand_id = element.id;
                        }
                    });
                    this.getVehicleModelList(null, false);
                }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.clearFactorVehicleForm();
            }
        },
        getPropertyDetailsById() {
            if(this.factorForm.recorded_factor_id !== '-1') {
                this.activeFactorStep = 2;
                this.factorModalType = 'edit';
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: this.factorForm.recorded_factor_id
                };  
                axios.post('/api/Insurance/GetFactorPropertyById', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.factorForm.factor_detail_id = (response.data.factor_detail_id !== '' && response.data.factor_detail_id !== null) ? response.data.factor_detail_id : '-1';
                        this.factorForm.registration_number = response.data.registration_number;
                        this.factorForm.name = response.data.name;
                        this.factorPropertyForm = {
                            postcode: response.data.postcode,
                            address_id: '-1',
                            address: response.data.address,
                            city: response.data.city,
                            district: response.data.district,
                            country: (response.data.country !== '' && response.data.country !== null) ? response.data.country : '-1',
                            apartment_no: response.data.apartment_no,
                            building_no: response.data.building_no,
                            block_no: response.data.block_no,
                            floor_no: response.data.floor_no,
                            street_no: response.data.street_no,
                            residence_year: response.data.residence_year,
                            residence_month: response.data.residence_month,
                            usage_type_id: (response.data.usage_type_id !== '' && response.data.usage_type_id !== null) ? response.data.usage_type_id : '-1',
                            usage_type_detail_id: (response.data.usage_type_detail_id !== '' && response.data.usage_type_detail_id !== null) ? response.data.usage_type_detail_id : '-1',
                            construction_type_id: (response.data.construction_type_id !== '' && response.data.construction_type_id !== null) ? response.data.construction_type_id : '-1',
                            property_type_id: (response.data.property_type_id !== '' && response.data.property_type_id !== null) ? response.data.property_type_id : '-1',
                            construction_year_id: (response.data.construction_year_id !== '' && response.data.construction_year_id !== null) ? response.data.construction_year_id : '-1',
                            residence_type_id: response.data.residence_type_id,
                            property_area: response.data.property_area,
                            owner_name: response.data.owner_name,
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.clearFactorPropertyForm();
            }
        },
        getPersonDetailsById() {
            if(this.factorForm.recorded_factor_id !== '-1') {
                this.activeFactorStep = 2;
                this.factorModalType = 'edit';
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: this.factorForm.recorded_factor_id
                };  
                axios.post('/api/Insurance/GetFactorPersonById', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.factorForm.factor_detail_id = (response.data.factor_detail_id !== '' && response.data.factor_detail_id !== null) ? response.data.factor_detail_id : '-1';
                        this.factorForm.registration_number = response.data.registration_number;
                        this.factorForm.name = response.data.name;
                        this.factorPersonForm = {
                            gender: response.data.gender,
                            birth_date: response.data.birth_date,
                            position_type_id: (response.data.position_type_id !== '' && response.data.position_type_id !== null) ? response.data.position_type_id : '-1',
                            position_date: response.data.position_date,
                            licence_number: response.data.licence_number,
                            licence_date: response.data.licence_date,
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.clearFactorPersonForm();
            }
        },
        getOtherDetailsById() {
            if(this.factorForm.recorded_factor_id !== '-1') {
                this.activeFactorStep = 2;
                this.factorModalType = 'edit';
                this.factorModalType == 'edit';
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: this.factorForm.recorded_factor_id
                };  
                axios.post('/api/Insurance/GetFactorOtherById', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.factorForm.factor_detail_id = (response.data.factor_detail_id !== '' && response.data.factor_detail_id !== null) ? response.data.factor_detail_id : '-1';
                        this.factorForm.registration_number = response.data.registration_number;
                        this.factorForm.name = response.data.name;
                        this.factorOtherForm = {
                            invoice_date: response.data.invoice_date,
                            brand: response.data.brand,
                            model: response.data.model,
                            serial_no: response.data.serial_no,
                            invoice_no: response.data.invoice_no,
                            price: response.data.price,
                            producer: response.data.producer,
                        };
                        this.getOtherLists();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.clearFactorOtherForm();
            }
        },
        clearFactorVehicleForm() {
            this.factorModalType = 'add';
            this.factorVehicleForm = {
                vehicle_group_id: '-1',
                vehicle_type_id: '-1',
                usage_type_id: '-1',
                usage_detail_id: '-1',
                fuel_type_id: '-1',
                licence_number: null,
                vehicle_brand_id: '-1',
                vehicle_model_id: '-1',
                vehicle_brand_code: null,
                vehicle_model_code: null,
                vehicle_code: null,
                engine_number: null,
                chassis_number: null,
                model_year: null,
                color: null,
                max_passenger: 0,
                empty_weight: 0,
                load_capacity: 0,
                engine_power: 0,
                last_registration_date: null,
                first_registration_date: null,
                vehicle_price: 0
            };
        },
        clearFactorPropertyForm() {    
            this.factorModalType = 'add';    
            this.factorPropertyForm = {
                postcode: null,
                address_id: '-1',
                address: null,
                city: null,
                district: null,
                country: '-1',
                apartment_no: null,
                building_no: null,
                block_no: null,
                floor_no: null,
                street_no: null,
                residence_year: null,
                residence_month: null,
                usage_type_id: '-1',
                usage_type_detail_id: '-1',
                property_type_id: '-1',
                construction_type_id: '-1',
                construction_year_id: '-1',
                property_area: '0',
                residence_type_id: 0,
                owner_name: null
            };
        },
        clearFactorPersonForm() {
            this.factorModalType = 'add';
            this.factorPersonForm = {
                gender: '-1',
                birth_date: null,
                position_type_id: '-1',
                position_date: null,
                licence_number: null,
                licence_date: null,
            };
        },
        clearFactorOtherForm() {
            this.factorModalType = 'add';
            this.factorOtherForm = {
                invoice_date: null,
                brand: null,
                model: null,
                serial_no: null,
                invoice_no: null,
                price: 0,
                producer: null,
            };
        },
        onNextFactorStep() {
            this.activeFactorStep++;
            if(this.activeFactorStep == 2 && this.factorForm.factor_type == 1) { this.getVehicleLists(); }
            if(this.activeFactorStep == 2 && this.factorForm.factor_type == 2) { this.getPropertyLists(); }
            if(this.activeFactorStep == 2 && this.factorForm.factor_type == 3) { this.getPersonLists(); }
            if(this.activeFactorStep == 2 && this.factorForm.factor_type == 5) { this.getOtherLists(); }
        },
        onBackFactorStep() {
            this.activeFactorStep--;
        },
        onChangeFactorType(isChanged) {
            if(isChanged) {
                this.factorForm.factor_detail_id = '-1';
                this.activeFactorStep = 1;
            }
            this.vehicleStatus = {
                is_vehicle_exist: false,
                existed_vehicle_id: null
            };
            if(this.factorForm.factor_type == 4) {
                this.recordedFactorList[{ id: '-1', text: 'New Load'}];
                return;
            }
            const parameters = {
                session_id: this.$getUser().session_id,
                account_id: this.$parent.form.account_id,
                tmp_session_id: this.$getSessionStorage('trn_tmp_session_id'),
                factor_type: this.factorForm.factor_type
            };  
            axios.post('/api/Insurance/GetAccountFactorsByType', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.recordedFactorList = response.data;                
                    if(this.factorForm.factor_type == 1) {
                        this.recordedFactorList.unshift({ id: '-1', text: 'New Vehicle Card'});
                    }
                    if(this.factorForm.factor_type == 2) {
                        this.recordedFactorList.unshift({ id: '-1', text: 'New Property'});
                    }
                    if(this.factorForm.factor_type == 3) {
                        this.recordedFactorList.unshift({ id: '-1', text: 'New Person'});
                    }
                    if(this.factorForm.factor_type == 5) {
                        this.recordedFactorList.unshift({ id: '-1', text: 'New Other Factor'});
                    }
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSaveFactorDetails() {
            if(this.factorForm.factor_type == 1) this.onSaveFactorVehicle();
            else if(this.factorForm.factor_type == 2) this.onSaveFactorProperty();
            else if(this.factorForm.factor_type == 3) this.onSaveFactorPerson();
            else if(this.factorForm.factor_type == 5) this.onSaveFactorOther();
            else {
                this.$swal("Failed", " Unknown factor type..!", 'danger' )
                return;
            }
        },
        onEditFactorDetails() {
            if(this.factorForm.factor_type == 1) this.onEditFactorVehicle();
            else if(this.factorForm.factor_type == 2) this.onEditFactorProperty();
            else if(this.factorForm.factor_type == 3) this.onEditFactorPerson();
            else if(this.factorForm.factor_type == 5) this.onEditFactorOther();
            else {
                this.$swal("Failed", " Unknown factor type..!", 'danger' )
                return;
            }
        },
        onSaveFactorVehicle() {
            if(this.factorForm.factor_type != 1) return;
            this.$checkSelect2Validation('vehicleDetailForm');
            if(!document.forms['vehicleDetailForm'].reportValidity()){
                return;
            }
            if (this.factorVehicleForm.vehicle_price == null || this.factorVehicleForm.vehicle_price == '' || this.factorVehicleForm.vehicle_price == undefined) { this.factorVehicleForm.vehicle_price = 0; }
            if (this.factorVehicleForm.max_passenger == null || this.factorVehicleForm.max_passenger == '' || this.factorVehicleForm.max_passenger == undefined) { this.factorVehicleForm.max_passenger = 0; }
            if (this.factorVehicleForm.empty_weight == null || this.factorVehicleForm.empty_weight == '' || this.factorVehicleForm.empty_weight == undefined) { this.factorVehicleForm.empty_weight = 0; }
            if (this.factorVehicleForm.load_capacity == null || this.factorVehicleForm.load_capacity == '' || this.factorVehicleForm.load_capacity == undefined) { this.factorVehicleForm.load_capacity = 0; }
            if (this.factorVehicleForm.engine_power == null || this.factorVehicleForm.engine_power == '' || this.factorVehicleForm.engine_power == undefined) { this.factorVehicleForm.engine_power = 0; }
            const vehicleBrandId = this.factorVehicleForm.vehicle_brand_id;
            const vehicleModelId = this.factorVehicleForm.vehicle_model_id;
            const parameters = this.factorVehicleForm;
            parameters.account_id = this.$parent.form.account_id;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.vehicle_registration_status = this.factorForm.vehicle_registration_status;
            parameters.is_vehicle_active = this.factorForm.is_vehicle_active ? 1 : 0;
            parameters.recorded_factor_id = this.factorForm.recorded_factor_id;
            parameters.vehicle_brand_id = parameters.vehicle_brand_code;
            parameters.vehicle_model_id = parameters.vehicle_model_code;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.first_registration_date = parameters.first_registration_date !== null ? moment(parameters.first_registration_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.last_registration_date = parameters.last_registration_date !== null ? moment(parameters.last_registration_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            if(parameters.factor_detail_id == '-1') parameters.factor_detail_id = null;
            if(parameters.vehicle_group_id == '-1') parameters.vehicle_group_id = null;
            if(parameters.vehicle_type_id == '-1') parameters.vehicle_type_id = null;
            if(parameters.usage_type_id == '-1') parameters.usage_type_id = null;
            if(parameters.usage_detail_id == '-1') parameters.usage_detail_id = null;
            if(parameters.fuel_type_id == '-1') parameters.fuel_type_id = null;
            if(parameters.vehicle_brand_id == '-1') parameters.vehicle_brand_id = null;
            if(parameters.vehicle_model_id == '-1') parameters.vehicle_model_id = null;
            if(parameters.model_year == null) parameters.model_year = 0;
            axios.post('/api/Insurance/AddFactorVehicles', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.vehicleStatus = response.data.vehicle_status;
                    if(!this.vehicleStatus.is_vehicle_exist) {
                        this.$parent.transactionFactorList = response.data.factors;
                        this.$parent.isViewFactorModal = false;
                        if(this.$getSessionStorage('trn_tmp_session_id') == null || this.$getSessionStorage('trn_tmp_session_id') == '' || this.$getSessionStorage('trn_tmp_session_id') == undefined) {
                            this.$setSessionStorage('trn_tmp_session_id', this.$parent.transactionFactorList[0].tmp_session_id); 
                        }
                    }
                }
                this.factorVehicleForm.vehicle_brand_id = vehicleBrandId;
                this.factorVehicleForm.vehicle_model_id = vehicleModelId;
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorVehicleForm.vehicle_group_id == null) {this.factorVehicleForm.vehicle_group_id = '-1';}
                if(this.factorVehicleForm.vehicle_type_id == null) {this.factorVehicleForm.vehicle_type_id = '-1';}
                if(this.factorVehicleForm.usage_type_id == null) {this.factorVehicleForm.usage_type_id = '-1';}
                if(this.factorVehicleForm.usage_detail_id == null) {this.factorVehicleForm.usage_detail_id = '-1';}
                if(this.factorVehicleForm.fuel_type_id == null) {this.factorVehicleForm.fuel_type_id = '-1';}
                if(this.factorVehicleForm.vehicle_brand_id == null) {this.factorVehicleForm.vehicle_brand_id = '-1';}
                if(this.factorVehicleForm.vehicle_model_id == null) {this.factorVehicleForm.vehicle_model_id = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onEditFactorVehicle() {
            if(this.factorForm.factor_type != 1) return;
            this.$checkSelect2Validation('vehicleDetailForm');
            if(!document.forms['vehicleDetailForm'].reportValidity()){
                return;
            }
            if (this.factorVehicleForm.vehicle_price == null || this.factorVehicleForm.vehicle_price == '' || this.factorVehicleForm.vehicle_price == undefined) { this.factorVehicleForm.vehicle_price = 0; }
            if (this.factorVehicleForm.max_passenger == null || this.factorVehicleForm.max_passenger == '' || this.factorVehicleForm.max_passenger == undefined) { this.factorVehicleForm.max_passenger = 0; }
            if (this.factorVehicleForm.empty_weight == null || this.factorVehicleForm.empty_weight == '' || this.factorVehicleForm.empty_weight == undefined) { this.factorVehicleForm.empty_weight = 0; }
            if (this.factorVehicleForm.load_capacity == null || this.factorVehicleForm.load_capacity == '' || this.factorVehicleForm.load_capacity == undefined) { this.factorVehicleForm.load_capacity = 0; }
            if (this.factorVehicleForm.engine_power == null || this.factorVehicleForm.engine_power == '' || this.factorVehicleForm.engine_power == undefined) { this.factorVehicleForm.engine_power = 0; }
            const vehicleBrandId = this.factorVehicleForm.vehicle_brand_id;
            const vehicleModelId = this.factorVehicleForm.vehicle_model_id;
            const parameters = this.factorVehicleForm;
            parameters.account_id = this.$parent.form.account_id;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.vehicle_registration_status = this.factorForm.vehicle_registration_status;
            parameters.is_vehicle_active = this.factorForm.is_vehicle_active ? 1 : 0;
            parameters.id = this.factorForm.recorded_factor_id;
            parameters.session_id = this.$getUser().session_id;
            parameters.vehicle_brand_id = parameters.vehicle_brand_code;
            parameters.vehicle_model_id = parameters.vehicle_model_code;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.first_registration_date = parameters.first_registration_date !== null ? moment(parameters.first_registration_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.last_registration_date = parameters.last_registration_date !== null ? moment(parameters.last_registration_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            if(parameters.factor_detail_id == '-1') parameters.factor_detail_id = null;
            if(parameters.vehicle_group_id == '-1') parameters.vehicle_group_id = null;
            if(parameters.vehicle_type_id == '-1') parameters.vehicle_type_id = null;
            if(parameters.usage_type_id == '-1') parameters.usage_type_id = null;
            if(parameters.usage_detail_id == '-1') parameters.usage_detail_id = null;
            if(parameters.fuel_type_id == '-1') parameters.fuel_type_id = null;
            if(parameters.vehicle_brand_id == '-1') parameters.vehicle_brand_id = null;
            if(parameters.vehicle_model_id == '-1') parameters.vehicle_model_id = null;
            if(parameters.model_year == null) parameters.model_year = 0;
            axios.post('/api/Insurance/EditFactorVehicles', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.isViewFactorModal = false;
                }
                this.factorVehicleForm.vehicle_brand_id = vehicleBrandId;
                this.factorVehicleForm.vehicle_model_id = vehicleModelId;
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorVehicleForm.vehicle_group_id == null) {this.factorVehicleForm.vehicle_group_id = '-1';}
                if(this.factorVehicleForm.vehicle_type_id == null) {this.factorVehicleForm.vehicle_type_id = '-1';}
                if(this.factorVehicleForm.usage_type_id == null) {this.factorVehicleForm.usage_type_id = '-1';}
                if(this.factorVehicleForm.usage_detail_id == null) {this.factorVehicleForm.usage_detail_id = '-1';}
                if(this.factorVehicleForm.fuel_type_id == null) {this.factorVehicleForm.fuel_type_id = '-1';}
                if(this.factorVehicleForm.vehicle_brand_id == null) {this.factorVehicleForm.vehicle_brand_id = '-1';}
                if(this.factorVehicleForm.vehicle_model_id == null) {this.factorVehicleForm.vehicle_model_id = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSaveFactorProperty() {
            if(this.factorForm.factor_type != 2) return;
            this.$checkSelect2Validation('propertyDetailForm');
            if(!document.forms['propertyDetailForm'].reportValidity()){
                return;
            }
            const parameters = this.factorPropertyForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            if(parameters.usage_type_id == '-1') parameters.usage_type_id = null;
            if(parameters.usage_type_detail_id == '-1') parameters.usage_type_detail_id = null;
            if(parameters.construction_type_id == '-1') parameters.construction_type_id = null;
            if(parameters.construction_year_id == '-1') parameters.construction_year_id = null;
            if(parameters.property_type_id == '-1') parameters.property_type_id = null;
            axios.post('/api/Insurance/AddFactorProperty', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.transactionFactorList = response.data.factors;
                    this.$parent.isViewFactorModal = false;
                }
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorPropertyForm.usage_type_id == null) {this.factorPropertyForm.usage_type_id = '-1';}
                if(this.factorPropertyForm.usage_type_detail_id == null) {this.factorPropertyForm.usage_type_detail_id = '-1';}
                if(this.factorPropertyForm.construction_type_id == null) {this.factorPropertyForm.construction_type_id = '-1';}
                if(this.factorPropertyForm.construction_year_id == null) {this.factorPropertyForm.construction_year_id = '-1';}
                if(this.factorPropertyForm.property_type_id == null) {this.factorPropertyForm.property_type_id = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onEditFactorProperty() {
            if(this.factorForm.factor_type != 2) return;
            this.$checkSelect2Validation('propertyDetailForm');
            if(!document.forms['propertyDetailForm'].reportValidity()){
                return;
            }
            const parameters = this.factorPropertyForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.id = this.factorForm.recorded_factor_id;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            if(parameters.usage_type_id == '-1') parameters.usage_type_id = null;
            if(parameters.usage_type_detail_id == '-1') parameters.usage_type_detail_id = null;
            if(parameters.construction_type_id == '-1') parameters.construction_type_id = null;
            if(parameters.construction_year_id == '-1') parameters.construction_year_id = null;
            if(parameters.property_type_id == '-1') parameters.property_type_id = null;
            axios.post('/api/Insurance/EditFactorProperty', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.isViewFactorModal = false;
                }
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorPropertyForm.usage_type_id == null) {this.factorPropertyForm.usage_type_id = '-1';}
                if(this.factorPropertyForm.usage_type_detail_id == null) {this.factorPropertyForm.usage_type_detail_id = '-1';}
                if(this.factorPropertyForm.construction_type_id == null) {this.factorPropertyForm.construction_type_id = '-1';}
                if(this.factorPropertyForm.construction_year_id == null) {this.factorPropertyForm.construction_year_id = '-1';}
                if(this.factorPropertyForm.property_type_id == null) {this.factorPropertyForm.property_type_id = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSaveFactorPerson() {
            if(this.factorForm.factor_type != 3) return;        
            this.$checkSelect2Validation('personDetailForm');
            if(!document.forms['personDetailForm'].reportValidity()){
                return;
            }
            const parameters = this.factorPersonForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.birth_date = parameters.birth_date !== null ? moment(parameters.birth_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.position_date = parameters.position_date !== null ? moment(parameters.position_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.licence_date = parameters.licence_date !== null ? moment(parameters.licence_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            if(parameters.position_type_id == '-1') parameters.position_type_id = null;
            if(parameters.gender == '-1') parameters.gender = null;
            axios.post('/api/Insurance/AddFactorPerson', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.transactionFactorList = response.data.factors;
                    this.$parent.isViewFactorModal = false;
                }
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorPersonForm.position_type_id == null) {this.factorPersonForm.position_type_id = '-1';}
                if(this.factorPersonForm.gender == null) {this.factorPersonForm.gender = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onEditFactorPerson() {
            if(this.factorForm.factor_type != 3) return;        
            this.$checkSelect2Validation('personDetailForm');
            if(!document.forms['personDetailForm'].reportValidity()){
                return;
            }
            const parameters = this.factorPersonForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.id = this.factorForm.recorded_factor_id;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.birth_date = parameters.birth_date !== null ? moment(parameters.birth_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.position_date = parameters.position_date !== null ? moment(parameters.position_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            parameters.licence_date = parameters.licence_date !== null ? moment(parameters.licence_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            if(parameters.position_type_id == '-1') parameters.position_type_id = null;
            if(parameters.gender == '-1') parameters.gender = null;
            axios.post('/api/Insurance/EditFactorPerson', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.isViewFactorModal = false;
                }
                if(this.factorForm.factor_detail_id == null) {this.factorForm.factor_detail_id = '-1';}
                if(this.factorPersonForm.position_type_id == null) {this.factorPersonForm.position_type_id = '-1';}
                if(this.factorPersonForm.gender == null) {this.factorPersonForm.gender = '-1';}
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onSaveFactorOther() {
            if(this.factorForm.factor_type != 5) return;
            this.$checkSelect2Validation('otherDetailForm');
            if(!document.forms['otherDetailForm'].reportValidity()){
                return;
            }
            if (this.factorOtherForm.price == null || this.factorOtherForm.price == '' || this.factorOtherForm.price == undefined) { this.factorOtherForm.price = 0; }
            const parameters = this.factorOtherForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.invoice_date = parameters.invoice_date !== null ? moment(parameters.invoice_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            axios.post('/api/Insurance/AddFactorOther', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.transactionFactorList = response.data.factors;
                    this.$parent.isViewFactorModal = false;
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onEditFactorOther() {
            if(this.factorForm.factor_type != 5) return;
            this.$checkSelect2Validation('otherDetailForm');
            if(!document.forms['otherDetailForm'].reportValidity()){
                return;
            }
            if (this.factorOtherForm.price == null || this.factorOtherForm.price == '' || this.factorOtherForm.price == undefined) { this.factorOtherForm.price = 0; }
            const parameters = this.factorOtherForm;
            parameters.factor_detail_id = this.factorForm.factor_detail_id;
            parameters.registration_number = this.factorForm.registration_number;
            parameters.name = this.factorForm.name;
            parameters.id = this.factorForm.recorded_factor_id;
            parameters.session_id = this.$getUser().session_id;
            parameters.tmp_session_id = this.$getSessionStorage('trn_tmp_session_id');
            parameters.invoice_date = parameters.invoice_date !== null ? moment(parameters.invoice_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            axios.post('/api/Insurance/EditFactorOther', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.$parent.isViewFactorModal = false;
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        onFindAddressByPostCode(){
            this.isSearchingByPostCode = true;
            this.factorPropertyForm.address_id = '-1';
            this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
            const that = this;
            const parameters = {
                PostCode: this.factorPropertyForm.postcode,
                SessionId: this.$getUser().session_id
            };
            axios.post('api/GetCompanyAccounts/FindAddressByPostCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data.results) {
                        response.data.results.forEach(value => {
                            value.DPA.id = value.DPA.UPRN;
                            value.DPA.text = value.DPA.ADDRESS;
                            that.addressList.push(value.DPA);
                        });
                    }
                }
            })
            .finally(function () { 
                that.isSearchingByPostCode = false;
            });  
        },
        onFindAddressByAddressCode(){
            this.isSearchingByAddressCode = true;
            this.factorPropertyForm.address_id = '-1';
            this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
            const that = this;
            const parameters = {
                PostCode: this.factorPropertyForm.uprn,
                SessionId: this.$getUser().session_id
            };
            axios.post('api/GetCompanyAccounts/FindAddressByAddressCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data.results) {
                        response.data.results.forEach(value => {
                            value.DPA.id = value.DPA.UPRN;
                            value.DPA.text = value.DPA.ADDRESS;
                            that.addressList.push(value.DPA);
                        });
                    }
                }
            })
            .finally(function () { 
                that.isSearchingByAddressCode = false;
            });  
        },
        onSelectAddressSearchResult(item){
            this.factorPropertyForm.address = item.ADDRESS;
            this.factorPropertyForm.city = item.POST_TOWN;
            this.factorPropertyForm.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
            this.factorPropertyForm.x_coordinate = item.X_COORDINATE;
            this.factorPropertyForm.postcode = item.POSTCODE;
            this.factorPropertyForm.apartment_no = item.SUB_BUILDING_NAME;
            this.factorPropertyForm.street_no = item.THOROUGHFARE_NAME;
            this.factorPropertyForm.uprn = item.UPRN;
            this.factorPropertyForm.udprn = item.UDPRN;
            let buildingName = (item.BUILDING_NAME !== null && item.BUILDING_NAME !== undefined && item.BUILDING_NAME !== '') ? item.BUILDING_NAME : '';
            if(item.BUILDING_NUMBER !== null && item.BUILDING_NUMBER !== undefined && item.BUILDING_NUMBER !== '') {
                buildingName = item.BUILDING_NUMBER;
            }
            this.factorPropertyForm.building_no = buildingName;
        },
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.factorPropertyForm.address = item.fullAddress;
            this.factorPropertyForm.city = item.town_or_city;
            this.factorPropertyForm.district = item.district;
            this.factorPropertyForm.postcode = item.postcode;
            this.factorPropertyForm.building_no = item.building_number;
        },    
        clearFactorForm() {
            this.vehicleStatus = {
                is_vehicle_exist: false,
                existed_vehicle_id: null
            };
            this.factorForm = {
                factor_type: 1,
                factor_detail_id: '-1',
                registration_number: null,
                name: null,
                recorded_factor_id: '-1',
                vehicle_registration_status: 1,
                is_vehicle_active: true
            };
            this.factorVehicleForm = {
                vehicle_group_id: '-1',
                vehicle_type_id: '-1',
                usage_type_id: '-1',
                usage_detail_id: '-1',
                fuel_type_id: '-1',
                licence_number: null,
                vehicle_brand_id: '-1',
                vehicle_model_id: '-1',
                vehicle_brand_code: null,
                vehicle_model_code: null,
                vehicle_code: null,
                engine_number: null,
                chassis_number: null,
                model_year: null,
                color: null,
                max_passenger: 0,
                empty_weight: 0,
                load_capacity: 0,
                engine_power: 0,
                vehicle_price: 0,
                last_registration_date: null,
                first_registration_date: null,
            };
            this.factorPropertyForm = {
                postcode: null,
                address_id: '-1',
                address: null,
                city: null,
                district: null,
                country: '-1',
                apartment_no: null,
                building_no: null,
                block_no: null,
                floor_no: null,
                street_no: null,
                residence_year: null,
                residence_month: null,
                usage_type_id: '-1',
                usage_type_detail_id: '-1',
                property_type_id: '-1',
                construction_type_id: '-1',
                construction_year_id: '-1',
                property_area: '0',
                residence_type_id: 0,
                owner_name: null,
                uprn: null,
                udprn: null
            };
            this.factorPersonForm = {
                gender: '-1',
                birth_date: null,
                position_type_id: '-1',
                position_date: null,
                licence_number: null,
                licence_date: null,
            };
            this.factorOtherForm = {
                invoice_date: null,
                brand: null,
                model: null,
                serial_no: null,
                invoice_no: null,
                price: 0,
                producer: null,
            };
        },
        onSelectExistedVehicleToEdit() {
            this.recordedFactorList.forEach(element => {
                if(element.id == this.vehicleStatus.existed_vehicle_id) {
                    this.factorForm.recorded_factor_id = element.id;
                    this.factorForm.factor_type = element.factor_type;
                    this.factorForm.vehicle_registration_status = 1;
                }
            });
            this.getVehicleDetailsById(true);
            this.vehicleStatus = {
                is_vehicle_exist: false,
                existed_vehicle_id: null
            };
        },
    }
}
</script>