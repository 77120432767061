<template> 
    <div> 
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Stock Tracking Group') }}
                </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>  
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">{{ $t('List Types') }}</router-link></li>    
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'StockTrackingGroupList' }">{{ $t('Stock Tracking Group') }}</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Stock Tracking Group') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/StockTrackingGroupList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="trackingUpdateActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="trackingUpdateActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteTrackingGroup(form.id)"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                            </svg>
                            </span>{{ $t('Delete') }} </a>
                        </li>
                    </ul>
                </div>  
            </div>
        </div>
        <div class="content flex-row-fluid mt-6" id="kt_content">
            <RequiredField></RequiredField>
            <div class="row">
                <div class="tab-content" id="myTabContent">
                    <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                        <div class="p-10"> 
                            <div class="row">
                                <div class="col-lg-12 mt-0"> 
                                    <div class="d-flex flex-row row">
                                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                                <div class="card-body">
                                                    <form id="kt_ecommerce_settings_general_form"
                                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                        <div class="fv-row mb-7 row">
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    {{ $t('Tracking Code') }}
                                                                </label>
                                                                <input type="text" class="form-control " name="code" v-model="form.code">
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    {{ $t('Tracking Name') }}
                                                                </label>
                                                                <input type="text" class="form-control " name="name" v-model="form.name">
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>{{ $t('Description') }}</span>
                                                                </label>
                                                                <textarea rows="2" class="form-control " name="description" v-model="form.description"></textarea>
                                                            </div>
                                                            <div class="col-lg-12 mt-5">
                                                                <input class="form-check-input" type="checkbox" value="" id="isReadOnly" v-model="form.read_only">
                                                                <label class="fs-6 fw-bold form-label ms-3" for="isReadOnly">
                                                                    {{ $t('Read Only') }} ({{ $t('Values') }})
                                                                </label>
                                                        </div> 
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                                <div class="card-body">
                                                    <form id="kt_ecommerce_settings_general_form"
                                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                            
                                                        <div class="fv-row mb-7">
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    {{ $t('Input Type') }} 
                                                                </label>
                                                                <Select2 v-model="form.input_type" :options="inputTypeList" :settings="{width: '100%'}" :disabled="this.itemList.length > 0"/>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    {{ $t('Data Type') }} 
                                                                </label>
                                                                <Select2 v-model="form.data_type" :options="dataTypeList" :settings="{width: '100%'}" :disabled="this.itemList.length > 0"/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel" v-if="form.input_type == '2'">
                        <div class="p-10"> 
                            <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="p-5">
                                        <div class="d-flex flex-stack flex-wrap mb-5">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                            <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                data-bs-target="#kt_modal_add_list_item" @click="openItemModal('addItem')"
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                :disabled="form.data_type == 0">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                            transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                            fill="currentColor">
                                                        </rect>
                                                    </svg>
                                                </span>
                                                {{ $t('Add Item') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                                data-bs-target="#kt_modal_add_list_item" @click="openItemModal('editItem')"
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :disabled="form.data_type == 0 ||  isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                        </svg>
                                                </span>
                                                {{ $t('Edit') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteItemData()" :disabled="form.data_type == 0 ||  isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                {{ $t('Remove') }}
                                            </button>
                                             <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllItems()" :disabled="form.data_type == 0">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                {{ $t('Remove All (!)') }}
                                            </button>
                                            </div>
                                        </div>
                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive min-h-300px">
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                            key-expr="id" 
                                                            :data-source="itemList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" > 

                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="false"/>
                                                        <DxPaging :page-size="10"/>
                                                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>

                                                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                        <DxColumn data-field="currency" caption="List Code"  cell-template="code-cell-template"/>
                                                        <DxColumn data-field="name" caption="List Name" cell-template="name-cell-template"/> 
                                                        <DxColumn data-field="value" caption="Value" alignment="right" cell-template="value-cell-template"/> 
                                                        <DxColumn data-field="description" caption="Description" cell-template="description-cell-template"/> 

                                                        <template #sales-channel-header>
                                                            <div>Sales</div><div>Channel</div>
                                                        </template>
                                                        
                                                            <!-- Cell Templates -->
                                                        <template #is-selected-cell-template="{ data }"> 
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template #code-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.code }}
                                                            </span>
                                                        </template>
                                                        <template #name-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.name }}
                                                            </span>
                                                        </template>
                                                        <template #value-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.value }}
                                                            </span>
                                                        </template> 
                                                        <template #description-cell-template="{ data }"> 
                                                            <span>
                                                                {{ data.data.description }}
                                                            </span>
                                                        </template>
                                                </DxDataGrid>
                                            </div>
                                            <div class="row" v-if="itemList && itemList.length > 0">
                                                <div
                                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                    <div class="dataTables_info" role="status" aria-live="polite">
                                                        {{ itemList.length }} {{ $t('Records') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                            </div>
                        </div>
                    </div> 
                    <div class="card card-xxl-stretch tab-pane fade show active mt-5" id="kt_accounting_1" role="tabpanel">
                        <div class="p-10"> 
                            <div class="row">
                                <div class="col-lg-12"> 
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> {{ $t('Update Stock Tracking Group') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>                   
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_add_list_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span class="fw-bolder" v-if="this.modalType == 'addItem'"> {{ $t('Add Item') }} </span>
                            <span class="fw-bolder" v-if="this.modalType == 'editItem'"> {{ $t('Edit Item') }} </span>                     
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                    {{ $t('List Code') }}
                                                </label>
                                                <div class="col-lg-8">
                                                    <input type="text" class="form-control " name="code" v-model="itemForm.code">
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                    {{ $t('List Name') }} 
                                                </label>
                                                <div class="col-lg-8">
                                                    <input type="text" class="form-control " name="name" v-model="itemForm.name">
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                    {{ $t('Value') }}
                                                </label>
                                                <div class="col-lg-8">
                                                    <!-- TEXT -->
                                                    <input type="text" class="form-control " name="value" v-model="itemForm.value" v-if="this.form.data_type == 1">
                                                    <!-- NUMBER -->
                                                    <CurrencyInput type="text" class="form-control" name="total_length" v-model="itemForm.value" :precision="0" @focus="$event.target.select()" v-if="this.form.data_type == 2"/>
                                                    <!-- NUMBER DECIMAL -->
                                                    <CurrencyInput type="text" class="form-control" name="total_length" v-model="itemForm.value" :precision="2" @focus="$event.target.select()" v-if="this.form.data_type == 3"/>
                                                    <!-- DATE -->
                                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" 
                                                        v-maska="'##.##.####'" 
                                                        v-model="itemForm.value" 
                                                        inputClassName="form-control" 
                                                        format="dd.MM.yyyy" autoApply 
                                                        :clearable="false" 
                                                        weekNumbers :monthChangeOnScroll="'inverse'" 
                                                        v-if="this.form.data_type == 4"></Datepicker>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t('Description') }}</label>
                                                <div class="col-lg-8">
                                                    <input type="text" class="form-control " name="description"
                                                        v-model="itemForm.description">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="this.saveItemData()" v-if="this.modalType == 'addItem'">
                                            <span class="indicator-label">{{ $t('Add to List') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                            @click="this.updateItemData()">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Component Alias: `STGU001`
import axios from 'axios' 
import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn } from 'devextreme-vue/data-grid';
 
export default {
  name: "StockTrackingGroupUpdate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn
  },
  data() {
    return {
        isProgressing: false,
        isItemSaving: false,
        form: { 
            id: this.$getSessionStorage('record_id'),
            name: null,
            code: null,
            description: null,
            session_id: this.$getUser().session_id,
            data_type: 0,
            input_type: 0,
            sales_channel: '-1',
            tmp_session_id: null,
            read_only: false
        },
        itemForm: {
            id: null,
            name: null,
            code: null,
            description: null,
            value: null
        },
        dataTypeList: [
            { id: 0, text: 'Select an option' },
            { id: 1, text: 'Text' },
            { id: 2, text: 'Number' },
            { id: 3, text: 'Number (Float)' },
            { id: 4, text: 'Date' },
        ],
        inputTypeList: [
            { id: 0, text: 'Select an option' },
            { id: 1, text: 'Free Data' },
            { id: 2, text: 'List' }
        ],
        salesChannelList: [
            { id: '-1', text: 'Select an option' }
        ],
        itemList: [],
        modalType: 'addItem',
        menuSelection: '/StockTrackingGroupHelppage',
        iconView : false,
        pageSize: 10
    };
  },
    computed: { 
   isItemButtonDisabled() {
       return this.itemList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.itemList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.itemList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {  
        this.salesChannelList = [];
        this.$setSessionStorage('refreshList', true);
        const parameters = { 
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id
        }; 
        axios.post('/api/StockTrackingGroups/CPGetStockTrackingGroupsById', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                    this.form = response.data.header;
                    this.itemList = response.data.items;
                    this.form.read_only = response.data.header.read_only == 1 ? true : false;
                    if(this.itemList.length > 0){
                        this.$setSessionStorage('tmp_session_id', response.data.items[0].tmp_session_id);
                    } 
                    const parameters = { 
                        session_id: this.$getUser().session_id,
                        list_name: ["sales_channel"],
                    }; 
                    axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
                        
                            this.salesChannelList = response.data.sales_channel;
                            this.salesChannelList.unshift({ id: 0, text: 'Select an option'});  

                            this.form.sales_channel = (this.form.sales_channel == '' || this.form.sales_channel == null) ? 0 : this.form.sales_channel;
                        });  
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
          .catch(function (error) {
              console.log(error);
          });          
    },
    onSelectItem(item) {
        this.itemList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });     
    },
    saveData(){
        const parameters = {  
            session_id: this.$getUser().session_id,
            id: this.$getSessionStorage('record_id'),
            code: this.form.code,
            name: this.form.name,
            description: this.form.description,
            input_type: this.form.input_type,
            data_type: this.form.data_type,
            sales_channel: this.form.sales_channel,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            read_only: this.form.read_only == true ? 1 : 0
        }; 
        parameters.sales_channel = parameters.sales_channel == '' || parameters.sales_channel == 0 ? null : parameters.sales_channel;
        axios.post('/api/StockTrackingGroups/CPUpdateStockTrackingGroup', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    new Swal( { icon: 'success', title: 'Saved...', text: 'Data saved successfully..!' });   
                    this.getData();
                    this.$setSessionStorage('refreshList', true);
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    deleteTrackingGroup(id) { 
          this.$swal.fire({
              title: 'Are you sure you want to delete this data?',
              confirmButtonColor: '#f1416c',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: 'Yes',
              icon: 'error'
          }).then((result) => { 
              if (result.isConfirmed) {
              const parameter = {
                  id: id,
                  session_id: this.$getUser().session_id,
              }; 
              axios.post('/api/StockTrackingGroups/CPDeleteStockTrackingGroup', parameter, {'Content-Type': 'application/json'})
                  .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') { 
                      new Swal( { icon: 'error', title: 'Deleted...', text: 'Data deleted..!' });   
                      this.$setSessionStorage('refreshList', true);
                      this.$router.push({ name: 'StockTrackingGroupList' }); 
                    }
                  })
                  .catch(function (error) {
                      console.log(error);
                  });
              }
          })  
      },
    openItemModal(value) {
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.modalType = value;
        this.isItemSaving = false;

        this.itemForm = {
            id: null,
            name: null,
            code: null,
            description: null,
            value: null
        };

        if (this.modalType == 'editItem') {                        
            this.itemList.forEach((item) => {
                if (item.isSelected) {
                    this.itemForm.id = item.id;
                    this.itemForm.name = item.name;
                    this.itemForm.code = item.code;
                    this.itemForm.description = item.description;
                    this.itemForm.value = item.value;
                }
            });
        }
    }, 
    saveItemData(){
         if(this.itemForm.code == null || this.itemForm.code == '' || this.itemForm.name == null || this.itemForm.name == '' || this.itemForm.value == null || this.itemForm.value == ''){ 
            this.$swal("Warning", "Required fields can not be empty..!", 'info'); 
            return; 
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            code: this.itemForm.code,
            name: this.itemForm.name,
            value: this.itemForm.value,
            description: this.itemForm.description
        }; 
        parameters.value = String(parameters.value);
        axios.post('/api/StockTrackingGroups/CPAddStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.itemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } 
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    updateItemData(){
         if(this.itemForm.code == null || this.itemForm.code == '' || this.itemForm.name == null || this.itemForm.name == '' || this.itemForm.value == null || this.itemForm.value == ''){ 
            this.$swal("Warning", "Required fields can not be empty..!", 'info');
            return; 
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            code: this.itemForm.code,
            name: this.itemForm.name,
            value: this.itemForm.value,
            description: this.itemForm.description,
            id: this.itemForm.id
        }; 
        parameters.value = String(parameters.value);
        axios.post('/api/StockTrackingGroups/CPUpdateStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.itemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } 
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    }, 
    deleteItemData(){
        this.itemList.forEach((item) => {
            if (item.isSelected) {
                this.itemForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    id: this.itemForm.id
                }; 
                axios.post('/api/StockTrackingGroups/CPDeleteStockTrackingListItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.itemList = response.data;
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllItems(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                }; 
                axios.post('/api/StockTrackingGroups/CPDeleteStockTrackingListItemAll', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.itemList = [];
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/StockTrackingGroupHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
      }, 
  },
  mounted() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  }
}; 
</script>