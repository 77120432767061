<template> 
    <div>
        <div class="card card-xxl-stretch card-bordered">
            <div class="card-header">
                <div class="card-title">
                    {{ $t('Account Group') }}
                </div>
                <div class="card-toolbar" v-if="routeName !== 'PolicyDataImport' || this.accountInfo.is_record_exists == 1">
                    <button v-if="this.accountInfo.is_main_group == 0 && this.accountMemberFilteredList.length == 0" type="button" class="btn btn-sm btn-light-success me-3" @click="onChangeAccountGroupStatus(true)">
                        <i class="bi bi-plus-lg"></i>
                        {{ $t('Create This Account As Main Group') }}
                    </button>
                    <button v-if="this.accountInfo.is_main_group == 1" type="button" class="btn btn-sm btn-light-danger me-3" @click="onChangeAccountGroupStatus(false)">
                        <i class="bi bi-dash-lg"></i>
                        {{ $t('Remove This Account From Main Groups') }}
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-stack flex-wrap mb-5" v-if="this.accountInfo.is_main_group == 0">
                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                        <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="getAccountsForGroup('add')" v-if="this.accountMemberList.length == 0">
                            <!-- data-bs-toggle="modal" data-bs-target="#kt_modal_add_member" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" -->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                        fill="currentColor">
                                    </rect>
                                </svg>
                            </span>
                            {{ $t('Add') }}
                        </button>
                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="removeAccountFromGroup()" v-if="this.accountMemberList.length > 0">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                            {{ $t('Remove') }}
                        </button>
                    </div>
                </div>
                <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="group_account_id" 
                                :data-source="accountMemberList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" > 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="false"/>
                                <DxPaging :page-size="1000"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxColumn data-field="group_type" :caption="$t('Group Type')" cell-template="group_type-cell-template"/>
                                <DxColumn data-field="group_account_code" :caption="$t('Code')" cell-template="group_account_code-cell-template"/> 
                                <DxColumn data-field="group_account_name" :caption="$t('Name')" cell-template="group_account_name-cell-template"/> 
                                <DxColumn data-field="relation_type_name" :caption="$t('Relation Type')" cell-template="relation_type_name-cell-template" header-cell-template="relation-type-header"/> 
                                
                                <template #relation-type-header>
                                    <div>{{ $t('Relation') }}</div> <div>{{ $t('Type') }}</div>
                                </template>

                                <template #group_type-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.group_type }}
                                    </span>
                                </template>
                                <template #group_account_code-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.group_account_code }}
                                    </span>
                                </template>
                                <template #group_account_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.group_account_name }}
                                    </span>
                                </template>
                                <template #relation_type_name-cell-template="{ data }"> 
                                    <span>
                                        {{ data.data.relation_type_name }}
                                    </span>
                                </template>

                                <DxSummary>
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                </DxSummary>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Group Member -->
        <div class="modal" :class="{'fade show d-block': isViewModal, 'd-none': !isViewModal}" id="kt_modal_add_member" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content border border-primary">
                    <div class="modal-header bg-primary">
                        <h2 v-if="modalType == 'add'" class="modal-title fw-bolder col-md-10 text-white">{{ $t('Select Group') }}</h2>  
                        <h2 v-if="modalType == 'edit'" class="modal-title fw-bolder col-md-10 text-white">{{ $t('Edit Group') }}</h2>  
                        <div class="btn btn-icon btn-sm"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeMemberModal" @click="isViewModal = false">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body" :class="{'overlay overlay-block': this.isItemSaving == true}">                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'group_account_id'">
                                        {{ $t('Group') }}
                                    </label> 
                                    <Select2 v-model="form.group_account_id" :options="groupAccountList" :settings="{width: '100%', dropdownParent: '#kt_modal_add_member .modal-content'}" v-custom name="group_account_id"/>  
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="fv-row">
                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'relation_type_id'">
                                        {{ $t('Relation Type') }}
                                    </label> 
                                    <Select2 v-model="form.relation_type_id" :options="relationTypeList" :settings="{width: '100%', dropdownParent: '#kt_modal_add_member .modal-content'}" v-custom name="relation_type_id"/>  
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-10" id="kt_add_user_button">
                                    <div class="d-grid gap-2"> 
                                        <button v-if="modalType == 'add'" type="button" class="btn btn-light-success border border-success btn-lg"
                                            @click="addAccountToGroup()">
                                            <span class="indicator-label">{{ $t('Add To Group') }}</span> 
                                        </button>
                                        <button v-if="modalType == 'edit'" type="button" class="btn btn-light-primary border border-primary btn-lg"
                                            @click="editVendorItem()">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios"; 
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary } from 'devextreme-vue/data-grid';

export default {
  name: "AccountGroupTab",
  props: {
    accountInfo: Object
  },
  components:{ DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxTotalItem, DxSummary
  },
  data() {
    return {
        accountMemberList: [],
        groupAccountList: [],
        relationTypeList: [],
        modalType: 'add',
        form: {
            relation_type_id: '-1',
            group_account_id: '-1',
            isSelectedAll: false
        },
        isViewModal: false,
        routeName: this.$route.name
    };
  }, 
  computed: {
        previewDate(){
            return (value)=>{
                if (value) {
                    return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        isButtonDisabled() {
            return this.accountMemberList.find(item => item.isSelected == true );
        },
        accountMemberFilteredList() {
            return this.accountMemberList.filter(p => !p.is_admin);
        }
	},
  methods: {
    getData() {
        const parameters = {
            session_id: this.$getUser().session_id,
            member_account_id: this.accountInfo.id,
            policy_transaction_id: this.routeName == 'PolicyDataImport' ? this.accountInfo.policy_transaction_id : null
        };  
        axios.post('/api/CompanyAccounts/GetAccountGroups', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.accountMemberList = response.data;
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onChangeAccountGroupStatus(isMainGroup) {
        this.$swal.fire({
            title: isMainGroup == true ? 'Are you sure you want to set this account as a main group?': 'Are you sure you want to remove this account from main groups? There may be accounts related to this group. If you confirm, this relation will be cancelled',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    account_id: this.accountInfo.id,
                    is_main_group: isMainGroup ? 1 : 0
                };  
                axios.post('/api/CompanyAccounts/SetAccountAsMainGroup', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.accountMemberList = response.data.groups;
                    this.$parent.onChangeIsMainGroup(response.data.is_main_group);
                }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
    },
    getAccountsForGroup() {
        this.form = {
            relation_type_id: '-1',
            group_account_id: '-1',
            isSelectedAll: false
        };
        this.groupAccountList = [{id: '-1', text: 'Select an option'}];
        this.relationTypeList = [{id: '-1', text: 'Select an option'}];
        this.isViewModal = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            list_type: 20, // Relation Type: 20
            get_access_status: 0
        };        
        const parametersTwo = {
            session_id: this.$getUser().session_id,
            account_type: this.accountInfo.account_type,
            member_account_id: this.accountInfo.id,
        };        
        const requestOne =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/CompanyAccounts/GetAccountsForGroup', parametersTwo, {'Content-Type': 'application/json'});         
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.relationTypeList= responses[0].data;
            this.groupAccountList = responses[1].data;
            this.groupAccountList.unshift({id: '-1', text: 'Select an option'});
            this.relationTypeList.unshift({id: '-1', text: 'Select an option'});
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    addAccountToGroup() {
        if(this.form.group_account_id == '-1' || this.form.group_account_id == null || this.form.group_account_id == '' || this.form.group_account_id == undefined) {
            this.$swal("Failed", "Group selection is required..!", 'error');
            return;
        }
        if(this.form.relation_type_id == '-1' || this.form.relation_type_id == null || this.form.relation_type_id == '' || this.form.relation_type_id == undefined) {
            this.$swal("Failed", "Relation Type selection is required..!", 'error');
            return;
        }
        const parameters = {
            session_id: this.$getUser().session_id,
            member_account_id: this.accountInfo.id,
            group_account_id: this.form.group_account_id,
            relation_type_id: this.form.relation_type_id !== '-1' ? this.form.relation_type_id : null,
            policy_transaction_id: this.routeName == 'PolicyDataImport' ? this.accountInfo.policy_transaction_id : null
        };  
        axios.post('/api/CompanyAccounts/AddAccountToGroup', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.accountMemberList = response.data;
            this.isViewModal = false;
            if(this.routeName == 'PolicyDataImport') this.$parent.$parent.getTransactionRowById(this.$parent.selectedTransaction.id, 1);
            this.$refs.closeMemberModal.click();
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    removeAccountFromGroup() {
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    member_account_id: this.accountInfo.id,
                    group_account_id: this.accountMemberList[0].group_account_id,
                    is_delete_all: 0,
                    policy_transaction_id: this.routeName == 'PolicyDataImport' ? this.accountInfo.policy_transaction_id : null
                }; 
                axios.post('/api/CompanyAccounts/RemoveAccountFromGroup', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.accountMemberList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    }
  },
  created() {
    this.getData();
  },
}; 
</script>