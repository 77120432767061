<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-xxl-stretch mb-6">
                <div class="card-header border-0" style="min-height: 44px;">
                    <div class="card-title">
                    </div>
                    <div class="card-toolbar">
                        <label class="required text-gray-800">Required field</label>
                        <i class="bi bi-gear fs-2 ms-3 cursor-pointer" v-if="userData.permission_type == 1"
                            @click="openValidationModal()" 
                            data-bs-toggle="modal" 
                            data-bs-target="#kt_modal_validation_modal" 
                            data-kt-menu-trigger="click" 
                            data-kt-menu-placement="bottom-end"></i>   
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_validation_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content border border-info">
                    <div class="modal-header bg-info">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            Validations
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-info" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y">
                        <div class="table-responsive">
                            <table class="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <th>Field</th>
                                        <th class="text-end">Is Required?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item) in fields" v-bind:key="item" class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                                        <td>{{ item.route }}.<strong>{{  item.field }} </strong></td>
                                        <td class="text-end">
                                            <input class="form-check-input fs-6 my-0 fw-bold form-label me-3" :class="{'form-check-danger': item.is_locked}"
                                                type="checkbox" 
                                                v-model="item.is_required" 
                                                @change="onSaveValidation(item)"
                                                :disabled="item.is_locked">
                                        </td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"; 
import Swal from "sweetalert2";
export default {
    name: "RequiredField",
    props: {
        routeName: String
    },
    data() {
        return {  
            route: this.$route.name,
            fields: [],
            userData: this.$getUser()
        }
    },
    methods: {
        openValidationModal(isRefreshFields){
            const params = {
                route: (this.routeName !== null && this.routeName !== '' && this.routeName !== undefined) ? this.routeName : this.route,
                session_id: this.$getUser().session_id
            };
            axios.post('api/FormValidations/GetFormFields', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data != null){  
                    this.fields = response.data;

                    if(isRefreshFields) {
                        var frmValues = JSON.parse(sessionStorage.getItem('frm_val_list'));
                        if(frmValues){
                            if(this.fields.length > 0 && frmValues.length > 0) {
                                this.fields.forEach(responseItem => {
                                    frmValues.forEach(fieldItem => {
                                        if(responseItem.route == fieldItem.route && responseItem.field == fieldItem.field) {
                                            fieldItem.is_required = responseItem.is_required;
                                            fieldItem.is_locked = responseItem.is_locked;
                                        }
                                    });
                                });
                                sessionStorage.setItem('frm_val_list', JSON.stringify(frmValues));
                            }
                        }
                    }
                }
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'No fields found..!' });
            });
        },
        onSaveValidation(item){
            const params = {
                route: (this.routeName !== null && this.routeName !== '' && this.routeName !== undefined) ? this.routeName : this.route,
                field: item.field,
                is_required: item.is_required == true ? 1 : 0,
                session_id: this.$getUser().session_id
            };
            axios.post('api/FormValidations/SetFormFields', params, {'Content-Type': 'application/json'})
            .then(() => { 
                this.openValidationModal(true);
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'No fields found..!' });
            }); 
        }
    },
};  
</script>