<template> 
  <div>
    <div class="card shadow-sm">
        <div class="card-header">
            <h3 class="card-title">{{ $t('Policy Data Import') }}</h3>
            <div class="d-flex flex-stack flex-wrap mb-2">
                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                    <button ref="openTransactionModal" v-if="this.$isCredentialActive(77,'I')" type="button" class="btn btn-sm btn-primary fw-bold required me-3" data-bs-toggle="modal" :disabled="isImporting"
                        data-bs-target="#kt_modal_add_item" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="this.getLists()">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                    fill="currentColor">
                                </rect>
                            </svg>
                        </span>
                        {{ $t('Add Policy Import List') }}
                    </button>
                    
                    <div class="dropdown" v-if="this.$isCredentialActive(77,'D')">
                        <button class="btn btn-sm btn-light-info fw-bold border border-info dropdown-toggle me-3" data-bs-toggle="dropdown" aria-expanded="true" id="cashActions" type="button" :disabled="isImporting">
                            <span class="svg-icon svg-icon-muted">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                </svg>
                            </span>
                            {{$t('Actions')}}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="cashActions">
                            <li>
                                <div class="menu-content fs-6 text-gray-900 fw-bold px-2 py-3 w-200px">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="deleteMode" @change="toggleDeleteMode()">
                                        <label class="form-check-label fw-bold" for="flexSwitchCheckDefault">{{$t('Multi Delete Mode')}}</label>
                                    </div>
                                </div>
                            </li> <div class="separator opacity-75"></div>
                            <li>
                                <button class="dropdown-item fw-bold fs-6 p-2" :class="{'text-danger': this.selectedRowDeleteKeys.length > 0}" @click="this.onDeleteSelectedTransactions()" :disabled="this.selectedRowDeleteKeys.length == 0">
                                    {{ $t('Delete Selected') }} <span v-if="tempPolicyList != null">({{ this.selectedRowDeleteKeys.length }})</span>
                                </button>
                            </li> <div class="separator opacity-75"></div>
                            <li>
                                <button class="dropdown-item fw-bold fs-6 p-2" @click="onDeleteAllTempTransactions()" :disabled="isImporting">
                                    {{ $t('Empty Table') }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-2">
                    <label class="fs-6 fw-bold form-label">
                        <span>{{ $t('Status') }}</span> 
                    </label>   
                    <Select2 v-model="filterForm.record_status" :disabled="isImporting" :options="filterStatusList" :settings="{ width: '100%' }"/>  
                </div>  
                <div class="col-lg-2">
                    <label class="fs-6 fw-bold form-label">
                        <span>{{ $t('Producer') }}</span> 
                    </label>   
                    <Select2 v-model="filterForm.supplier_producer_id" :disabled="isImporting" :options="producerCompanyAccountList" :settings="{ width: '100%' }"/>  
                </div> 
                <div class="col-lg-2">
                    <label class="fs-6 fw-bold form-label">
                        <span>{{ $t('Template Group') }}</span> 
                    </label>   
                    <Select2 v-model="filterForm.template_group_id" :disabled="isImporting" :options="templateGroupList" :settings="{ width: '100%' }"/>  
                </div>
                <div class="col-lg-6">
                    <div class="d-flex justify-content-end align-self-center mt-10"> 
                        <Popper hover placement="top" v-if="this.$isCredentialActive(77,'R')">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper>
                        <button type="button" v-if="this.$isCredentialActive(77,'R')" class="btn btn-light border border-gray-400 me-3" @click="this.getData()" data-kt-indicator="on" :disabled="isImporting"> 
                            <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z" fill="currentColor"/>
                            <path opacity="0.3" d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isTempLoading"> {{ $t('List Transactions') }} </span>
                            <span class="indicator-progress" v-if="this.isTempLoading">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                        <button type="button" class="btn btn-sm btn-info fw-bold me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_update_selected_data" @click="this.onOpenUpdateModal()"
                            v-if="tempPolicyList != null && this.$isCredentialActive(77,'U')" :disabled="this.selectedRowKeys.length == 0 || isImporting "> 
                            <span class="svg-icon svg-icon-primary svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
                            <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
                            <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Update Selected Data') }} <span v-if="tempPolicyList != null">({{ this.selectedRowKeys.length }})</span>
                        </button>
                        <button type="button" class="btn btn-sm btn-light d-none" ref="LoadingModal" data-bs-toggle="modal" data-bs-target="#kt_modal_loading" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        </button>
                        <button v-if="this.$isCredentialActive(77,'I')" type="button" class="btn btn-sm btn-success" @click="onSaveReadyPolicies()" :disabled="this.selectedRowKeys.length == 0 || isImporting"> 
                            <span class="svg-icon svg-icon-muted svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <path opacity="0.5" d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z" fill="currentColor"/>
                            <path d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Save Ready Policies') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="separator my-5 border-gray-300"></div>
            <div class="row">
                <div class="card card-xxl-stretch">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive-lg">
                            <DxDataGrid id="gridContainer" 
                                ref="dxTempPolicyList"
                                :show-borders="true" 
                                key-expr="id" 
                                :data-source="tempPolicyList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true"
                                :cache_enabled="true"
                                @exporting="this.$onExporting($event, 'Policy-temp-list')"
                                :v-model:selected-row-keys="selectedRowKeys"
                                @selection-changed="onSelectionChanged">
                                <!-- @cell-prepared="onCellBackground" :height="900" @cell-prepared="onCellPrepared" -->

                                <DxSelection mode="multiple" :select-all-mode="allMode" show-check-boxes-mode="always" v-if="!deleteMode"/>
                                <DxSelection mode="multiple" show-check-boxes-mode="always" v-if="deleteMode"/>
                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="50"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes(tempPolicyList, 50)" :show-page-size-selector="showPageSizeSelector(tempPolicyList, 50)" :show-info="true" :show-navigation-buttons="true"/> 
                                <DxSearchPanel :visible="true"/>

                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                
                                <DxColumn data-field="customer_group_name" caption="Group" css-class="align-middle"/> 
                                <DxColumn data-field="record" caption="Status" cell-template="record-cell-template" css-class="align-middle" :allow-filtering="false"/> 
                                <DxColumn data-field="policy_regulation_date" data-type="date" format="dd.MM.yyyy" caption="Date" cell-template="policy_regulation_date-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="customer_account_name" caption="Insurer ID/Name" cell-template="insurer_code-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_recorded_status" caption="Policy Card" alignment="left" cell-template="is_policy_card_exists-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="supplier_producer_name" caption="Producer Name" cell-template="supplier_producer_name-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_producer_policy_type_name" caption="Policy Type" header-cell-template="policy_producer_policy_type_name-header" cell-template="policy_producer_policy_type_name-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_type_name" caption="Regulation Type" alignment="left" header-cell-template="policy_type-header" css-class="align-middle"/> 
                                <DxColumn alignment="center" :caption="$t('Endorsement')" :fixed="false" fixed-position="right" css-class="align-middle">
                                    <DxColumn data-field="policy_endorsement_type_name" :caption="$t('Type')" css-class="align-middle" /> 
                                    <DxColumn data-field="policy_endorsement_detail_name" :caption="$t('Detail')" css-class="align-middle" /> 
                                </DxColumn>
                                <DxColumn data-field="vendor_name" caption="Vendor" cell-template="vendor_name-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="vehicle" caption="Vehicle" alignment="left" cell-template="vehicle-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="policy_start_date" caption="Start Date" data-type="date" format="dd.MM.yyyy" header-cell-template="policy_start_date-header" cell-template="policy_start_date-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_no" caption="Policy No" header-cell-template="policy_no-header" cell-template="policy_no-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_is_new_or_renewal_name" caption="New/Renewal" header-cell-template="policy_is_new_or_renewal_name-header" cell-template="policy_is_new_or_renewal_name-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_renewal_no" caption="Renewal No" header-cell-template="policy_renewal_no-header" css-class="align-middle"/> 
                                <DxColumn data-field="policy_endorsement_no" caption="Endors. No" header-cell-template="policy_endorsement_no-header" css-class="align-middle"/> 
                                <DxColumn data-field="policy_currency" caption="Curr." header-cell-template="policy_currency-header" cell-template="policy_currency-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_net_amount" caption="Net Amount" header-cell-template="policy_net_amount-header" alignment="right" css-class="align-middle"/> 
                                <DxColumn data-field="policy_tax_amount" caption="Taxes" alignment="right" cell-template="policy_tax_amount-cell-template" css-class="align-middle"/> 
                                <DxColumn data-field="policy_total_amount" caption="Total" alignment="right" css-class="align-middle"/> 
                                <DxColumn data-field="policy_insurance_commission" caption="Commission" alignment="right" css-class="align-middle"/> 
                                <DxColumn data-field="vendor_commission" caption="Vendor Commission" header-cell-template="vendor_commission-header" alignment="right" css-class="align-middle"/> 
                                <DxColumn data-field="branch_name" caption="Branch" alignment="left" cell-template="branch-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="properties" caption="Property" alignment="left" cell-template="properties-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="plans" caption="Collection Plans" header-cell-template="plans-header" alignment="left" cell-template="plans-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="coverages" caption="Coverages" alignment="left" cell-template="coverages-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="person" caption="Person" alignment="left" cell-template="person-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="load" caption="Load" alignment="left" cell-template="load-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="other" caption="Other" alignment="left" cell-template="other-cell-template" css-class="align-middle"/>
                                <DxColumn data-field="policy_sales_channel_name" :caption="$t('Sales Channel')"  css-class="align-middle" header-cell-template="policy_sales_channel-header" /> 
                                <DxColumn data-field="policy_campaign_name" :caption="$t('Sales Role/Campaign')" css-class="align-middle" header-cell-template="policy_campaign-header" /> 
                                <DxColumn data-field="policy_source_code_name" :caption="$t('Source Code 1')"  css-class="align-middle" header-cell-template="policy_source_code-header" /> 
                                <DxColumn data-field="policy_source_code_2_name" :caption="$t('Source Code 2')"  css-class="align-middle" header-cell-template="policy_source_code_2-header" /> 
                                <DxToolbar>
                                    <DxItem location="before">
                                        <DxButton v-if="!deleteMode"
                                            :disabled="selectedRowKeys.length == 0"
                                            :text="'Deselect All' + ' ('+ selectedRowKeys.length + ')'"
                                            @click="clearSelection"
                                        />
                                        <DxButton v-if="deleteMode" class="text-danger border-danger"
                                            :disabled="selectedRowDeleteKeys.length == 0"
                                            :text="'Deselect All' + ' ('+ selectedRowDeleteKeys.length + ')'"
                                            @click="clearSelection"
                                        />
                                    </DxItem>
                                </DxToolbar>
                                <template #headerLocalTemplate="{ data: data }"> <div v-html="setColumnTemplateForLocal(data)"></div> </template>
                                <template #policy_type-header><div>Regulation</div><div>Type</div></template>  
                                <template #policy_producer_policy_type_name-header><div>Producer</div><div>Policy Type</div></template>  
                                <template #policy_endorsement_type-header><div>Endorsement</div><div>Type</div></template> 
                                <template #policy_start_date-header><div>Start</div><div>End</div></template> 
                                <template #policy_no-header><div>Policy</div><div>No</div></template>
                                <template #policy_is_new_or_renewal_name-header><div>New</div><div>Renewal</div></template>
                                <template #policy_renewal_no-header><div>Renewal</div><div>No</div></template>
                                <template #policy_endorsement_no-header><div>Endors.</div><div>No</div></template>
                                <template #policy_currency-header><div>Curr.</div><div>Rate</div></template> 
                                <template #policy_net_amount-header><div>Policy</div><div>Net</div></template> 
                                <template #vendor_commission-header><div>Vendor</div><div>Commission</div></template> 
                                <template #plans-header><div>Collection</div><div>Plans</div></template>
                                <template #is_policy_card_exists-header><div>Policy</div><div>Card</div></template>
                                <template #policy_sales_channel-header><div>Sales</div><div>Channel</div></template>
                                <template #policy_campaign-header><div>Sales Role</div><div>Campaign</div></template>
                                <template #policy_source_code-header><div>Source</div><div>Code 1</div></template>
                                <template #policy_source_code_2-header><div>Source</div><div>Code 2</div></template>

                                <template #record-cell-template="{ data }">
                                    <span>
                                        <span v-if="data.data.id !== null && data.data.id !== ''">
                                            <span class="align-middle text-center cursor-pointer" :id="'popover_'+ data.rowIndex">
                                                <div v-if="data.data.is_policy_recorded == 0">
                                                    <div class="text-danger" v-if="!isReadyToSave(data.data)">
                                                        Not Ready
                                                    </div>
                                                    <div class="text-success"
                                                        v-if="isReadyToSave(data.data)">
                                                        Ready
                                                    </div>
                                                </div>
                                                <div v-if="data.data.is_policy_recorded !== 0">
                                                    <div class="text-success">
                                                        <i class="fa fa-check-circle text-success me-1" style="font-size: 12px;"></i> 
                                                        <span> Recorded</span>
                                                    </div>
                                                </div>
                                            </span>
                                            
                                            <DxPopover v-if="data.data.is_policy_recorded == 0"
                                                :width="300"
                                                :show-title="true"
                                                show-event="mouseenter"
                                                hide-event="mouseleave"
                                                :target="'#popover_'+ data.rowIndex"
                                                position="left"
                                                title="Status">
                                                <table class="table table-row-dashed mb-0"> 
                                                    <tr class="text-start" v-if="data.data.customer_account_number == '' && data.data.customer_vat_number == ''">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-asterisk text-danger"></i> 
                                                            <span>Customer Account Info is required. Transaction does not have any account info!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_fields_valid == 0">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-asterisk text-danger"></i> 
                                                            <span>There are required fields need to be filled for this customer account!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_policy_producer_type_exists == 0">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-asterisk text-danger"></i> 
                                                            <span>Producer Policy Type is not defined!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.customer_account_number !== '' && data.data.customer_vat_number !== ''">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-check-all text-success"></i> 
                                                            <span>Customer Account is ready!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_vehicle_required == 1 && (data.data.vehicle_registration_number == '' || data.data.is_vehicle_fields_valid == 0)">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-asterisk text-danger"></i> 
                                                            <span v-if="data.data.vehicle_registration_number == ''">Vehicle is required. Transaction does not have any vehicle!</span>
                                                            <span v-if="data.data.is_vehicle_fields_valid == 0 && data.data.vehicle_registration_number !== ''">There are required fields need to be filled for Vehicle!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_vehicle_required == 1 && data.data.vehicle_registration_number != '' && data.data.is_vehicle_fields_valid == 1">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-check-all text-success"></i> 
                                                            <span>Vehicle is ready!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_property_required == 1 && (data.data.property_registration_number == '' || data.data.is_property_fields_valid == 0)">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-asterisk text-danger"></i> 
                                                            <span v-if="data.data.property_registration_number == ''">Property is required. Transaction does not have any property!</span>
                                                            <span v-if="data.data.is_property_fields_valid == 0 && data.data.property_registration_number !== ''">There are required fields need to be filled for Property!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start" v-if="data.data.is_property_required == 1 && data.data.property_registration_number != '' && data.data.is_property_fields_valid == 1">
                                                        <td class="pt-2 pb-2 fw-bolder"><i class="bi bi-check-all text-success"></i> 
                                                            <span>Property is ready!</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="text-start">
                                                        <td class="pt-2 pb-2 fw-bolder"> 
                                                            <i class="bi bi-check-all text-success"></i> 
                                                            <span>Transaction is ready to save!</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </DxPopover>
                                            
                                            <i class="bi bi-info-circle text-danger float-end cursor-pointer text-end" :id="'popover_error_'+ data.rowIndex" v-if="data.data.is_policy_recorded == 0 && data.data.last_error !== null && data.data.last_error !== '' && data.data.last_error !== undefined"></i>
                                            <DxPopover v-if="data.data.is_policy_recorded == 0 && data.data.last_error !== null && data.data.last_error !== '' && data.data.last_error !== undefined"
                                                :width="300"
                                                :show-title="true"
                                                :target="'#popover_error_'+ data.rowIndex"
                                                position="right"                                            
                                                show-event="mouseenter"
                                                hide-event="mouseleave"
                                                title="Last Error">
                                                <div class="text-danger">
                                                    <span>{{data.data.last_error}}</span>
                                                </div>
                                            </DxPopover>
                                        </span>
                                    </span>
                                </template>  
                                <template #is_policy_card_exists-cell-template="{  data }">
                                    <span class="align-middle text-start">
                                        <div>
                                            <span v-if="data.data.is_policy_card_exists == 0"> 
                                                <span>Not Recorded </span>
                                            </span>
                                        </div> 
                                        <div>
                                            <span v-if="data.data.is_policy_card_exists == 1"> 
                                                <i class="fa fa-check-circle text-success me-1" style="font-size: 12px;"></i> 
                                                <span> Recorded</span>
                                            </span>
                                        </div> 
                                    </span>
                                </template>
                                <template #policy_producer_policy_type_name-cell-template="{ data }">
                                    <div class="align-middle text-start"> 
                                            <div>
                                                    <a href="#" 
                                                    target="_self" 
                                                    class="text-gray-800"
                                                    @click="onOpenProducerModal(
                                                        data.data.id,
                                                        data.data.is_policy_producer_type_exists, 
                                                        data.data.policy_producer_policy_type_code, 
                                                        data.data.policy_producer_policy_type_name,
                                                        data.data.policy_producer_type_main_branch_id,
                                                        data.data.policy_producer_type_policy_branch_id,
                                                        data.data.policy_producer_type_id,
                                                        data.data.supplier_producer_id,
                                                        data.data.policy_producer_type_policy_group_id,
                                                        data.data.policy_producer_type_search_title)"> 

                                                    <span><i class="bi bi-search me-2"></i> </span>
                                                    <span> {{ data.data.policy_producer_policy_type_name }} </span>
                                                </a>
                                            </div> 
                                            <div>
                                                <span class="me-2">
                                                    <i class="fa fa-exclamation-triangle text-warning" v-if="data.data.is_policy_producer_type_exists == 0" style="font-size: 12px;"></i>
                                                    <i class="fa fa-check-circle text-success" v-if="data.data.is_policy_producer_type_exists == 1" style="font-size: 12px;"></i>
                                                </span>
                                                <span>
                                                    {{ data.data.policy_producer_policy_type_code }}    
                                                </span>
                                            </div> 
                                    </div>
                                </template> 
                                <template #insurer_code-cell-template="{  data }">
                                    <span class="align-middle text-start">
                                        <div> 
                                            <a href="#" target="_self" class="text-gray-800" @click="this.onOpenAccountEditModal(data.data)"
                                                data-bs-original-title="Account Edit Modal" 
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_edit_item">
                                                <span><i class="bi bi-search me-2"></i> </span>
                                                <span>{{ data.data.customer_account_number }}</span>
                                            </a>
                                        </div>
                                        <div class="fw-bold">
                                            <span class="me-2">
                                                <i class="fa fa-exclamation-triangle text-danger" v-if="data.data.is_record_exists == 0 && data.data.is_fields_valid == 0" style="font-size: 12px;"></i>
                                                <i class="fa fa-check-circle text-warning" v-if="data.data.is_record_exists == 0 && data.data.is_fields_valid == 1" style="font-size: 12px;"></i>
                                                <i class="fa fa-check-circle text-success" v-if="data.data.is_record_exists == 1" style="font-size: 12px;"></i>
                                            </span>
                                            <span>
                                                {{ data.data.customer_account_name }} 
                                            </span> 
                                        </div> 
                                    </span>
                                </template>
                                <template #supplier_producer_name-cell-template="{  data }">
                                    <span class="align-middle  text-start">
                                        <div> 
                                            <span> {{ data.data.supplier_producer_name }} </span>
                                            <br>
                                            <small>{{ data.data.supplier_producer_code }}</small>
                                        </div> 
                                    </span>
                                </template>
                                <template #vendor_name-cell-template="{ data }">
                                    <div class="align-middle text-start">                                        
                                        <a href="#" target="_self" class="text-gray-800"
                                            data-bs-original-title="Add Vendor" 
                                            data-bs-toggle="modal" @click="onOpenTransactionVendorModal(data.data.id)"
                                            data-bs-target="#kt_modal_add_transaction_vendor">
                                            <span><i class="bi bi-search me-2"></i> </span>
                                            <span v-if="data.data.vendor_name == null || data.data.vendor_name == ''">MAIN</span>
                                            <span v-if="data.data.vendor_name !== null && data.data.vendor_name !== ''">{{ data.data.vendor_name }}</span>
                                        </a>
                                    </div>
                                </template>
                                <template #policy_regulation_date-cell-template="{ data }">
                                    <span class="align-middle text-end">
                                        <div>{{previewDate(data.data.policy_regulation_date)}}</div>
                                    </span>   
                                </template> 
                                <template #policy_start_date-cell-template="{ data }">
                                    <span class="align-middle  text-end">
                                        <div>{{previewDate(data.data.policy_start_date) }}</div>
                                        <div>{{previewDate(data.data.policy_end_date) }}</div>
                                    </span>   
                                </template>
                                <template #policy_no-cell-template="{ data }">
                                    <span class="align-middle  text-end">
                                        <div> 
                                            {{data.data.policy_no}} 
                                        </div>
                                    </span>   
                                </template>                                
                                <template #policy_currency-cell-template="{ data }">
                                    <span class="align-middle  text-end">
                                        <div>{{data.data.policy_currency }}</div>
                                        <div>{{data.data.policy_rate }}</div>
                                    </span>   
                                </template>
                                <template #policy_tax_amount-cell-template="{ data }">
                                    <span class="align-middle  text-end cursor-pointer"   
                                            @click="this.onClickTaxDetails(data.data)">
                                        <span>
                                                <i class="bi bi-search me-2"></i>
                                        </span>
                                        <span>
                                            {{ this.$globalParseFloat(parseFloat(data.data.policy_tax_amount) + parseFloat(data.data.policy_sub_tax_1_amount) + parseFloat(data.data.policy_sub_tax_2_amount) + parseFloat(data.data.policy_sub_tax_3_amount)) }}
                                        </span> 
                                    </span>
                                </template>
                                <template #branch-cell-template="{ data }">
                                    <div class="align-middle  text-start">  
                                        <span v-if="data.data.branch_id == '' || data.data.branch_id == null">
                                            Main
                                        </span>
                                        <span v-if="data.data.branch_id !== '' && data.data.branch_id !== null">
                                            {{ data.data.branch_name }}
                                        </span>
                                    </div> 
                                </template>
                                <template #vehicle-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <span>                                            
                                            <i class="fa fa-exclamation-triangle text-danger" 
                                                v-if="data.data.is_vehicle_exist == 0 && data.data.is_vehicle_required == 1 && data.data.is_vehicle_fields_valid == 0 && data.data.vehicle_registration_number != '' && data.data.vehicle_registration_number != '0' && data.data.vehicle_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-warning" v-if="data.data.is_vehicle_required == 1 && data.data.is_vehicle_exist == 0 && data.data.is_vehicle_fields_valid == 1 && data.data.vehicle_registration_number != '' && data.data.vehicle_registration_number != '0' && data.data.vehicle_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-warning" v-if="data.data.is_vehicle_required == 0 && data.data.is_vehicle_exist == 0 && data.data.vehicle_registration_number != '' && data.data.vehicle_registration_number != '0' && data.data.vehicle_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success" v-if="data.data.is_vehicle_exist == 1" style="font-size: 12px;" :class="{'text-danger': data.data.is_vehicle_required == 1 && data.data.is_vehicle_fields_valid == 0 && data.data.is_policy_recorded == 0}"></i>
                                        </span>
                                        <span>
                                            <button class="btn btn-secondary btn-xs" 
                                                    type="button" 
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#kt_modal_vehicle" 
                                                    data-kt-menu-trigger="click" 
                                                    data-kt-menu-placement="bottom-end" 
                                                @click="onShowVehicle(data.data)"
                                                v-if="data.data.vehicle_registration_number != '' && data.data.vehicle_registration_number != '0' && data.data.vehicle_registration_number != null">  
                                                <div>
                                                    <span> 
                                                        <i class="bi bi-search ms-2" v-if="data.data.is_vehicle_exist == 0"></i>
                                                        <i class="bi bi-pencil-square ms-2" v-if="data.data.is_vehicle_exist == 1"></i>
                                                    </span>
                                                    <span>
                                                        {{ data.data.vehicle_registration_number }}
                                                    </span>
                                                </div>
                                            </button> 
                                        </span>
                                    </span>   
                                </template>
                                <template #properties-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <span>                                            
                                            <i class="fa fa-exclamation-triangle text-danger" 
                                                v-if="data.data.is_property_exist == 0 && data.data.is_property_required == 1 && data.data.is_property_fields_valid == 0 && data.data.property_registration_number != '' && data.data.property_registration_number != '0' && data.data.property_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-warning" v-if="data.data.is_property_required == 1 && data.data.is_property_exist == 0 && data.data.is_property_fields_valid == 1 && data.data.property_registration_number != '' && data.data.property_registration_number != '0' && data.data.property_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-warning" v-if="data.data.is_property_required == 0 && data.data.is_property_exist == 0 && data.data.property_registration_number != '' && data.data.property_registration_number != '0' && data.data.property_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success" v-if="data.data.is_property_exist == 1" style="font-size: 12px;" :class="{'text-danger': data.data.is_property_required == 1 && data.data.is_property_fields_valid == 0 && data.data.is_policy_recorded == 0}"></i>
                                        </span>
                                        <span>
                                            <button class="btn btn-secondary btn-xs" 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_property" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end" 
                                                @click="onShowProperty(data.data)"
                                                v-if="data.data.property_registration_number != '' && data.data.property_registration_number != '0' && data.data.property_registration_number != null">  
                                                <div>
                                                    <span> 
                                                        <i class="bi bi-search ms-2" v-if="data.data.is_property_exist == 0"></i>
                                                        <i class="bi bi-pencil-square ms-2" v-if="data.data.is_property_exist == 1"></i>
                                                    </span>
                                                    <span>
                                                        {{ data.data.property_registration_number }}
                                                    </span>
                                                </div>
                                            </button> 
                                        </span>
                                    </span>   
                                </template>
                                <template #person-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <span>
                                            <i class="fa fa-exclamation-triangle text-warning me-2" v-if="data.data.is_person_exist == 0 && data.data.person_registration_number != '' && data.data.person_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success me-2" v-if="data.data.is_person_exist == 1" style="font-size: 12px;"></i>
                                        </span>
                                        <span>
                                            <button class="btn btn-secondary btn-xs" 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_person" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end" 
                                                @click="onShowPerson(data.data)"                                                
                                                v-if="data.data.person_registration_number != '' && data.data.person_registration_number != null">
                                                <div>
                                                    <span> 
                                                        <i class="bi bi-search ms-2" v-if="data.data.is_person_exist == 0"></i>
                                                        <i class="bi bi-pencil-square ms-2" v-if="data.data.is_person_exist == 1"></i>
                                                    </span>
                                                    <span>
                                                        {{ data.data.person_registration_number }}
                                                    </span>
                                                </div>
                                            </button> 
                                        </span>
                                    </span>   
                                </template>
                                <template #load-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <span>
                                            <i class="fa fa-exclamation-triangle text-warning me-2" v-if="data.data.is_load_exist == 0 && data.data.load_registration_number != '' && data.data.load_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success me-2" v-if="data.data.is_load_exist == 1" style="font-size: 12px;"></i>
                                        </span>
                                        <span>
                                            <button class="btn btn-secondary btn-xs" 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_load" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end" 
                                                @click="onShowLoad(data.data)"
                                                v-if="data.data.load_registration_number != '' && data.data.load_registration_number != null">
                                                <div>
                                                    <span> 
                                                        <i class="bi bi-search ms-2" v-if="data.data.is_load_exist == 0"></i>
                                                        <i class="bi bi-pencil-square ms-2" v-if="data.data.is_load_exist == 1"></i>
                                                    </span>
                                                    <span>
                                                        {{ data.data.load_registration_number }}
                                                    </span>
                                                </div>
                                            </button> 
                                        </span>
                                    </span>   
                                </template>
                                <template #other-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <span>
                                            <i class="fa fa-exclamation-triangle text-warning me-2" v-if="data.data.is_other_exist == 0 && data.data.other_registration_number != '' && data.data.other_registration_number != null" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success me-2" v-if="data.data.is_other_exist == 1" style="font-size: 12px;"></i>
                                        </span>
                                        <span>
                                            <button class="btn btn-secondary btn-xs" 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_other" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end" 
                                                @click="onShowLoad(data.data)"
                                                v-if="data.data.other_registration_number != '' && data.data.other_registration_number != null">
                                                <div>
                                                    <span> 
                                                        <i class="bi bi-search ms-2" v-if="data.data.is_other_exist == 0"></i>
                                                        <i class="bi bi-pencil-square ms-2" v-if="data.data.is_other_exist == 1"></i>
                                                    </span>
                                                    <span>
                                                        {{ data.data.other_registration_number }}
                                                    </span>
                                                </div>
                                            </button> 
                                        </span>
                                    </span>   
                                </template>
                                <template #plans-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <button class="btn btn-secondary btn-xs" 
                                                type="button" 
                                                data-bs-toggle="modal" 
                                                data-bs-target="#kt_modal_plans" 
                                                data-kt-menu-trigger="click" 
                                                data-kt-menu-placement="bottom-end" 
                                                @click="onShowCollectionPlans(data.data)">
                                                <div>
                                                    <span>Collection Plans </span>
                                                    <span v-if="data.data.collection_plan_total_installment != '' && data.data.collection_plan_total_installment != null">({{ data.data.collection_plan_total_installment }})</span>
                                                    <span v-if="data.data.collection_plan_total_installment == '' || data.data.collection_plan_total_installment == null">(0)</span>
                                                </div>
                                        </button>
                                    </span>   
                                </template>
                                <template #coverages-cell-template="{ data }">
                                    <span class="align-middle  text-start">
                                        <button class="btn btn-secondary btn-xs" type="button" @click="onShowCoverages(data.data)">
                                            Coverages ({{ data.data.coverage_count }})
                                        </button>
                                    </span>   
                                </template>
                                <template #policy_is_new_or_renewal_name-cell-template="{ data }">
                                    <div class="align-middle text-start">
                                        <a href="#" target="_self" class="text-gray-800"
                                            data-bs-original-title="Add New/Renewal" 
                                            data-bs-toggle="modal" @click="onOpenNewRenewalModal(data.data)"
                                            data-bs-target="#kt_modal_renewal_status_modal">
                                            <span><i class="bi bi-search me-2"></i> </span>
                                            <span>{{ data.data.policy_is_new_or_renewal_name }}</span>
                                        </a>
                                    </div>
                                </template>
                            </DxDataGrid>   
                        </div>
                    </div> 
                    <div class="d-flex flex-row-reverse mt-3">
                        <DxSelectBox
                            id="select-all-mode"
                            placeholder="Select All Mode"
                            :items="selectAllModes"
                            v-model="allMode"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="kt_modal_loading" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg top-25">
            <div class="modal-content">
                <div class="modal-header bg-primary min-h-20px py-2">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        Saving...         
                    </h2>
                </div>
                <div class="modal-body scroll-y bg-light-primary">
                    <div class="fs-5 text-center">
                        {{ $t('The policies you selected are being saved. Please wait until the saving operation is completed. If you want to cancel the transaction, click the cancel button') }}.
                    </div>
                    <div class="progress mt-5 h-20px">
                        <div class="progress-bar progress-bar-striped progress-bar-animated fs-7" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">
                            {{ $t('Saving') }}... {{ $t('Please wait') }}!
                        </div>
                    </div>
                    <button class="btn btn-icon btn-sm btn-active-icon-primary d-none" data-bs-dismiss="modal" aria-label="Close" ref="closeLoadingModal"></button>
                </div> 
                <div class="modal-footer row py-3 mx-0">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-light-danger btn-sm fw-bold" @click="onCancelDataImport()">
                            {{ $t('Cancel Transaction') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- IMPORT MODAL -->
    <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content"  >
                <div class="modal-header bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        <span v-if="this.modalType == 'addItem'"> New Data Import </span>
                        <span v-if="this.modalType == 'editItem'"> Edit </span>             
                    </h2>                        
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeImportModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y">
                    <div class="preloader vh-100" v-if="this.isDataTransferring">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'producer_company_account'">
                                        Producer Company Account
                                    </label>
                                    <Select2 v-model="form.producer_company_account" :options="producerCompanyAccountList" name="producer_company_account"  v-custom  
                                        :settings="{width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}" @select="onChangeInsuranceCompany($event)" />
                                </div>
                                <div class="col-lg-12" v-if="this.selectedInsuranceCompany != null && this.selectedInsuranceCompany != '' && this.selectedInsuranceCompany != undefined">
                                    <span v-if="this.selectedInsuranceCompany.supplier_dll_template_id != null && this.selectedInsuranceCompany.supplier_dll_template_id != ''">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            DLL Information
                                        </label>
                                        <div class="alert alert-dismissible bg-success d-flex flex-column flex-sm-row w-100 p-5 mb-5" v-if="this.selectedInsuranceCompany != null && this.selectedInsuranceCompany != '' && this.selectedInsuranceCompany != undefined">
                                            <span class="svg-icon svg-icon-dark svg-icon-2hx">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                </svg>
                                            </span>
                                            <div class="d-flex flex-column ps-4 pe-0 pe-sm-10">
                                                <h4 class="fw-semibold">A working DLL file found!</h4>
                                                <span><i class="fas fa-cogs text-gray-800"></i> <strong> {{ this.selectedInsuranceCompany.supplier_dll_template_name }} </strong> template will be used for this Data Import.</span>
                                            </div>
                                        </div> 
                                    </span>
                                </div>
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        Template Group
                                    </label>
                                    <div class="d-flex align-items-center">
                                        <Select2 v-model="form.template_group_id" :options="templateGroupList" name="template_group_id" class="w-100" v-custom 
                                        :settings="{width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/>
                                        <button class="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#template_group_id">
                                            <i class="bi bi-plus-lg fs-5 fw-boldest"></i>
                                        </button>
                                    </div>
                                    <small>You can assign imported data to different groups.</small>
                                </div>
                               <div class="col-lg-12" v-if="this.selectedInsuranceCompany != null && this.selectedInsuranceCompany != '' && this.selectedInsuranceCompany != undefined">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        Import Method
                                    </label>
                                    <div class="d-flex align-items-center">
                                        <div class="form-check form-check-inline" v-if="this.selectedInsuranceCompany.supplier_dll_template_id != null && this.selectedInsuranceCompany.supplier_dll_template_id != ''">
                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="true" v-model="form.isUseDLL" v-custom />
                                            <label class="form-check-label" for="inlineRadio1">{{ $t('Use DLL') }}</label>
                                        </div>
                                        <div class="form-check form-check-inline" v-if="this.selectedInsuranceCompany.supplier_data_import_design_id != null && this.selectedInsuranceCompany.supplier_data_import_design_id != ''">
                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="false" v-model="form.isUseDLL" v-custom />
                                            <label class="form-check-label" for="inlineRadio2">{{ $t('Use Design Module') }}</label>
                                        </div>
                                    </div>
                                    <small>Data Import methods have been found for this operation.</small>
                                </div>
                                <div class="col-lg-12 mt-3 bg-secondary border rounded" v-if="this.selectedInsuranceCompany != null && this.selectedInsuranceCompany.supplier_dll_template_type == 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Upload File
                                    </label>
                                    <DxFileUploader
                                            accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                                            multiple="false"
                                            upload-mode="useButtons" 
                                            :on-before-send="onUploadStarted"
                                            :visible="true"
                                            :upload-headers="getUploadHeaders()"
                                            upload-url="/api/DLLTemplates/UploadDataFile"
                                            :ref="'fileUploader'"
                                            :on-uploaded="onUploadCompleted"
                                            uploadButtonText="Start Data Import"
                                            :disabled="form.producer_company_account == '-1' || this.selectedInsuranceCompany == null || this.selectedInsuranceCompany == '' || this.selectedInsuranceCompany == undefined || this.form.template_group_id == '-1' || this.form.template_group_id == null || this.form.template_group_id == ''"
                                            :on-upload-error="onUploadError"
                                        /> 
                                        <div class="text-center mt-4" v-if="this.isDataTransferring">
                                            <h4> Please wait while data is importing to temporary table... </h4>
                                        </div>
                                </div>
                                <div class="col-lg-12 mt-3" 
                                     v-if="(this.selectedInsuranceCompany != null && (this.selectedInsuranceCompany.supplier_dll_template_type == 2 || this.selectedInsuranceCompany.supplier_dll_template_type == 3)) || this.selectedInsuranceCompany.supplier_data_import_design_id != ''">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        Upload XML File
                                    </label>
                                    <input type="file" id="input" class="form-control form-control-lg input-lg" 
                                        @change="onXMLFileChange" ref="xmlFile" :disabled="form.producer_company_account == '-1' || this.form.template_group_id == '-1' || this.form.template_group_id == null || this.form.template_group_id == ''"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal fade" id="template_group_id" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content"  >
                <div class="modal-header bg-primary py-3 min-h-50px">
                    <h2 class="modal-title fw-bolder col-md-6 text-white">
                    Template Group
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body">
                    <label class="form-label fs-6 fw-bold form-label mt-3">Template Group Code</label>
                    <input v-model="templateForm.templateGroupCode" type="text" class="form-control" id="templateGroupCode">
                    
                    <label class="form-label fs-6 fw-bold form-label mt-3">Template Group Name</label>
                    <input v-model="templateForm.templateGroupName" type="text" class="form-control" id="templateGroupName">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary w-100" @click="saveData()">SAVE</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="kt_modal_edit_item" v-show="isViewCustomerEditModal" :class="{'show': isViewCustomerEditModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <CustomerEditModal ref="CustomerEditModal"></CustomerEditModal>
    </div>

    <!-- COLLECTION/PAYMENT DETAILS MODAL -->
    <div class="modal fade" id="kt_modal_plans" v-show="isViewCollectionPlanModal" :class="{'show': isViewCollectionPlanModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <CollectionPlanModal ref="CollectionPlanModal"></CollectionPlanModal>
    </div>

    <!-- COVERAGES MODAL -->
    <div class="modal fade" id="kt_modal_coverages" v-show="isViewCoverageModal" :class="{'show': isViewCoverageModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true"> 
        <CoveragesModal ref="CoveragesModal"></CoveragesModal>
    </div>

    <!-- VEHICLE DETAILS MODAL -->
    <div class="modal fade" v-show="isViewFactorVehicleModal" :class="{'show': isViewFactorVehicleModal}" id="kt_modal_vehicle" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <FactorVehicleModal ref="FactorVehicleModal"></FactorVehicleModal>
    </div>

    <!-- PROPERTY DETAILS MODAL -->
    <div class="modal fade" v-show="isViewFactorPropertyModal" :class="{'show': isViewFactorPropertyModal}" id="kt_modal_property" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <FactorPropertyModal ref="FactorPropertyModal"></FactorPropertyModal>
    </div>

    <!-- PERSON DETAILS MODAL -->
    <div class="modal fade" v-show="isViewFactorPersonModal" :class="{'show': isViewFactorPersonModal}" id="kt_modal_person" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <FactorPersonModal ref="FactorPersonModal"></FactorPersonModal>
    </div>

    <!-- LOAD DETAILS MODAL -->
    <div class="modal fade" v-show="isViewLoadPersonModal" :class="{'show': isViewLoadPersonModal}" id="kt_modal_load" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">>
        <FactorLoadModal ref="FactorLoadModal"></FactorLoadModal>
    </div>

    <!-- OTHER DETAILS MODAL -->
    <div class="modal fade" v-show="isViewOtherPersonModal" :class="{'show': isViewOtherPersonModal}" id="kt_modal_other" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">>
        <FactorOtherModal ref="FactorOtherModal"></FactorOtherModal>
    </div>

    <!-- PRODUCER POLICY TYPE MODAL -->
    <div class="modal fade" id="kt_modal_policy_producer_type" v-show="isViewProducerPolicyTypeModal" :class="{'show': isViewProducerPolicyTypeModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <ProducerPolicyTypeModal ref="ProducerPolicyTypeModal"></ProducerPolicyTypeModal>
    </div> 

    <!-- TAX MODAL -->
    <div class="modal fade show d-block" v-if="isViewTaxModal" id="kt_modal_policy_tax_details" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <TaxModal ref="TaxModal" :tax="this.selectedTaxData"></TaxModal>
    </div>
    <!-- UPDATE SELECTED DATA MODAL -->
    <div class="modal fade" id="kt_modal_update_selected_data" v-show="isViewMultiUpdateModal" :class="{'show': isViewMultiUpdateModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <MultiTransactionUpdateModal ref="MultiTransactionUpdateModal"></MultiTransactionUpdateModal>
    </div>

    <!-- Add Vendor to Transaction -->
    <div class="modal fade" id="kt_modal_add_transaction_vendor" v-show="isViewVendorModal" :class="{'show': isViewVendorModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <TransactionVendorModal ref="TransactionVendorModal"></TransactionVendorModal>
    </div>

    <!-- Add New/Renewal to Transaction -->
    <div class="modal fade" id="kt_modal_renewal_status_modal" v-show="isNewRenewalModal" :class="{'show': isNewRenewalModal}" style="display: block;" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <NewRenewalModal ref="NewRenewalModal"></NewRenewalModal>
    </div>
  </div>
</template>
<style>
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<script>
import axios from "axios"; 
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxScrolling } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxPopover } from 'devextreme-vue/popover';
import TransactionVendorModal from '../DataImport/Modals/TransactionVendorModal.vue';
import TaxModal from './Modals/TaxModal.vue';
import ProducerPolicyTypeModal from './Modals/ProducerPolicyTypeModal.vue';
import MultiTransactionUpdateModal from './Modals/MultiTransactionUpdateModal.vue';
import FactorPersonModal from './Modals/FactorPersonModal.vue';
import FactorLoadModal from './Modals/FactorLoadModal.vue';
import FactorOtherModal from './Modals/FactorOtherModal.vue';
import FactorPropertyModal from './Modals/FactorPropertyModal.vue';
import FactorVehicleModal from './Modals/FactorVehicleModal.vue';
import CollectionPlanModal from './Modals/CollectionPlanModal.vue';
import CoveragesModal from './Modals/CoveragesModal.vue';
import CustomerEditModal from './Modals/CustomerEditModal.vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import NewRenewalModal from "./Modals/NewRenewalModal.vue";

import DataGrid from "devextreme/ui/data_grid";

DataGrid.defaultOptions({ 
    options: {
        scrolling: {
             legacyMode: true
        }
    }
});

export default {
  name: "PolicyDataImport",  
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxButton, DxFileUploader, DxPopover, TransactionVendorModal, TaxModal, ProducerPolicyTypeModal, MultiTransactionUpdateModal, FactorPersonModal, FactorLoadModal, FactorOtherModal, FactorPropertyModal, FactorVehicleModal, CollectionPlanModal, CoveragesModal,CustomerEditModal, DxSelectBox, NewRenewalModal, DxScrolling
  },
  data() {
    return {
        pageSize: 20,
        modalType: 'addItem',  
        activePlanTab: 'collection',
        policyImportDesignList: [{id: '-1', text: 'Select an option'}],
        producerCompanyAccountList: [{id: '-1', text: 'Select an option'}],
        templateGroupList: [{id: '-1', text: 'Select an option'}],
        tempPolicyList: [],
        paymentPlans: [],
        coverages: [],
        collectionPlanList: [],
        paymentPlanList: [], 
        selectedTaxData: null,
        policyProducerModalType: null,
        selectedInsuranceCompany: {
            supplier_dll_template_id: null,
            supplier_data_import_design_id: null,
            supplier_dll_template_type: null
        },
        isDataTransferring: false,
        templateForm: {
            templateGroupName: null,
            templateGroupCode: null
        },
        form: {
            policy_data_import_design: '-1',
            producer_company_account: '-1',
            collection_status: 1,
            collection_type: 0,
            template_group_id: '-1',
            isUseDLL: true
        },
        filterForm: {
            supplier_producer_id: '-1',
            template_group_id: '-1',
            record_status: 0
        },
        filterStatusList:[
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Ready'},
            { id: 2, text: 'Not Ready'},
            { id: 3, text: 'Recorded'}
        ],
        isViewTaxModal:false,
        isViewProducerPolicyTypeModal:false,
        isViewCoverageModal: false,
        isViewFactorPersonModal: false,
        isViewFactorLoadModal: false,
        isViewFactorOtherModal: false,
        isViewFactorPropertyModal: false,
        isViewFactorVehicleModal: false,
        isViewMultiUpdateModal: false,
        isViewVendorModal: false,
        isViewCollectionPlanModal: false,
        isViewCustomerEditModal: false,
        selectedRowKeys: [],
        selectedRowDeleteKeys: [],
        isImporting: false,
        isTempLoading: false,
        deleteMode: false,
        isNewRenewalModal:false,
        allMode: 'allPages',
        selectAllModes: ['allPages', 'page']
    };
  },
  computed: { 
    dataGrid: function() {
      return this.$refs["dxTempPolicyList"].instance;
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    isReadyToSave(){
        return (data)=>{
            if(data){
                var isReady = true;
                if(data.customer_account_number == '' && data.customer_vat_number == ''){
                    isReady = false;
                }
                if(data.is_vehicle_required == 1 && (data.vehicle_registration_number == '' || data.vehicle_registration_number == null || data.is_vehicle_fields_valid == 0)){
                    isReady = false;
                }
                if(data.is_property_required == 1 && (data.property_registration_number == '' || data.property_registration_number == null || data.is_property_fields_valid == 0)){
                    isReady = false;
                }
                if(data.is_fields_valid == 0){
                    isReady = false;
                }
                if(data.is_policy_producer_type_exists == 0){
                    isReady = false;
                }
                return isReady;
            } 
        }
    },
    showPageSizeSelector() {
        return(list, pageSize) => {
            return list.length > pageSize;
        }
    },
    pageSizes() {
        return(list, pageSize) => {
            let arr = [];
            let x = 1;
            let loop = (list.length / pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(1000);
            if(loop > 1) arr.push('all');
            return arr;
        }
    }
  },
  methods: {
    getData() {
        this.isTempLoading = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            template_group_id: this.filterForm.template_group_id == '-1' ? null : this.filterForm.template_group_id,
            supplier_producer_id: this.filterForm.supplier_producer_id == '-1' ? null : this.filterForm.supplier_producer_id,
            record_status: this.filterForm.record_status
        };  
        const that = this;
        axios.post('/api/DLLTemplates/GetTempTransactions', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.tempPolicyList = response.data;
                this.tempPolicyList.forEach(element => {
                    element.policy_no = this.$unlock(element.policy_no);
                    element.policy_renewal_no = this.$unlock(element.policy_renewal_no);
                    element.policy_endorsement_no = this.$unlock(element.policy_endorsement_no);
                    element.customer_account_id = this.$unlock(element.customer_account_id);
                    element.customer_account_name = this.$unlock(element.customer_account_name);
                    element.customer_account_number = this.$unlock(element.customer_account_number);
                    element.customer_vat_number = this.$unlock(element.customer_vat_number);
                    element.supplier_producer_name = this.$unlock(element.supplier_producer_name);
                    element.supplier_producer_code = this.$unlock(element.supplier_producer_code);
                    element.vehicle_registration_number = this.$unlock(element.vehicle_registration_number);
                    element.property_registration_number = this.$unlock(element.property_registration_number);
                    element.branch_name = this.$unlock(element.branch_name);
                    element.vendor_name = this.$unlock(element.vendor_name);
                    element.customer_group_name = this.$unlock(element.customer_group_name);
                    element.customer_address = this.$unlock(element.customer_address);
                    element.customer_city = this.$unlock(element.customer_city);
                    element.customer_country = this.$unlock(element.customer_country);
                    element.customer_district = this.$unlock(element.customer_district);
                    element.customer_email = this.$unlock(element.customer_email);
                    element.customer_landphone = this.$unlock(element.customer_landphone);
                    element.customer_mobile_phone = this.$unlock(element.customer_mobile_phone);
                    element.customer_postcode = this.$unlock(element.customer_postcode);
                });
            }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
            that.isTempLoading = false;
        });
    },
    saveData() {
      this.isProgressing = true;
      const that = this;
      const params = {
        name: this.templateForm.templateGroupName,
        code: this.templateForm.templateGroupCode,
        list_type: 23, // Template Group Type: 23
        session_id: this.$getUser().session_id,
      };

      axios.post('/api/PolicyLists/CreateListItem', params, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          if (response.data != null && response.data.length === 36) {
            this.$setSessionStorage('record_id', response.data);
            this.$setSessionStorage('refreshList', true);
            this.$refs.closeModal.click();
            this.$refs.openTransactionModal.click();
          } else {
            this.$swal("Failed", "Data could not be saved. Please refresh the page and try again.", 'error');
          }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        }) 
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });
    },
    getLists(isOnCreate) {
        //this.$Progress.start(); 
        this.selectedInsuranceCompany = {
            supplier_dll_template_id: null,
            supplier_data_import_design_id: null,
            supplier_dll_template_type: null
        };
        this.activeCoverageTransaction = null;
        this.paymentPlans = [];
        this.coverage = [];
        this.producerCompanyAccountList = [{id: '-1', text: 'Select an option'}];
        this.templateGroupList = [
            {id: '-1', text: 'Select an option'},
        ];
        this.form.producer_company_account = '-1';
        this.form.template_group_id = '-1';
        const parameters = {
            session_id: this.$getUser().session_id,
            supplier_account_type: 1 //Producer
        };
        const parameters2 = {
            session_id: this.$getUser().session_id,       
            list_type: 23 /* Template Group Type */
        };
        const requestOne =  axios.post('/api/GetCompanyAccounts/GetPolicyAccounts', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetPolicyLists', parameters2, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
            if(responses[0].data.error == null || responses[0].data.error == 'undefined' || responses[0].data.error == ''){
                this.producerCompanyAccountList = responses[0].data;
                this.producerCompanyAccountList.unshift({id: '-1', text: 'Select an option'});
            }
            if(responses[1].data.error == null || responses[1].data.error == 'undefined' || responses[1].data.error == ''){
                this.templateGroupList = responses[1].data;
            }
            this.templateGroupList.unshift({id: '-1', text: 'Select an option'});
            if(isOnCreate)this.getData();
        })
        )
        .catch(function (error) {
            console.log(error);
        });
    },
    onSelectProducer(event) {
        this.policyImportDesignList = [];
        this.policyImportDesignList.push({ id: event.template_id, text: event.template_name });
        this.form.policy_data_import_design = event.template_id;
    },
    onChangeInsuranceCompany(e){
        this.selectedInsuranceCompany = e;
        this.form.isUseDLL = true;
    },
    onStartDataTransfer(){
        this.$swal.fire({
            title: "Are you sure you want to start data transfer? Data located in the uploaded file will be saved on temporary table. This process can not stopped once it started.",
            confirmButtonColor: "#009ef7",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "START DATA TRANSFER",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$refs.uploadForm.submit();
            }
        });
    },
    getUploadHeaders(){
        if(this.selectedInsuranceCompany == null){
            return;
        }
        return { 
            session_id: this.$getUser().session_id,
            supplier_id: this.selectedInsuranceCompany.id,
            dll_template_id: this.selectedInsuranceCompany.supplier_dll_template_id,
            template_group_id: this.form.template_group_id == '-1' ? null : this.form.template_group_id,
            is_use_dll: this.form.isUseDLL,
            design_template_id: this.selectedInsuranceCompany.supplier_data_import_design_id
        };
    },
    onUploadStarted(){ 
        this.isDataTransferring = true;
    },
    onUploadCompleted(){
      this.isDataTransferring = false;
      this.$swal("Success", "Data Transferred successfully..!", 'success'); 
    },
    onUploadError() {  
      this.$swal("Error", 'An error occured while uploading your file..!', 'error'); 
      this.isDataTransferring = false;
    },
    onXMLFileChange(event){
		const that = this;
        this.isDataTransferring = true;
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        this.fileName = event.target.files[0].name;
        fetch('/api/DLLTemplates/UploadDataFile', {
            method: 'POST',
            body: formData,
            headers: {
                session_id: this.$getUser().session_id,
                supplier_id: this.selectedInsuranceCompany.id,
                dll_template_id: this.selectedInsuranceCompany.supplier_dll_template_id,
                template_group_id: this.form.template_group_id == '-1' ? null : this.form.template_group_id,
                is_use_dll: this.form.isUseDLL,
                design_template_id: this.selectedInsuranceCompany.supplier_data_import_design_id
            }
        }).then(response => response.json()).then(data => {
            if(!data.error){
                setTimeout(function(){
                    try{
                        that.$refs.closeImportModal.click();
                        that.$refs.xmlFile.value = null;
                        that.isDataTransferring = false;
                        that.$swal("Success", data.fileName + " transferred successfully..!", 'success');
                    }
                    catch(error){
                        console.log(error);
                        alert(data.fileName + " transferred successfully..!");
                        that.isDataTransferring = false;
                    }
                    that.getData();
                }, 500);
            } 
            else{
                that.$swal("Error", data.error, 'error');  
                that.isDataTransferring = false;
            }
        });
        
    },
    onShowCollectionPlans(item){        
        this.isViewCollectionPlanModal = true;
        this.$refs.CollectionPlanModal.getData(item);
    },
    onShowCoverages(item){
        this.isViewCoverageModal = true;
        this.$refs.CoveragesModal.getData(item);
    },
    onShowVehicle(item){
        this.isViewFactorVehicleModal = true;
        this.$refs.FactorVehicleModal.getData(item, false);
    },
    onShowProperty(item) {
        this.isViewFactorPropertyModal = true;
        this.$refs.FactorPropertyModal.getData(item);
    },
    onShowPerson(item) {
        this.isViewFactorPersonModal = true;
        this.$refs.FactorPersonModal.getData(item);
    },
    onShowOther(item) {
        this.isViewFactorOtherModal = true;
        this.$refs.FactorOtherModal.getData(item);
    },
    onDeleteAllTempTransactions(){
        this.$swal.fire({
            title: "Are you sure you want to CLEAR Temporary Policy Import Transaction List? This action can not be undone!",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    template_group_id: this.filterForm.template_group_id == '-1' ? null : this.filterForm.template_group_id,
                    supplier_producer_id: this.filterForm.supplier_producer_id == '-1' ? null : this.filterForm.supplier_producer_id,
                    record_status: this.filterForm.record_status
                };
                axios.post('/api/DLLTemplates/DeleteImportTempTransactions', parameters, {'Content-Type': 'application/json'}) 
                .then(() => { 
                    this.getData(); 
                })
                .catch(function (error) { 
                    console.error(error);
                }); 
            }
        }); 
    },
    onDeleteSelectedTransactions(){
        this.$swal.fire({
            title: "Are you sure you want to DELETE selected transactions? This action can not be undone!",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tempTransactionIds: this.selectedRowDeleteKeys
                };
                axios.post('/api/DLLTemplates/DeleteTransactionById', parameters, {'Content-Type': 'application/json'}) 
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.deleteMode = false;
                        this.selectedRowDeleteKeys = [];
                        this.selectedRowKeys = [];
                        this.getData();
                    }                    
                })
                .catch((error) => { 
                    console.error(error);
                }); 
            }
        }); 
    },
    toggleDeleteMode() {
        this.selectedRowDeleteKeys = [];
        this.selectedRowKeys = [];
    },
    onOpenAccountEditModal(selectedTransaction){
        this.isViewCustomerEditModal = true;
        this.$refs.CustomerEditModal.getData(selectedTransaction);
    },
    onOpenProducerModal(policy_transaction_id, is_policy_producer_type_exists, policy_producer_policy_type_code, policy_producer_policy_type_name, policy_producer_type_main_branch_id, 
        policy_producer_type_policy_branch_id, policy_producer_type_id, supplier_producer_id, policy_producer_type_policy_group_id, policy_producer_type_search_title)
        {
        const producerForm = {
            policy_transaction_id: policy_transaction_id,
            producer_type_exists: is_policy_producer_type_exists,
            main_branch_id: policy_producer_type_main_branch_id,
            policy_branch_id: policy_producer_type_policy_branch_id,
            producer_policy_code: policy_producer_policy_type_code,
            producer_policy_name: policy_producer_policy_type_name,
            policy_producer_type_id: policy_producer_type_id,
            supplier_producer_id: supplier_producer_id,
            policy_group_id: (policy_producer_type_policy_group_id == null || policy_producer_type_policy_group_id == '') ? '-1' : policy_producer_type_policy_group_id,
            search_title: (policy_producer_type_search_title == null || policy_producer_type_search_title == '') ? policy_producer_policy_type_name : policy_producer_type_search_title
        };
        this.isViewProducerPolicyTypeModal = true;
        this.$refs.ProducerPolicyTypeModal.getData(producerForm);

    }, 
    onClickTaxDetails(value){
        this.isViewTaxModal = true;
        this.selectedTaxData = value;
    },
    onOpenUpdateModal(){
        this.isViewMultiUpdateModal = true;
        this.$refs.MultiTransactionUpdateModal.getData();
    },
    getTransactionRowById(transactionId, updateType){
        const parameters = { 
            id: transactionId,
            session_id: this.$getUser().session_id,
            update_type: updateType
        };
        axios.post("/api/DLLTemplates/GetTempTransactionById", parameters, {'Content-Type': 'application/json'}).then((response) => {
            if(response.data.length == 0) return;
            const dataSource = this.dataGrid.getDataSource();
            var store = dataSource.store();
            if(response.data.length > 1) {
                response.data.forEach(element => {
                    element.policy_no = this.$unlock(element.policy_no);
                    element.policy_renewal_no = this.$unlock(element.policy_renewal_no);
                    element.policy_endorsement_no = this.$unlock(element.policy_endorsement_no);
                    element.customer_account_id = this.$unlock(element.customer_account_id);
                    element.customer_account_name = this.$unlock(element.customer_account_name);
                    element.customer_account_number = this.$unlock(element.customer_account_number);
                    element.customer_vat_number = this.$unlock(element.customer_vat_number);
                    element.supplier_producer_name = this.$unlock(element.supplier_producer_name);
                    element.supplier_producer_code = this.$unlock(element.supplier_producer_code);
                    element.branch_name = this.$unlock(element.branch_name);
                    element.vendor_name = this.$unlock(element.vendor_name);
                    element.customer_group_name = this.$unlock(element.customer_group_name);
                    element.customer_address = this.$unlock(element.customer_address);
                    element.customer_city = this.$unlock(element.customer_city);
                    element.customer_country = this.$unlock(element.customer_country);
                    element.customer_district = this.$unlock(element.customer_district);
                    element.customer_email = this.$unlock(element.customer_email);
                    element.customer_landphone = this.$unlock(element.customer_landphone);
                    element.customer_mobile_phone = this.$unlock(element.customer_mobile_phone);
                    element.customer_postcode = this.$unlock(element.customer_postcode);
                    element.vehicle_registration_number = this.$unlock(element.vehicle_registration_number);
                    element.property_registration_number = this.$unlock(element.property_registration_number);
                    store.update(element.id,  element)
                    .fail(function (error) { console.log(error) });                    
                });                
                dataSource.reload();
            } else {
                store.update(transactionId,  response.data[0])
                .done(function () {
                    dataSource.reload();
                })
                .fail(function (error) { console.log(error) });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getRecordedTransactions(){
        const parameters = { 
            tempIds: this.selectedRowKeys,
            session_id: this.$getUser().session_id
        };
        axios.post("/api/DLLTemplates/GetRecordedTransactions", parameters, {'Content-Type': 'application/json'}).then((response) => {
            if(response.data.length == 0) return;
            const dataSource = this.dataGrid.getDataSource();
            var store = dataSource.store();
            response.data.forEach(element => {
                element.policy_no = this.$unlock(element.policy_no);
                element.policy_renewal_no = this.$unlock(element.policy_renewal_no);
                element.policy_endorsement_no = this.$unlock(element.policy_endorsement_no);
                element.customer_account_id = this.$unlock(element.customer_account_id);
                element.customer_account_name = this.$unlock(element.customer_account_name);
                element.customer_account_number = this.$unlock(element.customer_account_number);
                element.customer_vat_number = this.$unlock(element.customer_vat_number);
                element.supplier_producer_name = this.$unlock(element.supplier_producer_name);
                element.supplier_producer_code = this.$unlock(element.supplier_producer_code);
                element.vehicle_registration_number = this.$unlock(element.vehicle_registration_number);
                element.property_registration_number = this.$unlock(element.property_registration_number);
                element.customer_group_name = this.$unlock(element.customer_group_name);
                element.customer_address = this.$unlock(element.customer_address);
                element.customer_city = this.$unlock(element.customer_city);
                element.customer_country = this.$unlock(element.customer_country);
                element.customer_district = this.$unlock(element.customer_district);
                element.customer_email = this.$unlock(element.customer_email);
                element.customer_landphone = this.$unlock(element.customer_landphone);
                element.customer_mobile_phone = this.$unlock(element.customer_mobile_phone);
                element.customer_postcode = this.$unlock(element.customer_postcode);
                store.update(element.id,  element)
                .fail(function (error) { console.log(error) });                    
            });
            dataSource.reload();            
            this.clearSelection();
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onSelectAddressSearchResult(item, formName){
        if(item.id == '-1') return;
        this.isViewAddressModal = false;
        this[formName].address = item.ADDRESS;
        this[formName].city = item.POST_TOWN;
        this[formName].district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this[formName].customer_x_coordinate = item.X_COORDINATE;
        this[formName].postcode = item.POSTCODE;
        this[formName].apartment_no = item.SUB_BUILDING_NAME;
        this[formName].street_no = item.THOROUGHFARE_NAME;
        this[formName].customer_uprn = item.UPRN;
        this[formName].customer_udprn = item.UDPRN;
        let buildingName = (item.BUILDING_NAME !== null && item.BUILDING_NAME !== undefined && item.BUILDING_NAME !== '') ? item.BUILDING_NAME : '';
        if(item.BUILDING_NUMBER !== null && item.BUILDING_NUMBER !== undefined && item.BUILDING_NUMBER !== '') {
            buildingName = item.BUILDING_NUMBER;
        }
        this[formName].building_no = buildingName;
    },
    onOpenTransactionVendorModal(policyTransactionId) {
        this.isViewVendorModal = true;
        this.$refs.TransactionVendorModal.getData(policyTransactionId);
    },  
    onOpenNewRenewalModal(item) {
        this.isNewRenewalModal = true;
        const tempValues = {
            account_id: item.customer_account_id,
            main_branch_id: item.policy_producer_type_main_branch_id,
            policy_branch_id: item.policy_producer_type_policy_branch_id,
            policy_transaction_id: item.id,
            regulation_date: item.policy_regulation_date
        };
        this.$refs.NewRenewalModal.getData(tempValues);
    },  
    onSelectItem(array, item) {
        array.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onCancelDataImport() {
        this.$swal.fire({
            title: "Are you sure you want to cancel this operation?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/DLLTemplates/CancelDataImport', {}, {'Content-Type': 'application/json'})
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
            }
        });
    },
    onSaveReadyPolicies() {
        this.$swal.fire({
            title: "Are you sure you want to save selected policies?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.isImporting = true;
                this.$refs.LoadingModal.click();
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tempIds: this.selectedRowKeys
                };
                const that = this;
                axios.post('/api/DLLTemplates/SaveReadyPolicies', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Data transferred successfully. There might be some records with errors. You can check the error message from the error icon in the Status column.", 'success'); 
                    }
                    this.getRecordedTransactions();
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function() {
                    setTimeout(() => {
                        that.isImporting = false;
                        that.$refs.closeLoadingModal.click();
                    }, 200);
                });
            }
        });
    },
    onSelectionChanged(e) {
        if(!this.deleteMode) {
            this.selectedRowKeys = e.selectedRowsData.filter(x => (x.is_policy_producer_type_exists == 1 && x.is_fields_valid == 1 && x.is_policy_recorded == 0) || (((x.is_vehicle_required == 1 && x.is_vehicle_fields_valid == 1)) || (x.is_property_required == 1 && x.is_property_fields_valid == 1))).map(x => x.id);

            var disabledKeys = e.selectedRowsData.filter(i => (i.is_policy_producer_type_exists == 0 || i.is_fields_valid == 0 || i.is_policy_recorded == 1 || ((i.is_vehicle_required == 1 && i.is_vehicle_fields_valid == 0) || (i.is_property_required == 1 && i.is_property_fields_valid == 0)))).map(i => i.id);  
            if (disabledKeys.length > 0)  
            e.component.deselectRows(disabledKeys);
        }else {
            this.selectedRowDeleteKeys = e.selectedRowsData.map(x => x.id);
        }
    },
    onCellPrepared(e) {
        if (e.rowType === "data" && e.column.command === 'select' && (e.data.is_policy_producer_type_exists == 0 || e.data.is_fields_valid == 0 || e.data.is_policy_recorded == 1 || ((e.data.is_vehicle_required == 1 && e.data.is_vehicle_fields_valid == 0) || (e.data.is_property_required == 1 && e.data.is_property_fields_valid == 0)))) {  
            var instance = e.cellElement.find('.dx-select-checkbox').dxCheckBox("instance");  
            instance.option("disabled", true);  
            e.cellElement.off();  
        }  
    },
    clearSelection () {
        this.dataGrid.clearSelection();
    },
    setCriteriasToDefault() {   
        this.filterForm = {
            supplier_producer_id: '-1',
            template_group_id: '-1',
            record_status: 0
        };
        this.tempPolicyList = [];
    },
    // onCellBackground(e) {
    //     if (e.rowType === "data" && e.column.cssClass === "dx-command-select" && this.deleteMode) {
    //         e.cellElement.style.cssText = "background-color : #FCA5A5; text-align:center";
    //     if (e.column.cssClass === "dx-command-select" && this.deleteMode) {
    //         e.cellElement.style.cssText = "background-color : #FF7070; text-align : center";
    //     }
    // }
  },
  created() {
    this.getLists(true);
  },
  
}; 
</script>