<template>
    <div>
        <div class="card-body">
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                    <div class="p-3">
                        <div class="d-flex flex-stack flex-wrap mb-2">
                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                            </div>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">    
                            <div class="table-responsive-lg">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="policyCoveragesList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" > 

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="false"/>
                                    <DxPaging :page-size="10000000"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                    <DxColumn data-field="code" :caption="$t('Code')" cell-template="code-cell-template"/>
                                    <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 
                                    <DxColumn data-field="coverage_main_name" :caption="$t('Coverage Main')" header-cell-template="coverage_main_name-header" cell-template="coverage_main_name-cell-template"/> 
                                    <DxColumn data-field="coverage_detail_name" :caption="$t('Coverage Detail')" header-cell-template="coverage_detail_name-header"  cell-template="coverage_detail_name-cell-template"/> 
                                    <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>
                                    <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/> 
                                    <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/>
                                    <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Date')" alignment="right" cell-template="regulation_date-cell-template"/> 
                                    <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header"  cell-template="policy_no-cell-template"/>
                                    <DxColumn data-field="producer_name" :caption="$t('Producer')" cell-template="producer_name-cell-template"/>
                                    <DxColumn data-field="producer_policy_type_name" :caption="$t('Producer Policy Type')" header-cell-template="producer_policy_type_name-header" cell-template="producer_policy_type_name-cell-template"/>
                                    
                                    <template #coverage_main_name-header>
                                        <div>{{ $t('Coverage') }}</div><div>{{ $t('Main') }}</div>
                                    </template>
                                    <template #coverage_detail_name-header>
                                        <div>{{ $t('Coverage') }}</div><div>{{ $t('Detail') }}</div>
                                    </template>
                                    <template #producer_policy_type_name-header>
                                        <div>{{ $t('Producer') }}</div><div>{{ $t('Policy Type') }}</div>
                                    </template>
                                    <template #policy_type_name-header>
                                        <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #policy_no-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                    </template>

                                    <template #code-cell-template="{ data }"> 
                                        <span>{{ data.data.code }}</span>
                                    </template>
                                    <template #name-cell-template="{ data }"> 
                                        <span>{{ data.data.name }}</span>
                                    </template>
                                    <template #coverage_main_name-cell-template="{ data }"> 
                                        <span>{{ data.data.coverage_main_name }}</span>
                                    </template>
                                    <template #coverage_detail_name-cell-template="{ data }"> 
                                        <span>{{ data.data.coverage_detail_name }}</span>
                                    </template>
                                    <template #currency-cell-template="{ data }"> 
                                        <span>{{ data.data.currency }}</span>
                                    </template>
                                    <template #amount-cell-template="{ data }"> 
                                        <span>{{ data.data.amount }}</span>
                                    </template>
                                    <template #producer_name-cell-template="{ data }">
                                        <span>{{ data.data.producer_name }}</span>
                                    </template>
                                    <template #producer_policy_type_name-cell-template="{ data }">
                                        <span>{{ data.data.producer_policy_type_name }}</span>
                                    </template>
                                    <template #policy_type_name-cell-template="{ data }">
                                        <span>{{ data.data.policy_type_name }}</span>
                                    </template>
                                    <template #policy_no-cell-template="{ data }">
                                        <span>{{ data.data.policy_no }}</span>
                                    </template>                                                
                                    <template #regulation_date-cell-template="{ data }">
                                        <span>{{this.$parent.previewDate(data.data.regulation_date)}}</span>   
                                    </template>
                                </DxDataGrid>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
export default {
    name: 'Coverages',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling
    },
    data() {
        return {
            policyCoveragesList: []
        }
    },
    methods: {        
        getData() {
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_card_id: this.$getSessionStorage('record_id')
            };  
            axios.post('/api/Insurance/GetPolicyCoverages', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.policyCoveragesList = response.data;
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        }
    },
    created() {
        this.getData();
    }
}
</script>