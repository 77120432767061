<template>
    <div>
        <div class="modal-dialog modal-xxl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">Update Vehicle</h2>
                    <h2 class="text-white" v-if="this.selectedTransaction.is_vehicle_exist == false || this.selectedTransaction.is_vehicle_exist == 0">Create Vehicle</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" ref="closeVehicleModal">
                        <span class="svg-icon svg-icon-1 svg-icon-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-2" :class="{'overlay overlay-block': this.isLoading == true || this.isModelsLoading == true}">
                    <div class="card card-xxl-stretch pt-3">
                        <div class="card-body pt-0">
                            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 pt-3">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#vehicle_tab_1" target="_self">
                                        <h4> {{ $t('Vehicle Detail') }} </h4>
                                    </a>
                                </li>  
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#vehicle_tab_2" target="_self">
                                        <h4> {{ $t('Policy Data') }}</h4>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myVehicleTabContent">
                                <div class="row tab-pane fade active show" id="vehicle_tab_1" role="tabpanel">
                                    <form id="vehicleModal" @submit.prevent=""  class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'vehicle_registration_number'">
                                                        {{ $t('Registration Number') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" 
                                                            v-model="vehicleForm.vehicle_registration_number" 
                                                            :disabled="this.selectedTransaction.is_vehicle_exist == 1"
                                                                class="form-control" name="vehicle_registration_number" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.name_description == true }" v-tag="'name_description'">
                                                        {{ $t('Description') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" v-model="vehicleForm.name_description" class="form-control" name="name_description" v-custom/>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_registration_status == true }" v-tag="'vehicle_registration_status'">
                                                        <span>{{ $t('Vehicle Registration Status') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.vehicle_registration_status" :options="vehicleRegistrationStatusList" name="vehicle_registration_status" v-custom 
                                                        :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }"/> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_group_id == true }" v-tag="'vehicle_group_id'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Group') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.vehicle_group_id" :options="vehicleGroupList" name="vehicle_group_id" v-custom 
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_type_id == true }" v-tag="'vehicle_type_id'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.vehicle_type_id" :options="vehicleTypeList" name="vehicle_type_id" v-custom 
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_usage_type_name == true }" v-tag="'usage_type_id'">
                                                        {{ $t('Usage') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.usage_type_id" :options="usageTypeList" name="usage_type_id" v-custom 
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />

                                                        <!-- <span class="badge badge-secondary mt-2 w-100">
                                                            {{ vehicleForm.vehicle_usage_type_code }} {{ vehicleForm.vehicle_usage_type_name }}
                                                        </span> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_usage_type_detail_name == true }" v-tag="'usage_detail_id'">
                                                        {{ $t('Usage') }} <br> {{ $t('Detail') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.usage_detail_id" :options="usageDetailList" name="usage_detail_id" v-custom 
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />

                                                        <!-- <span class="badge badge-secondary mt-2 w-100">
                                                            {{ vehicleForm.vehicle_usage_type_detail_code }} {{ vehicleForm.vehicle_usage_type_detail_name }}
                                                        </span> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.fuel_type_id == true }" v-tag="'fuel_type_id'">
                                                        {{ $t('Fuel') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.fuel_type_id" :options="fuelTypeList" name="fuel_type_id" v-custom 
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.licence_number == true }" v-tag="'licence_number'">
                                                        {{ $t('Licence') }} <br> {{ $t('Number') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="licence_number" v-model="vehicleForm.licence_number" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separator my-3 border-gray-300"></div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_brand_name == true }" v-tag="'vehicle_brand_id'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Brand') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="vehicleForm.vehicle_brand_id" :options="vehicleBrandList" name="vehicle_brand_id" v-custom
                                                            @select="getVehicleModelList($event, true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_model_name == true }" v-tag="'vehicle_model_id'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Model') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container"> 
                                                        <Select2 v-model="vehicleForm.vehicle_model_id" :options="vehicleModelList" name="vehicle_model_id" v-custom 
                                                            @select="onSelectVehicleModel($event)" :settings="{ width: '100%', dropdownParent: '#kt_modal_vehicle .modal-content' }" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_code == true }" v-tag="'vehicle_code'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Code') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="vehicle_code" v-model="vehicleForm.vehicle_code" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separator my-3 border-gray-300"></div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.engine_number == true }" v-tag="'engine_number'">
                                                        {{ $t('Engine') }} <br> {{ $t('Number') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="engine_number" v-model="vehicleForm.engine_number" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.chassis_number == true }" v-tag="'chassis_number'">
                                                        {{ $t('Chassis') }} <br> {{ $t('Number') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="chassis_number" v-model="vehicleForm.chassis_number" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.model_year == true }" v-tag="'model_year'">
                                                        {{ $t('Model') }} <br> {{ $t('Year') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Datepicker :textInputOptions="{ format: 'yyyy' }" :required="this.$checkIfDateFieldRequired('model_year')"  v-maska="'####'" v-model="vehicleForm.model_year" yearPicker autoApply :clearable="false" name="model_year" v-custom></Datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.color == true }" v-tag="'color'">
                                                        {{ $t('Color') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="color" v-model="vehicleForm.color" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.max_passenger == true }" v-tag="'max_passenger'">
                                                        {{ $t('Max') }}. <br> {{ $t('Passenger') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="number" class="form-control" name="max_passenger" v-model="vehicleForm.max_passenger" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.empty_weight == true }" v-tag="'empty_weight'">
                                                        {{ $t('Empty') }} <br> {{ $t('Weight') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="number" class="form-control" name="empty_weight" v-model="vehicleForm.empty_weight" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.load_capacity == true }" v-tag="'load_capacity'">
                                                        {{ $t('Load') }} <br> {{ $t('Capacity') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="number" class="form-control" name="load_capacity" v-model="vehicleForm.load_capacity" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.engine_power == true }" v-tag="'engine_power'">
                                                        {{ $t('Engine') }} <br> {{ $t('Power') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="number" class="form-control" name="engine_power" v-model="vehicleForm.engine_power" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.first_registration_date == true }" v-tag="'first_registration_date'">
                                                        {{ $t('First') }} <br> {{ $t('Reg. Date') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Datepicker name="first_registration_date" :required="this.$checkIfDateFieldRequired('first_registration_date')"  textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="vehicleForm.first_registration_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.last_registration_date == true }" v-tag="'last_registration_date'">
                                                        {{ $t('Last') }} <br> {{ $t('Reg. Date') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Datepicker name="last_registration_date" :required="this.$checkIfDateFieldRequired('last_registration_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="vehicleForm.last_registration_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" :class="{ 'fw-boldest text-info fst-italic' : this.vehiclePolicyDataChecker.vehicle_price == true }" v-tag="'vehicle_price'">
                                                        {{ $t('Vehicle') }} <br> {{ $t('Price') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <CurrencyInput type="text" step="any" class="form-control text-end" v-model="vehicleForm.vehicle_price" @focus="$event.target.select()" name="vehicle_price" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separator my-3 border-gray-300"></div>
                                            <!-- <div class="col-lg-8 bg-light-warning border rounded pb-3" v-if="this.selectedTransaction.is_vehicle_exist == true">
                                                <div class="form-check mt-3">
                                                    <input class="form-check-input" type="checkbox" value="" id="isUpdateCard" v-model="vehicleForm.is_update_vehicle_card">
                                                    <label class="fs-6 fw-bold form-label" for="isUpdateCard">
                                                        {{ $t('Update Vehicle Card') }}
                                                    </label>
                                                    <br>
                                                    <small> Vehicle card registered to your system will be updated with new details by checking this option. </small>
                                                </div>
                                            </div> -->
                                            <div class="col-lg-4" :class="{ 'col-lg-12' : this.selectedTransaction.is_vehicle_exist == false }"> 
                                                <div class="input-group mb-3">
                                                    <div class="input-group-text">
                                                        <input class="form-check-input" type="checkbox" v-model="vehicleForm.is_update_locked" :disabled="this.vehicleForm.is_admin_update_locked"/>
                                                    </div>
                                                    <span class="input-group-text">
                                                        {{ $t('Lock Update') }}
                                                    </span>
                                                    <span class="input-group-text" v-if="this.$isAdmin() && this.selectedTransaction.is_vehicle_exist == true">
                                                        <button type="button" class="btn btn-xs btn-warning text-center" 
                                                                @click="onAdminActivateLockUpdateForVehicle()"
                                                                :disabled="!this.userData.permission_type == 1"> 
                                                            <i class="fa fa-lock text-gray-800 ps-1" v-if="this.vehicleForm.is_admin_update_locked == true"></i> 
                                                            <i class="fa fa-unlock text-gray-800 ps-1" v-if="this.vehicleForm.is_admin_update_locked !== true"></i> 
                                                        </button>
                                                    </span>
                                                </div>
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                                <div class="row tab-pane fade" id="vehicle_tab_2" role="tabpanel">
                                        <div class="card">
                                        <div class="card-body px-0">
                                            <div class="table-responsive">
                                                <table class="table table-striped text-gray-800 gy-3 gs-3" v-if="this.vehiclePolicyData"> 
                                                    <tbody>  
                                                        <tr class="bg-light-info border-top border-bottom">
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Registration Number</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.registration_number }}</td>
                                                        </tr>
                                                        <tr class="bg-light-warning border-top border-bottom" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                            <td colspan="3" class="text-left align-middle">
                                                                <button class="btn btn-block btn-success btn-xs" v-if="!vehiclePolicyDataChecker.isSelected" @click="onSetAllPolicyVehicleData()" :disabled="this.vehicleForm.is_update_locked && this.vehicleForm.is_admin_update_locked != false"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    SET ALL DATA
                                                                </button>   
                                                                <button class="btn btn-block btn-danger btn-xs" v-if="vehiclePolicyDataChecker.isSelected" @click="onSetAllPolicyVehicleData()" :disabled="this.vehicleForm.is_update_locked && this.vehicleForm.is_admin_update_locked != false"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    UNSET ALL DATA
                                                                </button>
                                                                <span><small class="ps-3"> This action will set/unset all data specified below. </small> </span>
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="name_description" 
                                                                        v-model="vehiclePolicyDataChecker.name_description" @change="this.onSelectPolicyVehicleDataItem('name_description')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.name_description == null || this.vehiclePolicyData.name_description == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Description</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.name_description }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_group_id" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_group_id" @change="this.onSelectPolicyVehicleDataItem('vehicle_group_id')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_group_id == null || this.vehiclePolicyData.vehicle_group_id == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Group</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_group_id }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_type_id" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_type_id" @change="this.onSelectPolicyVehicleDataItem('vehicle_type_id')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_type_id == null || this.vehiclePolicyData.vehicle_type_id == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Type</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_type_id }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_usage_type_name" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_usage_type_name" @change="this.onSelectPolicyVehicleDataItem('vehicle_usage_type_name')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_usage_type_name == null || this.vehiclePolicyData.vehicle_usage_type_name == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Usage Type</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_usage_type_name }} - {{ this.vehiclePolicyData.vehicle_usage_type_code }} - {{ this.vehiclePolicyData.vehicle_usage_type_value }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_usage_type_detail_name" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_usage_type_detail_name" @change="this.onSelectPolicyVehicleDataItem('vehicle_usage_type_detail_name')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_usage_type_detail_name == null || this.vehiclePolicyData.vehicle_usage_type_detail_name == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Usage Detail</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_usage_type_detail_name }} - {{ this.vehiclePolicyData.vehicle_usage_type_detail_code }} - {{ this.vehiclePolicyData.vehicle_usage_type_detail_value }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="fuel_type_id" 
                                                                        v-model="vehiclePolicyDataChecker.fuel_type_id" @change="this.onSelectPolicyVehicleDataItem('fuel_type_id')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.fuel_type_id == null || this.vehiclePolicyData.fuel_type_id == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Fuel Type</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.fuel_type_id }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="licence_number" 
                                                                        v-model="vehiclePolicyDataChecker.licence_number" @change="this.onSelectPolicyVehicleDataItem('licence_number')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.licence_number == null || this.vehiclePolicyData.licence_number == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Licence Number</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.licence_number }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_brand_name" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_brand_name" @change="this.onSelectPolicyVehicleDataItem('vehicle_brand_name')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_brand_name == null || this.vehiclePolicyData.vehicle_brand_name == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Brand</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_brand_name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_model_name" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_model_name" @change="this.onSelectPolicyVehicleDataItem('vehicle_model_name')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_model_name == null || this.vehiclePolicyData.vehicle_model_name == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Model</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_model_name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_code" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_code" @change="this.onSelectPolicyVehicleDataItem('vehicle_code')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_code == null || this.vehiclePolicyData.vehicle_code == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Code</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_code }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="engine_number" 
                                                                        v-model="vehiclePolicyDataChecker.engine_number" @change="this.onSelectPolicyVehicleDataItem('engine_number')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.engine_number == null || this.vehiclePolicyData.engine_number == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Engine Number</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.engine_number }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="chassis_number" 
                                                                        v-model="vehiclePolicyDataChecker.chassis_number" @change="this.onSelectPolicyVehicleDataItem('chassis_number')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.chassis_number == null || this.vehiclePolicyData.chassis_number == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Chassis Number</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.chassis_number }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="model_year" 
                                                                        v-model="vehiclePolicyDataChecker.model_year" @change="this.onSelectPolicyVehicleDataItem('model_year')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.model_year == null || this.vehiclePolicyData.model_year == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Model Year</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.model_year }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="color" 
                                                                        v-model="vehiclePolicyDataChecker.color" @change="this.onSelectPolicyVehicleDataItem('color')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.color == null || this.vehiclePolicyData.color == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Color</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.color }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="max_passenger" 
                                                                        v-model="vehiclePolicyDataChecker.max_passenger" @change="this.onSelectPolicyVehicleDataItem('max_passenger')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.max_passenger == null || this.vehiclePolicyData.max_passenger == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Max.Passenger</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.max_passenger }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="empty_weight" 
                                                                        v-model="vehiclePolicyDataChecker.empty_weight" @change="this.onSelectPolicyVehicleDataItem('empty_weight')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.empty_weight == null || this.vehiclePolicyData.empty_weight == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Empty Weight</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.empty_weight }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="load_capacity" 
                                                                        v-model="vehiclePolicyDataChecker.load_capacity" @change="this.onSelectPolicyVehicleDataItem('load_capacity')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.load_capacity == null || this.vehiclePolicyData.load_capacity == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Load Capacity</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.load_capacity }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="engine_power" 
                                                                        v-model="vehiclePolicyDataChecker.engine_power" @change="this.onSelectPolicyVehicleDataItem('engine_power')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.engine_power == null || this.vehiclePolicyData.engine_power == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Engine Power</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.engine_power }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="first_registration_date" 
                                                                        v-model="vehiclePolicyDataChecker.first_registration_date" @change="this.onSelectPolicyVehicleDataItem('first_registration_date')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.first_registration_date == null || this.vehiclePolicyData.first_registration_date == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">First Reg. Date</td>
                                                            <td class="ps-3">{{ this.$parent.previewDate(this.vehiclePolicyData.first_registration_date) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="last_registration_date" 
                                                                        v-model="vehiclePolicyDataChecker.last_registration_date" @change="this.onSelectPolicyVehicleDataItem('last_registration_date')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.last_registration_date == null || this.vehiclePolicyData.last_registration_date == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Last Reg. Date</td>
                                                            <td class="ps-3">{{ this.$parent.previewDate(this.vehiclePolicyData.last_registration_date) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_vehicle_exist == true || this.selectedTransaction.is_vehicle_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="vehicle_price" 
                                                                        v-model="vehiclePolicyDataChecker.vehicle_price" @change="this.onSelectPolicyVehicleDataItem('vehicle_price')"
                                                                        :disabled="(this.vehicleForm.is_update_locked == true && this.vehicleForm.is_admin_update_locked != false) || (this.vehiclePolicyData.vehicle_price == null || this.vehiclePolicyData.vehicle_price == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Vehicle Price</td>
                                                            <td class="ps-3">{{ this.vehiclePolicyData.vehicle_price }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-success btn-sm w-100" 
                                    type="button" 
                                    @click="this.onSaveVehicle()" 
                                    :disabled="this.vehicleForm.is_admin_update_locked == true && this.vehicleForm.is_admin_update_locked != false"> 
                                    <i class="fa fa-save"></i> SAVE VEHICLE
                            </button>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isLoading == true || this.isModelsLoading == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name:'FactorLoadModal',
    data(){
        return {
            isLoading: false,
            isModelsLoading: false,
            userData: this.$getUser(),
            selectedTransaction: {},
            vehicleRegistrationStatusList: [
                {id: 1, text: 'Registered'},
                {id: 2, text: 'Not Registered'}
            ],
            vehiclePolicyData: null,
            vehiclePolicyDataChecker: {},
            originalVehicleAccount: {
                is_update_locked: false
            },                
            vehicleForm: {
                vehicle_usage_type_name: null,
                vehicle_usage_type_detail_name: null,
                vehicle_brand_name: null,
                vehicle_model_name: null,
                name_description: null,
                vehicle_group_id: '-1',
                vehicle_type_id: '-1',
                usage_type_id: '-1',
                usage_detail_id: '-1',
                fuel_type_id: '-1',
                licence_number: null,
                vehicle_brand_id: '-1',
                vehicle_model_id: '-1',
                vehicle_brand_code: null,
                vehicle_model_code: null,
                vehicle_code: null,
                engine_number: null,
                chassis_number: null,
                model_year: null,
                color: null,
                max_passenger: 0,
                empty_weight: 0,
                load_capacity: 0,
                engine_power: 0,
                last_registration_date: null,
                first_registration_date: null,
                vehicle_price: 0,
                is_update_vehicle_card: true,
                is_update_locked: false,
                is_admin_update_locked: false
            },
            vehicleGroupList: [],
            vehicleTypeList: [],
            usageTypeList: [],
            usageDetailList: [],
            fuelTypeList: [],
            vehicleBrandList: [],
            vehicleModelList: [{id: '-1', text: 'Select an option'}],
        }
    },
    methods:{
        getData(item, isUnsetFeatureTriggered){            
            if(isUnsetFeatureTriggered == true){
                item = this.selectedTransaction;
            }
            else{
                this.selectedTransaction = item; 
            }
            this.vehiclePolicyDataChecker = {};
            this.isLoading = true;
            const parametersOne = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 9, list_name: "vehicle_group" },
                    { list_type: 10, list_name: "vehicle_type" },
                    { list_type: 11, list_name: "usage_type", data_code: this.vehicleForm.vehicle_usage_type_code },
                    { list_type: 12, list_name: "usage_detail" },
                    { list_type: 13, list_name: "fuel_type" },
                    { list_type: 15, list_name: "vehicle_brand" }
                ],
                parent_account_id: this.selectedTransaction.supplier_producer_id,
                account_id: this.selectedTransaction.supplier_producer_id
            };
            const parametersTwo = {
                session_id: this.$getUser().session_id,
                account_id: this.selectedTransaction.supplier_producer_id,
                data_type: 12 // Registration Status
            };
            const parametersThree = {
                session_id: this.$getUser().session_id,
                account_id: this.selectedTransaction.supplier_producer_id,
                data_type: -1 // ALL
            };
            const parametersFour = {
                route: this.selectedTransaction.is_vehicle_exist === 1 ? 'VehicleUpdate' : 'VehicleCreate',
                session_id: this.$getUser().session_id
            };
            const requestOne =  axios.post('/api/Helper/GetMultiPolicyListsWithImportParameters', parametersOne, {'Content-Type': 'application/json'}); 
            const requestTwo = axios.post('/api/SupplierAccount/GetDataImportParameters', parametersTwo, {'Content-Type': 'application/json'}); 
            const requestThree = axios.post('/api/SupplierAccount/GetDataImportParameters', parametersThree, {'Content-Type': 'application/json'}); 
            const requestFour =  axios.post('/api/FormValidations/GetFormFields', parametersFour, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {                        
                this.vehicleGroupList = responses[0].data.vehicle_group;
                this.vehicleTypeList = responses[0].data.vehicle_type; 
                this.usageTypeList = responses[0].data.usage_type; 
                this.usageDetailList = responses[0].data.usage_detail; 
                this.fuelTypeList = responses[0].data.fuel_type; 
                this.vehicleBrandList = responses[0].data.vehicle_brand;
                this.vehicleStatus = responses[1].data !== null && responses[1].data.length > 0 ? responses[1].data[0] : null;
                this.vehicleImportParameters = responses[2].data;                
                this.vehicleGroupList.unshift({ id: '-1', text: 'Select an option'});
                this.vehicleTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.usageTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.usageDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.fuelTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.vehicleBrandList.unshift({ id: '-1', text: 'Select an option'});        
                if(responses[3].data != null){  
                    sessionStorage.removeItem('frm_val');
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[3].data));   
                }     
                
                // ARAÇ KARTI SİGORTALIDA VAR; UPDATE MODALI AÇILIR
                if(item.is_vehicle_exist == 1 || item.is_vehicle_exist == true){
                    this.getExistingVehicleInfo(item);                    
                }
                // ARAÇ KARTI YOK; INSERT MODALI AÇILIR
                if(item.is_vehicle_exist == 0 || item.is_vehicle_exist == false){
                    this.getTempVehicleInfo(item);
                }
            }))
            .catch(function (error) {
                console.log(error);
            });
        },
        getExistingVehicleInfo(item) {
            const parameters = {
                policy_transaction_id: item.id,
                session_id: this.$getUser().session_id
            };  
            const parametersTwo = {
                session_id: this.$getUser().session_id,
                registration_number: item.vehicle_registration_number,
                account_id: item.customer_account_id
            };
            this.isLoading = true;
            const that = this;            
            const requestOne =  axios.post('/api/DLLTemplates/GetTempTransactionVehicle', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Insurance/GetFactorVehiclesByVehicleInfo', parametersTwo, {'Content-Type': 'application/json'});             
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                if(responses[0].data.length > 0){
                    this.vehiclePolicyData = responses[0].data[0];
                    this.vehiclePolicyData.chassis_number = this.$unlock(this.vehiclePolicyData.chassis_number);
                    this.vehiclePolicyData.engine_number = this.$unlock(this.vehiclePolicyData.engine_number);
                    this.vehiclePolicyData.licence_number = this.$unlock(this.vehiclePolicyData.licence_number);
                    this.vehiclePolicyData.name_description = this.$unlock(this.vehiclePolicyData.name_description);
                    this.vehiclePolicyData.registration_number = this.$unlock(this.vehiclePolicyData.registration_number);
                } 
                this.vehicleForm = responses[1].data;
                this.vehicleForm.is_update_locked = responses[1].data.is_update_locked == 1 ? true : false;
                this.vehicleForm.is_admin_update_locked = responses[1].data.is_update_locked == 1 ? true : false;
                this.vehicleForm.name_description = responses[1].data.name;
                const originalVehicleData = JSON.parse(JSON.stringify(this.vehicleForm));
                this.originalVehicleAccount = originalVehicleData;
                this.vehicleForm.vehicle_registration_number = responses[1].data.registration_number; 
                
                this.vehicleBrandList.forEach(element => {
                    if(element.code == this.vehicleForm.vehicle_brand_id){
                        this.vehicleForm.vehicle_brand_id = element.id;
                    }
                });
                this.vehicleForm.usage_detail_id = this.vehicleForm.usage_detail_id == null || this.vehicleForm.usage_detail_id == '' ? '-1' : this.vehicleForm.usage_detail_id;
                this.vehicleForm.usage_type_id = this.vehicleForm.usage_type_id == null || this.vehicleForm.usage_type_id == '' ? '-1' : this.vehicleForm.usage_type_id;
                this.vehicleForm.vehicle_brand_id = this.vehicleForm.vehicle_brand_id == null || this.vehicleForm.vehicle_brand_id == '' ? '-1' : this.vehicleForm.vehicle_brand_id;
                this.vehicleForm.vehicle_group_id = this.vehicleForm.vehicle_group_id == null || this.vehicleForm.vehicle_group_id == '' ? '-1' : this.vehicleForm.vehicle_group_id;
                this.vehicleForm.vehicle_model_id = this.vehicleForm.vehicle_model_id == null || this.vehicleForm.vehicle_model_id == '' ? '-1' : this.vehicleForm.vehicle_model_id;
                this.vehicleForm.vehicle_type_id = this.vehicleForm.vehicle_type_id == null || this.vehicleForm.vehicle_type_id == '' ? '-1' : this.vehicleForm.vehicle_type_id;
                this.vehicleForm.vehicle_registration_status = this.vehicleForm.vehicle_registration_status == null || this.vehicleForm.vehicle_registration_status == '' ? 1 : this.vehicleForm.vehicle_registration_status;
                this.vehicleForm.fuel_type_id = this.vehicleForm.fuel_type_id == null || this.vehicleForm.fuel_type_id == '' ? '-1' : this.vehicleForm.fuel_type_id;
                this.vehicleForm.is_update_vehicle_card = this.vehicleForm.is_update_vehicle_card == 1 ? true : false;
                this.vehicleForm.is_admin_update_locked = this.vehicleForm.is_update_locked == 1 ? true : false; 

                // this.vehicleImportParameters.forEach(importParameter => {
                //     //Usage Type
                //     if(importParameter.data_type == 10 && (this.vehiclePolicyData.vehicle_usage_type_code == importParameter.data_code || this.vehiclePolicyData.vehicle_usage_type_value == importParameter.data_value)){
                //         this.vehicleForm.usage_type_id = importParameter.sub_list_id;
                //     }
                //     //Usage Detail
                //     if(importParameter.data_type == 11 && (this.vehiclePolicyData.vehicle_usage_type_detail_code == importParameter.data_code || this.vehiclePolicyData.vehicle_usage_type_detail_value == importParameter.data_value)){
                //         this.vehicleForm.usage_detail_id = importParameter.sub_list_id;
                //     }
                //     //Vehicle Group
                //     if(importParameter.data_type == 8 && (this.vehiclePolicyData.vehicle_group_code == importParameter.data_code || this.vehiclePolicyData.vehicle_group_value == importParameter.data_value)){
                //         this.vehicleForm.vehicle_group_id = importParameter.sub_list_id;
                //     }
                //     //Vehicle Type
                //     if(importParameter.data_type == 9 && (this.vehiclePolicyData.vehicle_type_code == importParameter.data_code || this.vehiclePolicyData.vehicle_type_value == importParameter.data_value)){
                //         this.vehicleForm.vehicle_type_id = importParameter.sub_list_id;
                //     }
                // });
                //Vehicle's Registration Statusunu Plakadan anlıyoruz.
                if(this.vehicleStatus != null){
                    const count =  this.resolveRegistrationStatus(this.vehicleStatus.data_code, '%');
                    const licenceNumber = this.vehicleForm.registration_number.substr(count);
                    const vehicleStatusCode = this.vehicleStatus.data_code.substr(count);
                    if(vehicleStatusCode == licenceNumber){
                        this.vehicleForm.vehicle_registration_status = 2; // Vehicle Not Registered
                    }
                }
                setTimeout(() => { 
                    this.getVehicleModelList(true, false, true);
                }, 500);
            }))
            .catch(errors => { 
                console.error(errors); 
            })
            .finally(function() {
                that.isLoading = false;
            });
        },
        getTempVehicleInfo(item) {
            const tempVehicleParameters = {
                policy_transaction_id: item.id,
                session_id: this.$getUser().session_id
            };
            this.isLoading = true;
            const that = this;
            axios.post('/api/DLLTemplates/GetTempTransactionVehicle', tempVehicleParameters, {'Content-Type': 'application/json'}).then((tempVehicleResponse) => {
                if(tempVehicleResponse.data.length > 0)
                {
                    tempVehicleResponse.data.forEach(element => {
                        element.chassis_number = this.$unlock(element.chassis_number);
                        element.engine_number = this.$unlock(element.engine_number);
                        element.licence_number = this.$unlock(element.licence_number);
                        element.name_description = this.$unlock(element.name_description);
                        element.registration_number = this.$unlock(element.registration_number);
                    });
                    this.vehiclePolicyData = tempVehicleResponse.data[0];
                    this.vehicleForm = tempVehicleResponse.data[0];
                    this.vehicleForm['policy_transaction_id'] = item.id;
                    this.vehicleBrandList.forEach(element => {
                        if(element.code == this.vehicleForm.vehicle_brand_code){
                            this.vehicleForm.vehicle_brand_id = element.id;
                        }
                    });
                    this.vehicleForm.vehicle_registration_number = this.vehicleForm.registration_number;
                    this.vehicleForm.usage_detail_id = this.vehicleForm.usage_detail_id == null || this.vehicleForm.usage_detail_id == '' ? '-1' : this.vehicleForm.usage_detail_id;
                    this.vehicleForm.usage_type_id = this.vehicleForm.usage_type_id == null || this.vehicleForm.usage_type_id == '' ? '-1' : this.vehicleForm.usage_type_id;
                    this.vehicleForm.vehicle_brand_id = this.vehicleForm.vehicle_brand_id == null || this.vehicleForm.vehicle_brand_id == '' ? '-1' : this.vehicleForm.vehicle_brand_id;
                    this.vehicleForm.vehicle_group_id = this.vehicleForm.vehicle_group_id == null || this.vehicleForm.vehicle_group_id == '' ? '-1' : this.vehicleForm.vehicle_group_id;
                    this.vehicleForm.vehicle_model_id = this.vehicleForm.vehicle_model_id == null || this.vehicleForm.vehicle_model_id == '' ? '-1' : this.vehicleForm.vehicle_model_id;
                    this.vehicleForm.vehicle_type_id = this.vehicleForm.vehicle_type_id == null || this.vehicleForm.vehicle_type_id == '' ? '-1' : this.vehicleForm.vehicle_type_id;
                    this.vehicleForm.vehicle_registration_status = this.vehicleForm.vehicle_registration_status == null || this.vehicleForm.vehicle_registration_status == '' ? 1 : this.vehicleForm.vehicle_registration_status;
                    this.vehicleForm.fuel_type_id = this.vehicleForm.fuel_type_id == null || this.vehicleForm.fuel_type_id == '' ? '-1' : this.vehicleForm.fuel_type_id;
                    this.vehicleForm.is_update_vehicle_card = this.vehicleForm.is_update_vehicle_card == 1 ? true : false;
                    this.vehicleForm.is_admin_update_locked = this.vehicleForm.is_update_locked == 1 ? true : false; 

                    this.vehicleImportParameters.forEach(importParameter => {
                        //Usage Type
                        if((this.vehicleForm.usage_type_id == null || this.vehicleForm.usage_type_id == '-1' || this.vehicleForm.usage_type_id == '') && importParameter.data_type == 10 && (this.vehiclePolicyData.vehicle_usage_type_code == importParameter.data_code || this.vehiclePolicyData.vehicle_usage_type_value == importParameter.data_value)){
                            this.vehicleForm.usage_type_id = importParameter.sub_list_id;
                        }
                        //Usage Detail
                        if((this.vehicleForm.usage_detail_id == null || this.vehicleForm.usage_detail_id == '-1' || this.vehicleForm.usage_detail_id == '') && importParameter.data_type == 11 && (this.vehiclePolicyData.vehicle_usage_type_detail_code == importParameter.data_code || this.vehiclePolicyData.vehicle_usage_type_detail_value == importParameter.data_value)){
                            this.vehicleForm.usage_detail_id = importParameter.sub_list_id;
                        }
                        //Vehicle Group
                        if((this.vehicleForm.vehicle_group_id == null || this.vehicleForm.vehicle_group_id == '-1' || this.vehicleForm.vehicle_group_id == '') && importParameter.data_type == 8 && (this.vehiclePolicyData.vehicle_group_code == importParameter.data_code || this.vehiclePolicyData.vehicle_group_value == importParameter.data_value)){
                            this.vehicleForm.vehicle_group_id = importParameter.sub_list_id;
                        }
                        //Vehicle Type
                        if((this.vehicleForm.vehicle_type_id == null || this.vehicleForm.vehicle_type_id == '-1' || this.vehicleForm.vehicle_type_id == '') && importParameter.data_type == 9 && (this.vehiclePolicyData.vehicle_type_code == importParameter.data_code || this.vehiclePolicyData.vehicle_type_value == importParameter.data_value)){
                            this.vehicleForm.vehicle_type_id = importParameter.sub_list_id;
                        }
                    });
                    //Vehicle's Registration Statusunu Plakadan anlıyoruz.
                    if(this.vehicleStatus != null){
                        const count =  this.resolveRegistrationStatus(this.vehicleStatus.data_code, '%');
                        const licenceNumber = this.vehicleForm.registration_number.substr(count);
                        const vehicleStatusCode = this.vehicleStatus.data_code.substr(count);
                        if(vehicleStatusCode == licenceNumber){
                            this.vehicleForm.vehicle_registration_status = 2; // Vehicle Not Registered
                        }
                    }

                    setTimeout(() => { 
                        this.getVehicleModelList(true);
                    }, 500);
                }
                else
                { 
                    this.vehicleGroupList.unshift({ id: '-1', text: 'Select an option'});
                    this.vehicleTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.usageTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.usageDetailList.unshift({ id: '-1', text: 'Select an option'});
                    this.fuelTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.vehicleBrandList.unshift({ id: '-1', text: 'Select an option'});
                    this.clearVehicleForm();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function() {
                that.isLoading = false;
            });
        },
        getVehicleModelList(e, isUpdated, isUpdateModal) { 
            if(e){
                this.isModelsLoading = true;
                const that = this;
                this.vehicleModelList = [];
                const params = {
                    session_id: this.$getUser().session_id,
                    list_type: 16,
                    parent_id: this.vehicleForm.vehicle_brand_id
                };
                axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'})
                .then((response) => {  
                    this.vehicleModelList = response.data;
                    this.vehicleModelList.unshift({ id: '-1', text: 'Select an option'});
                    if(this.vehicleForm.vehicle_model_id == '' || this.vehicleForm.vehicle_model_id == null || this.vehicleForm.vehicle_model_id == '-1'){ 
                        this.vehicleModelList.forEach(modelItem => {
                            if(modelItem.code == this.vehicleForm.vehicle_model_code){
                                this.vehicleForm.vehicle_model_id = modelItem.id;
                            }
                        }); 
                    }
                    if(isUpdated){
                        this.vehicleForm.vehicle_model_id = '-1';
                        this.vehicleForm.vehicle_brand_code = e.code;
                    }
                    if(isUpdateModal){
                        this.vehicleModelList.forEach(modelItem => {
                            if(modelItem.code == this.vehicleForm.vehicle_model_id){
                                this.vehicleForm.vehicle_model_id = modelItem.id;
                            }
                        }); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function() {
                    that.isModelsLoading = false;
                });
            }
        },
        onSelectVehicleModel(event) {
            this.vehicleForm.vehicle_model_code = event.code;
            this.vehicleForm.vehicle_code = this.vehicleForm.vehicle_brand_code + this.vehicleForm.vehicle_model_code;
        }, 
        clearVehicleForm(){
            this.vehicleForm = {
                vehicle_usage_type_name: null,
                vehicle_usage_type_detail_name: null,
                vehicle_brand_name: null,
                vehicle_model_name: null,
                name_description: null,
                vehicle_group_id: '-1',
                vehicle_type_id: '-1',
                usage_type_id: '-1',
                usage_detail_id: '-1',
                fuel_type_id: '-1',
                licence_number: null,
                vehicle_brand_id: '-1',
                vehicle_model_id: '-1',
                vehicle_brand_code: null,
                vehicle_model_code: null,
                vehicle_code: null,
                engine_number: null,
                chassis_number: null,
                model_year: null,
                color: null,
                max_passenger: 0,
                empty_weight: 0,
                load_capacity: 0,
                engine_power: 0,
                last_registration_date: null,
                first_registration_date: null,
                vehicle_price: 0,
                is_update_vehicle_card: true,
                is_update_locked: false,
                is_admin_update_locked: false
            };
        },
        onSaveVehicle(){
            this.$checkSelect2Validation('vehicleModal');
            if(!document.forms['vehicleModal'].reportValidity()){
                return;
            }
            this.$swal.fire({
                title: this.vehicleForm.is_update_vehicle_card == 1 || this.vehicleForm.is_update_vehicle_card == true ? 'Are you sure you want to save vehicle? Account\'s vehicle card will also be updated.' : 'Are you sure you want to save vehicle? Only Imported Data\'s vehicle card will be saved.',
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "SAVE VEHICLE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                    const params = this.vehicleForm;
                    params.session_id = this.$getUser().session_id;
                    params.id = this.vehiclePolicyData.id; //ID her zaman Data Import'daki Factor ID'sidir.
                    params.vehicle_group_id = (params.vehicle_group_id == '-1' || params.vehicle_group_id == '') ? null : params.vehicle_group_id;
                    params.vehicle_type_id = (params.vehicle_type_id == '-1' || params.vehicle_type_id == '') ? null : params.vehicle_type_id;
                    params.usage_type_id = (params.usage_type_id == '-1' || params.usage_type_id == '') ? null : params.usage_type_id;
                    params.usage_detail_id = (params.usage_detail_id == '-1' || params.usage_detail_id == '') ? null : params.usage_detail_id;
                    params.fuel_type_id = (params.fuel_type_id == '-1' || params.fuel_type_id == '') ? null : params.fuel_type_id;
                    params.vehicle_brand_id = (params.vehicle_brand_id == '-1' || params.vehicle_brand_id == '') ? null : params.vehicle_brand_id;
                    params.vehicle_model_id = (params.vehicle_model_id == '-1' || params.vehicle_model_id == '') ? null : params.vehicle_model_id;
                    params.is_update_vehicle_card = 1;
                    params.account_id = this.selectedTransaction.customer_account_id;
                    params.policy_transaction_id = this.selectedTransaction.id;
                    params.vehicle_usage_type_code = (params.vehicle_usage_type_code == null || params.vehicle_usage_type_code == '') ? '0' : params.vehicle_usage_type_code.toString();
                    params.vehicle_usage_type_detail_code = (params.vehicle_usage_type_detail_code == null || params.vehicle_usage_type_detail_code == '') ? '0' : params.vehicle_usage_type_detail_code;
                    params.vehicle_brand_code = (params.vehicle_brand_code == null || params.vehicle_brand_code == '') ? 0 : params.vehicle_brand_code;
                    params.vehicle_model_code = (params.vehicle_model_code == null || params.vehicle_model_code == '') ? 0 : params.vehicle_model_code;
                    params.is_update_locked = params.is_update_locked == true ? 1 : 0;

                    axios.post('/api/DLLTemplates/UpdateTempTransactionVehicle', params, {'Content-Type': 'application/json'})
                    .then(() => {
                        this.$swal("Success", "Vehicle saved successfully..!", 'success');
                        setTimeout(() => {
                            this.$refs.closeVehicleModal.click();
                            this.$parent.getTransactionRowById(this.selectedTransaction.id, 1);
                        }, 500);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            });  
        },
        onAdminActivateLockUpdateForVehicle(){
            this.vehicleForm.is_admin_update_locked = !this.vehicleForm.is_admin_update_locked;
        },
        onSetAllPolicyVehicleData(){
            this.vehiclePolicyDataChecker.isSelected = !this.vehiclePolicyDataChecker.isSelected;
            if(this.vehiclePolicyDataChecker.isSelected == true){
                this.vehiclePolicyDataChecker.name_description = true;
                this.vehiclePolicyDataChecker.vehicle_group_id = true;
                this.vehiclePolicyDataChecker.vehicle_type_id = true;
                this.vehiclePolicyDataChecker.vehicle_usage_type_name = true;
                this.vehiclePolicyDataChecker.vehicle_usage_type_detail_name = true;
                this.vehiclePolicyDataChecker.fuel_type_id = true;
                this.vehiclePolicyDataChecker.licence_number = true;
                this.vehiclePolicyDataChecker.vehicle_brand_name = true;
                this.vehiclePolicyDataChecker.vehicle_model_name = true;
                this.vehiclePolicyDataChecker.vehicle_code = true;
                this.vehiclePolicyDataChecker.engine_number = true;
                this.vehiclePolicyDataChecker.chassis_number = true;
                this.vehiclePolicyDataChecker.model_year = true;
                this.vehiclePolicyDataChecker.color = true;
                this.vehiclePolicyDataChecker.max_passenger = true;
                this.vehiclePolicyDataChecker.empty_weight = true;
                this.vehiclePolicyDataChecker.load_capacity = true;
                this.vehiclePolicyDataChecker.engine_power = true;
                this.vehiclePolicyDataChecker.first_registration_date = true;
                this.vehiclePolicyDataChecker.last_registration_date = true;
                this.vehiclePolicyDataChecker.vehicle_price = true;
                this.vehicleForm.name_description = this.vehiclePolicyData.name_description;
                this.vehicleForm.vehicle_group_id = this.vehiclePolicyData.vehicle_group_id !== '' && this.vehiclePolicyData.vehicle_group_id !== null && this.vehiclePolicyData.vehicle_group_id !== '-1' ? this.vehiclePolicyData.vehicle_group_id : this.vehicleForm.vehicle_group_id;
                this.vehicleForm.vehicle_type_id = this.vehiclePolicyData.vehicle_type_id !== '' && this.vehiclePolicyData.vehicle_type_id !== null && this.vehiclePolicyData.vehicle_type_id !== '-1' ? this.vehiclePolicyData.vehicle_type_id : this.vehicleForm.vehicle_type_id;
                this.vehicleForm.usage_type_id = this.usageTypeList.find( x => x.code == this.vehiclePolicyData.vehicle_usage_type_code) !== undefined ? this.usageTypeList.find( x => x.code == this.vehiclePolicyData.vehicle_usage_type_code).id : this.vehicleForm.usage_type_id;
                this.vehicleForm.usage_detail_id = this.usageDetailList.find( x => x.code == this.vehiclePolicyData.vehicle_usage_type_detail_code) !== undefined ? this.usageDetailList.find( x => x.code == this.vehiclePolicyData.vehicle_usage_type_detail_code).id : this.vehicleForm.usage_detail_id;
                this.vehicleForm.fuel_type_id = this.vehiclePolicyData.fuel_type_id !== '' && this.vehiclePolicyData.fuel_type_id !== null && this.vehiclePolicyData.fuel_type_id !== '-1' ? this.vehiclePolicyData.fuel_type_id : this.vehicleForm.fuel_type_id;
                this.vehicleForm.licence_number = this.vehiclePolicyData.licence_number;
                this.vehicleForm.vehicle_code = this.vehiclePolicyData.vehicle_code;
                this.vehicleForm.engine_number = this.vehiclePolicyData.engine_number;
                this.vehicleForm.chassis_number = this.vehiclePolicyData.chassis_number;
                this.vehicleForm.model_year = this.vehiclePolicyData.model_year;
                this.vehicleForm.color = this.vehiclePolicyData.color;
                this.vehicleForm.max_passenger = this.vehiclePolicyData.max_passenger;
                this.vehicleForm.empty_weight = this.vehiclePolicyData.empty_weight;
                this.vehicleForm.load_capacity = this.vehiclePolicyData.load_capacity;
                this.vehicleForm.engine_power = this.vehiclePolicyData.engine_power;
                this.vehicleForm.first_registration_date = this.vehiclePolicyData.first_registration_date;
                this.vehicleForm.last_registration_date = this.vehiclePolicyData.last_registration_date;
                this.vehicleForm.vehicle_price = this.vehiclePolicyData.vehicle_price;
                this.vehicleForm.vehicle_brand_id = this.vehicleBrandList.find( x => x.code == this.vehiclePolicyData.vehicle_brand_code) !== undefined ? this.vehicleBrandList.find( x => x.code == this.vehiclePolicyData.vehicle_brand_code).id : this.vehicleForm.vehicle_brand_id;
                this.getVehicleModelList(true);
                this.vehicleForm.vehicle_model_id = this.vehicleModelList.find( x => x.code == this.vehiclePolicyData.vehicle_model_code) !== undefined ? this.vehicleModelList.find( x => x.code == this.vehiclePolicyData.vehicle_model_code).id : this.vehicleForm.vehicle_model_id;
            }
            if(this.vehiclePolicyDataChecker.isSelected == false){
                this.vehiclePolicyDataChecker.name_description = false;
                this.vehiclePolicyDataChecker.vehicle_group_id = false;
                this.vehiclePolicyDataChecker.vehicle_type_id = false;
                this.vehiclePolicyDataChecker.vehicle_usage_type_name = false;
                this.vehiclePolicyDataChecker.vehicle_usage_type_detail_name = false;
                this.vehiclePolicyDataChecker.fuel_type_id = false;
                this.vehiclePolicyDataChecker.licence_number = false;
                this.vehiclePolicyDataChecker.vehicle_brand_name = false;
                this.vehiclePolicyDataChecker.vehicle_model_name = false;
                this.vehiclePolicyDataChecker.vehicle_code = false;
                this.vehiclePolicyDataChecker.engine_number = false;
                this.vehiclePolicyDataChecker.chassis_number = false;
                this.vehiclePolicyDataChecker.model_year = false;
                this.vehiclePolicyDataChecker.color = false;
                this.vehiclePolicyDataChecker.max_passenger = false;
                this.vehiclePolicyDataChecker.empty_weight = false;
                this.vehiclePolicyDataChecker.load_capacity = false;
                this.vehiclePolicyDataChecker.engine_power = false;
                this.vehiclePolicyDataChecker.first_registration_date = false;
                this.vehiclePolicyDataChecker.last_registration_date = false;
                this.vehiclePolicyDataChecker.vehicle_price = false;
                this.getData(null, true);
            }
        },
        onSelectPolicyVehicleDataItem(type){

            // POLICY ÜZERİNDEKİ DATA SETLENİR
            if(type == 'name_description' && this.vehiclePolicyDataChecker.name_description == true && this.vehiclePolicyData.name_description != '' && this.vehiclePolicyData.name_description != null){
                this.vehicleForm.name_description = this.vehiclePolicyData.name_description;
            }
            if(type == 'vehicle_group_id' && this.vehiclePolicyDataChecker.vehicle_group_id == true && this.vehiclePolicyData.vehicle_group_id != '' && this.vehiclePolicyData.vehicle_group_id != null){
                this.vehicleForm.vehicle_group_id = this.vehiclePolicyData.vehicle_group_id;
            }
            if(type == 'vehicle_type_id' && this.vehiclePolicyDataChecker.vehicle_type_id == true && this.vehiclePolicyData.vehicle_type_id != '' && this.vehiclePolicyData.vehicle_type_id != null){
                this.vehicleForm.vehicle_type_id = this.vehiclePolicyData.vehicle_type_id;
            }
            if(type == 'vehicle_usage_type_name' && this.vehiclePolicyDataChecker.vehicle_usage_type_name == true && this.vehiclePolicyData.vehicle_usage_type_code != '' && this.vehiclePolicyData.vehicle_usage_type_code != null){
                this.vehicleForm.usage_type_id = this.vehiclePolicyData.vehicle_usage_type_code;
            }
            if(type == 'vehicle_usage_type_detail_name' && this.vehiclePolicyDataChecker.vehicle_usage_type_detail_name == true && this.vehiclePolicyData.vehicle_usage_type_detail_code != '' && this.vehiclePolicyData.vehicle_usage_type_detail_code != null){
                this.vehicleForm.usage_detail_id = this.vehiclePolicyData.vehicle_usage_type_detail_code;
            }
            if(type == 'fuel_type_id' && this.vehiclePolicyDataChecker.fuel_type_id == true && this.vehiclePolicyData.fuel_type_id != '' && this.vehiclePolicyData.fuel_type_id != null){
                this.vehicleForm.fuel_type_id = this.vehiclePolicyData.fuel_type_id;
            }
            if(type == 'licence_number' && this.vehiclePolicyDataChecker.licence_number == true && this.vehiclePolicyData.licence_number != '' && this.vehiclePolicyData.licence_number != null){
                this.vehicleForm.licence_number = this.vehiclePolicyData.licence_number;
            }
            if(type == 'vehicle_brand_name' && this.vehiclePolicyDataChecker.vehicle_brand_name == true && this.vehiclePolicyData.vehicle_brand_code != '' && this.vehiclePolicyData.vehicle_brand_code != null){
                this.vehicleForm.vehicle_brand_id = this.vehicleBrandList.find( x => x.code == this.vehiclePolicyData.vehicle_brand_code).id;
            }
            if(type == 'vehicle_model_name' && this.vehiclePolicyDataChecker.vehicle_model_name == true && this.vehiclePolicyData.vehicle_model_code != '' && this.vehiclePolicyData.vehicle_model_code != null){
                this.getVehicleModelList(true);
                this.vehicleForm.vehicle_model_id = this.vehicleModelList.find( x => x.code == this.vehiclePolicyData.vehicle_model_code).id;
            }
            if(type == 'vehicle_code' && this.vehiclePolicyDataChecker.vehicle_code == true && this.vehiclePolicyData.vehicle_code != '' && this.vehiclePolicyData.vehicle_code != null){
                this.vehicleForm.vehicle_code = this.vehiclePolicyData.vehicle_code;
            }
            if(type == 'engine_number' && this.vehiclePolicyDataChecker.engine_number == true && this.vehiclePolicyData.engine_number != '' && this.vehiclePolicyData.engine_number != null){
                this.vehicleForm.engine_number = this.vehiclePolicyData.engine_number;
            }
            if(type == 'chassis_number' && this.vehiclePolicyDataChecker.chassis_number == true && this.vehiclePolicyData.chassis_number != '' && this.vehiclePolicyData.chassis_number != null){
                this.vehicleForm.chassis_number = this.vehiclePolicyData.chassis_number;
            }
            if(type == 'model_year' && this.vehiclePolicyDataChecker.model_year == true && this.vehiclePolicyData.model_year != '' && this.vehiclePolicyData.model_year != null){
                this.vehicleForm.model_year = this.vehiclePolicyData.model_year;
            }
            if(type == 'color' && this.vehiclePolicyDataChecker.color == true && this.vehiclePolicyData.color != '' && this.vehiclePolicyData.color != null){
                this.vehicleForm.color = this.vehiclePolicyData.color;
            }
            if(type == 'max_passenger' && this.vehiclePolicyDataChecker.max_passenger == true && this.vehiclePolicyData.max_passenger != '' && this.vehiclePolicyData.max_passenger != null){
                this.vehicleForm.max_passenger = this.vehiclePolicyData.max_passenger;
            }
            if(type == 'empty_weight' && this.vehiclePolicyDataChecker.empty_weight == true && this.vehiclePolicyData.empty_weight != '' && this.vehiclePolicyData.empty_weight != null){
                this.vehicleForm.empty_weight = this.vehiclePolicyData.empty_weight;
            }
            if(type == 'load_capacity' && this.vehiclePolicyDataChecker.load_capacity == true && this.vehiclePolicyData.load_capacity != '' && this.vehiclePolicyData.load_capacity != null){
                this.vehicleForm.load_capacity = this.vehiclePolicyData.load_capacity;
            }
            if(type == 'engine_power' && this.vehiclePolicyDataChecker.engine_power == true && this.vehiclePolicyData.engine_power != '' && this.vehiclePolicyData.engine_power != null){
                this.vehicleForm.engine_power = this.vehiclePolicyData.engine_power;
            }
            if(type == 'first_registration_date' && this.vehiclePolicyDataChecker.first_registration_date == true && this.vehiclePolicyData.first_registration_date != '' && this.vehiclePolicyData.first_registration_date != null){
                this.vehicleForm.first_registration_date = this.vehiclePolicyData.first_registration_date;
            }
            if(type == 'last_registration_date' && this.vehiclePolicyDataChecker.last_registration_date == true && this.vehiclePolicyData.last_registration_date != '' && this.vehiclePolicyData.last_registration_date != null){
                this.vehicleForm.last_registration_date = this.vehiclePolicyData.last_registration_date;
            }
            if(type == 'vehicle_price' && this.vehiclePolicyDataChecker.vehicle_price == true && this.vehiclePolicyData.vehicle_price != '' && this.vehiclePolicyData.vehicle_price != null){
                this.vehicleForm.vehicle_price = this.vehiclePolicyData.vehicle_price;
            }
            
            // KARTTAN ORİJİNAL DATA YENİDEN SETLENİR
            if(type == 'name_description' && this.vehiclePolicyDataChecker.name_description == false){
                this.vehicleForm.name_description = this.originalVehicleAccount.name_description;
            }
            if(type == 'vehicle_group_id' && this.vehiclePolicyDataChecker.vehicle_group_id == false){
                this.vehicleForm.vehicle_group_id = this.originalVehicleAccount.vehicle_group_id;
            }
            if(type == 'vehicle_type_id' && this.vehiclePolicyDataChecker.vehicle_type_id == false){
                this.vehicleForm.vehicle_type_id = this.originalVehicleAccount.vehicle_type_id;
            }
            if(type == 'vehicle_usage_type_name' && this.vehiclePolicyDataChecker.vehicle_usage_type_name == false){
                this.vehicleForm.usage_type_id = this.originalVehicleAccount.usage_type_id;
            }
            if(type == 'vehicle_usage_type_detail_name' && this.vehiclePolicyDataChecker.vehicle_usage_type_detail_name == false){
                this.vehicleForm.usage_detail_id = this.originalVehicleAccount.usage_detail_id;
            }
            if(type == 'fuel_type_id' && this.vehiclePolicyDataChecker.fuel_type_id == false){
                this.vehicleForm.fuel_type_id = this.originalVehicleAccount.fuel_type_id;
            }
            if(type == 'licence_number' && this.vehiclePolicyDataChecker.licence_number == false){
                this.vehicleForm.licence_number = this.originalVehicleAccount.licence_number;
            }
            if(type == 'vehicle_brand_name' && this.vehiclePolicyDataChecker.vehicle_brand_name == false){
                this.vehicleBrandList.forEach(element => {
                    if(element.code == this.originalVehicleAccount.vehicle_brand_id){
                        this.vehicleForm.vehicle_brand_id = element.id;
                    }
                });
            }
            if(type == 'vehicle_model_name' && this.vehiclePolicyDataChecker.vehicle_model_name == false){
                this.getVehicleModelList(true, false, true);
                this.vehicleForm.vehicle_model_id = this.originalVehicleAccount.vehicle_model_id;
            }
            if(type == 'vehicle_code' && this.vehiclePolicyDataChecker.vehicle_code == false){
                this.vehicleForm.vehicle_code = this.originalVehicleAccount.vehicle_code;
            }
            if(type == 'engine_number' && this.vehiclePolicyDataChecker.engine_number == false){
                this.vehicleForm.engine_number = this.originalVehicleAccount.engine_number;
            }
            if(type == 'chassis_number' && this.vehiclePolicyDataChecker.chassis_number == false){
                this.vehicleForm.chassis_number = this.originalVehicleAccount.chassis_number;
            }
            if(type == 'model_year' && this.vehiclePolicyDataChecker.model_year == false){
                this.vehicleForm.model_year = this.originalVehicleAccount.model_year;
            }
            if(type == 'color' && this.vehiclePolicyDataChecker.color == false){
                this.vehicleForm.color = this.originalVehicleAccount.color;
            }
            if(type == 'max_passenger' && this.vehiclePolicyDataChecker.max_passenger == false){
                this.vehicleForm.max_passenger = this.originalVehicleAccount.max_passenger;
            }
            if(type == 'empty_weight' && this.vehiclePolicyDataChecker.empty_weight == false){
                this.vehicleForm.empty_weight = this.originalVehicleAccount.empty_weight;
            }
            if(type == 'load_capacity' && this.vehiclePolicyDataChecker.load_capacity == false){
                this.vehicleForm.load_capacity = this.originalVehicleAccount.load_capacity;
            }
            if(type == 'engine_power' && this.vehiclePolicyDataChecker.engine_power == false){
                this.vehicleForm.engine_power = this.originalVehicleAccount.engine_power;
            }
            if(type == 'first_registration_date' && this.vehiclePolicyDataChecker.first_registration_date == false){
                this.vehicleForm.first_registration_date = this.originalVehicleAccount.first_registration_date;
            }
            if(type == 'last_registration_date' && this.vehiclePolicyDataChecker.last_registration_date == false){
                this.vehicleForm.last_registration_date = this.originalVehicleAccount.last_registration_date;
            }
            if(type == 'vehicle_price' && this.vehiclePolicyDataChecker.vehicle_price == false){
                this.vehicleForm.vehicle_price = this.originalVehicleAccount.vehicle_price;
            }
        },   
        resolveRegistrationStatus(str, char) {
            const regex = new RegExp(char, "g");
            const count = (str.match(regex) || []).length;
            return count;
        },
    }
}
</script>