<template>
    <div>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Tax Details</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" @click="onCloseModal()">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
            </div>
            <div class="modal-body pt-7"> 
                    <div class="card">
                        <div class="card-body">
                            <div class="row">  
                                <div class="col-lg-12">
                                    <table class="table table-row-dashed text-gray-800 mb-0">
                                        <tbody>
                                            <tr class="text-start">
                                                <td class="pt-2 pb-2 fw-bolder">TAX Amount</td>
                                                <td class="pt-2 pb-2 text-gray-600">{{ parseFloat(selectedTax.policy_tax_amount) }}</td>
                                            </tr>
                                            <tr class="text-start">
                                                <td class="pt-2 pb-2 fw-bolder">Sub Tax 1 Amount</td>
                                                <td class="pt-2 pb-2 text-gray-600">{{ parseFloat(selectedTax.policy_sub_tax_1_amount) }}</td>
                                            </tr>
                                            <tr class="text-start">
                                                <td class="pt-2 pb-2 fw-bolder">Sub Tax 2 Amount</td>
                                                <td class="pt-2 pb-2 text-gray-600">{{ parseFloat(selectedTax.policy_sub_tax_2_amount) }}</td>
                                            </tr>
                                            <tr class="text-start">
                                                <td class="pt-2 pb-2 fw-bolder">Sub Tax 3 Amount</td>
                                                <td class="pt-2 pb-2 text-gray-600">{{ parseFloat(selectedTax.policy_sub_tax_3_amount) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name:'TaxModal',
    props: {
        tax: Object
    },
    data(){
        return {
            selectedTax: {
                policy_tax_amount: 0,
                policy_sub_tax_1_amount: 0,
                policy_sub_tax_2_amount: 0,
                policy_sub_tax_3_amount: 0
            },
        }
    },
    methods:{
        getData(selectedinfo){
            this.selectedTax = this.tax;
            if(selectedinfo){
                this.selectedTax.policy_tax_amount = selectedinfo.policy_tax_amount;
                this.selectedTax.policy_sub_tax_1_amount = selectedinfo.policy_sub_tax_1_amount;
                this.selectedTax.policy_sub_tax_2_amount = selectedinfo.policy_sub_tax_2_amount;
                this.selectedTax.policy_sub_tax_3_amount = selectedinfo.policy_sub_tax_3_amount;
            }
        },
        onCloseModal(){
            this.$parent.isViewTaxModal = false;
        },
    },
    created() {
        this.getData();
    }
}
</script>