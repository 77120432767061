<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('General Policy Report') }}
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li>  
                <li class="breadcrumb-item text-primary">{{ $t('Policy Report') }}</li>    
                <li class="breadcrumb-item text-gray-600">{{ $t('General Policy Report') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="row fv-row border-0 pt-6 px-9">
                <div class="col-lg-9">
                    <div class="fv-row mb-4 row"> 
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Currency') }}</span>
                            </label>
                            <Select2 v-model="this.form.currency" :options="this.currencyList" :settings="{ width: '100%' }" />  
                        </div>
                        
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Date Type') }}</span>
                            </label>
                            <Select2 v-model="form.date_type" :options="dateTypeList" :settings="{width: '100%'}" @select="onChangeStartDate()" />
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" @update:modelValue="onChangeStartDate()"></Datepicker>  
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Insurance Type') }}</span>
                            </label>
                            <Select2 v-model="form.insurance_type" :options="insuranceTypeList" :settings="{width: '100%'}" />
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Main Branch') }}</span>
                            </label>
                            <Select2 v-model="form.main_branch_id" :options="mainBranchList" @select="getPolicyParentList()" :settings="{width: '100%'}" />
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Sub Branch') }}</span>
                            </label>
                            <Select2 v-model="form.policy_branch_id" :options="policyBranchList" :settings="{width: '100%'}" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end mt-5" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(76,'R')">
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper> 
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor"/>
                                <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor"/>
                                </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="isViewLocalAmounts" v-model="isViewLocalAmounts">
                        <label class="fs-5 fw-bold form-label mb-3" for="isViewLocalAmounts">
                            Is View Local Amounts
                        </label>
                    </div> 
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>                        
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="id" 
                            :data-source="lists" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true"
                            :ref="'dataGrid'"
                            @exporting="this.$onExporting($event , 'General-Policy-Report-List')"
                            :customize-columns="onCustomizeColumn"> 

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />

                            <DxColumn
                                data-field="dates"
                                cssClass="bg-gray-200"
                                alignment="center"
                                :caption="$t('Policy Dates')"
                                :fixed="false"
                                fixed-position="right">
                                <DxColumn cssClass="bg-gray-200" data-field="regulation_date" data-type="date" format="dd.MM.yyyy" alignment="right" :caption="$t('Regulation')" cell-template="regulation_date-cell-template"/> 
                                <DxColumn cssClass="bg-gray-200" data-field="start_date" data-type="date" format="dd.MM.yyyy" alignment="right" :caption="$t('Start')" cell-template="start_date-cell-template"/> 
                                <DxColumn cssClass="bg-gray-200" data-field="end_date" data-type="date" format="dd.MM.yyyy" alignment="right" :caption="$t('End')" cell-template="end_date-cell-template"/> 
                            </DxColumn>
                            <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/> 
                            <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/> 
                            <DxColumn data-field="is_new_or_renewal" :caption="$t('New/Renewal')" header-cell-template="is_new_or_renewal-header" cell-template="is_new_or_renewal-cell-template"/> 
                            <DxColumn
                                cssClass="bg-light-blue-grey"
                                alignment="center"
                                :caption="$t('Endorsement')"
                                :fixed="false"
                                fixed-position="right">
                                <DxColumn cssClass="bg-light-blue-grey" data-field="endorsement_type_name" :caption="$t('Type')" cell-template="endorsement_type_name-cell-template"/> 
                                <DxColumn cssClass="bg-light-blue-grey" data-field="endorsement_detail_name" :caption="$t('Detail')" cell-template="endorsement_detail_name-cell-template"/> 
                            </DxColumn>
                            <DxColumn
                                cssClass="bg-light-lime"
                                alignment="center"
                                :caption="$t('Producer')"
                                :fixed="false"
                                fixed-position="right">
                                <DxColumn cssClass="bg-light-lime" data-field="producer_name" :caption="$t('Name')" cell-template="producer_name-cell-template"/> 
                                <DxColumn cssClass="bg-light-lime" data-field="producer_policy_type_name" :caption="$t('Policy Type')" cell-template="producer_policy_type-cell-template"/> 
                            </DxColumn>
                            <DxColumn
                                alignment="center"
                                :caption="$t('Policy Currency')"
                                :fixed="false"
                                fixed-position="right">
                                <DxColumn data-field="currency" :caption="$t('Curr.')" cell-template="currency-cell-template"/> 
                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                            </DxColumn>
                            <DxColumn cssClass="bg-light-indigo" data-field="policy_total_amount" :sorting-method="this.$numericSort" :caption="$t('Policy Amount')" header-cell-template="policy_total_amount-header" alignment="right" cell-template="policy_total_amount-cell-template"/> 
                            <DxColumn
                                cssClass="bg-light-indigo"
                                alignment="center"
                                :caption="$t('Commissions')"
                                :fixed="false"
                                fixed-position="right">
                                <DxColumn cssClass="bg-light-indigo" data-field="insurance_commission" :sorting-method="this.$numericSort" :caption="$t('Policy')" alignment="right" cell-template="insurance_commission-cell-template"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="vendor_commission" :sorting-method="this.$numericSort" :caption="$t('Vendor')" alignment="right" cell-template="vendor_commission-cell-template"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="net_commission" :sorting-method="this.$numericSort" :caption="$t('Net')" alignment="right" cell-template="net_commission-cell-template"/> 
                            </DxColumn>
                            <DxColumn cssClass="bg-light-indigo" data-field="policy_tax_amount" :sorting-method="this.$numericSort" :caption="$t('Taxes')" alignment="right" cell-template="policy_tax_amount-cell-template"/>
                            <DxColumn cssClass="bg-light-indigo" data-field="policy_net_amount" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" alignment="right" cell-template="policy_net_amount-cell-template" header-cell-template="policy_net_amount-header"/>
                            <DxColumn :visible="isViewLocalAmounts" cssClass="bg-light-teal" :allow-grouping="false" data-field="policy_total_amount_local" :sorting-method="this.$numericSort" :caption="$t('Policy Amount')" header-cell-template="policy_total_amount_local-header" alignment="right" cell-template="policy_total_amount_local-cell-template"/> 
                            <DxColumn
                            :visible="isViewLocalAmounts"
                                data-field="commissions_local"
                                cssClass="bg-light-teal"
                                alignment="center"
                                :caption="$t('Commissions')"
                                :fixed="false"
                                fixed-position="right"
                                header-cell-template="commissions-header">
                                <DxColumn cssClass="bg-light-teal" data-field="insurance_commission_local" :allow-grouping="false" :sorting-method="this.$numericSort" :caption="$t('Policy')" alignment="right" cell-template="insurance_commission_local-cell-template"/> 
                                <DxColumn cssClass="bg-light-teal" data-field="vendor_commission_local" :allow-grouping="false" :sorting-method="this.$numericSort" :caption="$t('Vendor')" alignment="right" cell-template="vendor_commission_local-cell-template"/> 
                                <DxColumn cssClass="bg-light-teal" data-field="net_commission_local" :allow-grouping="false" :sorting-method="this.$numericSort" :caption="$t('Net')" alignment="right" cell-template="net_commission_local-cell-template"/> 
                            </DxColumn>
                            <DxColumn :visible="isViewLocalAmounts" cssClass="bg-light-teal" :allow-grouping="false" data-field="policy_tax_amount_local" :sorting-method="this.$numericSort" :caption="$t('Taxes')" alignment="right" cell-template="policy_tax_amount_local-cell-template" header-cell-template="policy_tax_amount_local-header"/>
                            <DxColumn :visible="isViewLocalAmounts" cssClass="bg-light-teal" :allow-grouping="false" data-field="policy_net_amount_local" :sorting-method="this.$numericSort" :caption="$t('Net Amount')" alignment="right" cell-template="policy_net_amount_local-cell-template" header-cell-template="policy_net_amount_local-header"/>
                            <DxColumn data-field="payment_amount" :sorting-method="this.$numericSort" :caption="$t('Payment to Producer')" alignment="right" cell-template="payment_amount-cell-template" header-cell-template="payment_amount-header"/>
                            <DxColumn data-field="factors" :caption="$t('Factors')" cell-template="factors-cell-template"/>

                            <template #headerLocalTemplate="{ data: data }">
                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                            </template>

                            <template #policy_type_name-header>
                                <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                            </template> 
                            <template #is_new_or_renewal-header>
                                <div>{{ $t('New/') }}</div><div>{{ $t('Renewal') }}</div>
                            </template>
                            <template #policy_no-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #policy_total_amount-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('Amount') }}</div>
                            </template>
                            <template #policy_total_amount_local-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('Total') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #policy_tax_amount_local-header>
                                <div>{{ $t('Taxes') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #policy_net_amount_local-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('Net') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #commissions-header>
                                <div>{{ $t('Commissions') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #policy_net_amount-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('Net') }}</div>
                            </template>
                            <template #payment_amount-header>
                                <div>{{ $t('Payment') }}</div><div>{{ $t('To Producer') }}</div>
                            </template> 
                                                
                            <template #policy_type_name-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.policy_type_name}}
                                </span>
                            </template>
                            <template #is_new_or_renewal-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.is_new_or_renewal}}
                                </span>
                            </template>
                            <template #endorsement_type_name-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.endorsement_type_name}}
                                </span>   
                            </template>
                            <template #endorsement_detail_name-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.endorsement_detail_name}}
                                </span>   
                            </template>
                            <template #producer_name-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.producer_name}}
                                </span>   
                            </template>
                            <template #producer_policy_type-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-start">
                                    {{data.data.producer_policy_type_name}}
                                </span>   
                            </template>
                            <template #regulation_date-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{previewDate(data.data.regulation_date)}}
                                </span>   
                            </template>
                            <template #start_date-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{previewDate(data.data.start_date) }}
                                </span>   
                            </template>
                            <template #end_date-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{previewDate(data.data.end_date)}}
                                </span>   
                            </template>
                            <template #policy_no-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_no}}-{{data.data.renewal_no}}-{{data.data.endorsement_no}}
                                </span>   
                            </template>
                            <template #currency-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.currency }}
                                </span>   
                            </template>
                            <template #policy_total_amount-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_total_amount }}
                                </span>   
                            </template>
                            <template #rate-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.rate }}
                                </span>   
                            </template>
                            <template #policy_tax_amount-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_tax_amount}}
                                </span>   
                            </template>
                            <template #policy_total_amount_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_total_amount_local }}
                                </span>   
                            </template>
                            <template #policy_tax_amount_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_tax_amount_local}}
                                </span>   
                            </template>
                            <template #insurance_commission-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.insurance_commission}}
                                </span>   
                            </template>
                            <template #vendor_commission-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.vendor_commission}}
                                </span>   
                            </template>
                            <template #net_commission-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.net_commission}}
                                </span>   
                            </template>
                            <template #insurance_commission_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.insurance_commission_local}}
                                </span>   
                            </template>
                            <template #vendor_commission_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.vendor_commission_local}}
                                </span>   
                            </template>
                            <template #net_commission_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.net_commission_local}}
                                </span>   
                            </template>
                            <template #policy_net_amount-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_net_amount}}
                                </span>   
                            </template>
                            <template #policy_net_amount_local-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.policy_net_amount_local}}
                                </span>   
                            </template>
                            <template #payment_amount-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.payment_amount}}
                                </span>   
                            </template>
                            <template #factors-cell-template="{ data }">
                                <span class="align-middle text-gray-800 text-end">
                                    {{data.data.factors}}
                                </span>   
                            </template>

                            <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                            
                            <DxSummary :calculate-custom-summary="setSummaries">
                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="dates"/>
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="policy_total_amount"
                                    summary-type="custom"
                                    show-in-column="policy_total_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="policy_tax_amount"
                                    summary-type="custom"
                                    show-in-column="policy_tax_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="policy_net_amount"
                                    summary-type="custom"
                                    show-in-column="policy_net_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="insurance_commission"
                                    summary-type="custom"
                                    show-in-column="insurance_commission"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="vendor_commission"
                                    summary-type="custom"
                                    show-in-column="vendor_commission"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="net_commission"
                                    summary-type="custom"
                                    show-in-column="net_commission"
                                    display-format="{0}"
                                />
                                <DxTotalItem v-if="form.currency !== '-1'"
                                    name="payment_amount"
                                    summary-type="custom"
                                    show-in-column="payment_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="policy_total_amount_local"
                                    summary-type="custom"
                                    show-in-column="policy_total_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="policy_tax_amount_local"
                                    summary-type="custom"
                                    show-in-column="policy_tax_amount_local"
                                    display-format="{0}"
                                />
                                
                                <DxTotalItem
                                    name="policy_net_amount_local"
                                    summary-type="custom"
                                    show-in-column="policy_net_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="insurance_commission_local"
                                    summary-type="custom"
                                    show-in-column="insurance_commission_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="vendor_commission_local"
                                    summary-type="custom"
                                    show-in-column="vendor_commission_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="net_commission_local"
                                    summary-type="custom"
                                    show-in-column="net_commission_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="payment_amount_local"
                                    summary-type="custom"
                                    show-in-column="payment_amount_local"
                                    display-format="{0}"
                                />
                            </DxSummary>
                        </DxDataGrid>     
                    </div>
                </div> 
            </div>
        </div> 
    </div>
  </div>  
</template>

<style>
    .dx-datagrid-total-footer .dx-footer-row > td, .dx-datagrid-rowsview .dx-footer-row > tr > td {
        background-color: #eff2f5 !important;
    }
    [data-bs-theme=dark] .dx-datagrid-total-footer .dx-footer-row > td, .dx-datagrid-rowsview .dx-footer-row > tr > td {
        background-color: #2a2a2a !important;
    }
</style>

<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxScrolling, DxStateStoring } from 'devextreme-vue/data-grid';
 
export default {
  name: "GeneralPolicyReport",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxScrolling, DxStateStoring
  }, 
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 100,
        pageSizeDetailList: 10,
        page: 1, 
        page_size: 20,
        lists: [],
        form:  {
            currency: '-1',
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            insurance_type: 0,
            date_type: 1,
            main_branch_id: '-1',
            policy_branch_id: '-1',
        },
        isListProgressing: false,
        detailList: [],
        currencyList: [],
        summary: {},
        usedCriterias: {},
        localCurrency: this.$getUser().currency_code,
        menuSelection: '/GeneralPolicyReportHelppage',
        iconView : false,
        insuranceTypeList: [
          { id: 0, text: 'ALL'},
          { id: 1, text: 'Insurance'},
          { id: 2, text: 'Reinsurance'},
          { id: 3, text: 'Coinsurance'}
        ],
        dateTypeList: [
            { id: 1, text: 'Regulation'},
            { id: 2, text: 'Start/End'},
        ],
        mainBranchList: [{ id: '-1', text: 'ALL'}],
        policyBranchList: [{ id: '-1', text: 'ALL'}],
        isViewLocalAmounts: false
    };
  },
  computed: {
   setColumnTemplate(){
        return (value)=>{
            return this.form.currency == -1 || this.form.currency == null || this.form.currency == '' ? value.column.caption : this.form.currency + '<br>' + value.column.caption;             
        }
   },
   setColumnTemplateForLocal(){
    return (value)=>{
                const captionValue = value.column.caption.replace(' Local', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
   },
   previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    },
    showPageSizeSelectorDetailList() {
        return this.detailList.length > this.pageSizeDetailList;
    },
    pageSizesDetailList() {
        let arr = [];
        let x = 1;
        let loop = (this.detailList.length / this.pageSizeDetailList);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSizeDetailList * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getLists() {        
        const parameters = {
            session_id: this.$getUser().session_id,
            list_type: 1, /* Main Branch */
            list_name: ["currency"]
        };
        const requestOne =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.mainBranchList= responses[0].data;
            this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
            this.currencyList = responses[1].data.currency;
            this.currencyList.unshift({ id: '-1', text: 'ALL'});
            this.form.currency = '-1'; 
            this.localCurrency = this.$getUser().currency_code;
            this.getData();
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getPolicyParentList() {
        this.form.policy_branch_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 2, // Policy Branch: 2 
            parent_id: this.form.main_branch_id
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyBranchList = response.data;
            this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getData() {
        this.$Progress.start();
        this.usedCriterias = {};
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = ( this.form.end_date == null || this.form.end_date == '') ? moment () : this.form.end_date; 
        
        const params = {
            session_id: this.$getUser().session_id,
            currency: this.form.currency,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            insurance_type: this.form.insurance_type,
            date_type: this.form.date_type,
            main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id,
        };
        if(params.insurance_type == 0) { params.insurance_type = null }
        params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        params.currency = this.form.currency == '-1' ? null : this.form.currency;
        axios.post('/api/PolicyReports/Transactions', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;  
            // this.summary = response.data.summary;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    setCriteriasToDefault() {   
        this.form = {
            currency: '-1',
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            insurance_type: 0,
            date_type: 1,
            main_branch_id: '-1',
            policy_branch_id: '-1',
        };
        this.lists = [];
    },
    viewDetail(id){
        this.detailList = [];
        this.$Progress.start(); 
        const queryParameter = {
            session_id: this.$getUser().session_id,
            stock_transaction_id: id
        };   
        axios.post('/api/Transaction/GetAllStockTransactionDetail', queryParameter, {'Content-Type': 'application/json'}) 
            .then((response) => {  
                this.detailList = response.data;
                this.$Progress.finish();
            })
            .catch(function () {
                this.$Progress.finish();
            });
    },
    setSummaries(options){        
        const optionNames = ["policy_net_amount", "policy_total_amount", "policy_tax_amount", "vendor_commission", "insurance_commission", "net_commission", "payment_amount", "policy_net_amount_local", "policy_total_amount_local", "policy_tax_amount_local", "vendor_commission_local", "insurance_commission_local", "net_commission_local", "payment_amount_local"];
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {               
                    if(column.dataField == 'policy_total_amount_local'){ 
                        column.caption =  'Policy Total Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'policy_tax_amount_local'){ 
                        column.caption =  'Taxes Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'commissions_local'){ 
                        column.caption =  'Commissions Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'policy_net_amount_local'){ 
                        column.caption =  'Net Local';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        }
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/GeneralPolicyReportHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onChangeStartDate() {
        if(this.form.date_type == 2) {
            this.form.end_date = moment(this.form.start_date).add(1, 'year');
        } else {
            this.form.end_date = moment();
        }
    }
  },
  created() {
    this.helpPageViewIcon();
    this.lists = [];
    this.getLists();
  }
}; 
</script>