/* eslint-disable */
<template>
  <div>
    <!-- <label class="form-label notranslate">Language</label> -->
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle btn-sm notranslate" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" :disabled="this.isTranslateSystemLoading == true">
        <div class="spinner-border" role="status" v-if="this.isTranslateSystemLoading == true">
          <span class="sr-only">Translate working...</span>
        </div>
        {{ this.selectedLanguage }} 
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-for="country in countryList" class="py-2 cursor-pointer notranslate" :key="country.title">
          <a class="dropdown-item" href="#" @click="doTranslation(country.code)" :title="country.title">
            <img width="32" height="32" 
                :alt="country.altText ? country.altText : `${country.title} flag icon`"
                :src="
                  checkIfCountryIsAvailableInDefaults(country)
                    ? `https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate/src/assets/images/flags/__${country.title}.png`
                    : country.flagIconUrl
                "
                class="flag"
              />
              {{ country.title }}
          </a>
        </li>
      </ul>
    </div> 
    <div class="fv-row mb-10 fv-plugins-icon-container text-start" style="display: none;">
        <label class="form-label fs-6 fw-bolder text-dark text-start">
            Language
        </label>
        <div id="google_translate_element2"></div>
        <div class="separator my-6"></div>
    </div>

  </div>
</template>

<script> 
import axios from "axios"; 
export default {
  name: "TranslateComponent",
  data() {
    return {
      selectedLanguage: 'Select a language',
      isSelectedLanguageUseAPI: 0,
      selectedLanguageCode: 'en|en',
      isTranslateSystemLoading: false,
      countryList: [
        {
          code: 'en|en',
          title: 'English',
        },
        {
          code: 'en|tr',
          title: 'Turkish',
        }
      ]
    }
  },
  computed: {
    hasClickListener() {
      return Object.keys(this.$attrs).includes('on-country-click');
    },
    currentRouteName() {
      if (this.$route.path === "/") {
        return `${this.$route.path}`;
      } else {
        return `${this.$route.path}/`;
      }
    }
  },
  methods: {
    getLanguageList(){
      this.isTranslateSystemLoading = true;
       axios.post('/api/Auth/GetLocalization', {}, {'Content-Type': 'application/json'})
          .then((response) => {
              if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                if(response.data.length > 0){
                  this.countryList = response.data;
                    setTimeout(() => {
                      this.isTranslateSystemLoading = false;
                    }, 3000);
                } 
              } 
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });
    },
    doTranslation(code) { 
      this.isTranslateSystemLoading = true;
      this.$cookies.remove('googtrans');
      
      setTimeout(() => {
        // Local Storage values set to default
        localStorage.setItem('i18nextLngActive', 'false');
        localStorage.setItem('i18nextLng', 'en-GB');

        try{
          this.selectedLanguage = this.countryList.find(x => x.code == code).title;
          this.isSelectedLanguageUseAPI = this.countryList.find(x => x.code == code).use_api;
          this.selectedLanguageCode = this.countryList.find(x => x.code == code).code;
        }
        catch(Err){
          console.log(Err);
          this.selectedLanguage = 'Select a language';
        }

        // API
        if(this.isSelectedLanguageUseAPI == 1){
          // Local Language settings set to default
          this.$i18next.changeLanguage(this.selectedLanguageCode);

          try{
            // Translate API proceeds
            window.doGTranslate(code);
            if (this.hasClickListener) {
              this.$emit('on-country-click');
            }
            if(code == 'en|en'){
              this.$cookies.set('googtrans','/en/en');
              this.$cookies.remove('googtrans');
            }
          }
          catch(Err){
            console.log(Err);  
            // Translate API proceeds
            window.doGTranslate('en|en');
            if (this.hasClickListener) {
              this.$emit('on-country-click');
            }
          }
          this.isTranslateSystemLoading = false;
        }
        else{
          try{
            axios.post('/api/Auth/GetLocalizationData', { language_code: this.selectedLanguageCode }, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                  this.$i18next.init({ resources: {} });
                  this.$i18next.addResourceBundle(this.selectedLanguageCode, 'translation', response.data );
                  this.$i18next.reloadResources();
                  this.$i18next.changeLanguage(this.selectedLanguageCode);
                  localStorage.setItem('i18nextLngActive', 'true');
                  this.$cookies.remove('googtrans');
                  this.isTranslateSystemLoading = false;
                  return;
                } 
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
                this.isTranslateSystemLoading = false;
            });
          }
          catch(Err){
            console.log(Err); 
            this.isTranslateSystemLoading = false;
          }
        }
      }, 2000);
      setTimeout(() => {
        // Google Translate API'si çalışamadığında dili otomatik olarak ilk dil olan Afrikancaya setliyor. Bu durum oluştuğunda GOOGTRANS Cookiesi silinir. 
        if(code == 'en|en' && this.$cookies.get('googtrans') == '/en/af'){
          this.$cookies.remove('googtrans');
          window.location.reload();
        }
      }, 3000);
    },
    checkIfCountryIsAvailableInDefaults(countryObject) {
      return this.countryList.some(
        (country) =>
          country.code.toLowerCase() === countryObject.code.toLowerCase() &&
          country.title.toLowerCase() === countryObject.title.toLowerCase()
      );
    }
  },
  mounted(){ 
    this.getLanguageList();
  }
}
</script>
<style scoped>
a:link {
  text-decoration: none;
  font-size: large;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
.goog-te-gadget .goog-te-combo {
    margin: 0px 0px;
     display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.goog-logo-link {
  display:none !important;
} 
.goog-te-gadget{
  color: transparent !important;
  font-size:0px;
}
.goog-te-combo { 
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #687074;
  text-transform: uppercase;
  cursor:pointer;
  
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
 .skiptranslate {
    display: none !important;
  }
</style>
