<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
                    {{ $t('Update Cheque/Card Operation') }}
                </h1> 
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Transactions') }}</li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Cheque/Card Operation') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1" v-if="getSubCredentials('R')">
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" :disabled="!this.$getUser().is_allow_list_all_data && (this.form.operation_account_id == null || this.form.operation_account_id == undefined || this.form.operation_account_id == '-1')" >
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{ $t('Transaction List') }}
                </button>
            </div>
        </div>  
        <div class="content flex-row-fluid mt-2" id="kt_content">  
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-4">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row g-xxl-9">
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h3>{{ $t('Cheque/Card Operation') }}</h3>
                                        </div>
                                        <div class="d-flex justify-content-end py-4 -">
                                            &nbsp;
                                            <router-link to="/ChequeOperationsCreate" v-if="getSubCredentials('I')">
                                                <button type="button" class="btn btn-light-primary border border-primary me-3">
                                                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                    </span>
                                                    {{ $t('Start New Cheque/Card Operation') }}
                                                </button>
                                            </router-link> 
                                            <div class="dropdown">
                                                <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="ChequeActions" type="button">
                                                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                                                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                                                </svg></span>
                                                    {{ $t('Actions') }}
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="ChequeActions"> 
                                                    <li v-if="form.is_accounted == 1 && this.$getUser().is_allow_cancel_accounting_integration"><a class="dropdown-item fs-4 text-danger" href="#" @click="onCancelIntegration()">{{ $t('Cancel Accounting Integration') }}</a></li>
                                                    <li v-if="getSubCredentials('D')"><a class="dropdown-item fs-4 text-danger" href="#" @click="deleteChequeHeader()">{{ $t('Delete') }} </a></li>
                                                </ul> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="fv-row mb-7 row">
                                            <div class="col">
                                                <label class="fs-6 fw-bold form-label" v-tag="'record_date'">
                                                    {{ $t('Record Date') }}
                                                </label>
                                                <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" format="dd.MM.yyyy" 
                                                    previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" name="record_date" v-custom :required="this.$checkIfDateFieldRequired ('record_date')" />
                                            </div>
                                            <div class="col">
                                                <label class="fs-6 fw-bold form-label" v-tag="'record_type'">
                                                    {{ $t('Record Type') }}
                                                </label>
                                                <Select2 name="record_type" v-custom v-model="form.record_type" :options="recordTypeList" :settings="{ width: '100%' }" disabled />
                                            </div>
                                            <div class="col">
                                                <label class="fs-6 fw-bold form-label" v-tag="'cheque_type'">
                                                    {{ $t('Cheque Type') }}
                                                </label>
                                                <Select2 v-model="form.transaction_type" :options="transactionTypeListWithNoSelection" disabled :settings="{ width: '100%' }" name="cheque_type" v-custom />
                                            </div>
                                            <div class="col">
                                                <label class="fs-6 fw-bold form-label" v-tag="'operation_position'">
                                                    {{ $t('Operation Position') }}
                                                </label>
                                                <Select2 v-model="form.cheque_position" :options="targetPositionList" disabled :settings="{ width: '100%' }" name="operation_position" v-custom />
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label class="fs-6 fw-bold form-label" >
                                                    {{ $t('Select Operation Account') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="search" class="form-control form-control-lg" v-model="form.operation_account_name" disabled>
                                                    <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()"
                                                        v-if="form.operation_account_id !== null && form.operation_account_id !== '-1' && form.operation_account_id !== '' && form.operation_account_id !== undefined">
                                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-0">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <div class="p-0">
                                            <div class="d-flex flex-stack flex-wrap mb-2">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-success fw-bold required me-3"
                                                            data-bs-toggle="modal" 
                                                            ref="btnAddItem" :disabled="this.form.cheque_position == '-1' || this.form.operation_account_id == '-1'"
                                                            data-bs-target="#kt_modal_add_item"
                                                            @click="openModal()"
                                                            data-kt-menu-trigger="click" 
                                                            data-kt-menu-placement="bottom-end">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                        transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                        fill="currentColor">
                                                                    </rect>
                                                                </svg>
                                                            </span> 
                                                            {{ $t('Add New') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                        @click="deleteChequeItem(0)"
                                                        :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger" @click="deleteChequeItem(1)">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button> 
                                                </div>
                                                <div class="flex-row">
                                                    <div class="d-flex justify-content-end">
                                                        <span class="fw-bold text-gray-600 me-5">{{ $t('Docket Number') }}: </span>
                                                        <span class="fw-boldest text-black-600"> {{this.form.docket_number}} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pb-10">
                                                <DxDataGrid id="gridContainer" 
                                                        :show-borders="true" 
                                                        key-expr="id" 
                                                        :data-source="chequeItemsList" 
                                                        :allow-column-reordering="true"  
                                                        :allow-column-resizing="true" 
                                                        :column-auto-width="true" 
                                                        :row-alternatin-enabled="true"
                                                        :hover-state-enabled="true" 
                                                        :show-row-lines="true" 
                                                        :customize-columns="onCustomizeColumn"
                                                        @exporting="this.$onExporting($event , 'Cheque-transaction-items')"
                                                        @option-changed="handlePropertyChange"> 

                                                        <DxFilterRow :visible="true"/>
                                                        <DxHeaderFilter :visible="true"/>
                                                        <DxGroupPanel :visible="true"/>      
                                                        <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                        <DxPaging :page-size="10"/>
                                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                        <DxSearchPanel :visible="true"/> 
                                                        <DxScrolling
                                                            mode="virtual"
                                                            row-rendering-mode="virtual"
                                                        />
                                                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                        <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/>
                                                        <DxColumn data-field="due_date" :caption="$t('Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="due_date-header" cell-template="due_date-cell-template"/>
                                                        <DxColumn data-field="item_type_name" :caption="$t('Type')" cell-template="item_type_name-cell-template"/>   
                                                        <DxColumn data-field="bank_account_name" :caption="$t('Bank Account')" header-cell-template="bank-account-header" cell-template="cash_bank_account_name-cell-template"/>   
                                                        <DxColumn data-field="transaction_number" :caption="$t('Transaction/Cheque No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>
                                                        <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>  
                                                        <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                        <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                        <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" header-cell-template="local-amount-header" cell-template="amount_local-cell-template"/> 
                                                        <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="transaction_date-header"  cell-template="transaction_date-cell-template"/>

                                                        <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                        <template #headerLocalTemplate="{ data: data }">
                                                            <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                        </template>                                                        
                                                        <template #bank-account-header>
                                                            <div>{{ $t('Bank') }}</div><div>{{ $t('Account') }}</div>
                                                        </template>
                                                        <template #payment-account-header>
                                                            <div>{{ $t('Payment') }}</div><div>{{ $t('Account') }}</div>
                                                        </template>
                                                        <template #transaction-no-header>
                                                            <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                        </template>
                                                        <template #local-amount-header>
                                                            <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                                        </template>
                                                        <template #due_date-header>
                                                            <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                                        </template>
                                                        <template #transaction_date-header>
                                                            <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                                        </template>

                                                        <template #is-selected-cell-template="{ data }">
                                                            <span>
                                                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                                                </div>
                                                            </span>
                                                        </template>
                                                        <template #id-cell-template="{ data }">
                                                            <a href="#" class="text-gray-800 text-hover-primary">
                                                                {{ (this.pageSize * this.pageIndex) + data.rowIndex + 1 }}
                                                            </a>
                                                        </template>
                                                        <template #due_date-cell-template="{ data }">
                                                            <span class="text-gray-700 p-3 align-middle text-end"> {{ previewDate(data.data.due_date) }}</span>
                                                        </template>
                                                        <template #transaction_date-cell-template="{ data }">
                                                            <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                        </template>
                                                        <template #item_type_name-cell-template="{ data }">
                                                            <span class="fw-bold p-3 align-middle" :class="{ 'text-primary': data.data.item_type == 1 , 'text-danger': data.data.item_type == 2}"> {{ data.data.item_type_name }}</span>
                                                        </template>
                                                        <template #cash_bank_account_name-cell-template="{ data }">
                                                            <span class="text-gray-700 p-3 align-middle" > {{ data.data.bank_account_name }}</span>
                                                        </template>
                                                        <template #payment_account_name-cell-template="{ data }">
                                                            <span class="text-gray-700 p-3 align-middle" >{{ data.data.payment_account_name }}</span>
                                                        </template>
                                                        <template #transaction_number-cell-template="{ data }">
                                                            <span class="text-gray-600 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                                                        </template>
                                                        <template #amount-cell-template="{ data }">
                                                            <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                        </template>
                                                        <template #currency-cell-template="{ data }">
                                                            <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                                                        </template>
                                                        <template #rate-cell-template="{ data }">
                                                            <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                        </template>
                                                        <template #amount_local-cell-template="{ data }">
                                                            <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                        </template>
                                                        <DxSummary :calculate-custom-summary="setSummaries">
                                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                            <DxGroupItem
                                                                :show-in-group-footer="true"
                                                                name="amount_local"
                                                                summary-type="custom"
                                                                show-in-column="amount_local"
                                                                display-format="{0}"
                                                            /> 
                                                            <DxTotalItem name="amount_local"
                                                                summary-type="custom"
                                                                show-in-column="amount_local"
                                                            />
                                                        </DxSummary>
                                                </DxDataGrid>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="getSubCredentials('U')">
                                <div class="row g-xxl-9">
                                    <div class="col-lg-12">
                                        <div class="d-grid gap-2"> 
                                            <button class="btn btn-primary btn-lg mb-0" type="submit" @click="updateChequeHeader()" data-kt-indicator="on" :disabled="chequeItemsList.length == 0"> 
                                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                </svg>
                                                </span>
                                                <strong v-if="!this.isDocketSaving"> {{ $t('Update Transaction') }} </strong> 
                                                <span class="indicator-progress" v-if="this.isDocketSaving">
                                                    {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- ADD TRANSACTION MODAL -->
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xxl-90">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">                        
                        <div class="modal-title col-md-11">
                            <span class="fs-3 fw-bolder me-3 text-white"> {{ $t('Add Cheque/Card') }} </span>
                            <!-- <i class="bi bi-person-fill mt-2"> </i>  <span> {{getAccountName(this.form.operation_account_id)}} </span> -->
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isChequeOperationItemSaving == true}">
                        <form @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                            <div class="row">
                                <div class="col-lg-12">                                    
                                    <div class="card card-xxl-stretch mb-6">
                                        <div class="row p-9 pb-4">
                                            <div class="col-lg-9">
                                                <div class="fv-row mb-4 row">
                                                    <div class="col-lg">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'start_date'" >
                                                            <span>{{ $t('Start Due Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="chequeForm.start_date" 
                                                            inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" name="start_date" v-custom ></Datepicker>  
                                                    </div>
                                                    <div class="col-lg">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'end_date'" >
                                                            <span>{{ $t('End Due Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="chequeForm.end_date"
                                                            inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" name="end_date" v-custom ></Datepicker>
                                                    </div>
                                                    <div class="col-lg">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'position'" >
                                                            {{ $t('Cheque Position') }}
                                                        </label>                                                            
                                                        <Select2 v-model="chequeForm.position" :options="lastPositionList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="position" v-custom />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                                    <button type="button" class="btn btn-light-primary border border-primary" @click="getChequeLists()" data-kt-indicator="on">
                                                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        <strong v-if="!this.isListProgressing"> {{ $t('List Cheques/Cards') }}</strong> 
                                                        <span class="indicator-progress" v-if="this.isListProgressing">
                                                            {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span> 
                                                    </button>
                                                </div>
                                            </div> 
                                            <div class="col-lg-12">
                                                <DxDataGrid id="gridContainer"
                                                    :show-borders="true" 
                                                    key-expr="id" 
                                                    :data-source="chequeOperationItemsList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true"
                                                    :customize-columns="onCustomizeColumn"
                                                    @exporting="this.$onExporting($event , 'Cheque-transaction-items')"
                                                    :ref="'detailDataGrid'"
                                                    @row-prepared="onRowPrepared"
                                                    @option-changed="handlePropertyChanges" > 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizesLengt" :show-page-size-selector="showPageSizeSelect" :show-info="true" :show-navigation-buttons="true"/> 
                                                    <DxSearchPanel :visible="true"/> 
                                                    
                                                    <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" header-cell-template="is-selected-header" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                    <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-templates" :allow-exporting="false"/>
                                                    <DxColumn data-field="due_date" :caption="$t('Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="due_date-header" cell-template="due_date-cell-template"/>
                                                    <DxColumn data-field="last_position_name" :caption="$t('Last Position')" header-cell-template="last_position_name-header" cell-template="position-cell-template"/>   
                                                    <DxColumn data-field="last_position_account_name" :caption="$t('Last Position Account Name')" header-cell-template="last_position_account_name-header" cell-template="last_position_account_name-cell-template"/>   
                                                    <DxColumn data-field="item_type_name" :caption="$t('Type')" cell-template="item_type_name-cell-template"/>   
                                                    <DxColumn data-field="docket_number" :caption="$t('Docket No')" header-cell-template="docket-no-header" cell-template="docket_number-cell-template"/>
                                                    <DxColumn data-field="payment_account_name" :caption="$t('Account')" cell-template="payment_account_name-cell-template"/>   
                                                    <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>  
                                                    <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                    <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                    <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" header-cell-template="local-amount-header" cell-template="amount_local-cell-template"/> 
                                                    <DxColumn data-field="bank_account_name" :caption="$t('Bank Account')" header-cell-template="bank-account-header" cell-template="cash_bank_account_name-cell-template"/>
                                                    <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>
                                                    <DxColumn data-field="cheque_number" :caption="$t('Cheque No')" header-cell-template="cheque-no-header" cell-template="cheque_number-cell-template"/>
                                                    <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>

                                                    <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 

                                                    <template #is-selected-header>                                
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" v-model="form.isSelectedAll" @change="onSelectAll()"> 
                                                    </div>
                                                    </template>
                                                    <template #headerLocalTemplate="{ data: data }">
                                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                    </template>

                                                    <template #bank-account-header>
                                                        <div>{{ $t('Bank') }}</div><div>{{ $t('Account') }}</div>
                                                    </template>
                                                    <template #transaction-no-header>
                                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                    </template>
                                                    <template #cheque-no-header>
                                                        <div>{{ $t('Cheque') }}</div><div>{{ $t('No') }}</div>
                                                    </template>
                                                    <template #card-type-header>
                                                        <div>{{ $t('Card') }}</div><div>{{ $t('Type') }}</div>
                                                    </template>
                                                    <template #docket-no-header>
                                                        <div>{{ $t('Docket') }}</div><div>{{ $t('No') }}</div>
                                                    </template>
                                                    <template #local-amount-header>
                                                        <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                                    </template>
                                                    <template #due_date-header>
                                                        <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                                    </template>
                                                    <template #last_position_name-header>
                                                        <div>{{ $t('Last') }}</div><div>{{ $t('Position') }}</div>
                                                    </template>
                                                    <template #last_position_account_name-header>
                                                        <div>{{ $t('Last Position') }}</div><div> {{ $t('Account Name') }}</div>
                                                    </template>

                                                    <template #is-selected-cell-template="{ data }">
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="!data.data.is_used && data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" :disabled="data.data.is_used" />
                                                            </div>
                                                            <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_used">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="updateItem(data.data.id)">
                                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </template>
                                                    <template #id-cell-templates="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary">
                                                            {{ (this.pageSizeNo * this.pageIndexNo) + data.rowIndex + 1 }}
                                                        </a>
                                                    </template>
                                                    <template #transaction_date-cell-template="{ data }">
                                                            <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                    </template>
                                                    <template #docket_number-cell-template="{ data }">
                                                        <span class="text-gray-600 p-3 align-middle">{{ data.data.docket_number }}</span>
                                                    </template>
                                                    <template #position-cell-template="{ data }">
                                                        <span class="text-gray-600 p-3 align-middle">{{ data.data.last_position_name }}</span>
                                                    </template>
                                                    <template #last_position_account_name-cell-template="{ data }">
                                                        <span class="text-gray-600 p-3 align-middle">{{ data.data.last_position_account_name }}</span>
                                                    </template>
                                                    <template #item_type_name-cell-template="{ data }">
                                                        <span class="fw-bold p-3 align-middle" :class="{ 'text-primary': data.data.item_type == 1 , 'text-danger': data.data.item_type == 2}"> {{ data.data.item_type_name }}</span>
                                                    </template>
                                                    <template #cash_bank_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle"> {{ data.data.bank_account_name }}</span>
                                                    </template>
                                                    <template #payment_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle">{{ data.data.payment_account_name }}</span>
                                                    </template>
                                                    <template #due_date-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle text-end"> {{ previewDate(data.data.due_date) }}</span>
                                                    </template>
                                                    <template #transaction_number-cell-template="{ data }">
                                                        <span class="text-gray-600 p-3 align-middle">{{ data.data.transaction_number }}</span>
                                                    </template>
                                                    <template #cheque_number-cell-template="{ data }">
                                                        <span class="text-gray-600 p-3 align-middle">{{ data.data.cheque_number }}</span>
                                                    </template>
                                                    <template #amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                    </template>
                                                    <template #currency-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                                                    </template>
                                                    <template #rate-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                    </template>
                                                    <template #amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                    </template>

                                                    <DxSummary>
                                                        <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                    </DxSummary>
                                                </DxDataGrid> 
                                            </div>
                                            <div class="col-lg-12 mt-10">
                                                <div class="card">
                                                    <div class="d-grid gap-2"> 
                                                        <button type="button" class="btn btn-success btn-lg" @click="this.addChequeOperationItem()"
                                                            :disabled="isModalItemButtonDisabled == null || isModalItemButtonDisabled == undefined">
                                                            <strong>{{ $t('Add To List') }}</strong> 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isChequeOperationItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <!-- Transaction List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="7" :account-id="form.operation_account_id" :account-name="form.operation_account_name" />
        </div>

        <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
           <AccountReports ref="AccountReports" :account-name="form.operation_account_name" :account-id="form.operation_account_id" :account-type="form.operation_account_type" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxTotalItem, DxSummary, DxGroupItem, DxScrolling } from 'devextreme-vue/data-grid';
import TransactionListModal from '../TransactionListModal.vue';
 
export default {
  name: "ChequeOperationsUpdate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxTotalItem, DxSummary, DxGroupItem, TransactionListModal , DxScrolling
  },
  data() {
    return {
        pageSize: 10,
        pageIndex: 0,
        pageSizeNo: 10,
        pageIndexNo: 0,
        isOpenActionsDropdown: false,
        isOpenDescriptionDropdown: false,
        searchbar: '',
        chequeOperationItemsList: [],
        chequeItemsList: [],
        recordTypeList: [
            { id: 1, text: 'Cheque'},
            { id: 2, text: 'Card'},
        ],
        transactionTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Incoming Cheque', type: 1},
            { id: 2, text: 'Outgoing Cheque', type: 1},
            { id: 1, text: 'Incoming Card', type: 2},
            { id: 2, text: 'Outgoing Card', type: 2},
        ],
        accountTypeList: [
            { id: '-1', text: 'Select an option'},
            { id: 12, text: 'Customer & Supplier'},
            { id: 5, text: 'Personnel'},
            { id: 8, text: 'Asset'},
            { id: 15, text: 'Other Account'}
        ],
        localCurrency: null,
        chequeForm: {
            start_date: moment(),
            end_date: moment().add(60, 'days'),
            position: '-1',
            payment_account_id: '-1',
            bank_account_id: '-1'
        },
        form: {
            id: null,
            docket_type: 14,
            record_date: moment(),
            docket_number: null, 
            account_name: '-1',
            account_id: '-1',
            operation_account_id: '-1',
            operation_account_name: null,
            operation_account_type: 0,
            cheque_position: '-1',
            transaction_type: 1,
            isSelectedAll: false,
            is_accounted: 0,
            record_type: 1
        },
        chequeSummaryList: [],
        isDocketSaving: false,
        isChequeOperationItemSaving: false,
        isListProgressing: false,
        accountNameList: [{id: '-1', text: 'Select an option'}],
        targetPositionList: [],
        lastPositionList: [],
        menuSelection: '/ChequeOperationHelppage',
        iconView : false
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
   },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },    
    isItemButtonDisabled() {
       return this.chequeItemsList.find(item =>item.isSelected == true );
    },
    isModalItemButtonDisabled() {
       return this.chequeOperationItemsList.find(item =>item.isSelected == true );
    },
    transactionTypeListWithNoSelection() {
        return this.transactionTypeList.filter(p => { 
            return p.id !== 0 && p.type == this.form.record_type;
        });
    },
    getAccountName() {
        return (value)=>{
            if (value !== null && value !== '' && value !== '-1' && value !== undefined && this.accountNameList.length > 1) {
                return this.accountNameList.find(element => {
                    return element.id == value
                }).text;
            }
        }
    },
    showPageSizeSelector() {
        return this.chequeItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.chequeItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    showPageSizeSelect() {
        return this.chequeOperationItemsList.length > this.pageSizeNo;
    },
    pageSizesLengt() {
        let arr = [];
        let x = 1;
        let loop = (this.chequeOperationItemsList.length / this.pageSizeNo);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSizeNo * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    getSubCredentials() {
      return (operation)=>{
        let isView = false;
        if(this.form.record_type == 1 && this.form.transaction_type == 1) isView = this.$isCredentialActive(44, operation, 39);
        if(this.form.record_type == 1 && this.form.transaction_type == 2) isView = this.$isCredentialActive(45, operation, 39);
        if(this.form.record_type == 2 && this.form.transaction_type == 1) isView = this.$isCredentialActive(46, operation, 39);
        if(this.form.record_type == 2 && this.form.transaction_type == 2) isView = this.$isCredentialActive(47, operation, 39);
        return isView;
      }
    }
  },
  methods: {
    getData() { 
        this.$Progress.start();
        const parameters = {
            session_id: this.$getUser().session_id,
            docket_sub_type: this.form.transaction_type,
            cheque_position: '-1',
            docket_header_id: this.$getSessionStorage('record_id')
        };        
        const requestOne =  axios.post('/api/Helper/GetPositions', parameters, {'Content-Type': 'application/json'}); 
        const requestTwo =  axios.post('/api/Cheque/GetChequeOperationById', parameters, {'Content-Type': 'application/json'}); 
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
            this.targetPositionList = responses[0].data;
            this.targetPositionList.unshift({ id: '-1', text: 'Select a position for search an account'});
            
            if(responses[1].data.cheque_items.length > 0){
                this.$setSessionStorage('tmp_session_id', responses[1].data.cheque_items[0].session_id); 
            }
            this.form = responses[1].data.cheque_header_detail;
            this.form.operation_account_id = responses[1].data.cheque_header_detail.account_id;
            this.form.transaction_type = responses[1].data.cheque_header_detail.docket_sub_type;
            this.chequeItemsList = responses[1].data.cheque_items;
            this.chequeSummaryList = responses[1].data.cheque_summary; 
            this.localCurrency = this.$getUser().currency_code; 
            this.$setSessionStorage('refreshList', true);

            this.getAccountDetail(this.form.operation_account_id);
            this.$Progress.finish();
        }))
        .catch(errors => { 
            this.$Progress.finish();
            console.error(errors); 
        });
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    handlePropertyChanges(e) {
        if(e.name == "paging") {
            this.pageIndexNo = e.component.pageIndexNo();
        }
    },
    openModal() {
        this.$refs.detailDataGrid.instance.clearFilter();
        this.chequeForm.start_date = moment();
        this.chequeForm.end_date = moment().add(60, 'days');
        const parameters = {
            session_id: this.$getUser().session_id,
            docket_sub_type: this.form.transaction_type,
            cheque_position: this.form.cheque_position
        };        
        this.form.isSelectedAll = false;
        const requestOne =  axios.post('/api/Helper/GetPositions', parameters, {'Content-Type': 'application/json'});
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.lastPositionList = responses[0].data;
            this.lastPositionList.unshift({ id: '-1', text: 'ALL'});
            if(this.lastPositionList.length > 0) {
                this.chequeForm.position = this.lastPositionList[0].id;
            }
            this.getChequeLists();
        }))
        .catch(errors => {
            console.error(errors); 
        }); 
    },
    getChequeLists() {
      this.$Progress.start();
      this.isListProgressing = true;
      this.chequeForm.start_date = (this.chequeForm.start_date == null || this.chequeForm.start_date == '') ? moment() : this.chequeForm.start_date;
      const params = {        
        session_id: this.$getUser().session_id,
        start_date: moment(this.chequeForm.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.chequeForm.end_date).format('YYYY-MM-DD'),
        transaction_type: this.form.transaction_type,
        payment_account_id: null,
        // bank_account_id: this.chequeForm.bank_account_id,
        temp_session_id: this.$getSessionStorage('tmp_session_id'),
        last_position: this.chequeForm.position,
        target_position: this.form.cheque_position,
        record_type: this.form.record_type
      };      
      if(this.form.cheque_position == '0' || this.form.cheque_position == '3' || this.form.cheque_position == '5' || this.form.cheque_position == '6') {
        // || (this.form.transaction_type == '2' && this.form.cheque_position == '4')
        params.payment_account_id = this.form.operation_account_id;
      }
      axios.post('api/Cheque/GetChequeForOperations', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.chequeOperationItemsList = response.data;
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            this.$removeSessionStorage('data'+ '_OTH001');
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    addChequeOperationItem(){
        this.isChequeOperationItemSaving = true;
        const that = this;
        const params = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            selectedChequeIds: []
        };
        this.chequeOperationItemsList.forEach((value) => {
            if(value.isSelected && !value.is_used){
                params.selectedChequeIds.push(value.id);
            }
        });
        axios.post('api/Cheque/CreateChequeOperationItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.cheque_items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.cheque_items[0].session_id); 
                    this.chequeItemsList = response.data.cheque_items;
                    this.chequeSummaryList = response.data.cheque_summary;
                    this.$refs.closeModal.click();
                }  
            }
            this.isChequeOperationItemSaving = false;
        })
        .catch(function () {
            this.isChequeOperationItemSaving = false;
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isChequeOperationItemSaving = false;
            }, 500);
        });  
    },
    deleteChequeItem(isDeleteAll) {
         this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                    this.$Progress.start();
                    const params = { 
                        id: null, 
                        selectedChequeIds: [],
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                        is_all: isDeleteAll
                    };
                    if(isDeleteAll !== 1) {
                        this.chequeItemsList.forEach((item) => { 
                            if(item.isSelected){ 
                                params.selectedChequeIds.push(item.id);
                            }
                        });
                    }
                    axios.post('api/Cheque/DeleteChequeOperationItem', params, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            if(response.data.cheque_items.length > 0){
                                this.$setSessionStorage('tmp_session_id', response.data.cheque_items[0].session_id);
                            }   
                            this.chequeItemsList = response.data.cheque_items;
                            this.chequeSummaryList = response.data.cheque_summary;
                        } 
                        this.$Progress.finish(); 
                    })
                    .catch(function () {
                        this.$Progress.finish();
                    });  
            }
        }); 
    },
    updateChequeHeader(){
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to save this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true; 
                const that = this;
                const params = { 
                    id: this.$getSessionStorage('record_id'),                    
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    record_date: this.form.record_date
                };  
                params.record_date = moment(params.record_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('api/Cheque/UpdateChequeOperationHeader', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$removeSessionStorage('tmp_session_id');
                        this.$setSessionStorage('refreshList', true); 
                        this.getData();
                        this.$swal("Success", "Transaction updated succesfully..!", 'success');
                        this.isDocketSaving = false;
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () { 
                    this.$Progress.finish(); 
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isDocketSaving = false;
                        that.$Progress.finish(); 
                    }, 500);
                }); 
            }
        }); 
    },
    deleteChequeHeader(){
        this.$swal.fire({
            title: "Are you sure you want to delete this transaction record?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start(); 
                const params = { 
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                };  
                axios.post('api/Cheque/DeleteChequeHeader', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$setSessionStorage('refreshList', true);    
                        this.$router.push({ name: 'ChequeOperationsCreate' });
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        });
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                    if(column.dataField == 'amount_local'){ 
                         column.caption =  'Local Amount';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    onRowPrepared(e){ 
        if(e.rowType === 'data') {
            if (e.data.is_used == '1') {
                e.rowElement.classList.add('bg-light');  
            }
        } 
    }, 
    getAccountDetail(id) {
        this.form.operation_account_id = id;
        this.chequeForm.amount = 0.00;
        this.chequeForm.amount_local = 0.00;
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.form.operation_account_name = this.$unlock(response.data.name);
            this.form.operation_account_type = response.data.account_type;
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ChequeOperationHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
      onSelectAll() {
        this.chequeOperationItemsList.forEach((value) => {
            value.isSelected = this.form.isSelectedAll;
        });
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    onCancelIntegration() {
        this.$swal.fire({
            title: "Are you sure you want to cancel accounting integration?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    header_id: this.$getSessionStorage('record_id')
                };
                axios.post('api/AccountingIntegration/CancelSingleIntegration', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$swal("Success", "Accounting Integration canceled..!", 'success');  
                        this.getData();
                    }
                })
                .catch(function (error) { 
                    console.log(error)
                });  
            }
        });
    },
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onOpenAccountReport(){
        this.$refs.AccountReports.getData();
        this.$refs.AccountReports.setModalCriteriasToDefault();
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  },
};
</script>