<template>
    <div class="modal-dialog modal-xxl p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header py-7 d-flex justify-content-between bg-primary">
                <h2 class="text-white">Create New Stock & Service</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeStockCreateModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isAccountSaving == true}">
                <div class="stepper stepper-links d-flex flex-column" data-kt-stepper="true">
                    <form id="poliformStockModal" @submit.prevent="" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                        <div class="current" data-kt-stepper-element="content" v-if="this.activeStep == 1">
                            <div class="w-100">                  
                                <div class="fv-row row">                                            
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-flex flex-row row">
                                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row mb-7 row">
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_code'">
                                                                        Product Code
                                                                    </label>
                                                                    <input type="text" class="form-control" name="product_code" v-model="createStockForm.product_code" v-custom>
                                                                </div>
                                                                <div class="col-lg-12" v-if="isViewAccountId" v-tag="'stock_code'">
                                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}" v-tag="'stock_code'">
                                                                        Stock & Service Code
                                                                    </label>
                                                                    <input type="text" class="form-control" v-model="createStockForm.account_id" name="stock_code" v-custom>
                                                                </div>                                        
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'stock_name'">
                                                                        Stock & Service Name
                                                                    </label>
                                                                    <input type="text" class="form-control" v-model="createStockForm.name" name="stock_name" v-custom>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'stock_group'">
                                                                        Stock & Service Group
                                                                    </label>
                                                                    <Select2 v-model="createStockForm.stock_group_id" :options="stockGroupList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }" name="stock_group" v-custom/>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row mb-6 row">
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_sub_type'">
                                                                        <span>Account Type</span>
                                                                    </label>
                                                                    <Select2 v-custom v-model="createStockForm.account_sub_type" name="account_sub_type" :options="accountTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/> 
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_group'">
                                                                        Product Group                                                                            
                                                                    </label>
                                                                    <Select2 v-custom v-model="createStockForm.product_group_id" name="product_group" :options="productGroupList" @select="onChangeStockProductGroup()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/> 
                                                                </div>                                                                                                                                                                                                            
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_type'">
                                                                        <span>Product Type</span>
                                                                    </label>
                                                                    <Select2 v-custom v-model="createStockForm.product_type_id" name="product_type" :options="productTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/> 
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="card card-xxl-stretch mb-6 border border-solid border-gray-300 bg-light border-gray-300 ">
                                            <div class="card-body pb-0">
                                                <div class="fv-row row">
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'unit_1'">
                                                            <span>Unit</span>
                                                        </label>
                                                        <Select2 v-custom v-model="createStockForm.stock_unit_id" name="unit_1" :options="unitTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'unit_2'">
                                                            <span>Unit 2</span>
                                                        </label>
                                                        <Select2 v-custom v-model="createStockForm.stock_unit_2_id" name="unit_2" :options="unitType2List" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/> 
                                                    </div>                                                                
                                                    <div class="col-lg-12">
                                                        <div class="fv-row mb-7">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                            <span>Notes</span>
                                                        </label>
                                                        <textarea class="form-control" name="notes" v-custom v-model="createStockForm.notes"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </div>
                        <div  v-if="this.activeStep == 2">
                            <div class="w-100">
                                <div class="fv-row row">
                                    <div class="col-lg-12">
                                        <div class="accordion accordion-icon-toggle" id="kt_accordion_2">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addPriceItem()">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        Add New Price
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllPrices()" :disabled="this.stockCurrencyList.length == 0">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        Remove All (!)
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="kt_accordion_2_item_1" class="fs-6 collapse mb-10" :class="{'show': this.isShowPriceForm}" data-bs-parent="#kt_accordion_2">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card border border-solid border-gray-300 border-gray-300 bg-light">
                                                            <div class="card-body">
                                                                <form class="form">
                                                                    <div class="row mb-2">
                                                                        <label class="col-lg-4 col-form-label fw-bold fs-6"> Currency / Price Type </label>
                                                                        <div class="col-lg-8">
                                                                            <div class="row">
                                                                                <div class="col-lg-6 fv-row">
                                                                                    <Select2 v-custom v-model="priceForm.currency" :options="currencyList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }" @select="onChangeCurrency()"/>
                                                                                </div>
                                                                                <div class="col-lg-6 fv-row ps-1 pe-1">
                                                                                    <Select2 v-custom v-model="priceForm.price_type_id" :options="priceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content'  }"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">Sales Price / %VAT</label>
                                                                        <div class="col-lg-8">
                                                                            <div class="row">
                                                                                <div class="col-lg-4 fv-row pe-1">
                                                                                    <CurrencyInput type="text" step="any" class="form-control text-end" v-custom v-model="priceForm.sales_price" @focus="$event.target.select()"/>
                                                                                </div>
                                                                                <div class="col-lg-4 fv-row ps-1 pe-1">
                                                                                    <Select2 v-custom v-model="priceForm.sales_tax_amount" :options="vatRateList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/>
                                                                                </div>
                                                                                <div class="col-lg-4 fv-row ps-1">
                                                                                    <Select2 v-custom v-model="priceForm.sales_vat_status_id" :options="vatStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">Purchase Price / %VAT</label>
                                                                        <div class="col-lg-8">
                                                                            <div class="row">
                                                                                <div class="col-lg-4 fv-row pe-1">
                                                                                    <CurrencyInput type="text" step="any" class="form-control text-end" v-custom v-model="priceForm.purchase_price" @focus="$event.target.select()"/>
                                                                                </div>
                                                                                <div class="col-lg-4 fv-row ps-1 pe-1">
                                                                                    <Select2 v-custom v-model="priceForm.purchase_tax_amount" :options="vatRateList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/>
                                                                                </div>
                                                                                <div class="col-lg-4 fv-row ps-1">
                                                                                    <Select2 v-custom v-model="priceForm.purchase_vat_status_id" :options="vatStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content' }"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mb-2">
                                                                        <label class="col-lg-4 col-form-label fw-bold fs-6"> Is Default? </label>
                                                                        <div class="col-lg-8">
                                                                            <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-custom v-model="priceForm.is_default" :disabled="this.isDefaultDisabled">
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div class="d-grid"> 
                                                                    <div class="separator separator-dashed my-6 mt-3"></div>
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <button type="button" class="btn btn-danger border border-danger btn-sm float-start" @click="this.clearPriceForm()">
                                                                                <span class="indicator-label">Cancel</span> 
                                                                            </button>
                                                                            <button type="button" class="btn btn-success border border-success btn-sm float-end"
                                                                                @click="this.savePriceData()" v-if="this.priceFormType == 'addPrice'" :disabled="this.isModalButtonDisabled">
                                                                                <span class="indicator-label">Add Stock Price</span> 
                                                                            </button>
                                                                            <button type="button" class="btn btn-primary border border-primary btn-sm float-end" v-if="this.priceFormType == 'editPrice'"
                                                                                @click="this.updatePriceData()" :disabled="this.isModalButtonDisabled">
                                                                                <span class="indicator-label">Update Stock Price</span> 
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div class="col-lg-12" v-if="stockCurrencyList.length > 0 && !this.isShowPriceForm">
                                        <div class="card card-xxl-stretch mb-6">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-rounded table-striped border gy-5 gs-7">
                                                    <thead>
                                                        <tr class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                                            <th> ID </th>
                                                            <th> Currency</th>
                                                            <th> Price Type</th>
                                                            <th class="text-end"> Sales Price</th>
                                                            <th class="text-end"> % Sales VAT</th>
                                                            <th class="text-end"> Purchase Price</th>
                                                            <th class="text-end"> % Purchase VAT</th>
                                                            <th class="text-end"> Sales VAT Status</th>
                                                            <th class="text-end"> Purchase VAT Status</th>
                                                            <th class="text-center"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in stockCurrencyList" v-bind:key="item" class="border-bottom">
                                                            <td>
                                                                <small> {{ index +1 }} </small>
                                                            </td>
                                                            <td>
                                                                {{item.currency}}
                                                                <span class="svg-icon svg-icon-2 svg-icon-success" v-if="item.is_default">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                                        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                                                                    </svg>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {{item.price_type_name}}
                                                            </td>
                                                            <td class="text-end">
                                                                {{item.sales_price}}
                                                            </td>
                                                            <td class="text-end">
                                                                {{item.sales_tax_amount}}
                                                            </td>
                                                            <td class="text-end">
                                                                {{item.purchase_price}}
                                                            </td>
                                                            <td class="text-end">
                                                                {{item.purchase_tax_amount}}
                                                            </td>
                                                            <td class="text-end">
                                                                <span v-if="item.sales_vat_status_id == 1">VAT (None)</span>
                                                                <span v-if="item.sales_vat_status_id == 2">VAT (+)</span>
                                                                <span v-if="item.sales_vat_status_id == 3">VAT (in)</span>
                                                            </td>
                                                            <td class="text-end">
                                                                <span v-if="item.purchase_vat_status_id == 1">VAT (None)</span>
                                                                <span v-if="item.purchase_vat_status_id == 2">VAT (+)</span>
                                                                <span v-if="item.purchase_vat_status_id == 3">VAT (in)</span>
                                                            </td>
                                                            <td class="text-end">
                                                                <div class="btn-group">
                                                                    <button type="button" class="btn btn-primary btn-xs me-3" @click="editPriceItem(item)">
                                                                        <i class="bi bi-pencil pe-0"></i>
                                                                    </button>
                                                                    <button type="button" class="btn btn-warning btn-xs" @click="deletePrice(item)">
                                                                        <i class="bi bi-trash pe-0"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot v-if="stockCurrencyList && stockCurrencyList.length == 0">
                                                        <tr>
                                                            <td colspan="10">
                                                                <h6> No price added yet. </h6>
                                                                <small> Use [+ Add New Price] button for adding more items. </small>
                                                            </td>
                                                        </tr>
                                                    </tfoot> 
                                                </table>
                                            </div>
                                            <div class="row" v-if="stockCurrencyList && stockCurrencyList.length > 0">
                                                <div class="col-sm-12 col-md-5 px-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                    <div class="dataTables_info" role="status" aria-live="polite">
                                                        {{ stockCurrencyList.length }} Records
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                    
                                    </div>
                                    <div class="col-lg-12" v-if="stockCurrencyList.length == 0 && !this.isShowPriceForm">
                                        <div class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row w-100 p-5">
                                            <div class="d-flex flex-column ">
                                                <h6> No price added yet. </h6>
                                                <small> Use [+ Add New Price] button for adding more items. </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-stack">
                            <div class="me-2">
                                <button type="button" class="btn btn-lg btn-light-primary border border-primary me-3" v-if="this.activeStep !== 1" @click="onBackStep()">
                                    <span class="svg-icon svg-icon-3 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                                        </svg>
                                    </span> Back
                                </button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-lg btn-light-primary border border-primary" v-if="this.activeStep == 1" @click="onNextStep()">Continue 
                                    <span class="svg-icon svg-icon-3 ms-1 me-0">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </button>
                                <button type="submit" class="btn btn-lg btn-block btn-success" v-if="this.activeStep == 2 && !isShowPriceForm" @click="createStockAccount()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    Save Stock Account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
 
export default {
  name: "AddNewStock",
  data() {
    return {        
        stockGroupList: [],
        productGroupList: [],
        unitTypeList: [],
        unitType2List: [],
        accountTypeList: [{ id: 0, text: 'Stock Account'}, { id: 0, text: 'Service Account'}],
        salesChannelList: [{ id: '-1', text: 'Select an option'}],
        campaignList: [{ id: '-1', text: 'Select an option'}],
        paymentList: [{ id: '-1', text: 'Select an option'}],
        shippingList: [{ id: '-1', text: 'Select an option'}],
        currencyList: [],
        stockCurrencyList: [],
        priceTypeList: [{ id: '-1', text: 'Select an option'}],
        vatRateList: [{ id: '-1', text: 'Select an option'}],
        vatStatusList: [
            { id: 1, text: 'Select an option'},
            { id: 2, text: 'VAT (+)'},
            { id: 3, text: 'VAT (in)'}
        ],
        productTypeList: [],
        createStockForm: {
            id: null,
            isViewAccountId: false,
            name: null,
            company_id: null,
            account_type: 7,
            account_id: null,
            qr_code: null,
            notes: null,
            stock_group_id: '-1',
            product_group_id: '-1',
            product_type_id: '-1',
            stock_unit_id: '-1',
            stock_unit_2_id: '-1',
            stockGroupSelection: null,
            stockTypeSelection: null,
            unitTypeSelection: null,
            accounting_plan: null,
            product_code: null,
            account_sub_type: 0,
        },
        priceForm: {
            id: null,
            currency: '-1',
            sales_price: 0.00,
            sales_tax_amount: 0.00,
            purchase_price: 0.00,
            purchase_tax_amount: 0.00,
            price_type_id: '-1',
            is_default: false,
            sales_vat_status_id: 1,
            purchase_vat_status_id: 1,
        },
        isAccountSaving: false,
        activeStep: 1,
        isModalButtonDisabled: false,
        isPriceSaving: false,
        isDefaultDisabled: false,
        isShowPriceForm: false,
        priceFormType: 'addPrice'
    };
  },
  methods: {
    getData() {
        this.clearForms();
        this.$Progress.start();
        this.$removeSessionStorage('prc_tmp_session_id');
        this.$removeSessionStorage('data'+ '_STCPRCLST');
        this.activeStep = 1;
        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency", "account_setting", "product_group"],
            account_type: 7 // yalnızca account settings listesi çekilecek ise gerekli
        }; 
        axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((generalListResponse) => {
            this.productGroupList = generalListResponse.data.product_group;
            this.currencyList = generalListResponse.data.currency;
            this.localCurrency = this.$getUser().currency_code;
            this.priceForm.currency = this.$getUser().currency_code;
            if (generalListResponse.data.account_setting.is_default == false) {
                this.createStockForm.isViewAccountId = true;
            }
            this.productGroupList.unshift({ id: '-1', text: 'Select an option'});
            this.productGroupList.forEach((value) => {
                if(value.is_default) {
                    this.createStockForm.product_group_id = value.id;
                }
            });

            const params = {
                session_id: this.$getUser().session_id,
                list_types: [
                    {
                        list_type: 6,
                        list_name: "stock_group"
                    },
                    {
                        list_type: 9,
                        list_name: "unit_type"
                    },
                    {
                        list_type: 10,
                        list_name: "unit_2_type"
                    },
                    {
                        list_type: 13,
                        list_name: "price_type"
                    },
                    {
                        list_type: 15,
                        list_name: "vat_rate"
                    }
                ],
            };
            axios.post("/api/Helper/GetMultiLists", params, {'Content-Type': 'application/json'}).then((response) => {
                this.stockGroupList = response.data.stock_group; 
                this.unitTypeList = response.data.unit_type; 
                this.unitType2List = response.data.unit_2_type; 
                this.priceTypeList = response.data.price_type; 
                this.vatRateList = response.data.vat_rate;
                this.vatRateList.forEach((value) => {
                    value.id = value.val;
                });
                this.priceTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.vatRateList.unshift({ id: 0, text: 'Select an option'});
                this.stockGroupList.unshift({ id: '-1', text: 'Select an option'}); 
                this.unitTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.unitType2List.unshift({ id: '-1', text: 'Select an option'});
                this.stockGroupList.forEach((value) => {
                    if(value.is_default) {
                        this.createStockForm.stock_group_id = value.id;
                    }
                });
                this.unitTypeList.forEach((value) => {
                    if(value.is_default) {
                        this.createStockForm.stock_unit_id = value.id;
                    }
                });  
                this.unitType2List.forEach((value) => {
                    if(value.is_default) {
                        this.createStockForm.stock_unit_2_id = value.id;
                    }
                });
                this.priceTypeList.forEach((value) => {
                    if (value.is_default) {
                        this.priceForm.price_type_id = value.id;
                    }
                });
                this.vatRateList.forEach((value) => {
                    if(value.is_default) {
                        this.priceForm.sales_tax_amount = value.val;
                        this.priceForm.purchase_tax_amount = value.val;
                    }
                });
                const params = {
                    session_id: this.$getUser().session_id,
                    parent_id: this.createStockForm.product_group_id
                };
                axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((stockTypeResponse) => { 
                    this.productTypeList = stockTypeResponse.data;
                    this.productTypeList.unshift({ id: '-1', text: 'Select an option'}); 
                    this.createStockForm.product_type_id = this.productTypeList[0].id;
                    this.productTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.createStockForm.product_type_id = value.id;
                        }
                    });
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    console.log(error);
                    this.$Progress.finish();
                });
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
            });
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    onChangeStockProductGroup() {
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.createStockForm.product_group_id
        };
        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((stockTypeResponse) => { 
            this.productTypeList = stockTypeResponse.data;
            this.productTypeList.unshift({ id: '-1', text: 'Select an option'}); 
            this.createStockForm.product_type_id = this.productTypeList[0].id;
            this.productTypeList.forEach((value) => {
                if(value.is_default) {
                    this.createStockForm.product_type_id = value.id;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    createStockAccount() {
        this.$checkSelect2Validation('poliformStockModal');
        if(!document.forms['poliformStockModal'].reportValidity()){
            return;
        }
        try{
            var data = sessionStorage.getItem('frm_val'); 
            var priceTableValidation = JSON.parse(data).find((item) => item.field == "price_table");
            if(priceTableValidation !== null && priceTableValidation !== undefined && priceTableValidation !== ''){
                if(priceTableValidation.is_required){
                    if(this.stockCurrencyList.length == 0){
                        this.$swal("Failed", "Price Table entry is required..!", 'error');
                        return;
                    }
                } 
            }
        }
        catch(Err){
            console.error(Err);
        }
        this.isProgressing = true;
        const params = this.createStockForm;
        const that = this;
        params.session_id = this.$getUser().session_id;
        params.tmp_session_id = this.$getSessionStorage('prc_tmp_session_id');
        if(params.stock_unit_2_id == '-1' ){
            params.stock_unit_2_id = null;
        }
        if(params.stock_group_id == '-1'){ params.stock_group_id = null;}
        if(params.stock_unit_id == '-1'){ params.stock_unit_id = null;}
        if(params.product_type_id == '-1'){ params.product_type_id = null;}
        if(params.product_group_id == '-1'){ params.product_group_id = null;}
        axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.createStockForm.id = response.data;
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.$refs.closeStockCreateModal.click();
                    this.$parent.onSuccessNewAccount(this.createStockForm.id, 7);
                }
                this.isAccountSaving = false;
            })
            .catch(function () { 
                this.isAccountSaving = false;
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isAccountSaving = false;
                }, 500);
            }); 
    },
    onChangeCurrency() {
        const isCurrencyExist = this.stockCurrencyList.find(item => item.currency == this.createStockForm.currency);
        if (!isCurrencyExist) {
            this.isDefaultDisabled = true;
            this.priceForm.is_default = true;
        }
    },
    clearPriceForm() {
        this.stockCurrencyList = [];
        this.isShowPriceForm = false;
        this.priceForm = {
            id: null,
            currency: this.localCurrency,
            sales_price: 0.00,
            sales_tax_amount: 0.00,
            purchase_price: 0.00,
            purchase_tax_amount: 0.00,
            price_type_id: '-1',
            is_default: false,
            sales_vat_status_id: 1,
            purchase_vat_status_id: 1,
        };
        this.isDefaultDisabled = false;
    },
    addPriceItem() {
        this.priceFormType = 'addPrice';
        this.isShowPriceForm = !this.isShowPriceForm;
        this.priceForm = {
            id: null,
            currency: this.localCurrency,
            sales_price: 0.00,
            sales_tax_amount: 0.00,
            purchase_price: 0.00,
            purchase_tax_amount: 0.00,
            price_type_id: '-1',
            is_default: false,
            sales_vat_status_id: 1,
            purchase_vat_status_id: 1,
        };
        this.isDefaultDisabled = false;

        if (this.stockCurrencyList.length == 0) {
            this.isDefaultDisabled = true;
            this.priceForm.currency = this.localCurrency;
            this.priceForm.is_default = true;
        }
    },
    editPriceItem(priceItem) {
        this.priceFormType = 'editPrice';
        this.isShowPriceForm = !this.isShowPriceForm;
        this.stockCurrencyList.forEach((item) => {
            if (item.id == priceItem.id) {
                this.priceForm.id = item.id;
                this.priceForm.stock_account_id = item.stock_account_id;
                this.priceForm.currency = item.currency;
                this.priceForm.sales_price = item.sales_price.replace(/,/g, '');
                this.priceForm.sales_tax_amount = item.sales_tax_amount.replace(/,/g, '');
                this.priceForm.purchase_price = item.purchase_price.replace(/,/g, '');
                this.priceForm.purchase_tax_amount = item.purchase_tax_amount.replace(/,/g, '');
                this.priceForm.is_default = item.is_default;
                this.priceForm.price_type_id = item.price_type_id ? item.price_type_id : '-1';
                this.priceForm.sales_vat_status_id = item.sales_vat_status_id;
                this.priceForm.purchase_vat_status_id = item.purchase_vat_status_id;
            }
        });
    },
    savePriceData() {
      const that = this;
      const params = this.priceForm;      
      params.session_id = this.$getUser().session_id;
      params.tmp_session_id = this.$getSessionStorage('prc_tmp_session_id');
      if (params.price_type_id == '-1' || params.price_type_id == '' || params.price_type_id == undefined) {
        params.price_type_id = null;
      }
      axios.post('api/CompanyAccounts/AddStockPrice', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.stockCurrencyList = [];
                response.data.forEach((item) => { 
                    this.stockCurrencyList.push({
                        created_at: item.created_at,
                        currency: item.currency,
                        id: item.id,
                        is_default: item.is_default,
                        price_type_id: item.price_type_id,
                        purchase_price: item.purchase_price,
                        purchase_tax_amount: item.purchase_tax_amount,
                        sales_price: item.sales_price,
                        sales_tax_amount: item.sales_tax_amount,
                        session_id: item.session_id,
                        tmp_session_id: item.tmp_session_id,
                        price_type_name: item.price_type_name,
                        sales_vat_status_id: item.sales_vat_status_id,
                        purchase_vat_status_id: item.purchase_vat_status_id,
                    });
                });
                
                if(this.stockCurrencyList.length > 0){
                    this.$setSessionStorage('prc_tmp_session_id', this.stockCurrencyList[0].tmp_session_id);
                } 
                this.$setSessionStorage('data'+ '_STCPRCLST', this.stockCurrencyList);
            }
            this.isShowPriceForm = false;
        })
        .catch(function (error) {
            console.log(error);
            this.isShowPriceForm = false;
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isShowPriceForm = false;
            }, 500);
        });  
    },
    updatePriceData() {
        this.stockCurrencyList.forEach((item) => { 
            if (item.id == this.priceForm.id) {
                const that = this;
                const params = this.priceForm;    
                params.session_id = this.$getUser().session_id;
                params.tmp_session_id = this.$getSessionStorage('prc_tmp_session_id');
                if (params.price_type_id == '-1' || params.price_type_id == '' || params.price_type_id == undefined) {
                    params.price_type_id = null;
                }
                axios.post('api/CompanyAccounts/UpdateStockPrice', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.stockCurrencyList = [];
                        response.data.forEach((item) => { 
                            this.stockCurrencyList.push({
                                created_at: item.created_at,
                                currency: item.currency,
                                id: item.id,
                                is_default: item.is_default,
                                price_type_id: item.price_type_id,
                                purchase_price: item.purchase_price,
                                purchase_tax_amount: item.purchase_tax_amount,
                                sales_price: item.sales_price,
                                sales_tax_amount: item.sales_tax_amount,
                                session_id: item.session_id,
                                tmp_session_id: item.tmp_session_id,
                                price_type_name: item.price_type_name,
                                sales_vat_status_id: item.sales_vat_status_id,
                                purchase_vat_status_id: item.purchase_vat_status_id,
                            });
                        });
                        
                        if(this.stockCurrencyList.length > 0){
                            this.$setSessionStorage('prc_tmp_session_id', this.stockCurrencyList[0].tmp_session_id);
                        } 
                        this.$setSessionStorage('data'+ '_STCPRCLST', this.stockCurrencyList);
                    }
                    this.isShowPriceForm = false;
                })
                .catch(function (error) {
                    this.isShowPriceForm = false;
                    console.log(error);
                    this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isShowPriceForm = false;
                    }, 500);
                });
            }
        });  
    },
    deletePrice(priceItem) {
         this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.stockCurrencyList.forEach((item) => {
                    if(item.id == priceItem.id) {
                        const params = {
                            id: item.id,
                            session_id: this.$getUser().session_id,
                            tmp_session_id: this.$getSessionStorage('prc_tmp_session_id')
                        }; 
                        axios.post('api/CompanyAccounts/DeleteStockPrice', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            this.stockCurrencyList = [];
                            response.data.forEach((tmpItem) => { 
                                this.stockCurrencyList.push({
                                    created_at: tmpItem.created_at,
                                    currency: tmpItem.currency,
                                    id: tmpItem.id,
                                    is_default: tmpItem.is_default,
                                    price_type_id: tmpItem.price_type_id,
                                    purchase_price: tmpItem.purchase_price,
                                    purchase_tax_amount: tmpItem.purchase_tax_amount,
                                    sales_price: tmpItem.sales_price,
                                    sales_tax_amount: tmpItem.sales_tax_amount,
                                    session_id: tmpItem.session_id,
                                    tmp_session_id: tmpItem.tmp_session_id,                                    
                                    price_type_name: tmpItem.price_type_name,
                                    sales_vat_status_id: tmpItem.sales_vat_status_id,
                                    purchase_vat_status_id: tmpItem.purchase_vat_status_id,
                                });
                            });

                            if(this.stockCurrencyList.length > 0){
                                this.$setSessionStorage('prc_tmp_session_id', this.stockCurrencyList[0].tmp_session_id);
                            }

                            this.$setSessionStorage('data'+ '_STCPRCLST', this.stockCurrencyList); 
                        })
                        .catch(function () {
                        });  
                        
                    }
                }) 
                this.$setSessionStorage('data'+ '_STCPRCLST', this.stockCurrencyList);
            }
        });
    },
    deleteAllPrices(){ 
        this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE ALL (!)",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('prc_tmp_session_id')
                }; 
                axios.post('api/CompanyAccounts/DeleteAllStockPrices', params, {'Content-Type': 'application/json'})
                    .then(() => { 
                        this.stockCurrencyList = [];  
                        this.$removeSessionStorage('data'+ '_STCPRCLST');
                    })
                    .catch(function () {
                    });   
                this.$setSessionStorage('data'+ '_STCPRCLST', this.stockCurrencyList); 
            }
        }); 
    },
    onNextStep() {        
        this.$checkSelect2Validation('poliformStockModal');        
        if(!document.forms['poliformStockModal'].reportValidity()){
            return;
        }
        this.activeStep++;
    },
    onBackStep() {
      this.activeStep--;
    },
    clearForms() {
        this.createStockForm = {
            id: null,
            isViewAccountId: false,
            name: null,
            company_id: null,
            account_type: 7,
            account_id: null,
            qr_code: null,
            notes: null,
            stock_group_id: '-1',
            product_group_id: '-1',
            product_type_id: '-1',
            stock_unit_id: '-1',
            stock_unit_2_id: '-1',
            stockGroupSelection: null,
            stockTypeSelection: null,
            unitTypeSelection: null,
            accounting_plan: null,
            product_code: null,
            account_sub_type: 0
        };
        this.clearPriceForm();

        const params = {
            route: 'StockCreate',
            session_id: this.$getUser().session_id
        };
        axios.post('api/FormValidations/GetFormFields', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(response.data));
            }
        })
        .catch(function () { 
            console.error('No fields found..!');
        });
    },
    onCloseModal() {
        this.$parent.$refs.openStockItemModal.click();
    }
  }
};
</script>