<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Balance Closing Parameters') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>      
                    <li class="breadcrumb-item text-gray-600">{{ $t('Balance Closing Parameters') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>  
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                      <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                <div class="fv-row row mb-10">
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Collection Balance Closing Option') }} 
                                        </label> 
                                        <Select2 v-model="form.balance_closing_option" :options="balanceClosingParameters" :settings="{width: '100%'}"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Payment Balance Closing Option') }} 
                                        </label> 
                                        <Select2 v-model="form.payment_balance_closing_option" :options="balanceClosingParameters" :settings="{width: '100%'}"/>
                                    </div>
                                </div>
                            </form>                        
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isProgressing"> {{ $t('Save Balance Closing Parameters') }}</strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// import Swal from "sweetalert2";
 
export default {
  name: "BalanceClosingParameters", 
  data() {
    return {
      isProgressing: false,
      form:{
        balance_closing_option: 1,
        payment_balance_closing_option: 1
      },
      menuSelection: '/BalanceCLosingHelppage',
      iconView : false,
      balanceClosingParameters: [
        { id: 1, text: 'Automatic Closing'},
        { id: 2, text: 'Automatic Closing + User'}
      ],
    };
  },
  methods: {
    getData() { 
        const queryParameter = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/CompanyAccounts/GetBalanceClosingParameters', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {
            this.form = { 
                balance_closing_option: response.data.balance_closing_option,
                payment_balance_closing_option: response.data.payment_balance_closing_option,
            };
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    saveData(){
        this.isProgressing = true;
        const params = {
            session_id: this.$getUser().session_id,
            balance_closing_option: this.form.balance_closing_option,
            payment_balance_closing_option: this.form.payment_balance_closing_option,
        };
        const that = this;     
        axios.post('/api/CompanyAccounts/UpdateBalanceClosingParameters', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){
                this.$swal("Success", "Data saved succesfully..!", 'success');  
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
      },
      async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/BalanceCLosingHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
    }, 
    created() {
        this.helpPageViewIcon();
        this.getData();
    }
}; 
</script>