<template> 
  <div>
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">File Manager (Beta)</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>     
                <li class="breadcrumb-item text-gray-600">File Manager (Beta)</li> 
            </ul> 
        </div>  
        <!-- <div class="d-flex align-items-center py-1">
            <router-link to="/Settings">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link> 
        </div> -->
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card mb-5 mb-xl-10">
            <div class="card-body pt-9 pb-0">
                <DxFileManager
                    :file-system-provider="remoteProvider"
                    :on-selected-file-opened="displayImagePopup"
                    current-path="Widescreen"
                    >
                    <DxPermissions
                        :create="false"
                        :copy="false"
                        :move="false"
                        :delete="false"
                        :rename="false"
                        :upload="false"
                        :download="false"
                    />
                    </DxFileManager> 
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
// Component Alias: `CMP001`  
// import axios from 'axios' 
// import Swal from 'sweetalert2';
import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
const remoteProvider = new RemoteFileSystemProvider({
  endpointUrl: '/api/FileManager/AllFiles',
});
 
export default {
  name: "FileManagerList", 
  components: {
    DxFileManager,
    DxPermissions
  },
  data() {
    return {
      remoteProvider,
      popupVisible: false,
      imageItemToDisplay: {},
    };
  }, 
  computed: {
    
  },
  methods: {
    displayImagePopup(e) {
      this.imageItemToDisplay = {
        name: e.file.name,
        url: e.file.dataItem.url,
      };
      this.popupVisible = true;
    },
},
created() {
        
}
}; 
</script>