<template>
  <div>  
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Users') }}</h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserManagement' }">{{ $t('User Management') }}</router-link></li>
              <li class="breadcrumb-item text-gray-600">{{ $t('Users') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
              </li> 
          </ul> 
      </div> 
      <div class="d-flex align-items-center py-1">
          <router-link to="/UserManagement">
              <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
               <span class="ms-2 fs-7"> {{ $t('Return back to User Management') }} </span>
              </button>
          </router-link> 
      </div> 
    </div>
  <div class="content flex-row-fluid mt-4" id="kt_content">    
      <div class="card mb-5">
        <div class="card-header min-h-45px">
            <div class="card-title"> <h3> {{ $t('User Privileges Setting') }} </h3> </div>
        </div>
        <div class="card-body py-1">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-check mt-5">
                        <input class="form-check-input" type="checkbox" value="" id="isManageUsersByGroups" v-model="form.isManageUsersByGroups" @change="savePrivilegesSetting()">
                        <label class="fs-6 fw-bold form-label" for="isManageUsersByGroups">
                            {{ $t('Manage User Accounts by User Groups?') }}
                        </label>
                    </div> 
                </div>
            </div>
        </div>
      </div>
      <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title">
                <router-link to="/UserCreate" v-if="this.lists.length !== 0 && this.lists.length < userData.user_count">
                    <button type="button" class="btn btn-light-primary border border-primary me-3">
                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                        </svg></span>
                        {{ $t('Add User') }}
                    </button>
                </router-link>
            </div>
            <div class="card-toolbar">
                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                    <button type="button" class="btn btn-light-primary border border-primary" @click="getUsers()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                        </svg>
                        </span>
                        <span v-if="!this.isListProgressing"> {{ $t('List Users') }} </span> 
                        <span class="indicator-progress" v-if="this.isListProgressing">
                            {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                    </button>
                </div> 
            </div>
          </div>
          <div class="card-body pt-3">
            <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
              <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive min-h-300px">                  
                  <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="lists" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                          data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'User-List')"> 
                          <DxFilterRow :visible="true"/>
                          <DxHeaderFilter :visible="true"/>
                          <DxGroupPanel :visible="true"/>      
                          <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                          <DxPaging :page-size="10"/>
                          <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                          <DxSearchPanel :visible="true"/>
                          <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />
                          <DxColumn data-field="name_surname" :caption="$t('Name Surname')" header-cell-template="name-surname-header"/> 
                          <DxColumn data-field="user_mail" :caption="$t('Email')" alignment="left"/> 
                          <DxColumn data-field="user_assignment_name" :caption="$t('User Assignment')" /> 
                          <DxColumn data-field="login_method_name" :caption="$t('Login Method')" alignment="center" header-cell-template="login-method-header"/> 
                          <DxColumn data-field="is_owner" :caption="$t('Is Owner?')" alignment="center" :allow-filtering="false"/> 
                          <DxColumn data-field="is_chatbi_user" :caption="$t('Is ChatBi User?')" alignment="center" :allow-filtering="false"/> 
                          <DxColumn data-field="is_active" :caption="$t('Active')" alignment="center" :allow-filtering="false"/>
                          <DxColumn data-field="created_at" :caption="$t('Created Date')" header-cell-template="created-date-header" alignment="right"/>
                          

                          <template #name-surname-header>
                              <div>{{ $t('Name') }}</div><div>{{ $t('Surname') }}</div>
                          </template>  
                          <template #login-method-header>
                              <div>{{ $t('Login') }}</div><div>{{ $t('Method') }}</div>
                          </template>
                          <template #created-date-header>
                              <div>{{ $t('Created') }}</div><div>{{ $t('Date') }}</div>
                          </template>

                          <template #dataRowTemplate="{ data: data }"> 
                              <td class="text-gray-900 fw-bolder">
                                  <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                      <span class="badge badge-circle badge-light me-3">
                                        <i class="bi bi-pencil text-gray-900"></i> 
                                      </span> 
                                      <span class="me-3"> {{ data.data.name_surname }} </span>
                                      <span v-if="data.data.permission_type == 0">
                                          <span class="badge badge-light-warning">Not Defined</span> 
                                      </span> 
                                      <span v-if="data.data.permission_type == 1">
                                          <span class="badge badge-light-primary">Admin</span> 
                                      </span> 
                                      <span v-if="data.data.permission_type == 2">
                                          <span class="badge badge-light-primary">Client</span> 
                                      </span> 
                                  </a>
                              </td>
                              <td class="text-gray-900">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                      <span class="me-3"> {{ data.data.user_mail }} </span>
                                       <span v-if="data.data.id == this.$getUser().id">
                                          <span class="badge badge-success">You</span> 
                                      </span> 
                                  </a>
                              </td>
                              <td class="text-gray-800">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                        {{ data.data.user_assignment_name }}
                                    </a>
                                </td>
                              <td class="text-gray-900 text-center">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                      
                                    <span :class="data.data.login_method == 0 ? 'badge badge-warning' : 'badge badge-primary'">
                                        {{ data.data.login_method_name }}
                                    </span>
                                  </a>
                              </td>
                              <td class="text-gray-800 text-center">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                      
                                      <span v-if="data.data.is_owner">
                                            <i class="las la-check fs-2 text-success"></i>
                                      </span>  
                                      <span v-if="!data.data.is_owner">
                                          <i class="las la-times fs-2 text-danger"></i>
                                      </span> 
                                  </a>
                              </td>  
                              <td class="text-gray-800 text-center">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                      
                                      <span v-if="data.data.is_chatbi_user">
                                            <i class="las la-check fs-2 text-success"></i>
                                      </span>  
                                      <span v-if="!data.data.is_chatbi_user">
                                          <i class="las la-times fs-2 text-danger"></i>
                                      </span> 
                                  </a>
                              </td>  
                              <td class="text-gray-800 text-center">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">                                      
                                      <span v-if="data.data.is_active">                                          
                                            <i class="las la-check fs-2 text-success"></i>
                                      </span>  
                                      <span v-if="!data.data.is_active">
                                          <i class="las la-times fs-2 text-danger"></i>
                                      </span> 
                                  </a>
                              </td>
                              <td class="text-gray-800 text-end">
                                  <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                     {{ previewDate(data.data.created_at) }} 
                                  </a>
                              </td>
                          </template>
                  </DxDataGrid>
                </div>
              </div> 
          </div>
      </div> 
  </div>
  </div> 
</template>

<style scoped>
.mh-300 {
    min-height: 300px;
}
</style>

<script>
// Component Alias: `CML001`  
import axios from 'axios' 
  
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "UserList",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
        form: {
            isManageUsersByGroups: false
        },
        userData: this.$getUser(),
        pageSize: 10,
        lists: [],
        isOpenActionsDropdown: false,
        searchbar: '',
        isListProgressing: false,
        logs: [],      
        page_size: 10,
        page: 1,
        menuSelection: '/UsersManagementHelppage',
        iconView : false
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name_surname.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {
      this.lists = [];
      const parameters = {
        session_id: this.$getUser().session_id
      };  
      axios.post('/api/Users/GetPrivilegesSetting', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == '') {
            this.form.isManageUsersByGroups = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getUsers() {
      this.$Progress.start();
      this.isListProgressing = true;
        const parameters = {
            session_id: this.$getUser().session_id
        };  
        const that = this;
        axios.post('/api/Users/CompanyUsers', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.lists = response.data;
                this.lists.forEach(element => {
                    element.user_mail = this.$unlock(element.user_mail);
                    element.name_surname = this.$unlock(element.name_surname);
                    element.text = this.$unlock(element.text);
                    element.phone = this.$unlock(element.phone);
                    element.user_description = this.$unlock(element.user_description);
                });
            }
        })
        .finally(function () { 
            that.isListProgressing = false;
            that.$Progress.finish();
        });  
    },
    savePrivilegesSetting() {
        this.$swal.fire({
            title: this.form.isManageUsersByGroups ? "Are you sure you want to activate this item?" : "Are you sure you want to deactivate this item?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        }).then((result) => { 
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    is_manage_users_by_groups: this.form.isManageUsersByGroups ? 1 : 0
                };  
                axios.post('/api/Users/SavePrivilegesSetting', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.form.isManageUsersByGroups = response.data;
                        this.$swal("Success", "Data saved succesfully..!", 'success'); 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
            else{
                this.form.isManageUsersByGroups = !this.form.isManageUsersByGroups;
            }
        });
    },
    updateUser(id){ 
      this.$setSessionStorage('record_id', id);
      this.$router.push({ name: 'UserUpdate' });
    },
    async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/UsersManagementHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() { 
    this.helpPageViewIcon();
    this.getData();
    this.getUsers();
  }
}; 
</script>