<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Fixed Asset Account') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'FixedAssetList' }">{{ $t('Fixed Asset') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Fixed Asset Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/FixedAssetList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch"> 
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-10">
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'asset_name'">
                                                    {{ $t('Asset Name') }}
                                                </label>
                                                <input type="text" class="form-control" name="asset_name" v-model="form.name" v-custom>
                                            </div>
                                            <div class="col-lg-12" v-if="isViewAccountId">
                                                <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                    {{ $t('Account Serial No') }}
                                                </label>
                                                <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'asset_type'">
                                                    <span>{{ $t('Asset Type') }}</span>
                                                </label>
                                                <Select2 v-model="form.asset_type_id" :options="assetTypeList" name="asset_type" v-custom :settings="{ width: '100%'}"/> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                        <div class="fv-row  row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                    <span>{{ $t('Branch') }}</span>
                                                </label>
                                                <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_center'">
                                                        <span>{{ $t('Expense Center') }}</span>
                                                    </label>

                                                    <Select2 v-model="form.expense_center_id" :options="expenseCenterList" name="expense_center" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mb-0">
                                                <label class="mt-20">
                                                </label>
                                            </div>                                                
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body">
                                        <div class="fv-row  row">
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'economic_life'">
                                                    <span>{{ $t('Economic Life') }}({{ $t('Year') }})</span>
                                                </label>
                                                <input type="number" class="form-control" name="economic_life" v-model="form.economic_life" @blur="onChangeEconomicLife()" v-custom>                                        
                                            </div>     
                                            <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'amortization_rate'">
                                                    <span>{{ $t('Amortization Rate') }} (%)</span>
                                                </label>
                                                <CurrencyInput type="text" step="any" class="form-control text-end" v-model="form.amortization_rate" 
                                                    name="amortization_rate" v-custom @focus="$event.target.select()" @blur="onChangeEconomicLife()"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div>
                                        <div class="fv-row mb-7">
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'show_on_invoice'">
                                                    <span>{{ $t('Show on Invoice?') }}</span>
                                                </label>
                                                <Select2 v-model="form.is_show_invoice" :options="showInvoiceList" name="show_on_invoice" v-custom :settings="{ width: '100%'}"/> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(17,'I')">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> {{ $t('Save Asset') }} </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
 
export default {
  name: "FixedAssetCreate",
  data() {
    return {
      assetTypeList: [],
      branchList: [],
      expenseCenterList: [],
      isProgressing: false,
      showInvoiceList: [ {id: '1', text: 'Yes'}, {id: '0', text: 'No'}],
      form: {
        name: null,
        company_id: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null,
        asset_type_id: '-1',
        branch_id: '-1',
        expense_center_id: '-1',
        accounting_plan: null,
        is_show_invoice: 1,
        economic_life: 1,
        amortization_rate: 100
      },
      isViewAccountId: false,
      menuSelection: '/FixedAssetHelppage',
      iconView : false
    };
  },
  methods: {
    getData() {
        this.$Progress.start();
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 8, // Asset Type: 8            
            get_access_status: 0
        };
        axios.post("/api/Helper/GetLists", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.assetTypeList = response.data; 
            this.assetTypeList.unshift({ id: '-1', text: 'Select an option'}); 
            this.assetTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.asset_type_id = value.id;
                }
            });
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["branch", "account_setting", "expense_center"],
                account_type: 8 // yalnızca account settings listesi çekilecek ise gerekli
            }; 
            axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {                
                this.branchList = response.data.branch;
                this.expenseCenterList = response.data.expense_center;
                this.branchList.forEach((value) => {
                    if (value.is_default) {
                        this.form.branch_id = value.id;
                    }
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.form.expense_center_id = value.id;
                    }
                });
                if (response.data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.$Progress.finish();
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
            });
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/FixedAssetHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isProgressing = true;
        const params = {
            id:this.form.id,
            name:this.form.name,
            company_id:this.form.company_id,
            account_type:this.form.account_type,
            account_id:this.form.account_id,
            qr_code:this.form.qr_code,
            notes:this.form.notes,
            asset_type_id:this.form.asset_type_id == '-1' ? null : this.form.asset_type_id,
            branch_id:this.form.branch_id == '-1' ? null : this.form.branch_id,
            expense_center_id:this.form.expense_center_id == '-1' ? null : this.form.expense_center_id,
            accounting_plan:this.form.accounting_plan,
            is_show_invoice:this.form.is_show_invoice,
            economic_life:this.form.economic_life,
            amortization_rate:this.form.amortization_rate
        };   
        const that = this; 
        params.session_id = this.$getUser().session_id;
        params.account_type = 8; // Fixed Asset: 8 
        axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'FixedAssetUpdate' });
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeEconomicLife() {
        this.form.amortization_rate = 100 / this.form.economic_life;
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>