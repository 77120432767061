<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Sales Role / Campaign') }} </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'CampaignList' }">{{ $t('Sales Role / Campaign') }}</router-link></li>     
                <li class="breadcrumb-item text-gray-600">{{ $t('Update Sales Role / Campaign') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/CampaignList">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                </button>
            </router-link> 
            &nbsp;
            <div class="dropdown">
                <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="campaignActions" type="button">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    {{ $t('Actions') }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="campaignActions"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>{{ $t('Delete') }} </a>
                    </li>
                </ul>
            </div>  
        </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">            
        <StatsBar :form-account="this.form"></StatsBar>            
        <div class="row">
            <div class="card-body">
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#kt_accounting_1" target="_self" :class="{'active': this.activeTab == 'inputForm'}">
                            <h4> {{ $t('Sales Role / Campaign') }} </h4>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#kt_accounting_2" target="_self">
                            <h4> {{ $t('View') }} </h4>
                        </a>
                    </li>
                </ul>
            </div>
            <RequiredField></RequiredField>
            <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="row p-10">
                    <div class="col-lg-12 mt-0"> 
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Code') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="code" v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Name') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="name" v-model="form.name">
                                                    </div>
                                                </div>
                                            </form>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0">
                                        <div class="row">
                                            <form id="kt_ecommerce_settings_general_form"
                                                class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-5">
                                                            <span>{{ $t('Payment Value') }}</span>
                                                            <small class="fs-8"> ({{ $t('Day') }}) </small>
                                                        </label>
                                                        <CurrencyInput type="text" class="form-control" name="payment_value" v-model="this.form.payment_value" 
                                                        :precision="0" @focus="$event.target.select()" :min="0" @change="onChangePaymentValue()" />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="isAlways">
                                                            {{ $t('Always') }}
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isAlways" v-model="form.is_always">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" for="isActive">
                                                            {{ $t('Active') }}
                                                        </label>
                                                        <div class="form-check mt-3 mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.is_active">
                                                        </div> 
                                                    </div>
                                                    <div class="col-lg-6" v-if="!this.form.is_always">
                                                        <label class="fs-6 fw-bold form-label mt-5">
                                                            <span>{{ $t('Campaign Start Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" @update:modelValue="onChangePaymentValue()"
                                                            inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                    </div>
                                                    <div class="col-lg-6" v-if="!this.form.is_always">
                                                        <label class="fs-6 fw-bold form-label mt-5">
                                                            <span>{{ $t('Campaign End Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                    </div>
                                                </div>
                                            </form>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                            <div class="d-flex flex-column flex-row-auto  col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="p-5">
                                        <div class="d-flex flex-stack flex-wrap mb-5">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                    data-bs-target="#kt_modal_add_item" @click="openItemModal('addItem')"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Add Item') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                                    data-bs-target="#kt_modal_add_item" @click="openItemModal('editItem')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    <span class="svg-icon svg-icon-2">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                            </svg>
                                                        </span> 
                                                    </span>
                                                    {{ $t('Edit') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteCampaignItem()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllCampaignItems()">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove All (!)') }}
                                                </button>
                                            </div>
                                        </div>
                                        <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive min-h-300px">
                                                <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="campaignItemList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'Campaign-update')"
                                                    :v-model:page-size="pageSize"
                                                    :v-model:page-index="pageIndex"> 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                    <DxSearchPanel :visible="true"/>
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"
                                                    />

                                                    <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                    <DxColumn data-field="customer_type_name" :caption="$t('Customer/Supplier Type')" header-cell-template="customer-type-header" alignment="left" cell-template="commission_type-cell-template"/>                                                            
                                                    <DxColumn data-field="sales_channel_name" :caption="$t('Sales Channel')" header-cell-template="sales-channel-header" alignment="left" cell-template="sales_channel-cell-template"/>                                                               
                                                    <DxColumn data-field="stock_group_name" :caption="$t('Stock Group')" header-cell-template="stock-group-header" alignment="left" cell-template="stock_group-cell-template"/>                                                           
                                                    <DxColumn data-field="product_group_name" :caption="$t('Product Group')" header-cell-template="product-group-header" alignment="left" cell-template="product_group-cell-template"/>                                                             
                                                    <DxColumn data-field="product_type_name" :caption="$t('Product Type')" header-cell-template="product-type-header" alignment="left" cell-template="product_type-cell-template"/>                                              
                                                    <DxColumn data-field="price_type_name" :caption="$t('Price Type')" header-cell-template="price-type-header" alignment="left" cell-template="price_type-cell-template"/>                                   
                                                    <DxColumn data-field="payment_type_name" :caption="$t('Payment Method')" header-cell-template="payment-type-header" alignment="left" cell-template="payment_type-cell-template"/>                                                             
                                                    <DxColumn data-field="shipping_type_name" :caption="$t('Shipping Type')" header-cell-template="shipping-type-header" alignment="left" cell-template="shipping_type-cell-template"/>                                                            
                                                    <DxColumn data-field="discount" :sorting-method="this.$numericSort" :caption="$t('Discount %')" alignment="right" cell-template="discount-cell-template"/>

                                                    <template #customer-type-header>
                                                            <div>{{ $t('Customer') }}</div><div>{{ $t('Type') }}</div>
                                                    </template>
                                                    <template #sales-channel-header>
                                                            <div>{{ $t('Sales') }}</div><div>{{ $t('Channel') }}</div>
                                                    </template>
                                                    <template #stock-group-header>
                                                            <div>{{ $t('Stock') }}</div><div>{{ $t('Group') }}</div>
                                                    </template>
                                                    <template #product-group-header>
                                                            <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>  
                                                    </template>
                                                    <template #product-type-header>
                                                            <div>{{$t('Product')}}</div><div>{{ $t('Type') }}</div>
                                                    </template>
                                                    <template #price-type-header>
                                                            <div>{{ $t('Price') }}</div><div>{{ $t('Type') }}</div>
                                                    </template>
                                                    <template #payment-type-header>
                                                            <div>{{ $t('Payment') }}</div><div>{{ $t('Type') }}</div>
                                                    </template>
                                                    <template #shipping-type-header>
                                                            <div>{{ $t('Shipping') }}</div><div>{{ $t('Type') }}</div>
                                                    </template>

                                                    <template #is-selected-cell-template="{ data }"> 
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #commission_type-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.customer_type_name }}
                                                        </span>
                                                    </template>
                                                    <template #sales_channel-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.sales_channel_name}}
                                                        </span>
                                                    </template>
                                                    <template #stock_group-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.stock_group_name }}
                                                        </span>
                                                    </template>
                                                    <template #product_group-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.product_group_name }}
                                                        </span>
                                                    </template>
                                                    <template #product_type-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.product_type_name }}
                                                        </span>
                                                    </template>
                                                    <template #price_type-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.price_type_name }}
                                                        </span>
                                                    </template>
                                                    <template #payment_type-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.payment_type_name }}
                                                        </span>
                                                    </template>
                                                    <template #shipping_type-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.shipping_type_name }}
                                                        </span>
                                                    </template>
                                                    <template #discount-cell-template="{ data }"> 
                                                        <span> 
                                                            {{ data.data.discount }}
                                                        </span>
                                                    </template>
                                                </DxDataGrid>
                                            </div>
                                            <div class="row" v-if="campaignItemList && campaignItemList.length > 0">
                                                <div
                                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                                    <div class="dataTables_info" role="status" aria-live="polite">
                                                        {{ campaignItemList.length }} {{ $t('Records') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                    
                            </div>
                        </div> 
                    </div>
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                            
                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Description') }}</span>
                                        </label>
                                        <textarea class="form-control" name="description" v-model="form.description"></textarea>
                                    </div>
                                    <div class="fv-row mb-7">
                                        <div class="form-check mt-3">
                                        <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                        <label class="fs-6 fw-bold form-label" for="isDefault">
                                            {{ $t('Default') }}
                                        </label>
                                        </div> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary" @click="this.saveData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> {{ $t('Update Sales Role / Campaign') }} </strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                            </button>
                        </div>                 
                    </div>
                </div>
            </div><div class="card card-xxl-stretch tab-pane fade" id="kt_accounting_2" role="tabpanel">
                <div class="row p-10">
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300">
                            <div class="card-body p-5">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                    <div class="fv-row row">
                                        <div class="col-lg-6">
                                            <label class="fs-6 fw-bold form-label mt-3">
                                                <span>{{ $t('Accounting Plan') }}</span>
                                            </label>
                                            <div class="input-group mb-5">
                                                <input type="text" class="form-control"
                                                    name="company_name" v-model="form.accounting_plan">
                                                <span class="input-group-text m-0" id="basic-addon2">
                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }} 
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content"  >
                <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-11 text-white">
                        <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span> 
                        <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> {{ $t('Item') }}                                           
                    </h2>   
                    <div class="btn btn-icon btn-sm btn-active-icon-primary">
                        <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card card-flush">
                                <div class="card-body pt-0 pb-0">
                                    <form class="form">
                                        <div class="card-body border-top">
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Customer/Supplier Type') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.customer_type_id" :options="customerTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Sales Channel') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Stock Group') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.stock_group_id" :options="stockGroupList" @select="onChangeStockGroup()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Product Group') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.product_group_id" :options="productGroupList" @select="onChangeProductGroup()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Product Type') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.product_type_id" :options="productTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Price Type') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.price_type_id" :options="priceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Payment Method') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.payment_type_id" :options="paymentTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                                <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Shipping Type') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="campaignForm.shipping_type_id" :options="shippingTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content'}"/> 
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Discount') }} %</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <CurrencyInput type="text" class="form-control text-end" name="discount" v-model="this.campaignForm.discount" :precision="0" @focus="$event.target.select()" :min="0" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-flush mt-4" id="kt_contacts_list">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'"
                                        @click="this.createCampaignItem()" :disabled="this.isModalButtonDisabled">
                                        <span class="indicator-label">{{ $t('Add Item') }}</span> 
                                    </button>
                                    <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                        @click="this.editCampaignItem()" :disabled="this.isModalButtonDisabled">
                                        <span class="indicator-label">{{ $t('Update') }}</span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
</template>

<script>

import axios from 'axios' 
import Swal from 'sweetalert2';   
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
 
export default {
  name: "CampaignUpdate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel
  }, 
  data() {
    return {
      campaignItemList: [],
      salesChannelList:[],
      customerTypeList: [],  
      stockGroupList: [],
      productGroupList: [],
      productTypeList: [],
      priceTypeList: [],
      searchbar: '',
      isProgressing: false,
      form:{
        code: null,
        name: null,
        description: null,
        is_default: false,
        is_always: true,
        is_active: true,
        accounting_plan: null,
        start_date: moment(),
        end_date: moment().add(1, 'days'),
        payment_value: 0
      },
      campaignForm: {
        id: null,
        sales_channel_id: '-1',
        customer_type_id: '-1',
        stock_group_id: '-1',
        product_type_id: '-1',
        product_group_id: '-1',
        discount: 0.00,
        price_type_id: '-1',
        payment_type_id: '-1',
        shipping_type_id: '-1'
      },
      modalType: 'addItem',
      isItemSaving: false,
      isModalButtonDisabled: false,
      activeTab: 'inputForm',
      menuSelection: '/SalesRoleHelppage',
      iconView : false,
      pageSize: 10,
      pageIndex: 0,
    };
  }, 
  computed: {
    searchableList() {
      return this.campaignItemList.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      });
    },
   isItemButtonDisabled() {
       return this.campaignItemList.find(item => item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.campaignItemList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.campaignItemList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {        
        getData() {
            const params = this.form; 
            this.$setSessionStorage('refreshList', true);
            const queryParameter = {
                id: this.$getSessionStorage('record_id'),
                session_id: this.$getUser().session_id
            };
            if(params.sales_channel == null ){ params.branch_id = '-1';}
            axios.post('/api/Campaign/GetCampaign', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => {     
                this.form = { 
                    id: response.data.campaign_header.id, 
                    code: response.data.campaign_header.code,
                    name: response.data.campaign_header.name,
                    description: response.data.campaign_header.description,
                    is_default: response.data.campaign_header.is_default,
                    start_date: response.data.campaign_header.start_date,
                    end_date: response.data.campaign_header.end_date,
                    is_always: response.data.campaign_header.is_always,
                    is_active: response.data.campaign_header.is_active,
                    payment_value: response.data.campaign_header.payment_value
                };  
                this.form.is_default = response.data.campaign_header.is_default == 1 ? true : false;
                this.form.is_always = response.data.campaign_header.is_always == 1 ? true : false;
                this.form.is_active = response.data.campaign_header.is_active == 1 ? true : false;
                this.form.start_date = response.data.campaign_header.start_date == null ? moment() : response.data.campaign_header.start_date;
                this.form.end_date = response.data.campaign_header.end_date == null ? moment().add(1, 'days') : response.data.campaign_header.end_date;

                this.campaignItemList = response.data.campaign_items;
                if(this.campaignItemList.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.campaign_items[0].tmp_session_id);
                }
                const parameters = { 
                    session_id: this.$getUser().session_id,
                    list_name: ["sales_channel", "product_group", "product_type"],
                    account_type: 6 // yalnızca account settings listesi çekilecek ise gerekli
                }; 
                axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
                    this.salesChannelList = response.data.sales_channel;
                    this.productGroupList = response.data.product_group;
                    this.productGroupList.unshift({ id: '-1', text: 'ALL'});
                    this.salesChannelList.unshift({ id: '-1', text: 'ALL'});

                    const params = {
                        session_id: this.$getUser().session_id,
                        list_types: [
                            {
                                list_type: 6,
                                list_name: "stock_group"
                            },
                            {
                                list_type: 7,
                                list_name: "product_group"
                            },
                            {
                                list_type: 11,
                                list_name: "payment_type"
                            },
                            {
                                list_type: 12,
                                list_name: "shipping"
                            },
                            {
                                list_type: 13,
                                list_name: "price_type"
                            },
                            {
                                list_type: 14,
                                list_name: "customer_type"
                            },
                        ],
                    };
                    axios.post("/api/Helper/GetMultiLists", params, {'Content-Type': 'application/json'}).then((listResponse) => {
                        this.stockGroupList = listResponse.data.stock_group; 
                        this.priceTypeList = listResponse.data.price_type;  
                        this.customerTypeList = listResponse.data.customer_type;
                        this.paymentTypeList = listResponse.data.payment_type;
                        this.shippingTypeList = listResponse.data.shipping;
                        this.stockGroupList.unshift({ id: '-1', text: 'ALL'}); 
                        this.customerTypeList.unshift({ id: '-1', text: 'ALL'});
                        this.priceTypeList.unshift({ id: '-1', text: 'ALL'});
                        this.paymentTypeList.unshift({ id: '-1', text: 'ALL'});
                        this.shippingTypeList.unshift({ id: '-1', text: 'ALL'});
                        const params = {
                            session_id: this.$getUser().session_id,
                            list_type: 16, // Product Type: 16
                            parent_id: this.campaignForm.product_group_id
                        };
                        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((campaignItemResponse) => { 
                                this.productTypeList = campaignItemResponse.data;
                                this.productTypeList.unshift({ id: '-1', text: 'Select an option'});
                            this.$Progress.finish();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$Progress.finish();
                        });
                    })
                    .catch(function (error) {
                        console.log(error); 
                        this.$Progress.finish();
                    });
                })
                .catch(function (error) {
                    console.log(error); 
                    this.$Progress.finish();
                });       
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            });  
        },
        openItemModal(value) {
            try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}
        
            this.modalType = value;
            this.isItemSaving = false;
            this.isModalButtonDisabled = false;
            this.productTypeList = [{ id: '-1', text: 'ALL'}];
            this.campaignForm.sales_channel_id = '-1';
            this.campaignForm.customer_type_id = '-1';
            this.campaignForm.stock_group_id = '-1';
            this.campaignForm.product_group_id = '-1';
            this.campaignForm.product_type_id = '-1';
            this.campaignForm.price_type_id = '-1';
            this.campaignForm.payment_type_id = '-1';
            this.campaignForm.shipping_type_id = '-1';
            this.campaignForm.discount = 0.00;

            if (this.modalType == 'editItem') {                        
                this.campaignItemList.forEach((item) => {
                    if (item.isSelected) {
                        this.campaignForm.id = item.id;                    
                        this.campaignForm.sales_channel_id = (item.sales_channel_id == null || item.sales_channel_id == '') ? '-1' : item.sales_channel_id;
                        this.campaignForm.customer_type_id = (item.customer_type_id == null || item.customer_type_id == '') ? '-1' : item.customer_type_id;
                        this.campaignForm.stock_group_id = (item.stock_group_id == null || item.stock_group_id == '') ? '-1' : item.stock_group_id;
                        this.campaignForm.product_group_id = (item.product_group_id == null || item.product_group_id == '') ? '-1' : item.product_group_id;
                        this.campaignForm.product_type_id = (item.product_type_id == null || item.product_type_id == '') ? '-1' : item.product_type_id;
                        this.campaignForm.price_type_id = (item.price_type_id == null || item.price_type_id == '') ? '-1' : item.price_type_id;
                        this.campaignForm.payment_type_id = (item.payment_type_id == null || item.payment_type_id == '') ? '-1' : item.payment_type_id;
                        this.campaignForm.shipping_type_id = (item.shipping_type_id == null || item.shipping_type_id == '') ? '-1' : item.shipping_type_id;
 
                        this.campaignForm.discount = item.discount.replace(/,/g, '');

                        const params = {
                            session_id: this.$getUser().session_id,
                            list_type: 16, // Product Type: 16
                            parent_id: this.campaignForm.product_group_id
                        };
                        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                    this.productTypeList = response.data;
                    this.productTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.$Progress.finish();
                })
                    }
                }); 
            } else {            
                this.salesChannelList.forEach((value) => {
                    if(value.is_default){
                        this.campaignForm.sales_channel_id = value.id;
                    }
                });  
            }
        },
        onSelectItem(item) {
            this.campaignItemList.forEach((value) => { 
                if (item.id !== value.id) {
                    value.isSelected = false;
                }
            });
        },
        saveData(){
            this.isProgressing = true;
            const params = {
                id: this.$getSessionStorage('record_id'),
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                code: this.form.code,
                name: this.form.name,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                payment_value: this.form.payment_value,
                description: this.form.description == null ? '' : this.form.description,
                branch_id: this.form.branch_id == -1 ? null : this.form.branch_id, 
                is_default: this.form.is_default == true ? 1 : 0,
                is_always: this.form.is_always == true ? 1 : 0,
                is_active: this.form.is_active == true ? 1 : 0
            };  
            const that = this;   
            if(params.is_always == 1){
                params.start_date = null;
                params.end_date = null;
            }
            else{
                params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');  
            } 
            axios.post('api/Campaign/UpdateCampaign', params, {'Content-Type': 'application/json'})
            .then((response) => {  
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    this.$swal("Success", "Data saved succesfully..!", 'success');   
                    this.$setSessionStorage('refreshList', true);
                }
            })
            .catch(function () { 
                new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            });
        },
        deleteItem() { 
            this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
            }).then((result) => { 
            if (result.isConfirmed) {
                const queryParameter = {
                    id: this.$getSessionStorage('record_id'),
                    session_id: this.$getUser().session_id,
                };
                axios.post("/api/Campaign/DeleteCampaign", queryParameter, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                            this.$setSessionStorage('refreshList', true);
                            this.$router.push({ name: 'CampaignList' });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");
                    });
            }
            })  
        },
        onChangeProductGroup() {
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 16, // Product Type: 16
                parent_id: this.campaignForm.product_group_id
            };
            axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((campaignItemResponse) => { 
            this.productTypeList = campaignItemResponse.data;
            this.productTypeList.unshift({ id: '-1', text: 'Select an option'});
            this.form.product_type_id = this.productTypeList[0].id;
            this.productTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.product_type_id = value.id;
                }
            });
            this.$Progress.finish();
            })
        },
        createCampaignItem(){ 
            const params = this.campaignForm; 
            this.isModalButtonDisabled = true;
            this.isItemSaving = true;
            const that = this;
            params.session_id = this.$getUser().session_id; 
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id'); 
            params.campaign_id = this.$getSessionStorage('record_id');
            params.sales_channel_id = params.sales_channel_id == '-1'?  null : params.sales_channel_id;
            params.customer_type_id = params.customer_type_id == '-1'?  null : params.customer_type_id;
            params.stock_group_id = params.stock_group_id == '-1'?  null : params.stock_group_id;
            params.product_group_id = params.product_group_id == '-1'?  null : params.product_group_id;
            params.product_type_id = params.product_type_id == '-1'?  null : params.product_type_id;
            params.price_type_id = params.price_type_id == '-1'?  null : params.price_type_id;
            params.payment_type_id = params.payment_type_id == '-1'?  null : params.payment_type_id;
            params.shipping_type_id = params.shipping_type_id == '-1'?  null : params.shipping_type_id;
            
            axios.post('/api/Campaign/AddCampaignItem', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.campaignItemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click();
                } 
                this.isItemSaving = false;
            })
            .catch(function () { 
                this.isItemSaving = false;
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                    that.isModalButtonDisabled = false;
                }, 500);
            });
        },
        editCampaignItem(){  
            const params = this.campaignForm; 
            this.isModalButtonDisabled = true;
            this.isItemSaving = true;
            const that = this;
            params.session_id = this.$getUser().session_id; 
            params.tmp_session_id = this.$getSessionStorage('tmp_session_id'); 
            params.campaign_id = this.$getSessionStorage('record_id');
            params.sales_channel_id = params.sales_channel_id == '-1'?  null : params.sales_channel_id;
            params.customer_type_id = params.customer_type_id == '-1'?  null : params.customer_type_id;
            params.stock_group_id = params.stock_group_id == '-1'?  null : params.stock_group_id;
            params.product_group_id = params.product_group_id == '-1'?  null : params.product_group_id;
            params.product_type_id = params.product_type_id == '-1'?  null : params.product_type_id;
            params.price_type_id = params.price_type_id == '-1'?  null : params.price_type_id; 
            params.payment_type_id = params.payment_type_id == '-1'?  null : params.payment_type_id;
            params.shipping_type_id = params.shipping_type_id == '-1'?  null : params.shipping_type_id;
            
            axios.post('/api/Campaign/UpdateCampaignItem', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    this.campaignItemList = response.data;
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                    this.$refs.closeModal.click(); 
                } 
                this.isItemSaving = false;
            })
            .catch(function () { 
                this.isItemSaving = false;                
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isItemSaving = false;
                    that.isModalButtonDisabled = false;
                }, 500);
            });
        },
        deleteCampaignItem(){ 
            this.$swal.fire({
                title: "Are you sure you want to delete this data?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.campaignItemList.forEach((item) => {
                    if(item.isSelected) {
                        const params = {
                            id: item.id,
                            session_id: this.$getUser().session_id,
                            tmp_session_id: this.$getSessionStorage('tmp_session_id')
                        };
                        axios.post('/api/Campaign/DeleteCampaignItem', params, {'Content-Type': 'application/json'})
                        .then((response) => { 
                            if(response.data != null){  
                                this.campaignItemList = response.data;
                                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                                this.$swal("Deleted", "Selected item deleted..!", 'warning');    
                                this.$refs.closeModal.click(); 
                            } 
                        })
                        .catch(function () { 
                            
                        }); 
                    }
                  });
                }
            }); 
        },
        deleteAllCampaignItems(){
            this.$swal.fire({
                title: "Are you sure you want to delete all items?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
            }).then((result) => {
                if (result.isConfirmed) { 
                    const params = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('tmp_session_id')
                    };
                    axios.post('/api/Campaign/DeleteAllCampaignItems', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data != null){  
                            this.campaignItemList = [];
                            this.$swal("Deleted", "All items deleted..!", 'warning');    
                            this.$refs.closeModal.click(); 
                        } 
                    })
                    .catch(function () { 
                        
                    });  
                } 
            }); 
        },
        onChangePaymentValue() {
            if (parseInt(this.form.payment_value) > 0) {
                this.form.end_date = moment(this.form.start_date, "YYYY-MM-DD").add(parseInt(this.form.payment_value), 'days');
            }
        },
        async helpPageViewIcon(){
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/SalesRoleHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        },
    }, 
    created() {
        this.helpPageViewIcon();
        this.$setSessionStorage('refreshList', true);
        this.getData();
    }
}; 
</script>