<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Costs') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>      
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Costs') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>   
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div class="fv-row mb-7 row">
                                        <div class="col-lg-6">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Cost Of Sales') }}
                                            </label>
                                            <Select2 v-model="form.cost_of_sales" :options="costOfSalesList" :settings="{ width: '100%' }"/> 
                                        </div>
                                        <div class="col-lg-6">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('‘Cost Of Sales’ Method') }}
                                            </label>
                                            <Select2 v-model="form.cost_of_sales_method" :options="costOfSalesMethodList" :settings="{ width: '100%' }"/> 
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form"
                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div class="fv-row mb-7 row">
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                Cost Of Production 
                                            </label>
                                            <Select2 v-model="form.cost_of_production" :options="costOfProductionList" /> 
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                ‘Cost Of Production’ Method
                                            </label>
                                            <Select2 v-model="form.cost_of_production_method" :options="costOfProductionMethodList" /> 
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-12">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span> 
                                <strong v-if="!this.isProgressing"> {{ $t('Save Cost Method') }}</strong> 
                                    <span class="indicator-progress" v-if="this.isProgressing">
                                        {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios' 
// import Swal from "sweetalert2";
 
export default {
  name: "CostSettings", 
  data() {
    return {
      searchbar: '',
      branchList: [],
      isProgressing: false,
      form:{
        cost_of_sales: 1,
        cost_of_sales_method: 1,
        cost_of_production: 1,
        cost_of_production_method: 1,
      },
      costOfSalesList: [
        { id: 1, text: 'Apply'},
        { id: 2, text: 'Not Apply'},
      ],
      costOfSalesMethodList: [
        { id: 1, text: 'First In First Out'},
        { id: 2, text: 'Moving Weighted Average Cost'}, /* hareketli ağırlıklı ortalama maliyet */
      ],
      costOfProductionList: [
        { id: 1, text: 'Apply'},
        { id: 2, text: 'Not Apply'},
      ],
      costOfProductionMethodList: [
        { id: 1, text: 'First In First Out'},
        { id: 2, text: 'Moving Weighted Average Cost'}, /* hareketli ağırlıklı ortalama maliyet */
      ],
      menuSelection: '/CostsManagementHelppage',
      iconView : false
    };
  },
  methods: {
    getData() { 
      const params = {
        session_id: this.$getUser().session_id
      };
      axios.post('/api/CostsSettings/GetCostSettings', params, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.form = { 
              id: response.data.id,
              cost_of_sales: response.data.cost_of_sales,
              cost_of_sales_method: response.data.cost_of_sales_method,
              cost_of_production: response.data.cost_of_production,
              cost_of_production_method: response.data.cost_of_production_method,
            };   
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form;
      params.session_id = this.$getUser().session_id;
      const that = this;     
      axios.post('/api/CostsSettings/UpdateCostSettings', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
            }
            
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
      },
      async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/CostsManagementHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
        }, 
    }, 
    created() {
        this.helpPageViewIcon();
        this.getData();
    }
}; 
</script>