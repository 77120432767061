<template> 
    <div>        
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Delete Multiple Policies') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'MultiDeleteOperations' }">Multi Delete Operations</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">{{ $t('Delete Multiple Policies') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/MultiDeleteOperations">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Multiple Deletions') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content"> 
            <div class="card">
                <div class="card-body pt-6">
                    <div class="row px-0">
                        <div class="col-lg-9">
                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework rounded-2">
                                <div class="fv-row mb-7 row">
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Date Type') }}</span>
                                        </label>
                                        <Select2 v-model="form.date_type" :options="dateTypeList" :settings="{width: '100%' }" @select="onChangeStartDate()" name="date_type" v-custom />
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Start Date') }}</span>
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" @update:modelValue="onChangeStartDate()"></Datepicker>  
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('End Date') }}</span>
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Producer') }}</span>
                                        </label>
                                        <Select2 v-model="form.main_producer_id" :options="producerList" :settings="{width: '100%' }" name="main_producer_id" v-custom/>
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Main Branch') }}</span>
                                        </label>
                                        <Select2 v-model="form.main_branch_id" :options="mainBranchList" @select="getPolicyParentList()" :settings="{width: '100%' }" name="main_branch_id" v-custom/>
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Sub Branch') }}</span>
                                        </label>
                                        <Select2 v-model="form.policy_branch_id" :options="policyBranchList" :settings="{width: '100%' }" name="policy_branch_id" v-custom/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-3">                        
                            <div class="d-flex justify-content-end align-items-center mt-8" data-kt-customer-table-toolbar="base"> 
                                <Popper hover placement="bottom">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                    </template>
                                </Popper>
                                <button type="button" class="btn btn-light-primary border border-primary" @click="getAllPolicies()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <span v-if="!this.isListProgressing"> {{ $t('List Policies') }}</span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                                </button>
                                <button type="button" class="btn border-gray-400 btn-danger btn-active-warning ms-3" :disabled="this.selectedRowKeys.length == 0"
                                @click="deleteItem()">
                                    <span class="svg-icon svg-icon-muted">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M19.5997 3.52344H2.39639C2.09618 3.53047 1.8003 3.59658 1.52565 3.718C1.25101 3.83941 1.00298 4.01375 0.79573 4.23106C0.588484 4.44837 0.426087 4.70438 0.317815 4.98447C0.209544 5.26456 0.157521 5.56324 0.164719 5.86344C0.157521 6.16364 0.209544 6.46232 0.317815 6.74241C0.426087 7.0225 0.588484 7.27851 0.79573 7.49581C1.00298 7.71312 1.25101 7.88746 1.52565 8.00888C1.8003 8.1303 2.09618 8.19641 2.39639 8.20344H19.5997C19.8999 8.19641 20.1958 8.1303 20.4704 8.00888C20.7451 7.88746 20.9931 7.71312 21.2004 7.49581C21.4076 7.27851 21.57 7.0225 21.6783 6.74241C21.7866 6.46232 21.8386 6.16364 21.8314 5.86344C21.8386 5.56324 21.7866 5.26456 21.6783 4.98447C21.57 4.70438 21.4076 4.44837 21.2004 4.23106C20.9931 4.01375 20.7451 3.83941 20.4704 3.718C20.1958 3.59658 19.8999 3.53047 19.5997 3.52344Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M2.39453 8.20361L4.01953 18.3111C4.15644 19.145 4.58173 19.9043 5.22121 20.4567C5.8607 21.009 6.6738 21.3194 7.5187 21.3336H14.5712C15.4215 21.3202 16.2395 21.006 16.8801 20.4468C17.5207 19.8875 17.9424 19.1193 18.0704 18.2786L19.5979 8.20361H2.39453ZM9.28453 16.3178C9.28453 16.5333 9.19893 16.7399 9.04656 16.8923C8.89418 17.0447 8.68752 17.1303 8.47203 17.1303C8.25654 17.1303 8.04988 17.0447 7.89751 16.8923C7.74513 16.7399 7.65953 16.5333 7.65953 16.3178V12.4069C7.65953 12.1915 7.74513 11.9848 7.89751 11.8324C8.04988 11.68 8.25654 11.5944 8.47203 11.5944C8.68752 11.5944 8.89418 11.68 9.04656 11.8324C9.19893 11.9848 9.28453 12.1915 9.28453 12.4069V16.3178ZM14.322 16.3178C14.322 16.5333 14.2364 16.7399 14.0841 16.8923C13.9317 17.0447 13.725 17.1303 13.5095 17.1303C13.294 17.1303 13.0874 17.0447 12.935 16.8923C12.7826 16.7399 12.697 16.5333 12.697 16.3178V12.4069C12.697 12.1915 12.7826 11.9848 12.935 11.8324C13.0874 11.68 13.294 11.5944 13.5095 11.5944C13.725 11.5944 13.9317 11.68 14.0841 11.8324C14.2364 11.9848 14.322 12.1915 14.322 12.4069V16.3178Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M17.3895 4.87755C17.2529 4.87776 17.1185 4.84303 16.999 4.77667C16.8796 4.71031 16.7791 4.61452 16.707 4.49839L14.5945 1.24839C14.488 1.07063 14.4544 0.858502 14.5009 0.656521C14.5473 0.45454 14.6702 0.2784 14.8437 0.165055C15.0215 0.0626479 15.2311 0.0303209 15.4315 0.0744071C15.6319 0.118493 15.8086 0.235816 15.927 0.403388L18.0395 3.70755C18.1434 3.88599 18.1755 4.09728 18.1292 4.2985C18.0829 4.49972 17.9618 4.67577 17.7904 4.79089C17.6659 4.85225 17.5282 4.88202 17.3895 4.87755Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M4.49988 4.8885C4.34679 4.8928 4.19591 4.85131 4.06655 4.76933C3.89514 4.65422 3.77399 4.47817 3.72771 4.27694C3.68143 4.07572 3.71349 3.86443 3.81738 3.686L5.98405 0.435999C6.09739 0.262485 6.27353 0.13961 6.47551 0.0931545C6.6775 0.0466989 6.88962 0.0802727 7.06738 0.186832C7.23676 0.303623 7.35627 0.479597 7.40239 0.680101C7.4485 0.880606 7.41788 1.09111 7.31655 1.27017L5.20405 4.52017C5.12881 4.63747 5.0243 4.73313 4.90082 4.79773C4.77733 4.86232 4.63914 4.8936 4.49988 4.8885Z" fill="currentColor" fill-opacity="0.8"/>
                                        </svg>
                                    </span>
                                    {{ $t('Delete') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="separator my-5 mt-0 border-gray-300"></div>
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">
                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" ref="dxDataGridRef"
                                    :data-source="policyList" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true"
                                    :v-model:selected-row-keys="selectedRowKeys"
                                    @selection-changed="onSelectionChanged"
                                    @exporting="this.$onExporting($event , 'Policy-list')"
                                    :customize-columns="onCustomizeTransactionColumns"> 

                                    <DxSelection mode="multiple" select-all-mode="allPages" show-check-boxes-mode="always"/>
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="1000"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                    <DxColumn data-field="regulation_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Regulation Date')" alignment="right" header-cell-template="regulation_date-header" cell-template="regulation_date-cell-template"/> 
                                    <DxColumn data-field="start_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Start Date')" alignment="right" header-cell-template="start_date-header" cell-template="start_date-cell-template"/> 
                                    <DxColumn data-field="end_date" data-type="date" format="dd.MM.yyyy" :caption="$t('End Date')" alignment="right" header-cell-template="end_date-header" cell-template="end_date-cell-template"/> 
                                    <DxColumn data-field="account_name" :caption="$t('Account')"/>   
                                    <DxColumn data-field="insurance_type_name" :caption="$t('Insurance Type')" header-cell-template="insurance_type-header"/> 
                                    <DxColumn data-field="main_branch_name" :caption="$t('Policy Main Branch')" header-cell-template="main_branch_name-header"/> 
                                    <DxColumn data-field="policy_branch_name" :caption="$t('Policy Sub Branch')" header-cell-template="policy_branch_name-header"/> 
                                    <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header"/> 
                                    <DxColumn data-field="renewal_no" :caption="$t('Renewal No')" header-cell-template="renewal_no-header"/> 
                                    <DxColumn data-field="endorsement_no" :caption="$t('Endorsement No')" header-cell-template="endorsement_no-header"/> 
                                    <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header"/>
                                    <DxColumn data-field="is_new_or_renewal" :caption="$t('New/Renewal')" header-cell-template="is_new_or_renewal-header"/> 
                                    <DxColumn
                                        alignment="center"
                                        :caption="$t('Endorsement')"
                                        :fixed="false"
                                        fixed-position="right">
                                        <DxColumn data-field="endorsement_type_name" :caption="$t('Type')"/> 
                                        <DxColumn data-field="endorsement_detail_name" :caption="$t('Detail')"/> 
                                    </DxColumn>
                                    <DxColumn
                                        alignment="center"
                                        :caption="$t('Producer')"
                                        :fixed="false"
                                        fixed-position="right">
                                        <DxColumn data-field="producer_name" :caption="$t('Name')"/> 
                                        <DxColumn data-field="producer_policy_type_name" :caption="$t('Policy Type')"/> 
                                    </DxColumn>
                                    <DxToolbar>
                                        <DxItem location="before">
                                        <DxButton
                                            :disabled="selectedRowKeys.length == 0"
                                            :text="'Deselect All' + ' ('+ selectedRowKeys.length + ')'"
                                            @click="clearSelection"
                                        />
                                        </DxItem>
                                    </DxToolbar>

                                    <template #headerLocalTemplate="{ data: data }">
                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                    </template>
                                    <template #policy_type_name-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('Type') }}</div>
                                    </template> 
                                    <template #is_new_or_renewal-header>
                                        <div>{{ $t('New/') }}</div><div>{{ $t('Renewal') }}</div>
                                    </template> 
                                    <template #regulation_date-header>
                                        <div>{{ $t('Regulation') }}</div><div>{{ $t('Date') }}</div>
                                    </template> 
                                    <template #start_date-header>
                                        <div>{{ $t('Start') }}</div><div>{{ $t('Date') }}</div>
                                    </template> 
                                    <template #end_date-header>
                                        <div>{{ $t('End') }}</div><div>{{ $t('Date') }}</div>
                                    </template> 
                                    <template #policy_no-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #endorsement_no-header>
                                        <div>{{ $t('Endorsement') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #renewal_no-header>
                                        <div>{{ $t('Renewal') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #insurance_type-header>
                                        <div>{{ $t('Insurance') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #main_branch_name-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('Main Branch') }}</div>
                                    </template>
                                    <template #policy_branch_name-header>
                                        <div>{{ $t('Policy') }}</div><div>{{ $t('Sub Branch') }}</div>
                                    </template>

                                    <template #is-selected-cell-template="{ data }">
                                        <span>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                            </div>
                                        </span>
                                    </template>
                                    <template #regulation_date-cell-template="{ data }">
                                        <span class="align-middle text-gray-700 text-end">
                                            {{previewDate(data.data.regulation_date)}}
                                        </span>   
                                    </template>
                                    <template #start_date-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            {{previewDate(data.data.start_date) }}
                                        </span>   
                                    </template>
                                    <template #end_date-cell-template="{ data }">
                                        <span class="align-middle text-gray-600 text-end">
                                            {{previewDate(data.data.end_date)}}
                                        </span>   
                                    </template>
                            </DxDataGrid>  
                        </div>
                    </div> 
                </div>
            </div> 
        </div>
    </div>  
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxScrolling } from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';

import DataGrid from "devextreme/ui/data_grid";

DataGrid.defaultOptions({ 
    options: {
        scrolling: {
            legacyMode: true
        }
    }
});

export default {
    name: "MultiPolicyDelete",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxButton, DxScrolling
    },
    data() {
        return {
            pageSize: 20,
            isListProgressing: false,
            policyList:[],
            form:  {
                start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
                end_date: moment(),
                date_type: 1,
                main_branch_id: '-1',
                policy_branch_id: '-1',
                main_producer_id: '-1',
                account_name: null,            
            },
            mainBranchList: [{ id: '-1', text: 'ALL'}],
            policyBranchList: [{ id: '-1', text: 'ALL'}],
            producerList: [{ id: '-1', text: 'ALL'}],
            dateTypeList: [
                { id: 1, text: 'Regulation'},
                { id: 2, text: 'Start/End'},
            ],
            selectedRowKeys: [],
            menuSelection: '/DeleteMultiplePoliciesHelppage',
            iconView : false, 
        };
    },
    computed: {
        showPageSizeSelector() {
            return this.policyList.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = (this.policyList.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 1) arr.push('all');
            return arr;
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        }
    },
    methods: {
        getData(){
            const parameters = {
                session_id: this.$getUser().session_id,            
                supplierAccounts: [{account_type: 1, account_name: "producer"}],
            };
            const parametersTwo = {
                session_id: this.$getUser().session_id,
                list_type: 1 
            };
            const requestOne =  axios.post('/api/GetCompanyAccounts/GetMultiPolicyAccounts', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GetPolicyLists', parametersTwo, {'Content-Type': 'application/json'});            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.producerList = responses[0].data.producer;
                this.producerList.unshift({ id: '-1', text: 'ALL'});
                this.mainBranchList= responses[1].data;
                this.mainBranchList.unshift({ id: '-1', text: 'ALL'});
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getAllPolicies() {
            this.isListProgressing = true;
            this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
            this.form.end_date = (this.form.end_date == null || this.form.end_date == '') ? moment() : this.form.end_date;
            const params = {
                session_id: this.$getUser().session_id,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                date_type: this.form.date_type,
                main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
                policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id,
                main_producer_id: this.form.main_producer_id !== '-1' ? this.form.main_producer_id : null
            };
            if(params.main_producer_id == '-1') { params.main_producer_id = null }
            params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
            params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
            axios.post('api/Insurance/GetPolicyTransactionAll', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.policyList = response.data;
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(function () {
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        },
        deleteItem() {
            this.$swal.fire({
                title: "Are you sure you want to delete this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const selectedItems = [];
                    this.selectedRowsData.forEach(element => {
                        selectedItems.push({id: element.id , policy_card_id: element.policy_card_id});
                    });
                    const params = { 
                        session_id: this.$getUser().session_id,
                        policyIds: selectedItems
                    };
                    axios.post('api/Insurance/DeletePolicyTransactionAll', params, {'Content-Type': 'application/json'})
                    .then(() => {
                        this.getAllPolicies();
                    });
                }
            });
        },
        onSelectionChanged({selectedRowKeys, selectedRowsData }) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRowsData = selectedRowsData;
        },
        clearSelection () {
            const dataGrid = this.$refs["dxDataGridRef"].instance;
            dataGrid.clearSelection();
        },
        onChangeStartDate() {
            if(this.form.date_type == 2) {
                this.form.end_date = moment(this.form.start_date).add(1, 'year');
            } else {
                this.form.end_date = moment();
            }
        },
        getPolicyParentList() {
            this.form.policy_branch_id = '-1';
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 2, // Policy Branch: 2 
                parent_id: this.form.main_branch_id
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.policyBranchList = response.data;
                this.policyBranchList.unshift({ id: '-1', text: 'ALL'});
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        setCriteriasToDefault() {   
            this.form = {
                start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
                end_date: moment(),
                date_type: 1,
                main_branch_id: '-1',
                policy_branch_id: '-1',
                main_producer_id: '-1',
            };
            this.policyList = [];
        },
        async helpPageViewIcon() {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/DeleteMultiplePoliciesHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
    },
    created() {
        this.getData();
        this.helpPageViewIcon();
    }
  }; 
</script>