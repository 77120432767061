<template>
  <div v-cloak>
    <div v-if="$route.matched.some(({ name }) => (name === 'Login' || name === 'Logout' || name === 'HelpPage' || name === 'ScreenLock'
      || name === 'ResetPassword' || name === 'ForgotPassword' || name === 'VerifyAccount' || name === 'NewCompany')) == false">
      <div>  
        <vue-progress-bar v-if="!isScreenLocked"></vue-progress-bar>
        <nav-header v-show="!isScreenLocked && userData !== null && userData !== '' && userData !== undefined"></nav-header> 
        <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start mt-4 min-vh-100"
          :class="{ 'container-xxl': !$route.matched.some(m => m.meta.fullwidth ), 'px-7 py-2': $route.matched.some(m => m.meta.fullwidth ), 'bg-transparent-logo': $route.matched.some(m => m.name == 'Home') && !isScreenLocked }" >
          <div class="content flex-row-fluid" id="kt_content">
            <div class="preloader vh-100" v-if="show">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <router-view  v-show="!isScreenLocked" />
            <screen-lock v-if="isScreenLocked" :inactiveTime="screenLockTime"></screen-lock>
          </div>
        </div>
        <drawer v-if="!isScreenLocked && userData !== null && userData !== '' && userData !== undefined"></drawer>
        <nav-footer :class="{'d-none': isScreenLocked }" v-if="userData !== null && userData !== '' && userData !== undefined"></nav-footer>
      </div>
    </div>
    <div class="d-flex flex-column flex-root" v-if="!isScreenLocked && $route.matched.some(({ name }) => (name === 'Login' || name === 'Logout' || name === 'HelpPage' || name === 'ScreenLock'
      || name === 'ResetPassword' || name === 'ForgotPassword' || name === 'VerifyAccount' || name === 'NewCompany'))">
      <router-view />
    </div>
  </div>
</template>   
<script>
import NavHeader from "./components/parts/NavHeader.vue";
import Drawer from "./components/parts/Drawer.vue";
import NavFooter from "./components/parts/NavFooter.vue";
import ScreenLock from './components/ScreenLock.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import themes from "devextreme/ui/themes";

export default {
  name: "App",
  components: {
    NavHeader,
    Drawer,
    NavFooter,
    ScreenLock    
  },
  data() {
    return {
      show: false,
      userData: this.$getUser(),
      isScreenLocked: false,
      screenLockTime: this.$getUser().lock_screen_time,
      custom_head_code: '',
      custom_body_code: ''
    }
  },
  methods: {
    getSEO() {
      const params = {
        session_id : this.$getUser().session_id
      };
      axios.post('/api/GeneralUserParameters/GetSEO', params, {'Content-Type' : 'application/json'})
      .then((response) => {
          if ((response.data.error == null || response.data.error == 'undefined' || response.data.error == '') && response.data.active_mode == 1) {
              this.custom_head_code = response.data.custom_head_code; 
              this.custom_body_code = response.data.custom_body_code;
              this.insertSEOCode(this.custom_head_code, this.custom_body_code);
          }
        })
        .catch((error) => {
          console.error('Error fetching SEO code:', error);
        });
    },

    insertSEOCode(custom_head_code, custom_body_code) {
      if (custom_head_code) {
        try {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = custom_head_code;
          tempDiv.querySelectorAll('script').forEach(script => {
            const newScript = document.createElement('script');
            Array.from(script.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
            newScript.innerHTML = script.innerHTML;
            document.head.appendChild(newScript);
          });
        } catch (error) {
          console.error('Error executing SEO code', error);
        }
      } else {
        console.error('SEO head code is empty');
      }

      if (custom_body_code) {
        try {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = custom_body_code;
            const scripts = Array.from(tempDiv.querySelectorAll('script'));
            const otherElements = Array.from(tempDiv.childNodes).filter(node => node.tagName !== 'SCRIPT');
            scripts.forEach(script => {
                const newScript = document.createElement('script');
                Array.from(script.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
                newScript.innerHTML = script.innerHTML;
                document.body.appendChild(newScript);
            });
            otherElements.forEach(element => document.body.appendChild(element));
        } catch (error) {
            console.error('Error executing SEO code', error);
        }
      } else {
          console.error('SEO head code is empty');
      }
    },
  },
  mounted() {
    window.axios = require('axios');
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';      
    window.axios.interceptors.request.use( (config) => {
      if(this.$getUser().session_id !== undefined && this.$getUser().session_id !== null && this.$getUser().session_id !== '') {
        if(this.$getUser().is_locked == 1 && config.url.includes('ContinueToSession') == false && config.url.includes('LockSession') == false){
          return;
        }
      }

      // Bu kısma Axios isteklerinin preloadersız/silent bir şekilde çalışmasını istediğimiz hariç tutulan durumları yazıyoruz.
      var value = true;
      if(config.url.includes('DocumentLocation') || config.url.includes('Chatbi') || config.url.includes('SaveReadyPolicies')){
        value = false;
      }
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
          detail: value
      }));
      return config
    }, function (error) {
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
          detail: false
      }));
      return Promise.reject(error)
    })  
    window.axios.interceptors.response.use((value) => {
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
          detail: false
      }));

      return value
    }, function (error) {
      if (error.includes('429')) {
          Swal.fire('Info','You made too many requests','warning');
      }
      // create and dispatch the event
      window.dispatchEvent(new CustomEvent("isAxiosRequesting", {
          detail: false
      }));

      return Promise.reject(error)
    })
    window.addEventListener('isAxiosRequesting',  (event) => {
      this.show = event.detail;
    })
    if(this.$getUser().session_id !== null && this.$getUser().session_id !== '' && this.$getUser().session_id !== undefined){     
      if(this.$getUser().is_locked == 1){
        this.isScreenLocked = true;
      }
    }

     try{
        if(localStorage.getItem('i18nextLng') !== 'en-GB' && localStorage.getItem('i18nextLngActive') == 'true'){
            axios.post('/api/Auth/GetLocalizationData', { language_code: localStorage.getItem('i18nextLng') }, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){  
                  this.$i18next.init({ resources: {} });
                  this.$i18next.addResourceBundle(localStorage.getItem('i18nextLng'), 'translation', response.data );
                  this.$i18next.reloadResources();
                  this.$i18next.changeLanguage(localStorage.getItem('i18nextLng'));
                  return;
                } 
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });  
        } 
    }catch(Err){ console.log(Err); }

    this.getSEO();
    // Devexpress için tema ayari yapiliyor
    if(document.documentElement.hasAttribute("data-bs-theme")) {
      if(document.documentElement.getAttribute("data-bs-theme") == 'dark') {
        themes.current("generic.dark");
      }
    }
  }
};
</script>

<style>
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
