<template>
    <div>
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content"  >
                <div class="modal-header bg-primary py-3">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        Search Result
                    </h2>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeAddressModal" @click="onCloseModal()">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6" v-show="!this.isAddressSearchInProgress && !this.addressSearchResult.length == 0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                            </svg>
                        </span>
                    <input  type="text" 
                            data-kt-customer-table-filter="search" 
                            class="form-control form-control-solid w-250px ps-15 form-control-sm" 
                            placeholder="Search in Results" 
                            v-model="searchAddressBar" 
                            v-show="!this.isAddressSearchInProgress && !this.addressSearchResult.length == 0"/>

                    <div class="progress" v-if="this.isAddressSearchInProgress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                            Getting Address List. Please wait...
                        </div>
                    </div>
                    <h5 v-if="this.addressSearchResult.length == 0 && !this.isAddressSearchInProgress">
                        No result found.
                    </h5>

                    <div class="table-responsive">
                        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover" v-show="!this.isAddressSearchInProgress && !this.addressSearchResult.length == 0">
                            <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 min-w-325px rounded-start">Address</th>
                                    <th></th>
                                    <th class="text-end rounded-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr v-for="item of searchableAddressList" v-bind:key="item">
                                        <td>
                                            <div class="d-flex align-items-center">  
                                                <div class="symbol symbol-45px me-5">
                                                    <span class="svg-icon svg-icon-danger svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="currentColor"/>
                                                    <path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="currentColor"/>
                                                    </svg>
                                                    </span>
                                                </div>
                                                <div class="d-flex justify-content-start flex-column">
                                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{{ item.DPA.ADDRESS }}</a>
                                                    <span class="text-muted fw-semibold text-muted d-block fs-7">
                                                        {{ item.DPA.POSTCODE }}
                                                        <span class="ps-3"> UPRN: <b>{{ item.DPA.UPRN }}</b> </span>
                                                        <span class="ps-3"> UDPRN: <b>{{ item.DPA.UDPRN }}</b> </span>
                                                    </span>
                                                </div>
                                            </div>                                
                                        </td> 
                                        <td>
                                            <a href="#" class="text-gray-800 fw-bold text-hover-primary d-block mb-1 fs-6">{{ item.DPA.POST_TOWN }}</a>
                                            <span class="text-muted fw-semibold text-muted d-block fs-7">{{ item.DPA.LOCAL_CUSTODIAN_CODE_DESCRIPTION }}</span>
                                        </td> 
                                        <td class="text-end">
                                            <button type="button" class="btn btn-sm btn-primary" @click="onSelectSearchResult(item.DPA)">Select</button>
                                        </td>
                                    </tr> 
                                </tbody> 
                        </table> 
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: 'FindAddressByAddressCode',
    props: {
        addressCode: String,
        accountType: Number
    },
    data() {
        return {
            isAddressSearchInProgress: false,
            addressSearchResult: [],
            searchAddressBar: ''
        }
    },
    computed: {
        searchableAddressList() {
            return this.addressSearchResult.filter(p => { 
                return p.DPA.ADDRESS.toLowerCase().indexOf(this.searchAddressBar.toLowerCase()) != -1
            });
        },
        previewDate(){
            return (value)=>{
                if (value) {
                    return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
    },
    methods: {
        getData() {
            const that = this;
            this.addressSearchResult = [];
            this.searchAddressBar = '';
            this.isAddressSearchInProgress = true;
            const parameters = {
                PostCode: this.addressCode,
                SessionId: this.$getUser().session_id,
                SourceType: this.accountType
            };
            axios.post('api/GetCompanyAccounts/FindAddressByAddressCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    that.addressSearchResult = response.data.results ? response.data.results : []; 
                    that.isAddressSearchInProgress = false;
                } else {
                    setTimeout(() => {
                        that.$refs.closeAddressModal.click(); 
                    }, 500);
                }
            });
        },
        onSelectSearchResult(item){
            this.$parent.onSelectAddressSearchResult(item);
            this.$refs.closeAddressModal.click(); 
        },
        onCloseModal() {
            if(this.$parent.isViewAddressModal) this.$parent.isViewAddressModal = false;
        }
    }
}
</script>