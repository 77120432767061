<template>
  <div>     
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update User') }}</h1>  
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
              <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
            </li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li> 
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserManagement' }">{{ $t('User Management') }}</router-link></li>
            <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserList' }">{{ $t('Users') }}</router-link></li>  
            <li class="breadcrumb-item text-gray-600">{{ $t('Update User') }}
              <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </li> 
          </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/UserList">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                   <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                </button>
            </router-link>
            &nbsp;
            <div class="dropdown" v-if="form.id !== this.$getUser().id">
                <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="userUpdateActions" type="button">
                <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                </svg></span>
                    {{ $t('Actions') }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="userUpdateActions"> 
                    <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteUser()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>{{ $t('Delete') }} </a>
                    </li>
                </ul>
            </div> 
        </div>
    </div>
    
    <div id="kt_content_container" class="d-flex flex-column-fluid align-items-start">
      <div class="content flex-row-fluid" id="kt_content">
        <div class="card mb-5 mb-xl-10">
          <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap">
              <div class="me-7 mb-4">
                  <div class="symbol symbol-75px symbol-fixed position-relative">
                    <img src="/assets/theme/media/avatars/blank.png" alt="image">
                  </div>
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{form.name_surname}}</a>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                      <a href="#"
                          class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <span class="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        {{form.user_mail}}
                      </a>
                      <a href="#"
                          class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                        <span class="svg-icon svg-icon-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="currentColor"/>
                          <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="currentColor"/>
                          </svg>
                        </span>
                        {{form.phone_country_code}} {{form.phone}}
                      </a>
                    </div>
                  </div>
                  <div class="d-flex my-4">                    
                      <span class="badge badge-light-warning fw-bolder fs-8 px-2 py-1">{{ $t('Company') }}</span>
                       <span class="badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2" v-if="form.permissionType == 1">{{ $t('Admin') }}</span>
                      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2" v-if="form.permissionType == 2">{{ $t('Client') }}</span> 
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              <li class="nav-item mt-2">
                  <a class="nav-link text-active-primary ms-0 me-10 py-5" :class="{'active': this.activeTab == 'UserDetails'}" data-bs-toggle="tab" href="#kt_general_tab" target="_self"
                  @click="this.activeTab = 'UserDetails'">
                      <h4 class="text-gray-800"> {{ $t('UserDetails') }} </h4>
                  </a>
              </li>
              <li class="nav-item mt-2">
                  <a class="nav-link text-active-primary ms-0 me-10 py-5" :class="{'active': this.activeTab == 'UserGroup'}" data-bs-toggle="tab" href="#kt_user_group_tab" target="_self"
                  @click="this.activeTab = 'UserGroup', getUserGroupDetails()">
                      <h4 class="text-gray-800"> {{ $t('User Groups') }} </h4>
                  </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <RequiredField></RequiredField> -->        
        <div v-if="form.is_mail_verified !== 1" class="alert alert-dismissible bg-light-danger border border-danger d-flex flex-column flex-sm-row w-100 p-5 mb-5">
          <i class="ki-duotone ki-search-list fs-2hx text-primary me-4 mb-5 mb-sm-0"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
          <div class="d-flex flex-column pe-0 pe-sm-10">
              <h4 class="fw-semibold">{{ $t('Verify Account!') }}</h4>
              <span>{{ $t('A verification link has been sent to the') }} <span class="link-primary fw-bold"> {{form.user_mail}} </span>. {{ $t('The account must be verified by setting a password on the link sent.') }} 
                {{ $t('The user will not be able to access the system until the account is verified.') }}
              </span>
              <br>
              <span>{{ $t('Didn’t receive an email? Please check your spam folder or') }} <a href="#" class="link-primary fw-bold" @click="sendVerifyLink()"> {{ $t('Try Again') }} </a></span>
          </div>
          <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
              <i class="ki-duotone ki-cross fs-1 text-primary"><span class="path1"></span><span class="path2"></span></i>
          </button>
        </div>
        <div class="card mb-5 mb-xl-10 tab-pane fade show active" id="kt_general_tab" role="tabpanel" v-if="this.activeTab == 'UserDetails'">
          <div id="kt_account_settings_profile_details" class="collapse show">
            <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
              <div class="card-body border-top p-9">
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">
                      {{ $t('Email') }}
                      <span class="ms-1" data-bs-toggle="tooltip" aria-label="Mail address must be verified" data-bs-original-title="Mail address must be verified" data-kt-initialized="1">
                        <i class="bi bi-info-circle fs-7"></i>
                      </span>
                    </label>
                    <div class="col-lg-8 d-flex align-items-center">
                        <span class="fw-bold fs-6 text-gray-800 me-2">{{form.user_mail}}</span>                      
                        <span class="badge badge-success" v-if="form.is_mail_verified == 1">Verified</span>
                        <span class="badge badge-danger" v-if="form.is_mail_verified == 0">Not Verified</span>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-6 mt-3"></div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label required fw-semibold fs-6">{{ $t('Name Surname') }}</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" placeholder="" name="name" v-model="form.name_surname"> 
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Phone') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <div class="input-group">
                        <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                        <input type="text" class="form-control" name="phone" v-model="form.phone">
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Permission Type') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                        <Select2 v-model="form.permission_type" :options="permissionTypes" :settings="{width: '100%'}" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('User Assignment') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <Select2 v-model="form.user_assignment_id" :options="userAssignments" :settings="{width: '100%'}" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Login Method') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <Select2 v-model="form.login_method" :options="loginMethods" :settings="{width: '100%'}" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Authentication Type') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <Select2 v-model="form.authentication_type" :options="authenticationTypes" :settings="{width: '100%'}" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Validity Type') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <Select2 v-model="form.validity_type" :options="validityTypes" :settings="{width: '100%'}" />
                    </div>
                  </div>
                  <div class="row mb-3" v-if="form.validity_type == 2">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">
                      {{ $t('Validity Start/End Date') }}                                
                      <small v-if="form.validity_date_start == null || form.validity_date_end == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                    </label>
                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                      <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_start" format="dd.MM.yyyy" 
                        previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                    </div>
                    <div class="col-lg-4 fv-row fv-plugins-icon-container">
                      <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.validity_date_end" format="dd.MM.yyyy" 
                        previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" /> 
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-lg-4 col-form-label fw-semibold fs-6">{{ $t('Description') }}</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <textarea type="text" rows="2" class="form-control" placeholder="" name="name" v-model="form.user_description" /> 
                    </div>
                  </div>
                  <div class="separator separator-dashed my-6"></div>
                  <div class="d-flex col-lg-12">
                    <div class="col-lg" v-if="form.id !== this.$getUser().id">
                      <label class="col-form-label fw-semibold ">{{ $t('Is Active?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.isActive" id="isActive" :disabled="form.is_mail_verified == 0"/>
                      </div>
                    </div>
                    <div class="col-lg">
                      <label class="col-form-label fw-semibold ">{{ $t('Is Owner?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="isOwner" v-model="form.isOwner">
                      </div> 
                    </div>
                    <div class="col-lg">
                      <label class="col-form-label fw-semibold ">{{ $t('Is ChatBi User?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="isChatbiUser" v-model="form.isChatbiUser">
                      </div>
                    </div>
                    <div class="col-lg">
                      <label class="col-form-label fw-semibold ">{{ $t('Allow List All Data?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="isAllowListAllData" v-model="form.isAllowListAllData">
                      </div>
                    </div>
                    <div class="col-lg">
                      <label class="col-form-label fw-semibold ">{{ $t('Allow Export?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="isAllowExport" v-model="form.isAllowExport">
                      </div> 
                    </div>
                    <div class="col-lg">
                      <label class="col-form-label fw-semibold ">{{ $t('Allow Cancel Accounting Integr.?') }}</label> 
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="isAllowCancelAccountingIntegration" v-model="form.isAllowCancelAccountingIntegration">
                      </div> 
                    </div>
                  </div>
                </div>

                <div class="card-footer d-flex row py-6 px-9">
                  <div class="d-grid gap-2"> 
                  <button type="button" class="btn btn-primary" id="kt_user_submit_button" @click="saveData()" data-kt-indicator="on">
                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                      </svg>
                    </span>
                    <strong v-if="!this.isProgressing"> {{ $t('Update User') }} </strong> 
                    <span class="indicator-progress" v-if="this.isProgressing">
                      {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span> 
                  </button>
                </div>
                </div>
            </form>
          </div>
        </div>
        <div class="card mb-5 mb-xl-10 tab-pane fade show active" id="kt_user_group_tab" role="tabpanel" v-if="this.activeTab == 'UserGroup'">
          <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_user_groups" aria-expanded="true" aria-controls="kt_account_user_groups">
              <div class="card-title m-0">
                  <h3 class="fw-bold m-0">{{ $t('User Groups') }}</h3>
              </div>
          </div>
          <div id="kt_account_user_groups" class="collapse show">            
            <div class="card-body border-top p-9"> 
              <form id="group_user_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                  <div class="fv-row mb-6 row">   
                      <div class="col-lg-4">
                          <div class="fv-row">
                              <label class="fs-6 fw-bold form-label mt-3">
                                  {{ $t('User Group') }}
                              </label> 
                              <Select2 v-model="user_group_id" :options="userGroupList" :settings="{width: '100%'}"/> 
                          </div>
                      </div>
                  </div> 
              </form>
              <div class="separator my-10"></div>
              <div class="d-flex flex-stack flex-wrap my-5">
                  <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                      <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addUserGroup()" :disabled="this.user_group_id == '-1'">
                          <span class="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                      transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                      fill="currentColor">
                                  </rect>
                              </svg>
                          </span>
                          {{ $t('Add') }}
                      </button>
                      <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteUserGroup()" 
                          :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                          <span class="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                              </svg>
                          </span>
                          {{ $t('Remove') }}
                      </button>
                      <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllUsersGroup()" :disabled="this.groupUserList.length == 0">
                          <span class="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                              </svg>
                          </span>
                          {{ $t('Remove All (!)') }}
                      </button>
                  </div>
              </div>
              <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive">
                  <DxDataGrid id="gridContainer" :show-borders="true" 
                    key-expr="id" 
                    :data-source="groupUserList" 
                    :allow-column-reordering="true"  
                    :allow-column-resizing="true" 
                    :column-auto-width="true" 
                    :row-alternatin-enabled="true"
                    :hover-state-enabled="true" 
                    :show-row-lines="true" 
                    @exporting="this.$onExporting($event , 'User-Group-List')">

                    <DxFilterRow :visible="true"/>
                    <DxHeaderFilter :visible="true"/>
                    <DxGroupPanel :visible="true"/>      
                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                    <DxPaging :page-size="10000"/>
                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                    <DxSearchPanel :visible="true"/>
                    <DxScrolling
                      mode="virtual"
                      row-rendering-mode="virtual"
                    />
                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                    <DxColumn data-field="name" :caption="$t('Group Name')" cell-template="name-cell-template"/> 
                    <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/> 
                    <DxColumn data-field="is_active" :caption="$t('Active?')" cell-template="is_active-cell-template" alignment="center"/> 
                    <template #is-selected-cell-template="{ data }"> 
                      <span>
                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                          <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                        </div>
                      </span>
                    </template>
                    <template #name-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                            {{ data.data.name }}
                        </a>
                    </template>
                    <template #description-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                            {{ data.data.description }}
                        </a>
                    </template>
                    <template #is_active-cell-template="{ data }">
                        <a href="#" class="text-gray-800 text-hover-primary mb-1" >
                          <span class="svg-icon svg-icon-1 svg-icon-success" v-if="data.data.is_active">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                              <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
                            </svg>
                          </span>
                        </a>
                    </template>
                  </DxDataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> 
</template>

<script>
// Component Alias: `USP001`
import axios from 'axios';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

export default {
  name: "UserUpdate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      userData: this.$getUser(),
      lists: [],
      isProgressing: false,
      accountTypes: [{ id: 1, text: "System User" }, { id: 2, text: "Controller User" }, { id: 3, text: "Company User" }],
      permissionTypes: [{ id: 1, text: "Admin" }, { id: 2, text: "Client" }],
      loginMethods: [{ id: 1, text: "Standard" }, { id: 2, text: "2FA" }],
      userGroupList: [],
      authenticationTypes: [{ id: 1, text: "SMS" }, { id: 2, text: "Google 2FA" }],
      validityTypes: [{ id: 1, text: "Always" }, { id: 2, text: "Date Range" }],
      userAssignments: [{ id: '-1', text: 'Select an option' }],
      phoneMaskList: [],
      form: { 
          id: null,
          account_type: -1,
          permission_type: 1,
          permissionType: 1,
          user_assignment_id: '-1',
          user_mail: null,
          user_password: null,
          name_surname: null,
          phone: null,
          user_description: null,
          login_method : 1,
          authentication_type: 1,
          validity_type: 1,
          validity_date_start: new Date(),
          validity_date_end: new Date(),
          password_validity: -1,
          is_active: 1,
          isActive: true,
          is_owner: 0,
          isOwner: false,
          isChatbiUser: false,
          is_chatbi_user: 0,
          isChangePassword: false,
          confirm_password: null,
          is_mail_verified: 1,
          phone_country_code: null,
          isAllowExport: false,
          is_allow_export : 0,
          isAllowListAllData: false,
          is_allow_list_all_data : 0,
          isAllowCancelAccountingIntegration: false,
          is_allow_cancel_accounting_integration: 0,
      },
      isHide: true,
      menuSelection: '/UsersManagementHelppage',
      iconView : false,
      activeTab: 'UserDetails',
      user_group_id: '-1',
      groupUserList:[],
      selectedUserGroupId: null
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.isChangePassword) {
        if(this.form.user_password !== null) {
          if(this.form.user_password == this.form.confirm_password) return true;
          else return false;
        }
        else return null;
      }
      else { return null;}
    },
    isButtonDisabled() {
      return this.groupUserList.find(item => item.isSelected == true && item.id !== '' );
    },
  },
  methods: {
    getData() { 
      this.lists = []; 
      this.$setSessionStorage('refreshList', true);

      const parameters = {
        id: this.$getSessionStorage('record_id'),
        session_id: this.$getUser().session_id,
        get_access_status: 0,
        list_type: 18 // User Assignment List: 18
      };
      const requestOne =  axios.post('/api/Users/GetUser', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'}); 
      const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'});
      axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
        this.form = { 
          id: responses[0].data.id,
          account_type: responses[0].data.account_type,
          permission_type: responses[0].data.permission_type,
          permissionType: responses[0].data.permission_type,
          system_account_id: responses[0].data.system_account_id,
          user_mail: this.$unlock(responses[0].data.user_mail),
          user_password: null,
          confirm_password: null,
          isChangePassword: false,
          name_surname: this.$unlock(responses[0].data.name_surname),
          phone: this.$unlock(responses[0].data.phone),
          user_description: this.$unlock(responses[0].data.user_description),
          login_method : responses[0].data.login_method,
          authentication_type: responses[0].data.authentication_type,
          validity_type: responses[0].data.validity_type,
          validity_date_start: responses[0].data.validity_date_start,
          validity_date_end: responses[0].data.validity_date_end,
          password_validity: responses[0].data.password_validity,
          user_assignment_id: responses[0].data.user_assignment_id == null || responses[0].data.user_assignment_id == '' ? '-1' : responses[0].data.user_assignment_id,
          is_active: responses[0].data.is_active,
          isActive: responses[0].data.is_active == 1 ? true : false,
          is_owner: responses[0].data.is_owner,
          isOwner: responses[0].data.is_owner == 1 ? true : false,
          is_chatbi_user: responses[0].data.is_chatbi_user,
          isChatbiUser: responses[0].data.is_chatbi_user == 1 ? true : false,
          is_mail_verified: responses[0].data.is_mail_verified,
          phone_country_code: this.$unlock(responses[0].data.phone_country_code),
          is_allow_list_all_data : responses[0].data.is_allow_list_all_data,
          isAllowListAllData: responses[0].data.is_allow_list_all_data == 1 ? true : false,
          is_allow_export : responses[0].data.is_allow_export,
          isAllowExport: responses[0].data.is_allow_export == 1 ? true : false,
          is_allow_cancel_accounting_integration : responses[0].data.is_allow_cancel_accounting_integration,
          isAllowCancelAccountingIntegration : responses[0].data.is_allow_cancel_accounting_integration == 1 ? true : false,
        };
        this.userAssignments = responses[1].data; 
        this.userAssignments.unshift({ id: '-1', text:  'Select an option'}); 
        this.phoneMaskList = responses[2].data; 
      }))
      .catch(errors => { 
        console.error(errors); 
      });
    },
    getUserGroupDetails() {
      const params = {
        session_id: this.$getUser().session_id,
        user_id: this.$getSessionStorage('record_id'),
      };
      const requestOne =  axios.post('/api/UserGroup/Get', params, {'Content-Type': 'application/json'}); 
      const requestTwo =  axios.post('/api/UserGroup/UserGroupListByUser', params, {'Content-Type': 'application/json'});
      axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
        this.userGroupList = responses[0].data;
        this.groupUserList = responses[1].data;
        this.userGroupList.unshift({ id: '-1', text: "Not Selected" });
      }))
      .catch(errors => { 
        console.error(errors); 
      });
    },
    togglePasswordVisibility() {
      this.isHide = !this.isHide;
      var x = document.getElementById("Ps_password");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    saveData(){
      this.isProgressing = true;
      const params = {
        session_id: this.$getUser().session_id,
        id: this.form.id,
        permission_type: this.form.permission_type,
        user_mail: this.form.user_mail,
        name_surname: this.form.name_surname,
        phone: this.form.phone,
        user_description: this.form.user_description,
        login_method: this.form.login_method,
        authentication_type: this.form.authentication_type,
        validity_type: this.form.validity_type,
        validity_date_start: this.form.validity_date_start,
        validity_date_end: this.form.validity_date_end,
        password_validity: this.form.password_validity,
        user_assignment_id: this.form.user_assignment_id !== '-1' ? this.form.user_assignment_id : null,
        phone_country_code: this.form.phone_country_code,
        is_active: this.form.isActive == true ? 1 : 0,
        is_owner: this.form.isOwner == true ? 1 : 0,
        is_chatbi_user: this.form.isChatbiUser == true ? 1 : 0,
        is_allow_list_all_data: this.form.isAllowListAllData == true ? 1 : 0,
        is_allow_export: this.form.isAllowExport == true ? 1 : 0,
        is_allow_cancel_accounting_integration: this.form.isAllowCancelAccountingIntegration == true ? 1 : 0,
      }; 
      const that = this;
      if (params.validity_type == 2) {
        params.validity_date_start = params.validity_date_start !== null ? moment(params.validity_date_start).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.validity_date_end = params.validity_date_end !== null ? moment(params.validity_date_end).format('YYYY-MM-DD[T]HH:mm:ss') : null;
      }
      axios.post('/api/Users/UpdateUser', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Data saved succesfully..!", 'success'); 
            this.$setSessionStorage('refreshList', true);    
            this.getData();
          } 
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
    },    
    deleteUser() { 
        this.$swal.fire({
          title: 'Are you sure you want to delete this data?',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          icon: 'warning'
        }).then((result) => { 
          if (result.isConfirmed) {
            const queryParameter = {
              id: this.form.id,
              session_id: this.$getUser().session_id,
            };
            axios.post('/api/Users/DeleteUser', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$setSessionStorage('refreshList', true);
                this.$router.push({ name: 'UserList' });
              }
            })
            .catch(function (error) {
                console.log(error);
            });
          }
        });
    },
    sendVerifyLink() {
      const parameters = {
        session_id: this.$getUser().session_id,
        user_mail: this.form.user_mail
      };  
      const that = this;
      axios.post('/api/Users/SendVerifyLink', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$swal("Success", "Verification link has been successfully sent.", 'success');   
        }
      })
      .catch(function () {
        that.$swal("Failed", "An error occurred while sending the email. You can wait for a while to resend or check the mail server settings.", 'error');
      });
    },
    async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/UsersManagementHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    },
    addUserGroup(){
      if (this.user_group_id == '-1' || this.user_group_id == '' || this.user_group_id == null || this.user_group_id == undefined) {
        this.$swal("Failed", "User must be selected..!", 'warning' );
        return;
      }
      const parameters = {
        session_id: this.$getUser().session_id,
        user_group_id: this.user_group_id,
        user_id: this.$getSessionStorage("record_id")
      };
      axios.post('/api/UserGroup/AddUser', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
          const params = {
            session_id: this.$getUser().session_id,
            user_id: this.$getSessionStorage('record_id'),
          };
          axios.post('/api/UserGroup/UserGroupListByUser', params, {'Content-Type': 'application/json'})
          .then((response) => {
            this.groupUserList = response.data;
            this.user_group_id = '-1';
          }); 
        }
      }) 
      .catch(function (error) {
        console.log(error);
      });
    },
    deleteUserGroup(){
      this.$swal.fire({
        title: "Are you sure you want to remove this item?",
        confirmButtonColor: "#f1416c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "DELETE",
        icon: 'info'
      })
      .then((result) => {
        if (result.isConfirmed) {
          const parameters = {
            session_id: this.$getUser().session_id,
            user_id: this.$getSessionStorage("record_id"),
            user_group_id: this.selectedUserGroupId,
            is_delete_all: 0
          }; 
          axios.post('/api/UserGroup/DeleteUserGroup', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            this.groupUserList = response.data;
            this.selectedUserId = null;
            this.user_group_id = '-1';
          }) 
          .catch(function (error) {
              console.log(error);
          });
        }
      });
    },
    deleteAllUsersGroup(){ 
      this.$swal.fire({
        title: "Are you sure you want to remove all items?",
        confirmButtonColor: "#f1416c",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "DELETE",
        icon: 'info'
      })
      .then((result) => {
        if (result.isConfirmed) { 
          const parameters = {
            session_id: this.$getUser().session_id,
            user_group_id: null,
            is_delete_all: 1,
            user_id: this.$getSessionStorage("record_id")
          }; 
          axios.post('/api/UserGroup/DeleteUserGroup', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            this.groupUserList = response.data;
            this.selectedUserId = null;
            this.user_group_id = '-1';
          }) 
          .catch(function (error) {
            console.log(error);
          });
        }
      });
    },
    onSelectItem(item) { 
      this.selectedUserGroupId = item.id;
      this.groupUserList.forEach((value) => {
        if (item.id !== value.id) { 
          value.isSelected = false;
        }
      });
    },
  },
  mounted() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
}; 
</script>