<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Bank Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'BankList' }">{{ $t('Bank') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Bank Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/BankList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-10">
                        <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-12" v-if="isViewAccountId">
                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                        {{ $t('Account ID') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'">{{ $t('Name') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="name" v-model="form.name" v-custom>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_type'">{{ $t('Account Type') }}
                                                    </label>
                                                    <Select2 v-model="form.account_type_id" :options="accountTypeList" name="account_type" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'currency'">
                                                        <span>{{ $t('Currency') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.currency" :options="currencyList" name="currency" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-8 row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'bank_account_detail'">
                                                        <span>{{ $t('Bank Account Detail') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.bank_detail_id" :options="bankDetailList" name="bank_account_detail" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                        <span>{{ $t('Branch') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'general_bank_list'">{{ $t('General Bank List') }}
                                                    </label>
                                                    <Select2 v-model="form.bank_bank_id" :options="bankList" name="general_bank_list" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>                                               
                                            <div class="fv-row row mb-7">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'short_code'">{{ $t('Short Code') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="short_code" v-model="form.bank_short_code" v-custom>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_number'">{{ $t('Account Number') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="account_number" v-model="form.account_number" v-custom>
                                                </div>
                                            </div>
                                    </div>
                                </div>                    
                            </div>

                            <!-- <div class="col-lg-6" v-if="form.account_type_id == '2'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-8">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'credit_card_no'">
                                                        <span>Credit Card No</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="credit_card_no" v-model="form.credit_card_no" v-custom>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'cvv'">
                                                        <span>CVV</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="cvv" v-model="form.cvv" v-custom>
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'credit_card_name'">
                                                        <span>Name</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="credit_card_name" v-model="form.cardholder_name" v-custom>
                                                </div>
                                            </div>
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-6" v-if="form.account_type_id == '2'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'valid_date'">
                                                        <span>Valid Date</span>
                                                    </label>
                                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expiry_date'">
                                                        <span>Expiry Date</span>
                                                    </label>
                                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.period_start" inputClassName="form-control" format="dd.MM.yyyy HH:mm" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                                </div>
                                            </div>
                                    </div>
                                </div>                    
                            </div>

                            <div class="col-lg-6" v-if="form.account_type_id == '3'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Loan Amount</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.loan_amount">
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Loan Rate</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.loan_rate">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Loan Term</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.loan_term">
                                                </div>
                                            </div> 
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-6" v-if="form.account_type_id == '3'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Total Refund</span>  <small> Toplam Geri Ödeme </small>
                                                    </label>
                                                    <input type="number" class="form-control" name="company_name" v-model="form.loan_total_refund">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Monthly Payment Amount </span> <small> Aylık Ödeme Tutarı </small>
                                                    </label>
                                                    <input type="number" class="form-control" name="company_name" v-model="form.loan_monthly_payment_amount">
                                                </div>
                                            </div> 
                                    </div>
                                </div>                    
                            </div>

                            <div class="col-lg-6" v-if="form.account_type_id == '4'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Lease Amount</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.lease_amount">
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Lease Rate</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.lease_rate">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Lease Term</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="company_name" v-model="form.lease_term">
                                                </div>
                                            </div> 
                                    </div>
                                </div>                    
                            </div>
                            <div class="col-lg-6" v-if="form.account_type_id == '4'">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Total Refund</span>  <small> Toplam Geri Ödeme </small>
                                                    </label>
                                                    <input type="number" class="form-control" name="company_name" v-model="form.lease_total_refund">
                                                </div>
                                                <div class="col-lg-12">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>Monthly Payment Amount </span> <small> Aylık Ödeme Tutarı </small>
                                                    </label>
                                                    <input type="number" class="form-control" name="company_name" v-model="form.lease_monthly_payment_amount">
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-body pb-0"> 
                                            <div class="fv-row mb-7">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                    <span>{{ $t('Notes') }}</span>
                                                </label>
                                                <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(13,'I')">
                                <div class="d-grid gap-2"> 
                                    <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isProgressing"> {{ $t('Save Bank') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import Swal from "sweetalert2";
 
export default {
  name: "BankCreate",
  data() {
    return {
      currencyList: [],
      bankList: [],
      accountTypeList: [],
      bankDetailList: [],      
      branchList: [],
      isProgressing: false,
      form: {
        name: null,
        company_id: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null,
        account_type_id: '1',
        bank_account_type_id: null,
        currency: '-1',
        bank_bank_id: '-1',
        bank_short_code: null,
        bankSelection: null,
        account_number: null,
        branch_id: '-1',
        accounting_plan: null,
        bank_detail_id: '-1'
      },
      isViewAccountId: false,
      menuSelection: '/BankHelppage',
      iconView : false
    };
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.getLists();   
    },
    getLists() {
        this.bankDetailList = [];
        this.bankList = [];
        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 2,
                    list_name: "bank_list"
                }
            ],
            list_name: ["branch", "account_setting", "currency"],
            account_type: 4 // yalnızca account settings listesi çekilecek ise gerekli
        }; 
        const parameters2 = {
            session_id: this.$getUser().session_id,
            list_type: 1, // Bank Detail List: 1,
            get_access_status: 0,
            tip: 9
        };
        const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/Helper/GetLists', parameters2, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/Helper/GetConst', parameters2, {'Content-Type': 'application/json'});
        
        axios.all([requestOne, requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {
                this.bankList = responses[0].data.bank_list;
                this.branchList = responses[1].data.branch;
                this.currencyList = responses[1].data.currency;
                this.bankDetailList = responses[2].data;
                this.accountTypeList = responses[3].data;
                this.bankList.unshift({ id: '-1', text: 'Select an option'});
                this.bankDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.bankList.forEach((value) => {
                    if(value.is_default) {
                        this.form.bank_bank_id = value.id;
                    }
                });
                this.form.currency = this.$getUser().currency_code; 
                this.branchList.forEach((value) => {
                    if(value.is_default) {
                        this.form.branch_id = value.id;
                    }
                });
                this.bankDetailList.forEach((value) => {
                if(value.is_default) {
                    this.form.bank_detail_id = value.id;
                }
                });
                if (responses[1].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.$Progress.finish();            
            })
          )
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();  
        });  
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/BankHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = { 
        id:this.form.id,
        name:this.form.name,
        company_id:this.form.company_id,
        account_type:this.form.account_type,
        account_id:this.form.account_id == '-1' ? null : this.form.account_id,
        qr_code:this.form.qr_code,
        notes:this.form.notes,
        account_number:this.form.account_number,
        bank_account_type_id: this.form.account_type_id == '' || this.form.account_type_id == null ? '1' : this.form.account_type_id,
        bank_bank_id:this.form.bank_bank_id == '-1' ? null : this.form.bank_bank_id,
        currency:this.form.currency == '-1' ? null : this.form.currency,
        bank_short_code:this.form.bank_short_code,
        branch_id:this.form.branch_id == '-1' ? null : this.form.branch_id,
        accounting_plan:this.form.accounting_plan,
        bank_detail_id: this.form.bank_detail_id == '-1' ? null : this.form.bank_detail_id,
        };    
      params.session_id = this.$getUser().session_id;
      params.account_type = 4; // Bank: 4
      const that = this;
      axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'BankUpdate' });
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>