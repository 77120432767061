<template> 
  <div>
    <div class="content flex-row-fluid mt-2" id="kt_content">
        <!-- <StatsBar></StatsBar> -->
        <div class="card">
           <div class="card-body pt-0">
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2">
                            <div class="fv-row mb-7 row">
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                </div>
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>
                                </div>
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        {{ $t('Transaction Type') }}
                                    </label>
                                    <Select2 name="item_type" v-model="form.item_type" :options="cashTypeFilteredList" @select="getData(true)" :settings="{ width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content' }" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3">
                        <div class="d-flex justify-content-end mt-8" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(22,'R')">
                            <Popper hover placement="top">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light-primary border border-primary" @click="getData(false)" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                    </div>
                 </div>
                 <div class="separator my-5 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="table-responsive min-h-300px">                    
                    <!-- <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions> -->
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" 
                                :data-source="transactionsList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                @exporting="this.$onExporting($event , 'Bank-Transaction-List')"> 
                                
                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="false"/>      
                                <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="false"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="record_date" :caption="$t('Record Date')" data-type="date" format="dd.MM.yyyy" alignment="right" header-cell-template="record-date-header" :width="120" cell-template="record_date-cell-template"/>
                                <DxColumn data-field="docket_number" :caption="$t('Docket Number')" header-cell-template="docket-number-header" cell-template="docket_number-cell-template"/> 
                                <DxColumn data-field="docket_type_name" :caption="$t('Type')" cell-template="docket_type_name-cell-template"/>
                                <DxColumn data-field="item_type_name" :caption="$t('Sub Type')" cell-template="item_type_name-cell-template"/>
                                <DxColumn data-field="payment_account_name" :caption="$t('Account')" cell-template="payment_account_name-cell-template"/>

                                <template #docket-number-header>
                                    <div>{{ $t('Docket') }}</div><div>{{ $t('Number') }}</div>
                                </template>
                                <template #docket-type-header>
                                    <div>{{ $t('Docket') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #record-date-header>
                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                </template>

                                <!-- Cell Templates -->
                                <template #record_date-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateDocket(data.data.id)">
                                        <div>
                                            <span class="text-gray-900"> 
                                                {{ previewDate(data.data.record_date) }}
                                            </span> 
                                        </div>
                                    </a>
                                </template>
                                 <template #docket_number-cell-template="{ data }">
                                    <span class="badge badge-circle text-hover-primary badge-light me-3" @click="updateDocket(data.data.id)">
                                        <i class="bi bi-pencil text-gray-900"></i> 
                                    </span>
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateDocket(data.data.id)">
                                        {{ data.data.docket_number }}
                                    </a>
                                </template>
                                 <template #docket_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-600 text-hover-primary" @click="updateDocket(data.data.id)">
                                        {{ data.data.docket_type_name }}
                                    </a>
                                </template> 
                                 <template #payment_account_name-cell-template="{ data }">
                                     <a href="#" class="text-gray-600 text-hover-primary" @click="updateDocket(data.data.id)">
                                        {{ data.data.payment_account_name }} 
                                    </a>
                                </template> 
                                 <template #item_type_name-cell-template="{ data }">
                                     <a href="#" class="text-gray-600 text-hover-primary" @click="updateDocket(data.data.id)">
                                        {{ data.data.item_type_name }} 
                                    </a>
                                </template> 

                                <DxSummary>
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="record_date"/> 
                                </DxSummary>

                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                    </DxDataGrid>   
                </div>
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
import axios from 'axios' 
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "BankTransactionList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring, DxScrolling
    },
	data() {
        return { 
            storageKey: 'dx_' + this.$options.name,
            page: 1,
            page_size: 10,
            form: {
                first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
                last_date: moment(),
                item_type: 0,
                account_id: null
            },
            transactionsList: [],
            pageSize: 10,
            pageIndex: 0,
            searchbar: '',
            isListProgressing: false,
            cashTypeList: [
                { id: 0, text: 'ALL'},
                { id: 1, text: 'Incoming Cash'},
                { id: 2, text: 'Outgoing Cash'},
            ]
        };
  },
  computed: { 
     searchableList() {
      return this.transactionsList.filter(p => { 
        return p.docket_number.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 || 
               p.docket_type_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1
      }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.transactionsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.transactionsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    cashTypeFilteredList() {
      if(!this.$isCredentialActive(33, 'R', 22)) {
        return this.cashTypeList.filter(p => p.id != 1);
      }
      if(!this.$isCredentialActive(34, 'R', 22)) {
        return this.cashTypeList.filter(p => p.id != 2);
      }      
      return this.cashTypeList;
    },
  },
  methods: {
    getData(isAuto) {
        if(isAuto && this.$getUser().is_allow_list_all_data) return;
        this.transactionsList = [];
        this.$Progress.start();  
        this.isListProgressing = true;
        this.form.first_date = (this.form.first_date == null || this.form.first_date == '') ? moment() : this.form.first_date;
        this.form.last_date = ( this.form.last_date == null || this.form.last_date == '') ? moment () : this.form.last_date;
        const params = { 
            session_id: this.$getUser().session_id,
            docket_type: 6,
            first_date: this.form.first_date,
            last_date: this.form.last_date,
            item_type: this.form.item_type,
            account_id: this.form.account_id
        };
        params.first_date = moment(params.first_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.last_date = moment(params.last_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        axios.post('api/Transaction/GetDockets', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error.length > 0){ 
                this.transactionsList = response.data;
                this.page = 1;
                this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
            } 
            this.$Progress.finish(); 
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function () {
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    updateDocket(id){ 
        this.$setSessionStorage('record_id', id);        
        this.$parent.$refs.closeTransactionListModal.click();
        if (this.$route.name == 'BankTransactionUpdate') {
            this.$router.go(0);
        } else {
            this.$router.push({ name: 'BankTransactionUpdate' });
        }
    },
    setCriteriasToDefault(accountId) {   
        this.transactionsList = [];
        if((accountId === null || accountId === undefined || accountId === '-1')  && !this.$getUser().is_allow_list_all_data) return;
        this.form = {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),
            item_type: 0,
            account_id: (accountId !== undefined && accountId !== '' && accountId !== '-1') ? accountId : null
        };
        this.getData();
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    }
  }
}; 
</script>