<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create New Turnover/Difference') }}</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>      
                <li class="breadcrumb-item text-gray-600">{{ $t('Create New Turnover/Difference') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>
        <div class="d-flex align-items-center py-1" v-if="this.$isCredentialActive(9, 'R')">
            <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" >
                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                </svg>
                </span>
                {{ $t('Transaction List') }}
            </button>
        </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <RequiredField></RequiredField>
        
        <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="col-lg-12">
                        <div class="col-lg-12">
                                <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h3>{{ $t('Turnover/Difference') }}</h3>
                                        </div>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label" v-tag="'record_date'">
                                                    {{ $t('Record Date') }}                        
                                                </label>
                                                <Datepicker :required="this.$checkIfDateFieldRequired('record_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :disabled="this.docketItemsList && this.docketItemsList.length > 0" name="record_date" v-custom />
                                            </div>
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label" v-tag="'transaction_type'">
                                                    {{ $t('Transaction Type') }}                   
                                                </label>
                                                <Select2 v-model="this.form.transaction_type" :options="this.transactionTypeFilteredList" :settings="{ width: '100%' }" :disabled="this.docketItemsList && this.docketItemsList.length > 0" name="transaction_type" v-custom />
                                            </div> 
                                            <div class="col-lg-4" v-if="this.form.transaction_type == '11'">
                                                <label class="fs-6 fw-bold form-label" v-tag="'is_accounted'">
                                                    {{ $t('Create Accounting Voucher') }}
                                                </label>
                                                <Select2 v-model="this.form.is_accounted" :options="this.accountingVoucherOptionList" :settings="{ width: '100%' }" :disabled="this.docketItemsList && this.docketItemsList.length > 0" name="is_accounted" v-custom />
                                            </div> 
                                            <div class="col-lg-12 mt-3">
                                                <label class="fs-6 fw-bold form-label" v-tag="'description'">
                                                    {{ $t('General Description') }}       
                                                </label>
                                                <textarea class="form-control" rows="1" v-model="form.description" placeholder="You can write a general description here..." name="description" v-custom></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="col-lg-12">
                            <div class="card"> 
                                <div class="card card-body pt-3 bg-lighten border border-gray-300"> 
                                    <div class="d-flex flex-stack flex-wrap">
                                        <div class="d-flex justify-content-end mt-5" data-kt-docs-table-toolbar="base">
                                            <button type="button" class="btn btn-sm btn-success fw-bold required me-3"
                                                    data-bs-toggle="modal" data-bs-target="#kt_modal_add_item" data-kt-menu-placement="bottom-end" ref="btnAddItem"                                     
                                                    @click="openDocketModal('addItem')" :disabled="this.form.transaction_type == null || this.form.transaction_type == '-1'">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                fill="currentColor">
                                                            </rect>
                                                        </svg>
                                                    </span> 
                                                    {{ $t('Add To List') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" 
                                                    data-bs-toggle="modal"
                                                    ref="btnEditItem"
                                                    data-bs-target="#kt_modal_add_item" 
                                                    @click="openDocketModal('editItem')"
                                                    :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                        </svg>
                                                    </span> 
                                                    {{ $t('Edit') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                @click="deleteDocketItem()"
                                                :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                {{ $t('Remove') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger" @click="deleteAllDocketItems()">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                {{ $t('Remove All (!)') }}
                                            </button> 
                                        </div>
                                    </div>
                                    <div class="dataTables_wrapper dt-bootstrap4 no-footer mt-3">
                                        <div class="table-responsive min-h-300px">
                                            <DxDataGrid id="gridContainer" 
                                                    :show-borders="true" key-expr="id" 
                                                    :data-source="docketItemsList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true"
                                                    :customize-columns="onCustomizeColumn"
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    @exporting="this.$onExporting($event , 'Turnover-difference-items')"
                                                    @option-changed="handlePropertyChange"> 

                                                    <DxFilterRow :visible="true"/>
                                                    <DxHeaderFilter :visible="true"/>
                                                    <DxGroupPanel :visible="true"/>      
                                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                    <DxPaging :page-size="10"/>
                                                    <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                    <DxSearchPanel :visible="true"/>
                                                    <DxScrolling
                                                        mode="virtual"
                                                        row-rendering-mode="virtual"
                                                    />
                                                    <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                    <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/> 
                                                    <DxColumn data-field="account_type_name" :caption="$t('Account Type')" header-cell-template="account-type-header" cell-template="account_type_name-cell-template"/>  
                                                    <DxColumn data-field="account_name" :caption="$t('Account')" cell-template="account_name-cell-template"/>  
                                                    <DxColumn data-field="item_type_name" :caption="$t('Debit/Credit')" header-cell-template="item-type-header" cell-template="item_type_name-cell-template"/> 
                                                    <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/> 
                                                    <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/> 
                                                    <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>  
                                                    <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" header-cell-template="local-amount-header" cell-template="amount_local-cell-template"/>  
                                                    <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>  
                                                    <DxColumn data-field="due_date" :caption="$t('Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="due-date-header" cell-template="due_date-cell-template"/> 


                                                    <template #headerLocalTemplate="{ data: data }">
                                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                    </template>
                                                    <template #account-type-header>
                                                        <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                                    </template> 
                                                    <template #local-amount-header>
                                                        <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                                    </template>
                                                    <template #transaction-no-header>
                                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Number') }}</div>
                                                    </template>
                                                    <template #due-date-header>
                                                        <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                                    </template>
                                                    <template #item-type-header>
                                                        <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                                    </template>

                                                    <template #is-selected-cell-template="{ data }">
                                                        <span>
                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                            </div>
                                                        </span>
                                                    </template>
                                                    <template #id-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary">
                                                            {{ (this.pageSize * this.pageIndex) + data.rowIndex + 1 }}
                                                        </a>
                                                    </template>
                                                    <template #account_type_name-cell-template="{ data }">
                                                            <span>{{ data.data.account_type_name }}</span>
                                                    </template>
                                                    <template #account_name-cell-template="{ data }">
                                                        <span>{{ data.data.account_name }}</span>
                                                    </template>
                                                    <template #item_type_name-cell-template="{ data }">
                                                        <span>{{ data.data.item_type_name }}</span>
                                                    </template>
                                                    <template #currency-cell-template="{ data }">
                                                        <span>{{ data.data.currency }}</span>
                                                    </template>
                                                    <template #amount-cell-template="{ data }">
                                                        <span class="text-end">{{ data.data.amount }}</span>
                                                    </template>
                                                    <template #rate-cell-template="{ data }">
                                                        <span class="text-end">{{ data.data.rate }}</span>
                                                    </template>
                                                    <template #amount_local-cell-template="{ data }">
                                                        <span class="text-end">{{ data.data.amount_local }}</span>
                                                    </template>
                                                    <template #transaction_number-cell-template="{ data }">
                                                        <span>{{ data.data.transaction_number }}</span>
                                                    </template>
                                                    <template #due_date-cell-template="{ data }">
                                                        <span>{{ previewDate(data.data.due_date) }}</span>
                                                    </template>
                                                    <DxSummary :calculate-custom-summary="setSummaries">
                                                            <DxTotalItem column="id" summary-type="count" show-in-column="isSelected"/>
                                                            <DxGroupItem
                                                                :show-in-group-footer="true"
                                                                name="amount_local"
                                                                summary-type="custom"
                                                                show-in-column="amount_local"
                                                                display-format="{0}"
                                                            /> 
                                                            <DxTotalItem name="amount_local"
                                                                summary-type="custom"
                                                                show-in-column="amount_local"
                                                            />
                                                        </DxSummary>
                                                            <DxStateStoring
                                                                :enabled="true"
                                                                type="custom"
                                                                :storage-key="this.storageKey"
                                                                :ref="dataGrid" 
                                                                :saving-timeout="1000"
                                                                :custom-load="LoadDXState"
                                                            />
                                            </DxDataGrid>
                                        </div>
                                    </div> 
                                </div>
                            </div> 


                            <!-- YA Turnover YA DA DIFFERENCE OLABILIR. SEÇİME GÖRE -->
                            <div class="card card-xxl-stretch mb-6 mt-5 bg-lighten border border-gray-300">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h3 class="text-gray-800 me-5"> {{ $t('Total') }} </h3>
                                        </div>
                                    </div>
                                    <div class="card-body pt-1">
                                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                            <div class="fv-row row">
                                                <table class="table align-middle table-row-dashed fs-6 gy-5 table-hover">
                                                    <thead>
                                                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 fw-bolder text-gray-800"> 
                                                            <th class="text-end"></th>
                                                            <th class="text-end">{{ $t('Total Debit') }}</th>
                                                            <th class="text-end">{{ $t('Total Credit') }}</th>
                                                            <th class="text-end">{{$t('Balance')}}</th>
                                                            <th class="text-end">{{ $t('Provision') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="text-gray-600 fw-bold">
                                                        <tr v-if="this.form.transaction_type == 11"> 
                                                            <td class="text-end">Turnover</td>
                                                            <td class="text-end">{{ this.docketSummary.total_debit}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_credit}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_balance_amount}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_provision_amount}}</td>
                                                        </tr>
                                                        <tr v-if="this.form.transaction_type == 12"> 
                                                            <td class="text-end">Difference</td>
                                                            <td class="text-end">{{ this.docketSummary.total_debit}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_credit}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_balance_amount}}</td>
                                                            <td class="text-end">{{ this.docketSummary.total_provision_amount}}</td>
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </form>
                                    </div>
                            </div>
                    </div>
                    <div class="col-lg-12 mt-0" v-if="getSubCredentials('I')">
                        <div class="d-grid gap-2"> 
                            <button class="btn btn-success btn-lg " type="button" @click="createDocket()" data-kt-indicator="on" :disabled="docketItemsList.length == 0"> 
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isDocketSaving"> {{ $t('Save Transaction') }} </strong> 
                                <span class="indicator-progress" v-if="this.isDocketSaving">
                                    {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div> 
            </div> 
        </form>
    </div>

    <div class="modal fade" :class="{'show d-block': isViewAddTurnoverModal, 'd-none': !isViewAddTurnoverModal}" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <form id="turnoverItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div class="modal-dialog modal-xxl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            <span> {{ $t('Add Turnover & Difference') }}</span>               
                        </h2>                        
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="this.onCloseModal()">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-1" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                        <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'TurnoverDiffItemCreate' : 'TurnoverDiffItemUpdate'" class="mt-5"></RequiredFieldOnModal>
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-5 h-sm-100">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h3 class="text-gray-800 me-5 mt-1"> {{ $t('Account Selection') }} </h3>
                                            </div>
                                        </div>
                                        <div class="card-body pt-4">
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    {{ $t('Search Account') }}
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="input-group">
                                                        <Select2 v-model="modalForm.account_type" :options="accountTypeList" name="account_type" v-custom @select="onChangeAccountType()" :settings="{ width: '100%'}"/> 
                                                        <input type="search" class="form-control form-control-lg" name="account_name" v-model="modalForm.account_name" @keyup.enter="getAccounts()" v-custom @input="modalForm.account_id = '-1'" :placeholder="$t('Please type an account name and press enter for search account')">
                                                        <button type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" @click="getAccounts()" :disabled="(modalForm.account_name === null || modalForm.account_name === undefined || modalForm.account_name.length < 1 ) && !this.$getUser().is_allow_list_all_data">
                                                            <span v-if="!isSearchingAccount"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                            <span v-if="isSearchingAccount">{{ $t('Searching...') }}</span> 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'account_id'">
                                                    {{ $t('Select Account') }}
                                                </label>
                                                <div class="col-lg-7 fv-row fv-plugins-icon-container" 
                                                    :class="{'col-lg-8': modalForm.account_id == null || modalForm.account_id == '-1' || modalForm.account_id == '' || modalForm.account_id == undefined,
                                                    'pe-0': modalForm.account_id !== null && modalForm.account_id !== '-1' && modalForm.account_id !== '' && modalForm.account_id !== undefined}">
                                                    <Select2 id="account_id" v-model="modalForm.account_id" :options="accountNameList" @select="onSelectAccount($event);" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"  name="account_id" v-custom />
                                                </div>
                                                <div class="col-lg-1 fv-row fv-plugins-icon-container ps-0" v-if="modalForm.account_id !== null && modalForm.account_id !== '-1' && modalForm.account_id !== '' && modalForm.account_id !== undefined">
                                                    <button type="button" class="btn rounded-start-0 btn-light pb-3 text-hover-primary border border-1 border-gray-300" @click="onOpenAccountReport()">
                                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row mb-2 my-4">
                                                <!-- Account Type seçimine göre Stock için Giriş Çıkış olarak değiştirilecek -->
                                                <label class="col-lg-4 col-form-label fw-bold fs-6 p-2">
                                                    <span v-if="modalForm.account_type != 7"> {{ $t('Debit') }}(+)/{{ $t('Credit') }}(-)</span>
                                                    <span v-if="modalForm.account_type == 7"> {{ $t('Incoming / Outgoing') }} </span>                                                
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Select2 v-if="modalForm.account_type != 7" v-model="modalForm.item_type" :options="debitCreditList" 
                                                        @select="getDescriptions()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="item_type" />
                                                    <Select2 v-if="modalForm.account_type == 7" v-model="modalForm.item_type" :options="debitCreditListForStock" 
                                                        @select="getDescriptions()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="item_type" />
                                                </div>
                                            </div> 
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                   <span v-tag="'branch_id'"> {{ $t('Branch') }} </span>
                                                   <span v-tag="'expense_center_id'"> {{ $t(' / Expense Center') }} </span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                                
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <Select2 v-model="modalForm.branch_id" :options="branchList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="branch_id" v-custom/>
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <Select2 v-model="modalForm.expense_center_id" :options="expenseCenterList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="expense_center_id" v-custom />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-5 h-sm-100">
                                        <div class="card-header min-h-50px">
                                            <div class="card-title">
                                                <h5 class="text-gray-800 me-5"> 
                                                    <span v-if="this.form.transaction_type == '11'"> {{ $t('Turnover') }} </span>
                                                    <span v-if="this.form.transaction_type == '12'"> {{ $t('Difference') }}</span>

                                                    <span class="bullet bullet-dot me-3 ms-2" v-if="this.modalForm.account_type_name !== null && this.modalForm.account_type_name !== '' && this.modalForm.account_type_name !== '-1'"></span>

                                                    <span v-if="this.modalForm.account_type_name !== null && this.modalForm.account_type_name !== '' && this.modalForm.account_type_name !== '-1'"> 
                                                        {{this.modalForm.account_type_name}}
                                                    </span>

                                                    <span class="bullet bullet-dot me-3 ms-2" v-if="this.modalForm.account_id !== '-1' && this.modalForm.account_name !== null && this.modalForm.account_name !== ''"></span>

                                                    <span v-if="this.modalForm.account_id !== '-1' && this.modalForm.account_name !== null && this.modalForm.account_name !== '' && this.modalForm.account_name !== '-1'"> 
                                                        {{this.modalForm.account_name}} 
                                                    </span>

                                                    <span class="bullet bullet-dot me-3 ms-2" v-if="this.modalForm.item_type == '1' || this.modalForm.item_type == '2'"></span>

                                                    <span v-if="this.modalForm.item_type == '1' && this.modalForm.account_type != 7"> {{ $t('Debit') }} </span>
                                                    <span v-if="this.modalForm.item_type == '2' && this.modalForm.account_type != 7"> {{ $t('Credit') }} </span>
                                                    <span v-if="this.modalForm.item_type == '1' && this.modalForm.account_type == 7"> {{ $t('Incoming') }} </span>
                                                    <span v-if="this.modalForm.item_type == '2' && this.modalForm.account_type == 7"> {{ $t('Outgoing') }} </span>
                                                </h5>
                                            </div>
                                        </div>
                                    <div class="card-body py-4" v-if="this.modalForm.account_type != 7">
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" >
                                                    <span v-tag="'currency'" >{{ $t('Currency') }}  </span>
                                                    <span v-if="this.modalForm.currency !== this.localCurrency" v-tag="'rate'"> / {{ $t('Rate') }} </span>                    
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.modalForm.currency == this.localCurrency }">
                                                            <Select2 v-model="this.modalForm.currency" :options="this.currencyList" @select="onChangeCurrency()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="currency" v-custom />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.modalForm.currency !== this.localCurrency">
                                                        <CurrencyInput type="text" class="form-control" name="rate" v-model="this.modalForm.rate" :precision="5" @focus="$event.target.select()"  @blur="calculateLocalAmount()" v-custom/>
                                                        <small v-if="this.modalForm.rate_date !== null">{{ $t('Rate Date') }}: {{ previewDate(this.modalForm.rate_date) }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" >
                                                    <span v-tag="'amount'">{{ $t('Amount') }}</span>
                                                    <span v-if="this.modalForm.currency !== this.localCurrency">/ {{ $t('Local Amount') }}</span>                                            
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.modalForm.currency == this.localCurrency }">
                                                            <div class="input-group mb-3">
                                                                <CurrencyInput type="text" class="form-control text-end" name="amount" v-model="modalForm.amount" @blur="calculateLocalAmount()" @focus="$event.target.select()" v-custom/>
                                                                <span class="input-group-text p-1">
                                                                    <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeAmount()" :disabled="modalType == 'editItem'" >
                                                                        <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.modalForm.currency !== this.localCurrency">
                                                            <CurrencyInput type="text" class="form-control text-end" v-model="modalForm.amount_local" @focus="$event.target.select()" disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" >
                                                    <span v-tag="'transaction_number'">{{ $t('Transaction No') }}</span>
                                                    <span v-tag="'ref_number'">{{ $t(' / Ref No') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="text" class="form-control fw-boldest text-black-600" v-model="modalForm.transaction_number" name="transaction_number" v-custom >
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="text" class="form-control" v-model="modalForm.ref_number" name="ref_number" v-custom >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'">
                                                    <span >{{ $t('Description') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" v-model="modalForm.description" name="description" v-custom >
                                                        <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                            <div class="dropdown dropup">
                                                                <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                                    <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                </button>
                                                                <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                    <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                        <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                            <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                                :class="{'text-success': this.modalForm.description == item.description && item.description !== ''}">
                                                                                <i class="bi bi-dash fw-boldest" v-if="this.modalForm.description !== item.description"> </i> 
                                                                                <i class="bi bi-check-lg fw-boldest text-success" v-if="this.modalForm.description == item.description && item.description !== ''"> </i>
                                                                                {{item.text}}  
                                                                            </a>
                                                                            <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'due_date'">
                                                    {{ $t('Due Date') }}
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Datepicker :required="this.$checkIfDateFieldRequired('due_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.due_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" name="due_date" v-custom />
                                                </div>
                                            </div> 
                                        </div> 
                                        <div class="card-body p-4" v-if="this.modalForm.account_type == 7" >     
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span v-tag="'currency'" >{{ $t('Currency') }}  </span>
                                                    <span v-if="this.modalForm.currency !== this.localCurrency" v-tag="'rate'"> / {{ $t('Rate') }} </span>  
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.modalForm.currency == this.localCurrency }">
                                                            <Select2 v-model="this.modalForm.currency" :options="this.currencyList" @select="onChangeCurrency()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="currency" v-custom />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-row fv-plugins-icon-container" v-if="this.modalForm.currency !== this.localCurrency">
                                                            <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="rate" v-custom/>  
                                                        </div>
                                                        <div class="col-lg-12 fv-row fv-plugins-icon-container mt-3" v-if="this.modalForm.currency !== this.localCurrency">
                                                        <CurrencyInput type="text" class="form-control" name="rate" v-model="this.modalForm.rate" :precision="5" @focus="$event.target.select()"  @blur="calculateLocalAmount()"/>
                                                        <small v-if="this.modalForm.rate_date !== null">{{ $t('Rate Date') }}: {{ previewDate(this.modalForm.rate_date) }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " >
                                                    <span v-tag="'quantity'">{{ $t('Quantity') }}</span>
                                                    <span v-tag="'price'">{{ $t(' / Price') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <CurrencyInput type="text" class="form-control text-end" v-model="modalForm.quantity" @blur="calculateLocalAmount()" @focus="$event.target.select()"  name="quantity" v-custom />
                                                            <ErrorMessage name="quantity" class="text-danger" />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <div class="input-group mb-3">
                                                                <CurrencyInput type="text" class="form-control text-end" v-model="modalForm.price" @blur="calculateLocalAmount()" @focus="$event.target.select()"  name="price" v-custom />
                                                                <ErrorMessage name="price" class="text-danger" />
                                                                <span class="input-group-text p-1" v-if="this.form.transaction_type == '12' && this.modalForm.account_type == 7" >
                                                                    <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeStockAmount()" :disabled="this.modalForm.account_id == '-1'">
                                                                        <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                    </button>
                                                                </span>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span v-tag="'amount'">{{ $t('Amount') }}</span>
                                                    <span v-if="this.modalForm.currency !== this.localCurrency">/ {{ $t('Local Amount') }}</span>  
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.modalForm.currency == this.localCurrency }">
                                                            <CurrencyInput type="text" class="form-control text-end" v-model="modalForm.amount" @blur="calculateLocalAmount()" @focus="$event.target.select()"  name="amount" v-custom />
                                                            <ErrorMessage name="amount" class="text-danger" />
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.modalForm.currency !== this.localCurrency">
                                                            <CurrencyInput type="text" class="form-control text-end" v-model="modalForm.amount_local" @focus="$event.target.select()" disabled />
                                                            <ErrorMessage name="amount" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span v-tag="'transaction_number'">{{ $t('Transaction No') }}</span>
                                                    <span v-tag="'ref_number'">{{ $t(' / Ref No') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="row">
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="text" class="form-control fw-boldest text-black-600" v-model="modalForm.transaction_number" name="transaction_number" v-custom >
                                                        </div>
                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                            <input type="text" class="form-control" v-model="modalForm.ref_number" name="ref_number" v-custom >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'" >
                                                    <span>{{ $t('Description') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" v-model="modalForm.description" name="description" v-custom >
                                                        <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                            <div class="dropdown dropup">
                                                                <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                                    <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                </button>
                                                                <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                    <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                        <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                            <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                                :class="{'text-success': this.modalForm.description == item.description && item.description !== ''}">
                                                                                <i class="bi bi-dash fw-boldest" v-if="this.modalForm.description !== item.description"> </i> 
                                                                                <i class="bi bi-check-lg fw-boldest text-success" v-if="this.modalForm.description == item.description && item.description !== ''"> </i>
                                                                                {{item.text}}  
                                                                            </a>
                                                                            <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <!-- OPTION DATE RECORD DATE İLE AYNI SETLENİR SQL SEVİYESİNDE -->
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card border border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h4 class="text-gray-800">
                                                {{ $t('Recently Used Accounts') }}
                                            </h4> 
                                        </div>
                                    </div>
                                    <div class="card-body" id="kt_contacts_list_body">
                                        <div class="scroll-y mh-500px h-xl-auto">
                                            <div v-for="(item, index) in recentlyUsedAccountsList" v-bind:key="item">
                                                <div class="d-flex flex-stack py-2">
                                                    <div class="d-flex align-items-center">
                                                        <div class="symbol symbol-40px symbol-circle cursor-pointer">
                                                            <span v-if="this.modalForm.account_id !== item.id" class="symbol-label fs-6 fw-bolder">
                                                                <a href="#" class="btn btn-sm btn-icon text-hover-primary btn-circle border border-1 border-gray-200" 
                                                                    @click="onOpenAccountReport(item)">
                                                                    <i class="fa fa-eye text-gray-600 fs-2"></i>
                                                                </a>
                                                            </span>
                                                            <span v-if="this.modalForm.account_id == item.id" class="svg-icon svg-icon-success svg-icon-2hx">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <a class="ms-4 cursor-pointer" @click="onSelectAccount(item)">
                                                            <span class="fs-6 fw-bold text-gray-800 text-hover-primary mb-2 cursor-pointer">
                                                                {{item.name}}
                                                            </span>
                                                            <div class="fw-bold fs-8 text-muted cursor-pointer">{{item.account_type_name}}
                                                                <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                <span v-if="this.modalForm.currency == this.localCurrency" class="fw-bold fs-8 text-muted cursor-pointer">{{item.local_balance}}</span>
                                                                <span v-if="this.modalForm.currency !== this.localCurrency" class="fw-bold fs-8 text-muted cursor-pointer">{{item.balance}}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="my-3" v-if="index !== recentlyUsedAccountsList.length - 1"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-4">
                                <div class="card">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'"
                                            @click="this.addDocketItem()" :disabled="this.isModalButtonDisabled || this.modalForm.item_type == '-1'">
                                            <strong>{{ $t('Add To List') }}</strong> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                            @click="this.updateDocketItem()" :disabled="this.isModalButtonDisabled">
                                            <strong>{{ $t('Update') }}</strong> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Transaction List -->
    <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <TransactionListModal ref="TransactionListModal" :transaction-type="8" />
    </div>

    <!--AccountReports-->
    <div class="modal" :class="{'fade show d-block': isViewStatementModal, 'd-none': !isViewStatementModal}" id="kt_modal_account_report_selection" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
        <AccountReports ref="AccountReports" :account-name="modalForm.account_name" :account-id="modalForm.account_id" :account-type="modalForm.account_type"/>
    </div>
  </div>  
</template>

<script>
import axios from 'axios' 
import moment from 'moment';
import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, DxScrolling } from 'devextreme-vue/data-grid';
import TransactionListModal from '../../Transaction/TransactionListModal.vue'; 
import $ from 'jquery';

export default {
  name: "TurnoverDiffCreate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, TransactionListModal, DxScrolling
  },
  data() {
    return {
        pageSize: 10,
        pageIndex: 0,        
        isOpenActionsDropdown: false, 
        isOpenDescriptionDropdown: false,
        form:  { 
            record_date: moment(),
            transaction_type: '-1',
            general_description: null,
            description: null,
            is_accounted: 1
        },
        modalForm: {
            id: null,
            account_type: 1, 
            account_id: '-1',
            item_type: '-1',
            branch_id: null,
            expense_center_id: null,
            rate_date: moment(),
            currency: null,
            amount: 0.00,
            amount_local: 0.00,
            description: null,
            due_date: moment(),
            rate: 1,
            transaction_number: null,
            ref_number: null,
            quantity: 1.00,
            price: 0.00,
            account_type_name: null,
            account_name: '',
        },
        docketItemsList: [],
        docketSummary: {},
        transactionTypeList: [{ id: '-1', text: 'Select an option' }, { id: '11', text: 'Turnover' }, { id: '12', text: 'Difference'}],
        debitCreditList: [{ id: '-1', text: 'Select an option' }, { id: '1', text: 'Debit' }, { id: '2', text: 'Credit'}],
        debitCreditListForStock: [{ id: '-1', text: 'Select an option' }, { id: '1', text: 'Incoming' }, { id: '2', text: 'Outgoing'}],
        accountingVoucherOptionList: [{ id: 1, text: 'Yes' }, { id: 0, text: 'No' }],
        branchList: [],
        expenseCenterList: [],
        accountTypeList: [
            { id: 1, text: 'Customer'},
            { id: 2, text: 'Supplier'}, 
            { id: 3, text: 'Cash'},
            { id: 4, text: 'Bank'},
            { id: 5, text: 'Personnel'},
            { id: 6, text: 'Expense'},
            { id: 7, text: 'Stock'},
            { id: 8, text: 'Fixed Asset'},
            { id: 15, text: 'Other Account' },
            // { id: 10, text: 'Amortization'}
        ], 
        accountNameList: [{ id: '-1', text: 'Select an option' }],
        descriptionTypeList: [],
        localCurrency: null,
        modalType: 'addItem',
        isDocketItemSaving: false,
        isModalButtonDisabled: false,
        menuSelection: '/TurnoverAndDifferenceListHelppage',
        iconView : false,
        isViewStatementModal: false,
        isViewAddTurnoverModal: false,
        isSearchingAccount: false
    };
  },
  computed: { 
    isItemButtonDisabled() {
       return this.docketItemsList.find(item =>item.isSelected == true );
    },
     searchableList() { 
        return this.invoiceList.filter(p => { 
            return p.account_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 || 
                    p.invoice_type_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 ||
                    p.invoice_no.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            }
        ).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    setColumnTemplateForLocal(){
        return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.docketItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.docketItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    getSubCredentials() {
      return (operation)=>{
        let isView = false;
        if(this.form.transaction_type == 11) isView = this.$isCredentialActive(78, operation, 9);
        if(this.form.transaction_type == 12) isView = this.$isCredentialActive(79, operation, 9);
        return isView;
      }
    },
    transactionTypeFilteredList() {
      if(!this.$isCredentialActive(78, 'I', 9)) {
        return this.transactionTypeList.filter(p => p.id != 11);
      }
      if(!this.$isCredentialActive(79, 'I', 9)) {
        return this.transactionTypeList.filter(p => p.id != 12);
      }      
      return this.transactionTypeList;
    },
  },
  methods: { 
    getData() {
      this.$Progress.start();
      this.items = [];
      const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["branch", "expense_center", "currency"],
            account_type: 4 // yalnızca account settings listesi çekilecek ise gerekli
        }; 
        axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
            this.branchList = response.data.branch;
            this.expenseCenterList = response.data.expense_center;
            this.currencyList = response.data.currency; 
            this.$setSessionStorage('refreshList', true);
            if(this.modalType !== 'editItem'){ 
                this.branchList.forEach((value) => {
                    if(value.is_default){
                        this.modalForm.branch_id = value.id;
                    }
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.modalForm.expense_center_id = value.id;
                    }
                });
            }
            this.localCurrency = this.$getUser().currency_code;
            this.$Progress.finish();            
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    openDocketModal(modalMode) {
        this.isViewAddTurnoverModal = true;
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}
        
        this.modalType = modalMode;
        this.isDocketItemSaving = false;
        this.isModalButtonDisabled = false;
        this.isOpenDescriptionDropdown = false;
        this.accountNameList = [{ id: '-1', text: 'Select an option'}]; 

        // Form öğeleri sıfırlanır.
        this.modalForm.account_type = 1;
        this.modalForm.account_id = '-1';
        this.modalForm.transaction_number = null; 
        this.modalForm.amount = 0;
        this.modalForm.amount_local = 0;
        this.modalForm.rate = 1;
        this.modalForm.ref_number = null;
        this.modalForm.description = null;
        this.modalForm.currency = this.localCurrency;
        this.modalForm.quantity = 1;
        this.modalForm.price = 0;
        this.modalForm.rate_date = null;
        this.modalForm.item_type = '-1';
        this.modalForm.branch_id = '-1';
        this.modalForm.expense_center_id = '-1';
        this.modalForm.account_type_name = null;
        this.modalForm.account_name = '';
        this.modalForm.due_date = moment(this.form.record_date);
        this.recentlyUsedAccountsList = [];
        // Open Docket Modal in Edit Mode.
        if(this.modalType == 'editItem'){ 
            this.docketItemsList.forEach((item) => { 
                if(item.isSelected){  
                    this.modalForm.id = item.id; 
                    this.modalForm.expense_center_id = (item.expense_center_id == '' || item.expense_center_id == null) ? '-1': item.expense_center_id; 
                    this.modalForm.branch_id = (item.branch_id == '' || item.branch_id == null) ? '-1': item.branch_id;  
                    this.modalForm.transaction_number = item.transaction_number; 
                    this.modalForm.amount = parseFloat(item.amount.replace(/,/g, ''));
                    this.modalForm.amount_local = parseFloat(item.amount_local.replace(/,/g, ''));
                    this.modalForm.rate = parseFloat(item.rate.replace(/,/g, ''));
                    this.modalForm.ref_number = item.ref_number;
                    this.modalForm.description = item.description;
                    this.modalForm.transaction_date = moment(item.transaction_date); 
                    this.modalForm.due_date = moment(item.due_date); 
                    this.modalForm.account_type = item.account_type;
                    this.modalForm.currency = item.currency; 
                    this.modalForm.quantity = item.quantity; 
                    this.modalForm.price = parseFloat(item.price);
                    this.modalForm.item_type = item.item_type; 
                    this.modalForm.account_id = item.account_id;
                    this.modalForm.account_type_name = item.account_type_name;
                    this.modalForm.account_name = item.account_name;
                }
            });
            this.getAccounts(true);
        }
        
        const parameters = {
            session_id : this.$getUser().session_id,
            account_type : -1
        };
        const parametersTwo = {
            route: this.modalType == 'addItem'  ? 'TurnoverDiffItemCreate' : 'TurnoverDiffItemUpdate',
            session_id: this.$getUser().session_id
        };   
        const requestOne =  axios.post('/api/Transaction/GetLatestUsedAccounts', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/FormValidations/GetFormFields', parametersTwo, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {            
            this.recentlyUsedAccountsList = responses[0].data; 
            if(responses[1].data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(responses[1].data));
            }      
            this.getDescriptions(function() {});
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){ 
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local); 
            }
            else if(options.summaryProcess === 'finalize'){
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        } 
    },
    onChangeStockAmount() {
        const params = {
            session_id: this.$getUser().session_id,
            currency: this.modalForm.currency,
            stock_account_id: this.modalForm.account_id,
        }; 
        axios.post('api/TurnoverDifference/GetStockInstant', params, {'Content-Type': 'application/json'})
        .then((response) => {            
            this.modalForm.price = parseFloat(response.data);
            this.calculateLocalAmount();            
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    onChangeRateType(){
        const params = {
            session_id: this.$getUser().session_id,
            rate_date: moment(this.modalForm.transaction_date),
            bank_id: 1, //EUROPEAN BANK
            currency: this.modalForm.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                
                this.rateList = response.data;
                this.form.rate_list_id = response.data[0].id;
                this.modalForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.modalForm.rate_date = response.data[0].rate_date;
                this.calculateLocalAmount(function() {});
            }
            else{
                this.modalForm.rate = 1;
                this.modalForm.rate_date = null;
                this.rateList = null;
                this.calculateLocalAmount(function() {});
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency() {
        this.modalForm.rate = 1; 
        if(this.modalForm.currency !== this.localCurrency){

            axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
                .then((typeResponse) => {
                        this.typeList = typeResponse.data;
                        this.typeList.forEach((item) => { 
                            if(item.is_default == 1){
                                this.typeSelection = item.id;
                            }
                        });  

                        this.$Progress.start();
                        const params = {
                            session_id: this.$getUser().session_id,
                            rate_date: moment(this.modalForm.transaction_date),
                            bank_id: 1, //EUROPEAN BANK
                            currency: this.modalForm.currency
                        };
                        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                        var source = this.typeList.find((element) => element.id == this.typeSelection);
                        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            if(response.data.length > 0){
                                
                                this.rateList = response.data;
                                this.form.rate_list_id = response.data[0].id;
                                this.modalForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                                this.modalForm.rate_date = response.data[0].rate_date;
                                this.calculateLocalAmount(function() {});
                            }
                            else{
                                this.modalForm.rate = 1;
                                this.modalForm.rate_date = null;
                                this.calculateLocalAmount(function() {});
                            }
                            this.$Progress.finish();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$Progress.finish();
                        });  

                })
                .catch(function (error) {
                    console.log(error); 
                }); 
        }
    },
    calculateLocalAmount(callback) {
        if (this.modalForm.account_type == 7) {
            this.modalForm.amount = this.modalForm.quantity * this.modalForm.price;
        }        
        if(this.$getUser().currency_search_type == 1) {
            this.modalForm.amount_local = this.modalForm.amount / this.modalForm.rate;
        } else {
            this.modalForm.amount_local = this.modalForm.amount * this.modalForm.rate;
        }
        if(typeof callback == 'function'){
            callback();
        }
    },
    onSelectAccount(selection){
        this.modalForm.account_id = selection.id;
        this.modalForm.account_name = selection.name;
        this.modalForm.account_type = selection.account_type;
        this.modalForm.branch_id = (selection.branch_id == '' || selection.branch_id == null) ? '-1' : selection.branch_id;
        this.modalForm.expense_center_id = (selection.expense_center_id == '' || selection.expense_center_id == null) ? '-1' : selection.expense_center_id;
        if (selection.account_type == 3) {
            this.modalForm.currency = (selection.cash_currency == '' || selection.cash_currency == null) ? '-1' : selection.cash_currency;
        }
        if (selection.account_type == 4) {
            this.modalForm.currency = (selection.bank_currency == '' || selection.bank_currency == null) ? '-1' : selection.bank_currency;
        }
        this.modalForm.price = 0;
        this.modalForm.amount = 0;
        this.modalForm.amount_local = 0.00;
        this.getAccounts(true);
        this.onChangeCurrency();
    },
    addDocketItem(){
        this.$checkSelect2Validation('turnoverItemForm');
        if(!document.forms['turnoverItemForm'].reportValidity()){
            return;
        }
        if(this.modalForm.due_date == null || this.modalForm.due_date == '' || this.modalForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.modalForm.amount == null || this.modalForm.amount == '' || this.modalForm.amount == undefined) { this.modalForm.amount = 0; }
        if (this.modalForm.price == null || this.modalForm.price == '' || this.modalForm.price == undefined) { this.modalForm.price = 0; }
        if (this.modalForm.quantity == null || this.modalForm.quantity == '' || this.modalForm.quantity == undefined) { this.modalForm.quantity = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            item_type: this.modalForm.item_type,
            account_id: this.modalForm.account_id,
            due_date: this.modalForm.due_date,
            transaction_number: this.modalForm.transaction_number,
            currency: this.modalForm.currency,
            amount: this.modalForm.amount,
            rate: this.modalForm.rate,
            ref_number: this.modalForm.ref_number,
            description: this.modalForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            expense_center_id: this.modalForm.expense_center_id,
            branch_id: this.modalForm.branch_id,
            price: this.modalForm.price,
            quantity: this.modalForm.quantity
        };
        
        params.due_date = moment(params.due_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        if(params.expense_center_id == '-1'){ params.expense_center_id = null; }
        if(params.branch_id == '-1'){ params.branch_id = null; }
        if(params.item_type == '-1'){ params.branch_id = null; }
        params.quantity = this.modalForm.account_type != 7 ? 0 : params.quantity;

        axios.post('api/TurnoverDifference/CreateTempItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                    this.docketSummary = response.data.summary;
                    this.docketItemsList = response.data.items;
                    this.$refs.closeModal.click();
                }  
            }
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            that.isDocketItemSaving = false;
            that.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });  
    },
    updateDocketItem(){
        this.$checkSelect2Validation('turnoverItemForm');
        if(!document.forms['turnoverItemForm'].reportValidity()){
            return;
        }
        if(this.modalForm.due_date == null || this.modalForm.due_date == '' || this.modalForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.modalForm.amount == null || this.modalForm.amount == '' || this.modalForm.amount == undefined) { this.modalForm.amount = 0; }
        if (this.modalForm.price == null || this.modalForm.price == '' || this.modalForm.price == undefined) { this.modalForm.price = 0; }
        if (this.modalForm.quantity == null || this.modalForm.quantity == '' || this.modalForm.quantity == undefined) { this.modalForm.quantity = 0; }
        this.isModalButtonDisabled = true;
        this.isDocketItemSaving = true;
        const that = this;
        const params = { 
            id: this.modalForm.id,
            item_type: this.modalForm.item_type,
            account_id: this.modalForm.account_id,
            due_date: this.modalForm.due_date,
            transaction_number: this.modalForm.transaction_number,
            currency: this.modalForm.currency,
            amount: this.modalForm.amount,
            rate: this.modalForm.rate,
            ref_number: this.modalForm.ref_number,
            description: this.modalForm.description,
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            expense_center_id: this.modalForm.expense_center_id,
            branch_id: this.modalForm.branch_id,
            price: this.modalForm.price,
            quantity: this.modalForm.quantity
        };
        
        params.due_date = moment(params.due_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        if(params.expense_center_id == '-1'){ params.expense_center_id = null; }
        if(params.branch_id == '-1'){ params.branch_id = null; }
        params.quantity = this.modalForm.account_type != 7 ? 0 : params.quantity;
        
        axios.post('api/TurnoverDifference/UpdateTempItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                if(response.data.items.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                    this.docketSummary = response.data.summary;
                    this.docketItemsList = response.data.items;
                    this.$refs.closeModal.click();
                }  
            } 
            this.isDocketItemSaving = false;
        })
        .catch(function () {
            this.isDocketItemSaving = false;
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");
        })
        .finally(function () { 
            setTimeout(() => {
                that.isDocketItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
    },
    deleteDocketItem() {
         this.$swal.fire({
            title: "Are you sure you want to delete this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start();
                var docketId = null; 
                this.docketItemsList.forEach((item) => { 
                    if(item.isSelected){ 
                        docketId = item.id; 
                    }
                });
                const params = { 
                    id: docketId, 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                };
                axios.post('api/TurnoverDifference/DeleteTempItem', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        if(response.data.items.length > 0){
                            this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                        }  
                        this.docketSummary = response.data.summary;
                        this.docketItemsList = response.data.items;
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    deleteAllDocketItems() {
         this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start();
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                };
                axios.post('api/TurnoverDifference/DeleteAllTempItems', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.$removeSessionStorage('tmp_session_id'); 
                        this.docketSummary = [];
                        this.docketItemsList = [];
                        this.$refs.closeModal.click();
                    } 
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
        }); 
    },
    createDocket() {
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to save this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true;
                const that = this;
                const params = { 
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    record_date: this.form.record_date,
                    docket_type: this.form.transaction_type,
                    description: this.form.description,
                    is_accounted: this.form.is_accounted
                };  
                params.record_date = moment(params.record_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('api/TurnoverDifference/CreateHeader', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.$removeSessionStorage('tmp_session_id'); 
                        this.$swal("Saved", "Transaction saved successfully..!", 'success');
                        this.$setSessionStorage('record_id', response.data); 
                        this.$setSessionStorage('refreshList', true);  
                        this.$router.push({ name: 'TurnoverDiffUpdate' });
                    }  
                    this.$Progress.finish();
                })
                .catch(function () { 
                    this.$Progress.finish();
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                })
                .finally(function () { 
                    setTimeout(() => {
                        that.isDocketSaving = false;
                        that.$Progress.finish(); 
                    }, 500);
                }); 
            }
        });
    },
    onSelectItem(item) {
        this.docketItemsList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });    
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                     if(column.dataField == 'amount_local'){ 
                         column.caption =  'Local Amount';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    getDescriptions(callback) {
        this.isOpenDescriptionDropdown = false;
        if (this.modalType == 'addItem') { this.modalForm.description = null; }
        const params = {
            session_id: this.$getUser().session_id,
            description_location: this.form.transaction_type == '11' ? 8 : 9, // 8: Turnover , 9: Difference
            location_detail: this.modalForm.item_type,
            account_type: this.modalForm.account_type !== -1 ? this.modalForm.account_type : 0,
            sales_type: 0
        };
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2 && this.modalType == 'addItem') {
                    this.modalForm.description = element.description;
                }
            });
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    onSelectDescription(item) {
        this.modalForm.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    async helpPageViewIcon(){
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/TurnoverAndDifferenceListHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onOpenAccountReport(item){
        let accountInfo = {
            id: (item !== null && item !== undefined && item !== '') ? item.id : this.modalForm.account_id, 
            name: (item !== null && item !== undefined && item !== '') ? item.name : this.modalForm.account_name
        };
        this.isViewStatementModal = true;
        this.isViewAddTurnoverModal = false;
        this.$refs.AccountReports.getData(accountInfo);
        this.$refs.AccountReports.setModalCriteriasToDefault();
    }, 
    onCloseModal(){
        this.isViewAddTurnoverModal = false;
    },
    getAccounts(isEditMode) {
        if((this.modalForm.account_name === null || this.modalForm.account_name === undefined || this.modalForm.account_name.length < 1) && !this.$getUser().is_allow_list_all_data) return;
        if(!isEditMode) this.modalForm.account_id = '-1';
        this.isSearchingAccount = true;
        const accountParams = {
            session_id : this.$getUser().session_id,
            account_type : this.modalForm.account_type,            
            search_text: this.modalForm.account_name == null ? "" : this.modalForm.account_name,
            search_parameter: 1  // 1: Search By Account Name
        };  
        const that = this;
        axios.post('api/CompanyAccounts/GetAccounts', accountParams, {'Content-Type': 'application/json'}).then((response) => { 
            this.accountNameList = response.data.account_data;
            this.accountNameList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.accountNameList.forEach((item) => {
                item.text = item.text + ' (' + item.account_type_name + ')';
            });
            this.accountNameList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});
            if(!isEditMode) {
                var $optionBox = $("#account_id").select2();
                setTimeout(() => {$optionBox.select2("open");}, 100);
            }
        })
        .catch(function(error) {
            console.log(error);
        })
        .finally(function () { 
            that.isSearchingAccount = false;
        });
    },
    onChangeAmount() {
        if(this.modalForm.account_id == null || this.modalForm.account_id == '' || this.modalForm.account_id == '-1' || this.modalForm.account_id == undefined) return;
        const parameters = {
          session_id: this.$getUser().session_id,
          account_id: this.modalForm.account_id
        };  
        axios.post('/api/CompanyAccounts/GetAccountBalance', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.modalForm.amount = this.modalForm.currency !== this.localCurrency ? Math.abs(parseFloat(response.data.total_balance.replaceAll(',', ''))) : Math.abs(parseFloat(response.data.total_balance_local.replaceAll(',', '')));
                this.calculateLocalAmount();
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onChangeAccountType() {
        this.modalForm.account_id = '-1';
        this.modalForm.account_name = null;
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.getData();   
  }
}; 
</script>