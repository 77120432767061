<template>
  <Field ref="inputRef" type="text" as="input"> </Field>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { Field } from "vee-validate";
import { watch } from 'vue';
export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
    precision: Object,
    min: Object,
    max: Object
  },
  components: {
    Field
  },
  setup(props) { 
    var options = {
      currency: "GBP",
      currencyDisplay: "hidden",
      precision: (props.precision !== null && props.precision !== undefined)  ? props.precision : 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      useGrouping: true,
      accountingSign: false,
      locale: "en-US"
    };  

    if(props.min != null && props.min != undefined){
      options.valueRange = { "min": props.min };
    } 
    if(props.max != null && props.max != undefined){
      options.valueRange = { "max": props.max };
    }
    if(props.min != null && props.min != undefined && props.max != null && props.max != undefined){
      options.valueRange = { "min": props.min, "max": props.max };
    }
        
    const { inputRef, setValue, setOptions  } = useCurrencyInput(options);

    watch(
      () => props.modelValue, 
      (value) => {
        setValue(value)
      } 
    )

    watch(
      () => props.options,
      (options) => {
        setOptions(options)
      }
    ) 

    return { inputRef };
  },
};
</script>