<template> 
  <div> 
    <div class="ms-4">
        <div class="d-flex">
            <div class="p-2 flex-grow-1">
                <div class="page-title flex-row"> 
                    <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('General Profit & Loss Statement') }}
                    </h1> 
                    <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                        <li class="breadcrumb-item text-gray-600">
                            <a href="#" class="text-gray-600 text-hover-primary">
                                <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                            </a>
                        </li> 
                        <li class="breadcrumb-item text-primary">{{ $t('Accounting') }}</li>  
                        <li class="breadcrumb-item text-primary">{{ $t('Profit & Loss Statement') }}</li>    
                        <li class="breadcrumb-item text-gray-600">{{ $t('General Profit & Loss Statement') }}
                            <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                        </li> 
                    </ul>  
                </div>  
            </div> 
        </div> 
    </div>
    <div class="content flex-row-fluid mt-4">
        <div class="card">
            <div class="row p-9 pb-4">
                    <div class="col-lg-9">
                       <div class="fv-row mb-4 row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Accounting Period') }}</span> 
                                    </label>   
                                    <Select2 v-model="filterForm.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event, true)"/>  
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('View Type') }}</span>
                                    </label> 
                                    <Select2 v-model="filterForm.view_type" :options="viewTypesList" :settings="{ width: '100%' }"/> 
                                </div> 
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Report Type') }}</span>
                                    </label>
                                    <Select2 v-model="filterForm.report_type" :options="reportTypes"  :settings="{ width: '100%' }" @select="onReportTypeChange()"/> 
                                </div> 
                                <div class="col-lg" v-if="filterForm.report_type == 2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Accounting Months') }}</span>
                                    </label> 
                                    <Select2 v-model="filterForm.period_month" :options="periodList" :settings="{ width: '100%' }"/> 
                                </div>  
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Empty Accounts') }}</span>
                                    </label>
                                    <Select2 v-model="filterForm.with_empty" :options="viewEmptyList" :settings="{ width: '100%' }"/>  
                                </div> 
                                <div class="col-lg"  v-if="filterForm.report_type == 3">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                </div> 
                                <div class="col-lg"  v-if="filterForm.report_type == 3">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                </div>  
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label" style="margin-top: -9px;">
                                        <span>{{ $t('Use Profit and Loss') }} <br>{{ $t('Operation Vouchers only') }}</span> 
                                    </label>   
                                    <Select2 v-model="filterForm.except_closing_items" :options="closingList" :settings="{ width: '100%' }"/>  
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                           <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(59,'R')">   
                                <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                    </template>
                                </Popper> 
                                <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on"> 
                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                                </button>
                            </div>
                    </div> 
            </div>
            <div class="card-body pt-3">
                <div v-if="this.lists && this.lists.length > 0">
                    <div class="bg-light p-3 text-center" v-if="filterForm.report_type == 1">
                        <h3 class="text-center"> 
                            {{ this.filterForm.periodSelection.period_year_month_short }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            {{ $t('Profit & Loss Statement') }}
                        </h3>
                        <span class="text-center mb-0 fw-bold"> ({{ previewOnlyDate(this.filterForm.periodSelection.start_date) }} - {{ previewOnlyDate(this.filterForm.periodSelection.end_date) }}) </span>
                    </div>
                    <div class="bg-light p-3" v-if="filterForm.report_type == 2">
                        <h3 class="text-center mb-0"> 
                            {{ this.getPeriodMonthValue(this.filterForm.period_month) }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            {{ $t('Profit & Loss Statement') }} 
                        </h3>
                    </div>
                    <div class="bg-light p-3" v-if="filterForm.report_type == 3">
                        <h3 class="text-center mb-0"> 
                            {{ previewOnlyDate(filterForm.start_date) }} - {{ previewOnlyDate(filterForm.end_date) }}
                            {{ this.getReportTypeValue(this.filterForm.report_type) }}
                            {{ $t('Profit & Loss Statement') }} 
                        </h3>
                    </div>
                </div>
                <div class="separator my-3 mt-0 border-gray-300"></div>
                <div>
                    <div class="table-responsive min-h-300px">
                        <!-- Profit And Loss Yes seçilen tüm accountlar gelecek -->
                        <DxDataGrid id="gridContainer"
                                :show-borders="true"
                                key-expr="id"
                                :data-source="lists"
                                :column-auto-width="true"
                                :row-alternatin-enabled="true"
                                data-row-template="dataRowTemplate"
                                :hover-state-enabled="true"
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                @row-prepared="onRowPrepared" 
                                @exporting="this.$onExporting($event , 'Plans-with-balances')"> 

                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="10000"/>
                                <DxPager
                                    :visible="false"
                                    :allowed-page-sizes="pageSizes"
                                    :show-page-size-selector="false"
                                    :show-info="false"
                                    :show-navigation-buttons="false"
                                />
                                <DxFilterRow :visible="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxSorting mode="none"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="plan_level" :caption="$t('Level')" alignment="center"  width="80"/> 
                                <DxColumn data-field="final_code" :caption="$t('Plan Code')" header-cell-template="accounting-plan-code-header"/> 
                                <DxColumn data-field="name" :caption="$t('Plan Name')"  header-cell-template="accounting-plan-name-header"/> 
                                <DxColumn data-field="total_debit" :sorting-method="this.$numericSort" :caption="$t('Total Debit')" alignment="right" header-cell-template="total-debit-header"/> 
                                <DxColumn data-field="total_credit" :sorting-method="this.$numericSort" :caption="$t('Total Credit')" alignment="right" header-cell-template="total-credit-header"/> 
                                <DxColumn data-field="balance" :sorting-method="this.$numericSort" :caption="$t('Balance Debit')" alignment="right" header-cell-template="balance-header"/> 
                                <DxColumn data-field="id" caption="" alignment="center" :allow-filtering="false" :allow-exporting="false" width="40"/>   

                                 <template #total-debit-header>
                                    <div>{{ $t('Total') }}</div><div>{{ $t('Debit') }}</div>
                                </template>
                                 <template #accounting-plan-code-header>
                                    <div>{{ $t('Plan') }}</div><div>{{ $t('Code') }}</div>
                                </template>
                                <template #accounting-plan-name-header>
                                    <div>{{ $t('Plan') }}</div><div>{{ $t('Name') }}</div>
                                </template>
                                 <template #total-credit-header>
                                    <div>{{ $t('Total') }}</div><div>{{ $t('Credit') }}</div>
                                </template>
                                 <template #balance-header>
                                    <div>{{ $t('Balance') }}</div>
                                </template>

                                <template #dataRowTemplate="{ data: data }">
                                    <td class="align-middle text-center"> 
                                        <span><small>{{ data.data.plan_level }}</small></span>
                                    </td>
                                     <td class="align-middle"> 
                                        <span class="fw-boldest" v-if="data.data.plan_level != 3">{{ data.data.final_code }}</span>
                                        <span v-if="data.data.plan_level == 3">{{ data.data.final_code }}</span> 
                                    </td>
                                    <td class="align-middle"> 
                                        <span class="fw-boldest" v-if="data.data.plan_level != 3">{{ data.data.name }}</span>
                                        <span v-if="data.data.plan_level == 3">{{ data.data.name }}</span>
                                    </td>
                               
                                    <td class="text-end align-middle">  
                                        <div> 
                                            <span class="fw-boldest" v-if="data.data.plan_level != 3">
                                                 <span>
                                                    {{ data.data.total_debit }}
                                                </span> 
                                            </span>
                                            <span v-if="data.data.plan_level == 3"> 
                                                <span>
                                                    {{ data.data.total_debit }}
                                                </span> 
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-end align-middle">
                                        <span class="fw-boldest" v-if="data.data.plan_level != 3">  
                                            <span>
                                                {{ data.data.total_credit }}
                                            </span>  
                                        </span>
                                        <span v-if="data.data.plan_level == 3"> 
                                            <span>
                                                {{ data.data.total_credit }}
                                            </span>  
                                        </span> 
                                    </td>
                                    <td class="text-end align-middle"> 
                                         <span>
                                            {{ data.data.balance }}
                                        </span>
                                        <span>
                                            <i class="fa fa-minus text-gray-400 ms-3" v-if="data.data.balance == '0.00'"></i>
                                            <i class="bi bi-caret-up-fill text-success ms-3" v-if="data.data.balance.startsWith('-') && data.data.balance !== '0.00'"></i>
                                            <i class="bi bi-caret-down-fill text-danger ms-3" v-if="!data.data.balance.startsWith('-') && data.data.balance !== '0.00'"></i>
                                        </span> 
                                    </td>
                                    <td class="text-center w-30px align-middle">
                                        <button class="btn btn-sm btn-icon btn-secondary btn-icon-dark btn-text-dark w-20px h-20px" @click="viewDetail(data.data)" v-if="data.data.plan_level == 3"
                                                data-bs-toggle="modal" data-bs-target="#kt_modal_view_detail" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span class="svg-icon svg-icon-muted icon-size-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                        </button>
                                    </td>
                                </template>

                                <DxSummary :calculate-custom-summary="setSummaries"> 

                                     <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="total_debit"
                                        summary-type="custom"
                                        show-in-column="total_debit"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="total_credit"
                                        summary-type="custom"
                                        show-in-column="total_credit"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="balance"
                                        summary-type="custom"
                                        show-in-column="balance"
                                        display-format="{0}"
                                    /> 

                                    <DxTotalItem
                                        name="total_debit"
                                        summary-type="custom"
                                        show-in-column="total_debit"
                                    />
                                    <DxTotalItem
                                        name="total_credit"
                                        summary-type="custom"
                                        show-in-column="total_credit"
                                    />
                                    <DxTotalItem
                                        name="balance"
                                        summary-type="custom"
                                        show-in-column="balance"
                                    /> 
                                </DxSummary>

                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="storageKey"
                                        :custom-save="saveState"
                                    />
                        </DxDataGrid>    
                    </div>
                </div> 
            </div>
        </div> 
    </div>  
     
    <div class="modal fade" id="kt_modal_view_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content"  >
                <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="this.detailHeader">  
                        <span> {{ this.detailHeader.final_code }} - {{ this.detailHeader.name }} - </span>
                        <span v-if="filterForm.report_type == 1"> {{ this.filterForm.periodSelection.period_year_month_short }} {{ this.getReportTypeValue(this.filterForm.report_type) }} - {{ $t('Trial Balance') }}  </span>
                        <span v-if="filterForm.report_type == 2"> {{ this.getPeriodMonthValue(this.filterForm.period_month) }} {{ this.getReportTypeValue(this.filterForm.report_type) }} - {{ $t('Trial Balance') }}  </span>
                        <span v-if="filterForm.report_type == 3"> {{ previewOnlyDate(filterForm.start_date) }} - {{ previewOnlyDate(filterForm.end_date) }} {{ this.getReportTypeValue(this.filterForm.report_type) }} - {{ $t('Trial Balance') }}  </span>
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row"> 
                                 <div class="card p-2">  
                                    <div class="card-body p-2">
                                            <div> 
                                                <div class="alert alert-dismissible bg-light-warning d-flex flex-column flex-sm-row w-100 p-5 justify-content-end"
                                                     v-if="this.detailTurnover && this.latestParameterCollection.report_type != 1"> 
													<div class="d-flex flex-column pe-0 pe-sm-10"> 
                                                        <span class="text-end">
                                                            {{ $t('Turnover Local Balance') }}: <strong>{{ this.detailTurnover.prev_total_balance }} </strong>
                                                        </span>
													</div> 
												</div>   
                                                <div class="table-responsive min-h-300px">                                                    
                                                    <DxDataGrid id="gridContainer"
                                                            :show-borders="true"
                                                            key-expr="id"
                                                            :data-source="detailList"
                                                            :allow-column-reordering="true" 
                                                            :allow-column-resizing="true"
                                                            :column-auto-width="true"
                                                            :row-alternatin-enabled="true"
                                                            data-row-template="dataRowTemplate"
                                                            :hover-state-enabled="true"
                                                            :show-row-lines="true"
                                                            :customize-columns="onCustomizeDetailColumn" 
                                                            @exporting="this.$onExporting($event , 'Plans-with-balances')" >   
                                                            
                                                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                            <DxPaging :page-size="100"/>
                                                            <DxPager
                                                                :visible="true"
                                                                :allowed-page-sizes="pageDetailSizes"
                                                                :show-page-size-selector="true"
                                                                :show-info="true"
                                                                :show-navigation-buttons="true"
                                                            />
                                                            <DxFilterRow :visible="true"/>
                                                            <DxSearchPanel :visible="true"/>
                                                            <DxSorting mode="none"/>

                                                            <DxColumn data-field="record_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy"/> 
                                                            <DxColumn data-field="account_plan_full_code" :caption="$t('Code')"/> 
                                                            <DxColumn data-field="account_name" :caption="$t('Account')" /> 
                                                            <DxColumn data-field="transaction_no" :caption="$t('Trans.No')" header-cell-template="transaction-number-header"/> 
                                                            <DxColumn data-field="reference_no" :caption="$t('Ref.No')" header-cell-template="reference-number-header"/> 
                                                            <DxColumn data-field="transaction_date" :caption="$t('Trans.Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="transaction-date-header"/> 
                                                            <DxColumn data-field="source_name" :caption="$t('Source')"/> 
                                                            <DxColumn data-field="amount_type" :caption="$t('Amount Type')" header-cell-template="amount-type-header"/>   
                                                            <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right"/>  
                                                            <DxColumn data-field="amount" :caption="$t('Amount')" alignment="right" :min-width="150"/>
                                                            <DxColumn data-field="currency" :caption="$t('Currency')"/> 
                                                            <DxColumn data-field="local_amount" :caption="$t('Local Amount')" header-cell-template="local-amount-header" alignment="right" :min-width="150"/>
                                                            <DxColumn data-field="local_balance" :caption="$t('Local Balance')" header-cell-template="local-balance-header" alignment="right" :min-width="150"/>
                                                            <DxColumn data-field="is_manuel_name" :caption="$t('Status')" :min-width="150"/>
                                                            <DxColumn data-field="description" :caption="$t('Description')" :min-width="250"/>
                                                            
                                                             <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                            <template #headerLocalTemplate="{ data: data }">
                                                                <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                            </template>

                                                            <template #transaction-number-header>
                                                                <div>{{ $t('Transaction') }}</div><div>{{ $t('Number') }}</div>
                                                            </template>
                                                            <template #reference-number-header>
                                                                <div>{{ $t('Reference') }}</div><div>{{ $t('Number') }}</div>
                                                            </template>
                                                            <template #transaction-date-header>
                                                                <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                                            </template>
                                                            <template #amount-type-header>
                                                                <div>{{ $t('Amount') }}</div><div>{{ $t('Type') }}</div>
                                                            </template>
                                                            <template #local-amount-header>
                                                                <div>{{ $t('Amount') }}</div><div>{{ $t('Local') }}</div>
                                                            </template>
                                                            <template #local-balance-header>
                                                                <div>{{ $t('Local') }}</div><div>{{ $t('Balance') }}</div>
                                                            </template>

                                                            <template #dataRowTemplate="{ data: data }"> 
                                                                <tr class="align-middle border border-gray-200">
                                                                        <td class="text-start border border-end border-gray-200 p-3 text-end"> 
                                                                            <small>{{ previewOnlyDate(data.data.record_date) }} </small>
                                                                        </td>
                                                                        <td class="border border-end border-gray-200 p-3"> 
                                                                            <span>{{ data.data.account_plan_full_code }} </span>
                                                                            <br> <small>{{ data.data.account_plan_name }} </small>
                                                                        </td>
                                                                        <td class="border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.account_name }}
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.transaction_no }} 
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.reference_no }}  
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3 text-end"> 
                                                                            <small>{{ previewOnlyDate(data.data.transaction_date) }} </small>
                                                                        </td> 
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.source_name }}
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.amount_type }}
                                                                        </td>
                                                                        <td class="text-end border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.rate }}
                                                                        </td>
                                                                        <td class="text-end border border-end border-gray-200 p-3">
                                                                            {{ data.data.amount }}
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.currency }}
                                                                        </td>
                                                                        <td class="text-end border border-end border-gray-200 p-3">
                                                                            {{ data.data.local_amount }}
                                                                        </td>
                                                                         <td class="text-end border border-end border-gray-200 p-3">
                                                                            {{ data.data.local_balance }}
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.is_manuel_name }}
                                                                        </td>
                                                                        <td class="text-start border border-end border-gray-200 p-3"> 
                                                                            {{ data.data.description }}
                                                                        </td>
                                                                </tr>
                                                            </template> 

                                                            <DxSummary :calculate-custom-summary="setDetailSummaries"> 
                                                                <DxTotalItem
                                                                    name="total_detail_debit"
                                                                    summary-type="custom"
                                                                    show-in-column="amount"
                                                                    display-format="T.Debit: {0}"
                                                                />
                                                                <DxTotalItem
                                                                    name="total_detail_credit"
                                                                    summary-type="custom"
                                                                    show-in-column="local_amount"
                                                                    display-format="T.Credit: {0}" 
                                                                />
                                                                <DxTotalItem
                                                                    name="total_detail_balance"
                                                                    summary-type="custom"
                                                                    show-in-column="local_balance"
                                                                    display-format="T.Balance: {0}" 
                                                                />
                                                            </DxSummary>
                                                    </DxDataGrid>
                                            </div> 
                                        </div>
                                    </div> 
                                </div>
                        </div>
                    </form>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
import { DxDataGrid, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxStateStoring, DxSorting, DxSearchPanel, DxFilterRow, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "GeneralProfitAndLossList",
    components:{
        DxDataGrid, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxStateStoring, DxSorting, DxSearchPanel, DxFilterRow, DxScrolling
    },
    data() {
        return {
            pageSizes: [50, 100, 200, 400, 1000, 'all'],
            storageKey: "datagrid-state",
            accountingYears: [],
            lists: [],
            summary: {},
            accountingYearList: [],
            reportTypes: [{ id: '1', text: 'General'}, { id: '2', text: 'Monthly'}, { id: '3', text: 'Date Range'}],
            viewTypesList: [ { id: '1', text: 'With Details'}, { id: '2', text: 'Main Plans Only'} ],
            periodList: [],
            viewEmptyList: [ { id: 0, text: 'No View'}, { id: 1, text: 'View All'} ],
            closingList: [ { id: 0, text: 'No'}, { id: 1, text: 'Yes'} ],
            searchbar: '',
            detailList: [],
            detailSummary: {},
            detailTurnover: null,
            latestParameterCollection: null,
            filterForm: {
                start_date: moment(),
                end_date: moment(),
                report_type: 1,
                period_month: null,
                with_empty: 0,
                view_type: 1,
                period_id: null,
                periodSelection: {},
                except_closing_items: 0
            },
            userData: this.$getUser(),
            detailHeader: null,
            isFilterOpen: false,
            isListProgressing: false,
            isAccountingYearLoading: false,
            localCurrency: null,
            menuSelection: '/GeneralProfitHelppage',
            iconView : false,
            pagesize: 10,
            pageSizeDetailList: 10
        };
    },
    computed: {
        getFloatValue(){
            return (value)=>{
                if (value) 
                {
                    return parseFloat(value);
                }
            }
        },
        setColumnTemplateForLocal(){
            return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
        },
        searchableList() {
            return this.lists.filter(p => { 
                return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            });
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        previewOnlyDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        getReportTypeValue(){
          return (value)=>{
                if (value) {
                    return this.reportTypes.find(p => { 
                        return p.id == value;
                    }).text; 
              }
          } 
        },
        getPeriodMonthValue(){
             return (value)=>{
                if (value) {
                    var period = this.periodList.find(p => { 
                        return p.id == value && p.id != '-1';
                    }); 
                    if(period){
                        return period.text;
                    }
                    else{
                        return null;
                    }
              }
          } 
        },
        getColumnTotalValue(){
            return (column)=>{
                if (column && this.lists) {
                    var totalValue = 0;
                    var listData = this.lists.filter((item) => item.plan_level == 1);
                    listData.forEach((item) => { 
                        totalValue = totalValue + parseFloat(item[column].replaceAll(',', ''));
                    });
                    return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(totalValue);
                }
            }
        }
    },
    methods: {
        getData() {
            this.lists = [];
            this.latestParameterCollection = null;
            this.isListProgressing = true;
            this.$Progress.start(); 
            const queryParameter = {
                session_id: this.$getUser().session_id,
                start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                report_type: this.filterForm.report_type,
                period_month: this.filterForm.period_month,
                with_empty: this.filterForm.with_empty,
                view_type: this.filterForm.view_type,
                period_id: this.filterForm.period_id,
                except_closing_items: this.filterForm.except_closing_items
            };  
            axios.post('/api/ProfitAndLoss/GetPlans', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {  
                    this.lists = response.data.plans;
                    this.summary = response.data.summary;  
                    this.latestParameterCollection = queryParameter;
                    this.isListProgressing = false; 
                    this.localCurrency = this.$getUser().currency_code; 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                    this.isListProgressing = false;
                });
        },
        onReportTypeChange(){
            this.periodList = [];
            if(this.filterForm.report_type == 2){
                this.$Progress.start(); 
                const queryParameter = {
                    session_id: this.$getUser().session_id,
                    period_id: this.filterForm.period_id
                };   
                axios.post('/api/ProfitAndLoss/GetPeriodMonths', queryParameter, {'Content-Type': 'application/json'}) 
                    .then((response) => { 
                        this.periodList = response.data;
                        this.periodList.unshift({ id: -1, text: 'Select an option'});
                        this.filterForm.period_month = -1;
                        this.$Progress.finish();
                    })
                    .catch(function () {
                        this.$Progress.finish();
                    });
            } 
        },
        viewDetail(data){
            this.detailList = [];
            this.detailHeader = null;
            this.$Progress.start(); 
            const queryParameter = {
                session_id: this.$getUser().session_id,
                accounting_plan_id: data.id,
                report_type: this.latestParameterCollection.report_type,
                period_month: this.latestParameterCollection.period_month,
                start_date: this.latestParameterCollection.start_date,
                end_date: this.latestParameterCollection.end_date,
                period_id: this.latestParameterCollection.period_id
            }; 
            axios.post('/api/ProfitAndLoss/GetPlanDetailById', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {  
                    this.detailList = response.data.plans;
                    this.detailSummary = response.data.summary;  
                    this.detailTurnover = response.data.turnover;
                    this.detailHeader = data;
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });
        },
        setSummaries(options){
            if(options.name == "total_debit") { options.totalValue = this.getColumnTotalValue('total_debit') }
            if(options.name == "total_credit") { options.totalValue = this.getColumnTotalValue('total_credit') }
            if(options.name == "balance") { options.totalValue = this.getColumnTotalValue('balance') } 
        },
        setDetailSummaries(options){
            if(options.name == "total_detail_debit") { options.totalValue = this.detailSummary.total_debit }
            if(options.name == "total_detail_credit") { options.totalValue = this.detailSummary.total_credit }
            if(options.name == "total_detail_balance") { options.totalValue = this.detailSummary.total_balance }
        }, 
        getAccountingYears(){
            this.isAccountingYearLoading = true;
            this.companyAccountingPeriods = [];
            const queryParameter = {
                session_id: this.$getUser().session_id
            };
            axios.post('/api/Auth/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => {   
                    this.accountingYears = response.data;
                    this.accountingYears.forEach((period) => {
                        if(period.id == this.$getSessionStorage('activeYear')){
                            this.filterForm.period_id = period.id; 
                            this.filterForm['periodSelection'] = period;
                            this.filterForm.start_date = this.filterForm['periodSelection'].start_date;
                            this.filterForm.end_date = this.filterForm['periodSelection'].end_date;
                        } 
                    });  
                    setTimeout(() => {
                        if(this.filterForm.period_id == null){ 
                            this.accountingYears.forEach((period) => {
                                if(period.id == this.userData.period_id){
                                    this.filterForm.period_id = period.id;
                                } 
                            }); 
                        }
                    }, 300); 
                    setTimeout(() => {
                        this.isAccountingYearLoading = false;
                    }, 1000); 
            })
            .catch(function (error) {
                this.isAccountingYearLoading = false;
                console.log(error);
            }); 
        }, 
        onSaveGridView(){
          var stateDetails = localStorage.getItem(this.storageKey);
          const params = {
              session_id: this.$getUser().session_id,
              user_id: this.$getUser().id,
              state_detail: stateDetails,
              table_code: 'BS001'
          };
          axios.post("/api/Users/SetGridSession", params, {'Content-Type': 'application/json'}).then(() => {   
          })
          .catch(function (error) {
              console.log(error);
          });  
        },
        loadState() {
            return JSON.parse(localStorage.getItem(this.storageKey));
        },
        saveState(state) { 
            localStorage.setItem(this.storageKey, JSON.stringify(state));
        },
        onChangeAccountYear(e, isChangeForced){
            this.filterForm['periodSelection'] = e; 
            if((e.id != this.$getSessionStorage('activeYear')) || isChangeForced){
                this.filterForm.end_date = moment(e.end_date);
            }
            if((e.id != this.$getSessionStorage('activeYear')) || isChangeForced){
                this.filterForm.start_date = moment(e.start_date);
            }
            
            if (this.filterForm.report_type == 2) {
                this.onReportTypeChange();
            }
        },
        onCustomizeDetailColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {   
                        if(column.dataField == 'local_amount'){ 
                            column.caption =  'Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'local_balance'){ 
                            column.caption =  'Local Balance';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
        onCellPrepared: function () {
            // if (e.rowType == 'data') {
            //     // Positive Coloring
            //     if (e.column.dataField == 'balance' && (e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#f5fffc';
            //     }
            //     if (e.column.dataField == 'total_credit' && (e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#f5fffc';
            //     }
            //     if (e.column.dataField == 'total_debit' && (e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#f5fffc';
            //     }

            //     // Negative Coloring
            //     if (e.column.dataField == 'balance' && (!e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#fdf6f8';
            //     }
            //     if (e.column.dataField == 'total_credit' && (!e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#fdf6f8';
            //     }
            //     if (e.column.dataField == 'total_debit' && (!e.value.startsWith('-') && e.value !== '0.00')) {
            //         e.cellElement.bgColor = '#fdf6f8';
            //     }
            //     if ((e.value == '0.00')) {
            //         e.cellElement.bgColor = '#fffef6';
            //     }
            // }
        },
        onRowPrepared(e){   
             if(e.rowType === 'data') {
                if (e.data.plan_level == '1') {
                    e.rowElement.classList.add('fw-boldest');  
                    e.rowElement.classList.add('bg-level-1');
                }
                if (e.data.plan_level == '2') {
                    e.rowElement.classList.add('fw-bolder');  
                    e.rowElement.classList.add('bg-level-2');
                }
            } 
        }, 
        setCriteriasToDefault() {
            this.filterForm = {
                start_date: moment(),
                end_date: moment(),
                report_type: 1,
                period_month: null,
                with_empty: 0,
                view_type: 1,
                period_id: null,
                periodSelection: {},
                except_closing_items: 0
            }; 
            this.getAccountingYears();
            this.lists = [];
        },
        async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/GeneralProfitHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    },
    created() {
        this.helpPageViewIcon();
        this.getAccountingYears();
    },
    mounted() {
        
    }
}; 
</script>