<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
      <div class="page-title d-flex flex-column me-3 mt-3"> 
        <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create User Group') }}</h1>
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                </a>
            </li> 
          <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
          <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserManagement' }">{{ $t('User Management') }}</router-link></li>
          <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'UserGroupList' }">{{ $t('User Group') }}</router-link></li> 
          <li class="breadcrumb-item text-gray-600">{{ $t('Create User Group') }}</li> 
          <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
        </ul> 
      </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/UserGroupList">
          <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
            <span class="ms-2 fs-7"> {{ $t('Return back to List') }} </span>
          </button>
        </router-link>
      </div>  
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
      <RequiredField></RequiredField>
      <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12 mt-0"> 
              <div class="d-flex flex-row row">
                <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                  <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3 required">
                            {{ $t('Group Name') }}
                          </label>
                          <input type="text" class="form-control" name="name" v-model="form.name"> 
                        </div>
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3">
                            {{ $t('Description') }}
                          </label>
                          <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                        </div>
                        <div class="col-lg-12">
                          <label class="fs-6 fw-bold form-label mt-3" for="isActive">
                            {{ $t('Active') }}
                          </label>
                          <div class="form-check mt-3 mb-3">
                            <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="form.is_active">
                          </div> 
                        </div>
                        <div class="col-lg-12">
                          <div class="alert alert-warning d-flex align-items-center p-3 mt-5">
                            <span class="svg-icon svg-icon-muted svg-icon-2 me-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                              <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                              <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                              </svg>
                            </span>
                            <div class="d-flex flex-column">
                              <span class="text-gray-800">{{ $t('You must save this group first in order to set the authorization settings for this group and add users to the group.') }}.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div> 
              </div>
            </div>
            <div class="col-lg-12">
              <div class="d-grid gap-2"> 
                <button type="button" class="btn btn-lg btn-success " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                  <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                  <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                  </svg>
                  </span>
                  <strong v-if="!this.isProgressing"> {{ $t('Save User Groups') }} </strong> 
                  <span class="indicator-progress" v-if="this.isProgressing">
                      {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span> 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>
  
<script>
  import axios from 'axios';   
  export default {
    name: "UserGroupCreate", 
    data() {
      return {
        menuSelection: '/UserGroupHelppage',
        iconView : false,
        form: {
          id: null,
          name: null,
          description: null,
          is_active: true
        },
        lists: []
      };
    },
    methods: {
      saveData() {
        const parameters = {
          session_id : this.$getUser().session_id,
          name: this.form.name,
          description: this.form.description,
          is_active: this.form.is_active ? 1 : 0
        };  
        axios.post('/api/UserGroup/CreateGroup', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){          
            this.$setSessionStorage("record_id", response.data);
            this.$swal("Success", "Data saved succesfully..!", 'success');    
            this.$setSessionStorage('refreshList', true);  
            this.$router.push({ name: 'UserGroupUpdate' });
          }
        })
        .catch(function (error) {
           console.log(error);
        });
      },
      async helpPageViewIcon()
      {
        const queryparameter = {
          session_id : this.$getUser().session_id,
          menu_selection : '/UserGroupHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(!response.data.error){ 
            this.iconView = response.data;
          }
        })
      },
  },
  mounted() {
    this.helpPageViewIcon();
  }
}; 
</script>