<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Other Account ') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'OtherAccountList' }">{{ $t('Other Account') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Other Account ') }} 
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/OtherAccountList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content"> 
            <RequiredField></RequiredField>              
            <div class="card card-xxl-stretch"> 
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-10">
                        <div class="col-lg-12 mt-0"> 
                            <div class="d-flex flex-row row">
                                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                        <div class="card-body"> 
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-12" v-if="isViewAccountId">
                                                        <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                            {{ $t('Account ID') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'">{{ $t('Name') }}
                                                        </label>
                                                        <input type="text" class="form-control" name="name" v-model="form.name" v-custom>
                                                    </div>                                              
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'other_account_group'">{{ $t('Other Account Group') }}
                                                        </label>
                                                        <Select2 v-model="form.other_account_group_id" :options="otherAccountGroupList" @select="onChangeOtherAccountGroup($event)" name="other_account_group" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'other_account_type'">
                                                            <span>{{ $t('Other Account Type') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.other_account_type_id" :options="otherAccountTypeList" name="other_account_type" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_sub_type'">
                                                            <span>{{ $t('Account Sub Type') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.policy_account_type" :options="policyAccountTypeList" name="account_sub_type" v-custom :settings="{ width: '100%'}"/>
                                                    </div>
                                                    <div class="col-lg-12" v-if="form.policy_account_type !== 0">
                                                        <div class="form-check mt-5">
                                                            <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                            <label class="fs-6 fw-bold form-label" for="isDefault">
                                                                {{ $t('Default') }}
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </div> 
                                        </div>
                                    </div> 
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-6 bg-lighten border border-gray-300">
                                        <div class="card-body"> 
                                                <div class="fv-row mb-6 row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">
                                                            <span>{{ $t('Contact Name') }}</span>
                                                        </label>
                                                        <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">
                                                                <span>{{ $t('Email') }}</span>
                                                            </label>
                                                            <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}" v-custom>
                                                            <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">
                                                                <span>{{ $t('Mobile Phone') }}</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                                <input type="text" class="form-control" name="mobile_phone" v-model="form.mobile_phone" v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="fv-row">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                                <span>{{ $t('Phone') }}</span>
                                                            </label>
                                                            <div class="input-group">
                                                                <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                                <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                            <span>{{ $t('Branch') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_center'">
                                                            <span>{{ $t('Expense Center') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.expense_center_id" :options="expenseCenterList" name="expense_center" v-custom :settings="{ width: '100%'}"/> 
                                                    </div>                                                
                                                </div> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div>
                                        <div class="fv-row row mb-7">
                                            <div class="col-lg-4">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'show_on_invoice'">
                                                    <span>{{ $t('Show on Invoice?') }}</span>
                                                </label>
                                                <Select2 v-model="form.is_show_invoice" :options="showInvoiceList" name="show_on_invoice" v-custom :settings="{ width: '100%'}"/> 
                                            </div>
                                            <div class="col-lg-4" v-if="form.other_account_group_type_id == 1 && form.is_show_invoice == 1">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_status'">
                                                    <span>{{ $t('VAT Status') }}</span>
                                                </label>
                                                <Select2 v-model="form.vat_status_id" :options="vatStatusList" :settings="{ width: '100%' }" @select="onChangeVatStatus()" name="vat_status" v-custom/> 
                                            </div>
                                            <div class="col-lg-4" v-if="form.other_account_group_type_id == 1 && form.is_show_invoice == 1">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_rate'">
                                                    <span>{{ $t('VAT Rate') }}</span>
                                                </label>
                                                <Select2 v-model="form.vat_rate" :options="vatRateList" :settings="{ width: '100%' }" :disabled="this.form.vat_status_id == 1" name="vat_rate" v-custom/>
                                            </div>
                                        </div>      
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12 mt-0"  v-if="this.$isCredentialActive(18,'I')">
                            <div class="d-grid gap-2"> 
                                <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <strong v-if="!this.isProgressing"> {{ $t('Save Other Account') }} </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
 
export default {
  name: "OtherAccountCreate",
  data() {
    return {
      userData: this.$getUser(),
      bankList: [],
      branchList: [],
      expenseCenterList: [],
      otherAccountGroupList: [{id: '-1', text: 'Select an option'}],
      otherAccountTypeList: [{id: '-1', text: 'Select an option'}],
      showInvoiceList: [ {id: '1', text: 'Yes'}, {id: '0', text: 'No'}],
      vatStatusList: [
        { id: 1, text: 'Select an option'},
        { id: 2, text: 'VAT (+)'},
        { id: 3, text: 'VAT (in)'}
      ],
      vatRateList: [],
      phoneMaskList: [],
      isProgressing: false,
      form: {
        name: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null,
        other_account_group_id: '-1',
        other_account_type_id: '-1',
        branch_id: '-1',
        expense_center_id: '-1',
        accounting_plan: null,
        is_show_invoice: 0,
        vat_rate: '0.00',
        other_account_group_type_id: null,
        vat_status_id: 1,
        contact_name: null,
        email: null,
        mobile_phone: null,
        phone: null,
        phone_country_code: null,
        land_phone_country_code: null,
        policy_account_type: 0,
        is_default: false
      },
      isViewAccountId: false,
      menuSelection: '/OtherHelppage',
      iconView : false,
      policyAccountTypeList: [
        {id: 0, text: 'Select an option'}, 
        {id: 1 , text: 'Insurance Commission'}, 
        {id: 2 , text: 'Vendor Commission'}, 
        {id: 3, text: 'Policy Taxes'}
      ],
    };
  },
  computed: {
    isValidEmail() {
        return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    }
  },
  methods: {
    getData() {
        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["branch", "expense_center", "account_setting"],
            account_type: 15, // yalnızca account settings listesi çekilecek ise gerekli
            list_types: [
                {
                    list_type: 17,
                    list_name: "other_account_group"
                },
                {
                    list_type: 15,
                    list_name: "vat_rate"
                }
            ],
        };  
        
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            
            this.branchList = responses[0].data.branch;
            this.expenseCenterList = responses[0].data.expense_center;
            this.branchList.forEach((value) => {
                if(value.is_default) {
                    this.form.branch_id = value.id;
                }
            });
            this.expenseCenterList.forEach((value) => {
                if(value.is_default) {
                    this.form.expense_center_id = value.id;
                }
            });
            if (responses[0].data.account_setting.is_default == false) {
                this.isViewAccountId = true;
            }
            this.otherAccountGroupList = responses[1].data.other_account_group;
            this.otherAccountGroupList.unshift({ id: '-1', text: 'Select an option'});
            this.otherAccountGroupList.forEach((value) => {
                if (value.type_id == 1) { value.text = value.text + ' (Other Income)'; }
                if (value.type_id == 2) { value.text = value.text + ' (Other Current Account)'; }
                if (value.type_id == 3) { value.text = value.text + ' (Other Payables)'; }
                if(value.is_default) {
                    this.form.other_account_group_id = value.id;
                }
            });
            this.vatRateList = responses[1].data.vat_rate;
            this.vatRateList.forEach((value) => {
                value.id = value.val;
            });
            this.vatRateList.unshift({ id: '0.00', text: 'Select an option'});
            this.vatRateList.forEach((value) => {
                if(value.is_default) {
                    this.form.vat_rate = value.val;
                }
            });
            this.phoneMaskList = responses[2].data;            
            this.phoneMaskList.forEach(element => {
                if(element.code == this.$getUser().company_country_code) {
                    this.form.phone_country_code = element.id;
                    this.form.land_phone_country_code = element.id;
                }
            });
            
            const params = {
                session_id: this.$getUser().session_id,
                parent_id: this.form.other_account_group_id
            };
            axios.post("/api/Helper/GetOtherAccountTypesByParentId", params, {'Content-Type': 'application/json'}).then((typeResponse) => { 
                this.otherAccountTypeList = typeResponse.data;
                this.otherAccountTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.otherAccountTypeList.forEach((value) => {
                    if(value.is_default) {
                        this.form.other_account_type_id = value.id;
                    }
                });
                this.$Progress.finish();
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
            });
        }))
        .catch(errors => { 
            console.error(errors); 
        }); 
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/OtherHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params = {
        id:this.form.id,
        name:this.form.name,
        account_type:this.form.account_type,
        account_id:this.form.account_id,
        qr_code:this.form.qr_code,
        notes:this.form.notes,
        other_account_group_id:this.form.other_account_group_id == '-1' ? null : this.form.other_account_group_id,
        other_account_type_id:this.form.other_account_type_id == '-1' ? null : this.form.other_account_type_id,
        branch_id:this.form.branch_id == '-1' ? null : this.form.branch_id,
        expense_center_id:this.form.expense_center_id == '-1' ? null : this.form.expense_center_id,
        accounting_plan:this.form.accounting_plan,
        is_show_invoice:this.form.is_show_invoice,
        vat_rate:this.form.vat_rate,
        other_account_group_type_id:this.form.other_account_group_type_id,
        vat_status_id:this.form.vat_status_id,
        contact_name:this.form.contact_name,
        email:this.form.email,
        mobile_phone:this.form.mobile_phone,
        phone:this.form.phone,
        phone_country_code: this.form.phone_country_code,
        land_phone_country_code: this.form.land_phone_country_code,
        policy_account_type: this.form.policy_account_type,
        is_default: this.form.is_default ? 1 : 0
      };    
      params.session_id = this.$getUser().session_id; 
      params.account_type = 15; // Other Account: 15
      const that = this; 
      axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'OtherAccountUpdate' });
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeOtherAccountGroup(groupItem) {
        if (groupItem.id !== '-1') { this.form.other_account_group_type_id = groupItem.type_id; }
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.form.other_account_group_id
        };
        axios.post("/api/Helper/GetOtherAccountTypesByParentId", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.otherAccountTypeList = response.data;
            this.otherAccountTypeList.unshift({ id: '-1', text: 'Select an option'});
            this.form.other_account_type_id = '-1';
            this.otherAccountTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.other_account_type_id = value.id;
                }
            });
            this.$Progress.finish();
        })
    },
    onChangeVatStatus() {
        if(this.form.vat_status_id == 1) {this.form.vat_rate = '0.00';}
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>