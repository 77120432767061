<template>
    <div>
        <div class="card-body">
            <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                <div class="fv-row mb-6 row">   
                    <div class="col-lg-12">
                        <div class="fv-row">
                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                {{ $t('Vendor') }}
                            </label> 
                            <Select2 v-model="form.vendor_id" :options="vendorSelectionList" :disabled="!this.$parent.updateForm.is_save_vendor"
                                name="vendor" v-custom :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/> 
                        </div>
                    </div>
                    <div class="col-lg-12" v-if="form.vendor_id !== '-1' && form.vendor_id !== '' && form.vendor_id !== null">
                        <small class="text-muted fw-bold"> 
                            <i class="bi bi-info-circle text-muted fs-7"></i> {{ $t('Commission rates must be defined to add this vendor account to the transaction...') }}.
                        </small>
                    </div>
                </div> 
            </form>

            <div class="d-flex flex-stack flex-wrap mb-5">
                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" ref="btnAddItem" @click="this.addVendorToTempTransaction()"
                        :disabled="form.vendor_id == '-1' || form.vendor_id == '' || form.vendor_id == null">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                    fill="currentColor">
                                </rect>
                            </svg>
                        </span>
                        {{ $t('Add') }}
                    </button>
                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="removeVendorToTempTransactionAccount()" 
                        :disabled="isVendorButtonDisabled == null || isVendorButtonDisabled == undefined">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        {{ $t('Remove') }}
                    </button>
                    <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="removeVendorToTempTransactionAccount(true)" 
                        :disabled="this.transactionVendorList.length == 0">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </span>
                        {{ $t('Remove All (!)') }}
                    </button>
                </div>
            </div>
            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="table-responsive">
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="id" 
                            :data-source="transactionVendorList" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" > 

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="false"/>
                            <DxPaging :page-size="100000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                     
                            <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template"/>
                            <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template" :allowEditing="false"/>
                            <DxColumn data-field="branch_name" :caption="$t('Branch')" cell-template="branch_name-cell-template" :allowEditing="false"/>
                            <DxColumn data-field="sales_channel_name" :caption="$t('Sales Channel')" cell-template="sales_channel_name-cell-template" :allowEditing="false"/>
                            <DxColumn data-field="campaign_name" :caption="$t('Sales Role / Campaign')" cell-template="campaign_name-cell-template" :allowEditing="false"/>
                            <DxColumn data-field="source_code" :caption="$t('Source Code 1')" cell-template="source_code-cell-template" :allowEditing="false"/> 
                            <DxColumn data-field="source_code_2" :caption="$t('Source Code 2')" cell-template="source_code_2-cell-template" :allowEditing="false"/>
                            
                            <template #is-selected-cell-template="{ data }"> 
                                <span>
                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                    </div>
                                </span>
                            </template>
                            <template #vendor_name-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.vendor_name }}
                                </span>
                            </template>
                            <template #branch_name-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.branch_name }}
                                </span>
                            </template>
                            <template #sales_channel_name-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.sales_channel_name }}
                                </span>
                            </template>
                            <template #campaign_name-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.campaign_name }}
                                </span>
                            </template>
                            <template #source_code-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.source_code }}
                                </span>
                            </template>
                            <template #source_code_2-cell-template="{ data }"> 
                                <span>
                                    {{ data.data.source_code_2 }}
                                </span>
                            </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn } from 'devextreme-vue/data-grid';
export default {
    name:'TransactionMultiVendorModal',
    data() {
        return {
            form: {
                policy_transaction_id: null,
                vendor_id: '-1'
            },
            vendorSelectionList: [],
            transactionVendorList: [],
        }
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn
    },
    computed: {
        isVendorButtonDisabled() {
            return this.transactionVendorList.find(item => item.isSelected == true && item.id !== '');
        }
    },
    methods:{ 
        getData(policyTransactionId){
            this.$removeSessionStorage('mvn_tmp_session_id');
            this.form = {
                policy_transaction_id: policyTransactionId,
                vendor_id: '-1'
            };
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_transaction_id: this.form.policy_transaction_id
            };
            const requestOne =  axios.post('/api/GetCompanyAccounts/GetTrnVendorAccountsForSelection', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/DLLTemplates/GetVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.vendorSelectionList = responses[0].data;
                this.transactionVendorList = responses[1].data;
                this.vendorSelectionList.unshift({ id: '-1', text: 'Select an option'});
                if(this.transactionVendorList.length > 0){ this.$setSessionStorage('mvn_tmp_session_id', responses[1].data[0].tmp_session_id); }
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        addVendorToTempTransaction() {
            const parameters = {
                session_id: this.$getUser().session_id,
                tmp_session_id: this.$getSessionStorage('mvn_tmp_session_id'),
                policy_transaction_id: this.form.policy_transaction_id,
                vendor_id: this.form.vendor_id !== '-1' ? this.form.vendor_id : null
            };  
            axios.post('/api/DLLTemplates/AddVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.transactionVendorList = response.data;
                    if(this.transactionVendorList.length > 0){ this.$setSessionStorage('mvn_tmp_session_id', response.data[0].tmp_session_id); }
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        },
        removeVendorToTempTransactionAccount(isDeleteAll) {
            let vendorId = null;
            this.transactionVendorList.forEach((item) => {
                if (item.isSelected) {
                    vendorId = item.id;
                }
            });
            this.$swal.fire({
                title: "Are you sure you want to remove this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        id: vendorId,
                        is_delete_all: isDeleteAll ? 1 : 0,
                        tmp_session_id: this.$getSessionStorage('mvn_tmp_session_id'),
                    }; 
                    axios.post('/api/DLLTemplates/RemoveVendorToTempTransactionAccount', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        this.transactionVendorList = response.data;
                    }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });
                }
            });
        },
        onSelectItem(item) {
            this.transactionVendorList.forEach((value) => { 
                if (item.id !== value.id) {
                    value.isSelected = false;
                }
            });
        },
    }
}
</script>