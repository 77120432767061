<template>
  <div class="d-flex flex-column flex-center flex-column-fluid">    
    <div class="d-flex flex-column flex-center text-center p-10">        
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder text-gray-800 mb-5">
            Session Timed Out
          </h1>
          <div class="fw-semibold fs-6 text-gray-500 mb-8">
            Your login session has expired due to <b> {{inactiveTime}} </b> minutes inactivity. 
            <br>
            Click the <b class="fw-boldest">Back to Login</b> button to login again.
          </div>
          <div class="mb-11">
            <router-link to="/Login">
              <button type="button" class="btn btn-sm btn-primary">
                Back to Login
              </button>
            </router-link>
          </div>
          <div class="mb-0">
            <img src="assets/theme/media/illustrations/unitedpalms-1/20.png" class="mw-100 mh-300px theme-light-show" alt="">
            <img src="assets/theme/media/illustrations/unitedpalms-1/20-dark.png" class="mw-100 mh-300px theme-dark-show" alt="">
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import SecureLS from "secure-ls";  
const ls = new SecureLS({ isCompression: false });

export default {
  name: "Logout",
  mounted() {   
    sessionStorage.clear();
  },
  data() {
    return {      
      inactiveTime: this.$route.params.m ? this.$route.params.m : 0
    };
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = `url('/assets/theme/media/auth/bg1.jpg')`;
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');
      let element = document.getElementsByClassName("modal-backdrop");
      if(element.length > 0) {
        element[0].className = "";
      }

      try{
        for (var a in localStorage) { 
          if(a.substring(0,2) == 'dx'){
            ls.remove(a);
          }
        }
      }
      catch(Error){ console.log(Error);}
    }
  },
  created() {
     this.getData();
  }
};
</script>