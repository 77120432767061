<template>
  <div>  
      <div class="page-title flex-row me-3 mt-3"> 
          <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">
            {{ $t('Documents') }}
          </h1>   
          <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
              <li class="breadcrumb-item text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></a>
              </li> 
              <li class="breadcrumb-item text-gray-600">{{ $t('Documents') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
              </li> 
          </ul> 
      </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
         <!-- FILE & FOLDER MANAGER SECTION -->
         <div class="card mb-5 mb-xl-10 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm bg-abstract-2">
                  <div class="card-body pt-6 pb-6">
                  <div class="d-flex flex-stack">
                      <div class="me-7 mb-6">
                          <div class="symbol symbol-90px symbol-lg-90px symbol-fixed position-relative">
                              <img src="assets/theme/media/avatars/blank.png" alt="image">
                              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                          </div>
                      </div>
                      <div class="flex-grow-1">
                          <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                              <div class="d-flex flex-column">
                                  <div class="d-flex align-items-center mb-2">
                                      <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{ userData.name_surname }}</a>
                                      <a href="#">
                                          <span class="svg-icon svg-icon-1 svg-icon-primary">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                  <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                                                  <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                                              </svg>
                                          </span>
                                      </a>
                                  </div>
                                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                      <span class="text-gray-400"> {{ userData.company_name }} </span>
                                  </div>
                              </div>
                          </div>
                      </div> 
                      <div class="flex-grow-1">
                          <div class="d-flex flex-column">
                              <div class="d-flex flex-wrap justify-content-end"> 
                                  <div class="nav-group nav-group-outline bg-white">
                                      <button type="button" class="btn btn-sm btn-active-light-info btn-hover-light-info me-2 px-6 py-3" :class="{'active': activeMainView == 'all_documents'}" @click="changeMainActiveView('all_documents')">
                                          <span class="svg-icon svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H6C6.6 2 7 2.4 7 3V6C7 6.6 6.6 7 6 7Z" fill="currentColor"/>
                                          <path opacity="0.3" d="M13 7H10C9.4 7 9 6.6 9 6V3C9 2.4 9.4 2 10 2H13C13.6 2 14 2.4 14 3V6C14 6.6 13.6 7 13 7ZM21 6V3C21 2.4 20.6 2 20 2H17C16.4 2 16 2.4 16 3V6C16 6.6 16.4 7 17 7H20C20.6 7 21 6.6 21 6ZM7 13V10C7 9.4 6.6 9 6 9H3C2.4 9 2 9.4 2 10V13C2 13.6 2.4 14 3 14H6C6.6 14 7 13.6 7 13ZM14 13V10C14 9.4 13.6 9 13 9H10C9.4 9 9 9.4 9 10V13C9 13.6 9.4 14 10 14H13C13.6 14 14 13.6 14 13ZM21 13V10C21 9.4 20.6 9 20 9H17C16.4 9 16 9.4 16 10V13C16 13.6 16.4 14 17 14H20C20.6 14 21 13.6 21 13ZM7 20V17C7 16.4 6.6 16 6 16H3C2.4 16 2 16.4 2 17V20C2 20.6 2.4 21 3 21H6C6.6 21 7 20.6 7 20ZM14 20V17C14 16.4 13.6 16 13 16H10C9.4 16 9 16.4 9 17V20C9 20.6 9.4 21 10 21H13C13.6 21 14 20.6 14 20ZM21 20V17C21 16.4 20.6 16 20 16H17C16.4 16 16 16.4 16 17V20C16 20.6 16.4 21 17 21H20C20.6 21 21 20.6 21 20Z" fill="currentColor"/>
                                          </svg>
                                          </span>
                                      </button>
                                      <button type="button" class="btn btn-sm btn-active-light-primary btn-hover-light-primary px-6 py-3" :class="{'active': activeMainView == 'folders'}" @click="changeMainActiveView('folders')">
                                          <span class="svg-icon svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path opacity="0.3" d="M20 14H11C10.4 14 10 13.6 10 13V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V13C21 13.6 20.6 14 20 14ZM21 20V17C21 16.4 20.6 16 20 16H11C10.4 16 10 16.4 10 17V20C10 20.6 10.4 21 11 21H20C20.6 21 21 20.6 21 20Z" fill="currentColor"/>
                                          <path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor"/>
                                          </svg>
                                          </span>
                                      </button>
                                  </div>
                              </div>
                          </div> 
                      </div>
                  </div>
              </div>
          </div>

         <!-- FOLDERS VIEW -->
         <div class="card" v-if="this.activeMainView == 'folders'">
              <div class="card-header pt-3">
                <h3 class="card-title">Folders</h3>
                <div class="card-toolbar">  
                    <div class="d-flex justify-content-end">
                      <!-- <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#add_folder_modal">
                        <span class="svg-icon svg-icon-2">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor"></path>
                          </svg>
                        </span>
                        New Folder
                      </button> -->
                      <!-- <button type="button" class="btn btn-primary" @click="getDocumentTypesForUpload()" data-bs-toggle="modal" data-bs-target="#upload_modal" v-if="this.activeFolder != null">
                          <span class="svg-icon svg-icon-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                              <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor"></path>
                            </svg>
                          </span>
                          Upload a File
                      </button> -->
                    </div> 
                </div>
              </div>
              <div class="card-body" style="min-height: 700px !important;"> 
                  <div class="d-flex flex-stack">
                    <div class="badge badge-lg badge-light-primary">
                      <div class="d-flex align-items-center flex-wrap text-gray-900">
                        <span class="svg-icon svg-icon-2 svg-icon-muted me-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z" fill="currentColor"/><path d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z" fill="currentColor"/><path opacity="0.3" d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z" fill="currentColor"/><path opacity="0.3" d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z" fill="currentColor"/></svg></span>
                          <a href="#" class="text-gray-700" @click="this.getFolders()">All Folders</a>
                        <span v-for="bcItem in parentFolders" v-bind:key="bcItem" @click="this.onOpenFolder(bcItem)">
                          <span class="svg-icon svg-icon-2 svg-icon-muted mx-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor"></path></svg></span>
                          <a href="#" class="text-gray-900"> {{ bcItem.name }}</a> 
                        </span>
                      </div>
                    </div>
                  </div> 
                  <div class="table-responsive mt-4"> 
                      <div  v-if="this.lists">
                          <div class="px-3 py-4" v-if="parentFolders.length > 0" style="display: none;">
                              <div class="card shadow-sm bg-hover-lighten" @click="this.onOpenFolder(this.activeFolder, true)">
                                  <div class="card-header">
                                      <h3 class="card-title py-3">
                                          <div class="d-flex align-items-center">
                                              <div class="d-flex align-items-center flex-grow-1">
                                                  <div class="symbol symbol-45px me-5"> 
                                                      <i class="fas fa-folder fs-2x me-4" style="color: rgb(0, 158, 247);"></i> 
                                                  </div> 
                                                  <div class="d-flex flex-column">
                                                      <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bold">
                                                          <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"/>
                                                          <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"/>
                                                          </svg>
                                                          </span>
                                                      </a>
                                                  </div>
                                              </div>
                                          </div>
                                      </h3> 
                                  </div> 
                              </div>
                          </div>


                          <!-- FOLDERS -->
                          <div class="px-3 py-3" v-for="(item, index) in searchableFoldersList" v-bind:key="item">
                              <div class="card shadow-sm bg-hover-lighten cursor-default">
                                  <div class="card-header">
                                      <h3 class="card-title py-3" @click="onOpenFolder(item)">
                                          <div class="d-flex align-items-center">
                                              <div class="d-flex align-items-center flex-grow-1">
                                                  <div class="symbol symbol-45px me-5">
                                                    <i :class="item.icon + ' fs-2x me-4'" :style="'color:' + item.color"></i>
                                                  </div> 
                                                  <div class="d-flex flex-column">
                                                      <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bold">
                                                        {{ item.name }}
                                                      </a>
                                                      <small class="mt-1 fs-8"> {{ item.description }} </small>
                                                  </div>
                                              </div>
                                              
                                          </div>
                                      </h3>
                                      <div class="card-toolbar">
                                          <!-- <div class="d-flex flex-wrap mx-10" @click="onOpenFolder(item)">
                                              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
                                                  <div class="fs-6 text-gray-800 fw-bold text-center"> 
                                                      <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> 
                                                          File Size Limit: {{ calculateSize(item.size_limit) }}   
                                                      </span>
                                                  </div>
                                                  <div class="fs-6 text-gray-800 fw-bold text-center"> 
                                                      <span class="fs-7 fw-semibold text-gray-400 me-1 mt-n1"> 
                                                          {{ previewDate(item.created_at) }} 
                                                      </span>
                                                  </div>
                                              </div>
                                          </div> -->
                                          <!-- <button type="button" class="btn btn-sm btn-light-primary me-3" style="font-size:11px !important;" @click="openEditModal(item)" data-bs-toggle="modal" data-bs-target="#edit_folder_modal">
                                              Edit Folder 
                                          </button> -->
                                          <div class="collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" :data-bs-target="'#kt_accordion_folder_'+ index" aria-expanded="true">
                                              <span class="svg-icon svg-icon-1 svg-icon-primary rotate-180">
                                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path></svg>
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- <div class="card-body p-1">
                                      <div class="row">
                                          <div class="col-lg-6">
                                            <span class="badge badge-sm badge-warning">
                                              Status
                                            </span>
                                          </div>
                                        <div class="col-lg-6">
                                              <div class="text-end me-10"> 
                                                  Test
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->
                                  <div :id="'kt_accordion_folder_' + index" class="collapse">
                                      <div class="card-body d-flex align-items-end"> 
                                          <div class="row align-items-center mx-0 w-100">
                                            <div class="col-lg-12">
                                              <div class="d-flex justify-content-end">
                                                <!-- <div class="ms-2 pb-4">
                                                    <button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" @click="openItemActionMenu(item)"> 
                                                      <span class="svg-icon svg-icon-5 m-0">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                          <rect x="17" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                          <rect x="3" y="10" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                        </svg>
                                                      </span>
                                                    </button>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-250px py-4" 
                                                        style="position: absolute; right: 0px;"
                                                        :class="{ 'show': item.isOpenActions }"> 
                                                      <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3" @click="openEditModal(item)" data-bs-toggle="modal" data-bs-target="#edit_folder_modal">Edit</a>
                                                      </div>
                                                      <div class="menu-item px-3">
                                                        <a href="#" target="_self" class="cursor-pointer menu-link px-3" data-bs-toggle="modal" data-bs-target="#set_document_type_modal" @click="getDocumentTypes(item)">Set Document Types</a>
                                                      </div> 
                                                      <div class="menu-item px-3">
                                                        <a href="#" target="_self" class="cursor-pointer menu-link px-3" @click="deleteFolder(item)">Delete</a>
                                                      </div> 
                                                    </div>
                                                  </div> -->
                                              </div>
                                            </div>
                                            <div class="card">
                                              <div class="row mt-4"> 
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                                  <span >{{ $t('Size') }} :</span>
                                                  &nbsp; 
                                                  <span> {{ calculateSize(item.size_limit) }} </span>
                                                </div>
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                  <span>{{ $t('Created At') }} :</span>
                                                  &nbsp; 
                                                  <span> {{ previewDate(item.created_at) }}</span>
                                                </div>
                                                <div class="col-lg-4 mt-3">
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                                  <span>{{ $t('Allowed Ext') }} :</span>
                                                  &nbsp; 
                                                  <span> {{ item.allowed_ext }} </span>
                                                </div>
                                                <div class="col-lg-4 mt-3">  
                                                  <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                  <span>{{ $t('Blocked Ext') }} :</span>
                                                  &nbsp; 
                                                  <span> {{ item.blocked_ext }} </span>
                                                </div>
                                                <div class="col-lg-12 mt-3 mb-4">
                                                    <span class="bullet bg-secondary me-3 bullet-md"></span>
                                                    <span>{{ $t('Description') }} :</span>
                                                    &nbsp; 
                                                    <span> 
                                                      <span> {{ item.description}} </span>
                                                    </span>
                                                </div>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- DOCUMENTS -->
                          <!-- FILTER SECTION -->
                          <div v-if="this.activeFolder != null">
                            <div class="card mb-3 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm mx-3 p-0 mt-3" 
                                style="background-image: url('assets/theme/media/svg/shapes/abstract-4.svg')">
                                <div class="card-body pt-0 pb-0">
                                    
                                    
                                    <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-1x justify-content-start align-items-start py-5 mb-2" role="tablist">  
                                       <li class="nav-item" role="presentation">
                                             <div class="symbol symbol-45px me-5 justify-content-start"><i class="fas fa-file fs-2x me-4" style="color: rgb(0, 158, 247);"></i></div>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(0)">{{ $t('Today') }}</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(1)">{{ $t('Yesterday') }}</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(3)">{{$t('Past 3 Days')}}</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary active fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(10)">{{ $t('Past 10 Days') }}</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(30)">{{ $t('Past 30 Days') }}</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="fw-bold px-4 collapsible cursor-pointer rotate collapsed justify-content-end align-items-end" data-bs-toggle="collapse" data-bs-target="#kt_criterias">
                                                <span class="svg-icon svg-icon-1 rotate-180">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="card no-border collapse" id="kt_criterias">                
                                        <div class="row py-3">
                                            <div class="col-lg-9">
                                                <div class="fv-row mb-4 row">
                                                    <div class="col-lg">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('Start Date') }}
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                                    </div>
                                                    <div class="col-lg">
                                                        <label class="fs-6 fw-bold form-label">
                                                            {{ $t('End Date') }}
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">   
                                                    <Popper hover placement="top">
                                                        <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                                            <i class="bi bi-eraser-fill fs-4"> </i>
                                                        </button>
                                                        <template #content>
                                                            <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                                        </template>
                                                    </Popper>  
                                                    <button type="button" class="btn btn-sm btn-primary" @click="getFolderDocuments()">
                                                        <span class="svg-icon svg-icon-muted">
                                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        <strong> {{ $t('List Documents') }} </strong>
                                                    </button>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- DOCUMENTS LIST -->
                            <div class="p-3" v-if="this.documents.length > 0">
                              <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="documents" 
                                    :allow-column-reordering="true"  
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true"
                                    @row-prepared="onRowPrepared"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>
                                <DxSearchPanel :visible="true"/>  
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="download" :caption="$t('Actions')" cell-template="download-cell-template" alignment="left" width="125"/>  
                                <DxColumn data-field="document_name" :caption="$t('Document Name')" cell-template="document_name-cell-template"/>
                                <DxColumn data-field="document_no" :caption="$t('Document No')" cell-template="document_no-cell-template"/>
                                <DxColumn data-field="name" :caption="$t('File Name')" cell-template="name-cell-template"/> 
                                <DxColumn data-field="document_type_name" :caption="$t('Document Type')" cell-template="document_type_name-cell-template"/> 
                                <DxColumn data-field="importance" :caption="$t('Importance')" cell-template="importance-cell-template" alignment="left"/> 
                                <DxColumn data-field="document_source" :caption="$t('In/Out')" cell-template="document_source-cell-template" alignment="left"/> 
                                <DxColumn data-field="upload_source" :caption="$t('Source')" cell-template="upload_source-cell-template" alignment="left"/> 
                                <DxColumn data-field="source_company" :caption="$t('Source Company')" cell-template="source_company-cell-template"/> 
                                <DxColumn data-field="source_person" :caption="$t('Source Person')" cell-template="source_person-cell-template"/> 
                                <DxColumn data-field="have_personal_data" :caption="$t('Personal Data')" cell-template="have_personal_data-cell-template"/> 
                                <DxColumn data-field="have_gdpr_permission" :caption="$t('GDPR Permission')" cell-template="have_gdpr_permission-cell-template"/> 
                                <DxColumn data-field="size" :caption="$t('Size')" alignment="right" cell-template="size-cell-template"/>
                                <DxColumn data-field="created_by_name" :caption="$t('Uploaded By')" alignment="left" cell-template="created_by_name-cell-template"/>
                                <DxColumn data-field="created_at" :caption="$t('Created At')" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                                <DxColumn data-field="is_deleted" :caption="$t('Status')" cell-template="is_deleted-cell-template" /> 
                                <DxColumn data-field="deleted_by_name" :caption="$t('Deleted By')" alignment="left" cell-template="deleted_by_name-cell-template"/>

                                <template #is_deleted-cell-template="{ data: data }">
                                  <span class="align-middle text-center"> 
                                      <span v-if="data.data.is_deleted == 1"> 
                                         <i class="fas fa-times"></i> 
                                         <span class="ms-3">Deleted</span>
                                      </span>
                                      <span v-if="data.data.is_deleted == 0">  
                                         <span>Active</span>
                                      </span>
                                  </span>
                                </template> 
                                 <template #deleted_by_name-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ data.data.deleted_by_name }}
                                  </span>
                                </template>
                                <template #created_by_name-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ data.data.created_by_name }}
                                  </span>
                                </template>
                                <template #name-cell-template="{ data: data }">
                                  <span class="align-middle fw-bold">
                                    {{ data.data.name }}
                                  </span>
                                </template>
                                <template #document_name-cell-template="{ data: data }">
                                  <span class="align-middle fw-bold">
                                    {{ data.data.document_name }}
                                  </span>
                                </template>
                                <template #document_no-cell-template="{ data: data }">
                                  <span class="align-middle fw-bold">
                                    {{ data.data.document_no }}
                                  </span>
                                </template>
                                 <template #document_type_name-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ data.data.document_type_name }}
                                  </span>
                                </template>
                                <template #source_company-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ data.data.source_company }}
                                  </span>
                                </template>
                                <template #source_person-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ data.data.source_person }}
                                  </span>
                                </template>
                                <template #document_source-cell-template="{ data: data }">
                                  <span class="align-middle">
                                      <span v-if="data.data.document_source == 0"> Incoming</span>
                                      <span v-if="data.data.document_source == 1"> Outgoing</span>
                                  </span>
                                </template>
                                <template #upload_source-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    <span v-if="data.data.upload_source == 0"> Manual</span>
                                    <span v-if="data.data.upload_source == 1"> Transaction</span>
                                    <span v-if="data.data.upload_source == 2"> Liability</span>
                                    <span v-if="data.data.upload_source == 3"> ChatBi</span>
                                  </span>
                                </template>
                                <template #have_personal_data-cell-template="{ data: data }">
                                  <span class="align-middle text-center">
                                      <span v-if="data.data.have_personal_data == 0"> 
                                          <i class="fas fa-times"></i>
                                      </span>
                                      <span v-if="data.data.have_personal_data == 1"> 
                                          <i class="fas fa-check"></i>
                                      </span>
                                  </span>
                                </template>
                                 <template #have_gdpr_permission-cell-template="{ data: data }">
                                  <span class="align-middle text-center">
                                      <span v-if="data.data.have_gdpr_permission == 0"> 
                                          <i class="fas fa-times"></i>
                                      </span>
                                      <span v-if="data.data.have_gdpr_permission == 1"> 
                                          <i class="fas fa-check"></i>
                                      </span>
                                  </span>
                                </template>
                                 <template #importance-cell-template="{ data: data }">
                                  <span class="align-middle">
                                      <span v-if="data.data.importance == 0"> Normal </span>
                                      <span v-if="data.data.importance == 1"> Low </span>
                                      <span v-if="data.data.importance == 2"> High </span>
                                      <span v-if="data.data.importance == 3"> Very High </span>
                                  </span>
                                </template>
                                <template #size-cell-template="{ data: data }">
                                  <span class="align-middle">
                                    {{ calculateSize(data.data.size) }}
                                  </span>
                                </template>

                                <template #download-cell-template="{ data: data }">
                                  <span>
                                  <a href="#" target="_self" @click="onOpenDetail(null, data)" data-bs-toggle="modal" data-bs-target="#document_details_modal">
                                     <span class="svg-icon svg-icon-1 mx-2 svg-icon-primary">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                          <rect x="11" y="11" width="2" height="2" rx="1" fill="currentColor"/>
                                          <rect x="11" y="15" width="2" height="2" rx="1" fill="currentColor"/>
                                          <rect x="11" y="7" width="2" height="2" rx="1" fill="currentColor"/>
                                          </svg>
                                      </span>
                                  </a> 
                                  <!-- Transaction -->
                                   <a href="#" target="_self" @click="onOpenAccordion(null, data)" data-bs-toggle="modal" data-bs-target="#transaction_details_modal">
                                      <span class="svg-icon svg-icon-1 mx-2 svg-icon-info">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor"/>
                                          </svg>
                                      </span>
                                  </a>  
                                  
                                  <!-- Download -->
                                   <a href="#" target="_self" data-bs-toggle="modal" data-bs-target="#file_download_modal" @click="onRequestFile(data.data)" v-if="data.data.is_deleted == 0">
                                     <span class="svg-icon svg-icon-1 mx-2 svg-icon-dark">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="currentColor"/>
                                          <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="currentColor"/>
                                          <path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="currentColor"/>
                                          </svg>
                                      </span>
                                  </a> 
                                  </span>
                                </template> 

                                <DxSummary>
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="download"/>
                                </DxSummary>
                                
                              </DxDataGrid>
                            </div>
                          </div>
                        

                        <div class="alert bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-10" v-if="this.lists.length == 0 && this.documents.length == 0 && this.isPreparementsReady"> 
                              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="currentColor"></path>
                                  <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="currentColor"></path>
                                  <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="currentColor"></rect>
                                  <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="currentColor"></path>
                                </svg>
                              </span>
                              <div class="d-flex flex-column pe-0 pe-sm-10">
                                <h4 class="fw-semibold">{{ $t('No folders added yet.') }}</h4>
                                <span>{{ $t('Please use New Folder button to add more folders..!') }}</span>
                              </div> 
                        </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    </div>
                    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    </div>
                  </div>
              </div>
         </div>

         <!-- ALL DOCUMENTS VIEW -->
         <div class="card" v-if="this.activeMainView == 'all_documents'">
            <div class="card-header">
                <h3 class="card-title">{{ $t('All Documents') }}</h3> 
            </div>
            <div class="card mb-3 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm mx-3 p-2 mt-3" 
                 style="background-image: url('assets/theme/media/svg/shapes/abstract-4.svg')">
                  <div class="card-body pt-0 pb-0">
                    <div class="d-flex flex-stack">
                      <div class="flex-grow">
                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-1x justify-content-start align-items-start py-5 mb-2" role="tablist">  
                            <li class="nav-item" role="presentation">
                                  <div class="symbol symbol-45px me-5 justify-content-start"><i class="fas fa-file fs-2x me-4" style="color: rgb(0, 158, 247);"></i></div>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(0)">{{ $t('Today') }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(1)">{{ $t('Yesterday') }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(3)">{{ $t('Past 3 Days') }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary active fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(10)">{{ $t('Past 10 Days') }}</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a style="font-size: 11px;" class="nav-link shadow-sm rounded-1 text-gray-600 text-active-primary fw-bold px-4" data-bs-toggle="tab" href="" target="_self" @click="onChangeDateCriteria(30)">{{ $t('Past 30 Days') }}</a>
                            </li>
                        </ul>
                      </div>
                      <div class="flex-grow" v-if="this.$isCredentialActive(6,'I')">
                        <button type="button" class="btn btn-primary" @click="getDocumentTypesForUpload()" data-bs-toggle="modal" data-bs-target="#upload_modal">
                            <span class="svg-icon svg-icon-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor"></path>
                                <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor"></path>
                              </svg>
                            </span>
                            {{ $t('Document Upload') }}
                        </button>
                      </div>
                    </div>
                    <div>
                    <div class="row py-3">
                        <div class="col-lg-9">
                            <div class="fv-row mb-4 row">
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label">
                                        {{ $t('Start Date') }}
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                </div>
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label">
                                        {{ $t('End Date') }}
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="filterForm.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>
                                </div>
                                <div class="col-lg-3">
                                    <label class="fs-6 fw-bold form-label">
                                        {{ $t('Document Type') }}
                                    </label>
                                    <Select2 v-model="filterForm.document_type_id" :options="documentTypeCriteriaList" :settings="{ width: '100%' }"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(6,'R')">   
                                <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                    </template>
                                </Popper>  
                                <button type="button" class="btn btn-sm btn-primary" @click="getViewAllFolderDocuments()">
                                    <span class="svg-icon svg-icon-muted">
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    <strong> {{ $t('List Documents') }} </strong>
                                </button>
                            </div>
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 p-3">
                  <!-- DOCUMENTS LIST -->
                  <div class="p-3">
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                          key-expr="id" 
                          :data-source="documents" 
                          :allow-column-reordering="true"  
                          :allow-column-resizing="true" 
                          :column-auto-width="true" 
                          :row-alternatin-enabled="true"
                          :hover-state-enabled="true" 
                          :show-row-lines="true"
                          @row-prepared="onRowPrepared"> 

                      <DxFilterRow :visible="true"/>
                      <DxHeaderFilter :visible="true"/>
                      <DxGroupPanel :visible="true"/>
                      <DxSearchPanel :visible="true"/>  
                      <DxScrolling
                          mode="virtual"
                          row-rendering-mode="virtual"
                      />
                      <DxColumn data-field="download" :caption="$t('Actions')" cell-template="download-cell-template" alignment="left" width="125"/>  
                      <DxColumn data-field="document_name" :caption="$t('Document Name')"  cell-template="document_name-cell-template"/>
                      <DxColumn data-field="document_no" :caption="$t('Document No')" cell-template="document_no-cell-template"/>
                      <DxColumn data-field="name" :caption="$t('File Name')"  cell-template="name-cell-template"/> 
                      <DxColumn data-field="document_type_name" :caption="$t('Document Type')" cell-template="document_type_name-cell-template"/> 
                      <DxColumn data-field="importance" :caption="$t('Importance')" cell-template="importance-cell-template" alignment="left"/> 
                      <DxColumn data-field="document_source" :caption="$t('In/Out')" cell-template="document_source-cell-template" alignment="left"/> 
                      <DxColumn data-field="upload_source" :caption="$t('Source')" cell-template="upload_source-cell-template" alignment="left"/> 
                      <DxColumn data-field="source_company" :caption="$t('Source Company')" cell-template="source_company-cell-template"/> 
                      <DxColumn data-field="source_person" :caption="$t('Source Person')" cell-template="source_person-cell-template"/> 
                      <DxColumn data-field="have_personal_data" :caption="$t('Personal Data')" cell-template="have_personal_data-cell-template"/> 
                      <DxColumn data-field="have_gdpr_permission" :caption="$t('GDPR Permission')" cell-template="have_gdpr_permission-cell-template"/> 
                      <DxColumn data-field="size" :caption="$t('Size')" alignment="right" cell-template="size-cell-template"/>
                      <DxColumn data-field="created_by_name" :caption="$t('Uploaded By')" alignment="left" cell-template="created_by_name-cell-template"/>
                      <DxColumn data-field="created_at" :caption="$t('Created At')" data-type="date" alignment="right" format="dd.MM.yyyy HH:mm" /> 
                      <DxColumn data-field="is_deleted" :caption="$t('Status')" cell-template="is_deleted-cell-template" /> 
                      <DxColumn data-field="deleted_by_name" :caption="$t('Deleted By')" alignment="left" cell-template="deleted_by_name-cell-template"/>

                      <template #is_deleted-cell-template="{ data: data }">
                        <span class="align-middle text-center"> 
                            <span v-if="data.data.is_deleted == 1"> 
                                <i class="fas fa-times"></i> 
                                <span class="ms-3">Deleted</span>
                            </span>
                        </span>
                      </template> 
                        <template #deleted_by_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.deleted_by_name }}
                        </span>
                      </template>
                      <template #created_by_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.created_by_name }}
                        </span>
                      </template>
                      <template #name-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.name }}
                        </span>
                      </template>
                      <template #document_name-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.document_name }}
                        </span>
                      </template>
                      <template #document_no-cell-template="{ data: data }">
                        <span class="align-middle fw-bold">
                          {{ data.data.document_no }}
                        </span>
                      </template>
                        <template #document_type_name-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.document_type_name }}
                        </span>
                      </template>
                      <template #source_company-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.source_company }}
                        </span>
                      </template>
                      <template #source_person-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ data.data.source_person }}
                        </span>
                      </template>
                      <template #document_source-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.document_source == 0"> Incoming</span>
                            <span v-if="data.data.document_source == 1"> Outgoing</span>
                        </span>
                      </template>
                      <template #upload_source-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.upload_source == 0"> Manual</span>
                            <span v-if="data.data.upload_source == 1"> Transaction</span>
                            <span v-if="data.data.upload_source == 2"> Liability</span>
                            <span v-if="data.data.upload_source == 3"> ChatBi</span>
                        </span>
                      </template>
                      <template #have_personal_data-cell-template="{ data: data }">
                        <span class="align-middle text-center">
                            <span v-if="data.data.have_personal_data == 0"> 
                                <i class="fas fa-times"></i>
                            </span>
                            <span v-if="data.data.have_personal_data == 1"> 
                                <i class="fas fa-check"></i>
                            </span>
                        </span>
                      </template>
                        <template #have_gdpr_permission-cell-template="{ data: data }">
                        <span class="align-middle text-center">
                            <span v-if="data.data.have_gdpr_permission == 0"> 
                                <i class="fas fa-times"></i>
                            </span>
                            <span v-if="data.data.have_gdpr_permission == 1"> 
                                <i class="fas fa-check"></i>
                            </span>
                        </span>
                      </template>
                        <template #importance-cell-template="{ data: data }">
                        <span class="align-middle">
                            <span v-if="data.data.importance == 0"> Normal </span>
                            <span v-if="data.data.importance == 1"> Low </span>
                            <span v-if="data.data.importance == 2"> High </span>
                            <span v-if="data.data.importance == 3"> Very High </span>
                        </span>
                      </template>
                      <template #size-cell-template="{ data: data }">
                        <span class="align-middle">
                          {{ calculateSize(data.data.size) }}
                        </span>
                      </template>

                      <template #download-cell-template="{ data: data }">
                        <span>
                        <a href="#" target="_self" @click="onOpenAccordion(null, data)" data-bs-toggle="modal" data-bs-target="#document_details_modal">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                <rect x="11" y="11" width="2" height="2" rx="1" fill="currentColor"/>
                                <rect x="11" y="15" width="2" height="2" rx="1" fill="currentColor"/>
                                <rect x="11" y="7" width="2" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </span>
                        </a> 
                        <!-- Transaction -->
                          <a href="#" target="_self" @click="onOpenAccordion(null, data)" data-bs-toggle="modal" data-bs-target="#transaction_details_modal">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-info">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.34375 3.9463V15.2178C1.34375 16.119 2.08105 16.8563 2.98219 16.8563H8.65093V19.4594H6.15702C5.38853 19.4594 4.75981 19.9617 4.75981 20.5757V21.6921H19.2403V20.5757C19.2403 19.9617 18.6116 19.4594 17.8431 19.4594H15.3492V16.8563H21.0179C21.919 16.8563 22.6562 16.119 22.6562 15.2178V3.9463C22.6562 3.04516 21.9189 2.30786 21.0179 2.30786H2.98219C2.08105 2.30786 1.34375 3.04516 1.34375 3.9463ZM12.9034 9.9016C13.241 9.98792 13.5597 10.1216 13.852 10.2949L15.0393 9.4353L15.9893 10.3853L15.1297 11.5727C15.303 11.865 15.4366 12.1837 15.523 12.5212L16.97 12.7528V13.4089H13.9851C13.9766 12.3198 13.0912 11.4394 12 11.4394C10.9089 11.4394 10.0235 12.3198 10.015 13.4089H7.03006V12.7528L8.47712 12.5211C8.56345 12.1836 8.69703 11.8649 8.87037 11.5727L8.0107 10.3853L8.96078 9.4353L10.148 10.2949C10.4404 10.1215 10.759 9.98788 11.0966 9.9016L11.3282 8.45467H12.6718L12.9034 9.9016ZM16.1353 7.93758C15.6779 7.93758 15.3071 7.56681 15.3071 7.1094C15.3071 6.652 15.6779 6.28122 16.1353 6.28122C16.5926 6.28122 16.9634 6.652 16.9634 7.1094C16.9634 7.56681 16.5926 7.93758 16.1353 7.93758ZM2.71385 14.0964V3.90518C2.71385 3.78023 2.81612 3.67796 2.94107 3.67796H21.0589C21.1839 3.67796 21.2861 3.78023 21.2861 3.90518V14.0964C15.0954 14.0964 8.90462 14.0964 2.71385 14.0964Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </a>  
                        
                        <!-- Download -->
                          <a href="#" target="_self" data-bs-toggle="modal" data-bs-target="#file_download_modal" @click="onRequestFile(data.data)" v-if="data.data.is_deleted == 0">
                            <span class="svg-icon svg-icon-1 mx-2 svg-icon-warning">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z" fill="currentColor"/>
                                <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z" fill="currentColor"/>
                                <path opacity="0.3" d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z" fill="currentColor"/>
                                </svg>
                            </span>
                        </a> 
                        </span>
                      </template> 

                      <DxSummary>
                          <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="download"/>
                      </DxSummary>
                      
                    </DxDataGrid>
                  </div>
              </div>
         </div>
    </div> 

    <!-- Add Folder Modal -->
    <div class="modal fade" tabindex="-1" id="add_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            {{ $t('Add Folder') }}      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeAddModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">{{ $t('Size Limit') }}</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">{{ $t('Upload size limits for files') }} ({{ $t('Bytes') }})</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">{{ $t('Allowed Extensions') }}</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">{{ $t('Type seperating via comma. E.g.') }} <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">{{ $t('Blocked Extensions') }}</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">{{ $t('Type seperating via comma. E.g') }}. <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">{{ $t('Folder Description') }}</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">{{ $t('Description for folder can be typed here.') }}</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-success w-100 mt-4" @click="onCreateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        {{ $t('Create Folder') }}
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> {{ $t('Icon Selection') }} </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                {{ $t('Reset') }}
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed text-gray-800 table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Edit Folder Modal -->
    <div class="modal fade" tabindex="-1" id="edit_folder_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            {{ $t('Update Folder') }}      
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                      <div :class="{ 'col-lg-9' : this.isViewIconModal == true, 'col-lg-12' : this.isViewIconModal == false }">
                          <div class="p-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                      <div class="input-group mb-4 mt-4">
                                            <span class="input-group-text" id="basic-addon1">/</span> 
                                            <input class="form-control" v-model="this.form.name" placeholder="Folder Name" /> 
                                            <span class="input-group-text p-0 me-3">
                                              <button class="btn btn-sm p-2" @click="this.isViewIconModal = !this.isViewIconModal;">
                                                  <span>
                                                    <i :class="this.form.icon + ' fs-2x'"></i>
                                                  </span>
                                              </button>  
                                          </span>
                                          <color-input type="text" placeholder="" name="name" v-model="form.color" format="hex string" />  
                                        </div>
                                    </div> 
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="sizeLimit" class="form-label">{{ $t('Size Limit') }}</label>
                                          <CurrencyInput class="form-control text-start" id="sizeLimit" aria-describedby="sizeLimitDesc" :precision="0" v-model="form.size_limit"> </CurrencyInput>
                                          <div id="sizeLimitDesc" class="form-text" style="font-size: 11px;">{{ $t('Upload size limits for files') }} ({{ $t('Bytes') }})</div>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="allowedExtensions" class="form-label">{{ $t('Allowed Extensions') }}</label>
                                          <input type="text" class="form-control text-start" id="allowedExtensions" aria-describedby="allowedExtensionsDesc" v-model="form.allowed_ext" />
                                          <div id="allowedExtensionsDesc" class="form-text" style="font-size: 11px;">{{ $t('Type seperating via comma. E.g.') }} <code>pdf, doc, docx, xlsx</code></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-1">
                                          <label for="blockedExtensions" class="form-label">{{ $t('Blocked Extensions') }}</label>
                                          <input type="text" class="form-control text-start" id="blockedExtensions" aria-describedby="blockedExtensionsDesc" v-model="form.blocked_ext" />
                                          <div id="blockedExtensionsDesc" class="form-text" style="font-size: 11px;">{{ $t('Type seperating via comma. E.g.') }} <code>bat, bin, exe, bak</code></div>
                                        </div>
                                    </div>  
                                    <div class="col-lg-12">
                                        <div class="mb-1 mt-4">
                                          <label for="folderDescription" class="form-label">{{ $t('Folder Description') }}</label>
                                          <textarea if="folderDescription" rows="2" class="form-control text-start" id="folderDescription" aria-describedby="folderDescriptionDesc" v-model="form.description" />
                                          <div id="folderDescriptionDesc" class="form-text" style="font-size: 11px;">{{ $t('Description for folder can be typed here.') }}</div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-12 text-start">
                                      <button class="btn btn-primary w-100 mt-4" @click="onUpdateFolder()">
                                          <span class="svg-icon svg-icon-success svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"/>
                                            <path opacity="0.3" d="M10.3 15.3L11 14.6L8.70002 12.3C8.30002 11.9 7.7 11.9 7.3 12.3C6.9 12.7 6.9 13.3 7.3 13.7L10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3Z" fill="currentColor"/>
                                            <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM11.7 16.7L16.7 11.7C17.1 11.3 17.1 10.7 16.7 10.3C16.3 9.89999 15.7 9.89999 15.3 10.3L11 14.6L8.70001 12.3C8.30001 11.9 7.69999 11.9 7.29999 12.3C6.89999 12.7 6.89999 13.3 7.29999 13.7L10.3 16.7C10.5 16.9 10.8 17 11 17C11.2 17 11.5 16.9 11.7 16.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        {{ $t('Update Folder') }}
                                      </button>
                                  </div>
                                </div> 
                          </div>
                      </div>
                      <div class="p-3 border border-dashed col-lg-3" :class="{ 'col-lg-3' : this.isViewIconModal == true, 'd-none' : this.isViewIconModal == false }">
                          <!-- Icon Selection Panel -->
                          <h4 class="mt-3"> {{ $t('Icon Selection') }} </h4>
                          <br>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-solid" placeholder="Search Icons" v-model="searchbar">
                            <span class="input-group-text p-0">
                              <button class="btn btn-sm btn-secondary m-0" v-if="this.form.icon != 'fas fa-folder'" @click="onIconSelection('fas fa-folder')" style="font-size: 9px !important;">
                                {{ $t('Reset') }}
                              </button>
                            </span> 
                          </div>
                          <br>
                          <div class="row overflow-auto" v-if="icons" style="max-height: 400px;">
                              <table class="table table-row-dashed text-gray-800 table-row-gray-300 table-hover">
                                <tbody>
                                  <tr v-for="item of searchableList" v-bind:key="item">
                                    <td class="text-center">  
                                      <a class="btn btn-light p-2 text-center cursor-pointer" @click="onIconSelection(item)">
                                        <i :class="item + ' fs-2x'"></i> <br>
                                      </a>
                                    </td>
                                    <td class="align-middle cursor-pointer" @click="onIconSelection(item)"><small class="mt-2">{{ item }}</small></td>
                                  </tr> 
                                </tbody>
                              </table> 
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Set Document Type Modal -->
    <div class="modal fade" tabindex="-1" id="set_document_type_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-lg-6">
                        <h2 class="modal-title fw-bolder">
                            {{ $t('Set Document Type') }}
                        </h2>  
                    </div>
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeDocumentTypeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div> 
                <div class="modal-body">
                  <div class="row">
                    <div class="card">
                      <table class="table table-striped gy-7 gs-7"> 
                        <tbody>
                          <tr v-for="item in documentTypeslist" v-bind:key="item">
                            <td class="align-middle">
                              <button class="btn btn-sm btn-danger" @click="onDocumentTypeSave(item)" v-if="item.isAdded">
                                  {{ $t('Remove') }}
                              </button>
                               <button class="btn btn-sm btn-success" @click="onDocumentTypeSave(item)" v-if="!item.isAdded">
                                  {{ $t('Add') }}
                              </button>
                            </td>
                            <td class="align-middle text-gray-800">{{ item.code }}</td>
                            <td class="align-middle text-gray-900">{{ item.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div> 

    <!-- Upload File Modal -->
    <div class="modal fade" tabindex="-1" id="upload_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content"  >
              <div class="modal-header bg-primary">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                      {{ $t('Upload File') }}
                      <span class="fs-6 text-gray-700 ms-3" v-if="this.activeStep > 1">
                        <i class="fs-6 me-1" :class="!this.selectedDocumentType.icon ? 'fa fa-file' : this.selectedDocumentType.icon" :style="{ 'color': this.selectedDocumentType.color }"></i>
                        <span class="text-white">{{this.selectedDocumentType.text}}</span>
                      </span>
                    </h2>                        
                    <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUploadModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body">
                  <div class="content flex-row-fluid p-0" id="kt_content">
                    <div v-if="activeStep == 1" class="d-flex flex-row row p-0">
                      <div class="d-flex flex-column flex-row-auto col-lg-12 text-center">
                        <div class="d-flex flex-column-fluid card shadow-sm  bg-white bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm rounded bg-abstract-2">
                          <div class="card-body px-2">
                            <h3 v-if="documentTypesForUpload.length == 0"> <i class="fa fa-info-circle fs-4"></i> {{ $t('No document types were found for manual document upload.') }}</h3>
                            <ul class="nav nav-pills nav-pills-custom" role="tablist">
                              <li class="nav-item mb-3 mx-3" role="presentation" v-for="typeItem in documentTypesForUpload" v-bind:key="typeItem">
                                <a class="nav-link d-flex justify-content-evenly flex-column flex-center w-100px h-85px border sub-settings-card-hover bg-light-primary position-relative"
                                  @click="onSelectDocumentType(typeItem)" :class="{'card-menu-selected': typeItem.id == this.uploadForm.document_type_id}">
                                  <div class="nav-icon">                                
                                    <i class="fs-2" :class="!typeItem.icon ? 'fa fa-file' : typeItem.icon" :style="{ 'color': typeItem.color }"></i>
                                  </div>                              
                                  <span class="nav-text text-gray-700 fw-bold fs-8 lh-1">
                                    {{typeItem.text}}
                                  </span>
                                  
                                  <span class="position-absolute top-0 start-75 ms-3" v-if="typeItem.id == this.uploadForm.document_type_id">
                                    <i class="fa fa-check-circle text-primary"></i>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-4" v-if="documentTypesForUpload.length > 0">
                        <div class="card shadow-sm p-3">
                          <div class="d-flex flex-stack align-items-baseline">
                            <div class="me-2">
                              <small v-if="documentTypesForUpload.length > 0 && (this.uploadForm.document_type_id == -1 || this.uploadForm.document_type_id == null)" class="text-gray-600"> 
                                <i class="bi bi-info-circle"></i>
                                {{ $t('Select document type above to upload a file...') }} 
                              </small>
                            </div>
                            <div>
                              <button type="button" class="btn btn-lg btn-primary" @click="onNextStep()" :disabled="this.uploadForm.document_type_id == -1">
                                {{ $t('Continue') }}
                                <span class="svg-icon svg-icon-3 ms-1 me-0"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="activeStep == 2" class="d-flex flex-row row p-0 animation animation-slide-in-up">
                      <div class="d-flex flex-column flex-row-auto col-lg-6">
                        <div class="d-flex flex-column-fluid card shadow-sm"> 
                          <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        {{ $t('Document Name') }}
                                    </label>
                                    <div class="fv-row fv-plugins-icon-container">
                                        <input type="text" v-model="uploadForm.document_name" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        {{ $t('Document No') }}
                                    </label>
                                    <div class="fv-row fv-plugins-icon-container">
                                        <input type="text" v-model="uploadForm.document_no" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        {{ $t('Importance') }}
                                    </label>
                                    <div class="fv-row fv-plugins-icon-container">
                                        <Select2 v-model="uploadForm.importance" :options="importanceList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3 required">
                                        {{ $t('Document Source') }}
                                    </label>
                                    <div class="fv-row fv-plugins-icon-container">
                                        <Select2 v-model="uploadForm.document_source" :options="documentSourceList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        {{ $t('Description') }}
                                    </label>
                                    <div class="fv-row fv-plugins-icon-container">
                                        <textarea class="form-control" rows="2" v-model="uploadForm.document_description"></textarea>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-column flex-row-auto col-lg-6">
                        <div class="d-flex flex-column-fluid card shadow-sm"> 
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Source Company') }}
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.source_company" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            {{ $t('Source Person') }}
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <input type="text" v-model="uploadForm.source_person" class="form-control" /> 
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Does it contain personal data?') }} ({{ $t('GDPR Content') }})
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.have_personal_data" 
                                                      :options="havePersonelDataList" 
                                                      :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"
                                                      @select="onPersonalDataChange()"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" v-if="uploadForm.have_personal_data == 1">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('Have GDPR permissions been obtained?') }}
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <Select2 v-model="uploadForm.have_gdpr_permission" :options="gpdrObtainList" :settings="{ width: '100%', dropdownParent: '#upload_modal .modal-content' }"/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" v-if="uploadForm.have_personal_data == 1">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                            {{ $t('GDPR Permissions Description') }}
                                        </label>
                                        <div class="fv-row fv-plugins-icon-container">
                                            <textarea class="form-control" rows="2" v-model="uploadForm.gdpr_description"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="col-lg-12 mt-4">
                        <div class="card shadow-sm p-3">
                          <div class="d-flex flex-stack">
                            <div class="me-2">
                              <button type="button" class="btn btn-lg btn-light-danger me-3" @click="onBackStep()">
                                <span class="svg-icon svg-icon-3 me-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                                </svg>
                                </span>
                                {{ $t('Back') }}
                              </button>
                            </div>
                            <div>
                              <button type="button" class="btn btn-lg btn-primary" @click="onNextStep()" :disabled="this.uploadForm.document_type_id == -1 || this.uploadForm.document_source == -1 || this.uploadForm.document_name == null || this.uploadForm.document_name == '' || this.uploadForm.document_no == null || this.uploadForm.document_no == ''">
                                {{ $t('Continue') }}
                                <span class="svg-icon svg-icon-3 ms-1 me-0"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="activeStep == 3" class="row animation p-0 animation-slide-in-up">
                      <div class="col-lg-12">
                        <div class="card shadow-sm mt-4 bgi-no-repeat bgi-position-y-top bgi-position-x-end" style="background-image: url('assets/theme/media/svg/shapes/abstract-5.svg')"> 
                          <div class="col-lg-12">  
                            <!-- dialog-trigger="#btn_download" -->
                            <DxFileUploader
                              multiple="false"
                              upload-mode="useButtons" 
                              :visible="true"
                              accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, image/*"
                              :disabled="this.uploadForm.document_type_id == -1 || this.uploadForm.document_source == -1 || this.uploadForm.document_name == null || this.uploadForm.document_name == '' || this.uploadForm.document_no == null || this.uploadForm.document_no == ''"
                              :upload-headers="getUploadHeaders()"
                              :on-uploaded="onUploadCompleted"
                              upload-url="/api/Folders/Upload"
                              :ref="'fileUploader'" />
                          </div>
                        </div> 
                      </div>                          
                      <div class="col-lg-12 mt-4">
                        <div class="card shadow-sm p-3">
                          <div class="d-flex flex-stack">
                            <button type="button" class="btn btn-lg btn-light-danger me-3" @click="onBackStep()">
                              <span class="svg-icon svg-icon-3 me-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                              <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                              </svg>
                              </span>
                              {{ $t('Back') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>

    <!-- Add Transaction Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_add_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content"  >
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10">
                      {{ $t('Add Transaction') }}                
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                              <div class="card-body py-1">
                                  <div class="row">
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>{{ $t('Status') }}</span>
                                          </label>
                                          <Select2 v-model="transactionForm.status" :options="statusList" :settings="{ width: '100%', dropdownParent: '#transaction_add_modal .modal-content' }"/>
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>{{ $t('Description') }}</span>
                                          </label>
                                          <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="card card-flush mt-4">
                                              <div class="d-grid gap-2">
                                                  <button type="button" class="btn btn-success btn-lg" @click="this.saveTransactionData()" :disabled="this.transactionForm.status == -1">
                                                      <span class="indicator-label">{{ $t('Save') }}</span> 
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Edit Transaction Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_edit_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content"  >
              <div class="modal-header bg-primary">
                  <h2 class="modal-title fw-bolder col-md-10 text-white">
                      {{ $t('Edit Transaction') }}                    
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="closeEditTransactionModal">
                      <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                              <div class="card-body py-1">
                                  <div class="row">
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>{{ $t('Status') }}</span>
                                          </label>
                                          <input class="form-control" v-model="transactionForm.status_text" disabled/>
                                      </div>
                                      <div class="col-lg-12">
                                          <label class="fw-bold form-label mt-3">
                                              <span>{{ $t('Description') }}</span>
                                          </label>
                                          <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                      </div>
                                      <div class="col-lg-12">
                                          <div class="card card-flush mt-4">
                                              <div class="d-grid gap-2">
                                                  <button type="button" class="btn btn-primary btn-lg" @click="this.updateTransactionData()" :disabled="this.transactionForm.status == -1">
                                                      <span class="indicator-label">{{ $t('Update') }}</span> 
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div> 

    <!-- Document Details Modal -->
    <div class="modal fade" tabindex="-1" id="document_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content"  >
              <div class="modal-header bg-primary">
                  <h2 class="modal-title fw-bolder col-md-10 text-white">
                      {{ $t('Document Details') }}                   
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="documentDetailsModal">
                      <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                  <div class="row align-items-center mx-0 w-100"> 
                      <div class="col-lg-12 px-4 text-end" v-if="this.$isCredentialActive(6,'D')">
                        <button type="button" v-if="!this.selectedDocument.is_deleted" class="btn btn-sm btn-light-danger me-3" style="font-size:11px !important;" @click="onDeleteDocument(this.selectedDocument)">
                            {{ $t('Delete File') }}
                        </button>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 mt-4">
                                  <div class="row">
                                      <div class="col-lg-6">
                                        <div>
                                          <span>
                                            {{ $t('Importance') }}: 
                                          </span>
                                          <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.importance == 0">{{ $t('Normal') }}</span>
                                          <span class="badge badge-sm badge-secondary" v-if="this.selectedDocument.importance == 1">{{ $t('Low') }}</span>
                                          <span class="badge badge-sm badge-warning" v-if="this.selectedDocument.importance == 2">{{ $t('High') }}</span>
                                          <span class="badge badge-sm badge-danger" v-if="this.selectedDocument.importance == 3">{{ $t('Very High') }}</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="text-end"> 
                                              <span class="badge badge-sm badge-primary" v-if="this.selectedDocument.document_source == 0">{{ $t('Incoming Document') }}</span>
                                              <span class="badge badge-sm badge-info" v-if="this.selectedDocument.document_source == 1">{{ $t('Outgoing Document') }}</span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                    <div class="row">
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>{{ $t('Document Name') }} :</span>
                                        &nbsp; 
                                        <span class="badge badge-sm badge-secondary"> {{ this.selectedDocument.document_name }} </span>
                                      </div>
                                      <div class="col-lg-4">  
                                        <span class="bullet bg-secondary me-3 bullet-md"></span>
                                        <span>{{ $t('Document No') }} :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.document_no }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                        <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                        <span>{{ $t('File Name') }} :</span>
                                        &nbsp; 
                                        <span> {{ this.selectedDocument.name }} </span>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>{{ $t('Source') }} :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.document_source == 0"> {{ $t('Incoming Document') }} </span>
                                            <span v-if="this.selectedDocument.document_source == 1"> {{ $t('Outgoing Document') }} </span>
                                          </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span> 
                                          <span >{{ $t('Source Company') }} :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_company }} </span>
                                        </div>
                                        <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>{{ $t('Source Person') }} :</span>
                                          &nbsp; 
                                          <span> {{ this.selectedDocument.source_person }} </span>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>{{ $t('Size') }} :</span>
                                          &nbsp; 
                                          <span> {{ calculateSize(this.selectedDocument.size) }} </span>
                                      </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md"></span>
                                          <span>{{ $t('Created At') }} :</span>
                                          &nbsp; 
                                          <span> {{ previewDate(this.selectedDocument.created_at) }} </span>
                                        </div>
                                      <div class="col-lg-4">
                                          <span class="bullet bg-secondary me-3 bullet-md "></span>
                                          <span>{{ $t('Status') }} :</span>
                                          &nbsp; 
                                          <span> 
                                            <span v-if="this.selectedDocument.status == 0"> {{ $t('Active') }} </span>
                                            <span class="text-danger" v-if="this.selectedDocument.status == 1"> {{ $t('Deleted File') }} </span>
                                          </span>
                                      </div>
                                    </div>
                              </div>
                          </div>
                      </div>  
                      <div class="col-lg-12 px-0">
                          <div class="d-flex flex-column content-justify-center">
                              <div class="border border-gray-300 border-dashed rounded bg-light-primary min-w-125px py-3 px-4 me-7 mb-3 pt-5">
                                <div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span>
                                        {{ $t('Does it contain personal data?') }} ({{ $t('GDPR Content') }}): 
                                      </span>
                                      &nbsp; 
                                      <span v-if="this.selectedDocument.have_personal_data == 1"> {{ $t('YES') }} </span>
                                      <span v-if="this.selectedDocument.have_personal_data == 0"> {{ $t('NO') }} </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                    <div>
                                      <span class="bullet bg-success me-3 bullet-md"></span> 
                                      <span> {{ $t('Have GDPR permissions been obtained?') }} </span> &nbsp; 
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 1"> {{ $t('YES') }} </span>
                                      <span v-if="this.selectedDocument.have_gdpr_permission == 0"> {{ $t('NO') }} </span>
                                    </div>
                                  </div>
                                  <div class="fs-7 text-gray-800 d-flex align-items-center py-2">
                                      {{ this.selectedDocument.gdpr_description }}
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>
      </div>
    </div> 

     <!-- Transaction Details Modal -->
    <div class="modal fade" tabindex="-1" id="transaction_details_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content"  >
              <div class="modal-header bg-primary">
                  <h2 class="modal-title fw-bolder col-md-10 text-white">
                      {{ $t('Document Transactions') }}                    
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="transactionDetailsModal">
                      <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white" v-if="this.selectedDocument"> 
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li class="nav-item">
                        <a class="nav-link fs-4 fw-bold text-gray-900 cursor-pointer" 
                          :class="{ 'active' : this.activeTransactionTab == 'Add' }" 
                          target="_self"
                          @click="this.activeTransactionTab = 'Add'">
                          {{ $t('Add Transaction') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link fs-4 fw-bold text-gray-900 cursor-pointer" 
                          :class="{ 'active' : this.activeTransactionTab == 'List' }" 
                          target="_self"
                          @click="this.activeTransactionTab = 'List'">
                          {{ $t('All Transactions') }}
                        </a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane fade" id="add_transaction_tab" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'Add' }">
                        <div class="card card-xxl-stretch mb-5 mb-xxl-10 h-sm-100">
                            <div class="card-body py-1">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3">
                                            <span>{{ $t('Status') }}</span>
                                        </label>
                                        <Select2 v-model="transactionForm.status" :options="statusList" :settings="{ width: '100%', dropdownParent: '#transaction_details_modal .modal-content' }"/>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="fw-bold form-label mt-3">
                                            <span>{{ $t('Description') }}</span>
                                        </label>
                                        <textarea rows="2" class="form-control " name="description" v-model="transactionForm.last_transaction_description"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card card-flush mt-4">
                                            <div class="d-grid gap-2">
                                                <button type="button" class="btn btn-success btn-lg" @click="this.saveTransactionData()" :disabled="this.transactionForm.status == -1">
                                                    <span class="indicator-label">{{ $t('Save') }}</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="all_transactions_tab" role="tabpanel" :class="{ 'show active': this.activeTransactionTab == 'List' }">
                          <div> 
                              <!-- <div class="row">
                                <div class="col-lg-12">
                                  <div class="text-end"> 
                                      <button class="btn btn-secondary btn-sm mb-4" style="font-size:11px !important;" data-bs-toggle="modal" data-bs-target="#transaction_add_modal">
                                          Add Transaction
                                      </button>
                                    </div>
                                </div> 
                              </div>   -->
                              <div>
                                  <div class="table-responsive"> 
                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="transactions" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true"> 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxSearchPanel :visible="true"/>  
                                        
                                        <DxColumn data-field="created_at" :caption="$t('Created At')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="created_at-cell-template"/> 
                                        <DxColumn data-field="created_by_name" :caption="$t('Created By')" cell-template="created_by_name-cell-template"/> 
                                        <DxColumn data-field="last_transaction_date" :caption="$t('Last Transaction Date')" alignment="right" format="dd.MM.yyyy" cell-template="last_transaction_date-cell-template"/> 
                                        <DxColumn data-field="last_transaction_by_name" :caption="$t('Last Transaction By')" cell-template="last_transaction_by_name-cell-template"/>
                                        <DxColumn data-field="transaction_source" :caption="$t('Transaction Source')" cell-template="transaction_source-cell-template"/>
                                        <DxColumn data-field="status" :caption="$t('Status')" cell-template="status-cell-template" alignment="left"/>
                                        <DxColumn data-field="download_reason" :caption="$t('Download Reason')"/> 
                                        <DxColumn data-field="actions" caption="..." cell-template="actions-cell-template" width="84" alignment="center"/>

                                        <template #created_at-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ previewDate(data.data.created_at) }}
                                          </span>
                                        </template>
                                        <template #created_by_name-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.created_by_name }}
                                          </span>
                                        </template>
                                        <template #last_transaction_date-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ previewDate(data.data.last_transaction_date) }}
                                          </span>
                                        </template>
                                        <template #last_transaction_by_name-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.last_transaction_by_name }}
                                          </span>
                                        </template>
                                        <template #transaction_source-cell-template="{ data: data }">
                                          <span class="align-middle">
                                          {{ data.data.transaction_source }}
                                          </span>
                                        </template>
                                        <template #status-cell-template="{ data: data }">
                                          <div class="align-middle">
                                                <span class="badge badge-sm badge-success" v-if="data.data.status == 1">File Uploaded</span>
                                                <span class="badge badge-sm badge-info" v-if="data.data.status == 2">File Downloaded</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 3">File Viewed</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 4">File Forwarded</span>
                                                <span class="badge badge-sm badge-info" v-if="data.data.status == 5">Submitted for Internal Confirmation</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 6">Submitted for External Confirmation</span>
                                                <span class="badge badge-sm badge-success" v-if="data.data.status == 7">Confirmed</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 8">Rejected</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 9">Revision Requested</span>
                                                <span class="badge badge-sm badge-warning" v-if="data.data.status == 10">Description Requested</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 11">Desription Added</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 12">Deletion Requested</span>
                                                <span class="badge badge-sm badge-danger" v-if="data.data.status == 13">File Deleted</span>
                                                <span class="badge badge-sm badge-primary" v-if="data.data.status == 14">Description Input</span>
                                          </div>
                                        </template>
                                        <template #actions-cell-template="{ data: data }">
                                          <div class="align-middle">
                                              <div class="d-flex justify-content-end flex-shrink-0">
                                                    <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" style="height: 30px; width: 30px;" @click="onEditTransactionData(data.data, selectedDocument)" data-bs-toggle="modal" data-bs-target="#transaction_edit_modal">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path><path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path></svg>
                                                        </span>
                                                    </button>
                                                    <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" style="height: 30px; width: 30px;" @click="onDeleteTransactionData(data.data, selectedDocument)">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg>
                                                        </span>
                                                    </button>
                                                </div>
                                          </div>
                                        </template> 

                                        <DxSummary>
                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="created_at"/>
                                        </DxSummary>
                                        
                                      </DxDataGrid> 
                                    </div>
                              </div>
                      </div>
                    </div>
                </div> 
              </div>
          </div>
      </div>
    </div> 

    <!-- Download Modal -->
    <div class="modal fade" tabindex="-1" id="file_download_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content"  >
              <div class="modal-header bg-primary">
                  <h2 class="modal-title fw-bolder col-md-10 text-white" v-if="this.requestedFile">
                      {{ $t('Download') }} "{{ this.requestedFile.name }}"
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="downloadModal">
                      <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white"> 
                <div class="row"> 
                  <div class="col-lg-12">
                      <label class="fw-bold form-label mt-3">
                          <span>{{ $t('Download Reason') }}</span>
                      </label>
                      <textarea rows="2" class="form-control " name="description" v-model="downloadForm.download_reason"></textarea>
                  </div> 
              </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary w-100" @click="onDownloadFile(this.requestedFile)"> {{ $t('Download') }} </button>
              </div>
          </div>
      </div>
    </div> 
    
  </div> 
</template> 
<style setup>
.color-input .box {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px;
}
#dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #66BB6A;
  padding: 10px;
}

.passive-dropzone-external {
  width: 350px;
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  border-color: #E57373;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dx-fileuploader-content > .dx-fileuploader-upload-button {
  width: 100% !important;
  background-color: #009ef7;
  color: white;
  padding: 4px;
  font-weight: 600;
}
.dx-fileuploader-content > .dx-fileuploader-upload-button:hover {
  width: 100% !important;
  background-color: #f1faff;
  color: #009ef7;
  padding: 4px;
  font-weight: 600;
}
</style>
<script>
// Component Alias: `DSHF001`  
import axios from 'axios';
import moment from 'moment';
import icons from '../../assets/icons/icons.json';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel, DxTotalItem, DxSummary } from 'devextreme-vue/data-grid';

export default {
  name: "Documents",
  components:{
    DxFileUploader, DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxColumn, DxSearchPanel, DxTotalItem, DxSummary
  },
  data() {
    return { 
      activeMainView: 'all_documents',
      activeTransactionTab: 'Add',
      collapsedFolder: null,
      downloadForm: {
        download_reason: null
      },
      requestedFile: null,
      isFirstLaunch: true,
      documentTypeCriteriaList: [],
      filterForm: {
          document_type_id: '-1',
          start_date: moment().subtract(10, 'days'),
          end_date: new Date(),
      },
      userData: this.$getUser(),
      lists: [],
      searchbar: '',
      folderSearch: '',
      isListProgressing: false, 
      icons: icons,
      form: {
        name: null,
        description: null,
        icon: 'fas fa-folder',
        color: '#009ef7',
        size_limit: 1048576,
        blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
        allowed_ext: null
      },
      uploadForm: {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        upload_source: 0,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      },
      isViewIconModal: false,
      parentFolders: [],
      activeFolder: null,
      isPreparementsReady: false,
      documentTypeslist: [],
      importanceList: [ 
        { id: 0, text: 'Normal' },
        { id: 1, text: 'Low' },
        { id: 2, text: 'High' },
        { id: 3, text: 'Very High' }
      ],
      documentSourceList: [
        { id: -1, text: 'Select an option' },
        { id: 0, text: 'Incoming Document' },
        { id: 1, text: 'Outgoing Document' }
      ], 
      havePersonelDataList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      gpdrObtainList: [
        { id: 0, text: 'No' },
        { id: 1, text: 'Yes' }
      ],
      documentTypesForUpload: [],
      documents: [],
      transactions: [],
      transactionForm: {
        status: -1,
        last_transaction_description: null
      },
      statusList: [
        { id: -1, text: 'Select an option'},
        { id: 14, text: 'Description Input'}
        //{ id: 1, text: 'Upload'},
        //{ id: 2, text: 'Download'},
        //{ id: 3, text: 'Viewed'},
        //{ id: 4, text: 'Forwarded'},
        //{ id: 5, text: 'Submitted for Internal Confirmation' },
        //{ id: 6, text: 'Submitted for External Confirmation' },
        //{ id: 7, text: 'Confirmed'},
        //{ id: 8, text: 'Rejected'},
        //{ id: 9, text: 'Revision Requested' },
        //{ id: 10, text: 'Description Requested'},
        //{ id: 11, text: 'Desription Added'},
        //{ id: 12, text: 'Deletion Requested' },
        //{ id: 13, text: 'Deleted'} 
      ],
      selectedDocument: null,
      menuSelection: '/DocumentHelppage',
      iconView: false,
      activeStep: 1,
      selectedDocumentType: {id: null, text: null, icon: null, color: null}
    };
  },
  computed: { 
    convertStringToISO(){
      return (value)=>{
       if (value) {
          value = value.replace(/ı/g, 'i');
          value = value.replace(/İ/g, 'I');
          value = value.replace(/ş/g, 's');
          value = value.replace(/Ş/g, 'S');
          value = value.replace(/ç/g, 'c');
          value = value.replace(/Ç/g, 'C');
          value = value.replace(/ö/g, 'o');
          value = value.replace(/Ö/g, 'O');
          value = value.replace(/ü/g, 'u');
          value = value.replace(/Ü/g, 'U');
          value = value.replace(/ğ/g, 'g');
          value = value.replace(/Ğ/g, 'G');
          return value;
        }
      }
    },
    searchableList() {
        return this.icons.filter(p => { 
            return p.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    searchableFoldersList() {
        return this.lists.filter(p => { 
            return p.name.toLowerCase().indexOf(this.folderSearch.toLowerCase()) != -1;
        });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    calculateSize(){
      return (bytes)=>{
            if (bytes) 
            {
                const decimals = 2;
                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
            }
        } 
    },
    getDocumentTypeID(){
      return (value)=>{
       if (value) {
          if(value == -1){
            return null;
          }
          else{
            var transactionID = null;
            this.documentTypesForUpload.forEach((item) => {
              if(item.id == value){
                transactionID = item.document_type_id;
              }
            });

            return transactionID;
          }
        }
      }  
    }
  },
  methods: {
    getData() { 
      this.isFirstLaunch = false;
      this.selectedDocument = null;
      this.isPreparementsReady = true;
      this.folderSearch = '';
      this.parentFolders = [];
      this.activeFolder = null;
      this.documents = [];
      this.requestedFile = null;
       
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
          this.documentTypeCriteriaList = response.data;
          this.documentTypeCriteriaList.unshift({ id: '-1', text: 'All'});
          this.filterForm['document_type_id'] = '-1';
        }
      }) 
      .catch(function (error) {
          console.log(error);
          this.Progress.finish();
      });
    }, 
    onIconSelection(item){
      this.form.icon = item;  
    }, 
    onCreateFolder(){ 
      
      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        parent: parent == null ? null : parent.id,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/CreateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been created successfully..!", 'success');   
              this.$refs.closeAddModal.click(); 
              if(parent == null){
                this.getFolders();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    onUpdateFolder(){ 

      var parent = null;
      if(this.parentFolders.length > 0){
        parent = this.parentFolders[this.parentFolders.length - 1];
      }

      const parameters = { 
        session_id: this.$getUser().session_id,
        id: this.form.id,
        name: this.form.name,
        color: this.form.color,
        size_limit: this.form.size_limit,
        allowed_ext: this.form.allowed_ext,
        blocked_ext: this.form.blocked_ext,
        icon: this.form.icon,
        description: this.form.description
      }; 
      axios.post('/api/Folders/UpdateFolder', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              this.$swal("Success", "Folder has been saved successfully..!", 'success');  
              this.$refs.closeUpdateModal.click(); 
              if(parent == null){
                this.getFolders();
              }
              else{
                this.onOpenFolder(parent);
              }
            }
         }) 
        .catch(function (error) {
            console.log(error); 
        });
    },
    openItemActionMenu(item){
      if(item.isOpenActions){
        item.isOpenActions = !item.isOpenActions;
      }
      else{
        this.lists.forEach((listItem) => { listItem.isOpenActions = false; });
        item.isOpenActions = !item.isOpenActions;
      }
      
    },
    openEditModal(item){
      const parameters = { 
        session_id: this.$getUser().session_id,
        id: item.id
      }; 
      axios.post('/api/Folders/GetFolderById', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
            this.form = {
              id: response.data.id,
              name: response.data.name,
              description: response.data.description,
              icon: response.data.icon,
              color: response.data.color,
              size_limit: response.data.size_limit,
              blocked_ext: response.data.blocked_ext,
              allowed_ext: response.data.allowed_ext
            };
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    deleteFolder(item) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this data?",
          confirmButtonColor: "#E53935",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          icon: 'warning'
        })
        .then((result) => {
          if (result.isConfirmed) {

                var parent = null;
                if(this.parentFolders.length > 0){
                  parent = this.parentFolders[this.parentFolders.length - 1];
                }
              
                const parameters = { 
                  session_id: this.$getUser().session_id,
                  id: item.id
                }; 
                axios.post('/api/Folders/DeleteFolder', parameters, {'Content-Type': 'application/json'})
                  .then((response) => {
                       if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            this.$swal("Deleted.", "Folder has been deleted..!", 'warning');  
                            if(parent == null){
                              this.getFolders();
                            }
                            else{
                              this.onOpenFolder(parent);
                            }
                       }
                  }) 
                  .catch(function (error) {
                      console.log(error);
                      this.Progress.finish();
                  });          
          }
        });
    },
    onOpenFolder(item, UseParentID){  
      if(item == null || item == '' || item == undefined){
        this.getFolders();
        return;
      }
      this.selectedDocument = null;
      this.collapsedFolder = null;
      this.requestedFile = null;
      this.isPreparementsReady = false;
      this.folderSearch = '';
      if(UseParentID){  
        if(item.parent == null || item.parent == ''){
            this.getFolders();
            return;
        }

        this.parentFolders.forEach((folderItem) => { 
          if(folderItem.id == item.parent){
             item = folderItem;
          }
        });
      }
      this.lists = [];
      const parameters = { 
        session_id: this.$getUser().session_id,
        parent: item.id
      }; 
      axios.post('/api/Folders/GetSubFolders', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
              response.data.forEach((dataItem) => { 
                dataItem.isOpenActions = false;
              });
              this.lists = response.data;
              this.form = {
                name: null,
                description: null,
                icon: 'fas fa-folder',
                color: '#009ef7',
                size_limit: 1048576,
                blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
                allowed_ext: null
              };
 
              this.activeFolder = item;
              this.isPreparementsReady = true;
              this.onFeedBreadCrumbs(item);
              this.getFolderDocuments();
           }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onFeedBreadCrumbs(item){
      var isExists = false;
      this.parentFolders.forEach((parentItem, index) => { 
        if(parentItem.id == item.id){
          isExists = true;
          this.parentFolders.splice(index + 1, this.parentFolders.length);
        }
      });
      if(!isExists){
        this.parentFolders.push(item);
      }
    },
    getDocumentTypes(folder){
      this.collapsedFolder = folder;
      this.documentTypeslist = [];
      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.documentTypeslist = response.data;
            //this.documentTypeslist.unshift({ id: null, code: '-', name: 'Select None'});
            this.documentTypeslist.forEach((item) => { 
              item['folder_id'] = folder.id;
            });

            const parameters = { 
              session_id: this.$getUser().session_id,
              folder_id: folder.id
            }; 
            axios.post('/api/Folders/GetDocumentTypesByFolderID', parameters, {'Content-Type': 'application/json'})
              .then((folderTypeResponse) => {
                this.documentTypeslist.forEach((typeItem) => { 
                    folderTypeResponse.data.forEach((folderTypeItem) => { 
                        if(typeItem.id == folderTypeItem.document_type_id){
                          typeItem['isAdded'] = true;
                        }
                    });
                }); 
               }) 
              .catch(function (error) {
                  console.log(error);
                  this.Progress.finish();
              });          
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onDocumentTypeSave(typeSelection){
        const parameters = { 
          session_id: this.$getUser().session_id,
          folder_id: typeSelection.folder_id,
          document_type_id: typeSelection.id
        }; 
        axios.post('/api/Folders/UpdateDocumentType', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.getDocumentTypes(this.collapsedFolder);
                //this.$refs.closeDocumentTypeModal.click(); 
            }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
        }); 
    },
    getDocumentTypesForUpload(){
      this.documentTypesForUpload = [];
      this.activeStep = 1;
      this.uploadForm =  {
        document_type_id: -1,
        importance: 0,
        document_source: -1,
        upload_source: 0,
        source_company: null,
        source_person: null,
        have_personal_data: 0,
        have_gdpr_permission: 0,
        gdpr_description: null,
        document_name: null,
        document_no: null,
        document_description: null
      };
      const parameters = { 
        session_id: this.$getUser().session_id,
        is_manual_upload: 1
      }; 
      axios.post('/api/Folders/GetDocumentTypes', parameters, {'Content-Type': 'application/json'})
      .then((response) => { 
        this.documentTypesForUpload = response.data;
      }) 
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    },
    onPersonalDataChange(){
        this.uploadForm.have_gdpr_permission = 0;
        this.uploadForm.gdpr_description = null;
    },
    getFolders(){      
      this.isFirstLaunch = false;
      this.selectedDocument = null;
      this.isPreparementsReady = true;
      this.folderSearch = '';
      this.parentFolders = [];
      this.activeFolder = null;
      this.documents = [];
      this.requestedFile = null;

      const parameters = { 
        session_id: this.$getUser().session_id
      }; 
      axios.post('/api/Folders/GetFolders', parameters, {'Content-Type': 'application/json'})
      .then((response) => {
        response.data.forEach((item) => { 
          item.isOpenActions = false;
        });
        this.lists = response.data;
        this.form = {
          name: null,
          description: null,
          icon: 'fas fa-folder',
          color: '#009ef7',
          size_limit: 1048576,
          blocked_ext: 'bat, bin, com, exe, iso, jar, msi',
          allowed_ext: null
        };
        this.isPreparementsReady = false;

        if(!this.lists.length > 0){
            this.isFirstLaunch = true;
        }

        this.collapsedFolder = null;
      }) 
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });    
    },
    getUploadHeaders(){
        return {
          sid: this.$getUser().session_id,
          fid: null,
          dos: this.uploadForm.document_source == -1 ? null : this.uploadForm.document_source,
          dti: this.uploadForm.document_type_id,
          hgp: this.uploadForm.have_gdpr_permission,
          hpd: this.uploadForm.have_personal_data,
          imp: this.uploadForm.importance,
          ups: 0, /* Upload Source */
        };
    },
    getFolderDocuments(){ 
        this.selectedDocument = null;
        this.requestedFile = null;
        this.documents = [];
        if(this.activeFolder == null){
            this.getViewAllFolderDocuments();
            return;
        }
        const parameters = { 
          session_id: this.$getUser().session_id,
          folder_id: this.activeFolder.id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD')
        }; 
        axios.post('/api/Folders/GetDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    getViewAllFolderDocuments(){ 
        this.selectedDocument = null;
        this.requestedFile = null;
        this.documents = [];
        const parameters = { 
          session_id: this.$getUser().session_id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD'),
          document_type_id: this.filterForm.document_type_id == '-1' ? null : this.filterForm.document_type_id
        }; 
        axios.post('/api/Folders/ViewAllDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    getDocumentsByFilter(){ 
        this.selectedDocument = null;
        this.documents = [];
        const parameters = { 
          session_id: this.$getUser().session_id,
          start_date: moment(this.filterForm.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.filterForm.end_date).format('YYYY-MM-DD')
        }; 
        axios.post('/api/Folders/GetAllDocuments', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documents = response.data;
                }
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    onUploadCompleted(e){
      const parameters = { 
        session_id: this.$getUser().session_id,
        fid: e.request.responseText,
        source_company: this.uploadForm.source_company,
        source_person: this.uploadForm.source_person,
        document_name: this.uploadForm.document_name,
        document_no: this.uploadForm.document_no,
        description: this.uploadForm.description,
        gdpr_description: this.uploadForm.gdpr_description
      };
      axios.post('/api/Folders/UpdateDocumentDetails', parameters, {'Content-Type': 'application/json'})
      .then(() => {
        this.getFolderDocuments();
        this.$swal("Success.", "File uploaded successfully..!", 'success');
        this.$refs.closeUploadModal.click();
      })
      .catch(function (error) {
        console.log(error);
        this.Progress.finish();
      });
    }, 
    onDownloadFile(item){
      const config = { 
        'Content-type': 'application/json',
        responseType: 'blob',
        headers: {
          sid: this.$getUser().session_id,
          fid: item.folder_id
        }
      }; 
      const parameters = {
          name: item.name,
          fid: item.fid,
          id: item.id,
          rsn: this.downloadForm.download_reason
      };
      axios.post('/api/Folders/Download', parameters, config)
          .then((response) => { 
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', parameters.name);
                  document.body.appendChild(link);
                  link.click();
                  this.onOpenAccordion(item);
                  this.$refs.downloadModal.click();
                  this.downloadForm = {
                    download_reason: null
                  };
              }
          }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          }); 
    },
    onDeleteDocument(item){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id,
                      fnm: item.name,
                      fid: item.fid
                  }; 
                  axios.post('/api/Folders/DeleteDocument', parameters, {'Content-Type': 'application/json'})
                  .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                      if(this.activeMainView == 'all_documents') {
                        this.getViewAllFolderDocuments();
                      } else {
                        this.getFolderDocuments();
                      }
                      this.$refs.documentDetailsModal.click();
                    }
                  }) 
                  .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                  });        
             }
        });   
    },
    onOpenDetail(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.selectedDocument = item;

      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null,
        last_transaction_description: 'File details viewed.',
        status: 3,
        transaction_source: 'AUTO TRANSACTION',
        download_reason: null
      }; 
      axios.post('/api/Folders/SetCustomTransaction', parameters, {'Content-Type': 'application/json'})
        .then(() => {

         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onOpenAccordion(item, detailData){
      if(item == null){
        item = detailData.data;
      } 
      this.transactions = [];
      this.selectedDocument = item;
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: item.id,
        folder_id: item.folder_id !== '' ? item.folder_id : null
      }; 
      axios.post('/api/TransactionsDocument/GetTransactionsDocumentBy', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.transactions = response.data;
            this.transactionForm = {
              status: -1,
              last_transaction_description: null
            };
            this.activeTransactionTab = 'Add';
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    saveTransactionData(){
      const parameters = { 
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/CreateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been saved successfully..!", 'success');  
                  this.$refs.closeTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    },
    onDeleteTransactionData(item, documentItem){
       this.$swal.fire({
            title: "Are you sure you want to delete this document?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "YES, DELETE IT",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                  const parameters = { 
                      session_id: this.$getUser().session_id,
                      id: item.id
                  }; 
                  axios.post('/api/Folders/DeleteTransaction', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                              this.onOpenAccordion(documentItem);
                          }
                      }) 
                    .catch(function (error) {
                        console.log(error);
                        this.Progress.finish();
                    });        
             }
        });   
    },
    onEditTransactionData(item){ 
      this.transactionForm = {
          status: item.status == null || item.status == 0 ? -1 : item.status,
          last_transaction_description: item.last_transaction_description,
          id: item.id
      };
      if(item.status == 14) { this.transactionForm.status_text = 'Description Input' }
      if(item.status == 1) { this.transactionForm.status_text = 'Upload'; }
      if(item.status == 2) { this.transactionForm.status_text = 'Download'; }
      if(item.status == 3) { this.transactionForm.status_text = 'Viewed'; }
      if(item.status == 4) { this.transactionForm.status_text = 'Forwarded'; }
      if(item.status == 5) { this.transactionForm.status_text = 'Submitted for Internal Confirmation'; }
      if(item.status == 6) { this.transactionForm.status_text = 'Submitted for External Confirmation'; }
      if(item.status == 7) { this.transactionForm.status_text = 'Confirmed'; }
      if(item.status == 8) { this.transactionForm.status_text = 'Rejected'; }
      if(item.status == 9) { this.transactionForm.status_text = 'Revision Requested'; }
      if(item.status == 10) { this.transactionForm.status_text = 'Description Requested'; }
      if(item.status == 11) { this.transactionForm.status_text = 'Desription Added'; }
      if(item.status == 12) { this.transactionForm.status_text = 'Deletion Requested'; }
      if(item.status == 13) { this.transactionForm.status_text = 'Deleted'; }
    },
    updateTransactionData(){
      const parameters = { 
        id: this.transactionForm.id,
        session_id: this.$getUser().session_id,
        document_id: this.selectedDocument.id,
        folder_id: this.selectedDocument.folder_id != '' ? this.selectedDocument.folder_id : null,
        last_transaction_description: this.transactionForm.last_transaction_description,
        status: this.transactionForm.status
      }; 
      axios.post('/api/TransactionsDocument/UpdateTransactionsDocument', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
              if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                  this.$swal("Success", "Transaction has been updated successfully..!", 'success');  
                  this.$refs.closeEditTransactionModal.click(); 
                  this.onOpenAccordion(this.selectedDocument);
              }
         }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        });          
    }, 
    onChangeDateCriteria(type) {

      // Today 0
      // Yesterday 1
      // Past 3 
      // Past 10 
      // Past 30 

        this.filterForm.start_date = moment(); /* Today */
        this.filterForm.end_date = moment(); /* Today */

        if(type == 1) { 
          this.filterForm.start_date = moment().subtract(1, 'days');
          this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 3) {  
          this.filterForm.start_date = moment().subtract(3, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 10) { 
          this.filterForm.start_date = moment().subtract(10, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        }
        if(type == 30) { 
          this.filterForm.start_date = moment().subtract(30, 'days');
          //this.filterForm.end_date = moment().subtract(1, 'days');
        } 

        this.getFolderDocuments();
    },
    resetFileManager() {
        this.isFirstLaunch = true;
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date()
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.parentFolders = [];
        this.activeFolder = null;
        this.documents = [];
        this.transactions = [];
        this.lists = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          upload_source: 0,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
    },
    setCriteriasToDefault() {
        this.filterForm = { 
            start_date: moment().subtract(10, 'days'),
            end_date: new Date(),
            document_type_id: '-1'
        };
        this.selectedDocument = null;
        this.isPreparementsReady = true;
        this.folderSearch = '';
        this.transactions = [];
        this.uploadForm =  {
          document_type_id: -1,
          importance: 0,
          document_source: -1,
          upload_source: 0,
          source_company: null,
          source_person: null,
          have_personal_data: 0,
          have_gdpr_permission: 0,
          gdpr_description: null,
          document_name: null,
          document_no: null,
          document_description: null
        };
        this.downloadForm = { 
          download_reason: null
        };
        this.requestedFile = null;
        this.documents = [];
    },
    onRequestFile(item){
      this.requestedFile = item;
      this.downloadForm = {
        download_reason: null
      };
    },
    onRowPrepared(e){ 
        if(e.rowType === 'data') {
                if (e.data.is_deleted == '1') {
                    e.rowElement.classList.add('bg-file-deleted');  
                }
        } 
    }, 
    changeMainActiveView(value){
      this.activeMainView = value;
      this.documents = [];
      if(value == 'folders') {
        this.getFolders();
      }
    },
    onNextStep() {
      if(this.activeStep == 2) { this.$refs.fileUploader.instance.reset(); }

      this.activeStep++;
    },
    onBackStep() {
      this.activeStep--;
    },
    onSelectDocumentType(item) {
      this.uploadForm.document_type_id = item.id;
      this.selectedDocumentType = item;
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/DocumentHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() {
      this.helpPageViewIcon();
      this.activeMainView = 'all_documents';
      this.getData();
      this.resetFileManager();
  }
}; 
</script>