<template>
  <div>
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Expense Type') }}
            </h1>
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ListTypes' }">{{ $t('List Types') }}</router-link></li>
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'ExpenseTypeList' }">{{ $t('Expense Type') }}</router-link></li> 
                <li class="breadcrumb-item text-gray-600">{{ $t('Create Expense Type') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/ExpenseTypeList">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                </button>
            </router-link> 
        </div>
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
        <RequiredField></RequiredField>
        <div class="row">
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                    <div class="p-10">
                        <div class="row">
                            <div class="col-lg-12 mt-0"> 
                                <div class="d-flex flex-row row">
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mb-2 required">
                                                            {{ $t('Expense Group') }}
                                                        </label>
                                                        <select class="form-control" v-model="form.expenseGroupSelection">
                                                            <option v-for="item in expenseGroups" :value="item" :key="item.id">
                                                            {{ item.code }} <span v-if="item.code != null">-</span> {{ item.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Code') }}
                                                        </label>
                                                        <input type="text" class="form-control " name="code"
                                                            v-model="form.code">
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                                            {{ $t('Name') }}
                                                        </label>
                                                        <input type="text" class="form-control " name="name"
                                                            v-model="form.name">
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                            <div class="card-body">
                                                <div class="row">                                                
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Description') }}</span>
                                                        </label>
                                                        <textarea rows="3" class="form-control " name="description" v-model="form.description"></textarea>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="form-check mt-3">
                                                            <input class="form-check-input fs-6 fw-bold form-label mt-3" type="checkbox" value="" id="isDefault" v-model="form.is_default">
                                                            <label class="fs-6 fw-bold form-label mt-3" for="isDefault">
                                                                {{ $t('Default') }}
                                                            </label>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                        </span> 
                                        <strong v-if="!this.isProgressing"> {{ $t('Save Expense Type') }}</strong> 
                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                    </button>
                                </div>                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// Component Alias: `EXTC001`
import axios from 'axios'  
 
// import Swal from 'sweetalert2'; 
 
export default {
  name: "ExpenseTypeCreate",
  data() {
    return {
      expenseGroups: [],
      isProgressing: false,
      form: { 
          name: null,
          code: null,
          description: null,
          session_id: null,
          parent_id: null,
          expenseGroupSelection: null,
          is_default: false
      },
      menuSelection: '/ExpenseTypeHelppage',
      iconView : false
    };
  },
  methods: { 
    getData(){
      this.expenseGroups = []; 
        this.$setSessionStorage('refreshList', true);
      this.$Progress.start();
      const params = {
        session_id: this.$getUser().session_id,
        list_type: 4, // Expense Group: 4
        get_access_status: 1
      };
      axios.post('/api/Helper/GetLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          this.expenseGroups = response.data; 
          this.expenseGroups.unshift({ code: null, name: 'Select an option'});
          this.form.expenseGroupSelection = this.expenseGroups[0];
          this.$Progress.finish();
        })
        .catch(function (error) {
          console.log(error); 
          this.$Progress.finish();
        });
    },
    saveData(){
      this.isProgressing = true;
      const params = this.form; 
      const that = this;     
      params.list_type = 5; // Expense Type: 5
      params.session_id = this.$getUser().session_id;
      params.parent_id = this.form.expenseGroupSelection.id;
      axios.post('/api/Helper/CreateListItem', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data);
                this.$setSessionStorage('refreshList', true); 
                this.$router.push({ name: 'ExpenseTypeUpdate' });
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
         .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      },
      async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/ExpenseTypeHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
}; 
</script>