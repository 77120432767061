<template> 
  <div> 
    <div class="page-title flex-row me-3 mt-3"> 
        <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('VAT Operations') }}
        </h1>  
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                </a>
            </li>  
            <li class="breadcrumb-item text-primary">{{ $t('Accounting') }}</li> 
            <li class="breadcrumb-item text-primary">{{ $t('Accounting Transaction') }}</li>
            <li class="breadcrumb-item text-gray-600">{{ $t('VAT Operations') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </li>    
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">  
        <div class="row">       
            <div class="col-lg-12">
                <div class="card mb-6">
                    <div class="card-header border-0 pt-6">
                        <div class="card-title">
                        </div>
                        <div class="card-toolbar">
                            <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(53,'R')">
                                <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                    </template>
                                </Popper>
                                <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <span v-if="!this.isListProgressing"> {{ $t('List Balances') }} </span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                                </button>                        
                            </div>
                            <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                <div class="fw-bolder me-5">
                                <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                                <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row p-3">
                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2 bg-light">
                                <div class="fv-row mb-7 row">    
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Accounting Period') }}</span> 
                                        </label>   
                                        <Select2 v-model="form.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event)" :disabled="this.lists.length > 0"/>  
                                    </div>
                                    <div class="col-lg">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('List Type') }}</span>
                                        </label>
                                        <Select2 v-model="form.list_type" :options="listTypes" :settings="{width: '100%'}" :disabled="this.lists.length > 0" @select="getPeriodMonths()"/>
                                    </div>
                                    <div class="col-lg" v-if="form.list_type == 1">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Accounting Months') }}</span>
                                        </label> 
                                        <Select2 v-model="form.month" :options="periodList" @select="onChangeMonth($event)" :settings="{ width: '100%' }" :disabled="this.lists.length > 0"/>
                                    </div>  
                                    <div class="col-lg"  v-if="form.list_type == 2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('Start Date') }}</span>
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" :disabled="this.lists.length > 0"></Datepicker>  
                                    </div> 
                                    <div class="col-lg"  v-if="form.list_type == 2">
                                        <label class="fs-6 fw-bold form-label mt-3">
                                            <span>{{ $t('End Date') }}</span>
                                        </label>
                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false" :disabled="this.lists.length > 0"></Datepicker>  
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div>                                
                                <DxDataGrid id="gridContainer"  
                                        :show-borders="true" 
                                        :data-source="lists" 
                                        :allow-column-reordering="true"  
                                        :allow-column-resizing="true" 
                                        :column-auto-width="true" 
                                        :row-alternatin-enabled="true"
                                        :hover-state-enabled="true" 
                                        :show-row-lines="true" 
                                        @exporting="this.$onExporting($event , 'VAT-Operations')"> 

                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxGroupPanel :visible="true"/>      
                                        <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="100"/>
                                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                        <DxSearchPanel :visible="true"/>  
                                        <DxScrolling
                                            mode="virtual"
                                            row-rendering-mode="virtual"
                                        />
                                        <DxColumn data-field="l2_account_code" :caption="$t('Account Code')" header-cell-template="lt-account-code-header" />
                                        <DxColumn data-field="l2_account_name" :caption="$t('Account Name')" header-cell-template="lt-account-name-header" />
                                        <DxColumn data-field="account_code" :caption="$t('Account Code')" header-cell-template="account-code-header" />
                                        <DxColumn data-field="account_name" :caption="$t('Account Name')" header-cell-template="account-name-header" />
                                        <DxColumn data-field="balance" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right" />
                                        <DxColumn data-field="debit_credit" :caption="$t('Debit / Credit')" header-cell-template="debit-credit-header"/>

                                        <template #lt-account-code-header>
                                            <div>{{ $t('Account Code') }}</div><div>{{ $t('(Level 2)') }}</div>
                                        </template>
                                        <template #lt-account-name-header>
                                            <div>{{ $t('Account Name') }}</div><div>{{ $t('(Level 2)') }}</div>
                                        </template>
                                        <template #account-code-header>
                                            <div>{{ $t('Account') }}</div><div>{{ $t('Code') }}</div>
                                        </template>
                                        <template #account-name-header>
                                            <div>{{ $t('Account') }}</div><div>{{ $t('Name') }}</div>
                                        </template>
                                        <template #new-account-code-header>
                                            <div>{{ $t('New') }}</div><div>{{ $t('Account Code') }}</div>
                                        </template>
                                        <template #debit-credit-header>
                                            <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                        </template>

                                        <DxSummary :calculate-custom-summary="setSummaries">
                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="l2_account_code"/>
                                            <DxGroupItem
                                                :show-in-group-footer="true"
                                                name="balance"
                                                summary-type="custom"
                                                show-in-column="balance"
                                                display-format="{0}"
                                            /> 
                                            <DxTotalItem name="balance"
                                                summary-type="custom"
                                                show-in-column="balance"
                                            />
                                        </DxSummary>
                                </DxDataGrid>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-6">
                    <div class="card-body">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2">
                            <div class="fv-row mb-7 row">                      
                                <div class="col-lg-2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Voucher Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :minDate="this.form.periodSelection.start_date" :maxDate="this.form.periodSelection.end_date"></Datepicker>
                                </div>
                                <div class="col-lg-6">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Description') }}</span>
                                    </label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" name="discount" v-model="form.description">
                                        <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                            <div class="dropdown dropup">
                                                <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                    <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                </button>
                                                <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                    <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                        <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                            <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                :class="{'text-success': this.form.description == item.description && item.description !== ''}">
                                                                <i class="bi bi-dash fw-boldest" v-if="this.form.description !== item.description"> </i> 
                                                                <i class="bi bi-check-lg fw-boldest text-success" v-if="this.form.description == item.description && item.description !== ''"> </i>
                                                                {{item.text}}  
                                                            </a>
                                                            <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span> {{ $t('VAT Calculated Type') }} </span>
                                    </label>
                                    <Select2 v-model="this.form.vat_calculated_type" @select="onChangeVATCalculatedType()" :options="this.vatCalculatedTypeList" :settings="{ width: '100%' }"/>
                                </div>
                                <div class="col-lg-2">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span> {{ $t('VAT Calculated Account') }} </span>
                                    </label>
                                    <Select2 v-model="this.form.target_account_id" :options="this.accountPlanList" :settings="{ width: '100%' }"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-12" v-if="this.$isCredentialActive(53,'I')">
                <div class="card card-xxl-stretch mb-6">
                    <div class="card-body">
                        <div class="d-grid gap-2"> 
                            <button class="btn btn-success btn-lg" type="button" @click="createVATVoucher()" data-kt-indicator="on" :disabled="!this.lists.length > 0"> 
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span>  
                                <strong v-if="!this.isSaving"> {{ $t('Create VAT Operation') }} </strong> 
                                <span class="indicator-progress" v-if="this.isSaving">
                                    {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios' 
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxGroupItem, DxScrolling} from 'devextreme-vue/data-grid'; 
 
export default {
    name: "VATOperationsList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxColumn, DxSummary, DxTotalItem , DxSearchPanel, DxGroupItem, DxScrolling
    },
	data() {
        return {
            searchbar: '',
            isOpenDescriptionDropdown: false,
            isListProgressing: false,
            isOpenActionsDropdown: false, 
            summary: {},
            lists: [],
            accountingYears: [],            
            listTypes: [
                { id: 1, text: 'Monthly'},
                // { id: 2, text: 'Date Range'}
            ],
            periodList: [],
            form:  {
                record_date: moment(), 
                period_id: null,
                periodSelection: {},
                list_type: 1,
                start_date: moment(),
                end_date: moment(),
                month: -1,
                description: null,
                vat_calculated_type: -1,
                target_account_id: '-1',
            },
            vatCalculatedTypeList: [
                { id: -1, text: 'Select an option'},
                { id: 0, text: 'ALL'},
                { id: 1, text: 'To be Paid'},
                { id: 2, text: 'To be Refund'},
            ],
            accountPlanList: [{id: '-1', text: 'Select an option'}],
            isAccountingYearLoading: false,
            descriptionTypeList: [],
            temp_session_id: null,
            planList: [],
            accountSelection: null,
            newAccountSelection: null,
            menuSelection : '/VATOperationsHelppage',
            iconView : false,
            pageSize: 100,
            isSaving: false
        };
  },
  computed: {  
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    previewOnlyDate(){
        return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
    getReportTypeValue(){
        return (value)=>{
            if (value) {
                return this.reportTypes.find(p => { 
                    return p.id == value;
                }).text; 
            }
        } 
    },
    getPeriodMonthValue(){
        return (value)=>{
            if (value) {
                var period = this.periodList.find(p => { 
                return p.id == value && p.id != '-1';
            }); 
            if(period){
                return period.text;
            }
            else{
                return null;
            }
            }
        } 
    },
    checkIfNewAccountSelected(){
        return this.newAccountSelection == null ? false : true;
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: { 
    getData() {
        this.lists = []; 
        const params = {
            session_id: this.$getUser().session_id,
            period_id: this.form.period_id,
            start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            month: this.form.month,
            list_type: this.form.list_type
        };
        axios.post('/api/VatOperations/GetList', params, {'Content-Type': 'application/json'})
        .then((response) => {  
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.lists = response.data.data; 
                this.summary = response.data.summary; 
            }
            else{
                this.lists = [];
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
        });
    },
    getAccountingYears(){
        this.isAccountingYearLoading = true;
        this.companyAccountingPeriods = [];
        const queryParameter = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/Auth/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.accountingYears = response.data; 
            this.accountingYears.forEach((period) => {
                if(period.id == this.$getSessionStorage('activeYear')){
                    this.form.period_id = period.id;
                    this.form['periodSelection'] = period; 
                    this.form.start_date = moment(this.form['periodSelection'].start_date); 
                    this.form.record_date = moment(this.form['periodSelection'].start_date); 
                } 
            });            
            this.getPeriodMonths();
            setTimeout(() => {
                this.isAccountingYearLoading = false;
            }, 1000); 
        })
        .catch(function (error) {
            this.isAccountingYearLoading = false;
            console.log(error);
        }); 
    },
    getPeriodMonths(){
        this.periodList = [];
        if(this.form.list_type == 1){
            this.$Progress.start(); 
            const queryParameter = {
                session_id: this.$getUser().session_id,
                period_id: this.form.period_id
            };   
            axios.post('/api/BalanceSheet/GetPeriodMonths', queryParameter, {'Content-Type': 'application/json'}) 
            .then((response) => { 
                this.periodList = response.data;
                this.periodList.unshift({ id: -1, text: 'Select an option'});
                this.form.month = -1;
                
                this.getDescriptions();
                this.$Progress.finish();
            })
            .catch(function () {
                this.$Progress.finish();
            });
        } 
    },
    onChangeAccountYear(e){
        this.form['periodSelection'] = e; 
        this.form.end_date = moment(e.end_date);
        this.form.start_date = moment(e.start_date);
        this.form.record_date = moment(e.end_date);

        if (this.form.list_type == 1) {
            this.getPeriodMonths();
        }
    },
    setCriteriasToDefault() {   
        this.lists = [];
        this.newAccountSelection = null;
        this.accountSelection = null;
        this.form = {
            record_date: moment(), 
            period_id: null,
            periodSelection: {},
            list_type: 1,
            start_date: moment(),
            end_date: moment(),
            month: -1,
            description: null,
            vat_calculated_type: -1,
            target_account_id: '-1',
        };
        this.getAccountingYears();
    },
    createVATVoucher(){
        this.$swal.fire({
            title: "You are about to save the VAT Accounting Voucher. Are you sure about this?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "CONFIRM",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.isSaving = true;
                const params = {
                    session_id: this.$getUser().session_id,
                    period_id: this.form.period_id,
                    list_type: this.form.list_type,
                    month: this.form.month,
                    description: this.form.description,
                    record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    target_account_id: this.form.target_account_id,
                    start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                };
                const that = this;
                axios.post('/api/VatOperations/Create', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.$swal("Success", "VAT Accounting Voucher saved successfully..!", 'success');  
                        this.setCriteriasToDefault();
                    }
                    that.isSaving = false;
                    this.$Progress.finish();
                })
                .catch(function (error) {
                    that.isSaving = false;
                    console.log(error); 
                    this.$Progress.fail();
                });
            }
        }); 
    },
    getDescriptions() {
        this.isOpenDescriptionDropdown = false;
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 17, // VAT Operations
            location_detail: 0,
            account_type: 0,
            sales_type: 0
        };
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2) {
                    this.form.description = element.description;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.fail();
        });
    },
    onSelectDescription(item) {
        this.form.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    onChangeVATCalculatedType() {
        if (this.form.vat_calculated_type == -1) {
            this.accountPlanList = [{ id: '-1', text: 'Select an option'}];
            // this.getDescriptions();
            return;
        }
        const queryParameter = {
            session_id: this.$getUser().session_id,
            accounting_period_id: this.form.period_id,            
            list_type: 18,
            list_account_type: this.form.vat_calculated_type
        };  
        axios.post('/api/Helper/GetAccountingPlanByParameter', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {
            this.accountPlanList = response.data;
            this.accountPlanList.forEach((item) => {
                item.text = item.final_code + ' - ' + item.name;
            });
            this.accountPlanList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});
        });

    },
    onChangeMonth(event) {
        if (event.id !== '-1') {
            if (event.text !== null && event.text !== undefined && event.text !== '' ) {
                const endOfMonth  = moment(event.text, 'MM-YYYY').endOf('month').format('YYYY-MM-DD hh:mm');
                this.form.record_date = endOfMonth;
            }
        }
    },
    setSummaries(options){
        if(options.name == "balance"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var balance = parseFloat(options.value.balance.replaceAll(',', ''));
                (options.totalValue += balance);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/VATOperationsHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() {      
    this.helpPageViewIcon();  
    this.temp_session_id = null;
    this.getAccountingYears();
    this.$removeSessionStorage('record_id');
  }
}; 
</script>