<template>
    <div>
        <div class="modal-dialog modal-xxl-90">
            <div class="modal-content border-1">
                <div class="modal-header p-5 bg-primary">
                    <h2 class="text-white">Coverages</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="this.$parent.isViewCoverageModal = false">
                        <span class="svg-icon svg-icon-1 svg-icon-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-7"> 
                    <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="id" 
                            :data-source="coverageList" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true"
                            @row-updating="onRowUpdating"
                            @exporting="this.$onExporting($event, 'coverage-list')"
                            @option-changed="handleEditItemChanged" > 

                            <DxFilterRow :visible="true"/>
                            <DxHeaderFilter :visible="true"/>
                            <DxGroupPanel :visible="true"/>      
                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10"/>
                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                            <DxSearchPanel :visible="true"/>
                            <DxEditing :allow-updating="true" mode="row" /> 
                            <!-- :allow-adding="true" :allow-deleting="true" -->
                            
                            <DxColumn data-field="coverage_main_name" caption="Coverage Main Name" alignment="left" cell-template="coverage_main_name-cell-template">
                                <DxLookup :data-source="coverageMainList" value-expr="id" display-expr="text" ref="coverage_main" v-model="coverageForm.coverage_main_id"/>
                            </DxColumn>
                            <DxColumn data-field="coverage_detail_name" caption="Coverage Detail Name" alignment="left" cell-template="coverage_detail_name-cell-template">
                                <DxLookup :data-source="coverageDetailList" value-expr="id" display-expr="text" v-model="coverageForm.coverage_detail_id" />
                            </DxColumn>
                            <DxColumn data-field="coverage_code" caption="Code" alignment="left" cell-template="coverage_code-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_name" caption="Name" alignment="left" cell-template="coverage_name-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_amount" caption="Amount" alignment="right" cell-template="coverage_amount-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_currency" caption="Currency" alignment="left" cell-template="coverage_currency-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_group_name" caption="Group Name" alignment="left" cell-template="coverage_group_name-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_insurance_commission" caption="Commission" alignment="right" cell-template="coverage_insurance_commission-cell-template" :allowEditing="false" />
                            <DxColumn data-field="coverage_insurance_premium" caption="Premium" alignment="right" cell-template="coverage_insurance_premium-cell-template" :allowEditing="false" />
                            <DxColumn data-field="producer_coverage_name" caption="Producer Coverage Name" alignment="left" cell-template="producer_coverage_name-cell-template" :allowEditing="false"/>

                            <template #coverage_main_name-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_main_name }}
                                </div>
                            </template>
                                <template #coverage_detail_name-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_detail_name }}
                                </div>
                            </template>
                            <template #coverage_code-cell-template="{ data }">
                                <div class="text-gray-900 mb-1"> 
                                    <span>
                                        <span class="me-1">
                                            <i class="fa fa-exclamation-triangle text-warning" v-if="data.data.coverage_code !== data.data.producer_coverage_code" style="font-size: 12px;"></i>
                                            <i class="fa fa-check-circle text-success" v-if="data.data.coverage_code === data.data.producer_coverage_code" style="font-size: 12px;"></i>
                                        </span>
                                        <span>
                                            {{ data.data.coverage_code }}
                                        </span>
                                    </span>
                                </div>
                            </template>
                            <template #coverage_name-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_name }}
                                </div>
                            </template>
                            <template #coverage_amount-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_amount }}
                                </div>
                            </template>
                            <template #coverage_currency-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_currency }}
                                </div>
                            </template>
                            <template #coverage_group_name-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_group_name }}
                                </div>
                            </template>
                            <template #coverage_insurance_commission-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_insurance_commission }}
                                </div>
                            </template>
                            <template #coverage_insurance_premium-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.coverage_insurance_premium }}
                                </div>
                            </template>
                                <template #producer_coverage_name-cell-template="{ data }">
                                <div class="text-gray-900 mb-1">
                                    {{ data.data.producer_coverage_name }}
                                </div>
                            </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxEditing, DxLookup } from 'devextreme-vue/data-grid'; 
export default {
    name:'CoveragesModal',
    data(){
        return {
            activeCoverageTransaction: null,
            coverageList: [],
            coverageForm: {
                supplier_producer_id: null,
                coverage_main_id: '-1',
                coverage_detail_id: '-1'
            },
            coverageMainList: [{ id: '-1', text: 'Select an option'}],
            coverageDetailList: [{ id: '-1', text: 'Select an option'}],
        }
    },
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxEditing, DxLookup
    },
    methods:{
        getData(item){
            this.coverageList = [];
            if(!this.$parent.isViewCoverageModal) return;
            this.coverageForm = {
                coverage_main_id: '-1',
                coverage_detail_id: '-1',
                supplier_producer_id: item.supplier_producer_id,
                policy_transaction_id: item.id
            };

            this.activeCoverageTransaction = item;
            const queryParameter = {
                policy_transaction_id: item.id,
                supplier_producer_id: item.supplier_producer_id,
                session_id: this.$getUser().session_id
            };  
            axios.post('/api/DLLTemplates/GetTempTransactionCoverages', queryParameter, {'Content-Type': 'application/json'}) 
            .then((response) => { 
                this.coverageList = response.data;
                this.getCoverageMainList();
            })
            .catch(function () { 
            });
        },
        handleEditItemChanged(e) {
            if(e.fullName == "editing.changes") {
                if(e.value.length>0) {
                    this.getCoverageDetailList(e.value[0].data.coverage_main_name);
                }
            }
        },
        getCoverageMainList() {
            const parameters = {
                session_id: this.$getUser().session_id,
                get_access_status: 1,
                list_type: 3 // Coverage Main: 3
            };
            const requestOne =  axios.post('/api/Helper/GetPolicyLists', parameters, {'Content-Type': 'application/json'});        
            axios.all([requestOne]).then(axios.spread((...responses) => {
                this.coverageMainList = responses[0].data; 
                this.coverageMainList.unshift({id: '-1', text: 'Select an option'});
            }))
            .catch(errors => { 
                console.error(errors); 
            }); 
        },
        getCoverageDetailList(coverageMainId) {
            const checkIsExist = this.coverageMainList.find(x => x.id == coverageMainId);
            if(checkIsExist == null || checkIsExist == undefined || checkIsExist == '') return;
            const params = {
                session_id: this.$getUser().session_id,
                list_type: 4, // Coverage Detail: 4 
                parent_id: coverageMainId
            };
            axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
                this.coverageDetailList = response.data;
                this.coverageDetailList.unshift({ id: '-1', text: 'Select an option'});
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onRowUpdating(e){
            const parameters = { 
                id: this.coverageForm.policy_transaction_id,
                supplier_producer_id: this.coverageForm.supplier_producer_id,
                coverage_main_id: e.newData.coverage_main_name ? e.newData.coverage_main_name : null,
                coverage_detail_id: e.newData.coverage_detail_name ? e.newData.coverage_detail_name : null,
                coverage_amount: e.oldData.coverage_amount ? e.oldData.coverage_amount : 0,
                coverage_code: e.oldData.coverage_code ? e.oldData.coverage_code : null,
                coverage_currency: e.oldData.coverage_currency ? e.oldData.coverage_currency : null,
                coverage_detail_name: e.oldData.coverage_detail_name ? e.oldData.coverage_detail_name : null,
                coverage_group_name: e.oldData.coverage_group_name ? e.oldData.coverage_group_name : null,
                coverage_insurance_commission: e.oldData.coverage_insurance_commission ? e.oldData.coverage_insurance_commission : 0,
                coverage_insurance_premium: e.oldData.coverage_insurance_premium ? e.oldData.coverage_insurance_premium : 0,
                coverage_main_name: e.oldData.coverage_main_name ? e.oldData.coverage_main_name : null,
                coverage_name: e.oldData.coverage_name ? e.oldData.coverage_name : null,
                producer_coverage_code: e.oldData.producer_coverage_code ? e.oldData.producer_coverage_code : null,
                producer_coverage_id: e.oldData.producer_coverage_id ? e.oldData.producer_coverage_id : null,
                producer_coverage_name: e.oldData.producer_coverage_name ? e.oldData.producer_coverage_name : null,
                session_id: this.$getUser().session_id
            };
            axios.post("/api/DLLTemplates/UpdateCoverageRow", parameters, {'Content-Type': 'application/json'}).then(() => {
                this.getData(this.activeCoverageTransaction);
            })
            .catch(function (error) {
                console.log(error);
            });
        }       
    },
    created() {
        this.coverageList = [];
    }
}
</script>