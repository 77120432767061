<template>
    <div class="modal-dialog modal-xxl-90 p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header py-7 d-flex justify-content-between bg-primary">
                <h2 class="text-white">Print Document</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeContactModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" style="min-height:700px;" v-if="this.isPrintModalOpen" 
                :class="{'overlay overlay-block': this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')}">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="input-group mb-5">
                            <Select2 v-model="designSelection" :options="designList" @select="onChangeReport($event)" :settings="{ width: '300px', dropdownParent: '#kt_modal_print .modal-content' }"/>  
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="alert alert-warning d-flex align-items-center p-2" v-if="designDescription !== '' && designDescription !== null && designDescription !== undefined">
                            <span class="svg-icon svg-icon-muted svg-icon-2x">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                </svg>
                            </span>
                            <div class="d-flex flex-column ms-3">
                                {{designDescription}}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                            <button type="button" class="btn btn-primary" @click="downloadDocument()">
                                <i class="fa fa-file fs-5"></i> Download PDF
                            </button>
                        </div> 
                    </div>
                </div>
                <div class="alert alert-warning d-flex align-items-center" v-if="this.designSelection == '-1' || this.designSelection == null || this.designSelection == '' || this.designSelection == undefined">
                    <span class="svg-icon svg-icon-muted svg-icon-2x">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                        <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                        <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                        </svg>
                    </span>
                    <div class="d-flex flex-column ms-3">
                        No default report template specified!
                    </div>
                </div>
                <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'https://localhost:5001/Home/Viewer?id=' + this.id + '&report_id='+ this.designSelection + '&report_type=' + this.report_type" 
                        style="width:100% !important; height: 100% !important;min-height:700px;"
                        v-if="this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                </iframe>

                <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'/reports/Home/Viewer?id=' + this.id + '&report_id='+ this.designSelection + '&report_type=' + this.report_type" 
                        style="width:100% !important; height: 100% !important;min-height:700px;"
                        v-if="!this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                </iframe>
                
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span class="d-flex text-primary ms-5">Document loading. Please wait...</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import { saveAs } from 'file-saver';
export default {
  name: "PrintModal",
  data() {
    return {
        localCurrency: this.$getUser().currency_code,
        id: this.$getSessionStorage('record_id'),
        designSelection: '-1',
        designDescription: null,
        designList: [{id: '-1', text: 'Select an option'}],
        isPrintModalOpen: false,
        is_local: false,
        report_type: this.$getSessionStorage('report_category'),
        receipt_type: this.$getSessionStorage('receipt_type'),
        isFrameLoading: true,
        documentName: ''
    };
  },
  methods: {
    getData() { 
        if(!this.isPrintModalOpen) return;
        const parameters = {
            session_id: this.$getUser().session_id,
            category: this.$getSessionStorage('report_category'),
            receipt_type: (this.receipt_type !== null && this.receipt_type !== '' && this.receipt_type !== undefined) ? this.$getSessionStorage('receipt_type') : 0,
            view_option: (this.report_type == 2 || this.$parent.form.currency == '-1') ? 0 : ((this.localCurrency == this.$parent.form.currency) ? 1 : 2)
        };
        axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'}).then((response) => { 
            this.designList = response.data.report_designs;
            this.designSelection = !response.data.default_report_design ? '-1' : response.data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.designDescription = item.description;
                    this.report_type = item.category;
                }
            });
            this.designList.unshift({id: '-1', text: 'Select an option'});
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){ this.is_local = true; }
            else{ this.is_local = false; }
            this.saveLogs(1);
        })
        .catch(function (error) {
            console.log(error); 
        });
    },
    saveLogs(viewType) {
        const parameters = {
            log_type: 7,
            log_location: 99,
            session_id: this.$getUser().session_id,
            log_location_detail: null,
            log_operation: viewType == 1 ? 22 : 19,
            document_no: null,
            account_id: null,
            description: this.documentName + ' named report ' + (viewType == 1 ? 'viewed.' : 'downloaded.'),
        };  
        axios.post('/api/GetCompanyAccounts/SaveLogs', parameters, {'Content-Type': 'application/json'});
    },
    onChangeReport(event) {
        this.isFrameLoading = true;
        this.designSelection = event.id;
        this.designDescription = event.description;
        this.report_type = event.category;
    },
    onCloseModal(){
        this.isPrintModalOpen = false;
    },
    iframeloadhelper() {
      this.isFrameLoading = false;
    },
    downloadDocument(){        
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        {
            fetch('https://localhost:5001/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.report_type)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.documentName + '.pdf');
                    this.saveLogs(2);
                });
        }
        else{
            fetch('/reports/Home/Downloader?id=' + this.$getSessionStorage('record_id') + '&report_id='+ this.designSelection + "&report_type=" + this.report_type)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.documentName + '.pdf');
                    this.saveLogs(2);
                });
        } 
    },    
  },
  created() {
    this.getData();
  }
};
</script>