<template>
    <div class="modal-dialog modal-xxl p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header py-7 d-flex justify-content-between bg-primary py-3">
                <h2 class="text-white">Create New Account</h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeContactModal">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y m-3" :class="{'overlay overlay-block': this.isAccountSaving == true}">
                <div class="stepper stepper-links d-flex flex-column" data-kt-stepper="true">
                    <form id="poliformModal" @submit.prevent=""  class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                        <div class="current" data-kt-stepper-element="content" v-if="this.activeStep == 1">
                            <div class="w-100">
                                <div class="d-flex flex-stack bg-light p-5">
                                    <div class="row">
                                        <label class="fs-6 fw-semibold fw-bold mb-1 required">Account Type</label>
                                        <div class="fs-8 fw-semibold text-muted">Please select contact type to create an Account</div>
                                    </div>
                                    <label class="fv-row w-100">
                                        <Select2 v-model="createContactForm.account_type" :options="accountTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }" :disabled="isDisabledAccountType"/> 
                                    </label>
                                </div>
                                <div class="separator separator-dashed my-7"></div>
                                <div class="fv-row row">
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-flex flex-row row">
                                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row mb-7 row">
                                                                <div class="col-lg-12" v-if="this.createContactForm.isViewAccountId == true">
                                                                    <label class="fs-6 fw-bold form-label mt-3" :class="{'required': this.createContactForm.isViewAccountId == true}">
                                                                        Account ID
                                                                    </label>
                                                                    <input type="text" class="form-control" name="accountID" v-custom v-model="createContactForm.account_id">
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'"> Name / Company Name
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control"
                                                                            name="name" v-custom v-model="createContactForm.name">
                                                                        <button v-if="createContactForm.company_account_type == 1 && this.$isCredentialActive(10,'CI') && userData.is_company_inquiry_active == 1" 
                                                                            type="button" class="input-group-text m-0 text-hover-success cursor-pointer"
                                                                            @click="onSearchCompanyByName()" :disabled="createContactForm.name == null || createContactForm.name == ''">
                                                                            <i class="fas fa-search fs-4 me-3"></i> Search by Name
                                                                        </button>
                                                                        <button v-if="createContactForm.company_account_type == 2 && this.$isCredentialActive(11,'CI') && userData.is_company_inquiry_active == 1" 
                                                                            type="button" class="input-group-text m-0 text-hover-success cursor-pointer"
                                                                            @click="onSearchCompanyByName()" :disabled="createContactForm.name == null || createContactForm.name == ''">
                                                                            <i class="fas fa-search fs-4 me-3"></i> Search by Name
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'member_type'">
                                                                            <span>Member Type</span>
                                                                        </label> 
                                                                        <Select2 v-custom v-model="createContactForm.company_account_type" :options="companyAccountTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content'}" @select="onChangeMemberType()" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" v-if="createContactForm.company_account_type == 1">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'registration_number'">
                                                                        Registration Number
                                                                    </label>
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control" name="registration_number" v-custom v-model="createContactForm.account_number">
                                                                        <button v-if="createContactForm.company_account_type == 1 && this.$isCredentialActive(10,'CI') && userData.is_company_inquiry_active == 1" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                                            :disabled="createContactForm.account_number == null || createContactForm.account_number == ''">
                                                                            <i class="fas fa-search fs-4 me-3"></i> Search by No
                                                                        </button>
                                                                        <button v-if="createContactForm.company_account_type == 2 && this.$isCredentialActive(11,'CI') && userData.is_company_inquiry_active == 1" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                                            :disabled="createContactForm.account_number == null || createContactForm.account_number == ''">
                                                                            <i class="fas fa-search fs-4 me-3"></i> Search by No
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">Account Name
                                                                    </label>
                                                                    <input type="text" class="form-control" name="contact_name" v-custom v-model="createContactForm.contact_name">
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="d-flex flex-column flex-row-auto col-lg-6">
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row mb-6 row">
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row fv-plugins-icon-container">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">Email
                                                                        </label>
                                                                        <input type="email" class="form-control" name="email" v-custom v-model="createContactForm.email" :class="{'border border-danger': isValidEmail == false}">
                                                                        <small class="text-danger" v-if="!isValidEmail && createContactForm.email !== null && createContactForm.email !== '' ">Email is not valid</small>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">Mobile Phone
                                                                        </label>
                                                                        <div class="input-group">
                                                                            <Select2 v-custom v-model="createContactForm.phone_country_code" :options="phoneMaskList" :settings="{width: '120px', dropdownParent: '#kt_modal_add_contact .modal-content'}"/>
                                                                            <input type="text" class="form-control" name="mobile_phone" v-custom v-model="createContactForm.mobile_phone">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                                            <span>Phone</span>
                                                                        </label>
                                                                        <div class="input-group">
                                                                            <Select2 v-custom v-model="createContactForm.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px', dropdownParent: '#kt_modal_add_contact .modal-content'}"/>
                                                                            <input type="text" class="form-control" name="phone" v-custom v-model="createContactForm.phone">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12"  v-if="createContactForm.account_type == 1">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'customer_type'">
                                                                            <span>Customer/Supplier Type</span>
                                                                        </label>
                                                                        <Select2 v-custom v-model="createContactForm.customer_type_id" name="customer_type" :options="customerTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }" />  
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" v-if="createContactForm.account_type == 2">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'supplier_type'">
                                                                            <span>Supplier Type</span>
                                                                        </label>
                                                                        <Select2 v-custom v-model="createContactForm.customer_type_id" name="supplier_type" :options="customerTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }" />  
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                                            <span>Sales Channel</span>
                                                                        </label>
                                                                        <Select2 v-custom v-model="createContactForm.sales_channel_id" name="customer_type" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }"/> 
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'payment_value'">
                                                                            <span>Payment Value</span> 
                                                                            <small class="fs-8"> (Day) </small>
                                                                        </label>
                                                                        <input type="text" class="form-control" name="payment_value" v-custom v-model="createContactForm.payment_value">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>                   
                                </div>
                            </div>
                        </div>
                        <div  v-if="this.activeStep == 2">
                            <div class="w-100">
                                <div class="fv-row row">                                            
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-flex flex-row row">
                                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row mb-6 row">
                                                                <GetAddress @address-selected="onSelectAddress" :account-type="createContactForm.company_account_type" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(createContactForm.company_account_type == 1 ? 10 : 11,'AI')"/>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">Post Code</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control" name="postcode" v-model="createContactForm.postcode" v-custom>
                                                                            <button v-if="createContactForm.company_account_type == 1 && this.$isCredentialActive(10,'AI') && userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                                id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByPostCode()"
                                                                                :disabled="this.createContactForm.postcode == null || this.createContactForm.postcode == ''">
                                                                                <span v-if="!isSearchingByPostCode"><i class="fas fa-search fs-4 me-3"></i></span>
                                                                                <span v-if="isSearchingByPostCode">{{ $t('Searching...') }}</span> 
                                                                            </button>
                                                                            <button v-if="createContactForm.company_account_type == 2 && this.$isCredentialActive(11,'AI') && userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                                id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByPostCode()"
                                                                                :disabled="this.createContactForm.postcode == null || this.createContactForm.postcode == ''">
                                                                                <span v-if="!isSearchingByPostCode"><i class="fas fa-search fs-4 me-3"></i></span>
                                                                                <span v-if="isSearchingByPostCode">{{ $t('Searching...') }}</span> 
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control" name="postcode" v-model="createContactForm.uprn" v-custom>
                                                                            <button v-if="createContactForm.company_account_type == 1 && this.$isCredentialActive(10,'AI') && userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                                id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByAddressCode()"
                                                                                :disabled="this.createContactForm.uprn == null || this.createContactForm.uprn == ''">
                                                                                <span v-if="!isSearchingByAddressCode"><i class="fas fa-search fs-4 me-3"></i></span>
                                                                                <span v-if="isSearchingByAddressCode">{{ $t('Searching...') }}</span> 
                                                                            </button>
                                                                            <button v-if="createContactForm.company_account_type == 2 && this.$isCredentialActive(11,'AI') && userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                                id="basic-addon2" data-kt-indicator="on" @click="this.onFindAddressByAddressCode()"
                                                                                :disabled="this.createContactForm.uprn == null || this.createContactForm.uprn == ''">
                                                                                <span v-if="!isSearchingByAddressCode"><i class="fas fa-search fs-4 me-3"></i></span>
                                                                                <span v-if="isSearchingByAddressCode">{{ $t('Searching...') }}</span> 
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">{{ $t('Select Address') }}</label>
                                                                        <Select2 v-model="createContactForm.address_id" :options="addressList" name="address" v-custom @select="onSelectAddressSearchResult($event)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }" :disabled="addressList.length == 0"/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">Address</label>
                                                                        <textarea class="form-control" name="address" v-custom v-model="createContactForm.address"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">City
                                                                        </label>
                                                                        <input type="text" class="form-control" name="city" v-custom v-model="createContactForm.city">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">District                                                    
                                                                        </label>
                                                                        <input type="text" class="form-control" name="district" v-custom v-model="createContactForm.district">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                                            Country 
                                                                        </label>
                                                                        <Select2 v-custom v-model="createContactForm.country" name="country" :options="countryList" @select="onChangeCountry($event)" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_contact .modal-content' }"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                                <div class="d-flex flex-column-fluid card border border-solid border-gray-300 border-gray-300 card-xxl-stretch mb-5 mb-xxl-10 bg-light">
                                                    <div class="card-body pb-0">
                                                        <div class="row">
                                                            <div class="fv-row row">
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row mb-7">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'is_vat_account'">VAT Account?</label> 
                                                                        <br>
                                                                        <div class="form-check form-check-inline mt-5">
                                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio1" value="false"
                                                                                v-custom v-model="createContactForm.isVATNumberExist">
                                                                            <label class="form-check-label" for="inlineRadio1">No</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input" type="radio" name="is_vat_account" id="inlineRadio2" value="true"
                                                                            v-custom v-model="createContactForm.isVATNumberExist">
                                                                            <label class="form-check-label" for="inlineRadio2">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'vat_number'" :class="{'required' : this.createContactForm.isVATNumberExist == 'true'}">
                                                                            VAT Number                                                                               
                                                                        </label>
                                                                        <input type="text" class="form-control" name="vat_number" v-custom v-model="createContactForm.vat_number" :disabled="createContactForm.isVATNumberExist == 'false'">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="fv-row">
                                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                                            <span>Notes</span>
                                                                        </label>
                                                                        <textarea rows="3" class="form-control" name="notes" v-custom v-model="createContactForm.notes"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-stack">
                            <div class="me-2">
                                <button type="button" class="btn btn-lg btn-light-primary me-3" v-if="this.activeStep !== 1" @click="onBackStep()">
                                    <span class="svg-icon svg-icon-3 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
                                        </svg>
                                    </span> Back
                                </button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-lg btn-primary" v-if="this.activeStep == 1" @click="onNextStep()" :disabled="isValidEmail == false">Continue 
                                    <span class="svg-icon svg-icon-3 ms-1 me-0">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </button>
                                <button type="button" class="btn btn-lg btn-block btn-success" v-if="this.activeStep == 2" @click="createContactAccount()">
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    Save Account
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        
        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div v-if="isViewSearchCompanyModal" class="modal fade show" id="kt_modal_search_company" style="display: block;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">
                            Search Result
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary" @click="isViewSearchCompanyModal = false"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                              </svg>
                          </span>
                        <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15 form-control-sm" placeholder="Search in Results" v-model="searchBar" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0"/>
                        <div class="progress" v-if="this.isCompanySearchInProgress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                Getting Company List. Please wait...
                            </div>
                        </div>
                        <h5 v-if="this.companySearchResult.length == 0 && !this.isCompanySearchInProgress">
                            No result found.
                        </h5>
                        <table class="table table-row-dashed text-gray-800 table-row-gray-300 table-hover" v-show="!this.isCompanySearchInProgress && !this.companySearchResult.length == 0">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Registration Number</th>
                                    <th>Date of Creation</th>
                                    <th>Address</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item of searchableList" v-bind:key="item">
                                    <td>
                                        <strong>{{ item.title }}</strong> <br>
                                        <small>{{ item.description }}</small>
                                    </td>
                                    <td>{{ item.companyNumber }}</td> 
                                    <td>{{ previewDate(item.dateOfCreation) }}</td>
                                    <td> 
                                        <small>
                                            {{ item.address.addressLine1 }} {{ item.address.postalCode }}<br>
                                            {{ item.address.locality }} / {{ item.address.country }}
                                        </small>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-sm btn-primary" @click="onSelectSearchResult(item.companyNumber)">Select</button>
                                    </td>
                                </tr> 
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import moment from 'moment';
export default {
  name: "AddNewContact",
  data() {
    return {
        userData: this.$getUser(),
        companyAccountTypeList: [
            {id: 1, text: 'Company'},
            {id: 2, text: 'Person'},
        ],
        accountTypeList: [
            { id: 1, text: 'Customer Account' },
            { id: 2, text: 'Supplier Account' },
        ],
        salesChannelList: [],
        customerTypeList: [], 
        countryList: [],
        cityList: [],
        phoneMaskList: [],
        createContactForm: {
            company_account_type: 2,
            account_type: 1,
            id: null,
            name: null,
            company_type_id: null,
            account_id: null,
            qr_code: null,
            notes: null, 
            account_number: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            address_id: '-1',
            country: '-1',
            city: null, 
            vat_number: null,
            payment_value: null,
            isVATNumberExist: 'false',
            accounting_plan: null,
            customer_type_id: '-1',
            sales_channel_id: '-1',
            isViewAccountId: false,
            phone_country_code: null,
            land_phone_country_code: null,
            district: null,
            x_coordinate: 0,
            y_coordinate: 0,
            uprn: null,
            udprn: null
        },
        addressList: [{id: '-1', text: 'Select an address from the list after the postcode query'}],
        isAccountSaving: false,
        activeStep: 1,
        companySearchResult: [],
        searchBar: '',
        isCompanySearchInProgress: false,
        isViewSearchCompanyModal: false,
        isDisabledAccountType: false,
        isSearchingByPostCode: false,
        isSearchingByAddressCode: false,
    };
  },
  computed: {
		isValidEmail() {
			return (this.createContactForm.email == null || this.createContactForm.email == '' || this.createContactForm.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.createContactForm.email);
		},
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        searchableList() {
            return this.companySearchResult.filter(p => { 
                return p.title.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1 || 
                p.companyNumber.toLowerCase().indexOf(this.searchBar.toLowerCase()) != -1
            });
        }
	},
  methods: {
    getData() {
        this.clearForm();
        this.$Progress.start();
        this.activeStep = 1;
        this.isAccountSaving = false;
        this.addressList = [];
        this.addressList.unshift({ id: '-1', text: 'Select an address from the list after the postcode query'});
        
        if(this.$getUser().company_country_code !== null && this.$getUser().company_country_code !== '' && this.$getUser().company_country_code !== undefined) {
            this.createContactForm.country = this.$getUser().company_country_code;
        }

        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["account_setting", "sales_channel", "country"],
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            account_type: 1 // yalnızca account settings listesi çekilecek ise gerekli
        }; 
        axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
            this.salesChannelList = response.data.sales_channel;
            this.countryList = response.data.country;
            if (response.data.account_setting.is_default == false) {
                this.createContactForm.isViewAccountId = true;
            }
            this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
            this.countryList.unshift({ id: '-1', text: 'Select an option'});
            this.salesChannelList.forEach((value) => {
                if(value.is_default){
                    this.createContactForm.sales_channel_id = value.id;
                }
            });

            const parameters = {
                session_id: this.$getUser().session_id,
                list_type: 14, // Customer Type: 14
                get_access_status: 1
            };  
            
            const requestOne =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.customerTypeList = responses[0].data;
                this.customerTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.customerTypeList.forEach((value) => {
                    if(value.is_default) {
                        this.createContactForm.customer_type_id = value.id;
                    }
                });
                this.phoneMaskList = responses[1].data; 
                this.phoneMaskList.forEach(element => {
                    if(element.code == this.$getUser().company_country_code) {
                        this.createContactForm.phone_country_code = element.id;
                        this.createContactForm.land_phone_country_code = element.id;
                    }
                });
                this.$Progress.finish();   
            }))
            .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish();
            });
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    createContactAccount() {
        this.$checkSelect2Validation('poliformModal');
        if(!document.forms['poliformModal'].reportValidity()){
            return;
        }
        this.isAccountSaving = true;
        const params = this.createContactForm;    
        const that = this;
        params.session_id = this.$getUser().session_id;
        if(params.country == '-1'){ params.country = null }
        if(params.sales_channel_id == '-1'){ params.sales_channel_id = null }
        if(params.customer_type_id == '-1'){ params.customer_type_id = null }
        axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.createContactForm.id = response.data;
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$refs.closeContactModal.click();
                this.$parent.onSuccessNewAccount(this.createContactForm.id);
            }
            this.isAccountSaving = false;
        })
        .catch(function () { 
            this.isAccountSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isAccountSaving = false;
            }, 500);
        }); 
    },
    onNextStep() {
        this.$checkSelect2Validation('poliformModal');
        if(!document.forms['poliformModal'].reportValidity()){
            return;
        }
        this.activeStep++;
    },
    onBackStep() {
      this.activeStep--;
    },    
    onChangeCountry(event) {
        this.createContactForm.phone_country_code = event.phone_country_code;
        this.createContactForm.land_phone_country_code = event.phone_country_code;
    },
    clearForm() {
        this.createContactForm = {
            company_account_type: 2,
            account_type: 1,
            id: null,
            name: null,
            company_type_id: null,
            account_id: null,
            qr_code: null,
            notes: null, 
            account_number: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            address_id: '-1',
            country: '-1',
            city: null, 
            vat_number: null,
            payment_value: null,
            isVATNumberExist: 'false',
            accounting_plan: null,
            customer_type_id: '-1',
            sales_channel_id: '-1',
            isViewAccountId: false,
            phone_country_code: null,
            land_phone_country_code: null,
            isRegistrationNumberRequired: 0,
            company_member_type: 0,
            district: null,
            x_coordinate: 0,
            y_coordinate: 0,
            uprn: null,
            udprn: null
        };        
        this.companySearchResult = [];
        this.searchBar = '';
        this.isCompanySearchInProgress = false;
        
        const params = {
            route: 'CustomerCreate',
            session_id: this.$getUser().session_id
        };
        axios.post('api/FormValidations/GetFormFields', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(response.data));
            }
        })
        .catch(function () { 
            console.error('No fields found..!');
        });
    },
    onChangeMemberType() {
        if(this.createContactForm.company_account_type == 2) {
            this.createContactForm.account_number = null;
        }
    },
    onSearchCompanyByNo(){
        const parameters = {
            CompanyNumber: this.createContactForm.account_number,
            SessionId: this.$getUser().session_id,
            SourceType: this.createContactForm.account_type
        }
        const that = this;
        axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.createContactForm.name = response.data.name;
                this.createContactForm.account_number = response.data.number;
                this.createContactForm.contact_name = response.data.contactName;
                this.createContactForm.postcode = response.data.postalCode;
                this.createContactForm.address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                //this.createContactForm.country = response.data.country; Çıktı England şeklinde?
                this.createContactForm.city = response.data.locality;
                this.companySearchResult = [];
                
                if(this.createContactForm.account_number !== null && this.createContactForm.account_number !== '' && this.createContactForm.account_number !== undefined) {
                    this.createContactForm.company_account_type = 1;
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            that.$swal("Error", "No records found..!", 'error');
        });
    },
    onSearchCompanyByName(){
        this.isViewSearchCompanyModal = true;
        this.isCompanySearchInProgress = true;
        this.companySearchResult = [];
        this.searchBar = '';
        const parameters = {
            CompanyName: this.createContactForm.name,
            SessionId: this.$getUser().session_id,
            SourceType: this.createContactForm.account_type
        }
        axios.post('api/GetCompanyAccounts/SearchByName', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.companySearchResult = response.data;
                this.isCompanySearchInProgress = false;
            } else {
                setTimeout(() => {
                    this.$refs.closeModal.click(); 
                }, 500);
            }
        });
    },
    onSelectSearchResult(selectedCompanyNumber){
        this.createContactForm.account_number = selectedCompanyNumber;
        this.onSearchCompanyByNo();
        this.$refs.closeModal.click(); 
    },
    onFindAddressByPostCode(){
        this.isSearchingByPostCode = true;
        this.createContactForm.address_id = '-1';
        this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
        const that = this;
        const parameters = {
            PostCode: this.createContactForm.postcode,
            SessionId: this.$getUser().session_id,
            SourceType: this.createContactForm.account_type
        };
        axios.post('api/GetCompanyAccounts/FindAddressByPostCode', parameters, {'Content-Type': 'application/json'})
        .then(function(response) {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                if(response.data.results) {
                    response.data.results.forEach(value => {
                        value.DPA.id = value.DPA.UPRN;
                        value.DPA.text = value.DPA.ADDRESS;
                        that.addressList.push(value.DPA);
                    });
                }
            }
        })
        .finally(function () { 
            that.isSearchingByPostCode = false;
        });  
    },
    onFindAddressByAddressCode(){
        this.isSearchingByAddressCode = true;
        this.createContactForm.address_id = '-1';
        this.addressList = [{id: '-1', text: 'Select an address from the list after the postcode query'}];
        const that = this;
        const parameters = {
            PostCode: this.createContactForm.uprn,
            SessionId: this.$getUser().session_id,
            SourceType: this.createContactForm.account_type
        };
        axios.post('api/GetCompanyAccounts/FindAddressByAddressCode', parameters, {'Content-Type': 'application/json'})
        .then(function(response) {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                if(response.data.results) {
                    response.data.results.forEach(value => {
                        value.DPA.id = value.DPA.UPRN;
                        value.DPA.text = value.DPA.ADDRESS;
                        that.addressList.push(value.DPA);
                    });
                }
            }
        })
        .finally(function () { 
            that.isSearchingByAddressCode = false;
        });  
    },
    onSelectAddressSearchResult(item){
        this.createContactForm.address = item.ADDRESS;
        this.createContactForm.city = item.POST_TOWN;
        this.createContactForm.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.createContactForm.x_coordinate = item.X_COORDINATE,
        this.createContactForm.y_coordinate = item.Y_COORDINATE,
        this.createContactForm.uprn = item.UPRN,
        this.createContactForm.udprn = item.UDPRN,
        this.createContactForm.postcode = item.POSTCODE;
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.createContactForm.address = item.fullAddress;
        this.createContactForm.city = item.town_or_city;
        this.createContactForm.district = item.district;
        this.createContactForm.postcode = item.postcode;
    }
  }
};
</script>