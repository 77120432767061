<template>
  <div>
    <div v-if="this.tokenStatus == 0 || (user_mail !== null && user_mail !== undefined && user_mail !== '')" class="d-flex flex-column flex-column-fluid flex-lg-row">
      <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div class="d-flex flex-center flex-lg-start flex-column text-start">
          <a href="/" class="mb-7">
            <img alt="Logo" src="assets/theme/media/logos/logo-cc-white.png">
          </a>
          <h2 class="text-white fw-normal m-0 ms-4">{{ $t('Branding tools designed for your business') }}</h2>
        </div>
      </div>
      <div class="d-flex flex-center w-lg-50 p-10">
        <div class="card rounded-3 w-md-550px pt-15 pb-15 mt-20 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow bg-abstract-5">
          <div class="card-body p-20 p-lg-20">
            <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_new_password_form">
              <div class="text-center mb-10" v-if="user_mail == null || user_mail == undefined || user_mail == ''">
                  <h1 class="text-gray-800 fw-bolder mb-3">
                      Hi {{this.userName}}
                  </h1>
                  <div class="text-gray-500 fw-semibold fs-6 mb-7"> {{ $t('Enter a new password below to change your password.') }} </div>
                  <div class="text-gray-500 fw-semibold fs-7">  {{ $t('Create a password 8-16 characters long, including uppercase, lowercase and special characters.') }} </div>
              </div>
              <div class="text-center mb-10" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">
                  <h1 class="text-gray-800 fw-bolder mb-3">
                    {{ $t('Password expired!') }}
                  </h1>
                  <div class="text-gray-500 fw-semibold fs-6">
                    {{ $t('Your password has expired and must be changed. You will not be able to access the system until your password is changed. The user will not be able to access the system until the account is verified.') }}
                    <br>
                    <router-link to="/Login">
                      <a href="#" class="link-primary fw-bold">
                        {{ $t('Back to Login') }}
                      </a>
                    </router-link>
                    <router-link to="/ForgotPassword">
                      <a href="#" class="link-primary fw-bold ms-5">
                        {{ $t('Forgot Password?') }}
                      </a>
                    </router-link>
                  </div>
              </div>
              <div class="fv-row mb-8 fv-plugins-icon-container" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">    
                <div class="position-relative"> 
                <input id="ps_password" type="password" placeholder="Old Password" name="old-password" v-model="form.old_password" maxlength="16" autocomplete="off" class="form-control">
                <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility('ps_password')">
                        <i v-if="oldIsHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!oldIsHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                </span>
                </div>
                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <div class="fv-row mb-8 fv-plugins-icon-container">
                <div class="mb-1">
                  <div class="position-relative">    
                    <input id="pw_password" class="form-control" type="password" v-model="form.password" maxlength="16" placeholder="Password" name="password" autocomplete="off"
                    :class="{'border border-success': isPasswordConfirm == true}">
                    <!--begin::Visibility toggle-->
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" @click="togglePasswordVisibility('pw_password')">
                        <i v-if="isHide" class="fa fa-eye-slash fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                        <i v-if="!isHide" class="fa fa-eye fs-2 mb-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    </span>
                    <!--end::Visibility toggle-->
                    <password-meter class="mb-3" @score="onScore" :password="form.password"/>
                  </div>
                </div>
                <div class="d-flex flex-stack mb-3 ms-1">
                  <div class="text-gray-500 fw-semibold">
                    {{ $t('Including 3 of the following:') }}
                  </div>
                  <div class="text-gray-500 fw-semibold">
                    {{ $t('Must Contain:') }}
                  </div>
                </div>
                <div class="d-flex flex-stack mb-3 ms-1">
                  <div class="text-muted">
                    <span class="me-3"><i class="fas" :class="isHasUppercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> ABC</span>
                    <span class="me-3"><i class="fas" :class="isHasLowercase ? 'fa-check text-success' : 'fa-times text-danger'"></i> abc</span>
                    <span class="me-3"><i class="fas" :class="isHasNumber ? 'fa-check text-success' : 'fa-times text-danger'"></i> 123</span>
                    <span class="me-3"><i class="fas" :class="isHasSpecial ? 'fa-check text-success' : 'fa-times text-danger'"></i> @#$</span>
                  </div>
                  <div class="text-muted">
                    <span> <i class="fas" :class="form.password.length > 8 ? 'fa-check text-success' : 'fa-times text-danger'"></i> 8-16 Chars </span>
                  </div>
                </div>
              </div>
            
              <div class="fv-row mb-8 fv-plugins-icon-container">    
                <input type="password" placeholder="Repeat Password" name="confirm-password" v-model="form.password_confirm" maxlength="16" autocomplete="off" class="form-control"  @paste.prevent
                  :class="{'border border-success': isPasswordConfirm == true, 'border border-danger': isPasswordConfirm == false}"> 
                <small class="text-danger" v-if="isPasswordConfirm == false">
                  {{ $t('The password and its confirm are not the same') }}
                </small>
                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <div class="d-grid mb-10"  v-if="user_mail == null || user_mail == undefined || user_mail == ''">
                <button type="button" data-kt-indicator="on"  id="kt_new_password_submit" class="btn btn-primary" @click="onBeforeSubmit('reset')" :disabled="passwordScore < 3 || isPasswordConfirm !== true">
                  <span v-if="!this.isProggressing">{{ $t('Submit') }}</span>
                  <span class="indicator-progress" v-if="this.isProggressing">
                    {{ $t('Please wait') }}...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
              <div class="d-grid mb-10" v-if="user_mail !== null && user_mail !== undefined && user_mail !== ''">
                <button type="button" data-kt-indicator="on"  id="kt_new_password_submit" class="btn btn-primary" @click="onBeforeSubmit('change')" :disabled="passwordScore < 3 || isPasswordConfirm !== true || (this.form.old_password == '' || this.form.old_password == null)">
                  <span v-if="!this.isProggressing">{{ $t('Submit') }}</span>
                  <span class="indicator-progress" v-if="this.isProggressing">
                    {{ $t('Please wait') }}...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 

    <div v-if="this.tokenStatus > 0 && (user_mail == null || user_mail == undefined || user_mail == '')" class="d-flex flex-column flex-center text-center p-10">        
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2hx text-gray-900 mb-4">
            <span v-if="tokenStatus == 1">{{ $t('URL is invalid!') }}</span>  
            <span v-if="tokenStatus == 2">{{ $t('Token expired!') }}</span>  
          </h1>
          <div class="fw-semibold fs-6 text-gray-500 mb-8">
            To send a new link, go to the
            <router-link to="/ForgotPassword">
              <a href="#" class="link-primary fw-bold">
                {{ $t('Forgot Password') }}
              </a>
            </router-link> page.
          </div>
          <div class="mb-10">
            <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18.png" class="mw-100 mh-200px theme-light-show" alt="">
            <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5.png" class="mw-100 mh-200px theme-light-show" alt="">

            <img v-if="tokenStatus == 1" src="assets/theme/media/illustrations/unitedpalms-1/18-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
            <img v-if="tokenStatus == 2" src="assets/theme/media/illustrations/unitedpalms-1/5-dark.png" class="mw-100 mh-200px theme-dark-show" alt="">
          </div>
          <div class="mb-10 fw-semibold fs-6 text-gray-500">
            <router-link to="/Login">
              <a href="#" class="btn btn-primary fw-bold">
                {{ $t('Back to Login') }}
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PasswordMeter from "../scripts/passwordMeter.js";
import { load } from 'recaptcha-v3';

export default {
  name: "ResetPassword",
  components: {
    PasswordMeter
  },
  data() {
    return {
      form: {
        password: "",
        password_confirm: "",
        old_password: ""
      },
      tokenStatus: -1,
      passwordScore: 0,
      isHide: true,
      oldIsHide: true,
      user_mail: this.$route.params.user_mail,
      isVerifyRecaptcha: false,
      rc_sk: '',
      userName: null,
      isProggressing: false
    };
  },
  computed: {
    isPasswordConfirm(){
      if(this.form.password !== null && this.form.password !== '') {
        if(this.form.password == this.form.password_confirm) return true;
        else return false;
      }
      else return null;
    },
    isHasNumber() {
      return /(.*[0-9].*)/g.test(this.form.password);
    },
    isHasLowercase() {
      return /(.*[a-z].*)/g.test(this.form.password);
    },
    isHasUppercase() {
      return /(.*[A-Z].*)/g.test(this.form.password);
    },
    isHasSpecial() {
      return /[^A-Za-z0-9]/g.test(this.form.password);
    }
  },
  methods: {
    getData(){
      document.body.style.backgroundImage = "url('/assets/theme/media/auth/bg7-dark.jpg')";
      document.body.classList.add('app-blank');
      document.body.classList.add('bgi-size-cover');
      document.body.classList.add('bgi-position-center');
      document.body.classList.add('bgi-no-repeat');

      if(this.$route.params.user_mail == null || this.$route.params.user_mail == undefined || this.$route.params.user_mail == '') {
        if(!this.$route.query.code) {return this.$router.push({ name: 'Login' });}

        const parameters = {
          code: this.$route.query.code,
          type: 1
        };  
        axios.post('/api/Auth/CheckTokenExpired', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.tokenStatus = response.data.tokenStatus;
            this.userName = response.data.name_surname;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      this.getUserParameters();
    },
    getUserParameters() {
      axios.get('/api/GeneralUserParameters/GetRc').then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.isVerifyRecaptcha = response.data.is_view_rc_rp == 1 ? true : false;
            this.rc_sk = this.$unlock(response.data.sk);
            this.loadReCaptcha();
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    async loadReCaptcha() {
			if(this.rc_sk !== null && this.rc_sk !== '' && this.rc_sk !== undefined) {
        const recaptcha = await load(this.rc_sk);
        if(!this.isVerifyRecaptcha) recaptcha.hideBadge();
        else recaptcha.showBadge();
      }
    },
    async onBeforeSubmit(submitType) {
      if(this.isVerifyRecaptcha) {
        const recaptcha = await load(this.rc_sk);
        const token = await recaptcha.execute('login');
        if(submitType == 'reset') { this.onResetPassword(token, true); }
        else this.onChangePassword(token, true);
      } else {
        if(submitType == 'reset') { this.onResetPassword(); }
        else this.onChangePassword();
      }
    },
    onResetPassword(token, is_recaptcha_active) {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        this.isProggressing = true;
        const parameters = {
          code: this.$route.query.code,
          user_password: this.form.password,
          type: 1,
          tk: token,
          ira: is_recaptcha_active
        };
        const that = this;
        axios.post('/api/Auth/ResetPassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password changed!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProggressing = false; 
          }, 500);
        });
      }
    },
    onChangePassword(token, is_recaptcha_active) {
      if(this.form.password == null || this.form.password == '') return;
      if(this.form.password !== this.form.password_confirm) {
        this.$swal("Failed", "The passwords you entered do not match..!", 'error');
        return;
      }
      else if(this.passwordScore < 3) {
        this.$swal("Failed", "Please specify a strong password..!", 'error');
        return;
      }
      else {
        this.isProggressing = true;
        const parameters = {
          user_mail: this.$route.params.user_mail,
          user_password: this.form.password,
          old_password: this.form.old_password,
          tk: token,
          ira: is_recaptcha_active
        };  
        const that = this;
        axios.post('/api/Auth/ChangePassword', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Password changed!", 'success');
            this.$router.push({ name: 'Login' });
          }
        })
        .catch(function (error) {
           console.log(error);
        })
        .finally(function () { 
          setTimeout(() => {
            that.isProggressing = false; 
          }, 500);
        });
      }
    },
    onScore(pwScore) {
      this.passwordScore = pwScore.score;
    },
    togglePasswordVisibility(id) {
      if(id == 'ps_password') {        
        this.oldIsHide = !this.oldIsHide;
      } else {
        this.isHide = !this.isHide;
      }
      var x = document.getElementById(id);
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    }
  },
  created() {
     this.getData();
  }
};
</script>
<style scoped>
body{
  background-image: url('/assets/theme/media/auth/bg7-dark.jpg');
}

@media (min-width: 992px){
.flex-lg-start {
    justify-content: start;
    align-items: start;
}}
</style>