<template>
    <div>
        <div id="kt_header" class="container-header" data-kt-sticky="true" data-kt-sticky-name="header" style="min-height: 50px; background-color: #c7c7c7;">
            <div class="d-flex align-items-center me-5">
                <div class="  d-flex flex-column me-3 mt-3 p-5"> 
                    <h1 class="d-flex text-gray fw-bolder fs-3">Help Page
                    </h1>
                </div> 
            </div>
        </div>
      <div class="separator my-3 mt-0 border-gray-300"></div>
      <div class="content flex-row-fluid mt-4 p-5" id="help_page">
        <div class="row" v-for="group in groupList" v-bind:key="group">
            <div class="d-flex flex-column flex-row-auto" v-for="rd in group.items" v-bind:key="rd" :class="[rd.pages_content_width]">
                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6"  v-if="rd.pages_content_is_active != 0" >
                    <div class="card-body pt-3 pb-3" > 
                        <div class="row">
                            <div v-html="rd.pages_content_content" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
      </div>   
    </div>  
</template>
  
<script> 
import axios from 'axios'

  export default {
    name: "HelpPage",
    data() {
      return {
          lists: [],
          searchbar: '',
          menu_selection: '',
          pagesAllLists:[],
          groupList :[],
          pageUrl :  window.location.pathname
      };
    },
    methods: {
      getData(){
        this.$Progress.start();
        const parameters = {
          session_id : this.$getUser().session_id,
          menu_selection : this.pageUrl
        };  
        axios.post('api/HelpPage/PagesAllList', parameters, {'Content-Type': 'application/json'})
          .then((response) => { 
                this.pagesAllLists = response.data;
                this.pagesAllLists.forEach((item) => { 
                    if(!this.groupList.find(existingKey => existingKey.key == item.pages_content_is_group )) {
                        this.groupList.push({key: item.pages_content_is_group , items: []});                   
                    } 
                });
                response.data.forEach((rd) => { 
                    this.groupList.forEach((gl) => {
                        if( gl.key == rd.pages_content_is_group ){
                            gl.items.push(rd);
                        }    
                    }); 
                });
                sessionStorage.clear();
                this.$Progress.finish();
          })
          .catch(function (error) {
            console.error(error);
            sessionStorage.clear();
            this.$Progress.finish();
          });  
      }
    },
    created() {
        this.getData();
    }
  }; 
  </script>