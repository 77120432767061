<template>
    <div>
        <div class="modal-dialog modal-xxl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Other Details</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeOtherModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-2"> 
                     <div class="card card-xxl-stretch pt-3">
                        <div class="card-body">
                            <div class="row">  
                                ...
                            </div>
                        </div>
                        <div class="card-footer">
                           <button class="btn btn-success btn-sm w-100" type="button" @click="this.onSaveOther()"> <i class="fa fa-save"></i> SAVE OTHER </button>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'FactorOtherModal',
    data(){
        return {
            form: {
                name: null
            }
        }
    },
    methods:{
        getData(){
            //do nothing
        },
        onSaveOther() {
            this.$refs.closeOtherModal.click();
        },
    }
}
</script>