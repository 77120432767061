<template> 
  <div>
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">Data Transfer</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">Settings</router-link></li>     
                <li class="breadcrumb-item text-gray-600">Data Transfer</li> 
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </ul> 
        </div>  
        <div class="d-flex align-items-center py-1">
            <router-link to="/Settings">
                <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                 <span class="ms-2 fs-7"> Return back to Settings </span>
                </button>
            </router-link> 
        </div>
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                            <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> List </span> 
                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                        Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span> 
                            </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover">
                    <thead>
                        
                    </thead>
                    <tbody class="fw-bold text-gray-600" v-if="lists"> 
                        
                    </tbody>
                    <tbody v-if="!lists || this.lists.length == 0">
                        
                    </tbody>
                    <tfoot class="border-top border-dashed" v-if="this.lists || this.lists.length > 0">
                       
                    </tfoot> 
                    </table>
                    </div>
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>

<script>
// Component Alias: `CMP001`  
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
 
export default {
  name: "DataTransferList", 
  data() {
    return {
      lists:[],
      searchbar: '',
      isListProgressing: false,
      page_size: 10,
      page: 1,
      menuSelection: '/DataTransferSettingsHelppage',
      iconView : false,
    };
  }, 
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() { 
    
    },
    async helpPageViewIcon()
    {
      const queryparameter = {
          session_id : this.$getUser().session_id,
          menu_selection : '/DataTransferSettingsHelppage'
      };
      axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
      .then((response) => { 
          if(!response.data.error){ 
              this.iconView = response.data;
          }
      })
    },
  }, 
    created() {
      this.helpPageViewIcon();
    }
}; 
</script>