<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Accounting Voucher Operations') }} </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Accounting') }}</li>
                    <li class="breadcrumb-item text-primary">{{ $t('Accounting Transaction') }}</li>
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'AccountingVoucherList' }">{{ $t('Accounting Voucher Operations') }}</router-link></li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Accounting Voucher Operations') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <router-link to="/AccountingVoucherList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                        <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link>
                &nbsp;
                <div class="dropdown" v-if="this.$isCredentialActive(49,'D')">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="accountingActions" type="button">
                    <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                    </svg></span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="accountingActions"> 
                        <li>
                        <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteTransaction()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                        </svg>
                        </span>{{ $t('Delete') }} </a>
                    </li>
                    </ul>
                </div>  
            </div>
        </div>
        <div class="content flex-row-fluid mt-4">
            <RequiredField></RequiredField>
            <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                <div class="card card-xxl-stretch">
                    <div class="row p-10">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                            <div class="row g-xxl-9">
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h3>{{ $t('Accounting Voucher Operations') }}</h3>
                                            </div>
                                        </div>
                                        <div class="card-body pb-0">
                                            <div class="fv-row mb-7 row">
                                                <div class="col-lg mb-2">
                                                    <label class="fs-6 fw-bold form-label"  v-tag="'period_id'" >
                                                        <span>{{ $t('Accounting Period') }}</span> 
                                                    </label>   
                                                    <Select2 v-model="form.period_id" :options="accountingYears" :settings="{ width: '100%' }" @select="onChangeAccountYear($event, true)" :disabled="this.docketItemsList.length > 0" name="period_id" v-custom  />  
                                                </div>
                                                <div class="col-lg mb-2" v-if="this.form.periodSelection">
                                                    <label class="fs-6 fw-bold form-label" v-tag="'record_date'" >
                                                        <span>{{ $t('Record Date') }}</span>
                                                    </label>
                                                    <Datepicker textInput 
                                                        :required="this.$checkIfDateFieldRequired ('record_date')"
                                                        :textInputOptions="{ format: 'dd.MM.yyyy' }" 
                                                        v-maska="'##.##.####'" 
                                                        v-model="form.record_date" 
                                                        format="dd.MM.yyyy" 
                                                        previewFormat="dd.MM.yyyy" 
                                                        autoApply 
                                                        :clearable="false" 
                                                        :enableTimePicker="false" 
                                                        :disabled="this.docketItemsList.length > 0" 
                                                        :minDate="this.form.periodSelection.start_date"
                                                        :maxDate="this.form.periodSelection.end_date" name="record_date" v-custom />
                                                </div>
                                                <div class="col-lg mb-2">
                                                    <label class="fs-6 fw-bold form-label"  v-tag="'docket_no'" >
                                                        <span>{{ $t('Voucher Number') }}</span>
                                                    </label>   
                                                    <input type="text" class="form-control fw-bolder" v-model="form.docket_no" disabled placeholder="Automatic"  name="docket_no" v-custom />
                                                </div>                                    
                                                <div class="col-lg mb-2">
                                                    <label class="fs-6 fw-bold form-label" v-tag="'serial_no'" >
                                                        <span>{{ $t('S.N.') }}</span>
                                                    </label>   
                                                    <input type="text" class="form-control fw-bolder" v-model="form.serial_no" disabled placeholder="Automatic" name="serial_no" v-custom />
                                                </div>                                    
                                                <div class="col-lg mb-2">
                                                    <label class="fs-6 fw-bold form-label" v-tag="'is_manuel'" >
                                                        <span>{{ $t('Record Type') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.is_manuel" :options="recordTypeList" disabled name="is_manuel" v-custom  :settings="{ width: '100%' }"/>
                                                </div>
                                                <div class="col-lg mb-2">
                                                    <label class="fs-6 fw-bold form-label" v-tag="'voucher_type'" >
                                                        <span>{{ $t('Voucher Type') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.voucher_type" :options="voucherTypeList" :disabled="true" :settings="{ width: '100%' }" name="voucher_type" v-custom />
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-0">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0">
                                            <div class="p-0">
                                                <div class="d-flex flex-stack flex-wrap mb-5">
                                                    <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                        <button type="button" class="btn btn-sm btn-success fw-bold required me-3"
                                                                ref="btnAddItem" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_add_item" 
                                                                @click="openDocketModal('addItem')"
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end">

                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                        viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                            transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                            fill="currentColor">
                                                                        </rect>
                                                                    </svg>
                                                                </span> 
                                                                {{ $t('Add Transaction') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" 
                                                                data-bs-toggle="modal"
                                                                ref="btnEditItem"
                                                                data-bs-target="#kt_modal_add_item" 
                                                                @click="openDocketModal('editItem')"
                                                                :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span> 
                                                                {{ $t('Edit') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                            @click="deleteTransactionItem()"
                                                            :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove') }}
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllTransactionItems()">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Remove All (!)') }}
                                                        </button> 
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="pb-10">
                                                            <DxDataGrid id="gridContainer" 
                                                                :show-borders="true" 
                                                                key-expr="id" 
                                                                :data-source="docketItemsList" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true" 
                                                                :column-auto-width="true" 
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true" 
                                                                :show-row-lines="true"
                                                                @row-prepared="onRowPrepared" 
                                                                @cell-prepared="onCellPrepared"
                                                                :customize-columns="onCustomizeColumn"
                                                                @exporting="this.$onExporting($event , 'Accounting-transactions')"> 

                                                                <DxFilterRow :visible="true"/>
                                                                <DxHeaderFilter :visible="true"/>
                                                                <DxGroupPanel :visible="true"/>      
                                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                                <DxPaging :page-size="pageSize"/>
                                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                                <DxSearchPanel :visible="true"/>
                                                                <DxScrolling
                                                                    mode="virtual"
                                                                    row-rendering-mode="virtual"
                                                                />
                                                                <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                                <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/>
                                                                <DxColumn data-field="account_plan_full_code" :caption="$t('Account Code/Name')" header-cell-template="account-codename-header" cell-template="account_plan_full_code-cell-template"/> 
                                                                <DxColumn data-field="transaction_date" data-type="date" format="dd.MM.yyyy" :caption="$t('Trans.Date')" header-cell-template="transaction-date-header" alignment="right" cell-template="transaction_date-cell-template"/> 
                                                                <DxColumn data-field="transaction_no" :caption="$t('Trans.No')" header-cell-template="transation-no-header" cell-template="transaction_no-cell-template" /> 
                                                                <DxColumn data-field="reference_no" :caption="$t('Ref.No')"  header-cell-template="reference-no-header" cell-template="reference_no-cell-template"/> 
                                                                <DxColumn data-field="amount_type" :caption="$t('Type')" cell-template="amount_type-cell-template"/> 
                                                                <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/> 
                                                                <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/> 
                                                                <DxColumn data-field="local_amount" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" header-cell-template="local-amount-header" alignment="right" cell-template="local_amount-cell-template"/>   
                                                                <DxColumn data-field="is_manuel_name" :caption="$t('Record Type')" header-cell-template="record-type-header" cell-template="is_manuel_name-cell-template"/>
                                                                <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/> 

                                                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                                <template #headerLocalTemplate="{ data: data }">
                                                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                                </template>

                                                                <template #account-codename-header>
                                                                    <div>{{ $t('Account') }}</div><div>{{ $t('Name/Code') }}</div>
                                                                </template>
                                                                <template #transaction-date-header>
                                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                                                </template>
                                                                <template #transation-no-header>
                                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                                </template>
                                                                <template #reference-no-header>
                                                                    <div>{{ $t('Reference') }}</div><div>{{ $t('No') }}</div>
                                                                </template>
                                                                <template #local-amount-header>
                                                                    <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                                                </template>
                                                                <template #record-type-header>
                                                                    <div>{{ $t('Record') }}</div><div>{{ $t('Type') }}</div>
                                                                </template>

                                                                <template #is-selected-cell-template="{ data }">
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #id-cell-template="{ data }">
                                                                    <a href="#" class="text-gray-800 text-hover-primary">
                                                                        {{ (this.pageSize * this.pageIndex) + data.rowIndex + 1 }}
                                                                    </a>
                                                            </template>
                                                            <template #account_plan_full_code-cell-template="{ data }">
                                                                <span>
                                                                    <div class="fw-bold p-1 align-middle"
                                                                        :class="{ 'text-primary': data.data.amount_type == 'Debit', 'text-danger ps-15': data.data.amount_type == 'Credit', 'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}">
                                                                        <div> {{ data.data.account_plan_full_code }} - {{data.data.account_plan_name}} </div>
                                                                        <div>{{ data.data.account_name }}</div>
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #transaction_date-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle text-end" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> {{ previewDate(data.data.transaction_date) }}
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #transaction_no-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> {{ data.data.transaction_no }}
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #reference_no-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> {{ data.data.reference_no }}
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #amount_type-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> <span>{{ data.data.amount_type }}</span>
                                                                    </div>
                                                                </span>
                                                            </template>
                                                                <template #amount-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold text-end align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> {{ data.data.amount }} 
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #currency-cell-template="{ data }">
                                                                <span>
                                                                    <div  class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}" > {{ data.data.currency }} 
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #local_amount-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold text-end align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> {{ data.data.local_amount }}
                                                                    </div>
                                                                </span>
                                                                    
                                                            </template>
                                                            <template #is_manuel_name-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> <span> {{ data.data.is_manuel_name }} </span> 
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #description-cell-template="{ data }">
                                                                <span>
                                                                    <div class="text-gray-800 p-1 fw-bold align-middle" 
                                                                        :class="{'fw-boldest': data.data.is_level2 == 1 || data.data.is_level2_bold == 1}"> <span> {{ data.data.description }} </span> 
                                                                    </div>
                                                                </span>
                                                            </template>
                                                        <DxSummary :calculate-custom-summary="setSummaries">
                                                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                            <DxGroupItem
                                                                :show-in-group-footer="true"
                                                                name="local_amount"
                                                                summary-type="custom"
                                                                show-in-column="local_amount"
                                                                display-format="{0}"
                                                            /> 
                                                            <DxTotalItem name="local_amount"
                                                                summary-type="custom"
                                                                show-in-column="local_amount"
                                                            />
                                                        </DxSummary>
                                                        <DxStateStoring
                                                            :enabled="true"
                                                            type="custom"
                                                            :storage-key="this.storageKey"
                                                            :ref="dataGrid" 
                                                            :saving-timeout="1000"
                                                            :custom-load="LoadDXState"
                                                        />
                                                        </DxDataGrid>   
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row g-xxl-9">
                                        <div class="col-lg-12 mt-0">
                                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5"> {{ $t('Voucher Total') }} </h3>
                                                    </div>
                                                </div>
                                                <div class="card-body pt-1">
                                                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                                        <div class="fv-row row">
                                                            <table class="table align-middle table-row-dashed fs-6 gy-5 table-hover">
                                                                <thead>
                                                                    <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 fw-bolder text-gray-800">
                                                                        <th class="text-end">{{ $t('Total Debit') }}</th>
                                                                        <th class="text-end">{{ $t('Total Credit') }}</th>
                                                                        <th class="text-end">{{ $t('Total Balance') }}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="text-gray-600 fw-bold" v-if="docketSummary">
                                                                    <tr class="odd">
                                                                        <td class="text-end">{{this.docketSummary.total_debit}}</td>
                                                                        <td class="text-end">{{this.docketSummary.total_credit}}</td>
                                                                        <td class="text-end">{{this.docketSummary.total_balance}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(49,'U')">
                                            <div class="d-grid gap-2"> 
                                                <button class="btn btn-primary btn-lg mb-0" type="button" @click="createManuelTransaction()" data-kt-indicator="on" :disabled="docketItemsList.length == 0"> 
                                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                    </svg>
                                                    </span>  
                                                    <strong v-if="!this.isDocketSaving"> {{ $t('Update Transaction') }} </strong> 
                                                    <span class="indicator-progress" v-if="this.isDocketSaving">
                                                        {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </form>
        </div>
        <!-- ADD TRANSACTION MODAL -->
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> {{ $t('Transaction') }}
                            <span v-if="this.docketForm.is_manuel == 0"> ({{ $t('Automatic') }}) </span>
                            <span v-if="this.docketForm.is_manuel == 1"> ({{ $t('Manuel') }}) </span>                                  
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-1" :class="{'overlay overlay-block': this.isVoucherItemSaving == true}">
                        <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'AccountingVoucherItemCreate' : 'AccountingVoucherItemUpdate'" class="mt-5"></RequiredFieldOnModal>
                        <form id="voucherItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                            <div class="row">
                                <div class="col-lg-8" :class="{'col-lg-12': this.modalType == 'editItem' && this.docketForm.is_manuel == 0}">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-5">
                                                            {{ $t('Select Account') }}
                                                        </h3>
                                                    </div>
                                                    <div class="card-toolbar" v-if="this.docketForm.account_id !== null && this.docketForm.account_id !== '' && this.docketForm.account_id != '-1'">
                                                        <i class="bi bi-info-circle text-primary fs-3 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                                    </div>
                                                </div>
                                                <div class="card-body pt-3 pb-3"> 
                                                    <div class="row">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'account_id'" >
                                                            {{ $t('Account') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                            <Select2 v-model="docketForm.account_id" :options="accountList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                            :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" @select="this.docketForm.amount = 0.00;" name="account_id" v-custom />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6">
                                                <div class="card-body"> 
                                                    <div class="card-body p-1 pt-2">    
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'transaction_date'" >
                                                                <span>{{ $t('Transaction Date') }}</span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <Datepicker :required="this.$checkIfDateFieldRequired ('transaction_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="docketForm.transaction_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false"
                                                                :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="transaction_date" v-custom  />
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'item_type'" >
                                                                <span> {{ $t('Debit / Credit') }} </span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <Select2 v-model="this.docketForm.item_type" :options="this.debitCreditStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                                :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" @select="getDescriptions()" name="item_type" v-custom  />
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                <span v-tag="'currency'" >{{ $t('Currency') }}</span> 
                                                                <span v-tag="'rate'" v-if="this.docketForm.currency !== this.localCurrency"> / {{ $t('Rate') }} </span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <div class="row">
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                        <Select2 v-model="this.docketForm.currency" :options="this.currencyList" @select="onChangeCurrency()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                                        :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="currency" v-cutom />
                                                                    </div>
                                                                    <div class="col-lg-6 fv-row fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                        <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>  
                                                                    </div>
                                                                    <div class="col-lg-12 fv-row fv-plugins-icon-container mt-3" v-if="this.docketForm.currency !== this.localCurrency">
                                                                       <CurrencyInput type="text" class="form-control text-end" v-model="this.docketForm.rate" :precision="5" @focus="$event.target.select()" @blur="calculateLocalAmount()"
                                                                                      :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="rate" v-custom />
                                                                       <small v-if="this.docketForm.rate_date !== null">Rate Date: {{ previewDate(this.docketForm.rate_date) }}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                <span v-tag="'amount'" > {{ $t('Amount') }} </span> 
                                                                <span v-tag="'amount_local'" v-if="this.docketForm.currency !== this.localCurrency"> / {{ $t('Local Amount') }} </span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <div class="row">
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                        <div class="input-group mb-3">
                                                                            <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount" @focus="$event.target.select()" @blur="calculateLocalAmount()" name="amount" v-custom />
                                                                            <ErrorMessage name="amount" class="text-danger" />
                                                                            <span class="input-group-text p-1">
                                                                                <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeAmount()">
                                                                                    <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                                </button>
                                                                            </span>
                                                                        </div>  
                                                                    </div>
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                        <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount_local" @focus="$event.target.select()" disabled name="amount_local" v-custom />
                                                                        <ErrorMessage name="amount" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                <span v-tag="'transaction_no'" >{{ $t('Transaction') }}</span>
                                                                <span v-tag="'reference_no'" >{{ $t(' / Ref Number') }}</span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <div class="row">
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">                                                                        
                                                                        <input type="text" class="form-control text-end" v-model="docketForm.transaction_no" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="transaction_no" v-custom >
                                                                    </div>
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                        <input type="text" class="form-control" v-model="docketForm.reference_no" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="reference_no" v-custom >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'" >
                                                                <span>{{ $t('Description') }}</span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" v-model="docketForm.description" name="description" v-custom >
                                                                    <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                                        <div class="dropdown dropup">
                                                                            <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                                                <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                            </button>
                                                                            <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                                <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                                    <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                                        <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                                            :class="{'text-success': this.docketForm.description == item.description && item.description !== ''}">
                                                                                            <i class="bi bi-dash fw-boldest" v-if="this.docketForm.description !== item.description"> </i> 
                                                                                            <i class="bi bi-check-lg fw-boldest text-success" v-if="this.docketForm.description == item.description && item.description !== ''"> </i>
                                                                                            {{item.text}}  
                                                                                        </a>
                                                                                        <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div class="row mb-2">
                                                            <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                <span v-tag="'branch_id'" >{{ $t('Branch') }}</span>
                                                                <span v-tag="'expense_center_id'" >{{ $t(' / Expense Center') }}</span>
                                                            </label>
                                                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                <div class="row">
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="this.docketForm.branch_id" :options="this.branchList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="branch_id" v-custom />
                                                                    </div>
                                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="this.docketForm.expense_center_id" :options="this.expenseCenterList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="expense_center_id" v-custom />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div class="card card-xxl-stretch mb-6" v-if="this.modalType == 'addItem'">
                                                <div class="card-body pt-1 pb-2">
                                                    <div class="row" >
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'other_account_id'" >
                                                            <span v-if="this.docketForm.item_type == 1">{{ $t('Debit Account') }}</span>
                                                            <span v-if="this.docketForm.item_type == 0">{{ $t('Credit Account') }}</span>
                                                        </label>
                                                        <!-- Tüm Level-3'ler kod - ad olarak listelenecek -->
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                            <Select2 v-model="docketForm.other_account_id" :options="accountList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                            :disabled="this.modalType == 'editItem' && this.docketForm.record_type == 2" name="other_account_id" v-custom />
                                                        </div>
                                                    </div> 
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4" v-if="this.modalType == 'addItem' || (this.modalType == 'editItem' && this.docketForm.is_manuel != 0)">
                                    <div class="card border border-gray-300 card-flush" id="kt_contacts_list" style="height: 97%" v-if="!this.isContactInfoVisible">
                                        <div class="card-header pt-3">
                                            <div class="card-title">
                                                <form @submit.prevent="" class="d-flex align-items-center position-relative w-100 m-0" autocomplete="off">
                                                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-300px ps-15" placeholder="Search accounting code" v-model="searchbar">
                                                </form>
                                            </div>
                                            
                                        </div>
                                        <div class="card-body p-5 pt-0" id="kt_contacts_list_body">
                                            <div class="scroll-y me-n5 pe-5 mh-450px h-xl-auto" v-if="accountList">
                                                <div v-for="(item, index) in searchableAccountList" v-bind:key="item">
                                                    <div class="d-flex flex-stack py-2" v-if="item.id !== '-1'">
                                                        <div class="d-flex align-items-center">
                                                            <div class="symbol symbol-40px symbol-circle cursor-pointer" @click="onSelectAccount(item)">
                                                                <span v-if="this.docketForm.account_id !== item.id" class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{{(item.name)[0]}}</span>
                                                                <span v-if="this.docketForm.account_id == item.id" class="svg-icon svg-icon-success svg-icon-3x">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <a class="ms-4 cursor-pointer" @click="onSelectAccount(item)">
                                                                <span class="fs-6 fw-bolder text-gray-800 text-hover-primary mb-2 cursor-pointer">
                                                                    {{item.final_code}} - {{item.name}}
                                                                </span>
                                                                <div class="fw-bold fs-7 text-muted cursor-pointer">{{item.local_balance}}</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="separator separator-dashed my-3" v-if="index !== accountList.length - 1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border border-gray-300 card-flush" style="height: 96%" v-if="this.isContactInfoVisible">
                                        <div class="card-header pt-3">
                                            <div class="card-title">
                                                <h4 class="text-gray-800">
                                                    {{ $t('Account Info') }}
                                                </h4> 
                                            </div>
                                            <div class="card-toolbar m-0 p-0">
                                                <i class="bi bi-x-lg fs-5 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                            </div>
                                        </div>
                                        <div class="card-body p-5 pt-0" id="kt_contacts_list_body">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.docketForm.is_manuel == 0 && this.modalType == 'editItem'">
                                    <div class="alert alert-warning d-flex align-items-center p-5 mb-10">
                                        <span class="svg-icon svg-icon-2hx svg-icon-dark me-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                                                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column">
                                            <h4 class="mb-1">{{ $t('This Transaction Item have been generated automatically. It can not be updated') }}.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-lg-12" v-if="(this.modalType == 'editItem' && this.docketForm.is_manuel != 0) || this.modalType == 'addItem'">
                                <div class="card card-flush" id="kt_contacts_list">                                        
                                    <div class="d-grid gap-2"> 
                                        <button class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" type="button"
                                            @click="this.addTransactionItem()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Add To Voucher') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                            @click="this.updateTransactionItem()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isVoucherItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from "axios";
// import Swal from "sweetalert2";
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, DxScrolling } from 'devextreme-vue/data-grid';
// import { DxCheckBox } from 'devextreme-vue';
 
export default {
  name: "AccountingVoucherUpdate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxStateStoring, DxTotalItem, DxGroupItem, DxScrolling
  },
  data() {
    return { 
        isOpenDescriptionDropdown: false,
        pageIndex: 0,
        pageSize:100,
        searchbar: '',
        accountingTransactionList: [],
        currencyList: [],
        accountList: [],
        docketItemsList: [], 
        debitCreditStatusList: [
            { id: 0, text: 'Debit'},
            { id: 1, text: 'Credit'},
        ],
        recordTypeList: [
            { id: 0, text: 'Automatic'},
            { id: 1, text: 'Manuel'},
            { id: 2, text: 'Mixed'}
        ],
        branchList: [],
        expenseCenterList: [{ id: '-1', text: 'Select an option'}],
        localCurrency: null,
        form: {
            record_date: moment(),
            docket_no: null, 
            serial_no: null, 
            is_manuel: 1,
            voucher_type: 0,
            period_id: null,
            periodSelection: {}
        },
        docketForm: {
            id: null,
            account_id: '-1',
            other_account_id: '-1',
            transaction_date: moment(),
            item_type: 1,
            currency: '-1',
            rate: 0.00,
            amount: 0.00,
            transaction_no: null,
            reference_no: null,
            description: null,
            rate_date: null,
            branch_id: '-1',
            expense_center_id: '-1',
        },
        modalType: 'addItem',
        docketSummary: {},
        isDocketSaving: false,        
        isContactInfoVisible: false,
        isVoucherItemSaving: false,
        isModalButtonDisabled: false,
        voucherTypeList: [], 
        accountingYears: [],
        descriptionTypeList: [],
        menuSelection: '/AccountingVoucherHelppage',
        iconView : false

    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    searchableAccountList() {
        return this.accountList.filter(p => { 
            return p.text.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
        });
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
   isItemButtonDisabled() {
       return this.docketItemsList.find(item => item.isSelected == true );
    },
   isChecked(value) { 
       return this.docketItemsList.find(item => item.id == value.id ).isSelected;
    },
    showPageSizeSelector() {
        return this.docketItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.docketItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {

        this.$Progress.start(); 
        this.getAccountingYears();
        this.isInvoiceProgressing = false;
        this.accountList = []; 
        this.voucherTypeList = [];
        this.detail = [];  
        this.currencyList = this.currencyList.filter((element) => {
            return element.id !== -1;
        });        

        const parameters = {
          session_id: this.$getUser().session_id,
          list_name: ["currency"],
          source_id: this.$getSessionStorage('record_id'),
          period_id: this.$getSessionStorage('tmp_period_selection'),
          tip: 17 //Voucher Type
        };  
        
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/AccountingTransaction/GetDetail', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetConst', parameters, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.currencyList = responses[0].data.currency;
                this.$setSessionStorage('tmp_session_id', responses[1].data.summary.tmp_session_id);
                this.docketItemsList = responses[1].data.detail;
                this.docketSummary = responses[1].data.summary; 
                this.form.is_manuel = responses[1].data.summary.is_manuel;
                this.form.record_date = responses[1].data.summary.record_date;
                this.form.docket_no = responses[1].data.summary.docket_no;
                this.form.serial_no = responses[1].data.summary.serial_no;
                this.form.voucher_type = responses[1].data.summary.voucher_type;
                this.voucherTypeList = responses[2].data;  

                this.localCurrency = this.$getUser().currency_code;  
                this.$setSessionStorage('refreshList', true);  
                if (this.localCurrency == null || this.localCurrency == '' || this.localCurrency == undefined) {
                    this.currencyList.unshift({id: '-1', text: 'Select an option'});
                }
                else {
                    this.docketForm.currency = this.$getUser().currency_code;
                } 
                
                this.form.period_id = this.$getSessionStorage('tmp_period_selection');

                this.accountingYears.forEach((period) => {
                    if(period.id == this.form.period_id){
                        this.form['periodSelection'] = period;
                    } 
                }); 
                this.$Progress.finish();
            })
          )
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        });  
    },
    getAccountingYears(){
        this.companyAccountingPeriods = [];
        const queryParameter = {
            session_id: this.$getUser().session_id
        };
        axios.post('/api/Auth/GetCompanyAccountingYears', queryParameter, {'Content-Type': 'application/json'})
        .then((response) => {   
            this.accountingYears = response.data;   
        })
        .catch(function (error) {
            console.log(error);
        }); 
    }, 
    openDocketModal(modalType) {
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.isOpenDescriptionDropdown = false;
        this.modalType = modalType;
        this.isContactInfoVisible = false; 
        this.isVoucherItemSaving = false;
        this.isModalButtonDisabled = false;

        // Form öğeleri sıfırlanır.
        this.docketForm.account_id = '-1';
        this.docketForm.account_name = null;
        this.docketForm.transaction_no = null; 
        this.docketForm.amount = 0.00;
        this.docketForm.rate = 0.00;
        this.docketForm.reference_no = null;
        this.docketForm.description = null;
        this.docketForm.item_type = 0;
        this.docketForm.branch_id = '-1';
        this.docketForm.expense_center_id = '-1';
        this.docketForm.currency = this.localCurrency;
        this.docketForm.is_manuel = this.form.is_manuel;
        this.docketForm.other_account_id = '-1';
        this.docketForm.transaction_date = moment(this.form.record_date);
        
        // Open Docket Modal in Edit Mode.
        if(this.modalType == 'editItem'){ 
            this.docketItemsList.forEach((item) => { 
                if(item.isSelected) {
                    this.docketForm.id = item.id; 
                    this.docketForm.account_id = item.account_plan_id; 
                    this.docketForm.account_name = item.account_name; 
                    this.docketForm.transaction_no = item.transaction_no; 
                    this.docketForm.amount = Math.abs(parseFloat(item.amount.replace(/,/g, '')));
                    this.docketForm.rate = item.rate.replace(',', '.');
                    this.docketForm.reference_no = item.reference_no;
                    this.docketForm.description = item.description;
                    this.docketForm.transaction_date = moment(item.transaction_date);
                    this.docketForm.item_type = item.item_type; 
                    this.docketForm.currency = item.currency; 
                    this.docketForm.branch_id = item.branch_id; 
                    this.docketForm.expense_center_id = item.expense_center_id; 
                    this.docketForm.is_manuel = item.is_manuel; 
                    this.docketForm.other_account_id = item.other_account_id; 
                    if(item.amount_type == 'Debit'){
                        this.docketForm.item_type = 0;
                    }
                    else{
                        this.docketForm.item_type = 1;
                    }
                    if(item.expense_center_id == ''){ this.docketForm.expense_center_id = -1; }
                    if(item.branch_id == ''){ this.docketForm.branch_id = -1; } 
                }
            });
        }        
        const parameters = {
            plan_level: 3,
            accounting_period_id: this.form.period_id,
            session_id: this.$getUser().session_id,
        };
        const parametersTwo = { 
            session_id: this.$getUser().session_id,
            list_name: ["branch", "expense_center"]
        }; 
        const parametersThree = {
            route: this.modalType == 'addItem'  ? 'AccountingVoucherItemCreate' : 'AccountingVoucherItemUpdate',
            session_id: this.$getUser().session_id
        };      
        const requestOne =  axios.post('/api/Helper/GetAccountingPlanByLevel', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parametersTwo, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/FormValidations/GetFormFields', parametersThree, {'Content-Type': 'application/json'});
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.accountList = responses[0].data;
            if(responses[2].data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(responses[2].data));
            } 
            this.accountList.forEach((item) => {
                item.text = item.final_code + ' - ' + item.name;
            });
            this.accountList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});
            this.branchList = responses[1].data.branch;
            this.expenseCenterList = responses[1].data.expense_center; 
            if(this.modalType !== 'editItem'){ 
                this.branchList.forEach((value) => {
                    if(value.is_default){
                        this.docketForm.branch_id = value.id;
                    }
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.docketForm.expense_center_id = value.id;
                    }
                });
            }            
            this.getDescriptions(function() {});
            this.onChangeCurrency(true);
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    setSummaries(options){
        if(options.name == "local_amount"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){ 
                var local_amount = (options.value.is_level2 == 0 && options.value.is_level2_bold == 0) ? parseFloat(options.value.local_amount.replaceAll(',', '')) : 0;
                (options.totalValue += local_amount);
            }
            else if(options.summaryProcess === 'finalize'){
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        } 
    },
    onSelectItem(item) { 
        this.docketItemsList.forEach((value) => {  
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },
    onChangeAmount() {
        this.accountList.forEach((item) => { 
            if(this.docketForm.account_id == item.id)
            { 
                this.docketForm.amount = Math.abs(parseFloat(item.local_balance.replace(/,/g, '')));
            }
        });  
        this.calculateLocalAmount(function() {});
    },
    onSelectAccount(item) {
        this.docketForm.amount = 0.00;
        this.docketForm.account_id = item.id;
        this.docketForm.account_name = item.final_code + ' - ' + item.name;
    }, 
    addTransactionItem(){
        this.$checkSelect2Validation('voucherItemForm');
        if(!document.forms['voucherItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        if (this.docketForm.rate == null || this.docketForm.rate == '' || this.docketForm.rate == undefined) { this.docketForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isVoucherItemSaving = true;
        const that = this;
        const params = {
            tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
            session_id: this.$getUser().session_id,
            record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            item_type: this.docketForm.item_type,
            account_id: this.docketForm.account_id,
            other_account_id: this.docketForm.other_account_id,
            transaction_date: moment(this.docketForm.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            transaction_no: this.docketForm.transaction_no,
            currency: this.docketForm.currency,
            rate: this.docketForm.rate,
            amount: this.docketForm.amount,
            reference_no: this.docketForm.reference_no,
            description: this.docketForm.description,
            branch_id: this.docketForm.branch_id,
            expense_center_id: this.docketForm.expense_center_id
        };  
        if(params.expense_center_id == -1){ params.expense_center_id = null; }
        if(params.branch_id == -1){ params.branch_id = null; }
        if(params.other_account_id == -1){ params.other_account_id = null; }
        axios.post('api/ManuelAccountingTransaction/CreateManuelItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('tmp_session_id', response.data.summary.tmp_session_id);
                this.docketItemsList = response.data.detail;
                this.docketSummary = response.data.summary;
                this.form.is_manuel = response.data.summary.is_manuel;
                this.form.record_date = response.data.summary.record_date;
                this.$refs.closeModal.click();
            } 
            this.isVoucherItemSaving = false;
        })
        .catch(function () {
            this.isVoucherItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isVoucherItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        }); 
    },
    updateTransactionItem(){ 
        this.$checkSelect2Validation('voucherItemForm');
        if(!document.forms['voucherItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        if (this.docketForm.rate == null || this.docketForm.rate == '' || this.docketForm.rate == undefined) { this.docketForm.rate = 0; }
        this.isModalButtonDisabled = true;
        this.isVoucherItemSaving = true;
        const that = this;
        const params = {
            tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
            session_id: this.$getUser().session_id,
            record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            item_type: this.docketForm.item_type,
            account_id: this.docketForm.account_id,
            transaction_date: moment(this.docketForm.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            transaction_no: this.docketForm.transaction_no,
            currency: this.docketForm.currency,
            rate: this.docketForm.rate,
            amount: this.docketForm.amount,
            reference_no: this.docketForm.reference_no,
            description: this.docketForm.description,
            branch_id: this.docketForm.branch_id,
            expense_center_id: this.docketForm.expense_center_id,
            id: this.docketForm.id
        };
        if(params.expense_center_id == '-1'){ params.expense_center_id = null; }
        if(params.branch_id == '-1'){ params.branch_id = null; }
        if(params.other_account_id == '-1'){ params.other_account_id = null; }
        axios.post('api/ManuelAccountingTransaction/UpdateManuelItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('tmp_session_id', response.data.summary.tmp_session_id);
                this.docketItemsList = response.data.detail;
                this.docketSummary = response.data.summary;
                this.form.is_manuel = response.data.summary.is_manuel;
                this.form.record_date = response.data.summary.record_date;
                this.$refs.closeModal.click();
                this.$Progress.finish();
            } 
            this.isVoucherItemSaving = false;
        })
        .catch(function () {
            this.isVoucherItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isVoucherItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
    },
    getDescriptions(callback) {
        this.isOpenDescriptionDropdown = false;
        if (this.modalType == 'addItem') { this.docketForm.description = null; }
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 18, // Accounting Voucher
            location_detail: this.docketForm.item_type,
            account_type: this.docketForm.account_type !== '-1' ? this.docketForm.account_type : 0,
            sales_type: 0
        };
        if (this.docketForm.item_type == 0) { params.location_detail = 1; }
        if (this.docketForm.item_type == 1) { params.location_detail = 2; }
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2 && this.modalType == 'addItem') {
                    this.docketForm.description = element.description;
                }
            });
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    onSelectDescription(item) {
        this.docketForm.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    deleteTransactionItem() { 
         this.docketItemsList.forEach((item) => { 
            if(item.isSelected) {
                this.docketForm.id = item.id; 
            }
         });
         this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start(); 
                const params = {
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                        session_id: this.$getUser().session_id, 
                        id: this.docketForm.id
                };   
                axios.post('api/ManuelAccountingTransaction/DeleteManuelItem', params, {'Content-Type': 'application/json'})
                    .then((response) => {
                            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                                this.docketItemsList = [];
                                this.$setSessionStorage('tmp_session_id', response.data.summary.tmp_session_id);
                                this.docketItemsList = response.data.detail;
                                this.docketSummary = response.data.summary;
                                this.$Progress.finish();
                            } 
                            this.$Progress.finish();
                    })
                .catch(function () {
                    this.$Progress.finish();
                });    
            }
        }); 
    },
    deleteAllTransactionItems() { 
         this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                this.$Progress.start(); 
                const params = {
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                    session_id: this.$getUser().session_id
                };   
                axios.post('api/ManuelAccountingTransaction/DeleteAllManuelItems', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.docketItemsList = [];
                        this.$setSessionStorage('tmp_session_id', response.data.summary.tmp_session_id);
                        this.docketItemsList = response.data.detail;
                        this.docketSummary = response.data.summary;
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                }); 
            }
        }); 
    },
    deleteTransaction() { 
         this.$swal.fire({
            title: "Are you sure you want to remove this Transaction?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                const params = {
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                    session_id: this.$getUser().session_id
                };   
                axios.post('api/ManuelAccountingTransaction/DeleteTransaction', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.$removeSessionStorage('tmp_session_id');
                        this.$removeSessionStorage('record_id');
                        this.$setSessionStorage('refreshList', true); 
                        this.$router.push({ name: 'AccountingVoucherList' });
                    } 
                })
                .catch(function () {
                }); 
            }
        }); 
    },
    onCellPrepared: function (e) {
        if (e.rowType == 'data') {
            e.cellElement.classList.add('align-middle');  
        }
    },
    onRowPrepared(e){
        if(e.rowType === 'data') {  
            if(e.data.is_level2 == 1 || e.data.is_level2_bold == 1) { 
                e.rowElement.classList.add('bg-material-gray-200');  
            }
        } 
    },
    createManuelTransaction(){
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to save this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true;
                const params = {
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                    session_id: this.$getUser().session_id,
                    record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    voucher_type: this.form.voucher_type
                };  
                axios.post('api/ManuelAccountingTransaction/CreateManuelTransaction', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.$setSessionStorage('record_id', response.data);
                        this.$swal("Success", "Data saved succesfully..!", 'success');  
                        this.$setSessionStorage('refreshList', true);  
                        this.$setSessionStorage('tmp_period_selection', this.form.period_id);
                        this.isDocketSaving = false;
                        this.getData();
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish();
                    this.isDocketSaving = false;
                })
                .catch(function () {
                    this.isDocketSaving = false;
                    this.$Progress.finish();
                });
                 
            }
        }); 
    },
    onChangeRateType(){ 
        const params = {
                session_id: this.$getUser().session_id,
                rate_date: moment(this.docketForm.transaction_date),
                bank_id: 0,
                currency: this.docketForm.currency
        };
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.docketForm.rate_date = response.data[0].rate_date;
                this.calculateLocalAmount(function() {});
            }
            else{
                this.docketForm.rate = 1;
                this.docketForm.rate_date = null;
                this.calculateLocalAmount(function() {});
                this.rateList = null;
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });  
    },
    onChangeCurrency(noWriteRate) {
        if(!noWriteRate) this.docketForm.rate = 0;
        if(this.docketForm.currency !== this.localCurrency) { 
            axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
            .then((typeResponse) => {
                this.typeList = typeResponse.data;
                this.typeList.forEach((item) => { 
                    if(item.is_default == 1){
                        this.typeSelection = item.id;
                    }
                });  

                const params = {
                    session_id: this.$getUser().session_id,
                    rate_date: moment(this.docketForm.transaction_date),
                    bank_id: 0,
                    currency: this.docketForm.currency
                };
                var source = this.typeList.find((element) => element.id == this.typeSelection);
                params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(!noWriteRate){
                        if(response.data.length > 0){
                            this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                            this.docketForm.rate_date = response.data[0].rate_date;
                            this.calculateLocalAmount(function() {});
                        }
                        else{
                            this.docketForm.rate = 1;
                            this.docketForm.rate_date = null;
                            this.calculateLocalAmount(function() {});
                        }
                    } else {                        
                        this.calculateLocalAmount(function() {});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });  
            })
            .catch(function (error) {
                console.log(error); 
            });  
        } 
    },
    calculateLocalAmount(callback){ 
        if(this.$getUser().currency_search_type == 1) {
            this.docketForm.amount_local = this.docketForm.amount / this.docketForm.rate;
        } else {
            this.docketForm.amount_local = this.docketForm.amount * this.docketForm.rate;
        }
        if(typeof callback == 'function'){
            callback();
        }
    },
    getCurrencies(){
        const params = {
            session_id: this.$getUser().session_id
        };    
        axios.post('/api/Currency/GetList', params, {'Content-Type': 'application/json'})
             .then((response) => { 
                this.currencyList = response.data;
                this.$Progress.finish();
             })
             .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
             });
    },
    onChangeAccountYear(e, isChangeForced){
        this.form['periodSelection'] = e;
        if((e.id != this.$getSessionStorage('activeYear')) || isChangeForced){
            this.form.record_date = moment(e.end_date);
        }  
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                     if(column.dataField == 'local_amount'){ 
                         column.caption =  'Local Amount';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/AccountingVoucherHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() { 
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  }
};
</script>