<template>
    <div class="card-body p-2">
        <div class="scroll-y me-n5 pe-5 mh-600px h-xl-auto"> 

            <!-- Customer + Supplier-->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 1 || this.selectedAccountInfo.account_type == 2">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Contact Name</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_contact_name }} <span v-if="this.selectedAccountInfo.customer_contact_name == null || this.selectedAccountInfo.customer_contact_name == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_contact_name }} <span v-if="this.selectedAccountInfo.supplier_contact_name == null || this.selectedAccountInfo.supplier_contact_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Registration Number</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_account_number }} <span v-if="this.selectedAccountInfo.customer_account_number == null || this.selectedAccountInfo.customer_account_number == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_account_number }} <span v-if="this.selectedAccountInfo.supplier_account_number == null || this.selectedAccountInfo.supplier_account_number == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600">
                        <small v-if="this.selectedAccountInfo.account_type == 1">Customer Type</small>
                        <small v-if="this.selectedAccountInfo.account_type == 2">Supplier Type</small>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_type_name }} <span v-if="this.selectedAccountInfo.customer_type_name == null || this.selectedAccountInfo.customer_type_name == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_type_name }} <span v-if="this.selectedAccountInfo.supplier_type_name == null || this.selectedAccountInfo.supplier_type_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Sales Channel</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.sales_channel_name }} <span v-if="this.selectedAccountInfo.sales_channel_name == null || this.selectedAccountInfo.sales_channel_name == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_sales_channel_name }} <span v-if="this.selectedAccountInfo.supplier_sales_channel_name == null || this.selectedAccountInfo.supplier_sales_channel_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Payment Value (Day)</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_payment_value }} <span v-if="this.selectedAccountInfo.customer_payment_value == null || this.selectedAccountInfo.customer_payment_value == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_payment_value }} <span v-if="this.selectedAccountInfo.supplier_payment_value == null || this.selectedAccountInfo.supplier_payment_value == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Mobile Phone</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_mobile_phone }} <span v-if="this.selectedAccountInfo.customer_mobile_phone == null || this.selectedAccountInfo.customer_mobile_phone == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_mobile_phone }} <span v-if="this.selectedAccountInfo.supplier_mobile_phone == null || this.selectedAccountInfo.supplier_mobile_phone == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Land Phone</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_phone }} <span v-if="this.selectedAccountInfo.customer_phone == null || this.selectedAccountInfo.customer_phone == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_phone }} <span v-if="this.selectedAccountInfo.supplier_phone == null || this.selectedAccountInfo.supplier_phone == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Email</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_email }} <span v-if="this.selectedAccountInfo.customer_email == null || this.selectedAccountInfo.customer_email == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_email }} <span v-if="this.selectedAccountInfo.supplier_email == null || this.selectedAccountInfo.supplier_email == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Post Code</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_postcode }} <span v-if="this.selectedAccountInfo.customer_postcode == null || this.selectedAccountInfo.customer_postcode == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_postcode }} <span v-if="this.selectedAccountInfo.supplier_postcode == null || this.selectedAccountInfo.supplier_postcode == ''"> - </span>
                    </div>
                </div>   
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>City</small></div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 1">
                        {{ this.selectedAccountInfo.customer_city }} <span v-if="this.selectedAccountInfo.customer_city == null || this.selectedAccountInfo.customer_city == ''"> - </span>
                    </div>
                    <div class="d-flex fw-semibold text-end" v-if="this.selectedAccountInfo.account_type == 2">
                        {{ this.selectedAccountInfo.supplier_city }} <span v-if="this.selectedAccountInfo.supplier_city == null || this.selectedAccountInfo.supplier_city == ''"> - </span>
                    </div>
                </div>
            </div>

            <!-- Cash -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 3">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Branch</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.branch_name }} <span v-if="this.selectedAccountInfo.branch_name == null || this.selectedAccountInfo.branch_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Currency</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.cash_currency }} <span v-if="this.selectedAccountInfo.cash_currency == null || this.selectedAccountInfo.cash_currency == ''"> - </span>
                    </div>
                </div>
            </div>

            <!-- Bank -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 4">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Account Type</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.bank_account_type_name }} <span v-if="this.selectedAccountInfo.bank_account_type_name == null || this.selectedAccountInfo.bank_account_type_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Account Detail</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.bank_detail_name }} <span v-if="this.selectedAccountInfo.bank_detail_name == null || this.selectedAccountInfo.bank_detail_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Branch</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.branch_name }} <span v-if="this.selectedAccountInfo.branch_name == null || this.selectedAccountInfo.branch_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Currency</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.bank_currency }} <span v-if="this.selectedAccountInfo.bank_currency == null || this.selectedAccountInfo.bank_currency == ''"> - </span>
                    </div>
                </div>
            </div>

            <!-- Personnel -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 5">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Position</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.personnel_position_name }} <span v-if="this.selectedAccountInfo.personnel_position_name == null || this.selectedAccountInfo.personnel_position_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Branch</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.branch_name }} <span v-if="this.selectedAccountInfo.branch_name == null || this.selectedAccountInfo.branch_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Expense Center</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.expense_center_name }} <span v-if="this.selectedAccountInfo.expense_center_name == null || this.selectedAccountInfo.expense_center_name == ''"> - </span>
                    </div>
                </div>
            </div>

            <!-- Expense -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 6">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Expense Group</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.expense_group_name }} <span v-if="this.selectedAccountInfo.expense_group_name == null || this.selectedAccountInfo.expense_group_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Expense Type</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.expense_type_name }} <span v-if="this.selectedAccountInfo.expense_type_name == null || this.selectedAccountInfo.expense_type_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Branch</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.branch_name }} <span v-if="this.selectedAccountInfo.branch_name == null || this.selectedAccountInfo.branch_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Expense Center</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.expense_center_name }} <span v-if="this.selectedAccountInfo.expense_center_name == null || this.selectedAccountInfo.expense_center_name == ''"> - </span>
                    </div>
                </div>
            </div>

             <!-- Stock -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 7">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <!-- <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Position</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.personnel_position_name }} <span v-if="this.selectedAccountInfo.personnel_position_name == null || this.selectedAccountInfo.personnel_position_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div> -->
            </div>

            <!-- Asset -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 8">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <!-- <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Position</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.personnel_position_name }} <span v-if="this.selectedAccountInfo.personnel_position_name == null || this.selectedAccountInfo.personnel_position_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div> -->
            </div>

            <!-- Other Account -->
            <div class="card-body p-6" v-if="this.selectedAccountInfo.account_type == 15">
                <div class="fs-4 fw-bold text-gray-800 mb-7">
                    {{ this.selectedAccountInfo.name }}
                </div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Other Account Group</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.other_account_group_name }} <span v-if="this.selectedAccountInfo.other_account_group_name == null || this.selectedAccountInfo.other_account_group_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Other Account Type</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.other_account_type_name }} <span v-if="this.selectedAccountInfo.other_account_type_name == null || this.selectedAccountInfo.other_account_type_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Branch</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.branch_name }} <span v-if="this.selectedAccountInfo.branch_name == null || this.selectedAccountInfo.branch_name == ''"> - </span>
                    </div>
                </div>
                <div class="separator separator-dashed"></div>
                <div class="fs-6 d-flex justify-content-between my-4">
                    <div class="fw-semibold text-gray-600"><small>Expense Center</small></div>
                    <div class="d-flex fw-semibold text-end">
                        {{ this.selectedAccountInfo.expense_center_name }} <span v-if="this.selectedAccountInfo.expense_center_name == null || this.selectedAccountInfo.expense_center_name == ''"> - </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 export default {
    name: "AccountInfo",
    props: {
        selectedAccountInfo: Object
    },
    methods: {
        getData() {
        }
    },
    created() {
        this.getData();
    }
};  
</script>