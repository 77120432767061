<template>
    <div>
        <div class="card-body">
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                    <div class="card-header">
                        <div class="card-title">
                            {{ $t('Collection Plan') }}
                        </div>
                    </div>
                    <div class="card-body">
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="currency" 
                            :data-source="collectionPlanTabList" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            :ref="'dataGrid'"
                            :customize-columns="onCustomizeCollectionColumns"
                            @exporting="this.$onExporting($event , 'Collection-plans')"> 

                            <DxFilterRow :visible="false"/>
                            <DxHeaderFilter :visible="false"/>
                            <DxGroupPanel :visible="false"/>      
                            <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                            <DxSearchPanel :visible="false"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />
                            <DxColumn data-field="due_date" :caption="$t('Due')" alignment="right" cell-template="due_date-cell-template"/>
                            <DxColumn data-field="installment_no" :caption="$t('Installment No')" alignment="right" header-cell-template="installment_no-header" cell-template="installment_no-cell-template"/>
                            <DxColumn data-field="producer_name" :caption="$t('Producer')" cell-template="producer_name-cell-template"/>
                            <DxColumn data-field="producer_policy_type_name" :caption="$t('Producer Policy Type')" header-cell-template="producer_policy_type_name-header" cell-template="producer_policy_type_name-cell-template"/>
                            <DxColumn data-field="policy_type_name" :caption="$t('Regulation Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/>
                            <DxColumn data-field="policy_no" :caption="$t('Policy No')" header-cell-template="policy_no-header"  cell-template="policy_no-cell-template"/>
                            <DxColumn data-field="installment_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Local')" alignment="right" header-cell-template="installment_amount_local-header" cell-template="installment_amount_local-cell-template"/>
                            <DxColumn data-field="collection_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Collected Local')" alignment="right" header-cell-template="collection_amount_local-header" cell-template="collection_amount_local-cell-template"/>
                            <DxColumn data-field="remain_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Remaining Local')" alignment="right" header-cell-template="remain_amount_local-header" cell-template="remain_amount_local-cell-template"/>
                            <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template"/>
                            <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>
                            <DxColumn data-field="installment_amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="installment_amount-cell-template"/>
                            <DxColumn data-field="collection_amount" :sorting-method="this.$numericSort" :caption="$t('Amount Collected')" alignment="right" header-cell-template="collection_amount-header" cell-template="collection_amount-cell-template"/>
                            <DxColumn data-field="remain_amount" :sorting-method="this.$numericSort" :caption="$t('Amount Remaining')" alignment="right" header-cell-template="remain_amount-header" cell-template="remain_amount-cell-template"/>
                            
                            <template #headerLocalTemplate="{ data: data }">
                                <div v-html="this.$parent.setColumnTemplateForLocal(data)"></div>
                            </template>

                            <!-- Header Cell Templates -->
                            <template #installment_no-header>
                                <div>{{ $t('Installment') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #policy_no-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #installment_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #remain_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Remaining') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #collection_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Collected') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #remain_amount-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Remaining') }}</div>
                            </template>
                            <template #collection_amount-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Collected') }}</div>
                            </template>
                            <template #producer_policy_type_name-header>
                                <div>{{ $t('Producer') }}</div><div>{{ $t('Policy Type') }}</div>
                            </template>
                            <template #policy_type_name-header>
                                <div>{{ $t('Regulation') }}</div><div>{{ $t('Type') }}</div>
                            </template>

                            <!-- Cell Templates -->
                            <template #due_date-cell-template="{ data }">
                                <span>{{ this.$parent.previewDate(data.data.due_date) }}</span>
                            </template>
                            <template #installment_no-cell-template="{ data }">
                                <span>{{ data.data.installment_no }}</span>
                            </template>
                            <template #producer_name-cell-template="{ data }">
                                <span>{{ data.data.producer_name }}</span>
                            </template>
                            <template #producer_policy_type_name-cell-template="{ data }">
                                <span>{{ data.data.producer_policy_type_name }}</span>
                            </template>
                            <template #policy_type_name-cell-template="{ data }">
                                <span>{{ data.data.policy_type_name }}</span>
                            </template>
                            <template #policy_no-cell-template="{ data }">
                                <span>{{ data.data.policy_no }}</span>
                            </template>
                            <template #installment_amount_local-cell-template="{ data }">
                                <span>{{ data.data.installment_amount_local }}</span>
                            </template>
                            <template #collection_amount_local-cell-template="{ data }">
                                <span>{{ data.data.collection_amount_local }}</span>
                            </template>
                            <template #remain_amount_local-cell-template="{ data }">
                                <span>{{ data.data.remain_amount_local }}</span>
                            </template>
                            <template #currency-cell-template="{ data }">
                                <span>{{ data.data.currency }}</span>
                            </template>
                            <template #rate-cell-template="{ data }">
                                <span>{{ data.data.rate }}</span>
                            </template>
                            <template #installment_amount-cell-template="{ data }">
                                <span>{{ data.data.installment_amount }}</span>
                            </template>
                            <template #collection_amount-cell-template="{ data }">
                                <span>{{ data.data.collection_amount }}</span>
                            </template>
                            <template #remain_amount-cell-template="{ data }">
                                <span>{{ data.data.remain_amount }}</span>
                            </template>
                            
                            <DxSummary :calculate-custom-summary="setDetailSummaries">
                                <DxTotalItem
                                    name="installment_amount"
                                    summary-type="custom"
                                    show-in-column="installment_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="installment_amount_local"
                                    summary-type="custom"
                                    show-in-column="installment_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="collection_amount"
                                    summary-type="custom"
                                    show-in-column="collection_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="collection_amount_local"
                                    summary-type="custom"
                                    show-in-column="collection_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="remain_amount"
                                    summary-type="custom"
                                    show-in-column="remain_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="remain_amount_local"
                                    summary-type="custom"
                                    show-in-column="remain_amount_local"
                                    display-format="{0}"
                                />
                            </DxSummary>
                        </DxDataGrid>   
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                    <div class="card-header">
                        <div class="card-title">
                            {{ $t('Payment Plan') }}
                        </div>
                    </div>
                    <div class="card-body">
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                            key-expr="currency" 
                            :data-source="paymentPlanTabList" 
                            :allow-column-reordering="true"  
                            :allow-column-resizing="true" 
                            :column-auto-width="true" 
                            :row-alternatin-enabled="true"
                            :hover-state-enabled="true" 
                            :show-row-lines="true" 
                            :ref="'dataGrid'"
                            :customize-columns="onCustomizePaymentColumns"
                            @exporting="this.$onExporting($event , 'Payment-plan')"> 

                            <DxFilterRow :visible="false"/>
                            <DxHeaderFilter :visible="false"/>
                            <DxGroupPanel :visible="false"/>      
                            <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                            <DxPaging :page-size="10000"/>
                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                            <DxSearchPanel :visible="false"/>
                            <DxScrolling
                                mode="virtual"
                                row-rendering-mode="virtual"
                            />
                            <DxColumn data-field="due_date" :caption="$t('Due')" alignment="right" cell-template="due_date-cell-template"/>
                            <DxColumn data-field="installment_no" :caption="$t('Installment No')" alignment="right" header-cell-template="installment_no-header" cell-template="installment_no-cell-template"/>
                            <DxColumn data-field="producer_name" :caption="$t('Producer')" alignment="right" cell-template="producer_name-cell-template"/>
                            <DxColumn data-field="producer_policy_type_name" :caption="$t('Producer Policy Type')" header-cell-template="producer_policy_type_name-header" cell-template="producer_policy_type_name-cell-template"/>
                            <DxColumn data-field="policy_type_name" :caption="$t('Policy Type')" header-cell-template="policy_type_name-header" cell-template="policy_type_name-cell-template"/>
                            <DxColumn data-field="policy_no" :caption="$t('Policy No')" alignment="right" header-cell-template="policy_no-header" cell-template="policy_no-cell-template"/>
                            <DxColumn data-field="installment_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Local')" alignment="right" header-cell-template="installment_amount_local-header" cell-template="installment_amount_local-cell-template"/>
                            <DxColumn data-field="payment_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Payable Local')" alignment="right" header-cell-template="payment_amount_local-header" cell-template="payment_amount_local-cell-template"/>
                            <DxColumn data-field="remain_amount_local" :sorting-method="this.$numericSort" :caption="$t('Amount Remaining Local')" alignment="right" header-cell-template="remain_amount_local-header" cell-template="remain_amount_local-cell-template"/>
                            <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template"/>
                            <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>
                            <DxColumn data-field="installment_amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="installment_amount-cell-template"/>
                            <DxColumn data-field="payment_amount" :sorting-method="this.$numericSort" :caption="$t('Amount Payable')" alignment="right" header-cell-template="payment_amount-header" cell-template="payment_amount-cell-template"/>
                            <DxColumn data-field="remain_amount" :sorting-method="this.$numericSort" :caption="$t('Amount Remaining')" alignment="right" header-cell-template="remain_amount-header" cell-template="remain_amount-cell-template"/>

                            <template #headerLocalTemplate="{ data: data }">
                                <div v-html="this.$parent.setColumnTemplateForLocal(data)"></div> 
                            </template>

                            <!-- Header Cell Templates -->
                            <template #installment_no-header>
                                <div>{{ $t('Installment') }}</div><div>{{ $t('No') }}</div>
                            </template>
                            <template #installment_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Local') }}</div>
                            </template>
                            <template #remain_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Remaining Local') }}</div>
                            </template>
                            <template #payment_amount_local-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Payable Local') }}</div>
                            </template>
                            <template #remain_amount-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Remaining') }}</div>
                            </template>
                            <template #payment_amount-header>
                                <div>{{ $t('Amount') }}</div><div>{{ $t('Payable') }}</div>
                            </template>
                            <template #producer_policy_type_name-header>
                                <div>{{ $t('Producer') }}</div><div>{{ $t('Policy Type') }}</div>
                            </template>
                            <template #policy_type_name-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('Type') }}</div>
                            </template>
                            <template #policy_no-header>
                                <div>{{ $t('Policy') }}</div><div>{{ $t('No') }}</div>
                            </template>

                            <!-- Cell Templates -->
                            <template #due_date-cell-template="{ data }">
                                <span>{{ this.$parent.previewDate(data.data.due_date) }}</span>
                            </template>
                            <template #installment_no-cell-template="{ data }">
                                <span>{{ data.data.installment_no }}</span>
                            </template>
                            <template #producer_name-cell-template="{ data }">
                                <span>{{ data.data.producer_name }}</span>
                            </template>
                            <template #producer_policy_type_name-cell-template="{ data }">
                                <span>{{ data.data.producer_policy_type_name }}</span>
                            </template>
                            <template #policy_type_name-cell-template="{ data }">
                                <span>{{ data.data.policy_type_name }}</span>
                            </template>
                            <template #policy_no-cell-template="{ data }">
                                <span>{{ data.data.policy_no }}</span>
                            </template>
                            <template #installment_amount_local-cell-template="{ data }">
                                <span>{{ data.data.installment_amount_local }}</span>
                            </template>
                            <template #payment_amount_local-cell-template="{ data }">
                                <span>{{ data.data.payment_amount_local }}</span>
                            </template>
                            <template #remain_amount_local-cell-template="{ data }">
                                <span>{{ data.data.remain_amount_local }}</span>
                            </template>
                            <template #currency-cell-template="{ data }">
                                <span>{{ data.data.currency }}</span>
                            </template>
                            <template #rate-cell-template="{ data }">
                                <span>{{ data.data.rate }}</span>
                            </template>
                            <template #installment_amount-cell-template="{ data }">
                                <span>{{ data.data.installment_amount }}</span>
                            </template>
                            <template #payment_amount-cell-template="{ data }">
                                <span>{{ data.data.payment_amount }}</span>
                            </template>
                            <template #remain_amount-cell-template="{ data }">
                                <span>{{ data.data.remain_amount }}</span>
                            </template>
                            
                            
                            <DxSummary :calculate-custom-summary="setDetailSummaries">
                                <DxTotalItem
                                    name="installment_amount"
                                    summary-type="custom"
                                    show-in-column="installment_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="remain_amount"
                                    summary-type="custom"
                                    show-in-column="remain_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="payment_amount"
                                    summary-type="custom"
                                    show-in-column="payment_amount"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="installment_amount_local"
                                    summary-type="custom"
                                    show-in-column="installment_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="remain_amount_local"
                                    summary-type="custom"
                                    show-in-column="remain_amount_local"
                                    display-format="{0}"
                                />
                                <DxTotalItem
                                    name="payment_amount_local"
                                    summary-type="custom"
                                    show-in-column="payment_amount_local"
                                    display-format="{0}"
                                />
                            </DxSummary>
                        </DxDataGrid>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling, DxTotalItem, DxSummary } from 'devextreme-vue/data-grid';
export default {
    name: 'CollectionAndPayments',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxPaging, DxPager, DxColumn, DxScrolling, DxTotalItem, DxSummary
    },
    data() {
        return {
            collectionPlanTabList: [],
            paymentPlanTabList: [],
        }
    },
    methods: {        
        getData() {
            const parameters = {
                session_id: this.$getUser().session_id,
                policy_card_id: this.$getSessionStorage('record_id')
            };            
            const requestOne =  axios.post('/api/Insurance/GetCollectionPlans', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Insurance/GetPaymentPlans', parameters, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.collectionPlanTabList= responses[0].data;
                this.paymentPlanTabList = responses[1].data; 
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        onCustomizePaymentColumns(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {               
                        if(column.dataField == 'installment_amount_local'){ 
                            column.caption =  'Amount Local';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'payment_amount_local'){ 
                            column.caption =  'Amount Payable Local';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'remain_amount_local'){ 
                            column.caption =  'Amount Remaining Local';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
        onCustomizeCollectionColumns(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {               
                        if(column.dataField == 'installment_amount_local'){ 
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'collection_amount_local'){ 
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'remain_amount_local'){ 
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
        setDetailSummaries(options){
            const optionNames = ["installment_amount", "installment_amount_local", "collection_amount", "collection_amount_local", "remain_amount", "remain_amount_local", "payment_amount", "payment_amount_local"]; 
            optionNames.forEach(item => {
                if(options.name == item){
                    if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                    else if(options.summaryProcess === 'calculate'){
                        var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                        (options.totalValue += itemValue);
                    }
                    else if(options.summaryProcess === 'finalize'){ 
                        options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                    }
                }
            });
        },
    },
    created() {
        this.getData();
    }
}
</script>