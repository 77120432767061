<template>
    <div class="card border bg-light-primary">
        <div class="row card-body p-1">
            <div class="col-lg-6 mb-3">
                <label class="fs-6 fw-bold form-label mt-3">{{ $t('Search Address') }}</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="address_code" v-model="searchPostCode" @keyup.enter="find()" placeholder="Type address or postcode to search">
                    <button type="button" class="input-group-text m-0 cursor-pointer text-hover-info" id="basic-addon2" data-kt-indicator="on"
                        @click="find()" :disabled="!canSearch">
                        <span v-if="!searching"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                        <span v-if="searching">{{ $t('Searching...') }}</span> 
                    </button>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <label class="fs-6 fw-bold form-label mt-3">{{ $t('Address List') }}</label>
                <Select2 id="selected_address_combobox" v-model="selectedAddress" :options="searchResults" name="selected_address_combobox" @select="selectAddress($event)" :disabled="searching || searchResults.length == 0"/>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';

export default {
    name: "GetAddress",
    props:{
        accountType: Number,
        isNewCompany: Boolean,
        controllerId: Object,
        format: {
            type: Boolean,
            required: false,
            default: true,
        },
        sort: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data() {
        return {
            selectedAddress: '-1',
            searchPostCode: null,
            searchResults: [{id: '-1', text: 'Select an address', address: null, url: null}],
            selectedResult: null,
            searchLat: null,
            searchLong: null,
            searching: false,
            addressDetail: {
                fullAddress: null,
                formatted_address:{},
                latitude: 0,
                longitude: 0,
                residential: false,
                building_name: null,
                building_number: null,
                country: null,
                county: null,
                district: null,
                line_1: null,
                line_2: null,
                line_3: null,
                line_4: null,
                locality: null,
                postcode: null,
                sub_building_name: null,
                sub_building_number: null,
                thoroughfare: null,
                town_or_city: null,
            },
            routeName: "GetCompanyAccounts"
        }
    },
    computed: {
        canSearch: function() {
            if(this.searchPostCode !== null && this.searchPostCode.length > 0 )
            {
                return true;
            }

            return false;
        }
    },
    watch: {
      searchPostcode: function(value){
          if(!value) {
            this.searchPostcode = this.formatPostcode(value);
          }
      }
    },
    methods: {
        dailyUsage: function(){

        },
        find: function(){
            this.searching = true
            this.selectedAddress = '-1';
            const that = this;
            let postcode = this.searchPostCode.toLowerCase().replace(/\s/g,'');
            let parameters = {
                PostCode: postcode,
                SessionId: this.$getUser().session_id,
                SourceType: this.accountType
            };  
            if(this.isNewCompany) {
                this.routeName = 'NewCompany';
                parameters = {
                    pc: postcode,
                    cid: this.controllerId
                };
            }
            axios.post('/api/' + this.routeName + '/GetAddress', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.searchResults = response.data.suggestions;
                    this.searchResults.forEach(element => { element.text = element.address; });
                    this.searchResults.unshift({id: '-1', text: 'Select an address', address: null, url: null});
                    
                    var $optionBox = $("#selected_address_combobox").select2();
                    setTimeout(() => {$optionBox.select2("open");}, 500);
                }
            })
            .catch(function (error) {
                console.log(error);
                that.searching = false;
            })
            .finally(function () {
                that.searching = false;
            });
        },
        formatPostcode: function(value){
            value = value.toUpperCase();
            if(value !== null){
                var parts = value.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
                if(parts !== null){
                    parts.shift();
                    return parts.join(' ');
                }
                return value;
            }
        },
        selectAddress: function(event){
            this.searchPostCode = null;
            let parameters = {
                id: event.id,
                SessionId: this.$getUser().session_id
            };  
            
            if(this.isNewCompany) {
                this.routeName = 'NewCompany';
                parameters = {
                    id: event.id,
                    cid: this.controllerId
                };
            }
            axios.post('/api/' + this.routeName + '/GetAddressDetail', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.addressDetail = response.data;
                    this.addressDetail.fullAddress = response.data.formatted_address.length > 0 ? (response.data.formatted_address.filter(i => i !== "")).toString() + ',' + response.data.postcode : "";
                    this.$emit('address-selected', this.addressDetail);
                }
            })
            .catch(function (error) {
               console.log(error);
            });
        }
    }
}
</script>
<style>
.vue_get_address_io_wrapper{
    min-width: 100%;
}

.vue_get_address_io_wrapper .search_input{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.vue_get_address_io_wrapper .search_input input{
    flex: 3;
}
.vue_get_address_io_wrapper .search_input button{
    flex: 1;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #4BB543;
    background-color: #4BB543;
    color: #ffffff;
}

.vue_get_address_io_wrapper .search_results{
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>