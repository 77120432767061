<template>
    <div>
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Update All Selected Data</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeUpdateSelectedModal" @click="onCloseModal()">
                        <span class="svg-icon svg-icon-1 svg-icon-light text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-7">  
                    <form id="kt_ecommerce_settings_general_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                         <div class="alert alert-primary d-flex align-items-center p-3 mb-3">
                            <i class="ki-duotone ki-shield-tick fs-2hx text-success me-4"><span class="path1"></span><span class="path2"></span></i>
                            <div class="d-flex flex-column">
                                <h4 class="mb-1 text-gray-800">{{ $t('Attention!') }}</h4>
                                <span>{{ $t('This update operation will update all Policy/Sources, Branch and Vendor information for selected transactions according to this informations.') }}</span>
                            </div>
                        </div>
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300 mt-3" :class="{ 'bg-white': this.updateForm.is_save_vendor == true }">
                            <div class="card-header min-h-40px mt-3">
                                <div class="card-title">
                                    <h5>
                                        <input class="form-check-input fw-bold form-label" type="checkbox" v-model="updateForm.is_save_vendor" /> 
                                        <span class="ms-3">{{ $t('Vendor') }}</span>
                                    </h5>
                                </div>
                                <div class="d-flex justify-content-end mt-5">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="is_apply_all_vendor_info" v-model="updateForm.is_apply_all_vendor_info" :disabled="!updateForm.is_save_vendor" >
                                        <label class="fs-6 fw-bold form-label" for="is_apply_all_vendor_info">
                                            {{ $t('Apply All') }}
                                        </label>
                                    </div> 
                                </div>
                            </div>
                            <TransactionMultiVendorModal v-if="this.$parent.isViewMultiUpdateModal" ref="TransactionMultiVendorModal"></TransactionMultiVendorModal>
                        </div>
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300 mt-3" :class="{ 'bg-white': this.updateForm.is_save_other_info == true }">
                            <div class="card-header min-h-40px mt-3">
                                <div class="card-title">                                  
                                    <h5>
                                        <input class="form-check-input fw-bold form-label" type="checkbox" v-model="updateForm.is_save_other_info" @change="updateForm.is_apply_all_other_info = false, updateForm.is_clear_all_other_info = false" /> 
                                        <span class="ms-3">{{ $t('Policy/Sources') }}</span>
                                    </h5>
                                </div>                                
                                <div class="d-flex justify-content-end mt-5">
                                    <div class="form-check me-5">
                                        <input class="form-check-input" type="checkbox" value="" id="is_apply_all_other_info" v-model="updateForm.is_apply_all_other_info" 
                                            :disabled="!updateForm.is_save_other_info || updateForm.is_clear_all_other_info">
                                        <label class="fs-6 fw-bold form-label" for="is_apply_all_other_info">
                                            {{ $t('Apply All') }}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="is_clear_all_other_info" v-model="updateForm.is_clear_all_other_info" @change="onChangeClearAll(1)"
                                            :disabled="!updateForm.is_save_other_info || updateForm.is_apply_all_other_info">
                                        <label class="fs-6 fw-bold form-label" for="is_clear_all_other_info">
                                            {{ $t('Clear All') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-7"> 
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label class="fw-bold form-label mt-3">
                                            <span> {{ $t('Sales Channel') }}</span>
                                        </label>
                                        <Select2 v-model="updateForm.sales_channel_id" :disabled="!updateForm.is_save_other_info" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fw-bold form-label mt-3">
                                            <span>{{ $t('Sales Role / Campaign') }}</span>
                                        </label>
                                        <Select2 v-model="updateForm.campaign_id" :disabled="!updateForm.is_save_other_info" :options="campaignList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fw-bold form-label mt-3">
                                            <span>{{ $t('Source Code') }} 1</span>
                                        </label>
                                        <Select2 v-model="updateForm.source_code" :disabled="!updateForm.is_save_other_info" :options="sourceCodeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="fw-bold form-label mt-3">
                                            <span>{{ $t('Source Code') }} 2</span>
                                        </label>
                                        <Select2 v-model="updateForm.source_code_2" :disabled="!updateForm.is_save_other_info" :options="sourceCode2List" :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-xxl-stretch bg-lighten border border-gray-300 mt-3" :class="{ 'bg-white': this.updateForm.is_save_branch == true }">
                            <div class="card-header min-h-40px mt-3">
                                <div class="card-title">
                                    <h5>
                                        <input class="form-check-input fw-bold form-label" type="checkbox" v-model="updateForm.is_save_branch" @change="updateForm.is_apply_all_branch = false, updateForm.is_clear_all_branch = false" /> 
                                        <span class="ms-3">{{ $t('Branch') }}</span>
                                    </h5>
                                </div>
                                <div class="d-flex justify-content-end mt-5">
                                    <div class="form-check me-5">
                                        <input class="form-check-input" type="checkbox" value="" id="is_apply_all_branch" v-model="updateForm.is_apply_all_branch" 
                                            :disabled="!updateForm.is_save_branch || updateForm.is_clear_all_branch">
                                        <label class="fs-6 fw-bold form-label" for="is_apply_all_branch">
                                            {{ $t('Apply All') }}
                                        </label>
                                    </div> 
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="is_clear_all_branch" v-model="updateForm.is_clear_all_branch" @change="onChangeClearAll(2)"
                                            :disabled="!updateForm.is_save_branch || updateForm.is_apply_all_branch">
                                        <label class="fs-6 fw-bold form-label" for="is_clear_all_branch">
                                            {{ $t('Clear All') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-7"> 
                                <div class="row"> 
                                    <div class="col-lg-12 fv-row fv-plugins-icon-container">
                                        <Select2 v-model="updateForm.branch_id" :options="generalBranchList" :disabled="!updateForm.is_save_branch" name="branch" v-custom 
                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_update_selected_data .modal-content' }"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="col-lg-12 mt-4">
                            <button class="btn btn-block w-100 btn-sm btn-success" type="button" @click="onSaveAllSelectedData()"> 
                                <span class="svg-icon svg-icon-primary svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="currentColor"/>
                                <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="currentColor"/>
                                <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="currentColor"/>
                                </svg>
                                </span> 

                                {{ $t('UPDATE ALL SELECTED DATA') }} <strong> ({{ this.$parent.selectedRowKeys.length }}) </strong>

                                <div class="spinner-border text-muted ms-3" role="status" v-if="isDataUpdating">
                                    <span class="visually-hidden">{{ $t('Updating') }}...</span>
                                </div>
                            </button>
                        </div>
                    </form> 
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import TransactionMultiVendorModal from '../Modals/TransactionMultiVendorModal.vue';
export default {
    name:'MultiTransactionUpdateModal',
    props: {
        tax: Object
    },
    components:{
        TransactionMultiVendorModal
    },
    data(){
        return {
            isDataUpdating: false,
            updateForm: {
                branch_id: null,
                vendor_id: null,
                sales_channel_id: null,
                campaign_id: null,
                source_code: null,
                source_code_2: null,
                is_save_other_info: false,
                is_save_vendor: false,
                is_save_branch: false,
                is_default: false,
                is_apply_all_branch: false,
                is_apply_all_vendor_info: false,
                is_apply_all_other_info: false,
                is_clear_all_branch: false,
                is_clear_all_other_info: false,
            },
            campaignList: [],
            sourceCodeList: [],
            sourceCode2List: [],
            salesChannelList: []
        }
    },
    methods:{
        getData(){
            if(!this.$parent.isViewMultiUpdateModal) return;
            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["account_setting", "sales_channel", "country"],
                country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
                account_type: 1, // yalnızca account settings listesi çekilecek ise gerekli
                supplier_account_type: 2
            }; 
            const requestOne =  axios.post('/api/Branch/BranchList', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/GetCompanyAccounts/GetPolicyAccounts', parameters, {'Content-Type': 'application/json'});

            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                this.generalBranchList = responses[0].data;
                this.generalVendorList = responses[1].data;
                this.generalVendorList.unshift({ id: '-1', text: 'Select an option'});
                this.updateForm.branch_id = '-1';
                this.updateForm.vendor_id = '-1';
                this.updateForm.sales_channel_id = '-1';
                this.updateForm.campaign_id = '-1';
                this.updateForm.source_code = '-1';
                this.updateForm.source_code_2 = '-1';
                this.updateForm.is_save_other_info = false;
                this.updateForm.is_save_vendor = false;
                this.updateForm.is_save_branch = false;
                this.updateForm.is_apply_all_branch = false;
                this.updateForm.is_apply_all_vendor_info = false;
                this.updateForm.is_apply_all_other_info = false;
                this.updateForm.is_clear_all_branch = false;
                this.updateForm.is_clear_all_other_info = false;
                this.$refs.TransactionMultiVendorModal.getData();
                this.getSourceLists();
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getSourceLists() {
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["campaign", "sales_channel"]
            };  
            const parametersTwo = { 
                session_id: this.$getUser().session_id,
                list_type: 7,
                main_branch_id: null,
                policy_branch_id: null
            };  
            const parametersThree = { 
                session_id: this.$getUser().session_id,
                list_type: 8,
                main_branch_id: null,
                policy_branch_id: null
            };  
            const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
            const requestTwo =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersTwo, {'Content-Type': 'application/json'});
            const requestThree =  axios.post('/api/Helper/GetPolicyListBySubLists', parametersThree, {'Content-Type': 'application/json'});
            axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.campaignList = responses[0].data.campaign;
                this.salesChannelList = responses[0].data.sales_channel;
                this.sourceCodeList = responses[1].data;
                this.sourceCode2List = responses[2].data;
                this.campaignList.unshift({ id: '-1', text: 'Select an option'});
                this.salesChannelList.unshift({ id: '-1', text: 'Select an option'});
                this.sourceCodeList.unshift({ id: '-1', text: 'Select an option'});
                this.sourceCode2List.unshift({ id: '-1', text: 'Select an option'});
            }))
            .catch(errors => { 
                console.error(errors);
            });
        },
        onSaveAllSelectedData(){
            if(!(this.updateForm.is_save_vendor || this.updateForm.is_save_branch || this.updateForm.is_save_other_info)){
                this.$swal("Info", "No data sections have been checked. Nothing to save..!", 'info');
                return;
            }
            this.isDataUpdating = false;
            const that = this;

            this.$swal.fire({
                title: "Are you sure you want to continue? Selected data will be updated..!",
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.isDataUpdating = true;
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        tmp_session_id: this.$getSessionStorage('mvn_tmp_session_id'),
                        transactionIds: this.$parent.selectedRowKeys,
                        branch_id: this.updateForm.branch_id == '-1' ? null : this.updateForm.branch_id,
                        sales_channel_id: this.updateForm.sales_channel_id == '-1' ? null : this.updateForm.sales_channel_id,
                        campaign_id: this.updateForm.campaign_id == '-1' ? null : this.updateForm.campaign_id,
                        source_code: this.updateForm.source_code == '-1' ? null : this.updateForm.source_code,
                        source_code_2: this.updateForm.source_code_2 == '-1' ? null : this.updateForm.source_code_2,
                        is_save_branch: this.updateForm.is_save_branch ? 1 : 0,
                        is_save_other_info: this.updateForm.is_save_other_info ? 1 : 0,
                        is_save_vendor_info: this.updateForm.is_save_vendor ? 1 : 0,
                        is_apply_all_branch: this.updateForm.is_apply_all_branch ? 1 : 0,
                        is_apply_all_vendor_info: this.updateForm.is_apply_all_vendor_info ? 1 : 0,
                        is_apply_all_other_info: this.updateForm.is_apply_all_other_info ? 1 : 0,
                        is_clear_all_branch: this.updateForm.is_clear_all_branch ? 1 : 0,
                        is_clear_all_other_info: this.updateForm.is_clear_all_other_info ? 1 : 0,
                    };  
                    axios.post('/api/DLLTemplates/MultiUpdateVendors', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.$refs.closeUpdateSelectedModal.click(); 
                            this.isDataUpdating = false; 
                            this.$parent.getRecordedTransactions();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        that.isDataUpdating = false; 
                    });
                }         
            });  
        },
        onCloseModal() {
            this.$parent.isViewMultiUpdateModal = false;
        },
        onChangeClearAll(type) {
            if(type == 1 && this.updateForm.is_clear_all_other_info) {
                this.updateForm.sales_channel_id = '-1';
                this.updateForm.campaign_id = '-1';
                this.updateForm.source_code = '-1';
                this.updateForm.source_code_2 = '-1';
            }
            
            if(type == 2 && this.updateForm.is_clear_all_branch) {
                this.updateForm.branch_id = '-1';
            }
        }
    }
}
</script>