<template>
    <div class="modal-dialog modal-dialog-centered modal-xxl">
        <div class="modal-content"  >
            <div class="modal-header bg-primary py-3">
                <h2 class="modal-title fw-bolder col-md-10 text-white">
                    <span>{{ $t('Search Accounts') }}</span>
                </h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeSearchModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body p-5 scroll-y" :class="{'overlay overlay-block': this.isLoading == true}">
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="fv-row row d-flex justify-content-end">
                                        <div class="col-lg-4">
                                            <label class="fs-6 fw-bold form-label">                                                
                                                <span>{{ $t('Account Name') }}</span>
                                            </label>
                                            <input type="search" class="form-control form-control-lg" name="accountName" v-model="searchText" @keyup.enter="getAccounts(searchText)" :placeholder="$t('Type at least 3 characters to search account')">
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="d-flex justify-content-end mt-6" data-kt-customer-table-toolbar="base">
                                                <button type="button" class="btn btn-primary border border-primary me-3" @click="getAccounts(searchText)" :disabled="searchText.length < 3">
                                                    <i class="fa fa-search"></i>
                                                    {{ $t('Search') }}
                                                </button>
                                                <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light border border-gray-400 me-3" @click="getAccounts('')" data-kt-indicator="on">
                                                    <span class="svg-icon svg-icon-muted">
                                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    <span> {{ $t('All Accounts') }}</span>
                                                </button>

                                                <!-- <button ref="createAccountButton" class="btn btn-light-success border border-success btn-sm justify-content-end" @click="openCreateNewModal()">
                                                    <span class="svg-icon svg-icon-success svg-icon-2">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Create New') }}
                                                </button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-lg-8 col-lg-12 mt-5">
                        <div class="card h-xl-100">
                            <div class="card-body py-3">
                                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div class="table-responsive min-h-300px">
                                        <DxDataGrid id="gridContainer" 
                                            :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="accountList" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true" 
                                            :ref="'dataGrid'"
                                            @exporting="this.$onExporting($event , 'Search-Account')" > 
                                            
                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>      
                                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                            <DxPaging :page-size="10"/>
                                            <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                            <DxSearchPanel :visible="true"/> 
                                            
                                            <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="left" :allow-exporting="false" width="100"/>
                                            <!-- <DxColumn data-field="account_number" :caption="$t('Registration Number')" header-cell-template="account_number-header" alignment="left" cell-template="account_number-cell-template"/>  -->
                                            <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 
                                            <!-- <DxColumn data-field="balance" :sorting-method="this.$numericSort" :caption="$t('Balance')" alignment="right" cell-template="balance-cell-template"/> -->
                                            <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/> 
                                            <!-- <DxColumn data-field="company_account_type_name" :caption="$t('Company Type')" header-cell-template="company_account_type_name-header" alignment="left" cell-template="company_account_type_name-cell-template"/> -->
                                            <DxColumn data-field="account_type_name" :caption="$t('Account Type')" header-cell-template="account-type-header" alignment="left" cell-template="account_type_name-cell-template"/>

                                            <template #account-type-header>
                                                <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                            </template>
                                            <!-- <template #company_account_type_name-header>
                                                <div>{{ $t('Company') }}</div><div>{{ $t('Type') }}</div>
                                            </template>
                                            <template #account_number-header>
                                                <div>{{ $t('Registration') }}</div><div>{{ $t('Number') }}</div>
                                            </template> -->

                                            <template #detail-cell-template="{ data }">                                            
                                                <button type="button" class="btn btn-xs btn-primary" @click="onSelectAccount(data.data)">Select</button>
                                            </template>
                                            <template #account_type_name-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                    {{ data.data.account_type_name }}
                                                </span>
                                            </template>
                                            <template #account_id-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-hover-primary cursor-pointer mb-1">
                                                    <div class="text-gray-900">
                                                        {{ data.data.account_id }} 
                                                    </div>
                                                </span>
                                            </template>
                                            <template #name-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-gray-800 fw-bold text-hover-primary cursor-pointer mb-1">
                                                    {{ data.data.name }}
                                                </span>
                                            </template>
                                            <!-- <template #account_number-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-gray-800 fw-bold text-hover-primary cursor-pointer mb-1">
                                                    {{ data.data.account_number }}
                                                </span>
                                            </template> -->
                                            <!-- <template #company_account_type_name-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                    {{ data.data.company_account_type_name }}
                                                </span>
                                            </template> -->
                                            <!-- <template #balance-cell-template="{ data }">
                                                <span @click="getStockTrackingGroups(data.data)" class="text-gray-800 text-hover-primary cursor-pointer mb-1">
                                                    {{ data.data.balance }}
                                                </span>
                                            </template> -->

                                            <DxSummary>
                                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="detail"/>
                                            </DxSummary>
                                        </DxDataGrid>   
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isLoading == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Searching Accounts') }}...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
export default {
    name: 'SearchOperationAccountWithSelect',
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem
    },
    data() {
        return {
            accountList: [],
            stockTrackingListItems: [],
            isLoading: false,
            searchText: '',
            transactionType: null,
            chequePosition: null,
        }
    },
    computed: {
        showPageSizeSelector() {
            return this.accountList.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = (this.accountList.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(100);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    methods: {
        getData(transaction_type, cheque_position, search_text) {
            this.accountList = [];
            this.searchText = search_text ? search_text : '';
            this.isLoading = false;
            this.transactionType = transaction_type;
            this.chequePosition = cheque_position;
            if((search_text == null || search_text == undefined || search_text.length < 3) && !this.$getUser().is_allow_list_all_data) return;
            if(this.searchText) { this.getAccounts(search_text); }
        },
        getAccounts(search_text) {
            if((search_text == null || search_text == undefined || search_text.length < 3) && !this.$getUser().is_allow_list_all_data) return;
            this.accountList = [];
            this.searchText = (search_text !== null && search_text !== undefined && search_text !== '') ? search_text : '';
            this.isLoading = true;
            const params = { 
                session_id: this.$getUser().session_id,
                docket_sub_type: this.transactionType,
                cheque_position: this.chequePosition,
                search_text: this.searchText,
            };  
            const that = this;
            axios.post('api/Cheque/GetAccountsChequeOperation', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.accountList = response.data;
            })
            .catch(function() {
                that.isLoading = false;
            })
            .finally(function () {
                that.isLoading = false;
            });
        },
        onSelectAccount(item) {            
            this.$refs.closeSearchModal.click();
            this.$parent.getAccountDetail(item.id);
            this.$parent.isViewSearchStockAccountModal = false;
        },
        onCloseModal() {
            this.$parent.isViewSearchStockAccountModal = false;
        },
        openCreateNewModal() {
            this.$parent.openCreateContactModal();   
        }
    }
}
</script>