<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title d-flex flex-column me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">Transactions</h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">Home</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-gray-600">Reports</li>     
                <li class="breadcrumb-item text-gray-600">Transactions</li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">                        
                    <div class="fv-row mb-7 row">
                        <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>Start Date</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                        <div class="col-lg-6">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>End Date</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> List Transactions </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover table-responsive">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"> 
                                    <th style="width: 1% !important;">#</th>
                                    <th><small>Record Date</small></th>
                                    <th><small>Account</small></th>
                                    <th><small>Transaction Type</small></th>
                                    <th><small>Transaction Subtype</small></th>
                                    <th><small>Transaction No</small></th>
                                    <th><small>Transaction Date</small></th>
                                    <th><small>Transaction Due Date</small></th>
                                    <th><small>Currency</small></th>
                                    <th class="text-end"><small>Rate</small></th>
                                    <th class="text-end"><small>Amount</small></th>
                                    <th class="text-end"><small>Amount Local</small></th>
                                    <th><small>Type</small></th>
                                    <th><small>Is Accounted?</small></th>
                                </tr>
                            </thead>
                            <tbody class="fw-bold text-gray-600" v-if="this.lists"> 
                                <tr v-for="(item, index) in searchableList" v-bind:key="item"  class="cursor-pointer"> 
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                           <small>{{ ((this.page - 1) * this.page_size) + index + 1 }} </small>
                                        </span>
                                    </td>
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ previewDate(item.record_date) }}
                                        </span>
                                    </td>
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.account_name }}
                                        </span>
                                    </td>  
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.transaction_type_name }}
                                        </span>
                                    </td>
                                    <td class="text-gray-900">
                                        <span class="fw-bolder" :class="{ 'text-danger': item.transaction_subtype == 4 || item.transaction_subtype == 3 }">
                                            {{ item.transaction_subtype_name }} 
                                        </span>
                                    </td>
                                     <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.transaction_no }}
                                        </span>
                                    </td>  
                                   
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ previewDate(item.transaction_date) }}
                                        </span>
                                    </td>   
                                     <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ previewDate(item.transaction_due_date) }}
                                        </span>
                                    </td>
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.currency }}
                                        </span>
                                    </td> 
                                    <td class="text-gray-600 text-end">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.rate }}
                                        </span>
                                    </td>  
                                    <td class="text-gray-600 text-end">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.amount }}
                                        </span>
                                    </td>
                                    <td class="text-gray-600 text-end">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.amount_local }}
                                        </span>
                                    </td> 
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                            {{ item.amount_type }}
                                        </span>
                                    </td>
                                    <td class="text-gray-600">
                                        <span class="text-gray-600 text-hover-primary mb-1">
                                             <span v-if="item.is_accounted">
                                                <i class="las la-check fs-2x text-success"></i>
                                            </span>  
                                            <span v-if="!item.is_accounted">
                                                <i class="las la-times fs-2x text-danger"></i>
                                            </span> 
                                        </span>
                                    </td>
                                </tr> 
                            </tbody>
                           <tbody v-if="!lists || this.lists.length == 0">
                                <tr>
                                    <td colspan="14">
                                        <no-table-results></no-table-results>
                                    </td>
                                </tr>
                            </tbody> 
                            <tfoot class="border-top border-dashed" v-if="this.lists || this.lists.length > 0">
                                <tr> 
                                    <td colspan="14">  
                                        <div class="d-flex w-90px align-items-start justify-content-start justify-content-md-start mb-3">
                                            <select v-model="this.page_size" class="form-select form-select-sm form-select-solid" @change="this.page = 1">
                                                <option>10</option>
                                                <option>20</option>
                                                <option>50</option>
                                                <option>100</option>
                                                <option>200</option>
                                            </select>
                                        </div>
                                        <pagination v-model="page" :records="lists.length" :per-page="this.page_size" @paginate="searchableList"/> 
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>
<script>
import axios from 'axios' 

// import Swal from 'sweetalert2';
import moment from 'moment';
//import parentComponent from '../../../App.vue';
 
export default {
  name: "TransactionList",
  data() {
    return {
        page: 1, 
        page_size: 20,
        lists: [],
        form:  {
            first_date: moment(),
            last_date: moment(),
        },
        isListProgressing: false
    };
  },
  computed: {
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    }
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.isListProgressing = true; 
        const params = {
            session_id: this.$getUser().session_id,
            first_date: this.form.first_date,
            last_date: this.form.last_date,
            is_accounted: '-1',
            ct: 1
        };
        axios.post('/api/Transaction/GetAllTransactionList', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;
            this.page = 1;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    }
  },
  created() { 
    this.$parent.$parent.isFullWidth = true;
    this.getData();
  },
  mounted(){ 
    try{ this.form.first_date = moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'); }
    catch(Err){ console.error(Err);}
  }
}; 
</script>