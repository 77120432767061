<template>
   <div class="min-h-500px" v-cloak> 
    <!-- Show a warning message if no Document Locations defined -->
    <div class="col-lg-12" v-if="this.documentLocations != null && this.documentLocations.length == 0 && isFileManagerReady">
        <div class="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                    <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                </svg>
            </span>
            <div class="d-flex flex-column pe-0 pe-sm-10">
                <h4 class="fw-semibold">Document Location not found.</h4>
                <span>No Document Location has been defined yet.</span>
            </div>
        </div>
    </div>
    <!-- Show a warning message if no Accounts defined on a Transaction window -->
    <div class="col-lg-12" v-if="this.is_transaction == 1 && this.account_a == '-1' && isFileManagerReady">
        <div class="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10">
            <span class="svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor"></path>
                    <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor"></path>
                </svg>
            </span>
            <div class="d-flex flex-column pe-0 pe-sm-10">
                <h4 class="fw-semibold">Account Info not found.</h4>
                <span>Please select the required account on Transaction tab.</span>
            </div>
        </div>
    </div>
    <!-- File Manager Section -->
    <div class="card p-3" v-if="this.documentLocations != null && this.documentLocations.length > 0 && !(this.is_transaction == 1 && this.account_a == '-1' && isFileManagerReady)">
        <div class="col-lg-12"> 
            <div class="row" v-if="this.currentDirectory != null && this.currentDirectory.location_id != null">
                <div class="col-lg-6" v-if="isUploadButton != false">
                    <DxFileUploader
                        accept= "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, image/*"
                        :multiple="multipleOption"
                        :upload-mode="uploadModeOption" 
                        :upload-headers="getUploadHeaders()"
                        :on-uploaded="onUploadCompleted"
                        upload-url="/api/DocumentLocation/Upload"
                        :ref="'fileUploader'" 
                    /> 
                </div>
                <div class="col-lg-6 text-end pt-3" v-if="this.documentSelection !== null && this.documentSelection.isDirectory != true">
                    <DxDropDownButton
                        :items="actionItems"
                        :drop-down-options="{ width: 230 }"
                        text="Document Actions"
                        icon="overflow"
                        @item-click="onActionsClick"
                    />
                </div>
            </div>  
            <DxFileManager
                :ref="'fileManagerRef'"
                :file-system-provider="this.documentLocations"
                :on-current-directory-changed="onDirectoryChanged"
                :on-selection-changed="onSelectionChanged" 
                :selection-mode="single"> 
                <DxPermissions
                    :create="false"
                    :copy="false"
                    :move="false"
                    :delete="false"
                    :rename="false"
                    :upload="false"
                    :download="false" />  
            </DxFileManager>  
        </div>   
    </div>

    <button ref="openDownloadModal" type="button" class="d-none" data-bs-toggle="modal" 
        data-bs-target="#file_download_modal" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
    </button>
    
    <!-- Download Modal -->
    <div class="modal fade" tabindex="-1" id="file_download_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content"  >
              <div class="modal-header">
                  <h2 class="modal-title fw-bolder col-md-10" v-if="this.documentSelection">
                      Download "{{ this.documentSelection.name }}"
                  </h2>                        
                  <div class="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal" aria-label="Close" ref="downloadModal">
                      <span class="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                              <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                  transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                          </svg>
                      </span>
                  </div>
              </div>
              <div class="modal-body scroll-y bg-white"> 
                <div class="row"> 
                  <div class="col-lg-12">
                      <label class="fw-bold form-label mt-3">
                          <span>Download Reason</span>
                      </label>
                      <textarea rows="2" class="form-control " name="description" v-model="downloadForm.download_reason"></textarea>
                  </div> 
              </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary w-100" @click="onDownloadItems()"> Download </button>
              </div>
          </div>
      </div>
    </div> 
   </div>
</template>
<script> 
import axios from 'axios';
import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';
export default {
    name: "FileManager",
    props: {
        location: Object,
        locationtype: Object,
        account_id: Object,
        account_type: Object,
        is_transaction: Object,
        account_a: Object,
        account_b: Object,
        uuid: Object,
        upload_source: Object
    },
    components:{
        DxFileManager, DxPermissions, DxFileUploader, DxDropDownButton
    }, 
    setup() {
    },
    data() {
        return { 
            isFileManagerReady: false,
            isUploadButton : false,
            actionItems: [
                { id: 1, text: 'Download Document', icon: 'save' },
                { id: 2, text: 'Delete Document', icon: 'trash' }
            ],
            documentSelection: null,
            documentLocations: [],
            currentDirectory: {
                location_id: null,
                main_location_id: null,
                document_type_id: null
            }, 
            multipleOption: false,
            uploadModeOption: 'instantly',
            files: [],
            remoteProvider: null,
            downloadForm: {
                download_reason: null
            }
        };
    },
    computed: { 
    },
    methods: {
        getDocumentLocations(){
            const parameters = { 
                session_id: this.$getUser().session_id,
                location: this.location, //Accounts
                location_type: this.locationtype, //Customers
                location_subtype: 0,
                account_id: this.account_id == null ? this.$getSessionStorage('record_id') : this.account_id,
                is_transaction: this.is_transaction == null ? 0 : 1
            }; 
            axios.post('/api/DocumentLocation/GetDocumentLocations', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                    response.data.forEach((item) => {
                        if(item.main_location == null || item.main_location == ''){
                                this.documentLocations.push(
                                {
                                    id: item.id,
                                    name: item.document_type_name,
                                    size: 1024,
                                    dateModified: item.created_at,
                                    isDirectory: true,
                                    main_location: item.main_location,
                                    document_type_id: item.document_type_id,
                                    items: [],
                                }
                            );
                        } 
                    }); 
                    response.data.forEach((responseItem) => {
                        this.documentLocations.forEach((item) => {
                            if(responseItem.main_location == item.id){
                                item.items.push(
                                {
                                    id: responseItem.id,
                                    name: responseItem.document_type_name,
                                    size: 1024,
                                    dateModified: responseItem.created_at,
                                    isDirectory: true,
                                    main_location: responseItem.main_location,
                                    document_type_id: item.document_type_id,
                                    items: [], 
                                }
                              );
                            } 
                        });
                    });
                    if(this.documentLocations.length > 0){
                        this.documentSelection = this.documentLocations[0];
                    } 
                    this.isFileManagerReady = true;
                    this.isUploadButton = false;
                    this.onRefreshFileList();
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });
        }, 
        getUploadHeaders(){
            return { 
                sid: this.$getUser().session_id,
                aid: this.account_id == null ? this.$getSessionStorage('record_id') : this.account_id,
                lcn: this.location,
                lct: this.locationtype,
                lid: this.currentDirectory.location_id,
                mid: this.currentDirectory.main_location_id,
                dti: this.currentDirectory.document_type_id,
                ist: this.is_transaction == null ? 0 : 1,
                aca: this.account_a == '-1' ? null : this.account_a,
                acb: this.account_b == '-1' ? null : this.account_b,
                uid: this.uuid,
                ups: this.upload_source, /* Upload Source */
            };
        },
        onSelectionChanged(e){
            if(e.selectedItems.length > 0){
                this.documentSelection = e.selectedItems[0].dataItem;
            } 
            else{
                this.documentSelection = null;
            }
        },
        onDirectoryChanged(e){
            this.isUploadButton = true;
            if(e){
                try{
                    this.currentDirectory = {
                        location_id: e.directory.dataItem.id,
                        main_location_id: e.directory.dataItem.main_location,
                        document_type_id: e.directory.dataItem.document_type_id,
                    };
                }
                catch(Err){ 
                    this.currentDirectory = {
                        location_id: null,
                        main_location_id: null,
                        document_type_id: null
                    };
                }
                const parameters = { 
                    session_id: this.$getUser().session_id,
                    location_type_id: e.directory.dataItem.id,
                    account_id: this.account_id == null ? this.$getSessionStorage('record_id') : this.account_id,
                    is_transaction: this.is_transaction == null ? 0 : 1,
                    transaction_id: this.uuid
                }; 
                if(parameters.is_transaction == 1 && parameters.transaction_id == null){
                    return;
                }
                axios.post('/api/DocumentLocation/GetDocumentsByLocationID', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                            this.clearDocuments();
                            // Dosyalar iç içe olabilirler. 
                            // Bu durumda bir dosyayı uygun olduğu içeriğine yerleştirmemiz gerekir.  
                            // Öncelikle bu Dokümanlar arraylerden temizlenirler.
                            response.data.forEach((document) => { 
                                // Ana Klasörlerdeki Dokümanlar pushlanır.
                                this.documentLocations.forEach((item) => { 
                                    if(item.id == document.location_id){
                                        item.items.push({
                                        id: document.id, 
                                        fid: document.fid,
                                        name: document.name, 
                                        isDirectory: false, 
                                        size: document.size, 
                                        dateModified: document.created_at,
                                        document_type_id: item.document_type_id,
                                    });
                                    }
                                });
                                // Alt Klasörlerdeki Dokümanlar pushlanır.
                                this.documentLocations.forEach((item) => { 
                                    item.items.forEach((subItem) => { 
                                        if(subItem.id == document.location_id){
                                            subItem.items.push({
                                            id: document.id, 
                                            fid: document.fid,
                                            name: document.name, 
                                            isDirectory: false, 
                                            size: document.size, 
                                            dateModified: document.created_at,
                                            document_type_id: item.document_type_id,
                                        });
                                        }
                                    }); 
                                });
                            }); 

                        this.onRefreshFileList();
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });          
            } 
        },
        onUploadCompleted(e){ 
            const parameters = { 
                session_id: this.$getUser().session_id,
                fid: e.request.responseText,
                location_type_id: this.currentDirectory.location_id,
                account_id: this.account_id == null ? this.$getSessionStorage('record_id') : this.account_id,
                is_transaction: this.is_transaction == null ? 0 : 1,
                transaction_id: this.uuid
            }; 
            axios.post('/api/DocumentLocation/GetDocumentsByLocationID', parameters, {'Content-Type': 'application/json'})
            .then((response) => {  
                this.clearDocuments();
                // Dosyalar iç içe olabilirler. 
                // Bu durumda bir dosyayı uygun olduğu içeriğine yerleştirmemiz gerekir.  
                // Öncelikle bu Dokümanlar arraylerden temizlenirler.
                response.data.forEach((document) => { 
                    // Ana Klasörlerdeki Dokümanlar pushlanır.
                    this.documentLocations.forEach((item) => { 
                        if(item.id == document.location_id){
                                item.items.push({
                                id: document.id, 
                                fid: document.fid,
                                name: document.name, 
                                isDirectory: false, 
                                size: document.size, 
                                dateModified: document.created_at,
                                document_type_id: item.document_type_id,
                            });
                        }
                    });
                    // Alt Klasörlerdeki Dokümanlar pushlanır.
                    this.documentLocations.forEach((item) => { 
                        item.items.forEach((subItem) => { 
                            if(subItem.id == document.location_id){
                                subItem.items.push({
                                id: document.id, 
                                fid: document.fid,
                                name: document.name, 
                                isDirectory: false, 
                                size: document.size, 
                                dateModified: document.created_at,
                                document_type_id: item.document_type_id,
                            });
                            }
                        }); 
                    });
                });
                
                this.documentSelection = null;
                this.onRefreshFileList();
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });
        },  
        clearDocuments(){
            // Temizlerken ilk olarak ikinci seviyelerin items arrayini temizliyoruz. Çünkü ikinci seviyelerde yalnızca doküman olabiliyor.
            this.documentLocations.forEach((item) => { 
                item.items.forEach((subItem) => { 
                    subItem.items = [];
                });
            });
            // Üst seviye klasörlerde Main Location değeri 'null' olanların items'ı içerisinde doküman varsa items arrayini temizliyoruz.
            this.documentLocations.forEach((item) => { 
                var subFolderList = [];
                if(item.main_location == null || item.main_location == ''){
                    item.items.forEach((subItem) => { 
                        if(subItem.isDirectory == true){
                            subFolderList.push(subItem);
                        } 
                    });
                    item.items = [];
                    item.items = subFolderList;
                } 
            });
        },
        onDownloadItems(){   
            if(this.documentSelection == null){
                    return;
            }
            const config = { 
                'Content-type': 'application/json',
                responseType: 'blob',
                headers: {
                    sid: this.$getUser().session_id,
                    aid: this.account_id == null ? this.$getSessionStorage('record_id') : this.account_id,
                    lcn: this.location,
                    lct: this.locationtype,
                    lid: this.currentDirectory.location_id,
                    mid: this.currentDirectory.main_location_id,
                    ist: this.is_transaction == null ? 0 : 1,
                    ups: this.upload_source, /* Upload Source */
                }
            }; 
            const parameters = {
                id: this.documentSelection.id,
                name: this.documentSelection.name,
                fid: this.documentSelection.fid,
                rsn: this.downloadForm.download_reason
            };
            axios.post('/api/DocumentLocation/Download', parameters, config)
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', parameters.name);
                        document.body.appendChild(link);
                        link.click();                        
                        this.$refs.downloadModal.click();
                        this.downloadForm = {
                            download_reason: null
                        };
                    }
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                    this.documentSelection = null;
                });          
        },
        onDeleteDocument(){
            const parameters = { 
                session_id: this.$getUser().session_id,
                id: this.documentSelection.id,
                fnm: this.documentSelection.name,
                fid: this.documentSelection.fid
            }; 
            axios.post('/api/DocumentLocation/DeleteDocument', parameters, {'Content-Type': 'application/json'})
              .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                        this.isFileManagerReady = false;
                        this.documentLocations = [];
                        this.documentSelection = null;
                        this.getDocumentLocations();
                        this.isUploadButton = false; 
                    }
               }) 
              .catch(function (error) {
                  console.log(error);
                  this.Progress.finish();
              });          
        },
        onActionsClick(e){
            if(e.itemData.id == 1){ //Download
                // this.onDownloadItems();
                this.$refs.openDownloadModal.click();
                this.downloadForm = {
                    download_reason: null
                };
            }
            if(e.itemData.id == 2){//Delete
             this.$swal.fire({
                    title: "Are you sure you want to delete this document?",
                    confirmButtonColor: "#f1416c",
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: "DELETE",
                    icon: 'info'
                })
                .then((result) => {
                    if (result.isConfirmed) { 
                        this.onDeleteDocument();
                    }
                });
            }
        },
        onRefreshFileList(){
            setTimeout(() => {
                if(this.$refs.fileManagerRef !== null && this.$refs.fileManagerRef !== undefined){
                    this.$refs.fileManagerRef.instance.refresh();
                }
            }, 100);
        }
    },
    created(){ 
        this.documentLocations = [];
        this.documentSelection = null; 
        this.getDocumentLocations();
    }
}; 
</script>