<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Balance Closing Operations') }}
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{$t('Transactions')}}</li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Balance Closing Operations') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <button v-if="form.activeView > 1" type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3" @click="onBackStep()">
                    <span class="ms-2 fs-7"> {{ $t('Return back to criterias') }}</span>
                </button>
                <button class="btn btn-light-danger border border-danger justify-content-end float-end me-5" @click="onClickCancel()" v-if="form.activeView > 1">
                    {{ $t('Cancel') }}
                </button>
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" v-if="this.$isCredentialActive(26,'R')" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" :disabled="!this.$getUser().is_allow_list_all_data && (this.form.account_id == null || this.form.account_id == undefined || this.form.account_id == '-1')">
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{$t('Transaction List')}}
                </button>
            </div>
        </div>
        <div class="content flex-row-fluid mt-2">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="row p-4" :class="{'pt-5': form.activeView == 2}">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row" v-if="form.activeView == 1">
                            <div class="col-lg-9">
                                <div class="row g-xl-9">
                                    <div class="d-flex flex-column flex-row-auto col-lg-5">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                            <div class="card-header">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-3"> {{ $t('Select Account') }} </h3>
                                                </div>
                                                <div class="card-toolbar">
                                                    <button ref="AddNewContactButton" type="button" class="d-none btn btn-light-primary border border-primary btn-sm justify-content-end"
                                                        @click="openCreateContactModal()" data-bs-toggle="modal" data-bs-target="#kt_modal_add_contact">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <path
                                                                    d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                                                                    fill="currentColor"></path>
                                                                <path opacity="0.3"
                                                                    d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                                                                    fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Create New Account') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="fv-row row">
                                                    <div class="col-lg-12">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">
                                                            <span>{{ $t('Search Account') }}</span>
                                                        </label>
                                                        <div class="input-group">
                                                            <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()"
                                                                v-if="form.account_id !== null && form.account_id !== '' && form.account_id !== undefined ">
                                                                <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                            </button>
                                                            <input type="search" class="form-control form-control-lg" v-model="form.account_name" @input="form.account_id = null" :placeholder="$t('Please type an account name and click on search button or press enter')" @keyup.enter="onClickSearchButton('searchBalanceClosingAccountButton')" name="contact_name" v-custom >
                                                            <button ref="searchBalanceClosingAccountButton" type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_search_account_modal" 
                                                                data-kt-indicator="on" @click="openSearchModal(12, form.account_name)">
                                                                <i class="fa fa-search"></i>
                                                                {{ $t('Search') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column flex-row-auto col-lg-7">
                                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                            <div class="card-header">
                                                <div class="card-title">
                                                    <h3 class="text-gray-800 me-3">{{$t('Select Balance Closing Type')}}</h3>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-4">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'balance_closing_type'">
                                                            <span>{{ $t('Balance Closing Type') }}</span>
                                                        </label>
                                                        <Select2 v-model="form.balance_closing_type" :options="balanceClosingTypeList" name="balance_closing_type" v-custom :settings="{ width: '100%' }"/>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row mb-1 fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'transaction_start_date'">
                                                                {{ $t('Transaction Start Date') }}
                                                            </label>
                                                            <Datepicker name="transaction_start_date" :required="this.$checkIfDateFieldRequired ('transaction_start_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.transaction_start_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                            :enableTimePicker="false" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="fv-row mb-1 fv-plugins-icon-container">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'transaction_end_date'">
                                                                {{ $t('Transaction End Date') }}
                                                            </label>
                                                            <Datepicker name="transaction_end_date" :required="this.$checkIfDateFieldRequired ('transaction_end_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.transaction_end_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" 
                                                            :enableTimePicker="false" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-0">
                                        <div class="d-grid gap-2">
                                            <button class="btn btn-primary mb-4 fw-boldest" type="submit" @click="changeView(2)" :disabled="form.account_id == null">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none">
                                                        <path
                                                            d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                                                            fill="currentColor"></path>
                                                        <path opacity="0.3"
                                                            d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </span>
                                                {{ $t('Start Balance Closing') }}
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="d-flex flex-column flex-row-auto col-lg-3">
                                <div class="d-flex flex-column-fluid card card-xxl-stretch border border-gray-300 mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300" v-if="!this.isContactInfoVisible"> 
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h4 class="text-gray-800">
                                                {{ $t('Recently Used Accounts') }}
                                            </h4> 
                                        </div>
                                        <div class="card-toolbar">                                        
                                            <i class="bi bi-info-circle text-primary fs-3 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"
                                                v-if="this.form.account_id !== null && this.form.account_id !== '' && this.form.account_id != undefined">
                                            </i>
                                        </div>
                                    </div>
                                    <div class="card-body p-5" v-if="accountLatestList">
                                        <div class="scroll-y me-n5 pe-5 h-300px h-xl-auto mh-500px">
                                            <div v-for="(item, index) in accountLatestList" v-bind:key="item">
                                                <div class="d-flex flex-stack py-2">
                                                    <div class="d-flex align-items-center">
                                                        <div class="symbol symbol-40px symbol-circle cursor-pointer">
                                                            <span v-if="this.form.account_id !== item.id" class="symbol-label fs-6 fw-bolder">
                                                                <a href="#" class="btn btn-sm btn-icon text-hover-primary btn-circle border border-1 border-gray-200" 
                                                                    @click="onOpenAccountReport(item)" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                    <i class="fa fa-eye text-gray-600 fs-2"></i>
                                                                </a>
                                                            </span>

                                                            <span v-if="this.form.account_id == item.id" class="svg-icon svg-icon-success svg-icon-3x">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                    <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <a class="ms-4 cursor-pointer" @click="getAccountDetail(item.id)">
                                                            <span class="fs-6 fw-bold text-gray-800 text-hover-primary mb-2 cursor-pointer">{{item.name}}</span>
                                                            <div class="fw-bold fs-7 text-muted cursor-pointer">{{item.account_type_name}}
                                                                <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                <span class="fw-bold fs-7 text-muted cursor-pointer">{{item.local_balance}}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="separator separator-dashed my-3" v-if="index !== accountLatestList.length - 1"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card border border-gray-300" v-if="this.isContactInfoVisible">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h4 class="text-gray-800">
                                                {{ $t('Account Info') }}
                                            </h4> 
                                        </div>
                                        <div class="card-toolbar m-0 p-0">
                                            <i class="bi bi-x-lg fs-5 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                        </div>
                                    </div>
                                    <AccountInfo :selected-account-info="this.selectedAccountInfo"></AccountInfo>  
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row" v-if="form.activeView > 1">
                        <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                <div class="card-header rounded-2 rounded-bottom-0 bg-gray-gradient min-h-40px">
                                    <div class="card-title">
                                        <h3> {{ $t('Account') }}</h3>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row">
                                                <span class="fs-2 fw-bolder text-gray-800">{{selectedAccountInfo.name}}<br>
                                                <span class="text-gray-700">{{selectedAccountInfo.customer_account_number}}</span></span> 
                                                <div class="d-flex justify-content-end ms-3">
                                                    <div class="symbol symbol-100px cursor-pointer"  data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()">
                                                        <div class="symbol-label fw-bold bg-light border text-hover-primary">
                                                            <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                <span class="fs-6 fw-bolder text-gray-700 mt-2">
                                                    {{selectedAccountInfo.customer_address}}
                                                </span>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                <span class="fs-6 text-gray-700 fw-bold"> {{selectedAccountInfo.customer_city}} </span>
                                                <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_district}} </span>
                                                <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_country}} </span>
                                                <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_postcode}} </span>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                <a href="{{selectedAccountInfo.customer_email}}" class="fw-bold fs-6"> {{selectedAccountInfo.customer_email}} </a>
                                                <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_mobile_phone}} </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                <span class="fs-6 text-gray-700 fw-bolder"> {{getBalanceClosingTypeName(form.balance_closing_type)}} </span>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                <span class="fs-6 text-gray-700 fw-bolder"> 
                                                    {{previewDate(form.transaction_start_date)}}
                                                    <i class="bi bi-dash fw-bolder"></i>
                                                    {{previewDate(form.transaction_end_date)}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="form.activeView == 2">
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                <div class="p-3">
                                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">    
                                        <div class="table-responsive-lg">
                                            <DxDataGrid id="gridContainer" 
                                                :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="lists" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                :customize-columns="onCustomizeColumn"
                                                @exporting="this.$onExporting($event , form.balance_closing_type == 1 ? 'Collection-list' : 'Payment-list')"
                                                @option-changed="handlePropertyChange"> 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxSearchPanel :visible="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                />
                                                <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="center" :allow-exporting="false"  :width="100" />
                                                <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/> 
                                                <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>   
                                                <DxColumn data-field="item_type_name" :caption="$t('Type')" cell-template="item_type_name-cell-template"/>   
                                                <DxColumn data-field="cash_bank_account_name" :caption="$t('Bank Account')" header-cell-template="bank-account-header" cell-template="cash_bank_account_name-cell-template"/>   
                                                <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>  
                                                <DxColumn data-field="ref_number" :caption="$t('Ref/Cheque No')" header-cell-template="ref-no-header" cell-template="ref_number-cell-template"/>  
                                                <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>
                                                <DxColumn data-field="closed_amount" :sorting-method="this.$numericSort" :caption="$t('Closed Amount')" alignment="right" cell-template="closed_amount-cell-template"/>
                                                <DxColumn data-field="remain_amount" :sorting-method="this.$numericSort" :caption="$t('Remain Amount')" alignment="right" cell-template="remain_amount-cell-template"/>
                                                <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" cell-template="amount_local-cell-template"/> 
                                                <DxColumn data-field="closed_amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Closed Amount')" alignment="right" cell-template="closed_amount_local-cell-template"/> 
                                                <DxColumn data-field="remain_amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Remain Amount')" alignment="right" cell-template="remain_amount_local-cell-template"/> 

                                                <template #bank-account-header>
                                                    <div>{{ $t('Cash') }}</div><div>{{ $t('Account') }}</div>
                                                </template>
                                                <template #transaction-no-header>
                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                </template>
                                                <template #ref-no-header>
                                                    <div>{{ $t('Ref/Cheque') }}</div><div>{{ $t('No') }}</div>
                                                </template>

                                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                <template #headerLocalTemplate="{ data: data }">
                                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                </template>
                                                
                                                <template #detail-cell-template="{ data }">
                                                    <button type="button" class="btn btn-xs btn-primary" @click="changeView(3, data.data)">
                                                        {{ $t('Select') }}
                                                        <i class="bi bi-chevron-right fs-8 mb-1"></i>
                                                    </button>
                                                </template>
                                                <template #id-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary">
                                                        {{ (this.pageSize * this.pageIndex) + data.rowIndex + 1 }}
                                                    </a>
                                                </template>
                                                <template #transaction_date-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                </template>
                                                <template #item_type_name-cell-template="{ data }">
                                                        <span class="fw-bold p-3 align-middle" :class="{ 'text-primary': data.data.item_type == 1 , 'text-danger': data.data.item_type == 2}"> {{ data.data.item_type_name }}</span>
                                                </template>
                                                <template #cash_bank_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle" > {{ data.data.cash_bank_account_name }}</span>
                                                </template>
                                                <template #transaction_number-cell-template="{ data }">
                                                    <span class="text-gray-600 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                                                </template>
                                                <template #ref_number-cell-template="{ data }">
                                                    <span class="text-gray-600 p-3 align-middle" >{{ data.data.ref_number }}</span>
                                                </template>
                                                <template #amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                </template>
                                                <template #closed_amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.closed_amount}}</span>
                                                </template>
                                                <template #remain_amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.remain_amount}}</span>
                                                </template>
                                                <template #currency-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                                                </template>
                                                <template #rate-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                </template>
                                                <template #amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                </template>
                                                <template #closed_amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.closed_amount_local}} </span>
                                                </template>
                                                <template #remain_amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.remain_amount_local}} </span>
                                                </template>
                                                <DxSummary :calculate-custom-summary="setSummaries">
                                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                    <DxGroupItem
                                                        :show-in-group-footer="true"
                                                        name="amount_local"
                                                        summary-type="custom"
                                                        show-in-column="amount_local"
                                                        display-format="{0}"
                                                    /> 
                                                    <DxTotalItem name="amount_local"
                                                        summary-type="custom"
                                                        show-in-column="amount_local"
                                                    />
                                                </DxSummary>
                                            </DxDataGrid>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" v-show="form.activeView == 3">
                            <BalanceClosingList ref="BalanceClosingList" :is-manual-operation="true" modal-type="editItem" :active-tab="form.activeView == 3 ? 'BalanceClosing' : ''"></BalanceClosingList>
                    </div>
                    <div class="col-lg-12 mt-0" v-if="form.activeView == 3 && this.$isCredentialActive(26,'I')">
                        <div class="row">
                            <div class="d-grid gap-2">
                                <button class="btn btn-success btn-lg mb-4" type="submit" @click="onSaveBalanceClosing()" data-kt-indicator="on"> 
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span> 
                                    <strong v-if="!this.isSaving"> {{ $t('Save Balance Closing') }} </strong> 
                                    <span class="indicator-progress" v-if="this.isSaving">
                                        {{ $t('Saving Invoice. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="kt_modal_add_contact" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<AddNewContact ref="AddNewContact"/>
		</div>
        <!-- Search Account Modal -->
        <div class="modal fade" id="kt_search_account_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchAccountWithSelect ref="SearchAccountWithSelect" :account-type="12"/>
        </div>
        <!-- Transaction List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="11" :account-id="form.account_id" :account-name="form.account_name" />
        </div>
        <!--AccountReports-->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.account_name" :account-id="form.account_id" :account-type="form.account_type" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxScrolling  } from 'devextreme-vue/data-grid';
import AddNewContact from '../Modals/AddNewContact.vue';
import SearchAccountWithSelect from '../../Accounts/Modals/SearchAccountWithSelect.vue';
import TransactionListModal from '../TransactionListModal.vue'; 
import BalanceClosingList from '../Modals/BalanceClosingList.vue';

export default {
  name: "BalanceClosingOperations",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, AddNewContact, SearchAccountWithSelect, TransactionListModal, DxSummary, DxTotalItem, DxGroupItem, DxScrolling, BalanceClosingList
  },
  data() {
    return {        
        pageSize: 10,
        pageIndex: 0,
        dropDownOptions: { width: 600 },
        accountLatestList: [],
        balanceClosingTypeList:[{ id: 1, text: 'Collection'}, { id: 2, text: 'Paid'}],
        form: {
            docket_item_id: null,
            currency: null,
            account_type: 12,
            account_id: null,
            account_name: null,
            balance_closing_type: 1,
            activeView: 1,
            transaction_start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            transaction_end_date: moment()
        },
        menuSelection: '/BalanceClosingHelppage',
        iconView: false,
        isContactInfoVisible: false,
        lists: [],
        localCurrency: null,
        selectedAccountInfo: {},
        selectedAccount: {
            name: null,
            account_type: null,
            account_type_name: null,
            account_id: null,
            account_name: null,
            qr_code: null,
            notes: null,
            company_type_id: null,
            account_number: null,
            contact_name: null,
            email: null,
            mobile_phone: null,
            phone: null,
            postcode: null,
            address: null,
            country: null,
            city: null,
            vat_number: null,
            payment_value: null,
            sales_channel_name: null,
            customer_type_name: null,
            expense_type_name: null,
            expense_group_name: null,
            branch_name: null
        },
        balanceClosingList: [],
        isSaving: false
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    getBalanceClosingTypeName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.balanceClosingTypeList.find(element => {
            return element.id == value
          }).text;
        }
      }
    },
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);
        }
    }
  },
  methods: { 
    getData() {
        this.$Progress.start(); 
        this.$setSessionStorage('tmp_session_id', null);
        this.isSaving = false;
        this.accountLatestList = [];
        this.localCurrency = this.$getUser().currency_code;             
        const params = {
            session_id : this.$getUser().session_id,
            account_type : 12 // Customer: 1 + Supplier: 2
        };
        const that = this;
        axios.post('api/Invoice/GetLatestUsedAccounts', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.accountLatestList = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
            that.$Progress.finish();
        })
        .finally(function () { 
            that.$Progress.finish();
        });
    },
    getCollections(docketInfo) {        
        //Transaction listesinden docket secildiyse docket bilgilerine gore listeleniyor
        if(docketInfo !== null && docketInfo !== '' && docketInfo !== undefined) {
            this.form.account_id = docketInfo.account_id;
            this.form.balance_closing_type = docketInfo.item_type;
            this.getAccountDetail(docketInfo.account_id);
            this.form.activeView = 2;
        }

        this.lists = [];
        this.$Progress.start();
        this.form.transaction_start_date = (this.form.transaction_start_date == null || this.form.transaction_start_date == '') ? moment() : this.form.transaction_start_date;
        this.form.transaction_end_date = ( this.form.transaction_end_date == null || this.form.transaction_end_date == '') ? moment () : this.form.transaction_end_date;
        const queryParameter = {
            session_id: this.$getUser().session_id,
            account_id: this.form.account_id,
            first_date: moment(this.form.transaction_start_date).format('YYYY-MM-DD'),
            last_date: moment(this.form.transaction_end_date).format('YYYY-MM-DD'),
            bc_type: this.form.balance_closing_type,
            docket_id: (docketInfo !== null && docketInfo !== '' && docketInfo !== undefined) ? docketInfo.id : null
        };   
        const that = this;
        axios.post('/api/BalanceClosing/GetTransactionsForBalanceClosing', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {  
            this.lists = response.data;
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.finish();
        })
        .finally(function() {
            that.$Progress.finish();
        });
    },
    onSaveBalanceClosing() {
        this.$swal.fire({
            title: 'Are you sure you want to save this Balance Closing Operation?',
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        }).then((result) => {
            if (result.isConfirmed) { 
                this.isSaving = true;
                let selectedBalances = [];
                this.balanceClosingList.forEach((value) => {
                    if(value.isSelected && !value.isBalanceToRefundExist) {
                        selectedBalances.push({ balance_id: value.id, amount: value.closing_amount_input });
                    }
                });
                const params = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    balanceItems: selectedBalances,
                    docket_item_id: this.form.docket_item_id,
                    bc_type: this.form.balance_closing_type,
                    account_id: this.form.account_id
                };
                const that = this;
                axios.post('api/BalanceClosing/SaveBalanceOperation', params, {'Content-Type': 'application/json'})
                .then(() => {
                    this.changeView(2);
                })
                .catch(function () {
                    that.isSaving = false;
                })
                .finally(function () { 
                    that.isSaving = false;
                });
            }
        });
    },
    handlePropertyChange(e) {
        if(e.name == "paging") { this.pageIndex = e.component.pageIndex(); }
    },
    onSelectItem(item) {
        this.lists.forEach((value) => { 
            if (item.id !== value.id) { value.isSelected = false; }
        });    
    },
    getAccountDetail(id) {
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if (response.data.account_type == 1 || response.data.account_type == 2) {
                response.data.name = this.$unlock(response.data.name);
                response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
                response.data.customer_address = this.$unlock(response.data.customer_address);
                response.data.customer_city = this.$unlock(response.data.customer_city);
                response.data.customer_district = this.$unlock(response.data.customer_district);
                response.data.customer_country = this.$unlock(response.data.customer_country);
                response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
                response.data.customer_email = this.$unlock(response.data.customer_email);
                response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
                this.selectedAccountInfo = response.data;
                this.form.account_id = response.data.id;
                this.form.account_name = response.data.name;
                this.form.account_type = response.data.account_type;
                this.form.balance_closing_type = response.data.account_type == 2 ? 2 : 1;
            }
        }); 
    },
    changeView(activeView, data) {
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.form.activeView = activeView;

        if(activeView == 2) {
            this.getCollections();
        }
        if(activeView == 3) {
            this.getBalanceClosingTemp(data);
        }
    },
    getBalanceClosingTemp(balanceItem) {
        this.form.transaction_start_date = (this.form.transaction_start_date == null || this.form.transaction_start_date == '') ? moment() : this.form.transaction_start_date;
        this.form.transaction_end_date = ( this.form.transaction_end_date == null || this.form.transaction_end_date == '') ? moment () : this.form.transaction_end_date;
        const parameters = {
            session_id: this.$getUser().session_id,
            docket_item_id: balanceItem.id,
            bc_type: balanceItem.item_type
        };
        axios.post('/api/BalanceClosing/GetBalanceClosingTemp', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$setSessionStorage('tmp_session_id', response.data);
            }
            this.form.docket_item_id = balanceItem.id;
            this.form.currency = balanceItem.currency;
            this.$refs.BalanceClosingList.form = {
                account_id: this.form.account_id,
                id: balanceItem.id,
                amount: Math.abs(parseFloat(balanceItem.amount.replace(/,/g,''))),
                item_type: balanceItem.item_type,
                docket_type: balanceItem.docket_type
            };
            this.$refs.BalanceClosingList.getData(true);
            this.$refs.BalanceClosingList.balanceForm.currency = balanceItem.currency;
           
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onClickCancel() {
        this.$swal.fire({
            title: 'You have not saved yet. Are you sure you want to cancel?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.lists = [];
                this.$router.push({ path: '/BalanceClosingOperations' }).then(() => { this.$router.go(0) });
            }
        });
    },
    openCreateContactModal() {
        this.$refs.AddNewContactButton.click();
        this.$refs.AddNewContact.getData();
        if (this.form.invoice_type == 1 || this.form.invoice_type == 4) {
            this.$refs.AddNewContact.createContactForm.account_type = 1;
        }
        if (this.form.invoice_type == 2 || this.form.invoice_type == 3) {
            this.$refs.AddNewContact.createContactForm.account_type = 2;
        }
    },
    onSuccessNewAccount(account_id) {
        this.getAccountDetail(account_id);
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/BalanceClosingHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    openSearchModal(accountType, searchText) {
        if(accountType == 7) {
            this.$refs.SearchStockAccountWithSelect.getData(7, this.form.process_type, this.form.invoice_type, searchText, true);
        } else {
            this.$refs.SearchAccountWithSelect.getData(12, 0, 0, searchText, true);
        }
    },
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onOpenAccountReport(item){
        let accountInfo = {
            id: (item !== null && item !== undefined && item !== '') ? item.id : this.form.account_id, 
            name: (item !== null && item !== undefined && item !== '') ? item.name : this.form.account_name
        };
        this.$refs.AccountReports.getData(accountInfo);
        this.$refs.AccountReports.setModalCriteriasToDefault();
    },
    onClickSearchButton(refName) {
        this.$refs[refName].click();
    },
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){ options.totalValue = 0; }
            else if(options.summaryProcess === 'calculate'){ 
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local); 
            }
            else if(options.summaryProcess === 'finalize'){
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {
                    if(column.dataField == 'amount_local'){ 
                        column.caption =  'Local Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'closed_amount_local'){ 
                        column.caption =  'Local Closed Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                    if(column.dataField == 'remain_amount_local'){ 
                        column.caption =  'Local Remain Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
    onBackStep() {
        this.form.activeView--;
    }
  },
  created() { 
    this.$removeSessionStorage('tmp_session_id');
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
};
</script>
<style scoped> 
.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px !important;
    color: black;
} 
</style>