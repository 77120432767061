<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create Personnel Account') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'PersonnelList' }">{{ $t('Personnel') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Personnel Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/PersonnelList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                     <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="d-flex flex-row row p-10">
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7 row">
                                            <div class="col-lg-12" v-if="isViewAccountId">
                                                <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}">
                                                    {{ $t('Account ID') }}
                                                </label>
                                                <input type="text" class="form-control" name="accountID" v-model="form.account_id">
                                            </div>
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'name'">
                                                    {{ $t('Name Surname') }}
                                                </label>
                                                <input type="text" class="form-control" name="name" v-model="form.name" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'country'">
                                                        <span>{{ $t('Country') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.country" :options="countryList" :settings="{width: '100%'}" @select="onChangeCountry($event)" name="country" v-custom/>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'position'">
                                                        <span>{{ $t('Position') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.personnel_position_id" :options="positionList" name="position" v-custom :settings="{ width: '100%'}"/>  
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row mb-6 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'contact_name'">{{ $t('Contact Name') }}
                                                </label>
                                                <input type="text" class="form-control" name="contact_name" v-model="form.contact_name" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row fv-plugins-icon-container">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'email'">{{ $t('Email') }}
                                                    </label>
                                                    <input type="email" class="form-control" name="email" v-model="form.email" :class="{'border border-danger': isValidEmail == false}" v-custom>
                                                    <small class="text-danger" v-if="!isValidEmail && form.email !== null && form.email !== '' ">{{ $t('Email is not valid') }}</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'mobile_phone'">{{ $t('Mobile Phone') }}
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                        <input type="text" class="form-control" name="mobile_phone" v-model="form.mobile_phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'phone'">
                                                        <span>{{ $t('Phone') }}</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <Select2 v-model="form.land_phone_country_code" :options="phoneMaskList" :settings="{width: '120px'}"/>
                                                        <input type="text" class="form-control" name="phone" v-model="form.phone" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">                              
                                        <div class="fv-row mb-6 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'branch'">
                                                    <span>{{ $t('Branch') }}</span>
                                                </label>
                                                <Select2 v-model="form.branch_id" :options="branchList" name="branch" v-custom :settings="{ width: '100%'}"/> 
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'expense_center'">
                                                        <span>{{ $t('Expense Center') }}</span>
                                                    </label> 
                                                    <Select2 v-model="form.expense_center_id" :options="expenseCenterList" name="expense_center" v-custom :settings="{ width: '100%'}"/> 
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-6">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">                             
                                        <div class="fv-row mb-6 row">
                                            <div class="col-lg-12">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_number'">
                                                    <span>{{ $t('Account Number') }}</span>
                                                </label>
                                                <input type="text" class="form-control" name="account_number" v-model="form.account_number" v-custom>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'nino'">
                                                        <span>{{ $t('NINO') }}</span>
                                                    </label> 
                                                    <input type="text" class="form-control" name="nino" v-model="form.personnel_nino" v-custom>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0">                             
                                        <div class="fv-row mb-6 row">
                                            <GetAddress @address-selected="onSelectAddress" :account-type="5" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2 && this.$isCredentialActive(14,'AI')"/>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'post_code'">{{ $t('Post Code') }}                                                                                                             
                                                    </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="post_code" v-model="form.postcode" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(14,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByPostCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.postcode == null || this.form.postcode == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                        <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1  && this.$isCredentialActive(14,'AI')" type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                id="basic-addon2" 
                                                                @click="this.onFindAddressByAddressCode()" 
                                                                data-bs-toggle="modal" 
                                                                data-bs-target="#kt_modal_search_address_by_code" 
                                                                data-kt-menu-trigger="click" 
                                                                data-kt-menu-placement="bottom-end"
                                                                :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                            <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'address'">
                                                        <span>{{ $t('Address') }}</span>
                                                    </label>
                                                    <textarea class="form-control" name="address" v-model="form.address" v-custom></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'city'">
                                                        <span>{{ $t('City') }}</span>
                                                    </label>
                                                    <input type="text" class="form-control" name="city" v-model="form.city" v-custom>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'district'">{{ $t('District') }}
                                                    </label>
                                                    <input type="text" class="form-control" name="district" v-model="form.district" v-custom>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0"> 
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                <span>{{ $t('Notes') }}</span>
                                            </label>
                                            <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                        </div> 
                                </div>
                            </div>                    
                        </div>
                        <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(14,'I')">
                            <div class="d-grid gap-2"> 
                                <button type="button" class="btn btn-lg btn-success mb-0" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false"> 
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                    </svg>
                                    </span>
                                    <strong v-if="!this.isProgressing"> {{ $t('Save Personnel') }} </strong> 
                                        <span class="indicator-progress" v-if="this.isProgressing">
                                            {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.postcode" :account-type="5"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn" :account-type="5"></FindAddressByAddressCode>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2"; 
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
export default {
  name: "PersonnelCreate",
  components: {
    FindAddressByPostCode, FindAddressByAddressCode
  },
  data() {
    return {
      userData: this.$getUser(),
      positionList: [],
      countryList: [],
      cityList: [],
      addressList: [],
      branchList: [],
      expenseCenterList: [],
      phoneMaskList: [],
      isProgressing: false,
      form: {
        name: null,
        company_type_id: null,
        account_type: null,
        account_id: null,
        qr_code: null,
        notes: null, 
        account_number: null,
        contact_name: null,
        email: null,
        mobile_phone: null,
        phone: null,
        postcode: null,
        address: null,
        address_id: '-1',
        country: '-1',
        city: null,
        vat_number: null,
        payment_value: null,
        isVATNumberExist: 'false',
        personnel_nino: null,
        personnel_position_id: '-1',
        branch_id: '-1',
        expense_center_id: '-1',
        accounting_plan: null,
        phone_country_code: null,
        land_phone_country_code: null,
        district: null,
        x_coordinate: 0,
        y_coordinate: 0,
        uprn: null,
        udprn: null,
      },
      isViewAccountId: false,
      menuSelection: '/PersonnelHelppage',
      iconView : false
    };
  },
  computed: {
    isValidEmail() {
        return (this.form.email == null || this.form.email == '' || this.form.email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    }
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.addressList = [];
        this.addressList.unshift({ id: '-1', text: 'Select an option'});

        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["branch", "account_setting", "expense_center","country"],
            account_type: 5, // yalnızca account settings listesi çekilecek ise gerekli
            country_code: this.$getUser().company_country_code, // yalnızca Company Types listesi çekilecek ise gerekli
            list_type: 3, // Personnel Position List: 3           
            get_access_status: 0
        };  
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetLists', parameters, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/Helper/GetPhoneMasks', parameters, {'Content-Type': 'application/json'});

        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.branchList = responses[0].data.branch;
                this.expenseCenterList = responses[0].data.expense_center; 
                this.countryList = responses[0].data.country; 
                this.positionList = responses[1].data; 
                this.phoneMaskList = responses[2].data; 
                this.phoneMaskList.forEach(element => {
                    if(element.code == this.$getUser().company_country_code) {
                        this.form.phone_country_code = element.id;
                        this.form.land_phone_country_code = element.id;
                    }
                });
                if (responses[0].data.account_setting.is_default == false) {
                    this.isViewAccountId = true;
                }
                this.positionList.unshift({ id: '-1', text: 'Select an option'});
                this.countryList.unshift({ id: '-1', text: 'Select an option'}); 
                this.positionList.forEach((value) => {
                    if(value.is_default) {
                    this.form.personnel_position_id = value.id;
                    }});
                this.branchList.forEach((value) => {
                    if (value.is_default) {
                        this.form.branch_id = value.id;
                    } 
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.form.expense_center_id = value.id;
                    }
                });
                this.$Progress.finish(); 
            })
        )
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/PersonnelHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    saveData() {
      this.$checkSelect2Validation('poliform');  
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      this.isProgressing = true;
      const params ={
        id: this.form.id,
        name: this.form.name,
        customer_company_type_id: this.form.customer_company_type_id,
        account_type: this.form.account_type,
        account_id: this.form.account_id,
        qr_code: this.form.qr_code,
        notes: this.form.notes,
        account_number: this.form.account_number,
        contact_name: this.form.contact_name,
        email: this.form.email,
        mobile_phone: this.form.mobile_phone,
        phone: this.form.phone,
        postcode: this.form.postcode,
        address: this.form.address,
        address_id: this.form.address_id,
        country: this.form.country == '-1' ? null : this.form.country,
        city: this.form.city,
        vat_number: this.form.vat_number,
        payment_value: this.form.payment_value,
        isVATNumberExist: this.form.isVATNumberExist,
        personnel_nino: this.form.personnel_nino,
        personnel_position_id:  this.form.personnel_position_id == '-1' ? null : this.form.personnel_position_id,
        branch_id: this.form.branch_id == '-1' || this.form.branch_id == ''  ? null : this.form.branch_id,
        expense_center_id:  this.form.expense_center_id == '-1' ? null : this.form.expense_center_id,
        accounting_plan:  this.form.accounting_plan,
        phone_country_code: this.form.phone_country_code,
        land_phone_country_code: this.form.land_phone_country_code,
        district: this.form.district,
        x_coordinate: this.form.x_coordinate,
        y_coordinate: this.form.y_coordinate,
        uprn: this.form.uprn,
        udprn: this.form.udprn,
      };     
      const that = this;
      params.session_id = this.$getUser().session_id;
      params.account_type = 5; // Personnel: 1
      axios.post('api/CompanyAccounts/CreateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('record_id', response.data); 
                this.$router.push({ name: 'PersonnelUpdate' });
            } 
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    onChangeCountry(event) {
        this.form.phone_country_code = event.phone_country_code;
        this.form.land_phone_country_code = event.phone_country_code;
    },
    onFindAddressByPostCode(){
        this.$refs.FindAddressByPostCode.getData();
    },
    onFindAddressByAddressCode(){  
        this.$refs.FindAddressByAddressCode.getData();
    },
    onSelectAddressSearchResult(item){
        this.form.address = item.ADDRESS;
        this.form.city = item.POST_TOWN;
        this.form.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
        this.form.x_coordinate = item.X_COORDINATE;
        this.form.y_coordinate = item.Y_COORDINATE;
        this.form.uprn = item.UPRN;
        this.form.udprn = item.UDPRN;
        this.form.postcode = item.POSTCODE;
    },
    onSelectAddress(item) {
        if(item.id == '-1') return;
        this.form.address = item.fullAddress;
        this.form.city = item.town_or_city;
        this.form.district = item.district;
        this.form.postcode = item.postcode;
    }
  },
  created() {
    this.helpPageViewIcon();
    this.getData();
  },
};
</script>