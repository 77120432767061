<template> 
    <div>        
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Delete Multiple Accounts') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'MultiDeleteOperations' }">Multi Delete Operations</router-link></li> 
                    <li class="breadcrumb-item text-gray-600">{{ $t('Delete Multiple Accounts') }}
                        <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/MultiDeleteOperations">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Multiple Deletions') }}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content"> 
        <!-- <StatsBar></StatsBar> -->
            <div class="card">
                <div class="card-header border-0 pt-6">
                    <div class="card-title">
                        <div class="row mb-2">
                            <div class="col-lg">
                                <label class="fs-6 fw-bold form-label mt-3">
                                    <span>{{ $t('Account List') }}</span>
                                </label>  
                                <Select2 v-model="form.account_type" :options="accountTypes" :settings="{ width: '250px' }"/> 
                            </div>
                        </div>
                    </div>
                    <div class="card-toolbar">
                        <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                            <button type="button" class="btn btn-light border border-gray-400" data-kt-indicator="on" @click="getListData()"> 
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('All Accounts') }} </span>
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                        </div>
                        <div class="d-flex flex-stack flex-wrap">
                            <div class="d-flex justify-content-end ms-1 " data-kt-docs-table-toolbar="base">
                                <button type="button" class="btn border-gray-400 btn-danger btn-active-warning me-3" :disabled="this.selectedRowKeys.length == 0"
                                    @click="deleteItem()">
                                    <span class="svg-icon svg-icon-muted">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M19.5997 3.52344H2.39639C2.09618 3.53047 1.8003 3.59658 1.52565 3.718C1.25101 3.83941 1.00298 4.01375 0.79573 4.23106C0.588484 4.44837 0.426087 4.70438 0.317815 4.98447C0.209544 5.26456 0.157521 5.56324 0.164719 5.86344C0.157521 6.16364 0.209544 6.46232 0.317815 6.74241C0.426087 7.0225 0.588484 7.27851 0.79573 7.49581C1.00298 7.71312 1.25101 7.88746 1.52565 8.00888C1.8003 8.1303 2.09618 8.19641 2.39639 8.20344H19.5997C19.8999 8.19641 20.1958 8.1303 20.4704 8.00888C20.7451 7.88746 20.9931 7.71312 21.2004 7.49581C21.4076 7.27851 21.57 7.0225 21.6783 6.74241C21.7866 6.46232 21.8386 6.16364 21.8314 5.86344C21.8386 5.56324 21.7866 5.26456 21.6783 4.98447C21.57 4.70438 21.4076 4.44837 21.2004 4.23106C20.9931 4.01375 20.7451 3.83941 20.4704 3.718C20.1958 3.59658 19.8999 3.53047 19.5997 3.52344Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M2.39453 8.20361L4.01953 18.3111C4.15644 19.145 4.58173 19.9043 5.22121 20.4567C5.8607 21.009 6.6738 21.3194 7.5187 21.3336H14.5712C15.4215 21.3202 16.2395 21.006 16.8801 20.4468C17.5207 19.8875 17.9424 19.1193 18.0704 18.2786L19.5979 8.20361H2.39453ZM9.28453 16.3178C9.28453 16.5333 9.19893 16.7399 9.04656 16.8923C8.89418 17.0447 8.68752 17.1303 8.47203 17.1303C8.25654 17.1303 8.04988 17.0447 7.89751 16.8923C7.74513 16.7399 7.65953 16.5333 7.65953 16.3178V12.4069C7.65953 12.1915 7.74513 11.9848 7.89751 11.8324C8.04988 11.68 8.25654 11.5944 8.47203 11.5944C8.68752 11.5944 8.89418 11.68 9.04656 11.8324C9.19893 11.9848 9.28453 12.1915 9.28453 12.4069V16.3178ZM14.322 16.3178C14.322 16.5333 14.2364 16.7399 14.0841 16.8923C13.9317 17.0447 13.725 17.1303 13.5095 17.1303C13.294 17.1303 13.0874 17.0447 12.935 16.8923C12.7826 16.7399 12.697 16.5333 12.697 16.3178V12.4069C12.697 12.1915 12.7826 11.9848 12.935 11.8324C13.0874 11.68 13.294 11.5944 13.5095 11.5944C13.725 11.5944 13.9317 11.68 14.0841 11.8324C14.2364 11.9848 14.322 12.1915 14.322 12.4069V16.3178Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M17.3895 4.87755C17.2529 4.87776 17.1185 4.84303 16.999 4.77667C16.8796 4.71031 16.7791 4.61452 16.707 4.49839L14.5945 1.24839C14.488 1.07063 14.4544 0.858502 14.5009 0.656521C14.5473 0.45454 14.6702 0.2784 14.8437 0.165055C15.0215 0.0626479 15.2311 0.0303209 15.4315 0.0744071C15.6319 0.118493 15.8086 0.235816 15.927 0.403388L18.0395 3.70755C18.1434 3.88599 18.1755 4.09728 18.1292 4.2985C18.0829 4.49972 17.9618 4.67577 17.7904 4.79089C17.6659 4.85225 17.5282 4.88202 17.3895 4.87755Z" fill="currentColor" fill-opacity="0.8"/>
                                        <path d="M4.49988 4.8885C4.34679 4.8928 4.19591 4.85131 4.06655 4.76933C3.89514 4.65422 3.77399 4.47817 3.72771 4.27694C3.68143 4.07572 3.71349 3.86443 3.81738 3.686L5.98405 0.435999C6.09739 0.262485 6.27353 0.13961 6.47551 0.0931545C6.6775 0.0466989 6.88962 0.0802727 7.06738 0.186832C7.23676 0.303623 7.35627 0.479597 7.40239 0.680101C7.4485 0.880606 7.41788 1.09111 7.31655 1.27017L5.20405 4.52017C5.12881 4.63747 5.0243 4.73313 4.90082 4.79773C4.77733 4.86232 4.63914 4.8936 4.49988 4.8885Z" fill="currentColor" fill-opacity="0.8"/>
                                        </svg>
                                    </span>
                                    {{ $t('Delete') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-3">
                    <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="table-responsive min-h-300px">
                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                key-expr="id" ref="dxDataGridRef"
                                :data-source="accountList" 
                                :allow-column-reordering="true"  
                                :allow-column-resizing="true" 
                                :column-auto-width="true" 
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                @exporting="this.$onExporting($event , 'Account-List')"
                                :v-model:selected-row-keys="selectedRowKeys"
                                @selection-changed="onSelectionChanged">

                                <DxSelection mode="multiple" select-all-mode="allPages" show-check-boxes-mode="always"/>
                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>  
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="account_id" :caption="$t('Account ID')" alignment="left" cell-template="account-id-cell-template"/> 
                                <DxColumn data-field="name" :caption="$t('Name')" alignment="left" cell-template="name-cell-template"/> 
                                <DxColumn data-field="is_transaction_exist" :caption="$t('Transaction Exist?')" alignment="center" cell-template="is_transaction_exist-cell-template"/> 

                                <DxToolbar>
                                    <DxItem location="before">
                                    <DxButton
                                        :disabled="selectedRowKeys.length == 0"                                        
                                        :text="'Deselect All' + ' ('+ selectedRowKeys.length + ')'"
                                        @click="clearSelection"
                                    />
                                    </DxItem>
                                </DxToolbar>

                                <template #account-id-cell-template="{ data }">
                                    <div>
                                        <span class="text-gray-900"> {{ data.data.account_id }} </span> 
                                    </div>
                                </template>
                                <template #is_transaction_exist-cell-template="{ data }">
                                    <div>
                                        <span class="text-gray-900">
                                            <i class="bi bi-check-circle fs-5 text-center text-success" v-if="data.data.is_transaction_exist"></i>
                                        </span> 
                                    </div>
                                </template>
                                <template #name-cell-template="{ data }">                                    
                                    <div>
                                        <span class="text-gray-900"> {{ data.data.name }} </span> 
                                    </div>
                                </template>
                            </DxDataGrid>
                        </div>
                    </div> 
                </div>
            </div> 
        </div>
    </div>  
</template>
<script>
  import axios from 'axios';
  import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxScrolling} from 'devextreme-vue/data-grid';
  import DxButton from 'devextreme-vue/button';

  import DataGrid from "devextreme/ui/data_grid";

    DataGrid.defaultOptions({ 
        options: {
            scrolling: {
                legacyMode: true
            }
        }
    });

  export default {
    name: "MultiAccountDelete",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSelection, DxToolbar, DxItem, DxButton, DxScrolling
    },
    data() {
        return {
            pageSize: 50,
            accountTypes:[
                { id: 1, text: 'Customer' },
                { id: 2, text: 'Supplier' },
                { id: 3, text: 'Cash' },
                { id: 4, text: 'Bank' },
                { id: 5, text: 'Personnel' },
                { id: 6, text: 'Expense' },
                { id: 7, text: 'Stock' },
                { id: 8, text: 'Fixed Asset' },
                { id: 15, text: 'Other Account' }
            ],
            form: {
                account_type: 1,
            },
            isListProgressing: false,
            accountList:[],
            selectedRowKeys: [],
            menuSelection: '/DeleteMultipleAccountsHelppage',
            iconView : false,
        };
    },
    computed: {
        showPageSizeSelector() {
            return this.accountList.length > this.pageSize;
        },
        pageSizes() {
            let arr = [];
            let x = 1;
            let loop = (this.accountList.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    methods: {
        getListData() {
            this.$Progress.start();
            this.isListProgressing = true;
            const params = {
                session_id: this.$getUser().session_id,
                account_type: this.form.account_type
            };
            axios.post('/api/CompanyAccounts/GetAccountsByFilter', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.isListProgressing = false;
                this.accountList = response.data;
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            })
            .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish();
                setTimeout(() => {
                    this.isListProgressing = false;
                }, 500);
            });
        }, 
        deleteItem() {
            this.$swal.fire({
                title: "Are you sure you want to delete this item?",
                confirmButtonColor: "#f1416c",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "DELETE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) { 
                this.$Progress.start();
                const params = { 
                    session_id: this.$getUser().session_id,
                    accountIds: this.selectedRowKeys
                };
                axios.post('api/CompanyAccounts/DeleteMultiAccount', params, {'Content-Type': 'application/json'})
                .then(() => {
                    this.getListData();
                    this.$Progress.finish(); 
                })
                .catch(function () {
                    this.$Progress.finish();
                });  
            }
            });
        }, 
        onSelectionChanged: function(e) {
            this.selectedRowKeys = e.selectedRowsData.filter(x => x.is_transaction_exist === false).map(x => x.id);
            e.selectedRowsData.forEach((i) => {
                if (i.is_transaction_exist == true){
                    e.component.deselectRows([i.id]); return;
                } 
            });
        },
        clearSelection () {
            const dataGrid = this.$refs["dxDataGridRef"].instance;
            dataGrid.clearSelection();
        },
        async helpPageViewIcon() {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/DeleteMultipleAccountsHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        },  
    },
    created(){
        this.helpPageViewIcon();
    } 
  }; 
</script>