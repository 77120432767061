<template> 
  <div> 
    <div class="d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3 mt-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Mail Setting') }}</h1>
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>
              <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'MailSettingsList' }">{{ $t('Mail Settings') }}</router-link></li> 
              <li class="breadcrumb-item text-gray-600">{{ $t('Update Mail Settings') }}</li> 
              <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </ul> 
        </div>  
      <div class="d-flex align-items-center py-1">
        <router-link to="/MailSettingsList">
            <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                  <span class="ms-2 fs-7"> {{ $t('Return back to List') }} </span>
            </button>
        </router-link>
        &nbsp;
        <div class="dropdown">
            <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="mailSettingActions" type="button">
            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
            <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
            </svg></span>
                {{ $t('Actions') }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="mailSettingActions"> 
                <li>
                    <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                    </svg>
                    </span>{{ $t('Delete') }} </a>
                </li>
            </ul>
        </div>  
      </div>  
    </div>
    <div class="content flex-row-fluid mt-6" id="kt_content">
      <RequiredField></RequiredField>
      <div class="row">
          <div class="tab-content" id="myTabContent">
            <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                <div class="p-10">
                    <div class="row">
                        <div class="col-lg-12 mt-0"> 
                          <div class="d-flex flex-row row">
                            <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          {{ $t('Mail Subject') }}
                                        </label>
                                        <Select2 v-model="form.mail_subject" :options="mailSubjectList" :settings="{ width: '100%' }" />  
                                      </div>
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          {{ $t('Sender Mail') }}
                                        </label>
                                        <input type="text" class="form-control" name="name" v-model="form.sender" :class="{'border border-danger': isValidEmail == false}"> 
                                        <small class="text-danger" v-if="!isValidEmail && form.sender !== null && form.sender !== '' ">Email is not valid</small>
                                      </div>
                                      <div class="col-lg-12">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          {{ $t('Mail Server') }} (Smtp)
                                        </label>
                                        <input type="text" class="form-control" name="Smtp" v-model="form.smtp"> 
                                      </div>
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          {{ $t('Port') }}
                                        </label>
                                        <input type="number" class="form-control" name="port" v-model="form.port">
                                      </div>
                                      <div class="col-lg-6">
                                        <label class="fs-6 fw-bold form-label mt-3 required">
                                          {{ $t('SSL') }}
                                        </label>
                                        <div class="form-check mt-3 mb-2">
                                            <input class="form-check-input" type="checkbox" value="" id="isSSL" v-model="form.ssl">
                                        </div> 
                                      </div>
                                    </div> 
                                  </div>
                                </div> 
                              </div>
                              <div class="d-flex flex-column flex-row-auto col-lg-6"> 
                                  <div class="d-flex flex-column-fluid card card-xxl-stretch mb-xxl-6 bg-lighten border border-gray-300">
                                      <div class="card-body">
                                          <div class="row">
                                            <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Sender Username') }}
                                              </label>
                                              <input type="text" class="form-control" name="name" v-model="form.username"> 
                                            </div>
                                            <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Sender  Password') }}
                                              </label>
                                              <div class="position-relative">
                                              <input type="password" class="form-control" name="name" v-model="form.password"> 
                                              </div>
                                            </div>
                                          </div> 
                                      </div>
                                  </div> 
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="d-grid gap-2"> 
                            <button type="button" class="btn btn-lg btn-primary " @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                              <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                              </svg>
                              </span>
                              <strong v-if="!this.isProgressing"> {{ $t('Save Mail Settings') }} </strong> 
                              <span class="indicator-progress" v-if="this.isProgressing">
                                  {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span> 
                            </button>
                          </div>               
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>    
  </div>  
</template>
  
  <script>
  import axios from 'axios';   
  export default {
    name: "MailSettingsUpdate", 
    data() {
      return {
        lists: [],
        mailSubjectList: [
          { id: 1, text: 'Sending Documents'},
        ],
        isProgressing: false,
        form: { 
          id: null,
          mail_type: 3,
          mail_subject: 1,
          username: null,
          password: null,
          sender: null,
          smtp: null,
          port:0,
          ssl: false,
        },
        data:{},
        menuSelection: '/MailSettingsHelppage',
        iconView : false,
      };
    },
    computed: {
        isValidEmail() {
            return (this.form.sender == null || this.form.sender == '' || this.form.sender == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.sender);
        }
    },
    methods: {
      getData() {
        this.$setSessionStorage('refreshList', true);
        const params = {
          id: this.$getSessionStorage("record_id"),
          session_id: this.$getUser().session_id,
        }; 
        axios.post('/api/MailSettings/GetById', params, {'Content-Type': 'application/json'}).then((response) => { 
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            const mailData = JSON.parse(response.data.data);
            if(mailData !== null && mailData !== '' && mailData !== undefined) {
              this.form = { 
                id: response.data.id,
                mail_type: response.data.mail_type,
                mail_subject: response.data.mail_subject,
                username: mailData.username,
                password: mailData.password,
                sender: mailData.sender,
                smtp: mailData.smtp,
                port: mailData.port,
                ssl: mailData.ssl,
              }; 
            }
          }
        })
        .catch(function (error) {
          console.log(error); 
        });
      },
      saveData(){
        if(this.form.username == null || this.form.username == '' || this.form.password == null || this.form.password == '' || this.form.sender == null || this.form.sender == '' || this.form.smtp == null || this.form.smtp == '' || this.form.port == null || this.form.port == '') {
          this.$swal("Warning", "Required fields must be filled..!", 'info');
          return;
        }
        this.isProgressing = true;
        const obj = {
          "username": this.form.username,
          "password": this.form.password,
          "sender": this.form.sender,
          "smtp": this.form.smtp,
          "port": this.form.port,
          "ssl": this.form.ssl
        };
        const mailInfo = JSON.stringify(obj);
        const params = {
          id: this.form.id,
          mail_type: this.form.mail_type,
          mail_subject: this.form.mail_subject,
          session_id:  this.$getUser().session_id,
          data : mailInfo
        };
        const that = this;
        axios.post('/api/MailSettings/Create', params, {'Content-Type': 'application/json'})
        .then((response) => { 
          if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
            this.$swal("Success", "Data saved succesfully..!", 'success');
            this.$setSessionStorage('refreshList', true); 
          }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        });   
      },
      deleteItem(){
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
                const params = {
                    session_id: this.$getUser().session_id,
                    id: this.form.id
                }; 
                axios.post('api/MailSettings/Delete', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$router.push({ name: 'MailSettingsList' });
                    }
                })
                .catch(function () {
                    this.$swal("Error", "Data could not be deleted..Please refresh the page and try again..!", 'error');
                });  
            }
        })   
    },
    async helpPageViewIcon()
    {
      const queryparameter = {
        session_id : this.$getUser().session_id,
        menu_selection : '/MailSettingsHelppage'
      };
      axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
      .then((response) => { 
        if(!response.data.error){ 
          this.iconView = response.data;
        }
      })
    },
  },
  mounted() { 
    this.getData();
    this.helpPageViewIcon();
  }
}; 
</script>