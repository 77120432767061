<template>
    <div>
        <div class="modal-dialog modal-xxl">
            <div class="modal-content">
                <div class="modal-header bg-primary p-5">
                    <h2 class="text-white">Property Details</h2>
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closePropertyModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body pt-2" :class="{'overlay overlay-block': this.isLoading == true}"> 
                    <div class="card card-xxl-stretch pt-3">
                        <div class="card-body">
                            <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 pt-3">
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#property_tab_1" target="_self" @click="this.activeTab = '1'" :class="{'active': this.activeTab == 1}">
                                        <h4> {{ $t('Property Detail') }} </h4>
                                    </a>
                                </li>  
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#property_tab_2" target="_self" @click="this.activeTab = '2'" :class="{'active': this.activeTab == 2}">
                                        <h4> {{ $t('Policy Data') }}</h4>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="PropertyTabContent">
                                <div class="row tab-pane fade active show" v-if="this.activeTab == '1'" id="property_tab_1" role="tabpanel">
                                    <form id="propertyModal" @submit.prevent=""  class="mx-auto fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                        <div class="row">  
                                            <GetAddress @address-selected="onSelectAddress" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2"/>
                                            <div class="col-lg-6">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'postcode'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.postcode == true }">
                                                        {{ $t('Post') }} <br> {{ $t('Code') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="postcode" v-model="propertyForm.postcode" v-custom>
                                                            <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                                    @click="this.onFindAddressByPostCode(propertyForm.postcode)"
                                                                    :disabled="this.propertyForm.postcode == null || this.propertyForm.postcode == ''">
                                                                <span v-if="!isAddressSearching"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                                <span v-if="isAddressSearching">{{ $t('Searching...') }}</span> 
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'address_code'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.address_code == true }">
                                                        {{ $t('Address') }} <br> {{ $t('Code') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="address_code" v-model="propertyForm.registration_number" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 ">
                                                <div class="row mb-2">
                                                    <label class="col-lg-2 col-form-label form-label fw-bold p-2" v-tag="'address_id'">
                                                        {{ $t('Select') }} <br> {{ $t('Address') }}
                                                    </label>
                                                    <div class="col-lg-10 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.address_id" :options="addressList" name="address_id" v-custom @select="onSelectAddressSearchResult($event)"
                                                            :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">                                        
                                                <div class="row mb-2">
                                                    <textarea class="form-control" placeholder="Address" name="address" rows="2" v-model="propertyForm.address"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'city'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.city == true }">
                                                        {{ $t('City') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="city" v-model="propertyForm.city" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'district'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.district == true }">
                                                        {{ $t('District') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="district" v-model="propertyForm.district" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'country'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.country == true }">
                                                        {{ $t('Country') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.country" :options="countryList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="country"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separator my-3 border-gray-300"></div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'apartment_no'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.apartment_no == true }">
                                                        {{ $t('Apartment No') }} <br> {{ $t('Name') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="apartment_no" v-model="propertyForm.apartment_no" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'building_no'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.building_no == true }">
                                                        {{ $t('Building No') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="building_no" v-model="propertyForm.building_no" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'block_no'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.block_no == true }">
                                                        {{ $t('Block No') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="block_no" v-model="propertyForm.block_no" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'floor_no'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.floor_no == true }">
                                                        {{ $t('Floor No') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="floor_no" v-model="propertyForm.floor_no" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="row mb-2">
                                                    <label class="col-lg-2 col-form-label form-label fw-bold p-2" v-tag="'street_no'" :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.street_no == true }">
                                                        Street
                                                    </label>
                                                    <div class="col-lg-10 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="street_no" v-model="propertyForm.street_no" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">                                                    
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'residence_year' && 'residence_month'" :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.residence_year == true ||  this.propertyPolicyDataChecker.residence_month == true}">
                                                        {{ $t('Residence') }} <br> {{ $t('Period') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" name="residence_year" v-model="propertyForm.residence_year" placeholder="Year" v-custom>
                                                            <input type="text" class="form-control" name="residence_month" v-model="propertyForm.residence_month" placeholder="Month" v-custom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_type_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.usage_type_id == true }">
                                                        {{ $t('Usage') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.usage_type_id" :options="propertyUsageTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="usage_type_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'usage_type_detail_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.usage_type_detail_id == true }">
                                                        {{ $t('Usage Type') }} <br> {{ $t('Detail') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.usage_type_detail_id" :options="propertyUsageDetailList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="usage_type_detail_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'property_type_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.property_type_id == true }">
                                                        {{ $t('Property') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.property_type_id" :options="propertyTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="property_type_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'construction_type_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.construction_type_id == true }">
                                                        {{ $t('Construction') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.construction_type_id" :options="constructionTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="construction_type_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'construction_year_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.construction_year_id == true }">
                                                        {{ $t('Construction') }} <br> {{ $t('Year') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.construction_year_id" :options="constructionYearList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="construction_year_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'property_area'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.property_area == true }">
                                                        {{ $t('Property') }} <br> {{ $t('Area') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="property_area" v-model="propertyForm.property_area" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'residence_type_id'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.residence_type_id == true }">
                                                        {{ $t('Residence') }} <br> {{ $t('Type') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="propertyForm.residence_type_id" :options="residenceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_property .modal-content' }" v-custom name="residence_type_id"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label form-label fw-bold p-2" v-tag="'owner_name'"  :class="{ 'fw-boldest text-info fst-italic' : this.propertyPolicyDataChecker.owner_name == true }">
                                                        {{ $t('Owner') }} <br> {{ $t('Name') }}
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control" name="owner_name" v-model="propertyForm.owner_name" v-custom>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="separator my-3 border-gray-300"></div>                                               
                                            <div class="col-lg-4" :class="{ 'col-lg-12' : this.selectedTransaction.is_property_exist == false }"> 
                                                <div class="input-group mb-3">
                                                    <div class="input-group-text">
                                                        <input class="form-check-input" type="checkbox" v-model="propertyForm.is_update_locked" :disabled="this.originalPropertyAccount.is_update_locked == true"/>
                                                    </div>
                                                    <span class="input-group-text">
                                                        {{ $t('Lock Update') }}
                                                    </span>
                                                    <span class="input-group-text" v-if="this.$isAdmin() && this.selectedTransaction.is_property_exist == true">
                                                        <button type="button" class="btn btn-xs btn-warning text-center" 
                                                                @click="onAdminActivateLockUpdate()"
                                                                :disabled="!this.userData.permission_type == 1"> 
                                                            <i class="fa fa-lock text-gray-800 ps-1" v-if="this.propertyForm.is_admin_update_locked == true"></i> 
                                                            <i class="fa fa-unlock text-gray-800 ps-1" v-if="this.propertyForm.is_admin_update_locked !== true"></i> 
                                                        </button>
                                                    </span>
                                                </div>
                                            </div> 
                                        </div>                   
                                    </form>
                                </div>
                                <div class="row tab-pane fade active show" v-if="this.activeTab == '2'" id="property_tab_2" role="tabpanel">
                                    <div class="card">
                                        <div class="card-body px-0">
                                            <div class="table-responsive">
                                                <table class="table table-striped text-gray-800 gy-3 gs-3" v-if="this.propertyPolicyData"> 
                                                    <tbody>  
                                                        <tr class="bg-light-info border-top border-bottom">
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1"></td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Registration Number</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.registration_number }}</td>
                                                        </tr>
                                                        <tr class="bg-light-warning border-top border-bottom" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                            <td colspan="3" class="text-left align-middle">
                                                                <button class="btn btn-block btn-success btn-xs" v-if="!propertyPolicyDataChecker.isSelected" @click="onSetAllPolicyPropertyData()" :disabled="this.propertyForm.is_update_locked && this.propertyForm.is_admin_update_locked != false"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    SET ALL DATA
                                                                </button>   
                                                                <button class="btn btn-block btn-danger btn-xs" v-if="propertyPolicyDataChecker.isSelected" @click="onSetAllPolicyPropertyData()" :disabled="this.propertyForm.is_update_locked && this.propertyForm.is_admin_update_locked != false"> 
                                                                    <i class="bi bi-ui-checks"></i>
                                                                    UNSET ALL DATA
                                                                </button>
                                                                <span><small class="ps-3"> This action will set/unset all data specified below. </small> </span>
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="name_description" 
                                                                    v-model="propertyPolicyDataChecker.name_description" @change="this.onSelectPolicyPropertyDataItem('name_description')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.name_description == null || this.propertyPolicyData.name_description == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Description</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.name_description }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="address" 
                                                                    v-model="propertyPolicyDataChecker.address" @change="this.onSelectPolicyPropertyDataItem('address')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.address == null || this.propertyPolicyData.address == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Address</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.address }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="apartment_no" 
                                                                    v-model="propertyPolicyDataChecker.apartment_no" @change="this.onSelectPolicyPropertyDataItem('apartment_no')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.apartment_no == null || this.propertyPolicyData.apartment_no == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Apartment No</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.apartment_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="block_no" 
                                                                    v-model="propertyPolicyDataChecker.block_no" @change="this.onSelectPolicyPropertyDataItem('block_no')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.block_no == null || this.propertyPolicyData.block_no == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Block No</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.block_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="building_no" 
                                                                    v-model="propertyPolicyDataChecker.building_no" @change="this.onSelectPolicyPropertyDataItem('building_no')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.building_no == null || this.propertyPolicyData.building_no == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Building No</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.building_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="city" 
                                                                    v-model="propertyPolicyDataChecker.city" @change="this.onSelectPolicyPropertyDataItem('city')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.city == null || this.propertyPolicyData.city == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">City</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.city }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="construction_type_id" 
                                                                    v-model="propertyPolicyDataChecker.construction_type_id" @change="this.onSelectPolicyPropertyDataItem('construction_type_id')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.construction_type_code == null || this.propertyPolicyData.construction_type_code == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Construction Type</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.construction_type_name }} - {{ this.propertyPolicyData.construction_type_code }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="country" 
                                                                    v-model="propertyPolicyDataChecker.country" @change="this.onSelectPolicyPropertyDataItem('country')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.country == null || this.propertyPolicyData.country == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Country</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.country }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="district" 
                                                                    v-model="propertyPolicyDataChecker.district" @change="this.onSelectPolicyPropertyDataItem('district')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.district == null || this.propertyPolicyData.district == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">District</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.district }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="floor_no" 
                                                                    v-model="propertyPolicyDataChecker.floor_no" @change="this.onSelectPolicyPropertyDataItem('floor_no')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.floor_no == null || this.propertyPolicyData.floor_no == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Floor No</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.floor_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="owner_name" 
                                                                    v-model="propertyPolicyDataChecker.owner_name" @change="this.onSelectPolicyPropertyDataItem('owner_name')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.owner_name == null || this.propertyPolicyData.owner_name == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Owner Name</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.owner_name }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="postcode" 
                                                                    v-model="propertyPolicyDataChecker.postcode" @change="this.onSelectPolicyPropertyDataItem('postcode')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.postcode == null || this.propertyPolicyData.postcode == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">PostCode</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.postcode }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="property_area" 
                                                                    v-model="propertyPolicyDataChecker.property_area" @change="this.onSelectPolicyPropertyDataItem('property_area')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.property_area == null || this.propertyPolicyData.property_area == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Property Area</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.property_area }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="property_type_id" 
                                                                    v-model="propertyPolicyDataChecker.property_type_id" @change="this.onSelectPolicyPropertyDataItem('property_type_id')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.property_type_code == null || this.propertyPolicyData.property_type_code == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Property Type</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.property_type_name }} - {{ this.propertyPolicyData.property_type_code }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="residence_month" 
                                                                    v-model="propertyPolicyDataChecker.residence_month" @change="this.onSelectPolicyPropertyDataItem('residence_month')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.residence_month == null || this.propertyPolicyData.residence_month == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Residence Month</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.residence_month }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="residence_year" 
                                                                    v-model="propertyPolicyDataChecker.residence_year" @change="this.onSelectPolicyPropertyDataItem('residence_year')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.residence_year == null || this.propertyPolicyData.residence_year == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Residence Year</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.residence_year }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="street_no" 
                                                                    v-model="propertyPolicyDataChecker.street_no" @change="this.onSelectPolicyPropertyDataItem('street_no')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.street_no == null || this.propertyPolicyData.street_no == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Street No</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.street_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="w-50px" v-if="this.selectedTransaction.is_property_exist == true || this.selectedTransaction.is_property_exist == 1">
                                                                <input class="form-check-input" type="checkbox" value="" id="usage_type_id" 
                                                                    v-model="propertyPolicyDataChecker.usage_type_id" @change="this.onSelectPolicyPropertyDataItem('usage_type_id')"
                                                                    :disabled="(this.propertyForm.is_update_locked == true && this.propertyForm.is_admin_update_locked != false) || (this.propertyPolicyData.usage_type_id == null || this.propertyPolicyData.usage_type_id == '')">
                                                            </td>
                                                            <td class="w-200px fw-bold border-gray-200 border-end">Usage Type</td>
                                                            <td class="ps-3">{{ this.propertyPolicyData.usage_type_name }} - {{ this.propertyPolicyData.usage_type_code }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-success btn-sm w-100" type="button" @click="this.onSaveProperty()"> <i class="fa fa-save"></i> SAVE PROPERTY </button>
                        </div>
                    </div>
                     
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isLoading == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name:'FactorPropertyModal',
    data(){
        return {
            activeTab: 1,
            isLoading: false,
            isAddressSearching: false,
            userData: this.$getUser(),
            selectedTransaction: {},
            propertyForm: {
                postcode: null,
                address_id: '-1',
                address: null,
                city: null,
                district: null,
                country: '-1',
                apartment_no: null,
                building_no: null,
                block_no: null,
                floor_no: null,
                street_no: null,
                residence_year: null,
                residence_month: null,
                usage_type_id: '-1',
                usage_type_detail_id: '-1',
                property_type_id: '-1',
                construction_type_id: '-1',
                construction_year_id: '-1',
                property_area: '0',
                residence_type_id: 0,
                owner_name: null,
                is_update_property_card: true,
                customer_uprn: null,
                customer_udprn: null,
                is_admin_update_locked: false,
                is_update_locked: false
            },
            addressList: [{id: '-1', text: 'Select an address from the list after the postcode query'}],
            constructionTypeList: [],
            constructionYearList: [],
            propertyTypeList: [],
            propertyUsageDetailList: [],
            propertyUsageTypeList: [],
            countryList: [],
            propertyPolicyData: null,
            propertyPolicyDataChecker: {},
            originalPropertyAccount: {
                is_update_locked: false
            },
            residenceTypeList: [
                {id: 0, text: 'Select an option'},
                {id: 1, text: 'Tenant'},
                {id: 2, text: 'Owner'},
            ],
            propertyImportParameters: []
        }
    },
    methods:{
        getData(item){
            this.clearForm();
            this.selectedTransaction = item;            
            this.propertyPolicyData = [];
            this.isLoading = true;
            this.activeTab = 1;
                
            const parameters = { 
                session_id: this.$getUser().session_id,
                list_name: ["country"],
                policy_transaction_id: item.id
            }; 
            const parametersTwo = {
                session_id : this.$getUser().session_id,
                list_types: [
                    { list_type: 17, list_name: "construction_type" },
                    { list_type: 18, list_name: "construction_year" },
                    { list_type: 20, list_name: "property_type" },
                    { list_type: 21, list_name: "property_usage_detail" },
                    { list_type: 22, list_name: "property_usage_type" },
                ],
                list_name: ["country"]
            };
            const parametersFour = {
                route: item.is_property_exist === 0  ? 'PropertyUpdate' : 'PropertyCreate',
                session_id: this.$getUser().session_id
            };
            const parametersFive = {
                session_id: this.$getUser().session_id,
                registration_number: item.property_registration_number,
                account_id: item.customer_account_id
            };
            const parametersSix = {
                session_id: this.$getUser().session_id,
                account_id: this.selectedTransaction.supplier_producer_id,
                data_type: -1 // ALL
            };
            const that = this;
            const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GetMultiPolicyLists', parametersTwo, {'Content-Type': 'application/json'});
            const requestThree =  axios.post('/api/DLLTemplates/GetTempTransactionProperty', parameters, {'Content-Type': 'application/json'});
            const requestFour =  axios.post('/api/FormValidations/GetFormFields', parametersFour, {'Content-Type': 'application/json'});
            const requestFive =  axios.post('/api/Insurance/GetFactorPropertyByPropertyInfo', parametersFive, {'Content-Type': 'application/json'});            
            const requestSix = axios.post('/api/SupplierAccount/GetDataImportParameters', parametersSix, {'Content-Type': 'application/json'}); 
            axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix]).then(axios.spread((...responses) => {
                this.countryList = responses[0].data.country; 
                this.countryList.unshift({ id: '-1', text: 'Select an option'});                    
                this.constructionTypeList= responses[1].data.construction_type;
                this.constructionYearList = responses[1].data.construction_year; 
                this.propertyTypeList = responses[1].data.property_type; 
                this.propertyUsageDetailList = responses[1].data.property_usage_detail; 
                this.propertyUsageTypeList = responses[1].data.property_usage_type; 
                this.propertyImportParameters = responses[5].data;
                this.constructionTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.constructionYearList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyUsageDetailList.unshift({ id: '-1', text: 'Select an option'});
                this.propertyUsageTypeList.unshift({ id: '-1', text: 'Select an option'});
                
                // Property Bilgisi varsa
                if(responses[2].data.length > 0){
                    responses[2].data.forEach(element => {
                        element.address = this.$unlock(element.address);
                        element.apartment_no = this.$unlock(element.apartment_no);
                        element.block_no = this.$unlock(element.block_no);
                        element.building_no = this.$unlock(element.building_no);
                        element.city = this.$unlock(element.city);
                        element.country = this.$unlock(element.country);
                        element.district = this.$unlock(element.district);
                        element.name_description = this.$unlock(element.name_description);
                        element.owner_name = this.$unlock(element.owner_name);
                        element.registration_number = this.$unlock(element.registration_number);
                        element.street_no = this.$unlock(element.street_no);
                    });
                    this.propertyPolicyData = JSON.parse(JSON.stringify(responses[2].data[0]));
                    this.propertyForm = responses[2].data[0];
                    this.propertyForm['policy_transaction_id'] = item.id;

                    this.propertyForm.address_id = this.propertyForm.address_id == '' || this.propertyForm.address_id == null ? '-1' : this.propertyForm.address_id;
                    this.propertyForm.property_type_id = this.propertyForm.property_type_id == '' || this.propertyForm.property_type_id == null ? '-1' : this.propertyForm.property_type_id;
                    this.propertyForm.construction_type_id = this.propertyForm.construction_type_id == '' || this.propertyForm.construction_type_id == null ? '-1' : this.propertyForm.construction_type_id;
                    this.propertyForm.usage_type_id = this.propertyForm.usage_type_id == '' || this.propertyForm.usage_type_id == null ? '-1' : this.propertyForm.usage_type_id;
                    this.propertyForm.usage_type_detail_id = this.propertyForm.usage_type_detail_id == '' || this.propertyForm.usage_type_detail_id == null ? '-1' : this.propertyForm.usage_type_detail_id;
                    this.propertyForm.construction_year_id = this.propertyForm.construction_year_id == '' || this.propertyForm.construction_year_id == null ? '-1' : this.propertyForm.construction_year_id;
                    this.propertyForm.residence_type_id = this.propertyForm.residence_type_id == '' || this.propertyForm.residence_type_id == null ? 0 : this.propertyForm.residence_type_id;
                    this.propertyForm.is_update_property_card = this.propertyForm.is_update_property_card == 1 ? true : false;
                    
                    this.countryList.forEach(countryItem => {
                        this.propertyForm.country = '-1';
                        if(countryItem.text == this.propertyForm.country){
                            this.propertyForm.country = countryItem.id;
                        }
                    });
                }
                else{
                    this.constructionTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.constructionYearList.unshift({ id: '-1', text: 'Select an option'});
                    this.propertyTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.propertyUsageDetailList.unshift({ id: '-1', text: 'Select an option'});
                    this.propertyUsageTypeList.unshift({ id: '-1', text: 'Select an option'});
                    this.clearPropertyForm();
                } 
                if(responses[3].data != null){  
                    sessionStorage.removeItem('frm_val');
                    sessionStorage.setItem('frm_val', JSON.stringify(responses[3].data));
                }
                
                if(item.is_property_exist == 1 || item.is_property_exist == true){
                    this.propertyForm = responses[4].data;
                    this.propertyForm.is_update_locked = responses[4].data.is_update_locked == 1 ? true : false;
                    this.propertyForm.is_admin_update_locked = responses[4].data.is_update_locked == 1 ? true : false;
                    this.propertyForm.name_description = responses[4].data.name;
                    const originalPropertyData = JSON.parse(JSON.stringify(this.propertyForm));
                    this.originalPropertyAccount = originalPropertyData;
                    this.propertyForm.address_code = responses[4].data.registration_number; 

                    
                    this.propertyForm.country = this.propertyForm.country == '' || this.propertyForm.country == null ? '-1' : this.propertyForm.country;
                    this.propertyForm.address_id = this.propertyForm.address_id == '' || this.propertyForm.address_id == null ? '-1' : this.propertyForm.address_id;
                    this.propertyForm.property_type_id = this.propertyForm.property_type_id == '' || this.propertyForm.property_type_id == null ? '-1' : this.propertyForm.property_type_id;
                    this.propertyForm.construction_type_id = this.propertyForm.construction_type_id == '' || this.propertyForm.construction_type_id == null ? '-1' : this.propertyForm.construction_type_id;
                    this.propertyForm.usage_type_id = this.propertyForm.usage_type_id == '' || this.propertyForm.usage_type_id == null ? '-1' : this.propertyForm.usage_type_id;
                    this.propertyForm.usage_type_detail_id = this.propertyForm.usage_type_detail_id == '' || this.propertyForm.usage_type_detail_id == null ? '-1' : this.propertyForm.usage_type_detail_id;
                    this.propertyForm.construction_year_id = this.propertyForm.construction_year_id == '' || this.propertyForm.construction_year_id == null ? '-1' : this.propertyForm.construction_year_id;
                    this.propertyForm.residence_type_id = this.propertyForm.residence_type_id == '' || this.propertyForm.residence_type_id == null ? 0 : this.propertyForm.residence_type_id;
                    this.propertyForm.is_update_property_card = this.propertyForm.is_update_property_card == 1 ? true : false;

                    //Usage Type ve Usage Detail Setlenir
                    this.propertyImportParameters.forEach(importParameter => {
                        //Construction Type
                        if((this.propertyForm.construction_type_id == null || this.propertyForm.construction_type_id == '-1' || this.propertyForm.construction_type_id == '') && importParameter.data_type == 3 && this.propertyPolicyData.construction_type_code !== null && this.propertyPolicyData.construction_type_code !== '' 
                            && (this.propertyPolicyData.construction_type_code == importParameter.data_code || this.propertyPolicyData.construction_type_name == importParameter.data_value)){
                            this.propertyForm.construction_type_id = (importParameter.sub_list_id !== null && importParameter.sub_list_id !== '') ? importParameter.sub_list_id : '-1';
                        }
                        //Property Type
                        if((this.propertyForm.property_type_id == null || this.propertyForm.property_type_id == '-1' || this.propertyForm.property_type_id == '') && importParameter.data_type == 5 && this.propertyPolicyData.property_type_code !== null && this.propertyPolicyData.property_type_code !== '' 
                            && (this.propertyPolicyData.property_type_code == importParameter.data_code || this.propertyPolicyData.property_type_name == importParameter.data_value)){
                            this.propertyForm.property_type_id = (importParameter.sub_list_id !== null && importParameter.sub_list_id !== '') ? importParameter.sub_list_id : '-1';
                        }
                        //Usage Type
                        if((this.propertyForm.usage_type_id == null || this.propertyForm.usage_type_id == '-1' || this.propertyForm.usage_type_id == '') && importParameter.data_type == 7 && this.propertyPolicyData.usage_type_code !== null && this.propertyPolicyData.usage_type_code !== '' 
                            && (this.propertyPolicyData.usage_type_code == importParameter.data_code || this.propertyPolicyData.usage_type_name == importParameter.data_value)){
                            this.propertyForm.usage_type_id = (importParameter.sub_list_id !== null && importParameter.sub_list_id !== '') ? importParameter.sub_list_id : '-1';
                        }
                    });
                }
            }))
            .catch(function (error) {
                console.log(error);
                that.isLoading = false;
            })
            .finally(function() {
                that.isLoading = false;
            });

        },
        onSaveProperty(){
            this.$checkSelect2Validation('propertyModal');
            if(!document.forms['propertyModal'].reportValidity()){
                return;
            }
            this.$swal.fire({
                title: "Are you sure you want to save property?",
                confirmButtonColor: "#50cd89",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "SAVE",
                icon: 'info'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    const parametersProperty = this.propertyForm;
                    parametersProperty.id = this.propertyPolicyData.id; //ID her zaman Data Import'daki Factor ID'sidir.
                    parametersProperty.account_id = this.selectedTransaction.customer_account_id;
                    parametersProperty.address_id = parametersProperty.address_id == '-1' ? null : parametersProperty.address_id;
                    parametersProperty.country = parametersProperty.country == '-1' ? null : parametersProperty.country;
                    parametersProperty.usage_type_id = parametersProperty.usage_type_id == '-1' ? null : parametersProperty.usage_type_id;
                    parametersProperty.usage_type_detail_id = parametersProperty.usage_type_detail_id == '-1' ? null : parametersProperty.usage_type_detail_id;
                    parametersProperty.property_type_id = parametersProperty.property_type_id == '-1' ? null : parametersProperty.property_type_id;
                    parametersProperty.construction_type_id = parametersProperty.construction_type_id == '-1' ? null : parametersProperty.construction_type_id;
                    parametersProperty.construction_year_id = parametersProperty.construction_year_id == '-1' ? null : parametersProperty.construction_year_id;
                    parametersProperty.is_update_property_card = 1;
                    parametersProperty.session_id = this.$getUser().session_id;
                    const that = this;
                    const request =  axios.post('/api/DLLTemplates/UpdateTempTransactionProperty', parametersProperty, {'Content-Type': 'application/json'});
                    axios.all([request]).then(axios.spread(() => {
                        this.$swal("Success", "Property saved successfully..!", 'success');
                        setTimeout(() => {
                            this.$refs.closePropertyModal.click(); 
                            this.$parent.getTransactionRowById(this.selectedTransaction.id, 1);
                        }, 500);                
                    }))
                    .catch(function (error) {
                        console.log(error);
                        that.isLoading = false;
                    })
                    .finally(function() {
                        that.isLoading = false;
                    });
                }
            
            });  
        },
        onSetAllPolicyPropertyData(){
            this.propertyPolicyDataChecker.isSelected = !this.propertyPolicyDataChecker.isSelected;
            if(this.propertyPolicyDataChecker.isSelected == true){
                this.propertyPolicyDataChecker = {
                    name_description: (this.propertyPolicyData.name_description !== null && this.propertyPolicyData.name_description) !== '' ? true : false,
                    address: (this.propertyPolicyData.address !== null && this.propertyPolicyData.address) !== '' ? true : false,
                    apartment_no: (this.propertyPolicyData.apartment_no !== null && this.propertyPolicyData.apartment_no) !== '' ? true : false,
                    block_no: (this.propertyPolicyData.block_no !== null && this.propertyPolicyData.block_no) !== '' ? true : false,
                    building_no: (this.propertyPolicyData.building_no !== null && this.propertyPolicyData.building_no) !== '' ? true : false,
                    city: (this.propertyPolicyData.city !== null && this.propertyPolicyData.city) !== '' ? true : false,
                    country: (this.propertyPolicyData.country !== null && this.propertyPolicyData.country) !== '' ? true : false,
                    district: (this.propertyPolicyData.district !== null && this.propertyPolicyData.district) !== '' ? true : false,
                    floor_no: (this.propertyPolicyData.floor_no !== null && this.propertyPolicyData.floor_no) !== '' ? true : false,
                    owner_name: (this.propertyPolicyData.owner_name !== null && this.propertyPolicyData.owner_name) !== '' ? true : false,
                    postcode: (this.propertyPolicyData.postcode !== null && this.propertyPolicyData.postcode) !== '' ? true : false,
                    property_area: (this.propertyPolicyData.property_area !== null && this.propertyPolicyData.property_area) !== '' ? true : false,
                    residence_month: (this.propertyPolicyData.residence_month !== null && this.propertyPolicyData.residence_month) !== '' ? true : false,
                    residence_year: (this.propertyPolicyData.residence_year !== null && this.propertyPolicyData.residence_year) !== '' ? true : false,
                    street_no: (this.propertyPolicyData.street_no !== null && this.propertyPolicyData.street_no) !== '' ? true : false,
                    property_type_id: (this.propertyPolicyData.property_type_id !== null && this.propertyPolicyData.property_type_id) !== '' ? true : false,
                    construction_type_id: (this.propertyPolicyData.construction_type_id !== null && this.propertyPolicyData.construction_type_id) !== '' ? true : false,
                    usage_type_id: (this.propertyPolicyData.usage_type_id !== null && this.propertyPolicyData.usage_type_id) !== '' ? true : false,
                    construction_year_id: (this.propertyPolicyData.construction_year_id !== null && this.propertyPolicyData.construction_year_id) !== '' ? true : false,
                    residence_type_id: (this.propertyPolicyData.residence_type_id !== null && this.propertyPolicyData.residence_type_id) !== '' ? true : false,
                };
                this.propertyForm.name_description = this.propertyPolicyData.name_description;
                this.propertyForm.postcode = (this.propertyPolicyData.postcode !== null && this.propertyPolicyData.postcode !== '') ? this.propertyPolicyData.postcode : this.propertyForm.postcode;
                this.propertyForm.address = (this.propertyPolicyData.address !== null && this.propertyPolicyData.address !== '') ? this.propertyPolicyData.address : this.propertyForm.address;
                this.propertyForm.city = (this.propertyPolicyData.city !== null && this.propertyPolicyData.city !== '') ? this.propertyPolicyData.city : this.propertyForm.city;
                this.propertyForm.district = (this.propertyPolicyData.district !== null && this.propertyPolicyData.district !== '') ? this.propertyPolicyData.district : this.propertyForm.district;
                this.propertyForm.country = (this.propertyPolicyData.country !== null && this.propertyPolicyData.country !== '') ? this.propertyPolicyData.country : this.propertyForm.country;
                this.propertyForm.apartment_no = (this.propertyPolicyData.apartment_no !== null && this.propertyPolicyData.apartment_no !== '') ? this.propertyPolicyData.apartment_no : this.propertyForm.apartment_no;
                this.propertyForm.building_no = (this.propertyPolicyData.building_no !== null && this.propertyPolicyData.building_no !== '') ? this.propertyPolicyData.building_no : this.propertyForm.building_no;
                this.propertyForm.block_no = (this.propertyPolicyData.block_no !== null && this.propertyPolicyData.block_no !== '') ? this.propertyPolicyData.block_no : this.propertyForm.block_no;
                this.propertyForm.floor_no = (this.propertyPolicyData.floor_no !== null && this.propertyPolicyData.floor_no !== '') ? this.propertyPolicyData.floor_no : this.propertyForm.floor_no;
                this.propertyForm.street_no = (this.propertyPolicyData.street_no !== null && this.propertyPolicyData.street_no !== '') ? this.propertyPolicyData.street_no : this.propertyForm.street_no;
                this.propertyForm.residence_year = (this.propertyPolicyData.residence_year !== null && this.propertyPolicyData.residence_year !== 0) ? this.propertyPolicyData.residence_year : this.propertyForm.residence_year;
                this.propertyForm.residence_month = (this.propertyPolicyData.residence_month !== null && this.propertyPolicyData.residence_month !== 0) ? this.propertyPolicyData.residence_month : this.propertyForm.residence_month;
                this.propertyForm.residence_type_id = (this.propertyPolicyData.residence_type_id !== null && this.propertyPolicyData.residence_type_id !== 0) ? this.propertyPolicyData.residence_type_id : this.propertyForm.residence_type_id;
                this.propertyForm.property_area = (this.propertyPolicyData.property_area !== null && this.propertyPolicyData.property_area !== 0) ? this.propertyPolicyData.property_area : this.propertyForm.property_area;
                this.propertyForm.owner_name = (this.propertyPolicyData.owner_name !== null && this.propertyPolicyData.owner_name !== '') ? this.propertyPolicyData.owner_name : this.propertyForm.owner_name;
                this.propertyForm.usage_type_id = this.propertyUsageTypeList.find( x => x.code == this.propertyPolicyData.usage_type_code).id;
                this.propertyForm.property_type_id = this.propertyTypeList.find( x => x.code == this.propertyPolicyData.property_type_code).id;
                this.propertyForm.construction_type_id = this.constructionTypeList.find( x => x.code == this.propertyPolicyData.construction_type_code).id;
                this.propertyForm.construction_year_id = this.constructionYearList.find( x => x.code == this.propertyPolicyData.construction_year).id;
            } else{
                this.propertyPolicyDataChecker = {
                    name_description: false,
                    address: false,
                    apartment_no: false,
                    block_no: false,
                    building_no: false,
                    city: false,
                    country: false,
                    district: false,
                    floor_no: false,
                    owner_name: false,
                    postcode: false,
                    property_area: false,
                    residence_month: false,
                    residence_year: false,
                    street_no: false,
                    property_type_id: false,
                    construction_type_id: false,
                    usage_type_id: false,
                    usage_type_detail_id: false,
                    construction_year_id: false,
                    residence_type_id: false,
                };
            }
        }, 
        onFindAddressByPostCode(postCode){
            this.isAddressSearching = true;
            const that = this;
            const parameters = {
                PostCode: postCode,
                SessionId: this.$getUser().session_id
            };
            axios.post('api/GetCompanyAccounts/FindAddressByPostCode', parameters, {'Content-Type': 'application/json'})
            .then(function(response) {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    if(response.data.results) {
                        response.data.results.forEach(value => {
                            value.DPA.id = value.DPA.UPRN;
                            value.DPA.text = value.DPA.ADDRESS;
                            that.addressList.push(value.DPA);
                        });
                    }
                }
            })
            .finally(function () { 
                that.isAddressSearching = false;
            }); 
        },
        onSelectAddressSearchResult(item){
            if(item.id == '-1') return;
            this.isViewAddressModal = false;
            this.propertyForm.address = item.ADDRESS;
            this.propertyForm.city = item.POST_TOWN;
            this.propertyForm.district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
            this.propertyForm.customer_x_coordinate = item.X_COORDINATE;
            this.propertyForm.postcode = item.POSTCODE;
            this.propertyForm.apartment_no = item.SUB_BUILDING_NAME;
            this.propertyForm.street_no = item.THOROUGHFARE_NAME;
            this.propertyForm.customer_uprn = item.UPRN;
            this.propertyForm.customer_udprn = item.UDPRN;
            let buildingName = (item.BUILDING_NAME !== null && item.BUILDING_NAME !== undefined && item.BUILDING_NAME !== '') ? item.BUILDING_NAME : '';
            if(item.BUILDING_NUMBER !== null && item.BUILDING_NUMBER !== undefined && item.BUILDING_NUMBER !== '') {
                buildingName = item.BUILDING_NUMBER;
            }
            this.propertyForm.building_no = buildingName;
        },
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.factorPropertyForm.address = item.fullAddress;
            this.factorPropertyForm.city = item.town_or_city;
            this.factorPropertyForm.district = item.district;
            this.factorPropertyForm.postcode = item.postcode;
            this.factorPropertyForm.building_no = item.building_number;
        },    
        onSelectPolicyPropertyDataItem(type){
            // POLICY ÜZERİNDEKİ DATA SETLENİR
            if(type == 'name_description' && this.propertyPolicyDataChecker.name_description == true && this.propertyPolicyData.name_description != '' && this.propertyPolicyData.name_description != null){
                this.propertyForm.name_description = this.propertyPolicyData.name_description;
            }
            if(type == 'address' && this.propertyPolicyDataChecker.address == true && this.propertyPolicyData.address != '' && this.propertyPolicyData.address != null){
                this.propertyForm.address = this.propertyPolicyData.address;
            }
            if(type == 'postcode' && this.propertyPolicyDataChecker.postcode == true && this.propertyPolicyData.postcode != '' && this.propertyPolicyData.postcode != null){
                this.propertyForm.postcode = this.propertyPolicyData.postcode;
            }
            if(type == 'city' && this.propertyPolicyDataChecker.city == true && this.propertyPolicyData.city != '' && this.propertyPolicyData.city != null){
                this.propertyForm.city = this.propertyPolicyData.city;
            }
            if(type == 'district' && this.propertyPolicyDataChecker.district == true && this.propertyPolicyData.district != '' && this.propertyPolicyData.district != null){
                this.propertyForm.district = this.propertyPolicyData.district;
            }
            if(type == 'country' && this.propertyPolicyDataChecker.country == true && this.propertyPolicyData.country != '' && this.propertyPolicyData.country != null){
                this.propertyForm.country = this.propertyPolicyData.country;
            }
            if(type == 'apartment_no' && this.propertyPolicyDataChecker.apartment_no == true && this.propertyPolicyData.apartment_no != '' && this.propertyPolicyData.apartment_no != null){
                this.propertyForm.apartment_no = this.propertyPolicyData.apartment_no;
            }
            if(type == 'building_no' && this.propertyPolicyDataChecker.building_no == true && this.propertyPolicyData.building_no != '' && this.propertyPolicyData.building_no != null){
                this.propertyForm.building_no = this.propertyPolicyData.building_no;
            }
            if(type == 'block_no' && this.propertyPolicyDataChecker.block_no == true && this.propertyPolicyData.block_no != '' && this.propertyPolicyData.block_no != null){
                this.propertyForm.block_no = this.propertyPolicyData.block_no;
            }
            if(type == 'floor_no' && this.propertyPolicyDataChecker.floor_no == true && this.propertyPolicyData.floor_no != '' && this.propertyPolicyData.floor_no != null){
                this.propertyForm.floor_no = this.propertyPolicyData.floor_no;
            }
            if(type == 'street_no' && this.propertyPolicyDataChecker.street_no == true && this.propertyPolicyData.street_no != '' && this.propertyPolicyData.street_no != null){
                this.propertyForm.street_no = this.propertyPolicyData.street_no;
            }
            if(type == 'residence_year' && this.propertyPolicyDataChecker.residence_year == true && this.propertyPolicyData.residence_year != '' && this.propertyPolicyData.residence_year != null){
                this.propertyForm.residence_year = this.propertyPolicyData.residence_year;
            }
            if(type == 'residence_month' && this.propertyPolicyDataChecker.residence_month == true && this.propertyPolicyData.residence_month != '' && this.propertyPolicyData.residence_month != null){
                this.propertyForm.residence_month = this.propertyPolicyData.residence_month;
            }
            if(type == 'residence_type_id' && this.propertyPolicyDataChecker.residence_type_id == true && this.propertyPolicyData.residence_type_id != '' && this.propertyPolicyData.residence_type_id != null){
                this.propertyForm.residence_type_id = this.propertyPolicyData.residence_type_id;
            }
            if(type == 'property_area' && this.propertyPolicyDataChecker.property_area == true && this.propertyPolicyData.property_area != '' && this.propertyPolicyData.property_area != null){
                this.propertyForm.property_area = this.propertyPolicyData.property_area;
            }
            if(type == 'owner_name' && this.propertyPolicyDataChecker.owner_name == true && this.propertyPolicyData.owner_name != '' && this.propertyPolicyData.owner_name != null){
                this.propertyForm.owner_name = this.propertyPolicyData.owner_name;
            }
            if(type == 'usage_type_id' && this.propertyPolicyDataChecker.usage_type_id == true && this.propertyPolicyData.usage_type_code != '' && this.propertyPolicyData.usage_type_code != null){
                this.propertyForm.usage_type_id = this.propertyUsageTypeList.find( x => x.code == this.propertyPolicyData.usage_type_code).id;
            }
            if(type == 'property_type_id' && this.propertyPolicyDataChecker.property_type_id == true && this.propertyPolicyData.property_type_code != '' && this.propertyPolicyData.property_type_code != null){
                this.propertyForm.property_type_id = this.propertyTypeList.find( x => x.code == this.propertyPolicyData.property_type_code).id;
            }
            if(type == 'construction_type_id' && this.propertyPolicyDataChecker.construction_type_id == true && this.propertyPolicyData.construction_type_code != '' && this.propertyPolicyData.construction_type_code != null){
                this.propertyForm.construction_type_id = this.constructionTypeList.find( x => x.code == this.propertyPolicyData.construction_type_code).id;
            }
            if(type == 'construction_year_id' && this.propertyPolicyDataChecker.construction_year_id == true && this.propertyPolicyData.construction_year_code != '' && this.propertyPolicyData.construction_year_code != null){
                this.propertyForm.construction_year_id = this.constructionYearList.find( x => x.code == this.propertyPolicyData.construction_year).id;
            }                
            
            // KARTTAN ORİJİNAL DATA YENİDEN SETLENİR
            if(type == 'name_description' && this.propertyPolicyDataChecker.name_description == false){
                this.propertyForm.name_description = this.originalPropertyAccount.name_description;
            }
            if(type == 'address' && this.propertyPolicyDataChecker.address == false) {
                this.propertyForm.address = this.originalPropertyAccount.address;
            }
            if(type == 'postcode' && this.propertyPolicyDataChecker.postcode == false) {
                this.propertyForm.postcode = this.originalPropertyAccount.postcode;
            }
            if(type == 'city' && this.propertyPolicyDataChecker.city == false) {
                this.propertyForm.city = this.originalPropertyAccount.city;
            }
            if(type == 'district' && this.propertyPolicyDataChecker.district == false) {
                this.propertyForm.district = this.originalPropertyAccount.district;
            }
            if(type == 'country' && this.propertyPolicyDataChecker.country == false) {
                this.propertyForm.country = this.originalPropertyAccount.country;
            }
            if(type == 'apartment_no' && this.propertyPolicyDataChecker.apartment_no == false) {
                this.propertyForm.apartment_no = this.originalPropertyAccount.apartment_no;
            }
            if(type == 'building_no' && this.propertyPolicyDataChecker.building_no == false) {
                this.propertyForm.building_no = this.originalPropertyAccount.building_no;
            }
            if(type == 'block_no' && this.propertyPolicyDataChecker.block_no == false) {
                this.propertyForm.block_no = this.originalPropertyAccount.block_no;
            }
            if(type == 'floor_no' && this.propertyPolicyDataChecker.floor_no == false) {
                this.propertyForm.floor_no = this.originalPropertyAccount.floor_no;
            }
            if(type == 'street_no' && this.propertyPolicyDataChecker.street_no == false) {
                this.propertyForm.street_no = this.originalPropertyAccount.street_no;
            }
            if(type == 'residence_year' && this.propertyPolicyDataChecker.residence_year == false) {
                this.propertyForm.residence_year = this.originalPropertyAccount.residence_year;
            }
            if(type == 'residence_month' && this.propertyPolicyDataChecker.residence_month == false) {
                this.propertyForm.residence_month = this.originalPropertyAccount.residence_month;
            }
            if(type == 'residence_type_id' && this.propertyPolicyDataChecker.residence_type_id == false) {
                this.propertyForm.residence_type_id = this.originalPropertyAccount.residence_type_id;
            }
            if(type == 'property_area' && this.propertyPolicyDataChecker.property_area == false) {
                this.propertyForm.property_area = this.originalPropertyAccount.property_area;
            }
            if(type == 'owner_name' && this.propertyPolicyDataChecker.owner_name == false) {
                this.propertyForm.owner_name = this.originalPropertyAccount.owner_name;
            }
            if(type == 'usage_type_id' && this.propertyPolicyDataChecker.usage_type_id == false){
                this.propertyForm.usage_type_id = this.originalPropertyAccount.usage_type_id;
            }
            if(type == 'property_type_id' && this.propertyPolicyDataChecker.property_type_id == false){
                this.propertyForm.property_type_id = this.originalPropertyAccount.property_type_id;
            }
            if(type == 'construction_type_id' && this.propertyPolicyDataChecker.construction_type_id == false){
                this.propertyForm.construction_type_id = this.originalPropertyAccount.construction_type_id;
            }
            if(type == 'construction_year_id' && this.propertyPolicyDataChecker.construction_year_id == false){
                this.propertyForm.construction_year_id = this.originalPropertyAccount.construction_year_id;
            }
        },  
        onCloseModal() {
            this.$parent.isViewFactorPersonModal = false;
        },
        clearForm() {
            this.propertyForm = {
                postcode: null,
                address_id: '-1',
                address: null,
                city: null,
                district: null,
                country: '-1',
                apartment_no: null,
                building_no: null,
                block_no: null,
                floor_no: null,
                street_no: null,
                residence_year: null,
                residence_month: null,
                usage_type_id: '-1',
                usage_type_detail_id: '-1',
                property_type_id: '-1',
                construction_type_id: '-1',
                construction_year_id: '-1',
                property_area: '0',
                residence_type_id: 0,
                owner_name: null
            };
            
            this.propertyPolicyDataChecker = {
                name_description: false,
                address: false,
                apartment_no: false,
                block_no: false,
                building_no: false,
                city: false,
                country: false,
                district: false,
                floor_no: false,
                owner_name: false,
                postcode: false,
                property_area: false,
                residence_month: false,
                residence_year: false,
                street_no: false,
                property_type_id: false,
                construction_type_id: false,
                usage_type_id: false,
                usage_type_detail_id: false,
                construction_year_id: false,
                residence_type_id: false,
            };
        },
        onAdminActivateLockUpdate(){
            this.propertyForm.is_admin_update_locked = false;
        },
    }
}
</script>